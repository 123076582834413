import {
  NOTIFICATION_CONTAINER,
  NOTIFICATION_INSERTION,
  NOTIFICATION_TYPE,
} from "@/enums/notify.enums";
import { INotifyType } from "@/models/notify";
import { Store } from "react-notifications-component";

const notify = (props: INotifyType) => {
  Store.addNotification({
    message: props.message,
    type: props.type,
    insert: NOTIFICATION_INSERTION.TOP,
    container: NOTIFICATION_CONTAINER.TOP_RIGHT,
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 2000,
    },
  });
};

const showSuccessNotification = (message: string) => {
  notify({ message, type: NOTIFICATION_TYPE.SUCCESS });
};

const showWarningNotification = (message: string) => {
  notify({ message, type: NOTIFICATION_TYPE.WARNING });
};

const showErrorNotification = (message: string) => {
  notify({ message, type: NOTIFICATION_TYPE.DANGER });
};

export {
  showSuccessNotification,
  showWarningNotification,
  showErrorNotification,
};
