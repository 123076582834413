/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Admin,
    AdminFromJSON,
    AdminToJSON,
    AdminPagination,
    AdminPaginationFromJSON,
    AdminPaginationToJSON,
    FilterPagination,
    FilterPaginationFromJSON,
    FilterPaginationToJSON,
    RegisterRequest,
    RegisterRequestFromJSON,
    RegisterRequestToJSON,
    UpdateRequest,
    UpdateRequestFromJSON,
    UpdateRequestToJSON,
} from '../models';

export interface AdminAdminsGetRequest {
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
    keyword?: string;
}

export interface AdminAdminsIdDeleteRequest {
    id: number;
}

export interface AdminAdminsIdGetRequest {
    id: number;
}

export interface AdminAdminsIdPutRequest {
    id: number;
    admin?: Admin;
}

export interface AdminAdminsPostRequest {
    registerRequest?: RegisterRequest;
}

export interface AdminFilterGetRequest {
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
    motherBirthStart?: string;
    motherBirthEnd?: string;
    motherGender?: string;
    email?: string;
    createStart?: string;
    createEnd?: string;
    address?: string;
    babyGender?: string;
    babyAgeStart?: string;
    babyAgeEnd?: string;
    babyStatus?: string;
    diagnosis?: string;
}

/**
 * 
 */
export class AdminsApi extends runtime.BaseAPI {

    /**
     * 
     */
    adminAdminsGetRaw = async (requestParameters: AdminAdminsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AdminPagination>> => {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.keyword !== undefined) {
            queryParameters['keyword'] = requestParameters.keyword;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/admins`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    adminAdminsGet = async (requestParameters: AdminAdminsGetRequest = {}, initOverrides?: RequestInit): Promise<AdminPagination> => {
        const response = await this.adminAdminsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    adminAdminsIdDeleteRaw = async (requestParameters: AdminAdminsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling adminAdminsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/admins/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    adminAdminsIdDelete = async (requestParameters: AdminAdminsIdDeleteRequest, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.adminAdminsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    adminAdminsIdGetRaw = async (requestParameters: AdminAdminsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Admin>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling adminAdminsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/admins/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminFromJSON(jsonValue));
    }

    /**
     * 
     */
    adminAdminsIdGet = async (requestParameters: AdminAdminsIdGetRequest, initOverrides?: RequestInit): Promise<Admin> => {
        const response = await this.adminAdminsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    adminAdminsIdPutRaw = async (requestParameters: AdminAdminsIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UpdateRequest>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling adminAdminsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/admins/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AdminToJSON(requestParameters.admin),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateRequestFromJSON(jsonValue));
    }

    /**
     */
    adminAdminsIdPut = async (requestParameters: AdminAdminsIdPutRequest, initOverrides?: RequestInit): Promise<UpdateRequest> => {
        const response = await this.adminAdminsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    adminAdminsPostRaw = async (requestParameters: AdminAdminsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Admin>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/admins`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterRequestToJSON(requestParameters.registerRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminFromJSON(jsonValue));
    }

    /**
     */
    adminAdminsPost = async (requestParameters: AdminAdminsPostRequest = {}, initOverrides?: RequestInit): Promise<Admin> => {
        const response = await this.adminAdminsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    adminFilterGetRaw = async (requestParameters: AdminFilterGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FilterPagination>> => {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.motherBirthStart !== undefined) {
            queryParameters['motherBirthStart'] = requestParameters.motherBirthStart;
        }

        if (requestParameters.motherBirthEnd !== undefined) {
            queryParameters['motherBirthEnd'] = requestParameters.motherBirthEnd;
        }

        if (requestParameters.motherGender !== undefined) {
            queryParameters['motherGender'] = requestParameters.motherGender;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        if (requestParameters.createStart !== undefined) {
            queryParameters['createStart'] = requestParameters.createStart;
        }

        if (requestParameters.createEnd !== undefined) {
            queryParameters['createEnd'] = requestParameters.createEnd;
        }

        if (requestParameters.address !== undefined) {
            queryParameters['address'] = requestParameters.address;
        }

        if (requestParameters.babyGender !== undefined) {
            queryParameters['babyGender'] = requestParameters.babyGender;
        }

        if (requestParameters.babyAgeStart !== undefined) {
            queryParameters['babyAgeStart'] = requestParameters.babyAgeStart;
        }

        if (requestParameters.babyAgeEnd !== undefined) {
            queryParameters['babyAgeEnd'] = requestParameters.babyAgeEnd;
        }

        if (requestParameters.babyStatus !== undefined) {
            queryParameters['babyStatus'] = requestParameters.babyStatus;
        }

        if (requestParameters.diagnosis !== undefined) {
            queryParameters['diagnosis'] = requestParameters.diagnosis;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/filter`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FilterPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    adminFilterGet = async (requestParameters: AdminFilterGetRequest = {}, initOverrides?: RequestInit): Promise<FilterPagination> => {
        const response = await this.adminFilterGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
