/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MedicalRecord,
    MedicalRecordFromJSON,
    MedicalRecordToJSON,
    MedicalRecordPagination,
    MedicalRecordPaginationFromJSON,
    MedicalRecordPaginationToJSON,
} from '../models';

export interface AdminMedicalRecordsGetRequest {
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
    userId?: number;
}

export interface MedicalRecordsGetRequest {
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface MedicalRecordsIdDeleteRequest {
    id: number;
}

export interface MedicalRecordsIdGetRequest {
    id: number;
}

export interface MedicalRecordsIdPutRequest {
    id: number;
    medicalRecord?: MedicalRecord;
}

export interface MedicalRecordsPostRequest {
    medicalRecord?: MedicalRecord;
}

export interface MedicalRecordsShareAllGetRequest {
    sourceUserId: number;
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface MedicalRecordsShareDetailGetRequest {
    recordId: number;
    sourceUserId: number;
}

/**
 * 
 */
export class MedicalRecordsApi extends runtime.BaseAPI {

    /**
     * 
     */
    adminMedicalRecordsGetRaw = async (requestParameters: AdminMedicalRecordsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MedicalRecordPagination>> => {
        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling adminMedicalRecordsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/medicalRecords`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MedicalRecordPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    adminMedicalRecordsGet = async (requestParameters: AdminMedicalRecordsGetRequest, initOverrides?: RequestInit): Promise<MedicalRecordPagination> => {
        const response = await this.adminMedicalRecordsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    medicalRecordsGetRaw = async (requestParameters: MedicalRecordsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MedicalRecordPagination>> => {
        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling medicalRecordsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/medicalRecords`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MedicalRecordPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    medicalRecordsGet = async (requestParameters: MedicalRecordsGetRequest, initOverrides?: RequestInit): Promise<MedicalRecordPagination> => {
        const response = await this.medicalRecordsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    medicalRecordsIdDeleteRaw = async (requestParameters: MedicalRecordsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling medicalRecordsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/medicalRecords/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    medicalRecordsIdDelete = async (requestParameters: MedicalRecordsIdDeleteRequest, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.medicalRecordsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    medicalRecordsIdGetRaw = async (requestParameters: MedicalRecordsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MedicalRecord>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling medicalRecordsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/medicalRecords/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MedicalRecordFromJSON(jsonValue));
    }

    /**
     * 
     */
    medicalRecordsIdGet = async (requestParameters: MedicalRecordsIdGetRequest, initOverrides?: RequestInit): Promise<MedicalRecord> => {
        const response = await this.medicalRecordsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    medicalRecordsIdPutRaw = async (requestParameters: MedicalRecordsIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MedicalRecord>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling medicalRecordsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/medicalRecords/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MedicalRecordToJSON(requestParameters.medicalRecord),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MedicalRecordFromJSON(jsonValue));
    }

    /**
     */
    medicalRecordsIdPut = async (requestParameters: MedicalRecordsIdPutRequest, initOverrides?: RequestInit): Promise<MedicalRecord> => {
        const response = await this.medicalRecordsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    medicalRecordsPostRaw = async (requestParameters: MedicalRecordsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MedicalRecord>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/medicalRecords`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MedicalRecordToJSON(requestParameters.medicalRecord),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MedicalRecordFromJSON(jsonValue));
    }

    /**
     */
    medicalRecordsPost = async (requestParameters: MedicalRecordsPostRequest = {}, initOverrides?: RequestInit): Promise<MedicalRecord> => {
        const response = await this.medicalRecordsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    medicalRecordsShareAllGetRaw = async (requestParameters: MedicalRecordsShareAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MedicalRecordPagination>> => {
        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling medicalRecordsShareAllGet.');
        }

        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling medicalRecordsShareAllGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/medicalRecords/share/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MedicalRecordPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    medicalRecordsShareAllGet = async (requestParameters: MedicalRecordsShareAllGetRequest, initOverrides?: RequestInit): Promise<MedicalRecordPagination> => {
        const response = await this.medicalRecordsShareAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    medicalRecordsShareDetailGetRaw = async (requestParameters: MedicalRecordsShareDetailGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MedicalRecord>> => {
        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling medicalRecordsShareDetailGet.');
        }

        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling medicalRecordsShareDetailGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.recordId !== undefined) {
            queryParameters['recordId'] = requestParameters.recordId;
        }

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/medicalRecords/share/detail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MedicalRecordFromJSON(jsonValue));
    }

    /**
     * 
     */
    medicalRecordsShareDetailGet = async (requestParameters: MedicalRecordsShareDetailGetRequest, initOverrides?: RequestInit): Promise<MedicalRecord> => {
        const response = await this.medicalRecordsShareDetailGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
