import { Admin, AdminRole } from '@/api';

import { atom } from 'recoil';

export const currentUserState = atom<Admin>({
    key: 'currentUserState',
    default: null,
});

export const allRolesState = atom<AdminRole[]>({
    key: 'allRoles',
    default: null,
});