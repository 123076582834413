/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ChildrenBody,
    ChildrenBodyFromJSON,
    ChildrenBodyToJSON,
    ChildrenBodyPagination,
    ChildrenBodyPaginationFromJSON,
    ChildrenBodyPaginationToJSON,
} from '../models';

export interface AdminChildrenBodiesGetRequest {
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
    userId?: number;
}

export interface ChildrenBodiesGetRequest {
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface ChildrenBodiesIdDeleteRequest {
    id: number;
}

export interface ChildrenBodiesIdGetRequest {
    id: number;
}

export interface ChildrenBodiesIdPutRequest {
    id: number;
    childrenBody?: ChildrenBody;
}

export interface ChildrenBodiesPatchRequest {
    childrenBody?: ChildrenBody;
}

export interface ChildrenBodiesShareAllGetRequest {
    sourceUserId: number;
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

/**
 * 
 */
export class ChildrenBodiesApi extends runtime.BaseAPI {

    /**
     * 
     */
    adminChildrenBodiesGetRaw = async (requestParameters: AdminChildrenBodiesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ChildrenBodyPagination>> => {
        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling adminChildrenBodiesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/childrenBodies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChildrenBodyPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    adminChildrenBodiesGet = async (requestParameters: AdminChildrenBodiesGetRequest, initOverrides?: RequestInit): Promise<ChildrenBodyPagination> => {
        const response = await this.adminChildrenBodiesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    childrenBodiesGetRaw = async (requestParameters: ChildrenBodiesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ChildrenBodyPagination>> => {
        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling childrenBodiesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/childrenBodies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChildrenBodyPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    childrenBodiesGet = async (requestParameters: ChildrenBodiesGetRequest, initOverrides?: RequestInit): Promise<ChildrenBodyPagination> => {
        const response = await this.childrenBodiesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    childrenBodiesIdDeleteRaw = async (requestParameters: ChildrenBodiesIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling childrenBodiesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/childrenBodies/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    childrenBodiesIdDelete = async (requestParameters: ChildrenBodiesIdDeleteRequest, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.childrenBodiesIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    childrenBodiesIdGetRaw = async (requestParameters: ChildrenBodiesIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ChildrenBody>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling childrenBodiesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/childrenBodies/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChildrenBodyFromJSON(jsonValue));
    }

    /**
     * 
     */
    childrenBodiesIdGet = async (requestParameters: ChildrenBodiesIdGetRequest, initOverrides?: RequestInit): Promise<ChildrenBody> => {
        const response = await this.childrenBodiesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    childrenBodiesIdPutRaw = async (requestParameters: ChildrenBodiesIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ChildrenBody>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling childrenBodiesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/childrenBodies/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChildrenBodyToJSON(requestParameters.childrenBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChildrenBodyFromJSON(jsonValue));
    }

    /**
     */
    childrenBodiesIdPut = async (requestParameters: ChildrenBodiesIdPutRequest, initOverrides?: RequestInit): Promise<ChildrenBody> => {
        const response = await this.childrenBodiesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    childrenBodiesPatchRaw = async (requestParameters: ChildrenBodiesPatchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ChildrenBody>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/childrenBodies`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ChildrenBodyToJSON(requestParameters.childrenBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChildrenBodyFromJSON(jsonValue));
    }

    /**
     */
    childrenBodiesPatch = async (requestParameters: ChildrenBodiesPatchRequest = {}, initOverrides?: RequestInit): Promise<ChildrenBody> => {
        const response = await this.childrenBodiesPatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    childrenBodiesShareAllGetRaw = async (requestParameters: ChildrenBodiesShareAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ChildrenBodyPagination>> => {
        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling childrenBodiesShareAllGet.');
        }

        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling childrenBodiesShareAllGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/childrenBodies/share/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChildrenBodyPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    childrenBodiesShareAllGet = async (requestParameters: ChildrenBodiesShareAllGetRequest, initOverrides?: RequestInit): Promise<ChildrenBodyPagination> => {
        const response = await this.childrenBodiesShareAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
