import { CardCustom, TextBlockCustom } from "@/components/display";
import { RoleCheck } from '@/components/system';
import { changeUrlQuery, getUrlQueryValue } from '@/utils';
import { Search } from "@mui/icons-material";
import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import debounce from "lodash/debounce";
import React from "react";
import styled from "styled-components";
import { AdminCreateButton, AdminTable, reloadAdminTableEvent } from "./components";
const StyledAdminListPage = styled.div`
.search-box {
  max-width: 400px;
  width: 100%;
}
`;

interface IAdminListPageProps { }

export const AdminListPage = (
  props: React.PropsWithChildren<IAdminListPageProps>
) => {

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchKeywordChange = React.useCallback(
    debounce((searchKeyword: string) => {
      changeUrlQuery('search', searchKeyword);
      window.dispatchEvent(reloadAdminTableEvent);
    }, 500),
    []
  );

  return (
    <StyledAdminListPage>
      <TextBlockCustom classText='text-lg font-bold mb-8' textHeader="管理者リスト" textSize={24} />
      <CardCustom>
        <div className="mb-6 flex">
          <div className="grow">
            <OutlinedInput
              defaultValue={getUrlQueryValue('search') ?? ''}
              className='bordered-input search-box'
              placeholder="社員名またはメールアドレスで検索してください。"
              size="small"
              onChange={(e) => handleSearchKeywordChange(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton>
                    {<Search />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>
          <div>
            <RoleCheck role="admin">
              {({ allowed }) => {
                if (!allowed) {
                  return null;
                }

                return (
                  <AdminCreateButton />
                );
              }}
            </RoleCheck>
          </div>
        </div>
        <AdminTable />
      </CardCustom>
    </StyledAdminListPage>
  );
};
