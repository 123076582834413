import { Route } from 'react-router-dom';

import { AUTH_PATH } from '@/constants/paths';

import { LoginPage } from './login';
import { LOGIN_PATH } from './login/LoginPage.shared';

export const renderAuthRoutes = () => (
    <Route path={AUTH_PATH}>
        <Route path={LOGIN_PATH} element={<LoginPage />} />
    </Route>
)