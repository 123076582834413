/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeleteResponse
 */
export interface DeleteResponse {
    /**
     * 
     * @type {Array<number>}
     * @memberof DeleteResponse
     */
    deleteList?: Array<number>;
}

/**
 * Check if a given object implements the DeleteResponse interface.
 */
export function instanceOfDeleteResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DeleteResponseFromJSON(json: any): DeleteResponse {
    return DeleteResponseFromJSONTyped(json, false);
}

export function DeleteResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deleteList': !exists(json, 'deleteList') ? undefined : json['deleteList'],
    };
}

export function DeleteResponseToJSON(value?: DeleteResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deleteList': value.deleteList,
    };
}

