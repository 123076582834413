/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';
import type { UserAllOf } from './UserAllOf';
import {
    UserAllOfFromJSON,
    UserAllOfFromJSONTyped,
    UserAllOfToJSON,
} from './UserAllOf';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    googleId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isVerified?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    gender?: UserGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    birthday?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    postalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    nickname?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isSetupCompleted?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    appleId?: string | null;
}


/**
 * @export
 */
export const UserGenderEnum = {
    Male: 'MALE',
    Female: 'FEMALE',
    Unknown: 'UNKNOWN'
} as const;
export type UserGenderEnum = typeof UserGenderEnum[keyof typeof UserGenderEnum];


/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'googleId': !exists(json, 'googleId') ? undefined : json['googleId'],
        'isVerified': !exists(json, 'isVerified') ? undefined : json['isVerified'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'birthday': !exists(json, 'birthday') ? undefined : json['birthday'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'nickname': !exists(json, 'nickname') ? undefined : json['nickname'],
        'isSetupCompleted': !exists(json, 'isSetupCompleted') ? undefined : json['isSetupCompleted'],
        'appleId': !exists(json, 'appleId') ? undefined : json['appleId'],
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'email': value.email,
        'googleId': value.googleId,
        'isVerified': value.isVerified,
        'gender': value.gender,
        'birthday': value.birthday,
        'postalCode': value.postalCode,
        'address': value.address,
        'nickname': value.nickname,
        'isSetupCompleted': value.isSetupCompleted,
        'appleId': value.appleId,
    };
}

