import React from "react";
import { AvatarCustom, TextBlockCustom } from "@/components/display";
import { COLORS } from "@/constants";
import { Grid } from "@mui/material";
import { BootstrapDialogCustom } from "@/components/dialog";
import {
  getTextIrregularRubellaAntibody,
  toJapanDate,
  toTwoDigits,
} from "@/utils";
import {
  getTextCervicalCancerScreening,
  getTextEdemaStatus,
  getTextListIrregularAntibody,
  getTextObstetricPathologies,
} from "@/utils";
import { BabyBloodRhEnum, BabyBloodTypeEnum, PregnantDiagnosis } from "@/api";

interface IDialogPregnancyDiagnosisRecordProps {
  openDialog?: boolean;
  onCloseDialog?: () => void;
  pregnancyDiagnosisItem?: PregnantDiagnosis;
}

export const DialogPregnancyDiagnosisRecord = (
  props: React.PropsWithChildren<IDialogPregnancyDiagnosisRecordProps>
) => {
  const { openDialog, onCloseDialog, pregnancyDiagnosisItem } = props;

  return (
    <BootstrapDialogCustom
      openDialog={openDialog}
      onCloseDialog={onCloseDialog}
    >
      <Grid container spacing={6}>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextBlockCustom
                classText="font-bold"
                textHeader="妊婦健診の記録 "
                headerColor={COLORS.primary}
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="診察日"
                headerColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  pregnancyDiagnosisItem?.date
                    ? toJapanDate(pregnancyDiagnosisItem?.date)
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="施設名または担当者名"
                headerColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={pregnancyDiagnosisItem?.examinationPlace}
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="妊娠週数"
                headerColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  pregnancyDiagnosisItem?.prenancyWeeks &&
                  pregnancyDiagnosisItem?.prenancyDays
                    ? `${toTwoDigits(
                        pregnancyDiagnosisItem?.prenancyWeeks
                      )}週 ${toTwoDigits(
                        pregnancyDiagnosisItem?.prenancyDays
                      )}日`
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="子宮底長"
                headerColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  pregnancyDiagnosisItem?.uterineCavityLength
                    ? `${toTwoDigits(
                        pregnancyDiagnosisItem?.uterineCavityLength
                      )}cm`
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="腹囲"
                headerColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  pregnancyDiagnosisItem?.waistCircumference
                    ? `${toTwoDigits(
                        pregnancyDiagnosisItem?.waistCircumference
                      )}cm`
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="体重"
                headerColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  pregnancyDiagnosisItem?.bodyWeight
                    ? `${toTwoDigits(pregnancyDiagnosisItem.bodyWeight)}kg`
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="血圧"
                headerColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  (!!pregnancyDiagnosisItem?.bloodPressureMax
                    ? toTwoDigits(pregnancyDiagnosisItem?.bloodPressureMax)
                    : "") +
                  (!!pregnancyDiagnosisItem?.bloodPressureMax ||
                  !!pregnancyDiagnosisItem?.bloodPressureMin
                    ? "/"
                    : "") +
                  (pregnancyDiagnosisItem?.bloodPressureMin !== undefined
                    ? toTwoDigits(pregnancyDiagnosisItem?.bloodPressureMin)
                    : "")
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="浮腫"
                headerColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  pregnancyDiagnosisItem?.edemaStatus
                    ? getTextEdemaStatus(pregnancyDiagnosisItem?.edemaStatus)
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="尿蛋白"
                headerColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  pregnancyDiagnosisItem?.urineProtein
                    ? getTextEdemaStatus(pregnancyDiagnosisItem?.urineProtein)
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="font-bold"
                textHeader="その他の検査"
                headerColor={COLORS.primary}
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="推定胎児体重（EFW）"
                headerColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  pregnancyDiagnosisItem?.fetalWeight
                    ? `${toTwoDigits(pregnancyDiagnosisItem?.fetalWeight)}g`
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="font-bold"
                textHeader="特記事項"
                headerColor={COLORS.primary}
              />
            </Grid>

            <Grid item xs={12}>
              {pregnancyDiagnosisItem?.obstetricPathologies &&
                pregnancyDiagnosisItem?.obstetricPathologies
                  ?.split(",")
                  .map(
                    (item, index) =>
                      !!item && (
                        <TextBlockCustom
                          key={"obstetricPathologies_" + index}
                          classText="font-medium mb-2"
                          textHeader={getTextObstetricPathologies(item)}
                        />
                      )
                  )}
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="その他"
                headerColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  pregnancyDiagnosisItem?.obstetricPathologiesNote
                    ? pregnancyDiagnosisItem?.obstetricPathologiesNote
                    : ""
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextBlockCustom
                classText="font-bold"
                textHeader="検査の記録"
                headerColor={COLORS.primary}
              />
            </Grid>

            {pregnancyDiagnosisItem?.bloodType &&
              pregnancyDiagnosisItem?.bloodType !==
                BabyBloodTypeEnum.Unknown && (
                <Grid item xs={12}>
                  <TextBlockCustom
                    classText="mb-2"
                    textHeader="血液型"
                    headerColor={COLORS.approxGrey}
                  />

                  <TextBlockCustom
                    classText="font-medium mb-2"
                    textHeader={pregnancyDiagnosisItem?.bloodType + "型"}
                  />

                  {pregnancyDiagnosisItem?.bloodRh !==
                    BabyBloodRhEnum.Unknown && (
                    <>
                      <TextBlockCustom
                        classText="mb-2"
                        textHeader="Rh"
                        headerColor={COLORS.approxGrey}
                      />

                      <TextBlockCustom
                        classText="font-medium"
                        textHeader={
                          pregnancyDiagnosisItem?.bloodRh
                            ? pregnancyDiagnosisItem?.bloodRh
                            : ""
                        }
                      />
                    </>
                  )}
                </Grid>
              )}

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="不規則抗体"
                headerColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                classText="font-medium mb-2"
                textHeader={
                  pregnancyDiagnosisItem?.irregularAntibodyCheck
                    ? getTextListIrregularAntibody(
                        pregnancyDiagnosisItem?.irregularAntibody
                      )
                    : ""
                }
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  pregnancyDiagnosisItem?.irregularAntibodyCheck &&
                  pregnancyDiagnosisItem?.irregularAntibodyRemark
                    ? pregnancyDiagnosisItem?.irregularAntibodyRemark
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="血算検査"
                headerColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                classText="mb-2"
                textHeader="ヘモグロビン"
                headerColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  pregnancyDiagnosisItem?.bloodHemoglobin?.toString()
                    ? `${pregnancyDiagnosisItem?.bloodHemoglobin}g/dl`
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="ヘマトクリット"
                headerColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  pregnancyDiagnosisItem?.bloodHematocrit?.toString()
                    ? `${pregnancyDiagnosisItem?.bloodHematocrit}%`
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="血小板"
                headerColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                classText="font-medium mb-2"
                textHeader={
                  pregnancyDiagnosisItem?.completeBloodCount &&
                  pregnancyDiagnosisItem?.bloodPlatelet?.toString()
                    ? `${pregnancyDiagnosisItem?.bloodPlatelet + "万/μl"}`
                    : ""
                }
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  pregnancyDiagnosisItem?.completeBloodCount &&
                  pregnancyDiagnosisItem?.bloodRemark
                    ? `${pregnancyDiagnosisItem?.bloodRemark}`
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="子宮頸がん検査"
                headerColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                classText="font-medium mb-2"
                textHeader={
                  pregnancyDiagnosisItem?.cervicalCancerScreeningCheck &&
                  pregnancyDiagnosisItem?.cervicalCancerScreening
                    ? `${getTextCervicalCancerScreening(
                        pregnancyDiagnosisItem?.cervicalCancerScreening
                      )}`
                    : ""
                }
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  pregnancyDiagnosisItem?.cervicalCancerScreeningCheck &&
                  pregnancyDiagnosisItem?.cervicalCancerScreeningRemark
                    ? `${pregnancyDiagnosisItem?.cervicalCancerScreeningRemark}`
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="梅毒血清反応"
                headerColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                classText="font-medium mb-2"
                textHeader={
                  pregnancyDiagnosisItem?.syphilisSerumReactionCheck &&
                  pregnancyDiagnosisItem?.syphilisSerumReaction
                    ? getTextListIrregularAntibody(
                        pregnancyDiagnosisItem?.syphilisSerumReaction
                      )
                    : ""
                }
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  pregnancyDiagnosisItem?.syphilisSerumReactionCheck &&
                  pregnancyDiagnosisItem?.syphilisSerumReactionRemark
                    ? pregnancyDiagnosisItem?.syphilisSerumReactionRemark
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="HBS抗原（B型肝炎抗原検査）"
                headerColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                classText="font-medium mb-2"
                textHeader={
                  pregnancyDiagnosisItem?.hbsAntibodyCheck &&
                  pregnancyDiagnosisItem?.hbsAntibody
                    ? getTextListIrregularAntibody(
                        pregnancyDiagnosisItem?.hbsAntibody
                      )
                    : ""
                }
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  pregnancyDiagnosisItem?.hbsAntibodyCheck &&
                  pregnancyDiagnosisItem?.hbsAntibodyRemark
                    ? pregnancyDiagnosisItem?.hbsAntibodyRemark
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="HCV抗体（C型肝炎抗体検査）"
                headerColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                classText="font-medium mb-2"
                textHeader={
                  pregnancyDiagnosisItem?.hcvAntibodyCheck &&
                  pregnancyDiagnosisItem?.hcvAntibody
                    ? getTextListIrregularAntibody(
                        pregnancyDiagnosisItem?.hcvAntibody
                      )
                    : ""
                }
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  pregnancyDiagnosisItem?.hcvAntibodyCheck &&
                  pregnancyDiagnosisItem?.hcvAntibodyRemark
                    ? pregnancyDiagnosisItem?.hcvAntibodyRemark
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="HIV抗体"
                headerColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                classText="font-medium mb-2"
                textHeader={
                  pregnancyDiagnosisItem?.hivAntibodyCheck &&
                  pregnancyDiagnosisItem?.hivAntibody
                    ? getTextListIrregularAntibody(
                        pregnancyDiagnosisItem?.hivAntibody
                      )
                    : ""
                }
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  pregnancyDiagnosisItem?.hivAntibodyCheck &&
                  pregnancyDiagnosisItem?.hivAntibodyRemark
                    ? pregnancyDiagnosisItem?.hivAntibodyRemark
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="風しんウイルス抗体"
                headerColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                classText="font-medium mb-2"
                textHeader={
                  pregnancyDiagnosisItem?.rubellaVirusAntibodyCheck &&
                  pregnancyDiagnosisItem?.rubellaVirusAntibody &&
                  getTextIrregularRubellaAntibody(
                    pregnancyDiagnosisItem?.rubellaVirusAntibody
                  )
                }
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  pregnancyDiagnosisItem?.rubellaVirusAntibodyCheck &&
                  pregnancyDiagnosisItem?.rubellaVirusAntibodyRemark
                    ? pregnancyDiagnosisItem?.rubellaVirusAntibodyRemark
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="HTLV-1抗体"
                headerColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                classText="font-medium mb-2"
                textHeader={
                  pregnancyDiagnosisItem?.htlv1AntibodyCheck &&
                  pregnancyDiagnosisItem?.htlv1Antibody
                    ? getTextListIrregularAntibody(
                        pregnancyDiagnosisItem?.htlv1Antibody
                      )
                    : ""
                }
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  pregnancyDiagnosisItem?.htlv1AntibodyCheck &&
                  pregnancyDiagnosisItem?.htlv1AntibodyRemark
                    ? pregnancyDiagnosisItem?.htlv1AntibodyRemark
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="クラミジア抗原"
                headerColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                classText="font-medium mb-2"
                textHeader={
                  pregnancyDiagnosisItem?.chlamydiaAntigenCheck &&
                  pregnancyDiagnosisItem?.chlamydiaAntigen
                    ? getTextListIrregularAntibody(
                        pregnancyDiagnosisItem?.chlamydiaAntigen
                      )
                    : ""
                }
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  pregnancyDiagnosisItem?.chlamydiaAntigenCheck &&
                  pregnancyDiagnosisItem?.chlamydiaAntigenRemark
                    ? pregnancyDiagnosisItem?.chlamydiaAntigenRemark
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="B群溶血性連鎖球菌"
                headerColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                classText="font-medium mb-2"
                textHeader={
                  pregnancyDiagnosisItem?.groupBHemolyticStreptococcusCheck &&
                  pregnancyDiagnosisItem?.groupBHemolyticStreptococcus
                    ? getTextListIrregularAntibody(
                        pregnancyDiagnosisItem?.groupBHemolyticStreptococcus
                      )
                    : ""
                }
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  pregnancyDiagnosisItem?.groupBHemolyticStreptococcusCheck &&
                  pregnancyDiagnosisItem?.groupBHemolyticStreptococcusRemark
                    ? pregnancyDiagnosisItem?.groupBHemolyticStreptococcusRemark
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="メモ"
                headerColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  pregnancyDiagnosisItem?.note
                    ? pregnancyDiagnosisItem?.note
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="写真"
                headerColor={COLORS.approxGrey}
              />

              <AvatarCustom imgSrc={pregnancyDiagnosisItem?.photoUrls} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </BootstrapDialogCustom>
  );
};
