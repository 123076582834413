/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';
import type { InfantFourYearOldAllOf } from './InfantFourYearOldAllOf';
import {
    InfantFourYearOldAllOfFromJSON,
    InfantFourYearOldAllOfFromJSONTyped,
    InfantFourYearOldAllOfToJSON,
} from './InfantFourYearOldAllOf';

/**
 * 
 * @export
 * @interface InfantFourYearOld
 */
export interface InfantFourYearOld {
    /**
     * 
     * @type {number}
     * @memberof InfantFourYearOld
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof InfantFourYearOld
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof InfantFourYearOld
     */
    babyId?: number;
    /**
     * 
     * @type {number}
     * @memberof InfantFourYearOld
     */
    ageId?: number;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    implementationDate?: string;
    /**
     * 
     * @type {number}
     * @memberof InfantFourYearOld
     */
    year?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantFourYearOld
     */
    month?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    photo1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    photo2?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InfantFourYearOld
     */
    bodyWeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantFourYearOld
     */
    height?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantFourYearOld
     */
    kaupIndex?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantFourYearOld
     */
    headCircumference?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    nutritionalStatus?: InfantFourYearOldNutritionalStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    eyeAbnormalities?: InfantFourYearOldEyeAbnormalitiesEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    eyeAbnormalitiesNote?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InfantFourYearOld
     */
    sightLeft?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantFourYearOld
     */
    sightRight?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    earAbnormalities?: InfantFourYearOldEarAbnormalitiesEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    earAbnormalitiesNote?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    healthObservation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    remarks?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    facilityOrPersonName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    dayCaregiver?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    cariesRequiringTreatment?: InfantFourYearOldCariesRequiringTreatmentEnum;
    /**
     * 
     * @type {number}
     * @memberof InfantFourYearOld
     */
    milkTeeth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantFourYearOld
     */
    permanentTeeth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantFourYearOld
     */
    total?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    dirtyTeeth?: InfantFourYearOldDirtyTeethEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    gingivaMucousMembrane?: InfantFourYearOldGingivaMucousMembraneEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    gingivaMucousMembraneNote?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    engagement?: InfantFourYearOldEngagementEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    examinationDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    rightMolarsMaxilla?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    leftFrontToothMaxilla?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    rightMolarsLowerJaw?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    leftFrontToothLowerJaw?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    urineProtein?: InfantFourYearOldUrineProteinEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    urineSugar?: InfantFourYearOldUrineSugarEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    urineOccultBlood?: InfantFourYearOldUrineOccultBloodEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    whiteBloodCells?: InfantFourYearOldWhiteBloodCellsEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    parentMessage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    recordingDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    jumpStaircase?: InfantFourYearOldJumpStaircaseEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    jumpWithOneFoot?: InfantFourYearOldJumpWithOneFootEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    tellParentYourExp?: InfantFourYearOldTellParentYourExpEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    drawCross?: InfantFourYearOldDrawCrossEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    useScissors?: InfantFourYearOldUseScissorsEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    takeOffClothes?: InfantFourYearOldTakeOffClothesEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    playWithFriends?: InfantFourYearOldPlayWithFriendsEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    washYourMouth?: InfantFourYearOldWashYourMouthEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    brushYourTeeth?: InfantFourYearOldBrushYourTeethEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    suckYourFingers?: InfantFourYearOldSuckYourFingersEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    likeOrDislikeFood?: InfantFourYearOldLikeOrDislikeFoodEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    likeOrDislikeFoodNote?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    peeAlone?: InfantFourYearOldPeeAloneEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    childRearing?: InfantFourYearOldChildRearingEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    raisingChildren?: InfantFourYearOldRaisingChildrenEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    yourGrowth?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFourYearOld
     */
    photo3?: string | null;
}


/**
 * @export
 */
export const InfantFourYearOldNutritionalStatusEnum = {
    ALittleFluffy: 'aLittleFluffy',
    Usually: 'usually',
    Skinny: 'skinny'
} as const;
export type InfantFourYearOldNutritionalStatusEnum = typeof InfantFourYearOldNutritionalStatusEnum[keyof typeof InfantFourYearOldNutritionalStatusEnum];

/**
 * @export
 */
export const InfantFourYearOldEyeAbnormalitiesEnum = {
    None: 'none',
    CanBe: 'canBe',
    Suspicion: 'suspicion'
} as const;
export type InfantFourYearOldEyeAbnormalitiesEnum = typeof InfantFourYearOldEyeAbnormalitiesEnum[keyof typeof InfantFourYearOldEyeAbnormalitiesEnum];

/**
 * @export
 */
export const InfantFourYearOldEarAbnormalitiesEnum = {
    None: 'none',
    CanBe: 'canBe',
    Suspicion: 'suspicion'
} as const;
export type InfantFourYearOldEarAbnormalitiesEnum = typeof InfantFourYearOldEarAbnormalitiesEnum[keyof typeof InfantFourYearOldEarAbnormalitiesEnum];

/**
 * @export
 */
export const InfantFourYearOldCariesRequiringTreatmentEnum = {
    None: 'none',
    CanBe: 'canBe',
    NotClear: 'notClear'
} as const;
export type InfantFourYearOldCariesRequiringTreatmentEnum = typeof InfantFourYearOldCariesRequiringTreatmentEnum[keyof typeof InfantFourYearOldCariesRequiringTreatmentEnum];

/**
 * @export
 */
export const InfantFourYearOldDirtyTeethEnum = {
    Beautiful: 'beautiful',
    Few: 'few',
    Many: 'many'
} as const;
export type InfantFourYearOldDirtyTeethEnum = typeof InfantFourYearOldDirtyTeethEnum[keyof typeof InfantFourYearOldDirtyTeethEnum];

/**
 * @export
 */
export const InfantFourYearOldGingivaMucousMembraneEnum = {
    NoAbnormality: 'noAbnormality',
    CanBe: 'canBe',
    NotClear: 'notClear'
} as const;
export type InfantFourYearOldGingivaMucousMembraneEnum = typeof InfantFourYearOldGingivaMucousMembraneEnum[keyof typeof InfantFourYearOldGingivaMucousMembraneEnum];

/**
 * @export
 */
export const InfantFourYearOldEngagementEnum = {
    Good: 'good',
    FollowUp: 'followUp',
    NotClear: 'notClear'
} as const;
export type InfantFourYearOldEngagementEnum = typeof InfantFourYearOldEngagementEnum[keyof typeof InfantFourYearOldEngagementEnum];

/**
 * @export
 */
export const InfantFourYearOldUrineProteinEnum = {
    Minus: 'minus',
    PlusAndMinus: 'plusAndMinus',
    Plus: 'plus',
    DoublePlus: 'doublePlus'
} as const;
export type InfantFourYearOldUrineProteinEnum = typeof InfantFourYearOldUrineProteinEnum[keyof typeof InfantFourYearOldUrineProteinEnum];

/**
 * @export
 */
export const InfantFourYearOldUrineSugarEnum = {
    Minus: 'minus',
    PlusAndMinus: 'plusAndMinus',
    Plus: 'plus',
    DoublePlus: 'doublePlus'
} as const;
export type InfantFourYearOldUrineSugarEnum = typeof InfantFourYearOldUrineSugarEnum[keyof typeof InfantFourYearOldUrineSugarEnum];

/**
 * @export
 */
export const InfantFourYearOldUrineOccultBloodEnum = {
    Minus: 'minus',
    PlusAndMinus: 'plusAndMinus',
    Plus: 'plus',
    DoublePlus: 'doublePlus'
} as const;
export type InfantFourYearOldUrineOccultBloodEnum = typeof InfantFourYearOldUrineOccultBloodEnum[keyof typeof InfantFourYearOldUrineOccultBloodEnum];

/**
 * @export
 */
export const InfantFourYearOldWhiteBloodCellsEnum = {
    Minus: 'minus',
    PlusAndMinus: 'plusAndMinus',
    Plus: 'plus',
    DoublePlus: 'doublePlus'
} as const;
export type InfantFourYearOldWhiteBloodCellsEnum = typeof InfantFourYearOldWhiteBloodCellsEnum[keyof typeof InfantFourYearOldWhiteBloodCellsEnum];

/**
 * @export
 */
export const InfantFourYearOldJumpStaircaseEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantFourYearOldJumpStaircaseEnum = typeof InfantFourYearOldJumpStaircaseEnum[keyof typeof InfantFourYearOldJumpStaircaseEnum];

/**
 * @export
 */
export const InfantFourYearOldJumpWithOneFootEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantFourYearOldJumpWithOneFootEnum = typeof InfantFourYearOldJumpWithOneFootEnum[keyof typeof InfantFourYearOldJumpWithOneFootEnum];

/**
 * @export
 */
export const InfantFourYearOldTellParentYourExpEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantFourYearOldTellParentYourExpEnum = typeof InfantFourYearOldTellParentYourExpEnum[keyof typeof InfantFourYearOldTellParentYourExpEnum];

/**
 * @export
 */
export const InfantFourYearOldDrawCrossEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantFourYearOldDrawCrossEnum = typeof InfantFourYearOldDrawCrossEnum[keyof typeof InfantFourYearOldDrawCrossEnum];

/**
 * @export
 */
export const InfantFourYearOldUseScissorsEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantFourYearOldUseScissorsEnum = typeof InfantFourYearOldUseScissorsEnum[keyof typeof InfantFourYearOldUseScissorsEnum];

/**
 * @export
 */
export const InfantFourYearOldTakeOffClothesEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantFourYearOldTakeOffClothesEnum = typeof InfantFourYearOldTakeOffClothesEnum[keyof typeof InfantFourYearOldTakeOffClothesEnum];

/**
 * @export
 */
export const InfantFourYearOldPlayWithFriendsEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantFourYearOldPlayWithFriendsEnum = typeof InfantFourYearOldPlayWithFriendsEnum[keyof typeof InfantFourYearOldPlayWithFriendsEnum];

/**
 * @export
 */
export const InfantFourYearOldWashYourMouthEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantFourYearOldWashYourMouthEnum = typeof InfantFourYearOldWashYourMouthEnum[keyof typeof InfantFourYearOldWashYourMouthEnum];

/**
 * @export
 */
export const InfantFourYearOldBrushYourTeethEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantFourYearOldBrushYourTeethEnum = typeof InfantFourYearOldBrushYourTeethEnum[keyof typeof InfantFourYearOldBrushYourTeethEnum];

/**
 * @export
 */
export const InfantFourYearOldSuckYourFingersEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantFourYearOldSuckYourFingersEnum = typeof InfantFourYearOldSuckYourFingersEnum[keyof typeof InfantFourYearOldSuckYourFingersEnum];

/**
 * @export
 */
export const InfantFourYearOldLikeOrDislikeFoodEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantFourYearOldLikeOrDislikeFoodEnum = typeof InfantFourYearOldLikeOrDislikeFoodEnum[keyof typeof InfantFourYearOldLikeOrDislikeFoodEnum];

/**
 * @export
 */
export const InfantFourYearOldPeeAloneEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantFourYearOldPeeAloneEnum = typeof InfantFourYearOldPeeAloneEnum[keyof typeof InfantFourYearOldPeeAloneEnum];

/**
 * @export
 */
export const InfantFourYearOldChildRearingEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantFourYearOldChildRearingEnum = typeof InfantFourYearOldChildRearingEnum[keyof typeof InfantFourYearOldChildRearingEnum];

/**
 * @export
 */
export const InfantFourYearOldRaisingChildrenEnum = {
    No: 'no',
    Yes: 'yes',
    CanNotSayAnything: 'canNotSayAnything'
} as const;
export type InfantFourYearOldRaisingChildrenEnum = typeof InfantFourYearOldRaisingChildrenEnum[keyof typeof InfantFourYearOldRaisingChildrenEnum];


/**
 * Check if a given object implements the InfantFourYearOld interface.
 */
export function instanceOfInfantFourYearOld(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InfantFourYearOldFromJSON(json: any): InfantFourYearOld {
    return InfantFourYearOldFromJSONTyped(json, false);
}

export function InfantFourYearOldFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfantFourYearOld {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'babyId': !exists(json, 'babyId') ? undefined : json['babyId'],
        'ageId': !exists(json, 'ageId') ? undefined : json['ageId'],
        'implementationDate': !exists(json, 'implementationDate') ? undefined : json['implementationDate'],
        'year': !exists(json, 'year') ? undefined : json['year'],
        'month': !exists(json, 'month') ? undefined : json['month'],
        'photo1': !exists(json, 'photo1') ? undefined : json['photo1'],
        'photo2': !exists(json, 'photo2') ? undefined : json['photo2'],
        'bodyWeight': !exists(json, 'bodyWeight') ? undefined : json['bodyWeight'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'kaupIndex': !exists(json, 'kaupIndex') ? undefined : json['kaupIndex'],
        'headCircumference': !exists(json, 'headCircumference') ? undefined : json['headCircumference'],
        'nutritionalStatus': !exists(json, 'nutritionalStatus') ? undefined : json['nutritionalStatus'],
        'eyeAbnormalities': !exists(json, 'eyeAbnormalities') ? undefined : json['eyeAbnormalities'],
        'eyeAbnormalitiesNote': !exists(json, 'eyeAbnormalitiesNote') ? undefined : json['eyeAbnormalitiesNote'],
        'sightLeft': !exists(json, 'sightLeft') ? undefined : json['sightLeft'],
        'sightRight': !exists(json, 'sightRight') ? undefined : json['sightRight'],
        'earAbnormalities': !exists(json, 'earAbnormalities') ? undefined : json['earAbnormalities'],
        'earAbnormalitiesNote': !exists(json, 'earAbnormalitiesNote') ? undefined : json['earAbnormalitiesNote'],
        'healthObservation': !exists(json, 'healthObservation') ? undefined : json['healthObservation'],
        'remarks': !exists(json, 'remarks') ? undefined : json['remarks'],
        'facilityOrPersonName': !exists(json, 'facilityOrPersonName') ? undefined : json['facilityOrPersonName'],
        'dayCaregiver': !exists(json, 'dayCaregiver') ? undefined : json['dayCaregiver'],
        'cariesRequiringTreatment': !exists(json, 'cariesRequiringTreatment') ? undefined : json['cariesRequiringTreatment'],
        'milkTeeth': !exists(json, 'milkTeeth') ? undefined : json['milkTeeth'],
        'permanentTeeth': !exists(json, 'permanentTeeth') ? undefined : json['permanentTeeth'],
        'total': !exists(json, 'total') ? undefined : json['total'],
        'dirtyTeeth': !exists(json, 'dirtyTeeth') ? undefined : json['dirtyTeeth'],
        'gingivaMucousMembrane': !exists(json, 'gingivaMucousMembrane') ? undefined : json['gingivaMucousMembrane'],
        'gingivaMucousMembraneNote': !exists(json, 'gingivaMucousMembraneNote') ? undefined : json['gingivaMucousMembraneNote'],
        'engagement': !exists(json, 'engagement') ? undefined : json['engagement'],
        'examinationDate': !exists(json, 'examinationDate') ? undefined : json['examinationDate'],
        'rightMolarsMaxilla': !exists(json, 'rightMolarsMaxilla') ? undefined : json['rightMolarsMaxilla'],
        'leftFrontToothMaxilla': !exists(json, 'leftFrontToothMaxilla') ? undefined : json['leftFrontToothMaxilla'],
        'rightMolarsLowerJaw': !exists(json, 'rightMolarsLowerJaw') ? undefined : json['rightMolarsLowerJaw'],
        'leftFrontToothLowerJaw': !exists(json, 'leftFrontToothLowerJaw') ? undefined : json['leftFrontToothLowerJaw'],
        'urineProtein': !exists(json, 'urineProtein') ? undefined : json['urineProtein'],
        'urineSugar': !exists(json, 'urineSugar') ? undefined : json['urineSugar'],
        'urineOccultBlood': !exists(json, 'urineOccultBlood') ? undefined : json['urineOccultBlood'],
        'whiteBloodCells': !exists(json, 'whiteBloodCells') ? undefined : json['whiteBloodCells'],
        'parentMessage': !exists(json, 'parentMessage') ? undefined : json['parentMessage'],
        'recordingDate': !exists(json, 'recordingDate') ? undefined : json['recordingDate'],
        'jumpStaircase': !exists(json, 'jumpStaircase') ? undefined : json['jumpStaircase'],
        'jumpWithOneFoot': !exists(json, 'jumpWithOneFoot') ? undefined : json['jumpWithOneFoot'],
        'tellParentYourExp': !exists(json, 'tellParentYourExp') ? undefined : json['tellParentYourExp'],
        'drawCross': !exists(json, 'drawCross') ? undefined : json['drawCross'],
        'useScissors': !exists(json, 'useScissors') ? undefined : json['useScissors'],
        'takeOffClothes': !exists(json, 'takeOffClothes') ? undefined : json['takeOffClothes'],
        'playWithFriends': !exists(json, 'playWithFriends') ? undefined : json['playWithFriends'],
        'washYourMouth': !exists(json, 'washYourMouth') ? undefined : json['washYourMouth'],
        'brushYourTeeth': !exists(json, 'brushYourTeeth') ? undefined : json['brushYourTeeth'],
        'suckYourFingers': !exists(json, 'suckYourFingers') ? undefined : json['suckYourFingers'],
        'likeOrDislikeFood': !exists(json, 'likeOrDislikeFood') ? undefined : json['likeOrDislikeFood'],
        'likeOrDislikeFoodNote': !exists(json, 'likeOrDislikeFoodNote') ? undefined : json['likeOrDislikeFoodNote'],
        'peeAlone': !exists(json, 'peeAlone') ? undefined : json['peeAlone'],
        'childRearing': !exists(json, 'childRearing') ? undefined : json['childRearing'],
        'raisingChildren': !exists(json, 'raisingChildren') ? undefined : json['raisingChildren'],
        'yourGrowth': !exists(json, 'yourGrowth') ? undefined : json['yourGrowth'],
        'photo3': !exists(json, 'photo3') ? undefined : json['photo3'],
    };
}

export function InfantFourYearOldToJSON(value?: InfantFourYearOld | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'userId': value.userId,
        'babyId': value.babyId,
        'ageId': value.ageId,
        'implementationDate': value.implementationDate,
        'year': value.year,
        'month': value.month,
        'photo1': value.photo1,
        'photo2': value.photo2,
        'bodyWeight': value.bodyWeight,
        'height': value.height,
        'kaupIndex': value.kaupIndex,
        'headCircumference': value.headCircumference,
        'nutritionalStatus': value.nutritionalStatus,
        'eyeAbnormalities': value.eyeAbnormalities,
        'eyeAbnormalitiesNote': value.eyeAbnormalitiesNote,
        'sightLeft': value.sightLeft,
        'sightRight': value.sightRight,
        'earAbnormalities': value.earAbnormalities,
        'earAbnormalitiesNote': value.earAbnormalitiesNote,
        'healthObservation': value.healthObservation,
        'remarks': value.remarks,
        'facilityOrPersonName': value.facilityOrPersonName,
        'dayCaregiver': value.dayCaregiver,
        'cariesRequiringTreatment': value.cariesRequiringTreatment,
        'milkTeeth': value.milkTeeth,
        'permanentTeeth': value.permanentTeeth,
        'total': value.total,
        'dirtyTeeth': value.dirtyTeeth,
        'gingivaMucousMembrane': value.gingivaMucousMembrane,
        'gingivaMucousMembraneNote': value.gingivaMucousMembraneNote,
        'engagement': value.engagement,
        'examinationDate': value.examinationDate,
        'rightMolarsMaxilla': value.rightMolarsMaxilla,
        'leftFrontToothMaxilla': value.leftFrontToothMaxilla,
        'rightMolarsLowerJaw': value.rightMolarsLowerJaw,
        'leftFrontToothLowerJaw': value.leftFrontToothLowerJaw,
        'urineProtein': value.urineProtein,
        'urineSugar': value.urineSugar,
        'urineOccultBlood': value.urineOccultBlood,
        'whiteBloodCells': value.whiteBloodCells,
        'parentMessage': value.parentMessage,
        'recordingDate': value.recordingDate,
        'jumpStaircase': value.jumpStaircase,
        'jumpWithOneFoot': value.jumpWithOneFoot,
        'tellParentYourExp': value.tellParentYourExp,
        'drawCross': value.drawCross,
        'useScissors': value.useScissors,
        'takeOffClothes': value.takeOffClothes,
        'playWithFriends': value.playWithFriends,
        'washYourMouth': value.washYourMouth,
        'brushYourTeeth': value.brushYourTeeth,
        'suckYourFingers': value.suckYourFingers,
        'likeOrDislikeFood': value.likeOrDislikeFood,
        'likeOrDislikeFoodNote': value.likeOrDislikeFoodNote,
        'peeAlone': value.peeAlone,
        'childRearing': value.childRearing,
        'raisingChildren': value.raisingChildren,
        'yourGrowth': value.yourGrowth,
        'photo3': value.photo3,
    };
}

