/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    HealthOfMomPagination,
    HealthOfMomPaginationFromJSON,
    HealthOfMomPaginationToJSON,
    HealthOfMoms,
    HealthOfMomsFromJSON,
    HealthOfMomsToJSON,
} from '../models';

export interface AdminHealthOfMomsMotherIdGetRequest {
    motherId: number;
}

export interface HealthOfMomsGetRequest {
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface HealthOfMomsIdDeleteRequest {
    id: number;
}

export interface HealthOfMomsIdPutRequest {
    id: number;
    healthOfMoms?: HealthOfMoms;
}

export interface HealthOfMomsPostRequest {
    healthOfMoms?: HealthOfMoms;
}

export interface HealthOfMomsShareDetailGetRequest {
    sourceUserId: number;
}

/**
 * 
 */
export class HealthOfMomsApi extends runtime.BaseAPI {

    /**
     * 
     */
    adminHealthOfMomsMotherIdGetRaw = async (requestParameters: AdminHealthOfMomsMotherIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<HealthOfMoms>> => {
        if (requestParameters.motherId === null || requestParameters.motherId === undefined) {
            throw new runtime.RequiredError('motherId','Required parameter requestParameters.motherId was null or undefined when calling adminHealthOfMomsMotherIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/healthOfMoms/:motherId`.replace(`${":motherId"}`, encodeURIComponent(String(requestParameters.motherId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HealthOfMomsFromJSON(jsonValue));
    }

    /**
     * 
     */
    adminHealthOfMomsMotherIdGet = async (requestParameters: AdminHealthOfMomsMotherIdGetRequest, initOverrides?: RequestInit): Promise<HealthOfMoms> => {
        const response = await this.adminHealthOfMomsMotherIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    healthOfMomsByMotherMotherIdGetRaw = async (initOverrides?: RequestInit): Promise<runtime.ApiResponse<HealthOfMoms>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/healthOfMoms/byMother/:motherId`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HealthOfMomsFromJSON(jsonValue));
    }

    /**
     * 
     */
    healthOfMomsByMotherMotherIdGet = async (initOverrides?: RequestInit): Promise<HealthOfMoms> => {
        const response = await this.healthOfMomsByMotherMotherIdGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    healthOfMomsGetRaw = async (requestParameters: HealthOfMomsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<HealthOfMomPagination>> => {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/healthOfMoms`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HealthOfMomPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    healthOfMomsGet = async (requestParameters: HealthOfMomsGetRequest = {}, initOverrides?: RequestInit): Promise<HealthOfMomPagination> => {
        const response = await this.healthOfMomsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    healthOfMomsIdDeleteRaw = async (requestParameters: HealthOfMomsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling healthOfMomsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/healthOfMoms/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    healthOfMomsIdDelete = async (requestParameters: HealthOfMomsIdDeleteRequest, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.healthOfMomsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    healthOfMomsIdPutRaw = async (requestParameters: HealthOfMomsIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<HealthOfMoms>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling healthOfMomsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/healthOfMoms/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: HealthOfMomsToJSON(requestParameters.healthOfMoms),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HealthOfMomsFromJSON(jsonValue));
    }

    /**
     */
    healthOfMomsIdPut = async (requestParameters: HealthOfMomsIdPutRequest, initOverrides?: RequestInit): Promise<HealthOfMoms> => {
        const response = await this.healthOfMomsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    healthOfMomsPostRaw = async (requestParameters: HealthOfMomsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<HealthOfMoms>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/healthOfMoms`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HealthOfMomsToJSON(requestParameters.healthOfMoms),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HealthOfMomsFromJSON(jsonValue));
    }

    /**
     */
    healthOfMomsPost = async (requestParameters: HealthOfMomsPostRequest = {}, initOverrides?: RequestInit): Promise<HealthOfMoms> => {
        const response = await this.healthOfMomsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    healthOfMomsShareDetailGetRaw = async (requestParameters: HealthOfMomsShareDetailGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<HealthOfMoms>> => {
        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling healthOfMomsShareDetailGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/healthOfMoms/share/detail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HealthOfMomsFromJSON(jsonValue));
    }

    /**
     * 
     */
    healthOfMomsShareDetailGet = async (requestParameters: HealthOfMomsShareDetailGetRequest, initOverrides?: RequestInit): Promise<HealthOfMoms> => {
        const response = await this.healthOfMomsShareDetailGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
