/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RegisterRequest
 */
export interface RegisterRequest {
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    confirmPassword?: string;
    /**
     * 
     * @type {number}
     * @memberof RegisterRequest
     */
    roleId?: number;
    /**
     * 
     * @type {string}
     * @memberof RegisterRequest
     */
    staffName?: string;
}

/**
 * Check if a given object implements the RegisterRequest interface.
 */
export function instanceOfRegisterRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RegisterRequestFromJSON(json: any): RegisterRequest {
    return RegisterRequestFromJSONTyped(json, false);
}

export function RegisterRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'confirmPassword': !exists(json, 'confirmPassword') ? undefined : json['confirmPassword'],
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
        'staffName': !exists(json, 'staffName') ? undefined : json['staffName'],
    };
}

export function RegisterRequestToJSON(value?: RegisterRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'password': value.password,
        'confirmPassword': value.confirmPassword,
        'roleId': value.roleId,
        'staffName': value.staffName,
    };
}

