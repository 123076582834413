/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';
import type { RecordSideEffectAllOf } from './RecordSideEffectAllOf';
import {
    RecordSideEffectAllOfFromJSON,
    RecordSideEffectAllOfFromJSONTyped,
    RecordSideEffectAllOfToJSON,
} from './RecordSideEffectAllOf';

/**
 * 
 * @export
 * @interface RecordSideEffect
 */
export interface RecordSideEffect {
    /**
     * 
     * @type {number}
     * @memberof RecordSideEffect
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RecordSideEffect
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof RecordSideEffect
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof RecordSideEffect
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof RecordSideEffect
     */
    babyId?: number;
    /**
     * 
     * @type {number}
     * @memberof RecordSideEffect
     */
    vaccineId?: number;
    /**
     * 
     * @type {string}
     * @memberof RecordSideEffect
     */
    dayOfSideEffects?: string;
    /**
     * 
     * @type {string}
     * @memberof RecordSideEffect
     */
    sideEffectsDetails?: string;
    /**
     * 
     * @type {string}
     * @memberof RecordSideEffect
     */
    vaccineName?: string;
}

/**
 * Check if a given object implements the RecordSideEffect interface.
 */
export function instanceOfRecordSideEffect(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RecordSideEffectFromJSON(json: any): RecordSideEffect {
    return RecordSideEffectFromJSONTyped(json, false);
}

export function RecordSideEffectFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordSideEffect {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'babyId': !exists(json, 'babyId') ? undefined : json['babyId'],
        'vaccineId': !exists(json, 'vaccineId') ? undefined : json['vaccineId'],
        'dayOfSideEffects': !exists(json, 'dayOfSideEffects') ? undefined : json['dayOfSideEffects'],
        'sideEffectsDetails': !exists(json, 'sideEffectsDetails') ? undefined : json['sideEffectsDetails'],
        'vaccineName': !exists(json, 'vaccineName') ? undefined : json['vaccineName'],
    };
}

export function RecordSideEffectToJSON(value?: RecordSideEffect | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'userId': value.userId,
        'babyId': value.babyId,
        'vaccineId': value.vaccineId,
        'dayOfSideEffects': value.dayOfSideEffects,
        'sideEffectsDetails': value.sideEffectsDetails,
        'vaccineName': value.vaccineName,
    };
}

