/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';
import type { PregnantAllOf } from './PregnantAllOf';
import {
    PregnantAllOfFromJSON,
    PregnantAllOfFromJSONTyped,
    PregnantAllOfToJSON,
} from './PregnantAllOf';

/**
 * 
 * @export
 * @interface Pregnant
 */
export interface Pregnant {
    /**
     * 
     * @type {number}
     * @memberof Pregnant
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Pregnant
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Pregnant
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof Pregnant
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof Pregnant
     */
    caseId?: number;
    /**
     * 
     * @type {string}
     * @memberof Pregnant
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Pregnant
     */
    birthday?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Pregnant
     */
    expectedGiveBirthDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Pregnant
     */
    bloodType?: PregnantBloodTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Pregnant
     */
    bloodRh?: PregnantBloodRhEnum;
    /**
     * 
     * @type {number}
     * @memberof Pregnant
     */
    prePregnancyBodyWeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Pregnant
     */
    prePergnamcyBodyHeight?: number | null;
}


/**
 * @export
 */
export const PregnantBloodTypeEnum = {
    A: 'A',
    B: 'B',
    Ab: 'AB',
    O: 'O',
    Unknown: 'UNKNOWN'
} as const;
export type PregnantBloodTypeEnum = typeof PregnantBloodTypeEnum[keyof typeof PregnantBloodTypeEnum];

/**
 * @export
 */
export const PregnantBloodRhEnum = {
    Plus: '+',
    Minus: '-',
    Unknown: 'UNKNOWN'
} as const;
export type PregnantBloodRhEnum = typeof PregnantBloodRhEnum[keyof typeof PregnantBloodRhEnum];


/**
 * Check if a given object implements the Pregnant interface.
 */
export function instanceOfPregnant(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PregnantFromJSON(json: any): Pregnant {
    return PregnantFromJSONTyped(json, false);
}

export function PregnantFromJSONTyped(json: any, ignoreDiscriminator: boolean): Pregnant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'caseId': !exists(json, 'caseId') ? undefined : json['caseId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'birthday': !exists(json, 'birthday') ? undefined : json['birthday'],
        'expectedGiveBirthDate': !exists(json, 'expectedGiveBirthDate') ? undefined : json['expectedGiveBirthDate'],
        'bloodType': !exists(json, 'bloodType') ? undefined : json['bloodType'],
        'bloodRh': !exists(json, 'bloodRh') ? undefined : json['bloodRh'],
        'prePregnancyBodyWeight': !exists(json, 'prePregnancyBodyWeight') ? undefined : json['prePregnancyBodyWeight'],
        'prePergnamcyBodyHeight': !exists(json, 'prePergnamcyBodyHeight') ? undefined : json['prePergnamcyBodyHeight'],
    };
}

export function PregnantToJSON(value?: Pregnant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'userId': value.userId,
        'caseId': value.caseId,
        'name': value.name,
        'birthday': value.birthday,
        'expectedGiveBirthDate': value.expectedGiveBirthDate,
        'bloodType': value.bloodType,
        'bloodRh': value.bloodRh,
        'prePregnancyBodyWeight': value.prePregnancyBodyWeight,
        'prePergnamcyBodyHeight': value.prePergnamcyBodyHeight,
    };
}

