import { Admin, adminsApi } from "@/api";
import { allRolesState } from "@/constants";
import { translate } from '@/helpers';
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import * as yup from "yup";

const StyledAdminForm = styled.div`
  margin: 0 auto;
  max-width: 400px;
`;

interface IAdminFormValues extends Partial<Admin> { }

interface IAdminFormProps {
  initialValues?: IAdminFormValues;
  onSuccess: () => void;
  onClose: () => void;
}

export const AdminForm = (props: React.PropsWithChildren<IAdminFormProps>) => {
  const isSubmitted = React.useRef(false);

  const [allRoles] = useRecoilState(allRolesState);
  const roleOptions = React.useMemo(
    () => allRoles?.map((role) => ({ label: translate(role.roleName), value: role.id })),
    [allRoles]
  );
  const defaultRoleId = allRoles.find(o => o.roleName === "employee")?.id;

  const [isLoading, setIsLoading] = useState(false);
  const { mutateAsync: createAdmin } = useMutation(adminsApi.adminAdminsPost);
  const { mutateAsync: updateAdmin } = useMutation(adminsApi.adminAdminsIdPut);

  const isCreate = !props.initialValues?.id;

  const validationSchema = React.useMemo(
    () => {
      return yup.object({
        staffName: yup
          .string()
          .max(50, "50文字以内で入力してください")
          .required("社員名入力してください"),
        email: yup
          .string()
          .email("メールアドレスは正しいく入力してください")
          .required("メールアドレスを入力してください"),
        password: isCreate
          ? yup
            .string()
            .min(8, "8文字以上で入力してください")
            .max(16, "16文字以内で入力してください")
            .required("パスワードを入力してください")
          : yup.string()
            .min(8, "8文字以上で入力してください")
            .max(16, "16文字以内で入力してください"),
        roleId: yup.string().required("ロールを選択してください"),
      });
    },
    [isCreate]
  );

  const formik = useFormik<IAdminFormValues>({
    initialValues: {
      roleId: defaultRoleId,
      ...props.initialValues
    },
    validationSchema: validationSchema,
    onSubmit: async (values: IAdminFormValues) => {
      setIsLoading(true);

      try {
        if (values.id) {
          await updateAdmin({
            id: values.id!,
            admin: values,
          });
        } else {
          await createAdmin({
            registerRequest: values,
          });
        }
        props.onSuccess();
      } catch (error) {
        if (error.body) {
          formik.setErrors(error.body);
        }
        throw error;
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <StyledAdminForm>
      <form onSubmit={(e) => {
        isSubmitted.current = true;
        formik.handleSubmit(e);
      }}>
        <div className="mb-8">
          <Typography variant="h5" fontWeight={700} className="text-center">
            {isCreate ? "管理者の追加" : "詳細の編集"}
          </Typography>
        </div>
        <div className="mb-8">
          <div className="mb-4">
            <Typography variant="body1" fontWeight={500} component="label">
              社員名 <span className="text-red-500">※</span>
            </Typography>
          </div>
          <div className="custom-input backgrounded-input">
            <TextField
              variant="outlined"
              size="small"
              fullWidth={true}
              name="staffName"
              value={formik.values.staffName}
              onFocus={(e) => {
                formik.setFieldTouched(e.target.name, true);
              }}
              onChange={formik.handleChange("staffName")}
              onBlur={formik.handleBlur("staffName")}
              error={
                (isSubmitted.current || formik.touched.staffName) && Boolean(formik.errors.staffName)
              }
              helperText={(isSubmitted.current || formik.touched.staffName) && translate(formik.errors.staffName)}
            />
          </div>
        </div>
        <div className="mb-8">
          <div className="mb-4">
            <Typography variant="body1" fontWeight={500} component="label">
              メールアドレス <span className="text-red-500">※</span>
            </Typography>
          </div>
          <div className="custom-input backgrounded-input">
            <TextField
              variant="outlined"
              size="small"
              fullWidth={true}
              name="email"
              value={formik.values.email}
              onFocus={(e) => {
                formik.setFieldTouched(e.target.name, true);
              }}
              onChange={formik.handleChange("email")}
              onBlur={formik.handleBlur("email")}
              error={(isSubmitted.current || formik.touched.email) && Boolean(formik.errors.email)}
              helperText={(isSubmitted.current || formik.touched.email) && translate(formik.errors.email)}
            />
          </div>
        </div>
        <div className="mb-8">
          <div className="mb-4">
            <Typography variant="body1" fontWeight={500} component="label">
              パスワード {isCreate && <span className="text-red-500">※</span>}
            </Typography>
          </div>
          <div className="custom-input backgrounded-input">
            <TextField
              variant="outlined"
              size="small"
              fullWidth={true}
              name="password"
              value={formik.values.password}
              onFocus={(e) => {
                formik.setFieldTouched(e.target.name, true);
              }}
              onChange={formik.handleChange("password")}
              onBlur={formik.handleBlur("password")}
              error={(isSubmitted.current || formik.touched.password) && Boolean(formik.errors.password)}
              helperText={(isSubmitted.current || formik.touched.password) && translate(formik.errors.password)}
            />
          </div>
        </div>
        <div className="mb-12">
          <div className="mb-4">
            <Typography variant="body1" fontWeight={500} component="label">
              役割
            </Typography>
          </div>
          <FormControl
            fullWidth={true}
            className="backgrounded-input custom-select"
          >
            <InputLabel
              id="demo-simple-select-label"
              variant="outlined"
              size="small"
            />
            <Select
              labelId="demo-simple-select-label"
              name="roleId"
              value={formik.values.roleId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur("password")}
              error={(isSubmitted.current || formik.touched.roleId) && Boolean(formik.errors.roleId)}
              defaultValue={defaultRoleId}
            >
              {roleOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            {formik.touched.roleId && (
              <FormHelperText>{formik.errors.roleId}</FormHelperText>
            )}
          </FormControl>
        </div>
        <div className="flex">
          <div className="w-6/12 mr-2 ml-4 c">
            <Button
              variant="outlined"
              size="large"
              className="w-full custom-button"
              type="submit"
              onClick={props.onClose}
            >
              キャンセル
            </Button>
          </div>
          <div className="w-6/12 mr-4 ml-2">
            <Button
              variant="contained"
              size="large"
              className="w-full custom-button"
              type="submit"
              disabled={isLoading}
            >
              保存
            </Button>
          </div>
        </div>
      </form>
    </StyledAdminForm>
  );
};
