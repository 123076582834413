/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MapLocationGetAllAllOf } from './MapLocationGetAllAllOf';
import {
    MapLocationGetAllAllOfFromJSON,
    MapLocationGetAllAllOfFromJSONTyped,
    MapLocationGetAllAllOfToJSON,
} from './MapLocationGetAllAllOf';

/**
 * 
 * @export
 * @interface MapLocationGetAll
 */
export interface MapLocationGetAll {
    /**
     * 
     * @type {string}
     * @memberof MapLocationGetAll
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MapLocationGetAll
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof MapLocationGetAll
     */
    placeId?: string;
    /**
     * 
     * @type {number}
     * @memberof MapLocationGetAll
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof MapLocationGetAll
     */
    longitude?: number;
}

/**
 * Check if a given object implements the MapLocationGetAll interface.
 */
export function instanceOfMapLocationGetAll(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MapLocationGetAllFromJSON(json: any): MapLocationGetAll {
    return MapLocationGetAllFromJSONTyped(json, false);
}

export function MapLocationGetAllFromJSONTyped(json: any, ignoreDiscriminator: boolean): MapLocationGetAll {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'placeId': !exists(json, 'placeId') ? undefined : json['placeId'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
    };
}

export function MapLocationGetAllToJSON(value?: MapLocationGetAll | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'address': value.address,
        'placeId': value.placeId,
        'latitude': value.latitude,
        'longitude': value.longitude,
    };
}

