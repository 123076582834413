/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Pregnant,
    PregnantFromJSON,
    PregnantToJSON,
    PregnantPagination,
    PregnantPaginationFromJSON,
    PregnantPaginationToJSON,
} from '../models';

export interface PregnantShareAllGetRequest {
    sourceUserId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface PregnantsGetRequest {
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface PregnantsIdDeleteRequest {
    id: number;
}

export interface PregnantsIdGetRequest {
    id: number;
}

export interface PregnantsIdPutRequest {
    id: number;
    pregnant?: Pregnant;
}

export interface PregnantsPostRequest {
    pregnant?: Pregnant;
}

/**
 * 
 */
export class PregnantsApi extends runtime.BaseAPI {

    /**
     * 
     */
    pregnantShareAllGetRaw = async (requestParameters: PregnantShareAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PregnantPagination>> => {
        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling pregnantShareAllGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/pregnant/share/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PregnantPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    pregnantShareAllGet = async (requestParameters: PregnantShareAllGetRequest, initOverrides?: RequestInit): Promise<PregnantPagination> => {
        const response = await this.pregnantShareAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    pregnantsGetRaw = async (requestParameters: PregnantsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PregnantPagination>> => {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/pregnants`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PregnantPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    pregnantsGet = async (requestParameters: PregnantsGetRequest = {}, initOverrides?: RequestInit): Promise<PregnantPagination> => {
        const response = await this.pregnantsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    pregnantsIdDeleteRaw = async (requestParameters: PregnantsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pregnantsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/pregnants/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    pregnantsIdDelete = async (requestParameters: PregnantsIdDeleteRequest, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.pregnantsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    pregnantsIdGetRaw = async (requestParameters: PregnantsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Pregnant>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pregnantsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/pregnants/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PregnantFromJSON(jsonValue));
    }

    /**
     * 
     */
    pregnantsIdGet = async (requestParameters: PregnantsIdGetRequest, initOverrides?: RequestInit): Promise<Pregnant> => {
        const response = await this.pregnantsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    pregnantsIdPutRaw = async (requestParameters: PregnantsIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Pregnant>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pregnantsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/pregnants/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PregnantToJSON(requestParameters.pregnant),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PregnantFromJSON(jsonValue));
    }

    /**
     */
    pregnantsIdPut = async (requestParameters: PregnantsIdPutRequest, initOverrides?: RequestInit): Promise<Pregnant> => {
        const response = await this.pregnantsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    pregnantsPostRaw = async (requestParameters: PregnantsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Pregnant>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/pregnants`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PregnantToJSON(requestParameters.pregnant),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PregnantFromJSON(jsonValue));
    }

    /**
     */
    pregnantsPost = async (requestParameters: PregnantsPostRequest = {}, initOverrides?: RequestInit): Promise<Pregnant> => {
        const response = await this.pregnantsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
