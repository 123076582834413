/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';
import type { PregnantInfoAllOf } from './PregnantInfoAllOf';
import {
    PregnantInfoAllOfFromJSON,
    PregnantInfoAllOfFromJSONTyped,
    PregnantInfoAllOfToJSON,
} from './PregnantInfoAllOf';

/**
 * 
 * @export
 * @interface PregnantInfo
 */
export interface PregnantInfo {
    /**
     * 
     * @type {number}
     * @memberof PregnantInfo
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PregnantInfo
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof PregnantInfo
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof PregnantInfo
     */
    motherId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PregnantInfo
     */
    healthOfMomId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PregnantInfo
     */
    year?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PregnantInfo
     */
    month?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PregnantInfo
     */
    childbirthStatus?: PregnantInfoChildbirthStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof PregnantInfo
     */
    pregnancy?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PregnantInfo
     */
    weight?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PregnantInfo
     */
    gender?: PregnantInfoGenderEnum;
}


/**
 * @export
 */
export const PregnantInfoChildbirthStatusEnum = {
    CaesareanSection: 'CAESAREAN_SECTION',
    SuctionDelivery: 'SUCTION_DELIVERY',
    NormalDelivery: 'NORMAL_DELIVERY'
} as const;
export type PregnantInfoChildbirthStatusEnum = typeof PregnantInfoChildbirthStatusEnum[keyof typeof PregnantInfoChildbirthStatusEnum];

/**
 * @export
 */
export const PregnantInfoGenderEnum = {
    Male: 'MALE',
    Female: 'FEMALE'
} as const;
export type PregnantInfoGenderEnum = typeof PregnantInfoGenderEnum[keyof typeof PregnantInfoGenderEnum];


/**
 * Check if a given object implements the PregnantInfo interface.
 */
export function instanceOfPregnantInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PregnantInfoFromJSON(json: any): PregnantInfo {
    return PregnantInfoFromJSONTyped(json, false);
}

export function PregnantInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PregnantInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'motherId': !exists(json, 'motherId') ? undefined : json['motherId'],
        'healthOfMomId': !exists(json, 'healthOfMomId') ? undefined : json['healthOfMomId'],
        'year': !exists(json, 'year') ? undefined : json['year'],
        'month': !exists(json, 'month') ? undefined : json['month'],
        'childbirthStatus': !exists(json, 'childbirthStatus') ? undefined : json['childbirthStatus'],
        'pregnancy': !exists(json, 'pregnancy') ? undefined : json['pregnancy'],
        'weight': !exists(json, 'weight') ? undefined : json['weight'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
    };
}

export function PregnantInfoToJSON(value?: PregnantInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'motherId': value.motherId,
        'healthOfMomId': value.healthOfMomId,
        'year': value.year,
        'month': value.month,
        'childbirthStatus': value.childbirthStatus,
        'pregnancy': value.pregnancy,
        'weight': value.weight,
        'gender': value.gender,
    };
}

