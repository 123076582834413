import React from "react";
import { TextBlockCustom } from "@/components/display";
import { Grid } from "@mui/material";
import { COLORS } from "@/constants";
import { infantEarlyNeonatalPeriodsApi, InfantMedicalExam } from "@/api";
import { useQuery } from "react-query";
import { toJapanDate } from "@/utils";
import { translate } from "@/helpers";

interface IInfantEarlyNeonatalPeriodDetailProps {
  infantMedicalExamItem?: InfantMedicalExam;
}

export const InfantEarlyNeonatalPeriodDetail = (
  props: React.PropsWithChildren<IInfantEarlyNeonatalPeriodDetailProps>
) => {
  const { infantMedicalExamItem } = props;

  //--- Get medical consultant
  const { data: infantEarlyNeonatalPeriod } = useQuery({
    queryKey: "GET_INFANT_EARLY_NEONATAL_PERIOD_BY_ID",
    queryFn: () => {
      return infantEarlyNeonatalPeriodsApi.adminInfantEarlyNeonatalPeriodsIdGet(
        {
          id: infantMedicalExamItem?.infantId,
          userId: infantMedicalExamItem?.userId,
        }
      );
    },
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextBlockCustom
          classText="font-bold text-center mb-4"
          textHeader={`${translate("infantMedicalExam.text.age2")} ${translate(
            "infantMedicalExam.text.detail"
          )}`}
          textSize={18}
          headerColor={COLORS.primary}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          {/* 1回目 */}
          <Grid item xs={12}>
            <TextBlockCustom
              classText="font-bold"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.firstTime"
              )}
              headerColor={COLORS.primary}
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.age")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantEarlyNeonatalPeriod?.age1
                  ? infantEarlyNeonatalPeriod?.age1?.toString() + "日"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.bodyWeight"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantEarlyNeonatalPeriod?.bodyWeight1
                  ? infantEarlyNeonatalPeriod?.bodyWeight1?.toString() + "g"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.breastFeedingPower"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantEarlyNeonatalPeriod?.breastFeedingPower1
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantEarlyNeonatalPeriod?.breastFeedingPower1
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.nutritionMethod"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantEarlyNeonatalPeriod?.nutritionMethod1
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantEarlyNeonatalPeriod?.nutritionMethod1
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.other")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantEarlyNeonatalPeriod?.others1
                  ? infantEarlyNeonatalPeriod?.others1
                  : ""
              }
            />
          </Grid>

          {/* 2回目 */}
          <Grid item xs={12}>
            <TextBlockCustom
              classText="font-bold"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.secondTime"
              )}
              headerColor={COLORS.primary}
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.age")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantEarlyNeonatalPeriod?.age2
                  ? infantEarlyNeonatalPeriod?.age2?.toString() + "日"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.bodyWeight"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantEarlyNeonatalPeriod?.bodyWeight2
                  ? infantEarlyNeonatalPeriod?.bodyWeight2?.toString() + "g"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.breastFeedingPower"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantEarlyNeonatalPeriod?.breastFeedingPower2
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantEarlyNeonatalPeriod?.breastFeedingPower2
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.nutritionMethod"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantEarlyNeonatalPeriod?.nutritionMethod2
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantEarlyNeonatalPeriod?.nutritionMethod2
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.other")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantEarlyNeonatalPeriod?.others2
                  ? infantEarlyNeonatalPeriod?.others2
                  : ""
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          {/* 退院時の記録 */}
          <Grid item xs={12}>
            <TextBlockCustom
              classText="font-bold"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.recordDischarge"
              )}
              headerColor={COLORS.primary}
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.implementationDate"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantEarlyNeonatalPeriod?.implementationDate
                  ? toJapanDate(infantEarlyNeonatalPeriod?.implementationDate)
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.age")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantEarlyNeonatalPeriod?.age3
                  ? infantEarlyNeonatalPeriod?.age3.toString() + "日"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.bodyWeight"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantEarlyNeonatalPeriod?.bodyWeight3
                  ? infantEarlyNeonatalPeriod?.bodyWeight3.toString() + "g"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.height")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantEarlyNeonatalPeriod?.height
                  ? infantEarlyNeonatalPeriod?.height + "cm"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.chestCircumference"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantEarlyNeonatalPeriod?.chestCircumference
                  ? infantEarlyNeonatalPeriod?.chestCircumference + "cm"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.headCircumference"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantEarlyNeonatalPeriod?.headCircumference
                  ? infantEarlyNeonatalPeriod?.headCircumference + "cm"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.nutritionMethod"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantEarlyNeonatalPeriod?.nutritionMethod3
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantEarlyNeonatalPeriod?.nutritionMethod3
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.facilityOrPersonName"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantEarlyNeonatalPeriod?.facilityOrPersonName
                  ? infantEarlyNeonatalPeriod?.facilityOrPersonName
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.remarks")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantEarlyNeonatalPeriod?.remarks
                  ? infantEarlyNeonatalPeriod?.remarks
                  : ""
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
