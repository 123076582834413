/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmailLinked
 */
export interface EmailLinked {
    /**
     * 
     * @type {string}
     * @memberof EmailLinked
     */
    emailLinked?: string;
}

/**
 * Check if a given object implements the EmailLinked interface.
 */
export function instanceOfEmailLinked(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EmailLinkedFromJSON(json: any): EmailLinked {
    return EmailLinkedFromJSONTyped(json, false);
}

export function EmailLinkedFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailLinked {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emailLinked': !exists(json, 'emailLinked') ? undefined : json['emailLinked'],
    };
}

export function EmailLinkedToJSON(value?: EmailLinked | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'emailLinked': value.emailLinked,
    };
}

