/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InfantMedicalExam,
    InfantMedicalExamFromJSON,
    InfantMedicalExamToJSON,
    InfantMedicalExamPagination,
    InfantMedicalExamPaginationFromJSON,
    InfantMedicalExamPaginationToJSON,
} from '../models';

export interface AdminInfantMedicalExamsGetRequest {
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
    userId?: number;
}

export interface InfantMedicalExamsGetRequest {
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface InfantMedicalExamsIdDeleteRequest {
    id: number;
}

export interface InfantMedicalExamsIdGetRequest {
    id: number;
}

export interface InfantMedicalExamsIdPutRequest {
    id: number;
    infantMedicalExam?: InfantMedicalExam;
}

export interface InfantMedicalExamsPostRequest {
    infantMedicalExam?: InfantMedicalExam;
}

export interface InfantMedicalExamsShareAllGetRequest {
    sourceUserId: number;
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface InfantMedicalExamsShareDetailGetRequest {
    sourceUserId: number;
    recordId: number;
}

/**
 * 
 */
export class InfantMedicalExamsApi extends runtime.BaseAPI {

    /**
     * 
     */
    adminInfantMedicalExamsGetRaw = async (requestParameters: AdminInfantMedicalExamsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantMedicalExamPagination>> => {
        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling adminInfantMedicalExamsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/infantMedicalExams`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantMedicalExamPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    adminInfantMedicalExamsGet = async (requestParameters: AdminInfantMedicalExamsGetRequest, initOverrides?: RequestInit): Promise<InfantMedicalExamPagination> => {
        const response = await this.adminInfantMedicalExamsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantMedicalExamsGetRaw = async (requestParameters: InfantMedicalExamsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantMedicalExamPagination>> => {
        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling infantMedicalExamsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantMedicalExams`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantMedicalExamPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantMedicalExamsGet = async (requestParameters: InfantMedicalExamsGetRequest, initOverrides?: RequestInit): Promise<InfantMedicalExamPagination> => {
        const response = await this.infantMedicalExamsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    infantMedicalExamsIdDeleteRaw = async (requestParameters: InfantMedicalExamsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling infantMedicalExamsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantMedicalExams/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    infantMedicalExamsIdDelete = async (requestParameters: InfantMedicalExamsIdDeleteRequest, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.infantMedicalExamsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantMedicalExamsIdGetRaw = async (requestParameters: InfantMedicalExamsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantMedicalExam>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling infantMedicalExamsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantMedicalExams/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantMedicalExamFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantMedicalExamsIdGet = async (requestParameters: InfantMedicalExamsIdGetRequest, initOverrides?: RequestInit): Promise<InfantMedicalExam> => {
        const response = await this.infantMedicalExamsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    infantMedicalExamsIdPutRaw = async (requestParameters: InfantMedicalExamsIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantMedicalExam>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling infantMedicalExamsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/infantMedicalExams/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InfantMedicalExamToJSON(requestParameters.infantMedicalExam),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantMedicalExamFromJSON(jsonValue));
    }

    /**
     */
    infantMedicalExamsIdPut = async (requestParameters: InfantMedicalExamsIdPutRequest, initOverrides?: RequestInit): Promise<InfantMedicalExam> => {
        const response = await this.infantMedicalExamsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    infantMedicalExamsPostRaw = async (requestParameters: InfantMedicalExamsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantMedicalExam>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/infantMedicalExams`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InfantMedicalExamToJSON(requestParameters.infantMedicalExam),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantMedicalExamFromJSON(jsonValue));
    }

    /**
     */
    infantMedicalExamsPost = async (requestParameters: InfantMedicalExamsPostRequest = {}, initOverrides?: RequestInit): Promise<InfantMedicalExam> => {
        const response = await this.infantMedicalExamsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantMedicalExamsShareAllGetRaw = async (requestParameters: InfantMedicalExamsShareAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantMedicalExamPagination>> => {
        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling infantMedicalExamsShareAllGet.');
        }

        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling infantMedicalExamsShareAllGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantMedicalExams/share/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantMedicalExamPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantMedicalExamsShareAllGet = async (requestParameters: InfantMedicalExamsShareAllGetRequest, initOverrides?: RequestInit): Promise<InfantMedicalExamPagination> => {
        const response = await this.infantMedicalExamsShareAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantMedicalExamsShareDetailGetRaw = async (requestParameters: InfantMedicalExamsShareDetailGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantMedicalExam>> => {
        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling infantMedicalExamsShareDetailGet.');
        }

        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling infantMedicalExamsShareDetailGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        if (requestParameters.recordId !== undefined) {
            queryParameters['recordId'] = requestParameters.recordId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantMedicalExams/share/detail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantMedicalExamFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantMedicalExamsShareDetailGet = async (requestParameters: InfantMedicalExamsShareDetailGetRequest, initOverrides?: RequestInit): Promise<InfantMedicalExam> => {
        const response = await this.infantMedicalExamsShareDetailGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
