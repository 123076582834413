/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';
import type { NotificationCampaignAllOf } from './NotificationCampaignAllOf';
import {
    NotificationCampaignAllOfFromJSON,
    NotificationCampaignAllOfFromJSONTyped,
    NotificationCampaignAllOfToJSON,
} from './NotificationCampaignAllOf';

/**
 * 
 * @export
 * @interface NotificationCampaign
 */
export interface NotificationCampaign {
    /**
     * 
     * @type {number}
     * @memberof NotificationCampaign
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationCampaign
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationCampaign
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationCampaign
     */
    type?: NotificationCampaignTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationCampaign
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationCampaign
     */
    content?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationCampaign
     */
    image?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationCampaign
     */
    siteUrl?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationCampaign
     */
    isShowInList?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NotificationCampaign
     */
    listStartDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationCampaign
     */
    listEndDate?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationCampaign
     */
    isShowInHome?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NotificationCampaign
     */
    homeStartDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationCampaign
     */
    homeEndDate?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationCampaign
     */
    isPublish?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NotificationCampaign
     */
    userList?: string | null;
}


/**
 * @export
 */
export const NotificationCampaignTypeEnum = {
    Content: 'content',
    Banner: 'banner'
} as const;
export type NotificationCampaignTypeEnum = typeof NotificationCampaignTypeEnum[keyof typeof NotificationCampaignTypeEnum];


/**
 * Check if a given object implements the NotificationCampaign interface.
 */
export function instanceOfNotificationCampaign(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NotificationCampaignFromJSON(json: any): NotificationCampaign {
    return NotificationCampaignFromJSONTyped(json, false);
}

export function NotificationCampaignFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationCampaign {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'siteUrl': !exists(json, 'siteUrl') ? undefined : json['siteUrl'],
        'isShowInList': !exists(json, 'isShowInList') ? undefined : json['isShowInList'],
        'listStartDate': !exists(json, 'listStartDate') ? undefined : json['listStartDate'],
        'listEndDate': !exists(json, 'listEndDate') ? undefined : json['listEndDate'],
        'isShowInHome': !exists(json, 'isShowInHome') ? undefined : json['isShowInHome'],
        'homeStartDate': !exists(json, 'homeStartDate') ? undefined : json['homeStartDate'],
        'homeEndDate': !exists(json, 'homeEndDate') ? undefined : json['homeEndDate'],
        'isPublish': !exists(json, 'isPublish') ? undefined : json['isPublish'],
        'userList': !exists(json, 'userList') ? undefined : json['userList'],
    };
}

export function NotificationCampaignToJSON(value?: NotificationCampaign | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'type': value.type,
        'title': value.title,
        'content': value.content,
        'image': value.image,
        'siteUrl': value.siteUrl,
        'isShowInList': value.isShowInList,
        'listStartDate': value.isShowInList ? new Date(value.listStartDate) : null,
        'listEndDate': value.isShowInList ? new Date(value.listEndDate) : null,
        'isShowInHome': value.isShowInHome,
        'homeStartDate': value.isShowInHome ? new Date(value.homeStartDate) : null,
        'homeEndDate': value.isShowInHome ?new Date(value.homeEndDate) : null,
        'isPublish': value.isPublish,
        'userList': value.userList,
    };
}

