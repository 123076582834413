/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PrescriptionDrug,
    PrescriptionDrugFromJSON,
    PrescriptionDrugToJSON,
    PrescriptionDrugPagination,
    PrescriptionDrugPaginationFromJSON,
    PrescriptionDrugPaginationToJSON,
} from '../models';

export interface PrescriptionDrugsGetRequest {
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface PrescriptionDrugsIdDeleteRequest {
    id: number;
}

export interface PrescriptionDrugsIdGetRequest {
    id: number;
}

export interface PrescriptionDrugsIdPutRequest {
    id: number;
    prescriptionDrug?: PrescriptionDrug;
}

export interface PrescriptionDrugsPostRequest {
    prescriptionDrug?: PrescriptionDrug;
}

export interface PrescriptionDrugsShareAllGetRequest {
    sourceUserId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface PrescriptionDrugsShareDetailGetRequest {
    id: number;
}

/**
 * 
 */
export class PrescriptionDrugsApi extends runtime.BaseAPI {

    /**
     * 
     */
    prescriptionDrugsGetRaw = async (requestParameters: PrescriptionDrugsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PrescriptionDrugPagination>> => {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/prescriptionDrugs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PrescriptionDrugPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    prescriptionDrugsGet = async (requestParameters: PrescriptionDrugsGetRequest = {}, initOverrides?: RequestInit): Promise<PrescriptionDrugPagination> => {
        const response = await this.prescriptionDrugsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    prescriptionDrugsIdDeleteRaw = async (requestParameters: PrescriptionDrugsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling prescriptionDrugsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/prescriptionDrugs/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    prescriptionDrugsIdDelete = async (requestParameters: PrescriptionDrugsIdDeleteRequest, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.prescriptionDrugsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    prescriptionDrugsIdGetRaw = async (requestParameters: PrescriptionDrugsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PrescriptionDrug>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling prescriptionDrugsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/prescriptionDrugs/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PrescriptionDrugFromJSON(jsonValue));
    }

    /**
     * 
     */
    prescriptionDrugsIdGet = async (requestParameters: PrescriptionDrugsIdGetRequest, initOverrides?: RequestInit): Promise<PrescriptionDrug> => {
        const response = await this.prescriptionDrugsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    prescriptionDrugsIdPutRaw = async (requestParameters: PrescriptionDrugsIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PrescriptionDrug>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling prescriptionDrugsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/prescriptionDrugs/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PrescriptionDrugToJSON(requestParameters.prescriptionDrug),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PrescriptionDrugFromJSON(jsonValue));
    }

    /**
     */
    prescriptionDrugsIdPut = async (requestParameters: PrescriptionDrugsIdPutRequest, initOverrides?: RequestInit): Promise<PrescriptionDrug> => {
        const response = await this.prescriptionDrugsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    prescriptionDrugsPostRaw = async (requestParameters: PrescriptionDrugsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PrescriptionDrug>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/prescriptionDrugs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PrescriptionDrugToJSON(requestParameters.prescriptionDrug),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PrescriptionDrugFromJSON(jsonValue));
    }

    /**
     */
    prescriptionDrugsPost = async (requestParameters: PrescriptionDrugsPostRequest = {}, initOverrides?: RequestInit): Promise<PrescriptionDrug> => {
        const response = await this.prescriptionDrugsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    prescriptionDrugsShareAllGetRaw = async (requestParameters: PrescriptionDrugsShareAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PrescriptionDrug>> => {
        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling prescriptionDrugsShareAllGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/prescriptionDrugs/share/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PrescriptionDrugFromJSON(jsonValue));
    }

    /**
     * 
     */
    prescriptionDrugsShareAllGet = async (requestParameters: PrescriptionDrugsShareAllGetRequest, initOverrides?: RequestInit): Promise<PrescriptionDrug> => {
        const response = await this.prescriptionDrugsShareAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    prescriptionDrugsShareDetailGetRaw = async (requestParameters: PrescriptionDrugsShareDetailGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PrescriptionDrug>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling prescriptionDrugsShareDetailGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/prescriptionDrugs/share/detail`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PrescriptionDrugFromJSON(jsonValue));
    }

    /**
     * 
     */
    prescriptionDrugsShareDetailGet = async (requestParameters: PrescriptionDrugsShareDetailGetRequest, initOverrides?: RequestInit): Promise<PrescriptionDrug> => {
        const response = await this.prescriptionDrugsShareDetailGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
