/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaginationInfo } from './PaginationInfo';
import {
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './PaginationInfo';
import type { Pregnant } from './Pregnant';
import {
    PregnantFromJSON,
    PregnantFromJSONTyped,
    PregnantToJSON,
} from './Pregnant';

/**
 * 
 * @export
 * @interface PregnantPagination
 */
export interface PregnantPagination {
    /**
     * 
     * @type {Array<Pregnant>}
     * @memberof PregnantPagination
     */
    data?: Array<Pregnant>;
    /**
     * 
     * @type {PaginationInfo}
     * @memberof PregnantPagination
     */
    meta?: PaginationInfo;
}

/**
 * Check if a given object implements the PregnantPagination interface.
 */
export function instanceOfPregnantPagination(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PregnantPaginationFromJSON(json: any): PregnantPagination {
    return PregnantPaginationFromJSONTyped(json, false);
}

export function PregnantPaginationFromJSONTyped(json: any, ignoreDiscriminator: boolean): PregnantPagination {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(PregnantFromJSON)),
        'meta': !exists(json, 'meta') ? undefined : PaginationInfoFromJSON(json['meta']),
    };
}

export function PregnantPaginationToJSON(value?: PregnantPagination | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(PregnantToJSON)),
        'meta': PaginationInfoToJSON(value.meta),
    };
}

