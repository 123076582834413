/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InfantOneAndSixYearOld,
    InfantOneAndSixYearOldFromJSON,
    InfantOneAndSixYearOldToJSON,
    InfantOneAndSixYearOldPagination,
    InfantOneAndSixYearOldPaginationFromJSON,
    InfantOneAndSixYearOldPaginationToJSON,
} from '../models';

export interface AdminInfantOneAndSixYearOldsIdGetRequest {
    id: number;
    userId?: number;
}

export interface InfantOneAndSixYearOldsGetRequest {
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface InfantOneAndSixYearOldsIdDeleteRequest {
    id: number;
}

export interface InfantOneAndSixYearOldsIdGetRequest {
    id: number;
}

export interface InfantOneAndSixYearOldsIdPutRequest {
    id: number;
    infantOneAndSixYearOld?: InfantOneAndSixYearOld;
}

export interface InfantOneAndSixYearOldsPostRequest {
    infantOneAndSixYearOld?: InfantOneAndSixYearOld;
}

export interface InfantOneAndSixYearOldsShareAllGetRequest {
    sourceUserId: number;
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface InfantOneAndSixYearOldsShareDetailGetRequest {
    recordId: number;
    sourceUserId: number;
}

/**
 * 
 */
export class InfantOneAndSixYearOldsApi extends runtime.BaseAPI {

    /**
     * 
     */
    adminInfantOneAndSixYearOldsIdGetRaw = async (requestParameters: AdminInfantOneAndSixYearOldsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantOneAndSixYearOld>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling adminInfantOneAndSixYearOldsIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/infantOneAndSixYearOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantOneAndSixYearOldFromJSON(jsonValue));
    }

    /**
     * 
     */
    adminInfantOneAndSixYearOldsIdGet = async (requestParameters: AdminInfantOneAndSixYearOldsIdGetRequest, initOverrides?: RequestInit): Promise<InfantOneAndSixYearOld> => {
        const response = await this.adminInfantOneAndSixYearOldsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantOneAndSixYearOldsGetRaw = async (requestParameters: InfantOneAndSixYearOldsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantOneAndSixYearOldPagination>> => {
        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling infantOneAndSixYearOldsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantOneAndSixYearOlds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantOneAndSixYearOldPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantOneAndSixYearOldsGet = async (requestParameters: InfantOneAndSixYearOldsGetRequest, initOverrides?: RequestInit): Promise<InfantOneAndSixYearOldPagination> => {
        const response = await this.infantOneAndSixYearOldsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    infantOneAndSixYearOldsIdDeleteRaw = async (requestParameters: InfantOneAndSixYearOldsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling infantOneAndSixYearOldsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantOneAndSixYearOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    infantOneAndSixYearOldsIdDelete = async (requestParameters: InfantOneAndSixYearOldsIdDeleteRequest, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.infantOneAndSixYearOldsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantOneAndSixYearOldsIdGetRaw = async (requestParameters: InfantOneAndSixYearOldsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantOneAndSixYearOld>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling infantOneAndSixYearOldsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantOneAndSixYearOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantOneAndSixYearOldFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantOneAndSixYearOldsIdGet = async (requestParameters: InfantOneAndSixYearOldsIdGetRequest, initOverrides?: RequestInit): Promise<InfantOneAndSixYearOld> => {
        const response = await this.infantOneAndSixYearOldsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    infantOneAndSixYearOldsIdPutRaw = async (requestParameters: InfantOneAndSixYearOldsIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantOneAndSixYearOld>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling infantOneAndSixYearOldsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/infantOneAndSixYearOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InfantOneAndSixYearOldToJSON(requestParameters.infantOneAndSixYearOld),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantOneAndSixYearOldFromJSON(jsonValue));
    }

    /**
     */
    infantOneAndSixYearOldsIdPut = async (requestParameters: InfantOneAndSixYearOldsIdPutRequest, initOverrides?: RequestInit): Promise<InfantOneAndSixYearOld> => {
        const response = await this.infantOneAndSixYearOldsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    infantOneAndSixYearOldsPostRaw = async (requestParameters: InfantOneAndSixYearOldsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantOneAndSixYearOld>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/infantOneAndSixYearOlds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InfantOneAndSixYearOldToJSON(requestParameters.infantOneAndSixYearOld),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantOneAndSixYearOldFromJSON(jsonValue));
    }

    /**
     */
    infantOneAndSixYearOldsPost = async (requestParameters: InfantOneAndSixYearOldsPostRequest = {}, initOverrides?: RequestInit): Promise<InfantOneAndSixYearOld> => {
        const response = await this.infantOneAndSixYearOldsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantOneAndSixYearOldsShareAllGetRaw = async (requestParameters: InfantOneAndSixYearOldsShareAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantOneAndSixYearOldPagination>> => {
        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling infantOneAndSixYearOldsShareAllGet.');
        }

        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling infantOneAndSixYearOldsShareAllGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantOneAndSixYearOlds/share/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantOneAndSixYearOldPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantOneAndSixYearOldsShareAllGet = async (requestParameters: InfantOneAndSixYearOldsShareAllGetRequest, initOverrides?: RequestInit): Promise<InfantOneAndSixYearOldPagination> => {
        const response = await this.infantOneAndSixYearOldsShareAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantOneAndSixYearOldsShareDetailGetRaw = async (requestParameters: InfantOneAndSixYearOldsShareDetailGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantOneAndSixYearOld>> => {
        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling infantOneAndSixYearOldsShareDetailGet.');
        }

        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling infantOneAndSixYearOldsShareDetailGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.recordId !== undefined) {
            queryParameters['recordId'] = requestParameters.recordId;
        }

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantOneAndSixYearOlds/share/detail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantOneAndSixYearOldFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantOneAndSixYearOldsShareDetailGet = async (requestParameters: InfantOneAndSixYearOldsShareDetailGetRequest, initOverrides?: RequestInit): Promise<InfantOneAndSixYearOld> => {
        const response = await this.infantOneAndSixYearOldsShareDetailGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
