import React, { useEffect, useState } from "react";
import {
  ButtonCustom,
  CardCustom,
  TextBlockCustom,
} from "@/components/display";
import { COLORS, noData } from "@/constants";
import { Grid } from "@mui/material";
import { useQuery } from "react-query";
import { Baby, infantMedicalExamsApi } from "@/api";
import { getTextAgeData, toJapanDate } from "@/utils";
import { DialogInfantMedicalExam } from "./DialogInfantMedicalExam";

interface IInfantMedicalExamProps {
  userId?: number;
  babyId?: number;
  babyItem?: Baby;
}

export const InfantMedicalExam = (
  props: React.PropsWithChildren<IInfantMedicalExamProps>
) => {
  //--- Get medical consultant
  const { data: infantMedicalExamData, refetch: refetchInfantMedicalExam } =
    useQuery({
      queryKey: "GET_INFANT_MEDICAL_EXAM",
      queryFn: () => {
        return infantMedicalExamsApi.adminInfantMedicalExamsGet({
          userId: props.userId,
          babyId: props.babyId,
          orderBy: "createdAt",
          orderType: "desc",
        });
      },
    });

  //--- Dialog
  const [openDialog, setOpenDialog] = useState(false);
  const [infantMedicalExamId, setInfantMedicalExamId] = useState(0);

  const handleClickOpenDialog = (id: number) => {
    setOpenDialog(true);
    setInfantMedicalExamId(id);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setInfantMedicalExamId(0);
  };

  useEffect(() => {
    if (props.userId > 0 && props.babyId > 0) {
      refetchInfantMedicalExam();
    }
  }, [props.babyId, props.userId, refetchInfantMedicalExam]);

  return (
    <>
      <CardCustom classCard={"mt-4"}>
        <TextBlockCustom
          classText="mb-4 font-bold"
          textHeader="乳幼児健診"
          headerColor={COLORS.primary}
          textSize={16}
        />

        <Grid container spacing={3}>
          {infantMedicalExamData?.data?.length > 0 ? (
            (infantMedicalExamData?.data || []).map((item, index) => (
              <Grid item xs={4} key={"infantMedicalExams_" + index}>
                <Grid container className="h-full">
                  <Grid item xs={10}>
                    <Grid container>
                      <Grid item xs={3}>
                        <TextBlockCustom
                          classText="mb-2"
                          textHeader="検診名:"
                          headerColor={COLORS.approxGrey}
                        />

                        {item?.ageId > 3 && (
                          <>
                            <TextBlockCustom
                              classText="mb-2"
                              textHeader="実施日:"
                              headerColor={COLORS.approxGrey}
                            />

                            <TextBlockCustom
                              classText="mb-2"
                              textHeader={
                                item?.ageId === (4 || 5 || 6 || 7)
                                  ? "月齢:"
                                  : "年齢:"
                              }
                              headerColor={COLORS.approxGrey}
                            />
                          </>
                        )}
                      </Grid>
                      <Grid item xs={9}>
                        <TextBlockCustom
                          classText="mb-2 font-medium"
                          textHeader={getTextAgeData(item?.ageId)}
                        />

                        {item?.ageId > 3 && (
                          <>
                            <TextBlockCustom
                              classText="mb-2 font-medium"
                              textHeader={toJapanDate(item?.examImplementDate)}
                            />

                            <TextBlockCustom
                              classText="mb-2 font-medium"
                              textHeader={
                                item?.ageId === (4 || 5 || 6 || 7)
                                  ? `${
                                      item?.examAgeMonth +
                                      "か月" +
                                      item?.examAgeDay +
                                      "日"
                                    }`
                                  : `${
                                      item?.examAgeMonth +
                                      "歳" +
                                      item?.examAgeDay +
                                      "か月"
                                    }`
                              }
                            />
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2}>
                    <div className="flex justify-end mb-2">
                      <ButtonCustom
                        title="詳細"
                        onClick={() => handleClickOpenDialog(item?.id)}
                      />
                    </div>
                  </Grid>
                </Grid>
                <div className="divide-dot"></div>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <div className="text-center font-bold">{noData}</div>
            </Grid>
          )}
        </Grid>
      </CardCustom>

      <DialogInfantMedicalExam
        openDialog={openDialog}
        onCloseDialog={() => handleCloseDialog()}
        infantMedicalExamItem={infantMedicalExamData?.data?.find(
          (m) => m.id === infantMedicalExamId
        )}
        baby={props.babyItem}
      />
    </>
  );
};
