import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { getUserDetailUrl } from "../../user-details/UserDetail.shared";
import { motherApi } from "@/api";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  LinearProgress,
} from "@mui/material";
import moment from "moment";
import { getMatureGenderText, getUrlQueryValue } from "@/utils";
import { noData, textRowsPerPage } from "@/constants";
import { useApiPagination } from "@/hooks";

const StyledUserTable = styled.div`
  position: relative;
`;

export const reloadUserTableEvent = new Event("reloadUserTable");

interface IUserTableProps {
  searchKeyword?: string;
}

export const UserTable = (props: React.PropsWithChildren<IUserTableProps>) => {
  //--- Get mother list
  const {
    data: motherList,
    isFetching,
    refetch: refetchMotherList,
  } = useQuery({
    queryKey: "GET_MOTHER_LIST",
    queryFn: () => {
      return motherApi.mothersGetManyGet({
        page: apiPagi.page,
        itemsPerPage: apiPagi.itemsPerPage,
        orderBy: "id",
        orderType: "desc",
        keyword: getUrlQueryValue("search") ?? undefined,
      });
    },
    onSuccess: (data) => {
      apiPagi.setTotalItems(data.meta.totalItems);
    },
    initialData: { data: [] },
  });

  const apiPagi = useApiPagination({
    onPageChange: () => refetchMotherList(),
  });

  useEffect(() => {
    const handler = () => refetchMotherList();
    window.addEventListener("reloadUserTable", handler);

    return () => {
      window.removeEventListener("reloadUserTable", handler);
    };
  }, [apiPagi.setPage, refetchMotherList]);

  return (
    <StyledUserTable>
      {isFetching && (
        <div className="absolute w-full">
          <LinearProgress />
        </div>
      )}

      <TableContainer component={Paper} className="mb-8">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="color-primary">ID</TableCell>
              <TableCell align="center" className="color-primary">
                ユーザー
              </TableCell>
              <TableCell align="center" className="color-primary">
                生年月日
              </TableCell>
              <TableCell align="center" className="color-primary">
                性別
              </TableCell>
              <TableCell align="center" className="color-primary">
                メール
              </TableCell>
              <TableCell align="center" className="color-primary">
                会員登録日
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(motherList?.data || []).map((mItem, mIndex) => (
              <TableRow key={"motherList_" + mItem.id}>
                <TableCell>
                  {mItem.id < 10 ? "0" + mItem.id : mItem.id}
                </TableCell>
                <TableCell align="center">
                  <Link to={getUserDetailUrl(mItem.userId, mItem.id)}>
                    <span className="underline cursor-pointer">
                      {mItem.name}
                    </span>
                  </Link>
                </TableCell>
                <TableCell align="center">
                  {mItem.birthday && moment(mItem.birthday).format("yyyy/MM/DD")}
                </TableCell>
                <TableCell align="center">
                  {getMatureGenderText(mItem.gender)}
                </TableCell>
                <TableCell align="center">{mItem.email}</TableCell>
                <TableCell align="center">
                  {moment(mItem.createdAt).format("yyyy/MM/DD")}
                </TableCell>
              </TableRow>
            ))}

            {motherList?.data.length === 0 && (
              <TableRow>
                <TableCell colSpan={6}>
                  <div className="text-center font-bold">{noData}</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        className="custom-pagination"
        count={apiPagi.totalItems}
        rowsPerPage={apiPagi.itemsPerPage}
        page={apiPagi.page - 1}
        labelRowsPerPage={textRowsPerPage}
        onPageChange={(e, page) => {
          apiPagi.setPage(page + 1);
        }}
        onRowsPerPageChange={(e) => {
          apiPagi.setItemsPerPage(+e.target.value);
        }}
        labelDisplayedRows={(p) => `${p.from}-${p.to} / ${p.count}件`}
      />
    </StyledUserTable>
  );
};
