/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminRole } from './AdminRole';
import {
    AdminRoleFromJSON,
    AdminRoleFromJSONTyped,
    AdminRoleToJSON,
} from './AdminRole';
import type { PaginationInfo } from './PaginationInfo';
import {
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './PaginationInfo';

/**
 * 
 * @export
 * @interface AdminRolePagination
 */
export interface AdminRolePagination {
    /**
     * 
     * @type {Array<AdminRole>}
     * @memberof AdminRolePagination
     */
    data?: Array<AdminRole>;
    /**
     * 
     * @type {PaginationInfo}
     * @memberof AdminRolePagination
     */
    meta?: PaginationInfo;
}

/**
 * Check if a given object implements the AdminRolePagination interface.
 */
export function instanceOfAdminRolePagination(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AdminRolePaginationFromJSON(json: any): AdminRolePagination {
    return AdminRolePaginationFromJSONTyped(json, false);
}

export function AdminRolePaginationFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminRolePagination {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(AdminRoleFromJSON)),
        'meta': !exists(json, 'meta') ? undefined : PaginationInfoFromJSON(json['meta']),
    };
}

export function AdminRolePaginationToJSON(value?: AdminRolePagination | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(AdminRoleToJSON)),
        'meta': PaginationInfoToJSON(value.meta),
    };
}

