/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Medic,
    MedicFromJSON,
    MedicToJSON,
    MedicPagination,
    MedicPaginationFromJSON,
    MedicPaginationToJSON,
} from '../models';

export interface MedicsGetRequest {
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface MedicsIdDeleteRequest {
    id: number;
}

export interface MedicsIdGetRequest {
    id: number;
}

export interface MedicsIdPutRequest {
    id: number;
    medic?: Medic;
}

export interface MedicsPostRequest {
    medic?: Medic;
}

/**
 * 
 */
export class MedicsApi extends runtime.BaseAPI {

    /**
     * 
     */
    medicsGetRaw = async (requestParameters: MedicsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MedicPagination>> => {
        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling medicsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/medics`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MedicPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    medicsGet = async (requestParameters: MedicsGetRequest, initOverrides?: RequestInit): Promise<MedicPagination> => {
        const response = await this.medicsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    medicsIdDeleteRaw = async (requestParameters: MedicsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling medicsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/medics/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    medicsIdDelete = async (requestParameters: MedicsIdDeleteRequest, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.medicsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    medicsIdGetRaw = async (requestParameters: MedicsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Medic>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling medicsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/medics/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MedicFromJSON(jsonValue));
    }

    /**
     * 
     */
    medicsIdGet = async (requestParameters: MedicsIdGetRequest, initOverrides?: RequestInit): Promise<Medic> => {
        const response = await this.medicsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    medicsIdPutRaw = async (requestParameters: MedicsIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Medic>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling medicsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/medics/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MedicToJSON(requestParameters.medic),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MedicFromJSON(jsonValue));
    }

    /**
     */
    medicsIdPut = async (requestParameters: MedicsIdPutRequest, initOverrides?: RequestInit): Promise<Medic> => {
        const response = await this.medicsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    medicsPostRaw = async (requestParameters: MedicsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Medic>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/medics`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MedicToJSON(requestParameters.medic),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MedicFromJSON(jsonValue));
    }

    /**
     */
    medicsPost = async (requestParameters: MedicsPostRequest = {}, initOverrides?: RequestInit): Promise<Medic> => {
        const response = await this.medicsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
