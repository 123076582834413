/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';
import type { PregnantDiagnosisAllOf } from './PregnantDiagnosisAllOf';
import {
    PregnantDiagnosisAllOfFromJSON,
    PregnantDiagnosisAllOfFromJSONTyped,
    PregnantDiagnosisAllOfToJSON,
} from './PregnantDiagnosisAllOf';

/**
 * 
 * @export
 * @interface PregnantDiagnosis
 */
export interface PregnantDiagnosis {
    /**
     * 
     * @type {number}
     * @memberof PregnantDiagnosis
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PregnantDiagnosis
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof PregnantDiagnosis
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof PregnantDiagnosis
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof PregnantDiagnosis
     */
    pregnantId?: number;
    /**
     * 
     * @type {string}
     * @memberof PregnantDiagnosis
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof PregnantDiagnosis
     */
    examinationPlace?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PregnantDiagnosis
     */
    prenancyWeeks?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PregnantDiagnosis
     */
    prenancyDays?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PregnantDiagnosis
     */
    uterineCavityLength?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PregnantDiagnosis
     */
    waistCircumference?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PregnantDiagnosis
     */
    bodyWeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PregnantDiagnosis
     */
    bloodPressureMax?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PregnantDiagnosis
     */
    bloodPressureMin?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PregnantDiagnosis
     */
    bloodType?: PregnantDiagnosisBloodTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PregnantDiagnosis
     */
    bloodRh?: PregnantDiagnosisBloodRhEnum;
    /**
     * 
     * @type {string}
     * @memberof PregnantDiagnosis
     */
    edemaStatus?: PregnantDiagnosisEdemaStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof PregnantDiagnosis
     */
    fetalWeight?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PregnantDiagnosis
     */
    obstetricPathologies?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PregnantDiagnosis
     */
    obstetricPathologiesNote?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PregnantDiagnosis
     */
    irregularAntibodyCheck?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof PregnantDiagnosis
     */
    irregularAntibody?: PregnantDiagnosisIrregularAntibodyEnum;
    /**
     * 
     * @type {string}
     * @memberof PregnantDiagnosis
     */
    irregularAntibodyRemark?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PregnantDiagnosis
     */
    completeBloodCount?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof PregnantDiagnosis
     */
    bloodHemoglobin?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PregnantDiagnosis
     */
    bloodHematocrit?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PregnantDiagnosis
     */
    bloodPlatelet?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PregnantDiagnosis
     */
    bloodRemark?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PregnantDiagnosis
     */
    cervicalCancerScreeningCheck?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof PregnantDiagnosis
     */
    cervicalCancerScreening?: PregnantDiagnosisCervicalCancerScreeningEnum;
    /**
     * 
     * @type {string}
     * @memberof PregnantDiagnosis
     */
    cervicalCancerScreeningRemark?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PregnantDiagnosis
     */
    syphilisSerumReactionCheck?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof PregnantDiagnosis
     */
    syphilisSerumReaction?: PregnantDiagnosisSyphilisSerumReactionEnum;
    /**
     * 
     * @type {string}
     * @memberof PregnantDiagnosis
     */
    syphilisSerumReactionRemark?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PregnantDiagnosis
     */
    hbsAntibodyCheck?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof PregnantDiagnosis
     */
    hbsAntibody?: PregnantDiagnosisHbsAntibodyEnum;
    /**
     * 
     * @type {string}
     * @memberof PregnantDiagnosis
     */
    hbsAntibodyRemark?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PregnantDiagnosis
     */
    hcvAntibodyCheck?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof PregnantDiagnosis
     */
    hcvAntibody?: PregnantDiagnosisHcvAntibodyEnum;
    /**
     * 
     * @type {string}
     * @memberof PregnantDiagnosis
     */
    hcvAntibodyRemark?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PregnantDiagnosis
     */
    hivAntibodyCheck?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof PregnantDiagnosis
     */
    hivAntibody?: PregnantDiagnosisHivAntibodyEnum;
    /**
     * 
     * @type {string}
     * @memberof PregnantDiagnosis
     */
    hivAntibodyRemark?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PregnantDiagnosis
     */
    rubellaVirusAntibodyCheck?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof PregnantDiagnosis
     */
    rubellaVirusAntibody?: PregnantDiagnosisRubellaVirusAntibodyEnum;
    /**
     * 
     * @type {string}
     * @memberof PregnantDiagnosis
     */
    rubellaVirusAntibodyRemark?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PregnantDiagnosis
     */
    htlv1AntibodyCheck?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof PregnantDiagnosis
     */
    htlv1Antibody?: PregnantDiagnosisHtlv1AntibodyEnum;
    /**
     * 
     * @type {string}
     * @memberof PregnantDiagnosis
     */
    htlv1AntibodyRemark?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PregnantDiagnosis
     */
    chlamydiaAntigenCheck?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof PregnantDiagnosis
     */
    chlamydiaAntigen?: PregnantDiagnosisChlamydiaAntigenEnum;
    /**
     * 
     * @type {string}
     * @memberof PregnantDiagnosis
     */
    chlamydiaAntigenRemark?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PregnantDiagnosis
     */
    groupBHemolyticStreptococcusCheck?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof PregnantDiagnosis
     */
    groupBHemolyticStreptococcus?: PregnantDiagnosisGroupBHemolyticStreptococcusEnum;
    /**
     * 
     * @type {string}
     * @memberof PregnantDiagnosis
     */
    urineProtein?: PregnantDiagnosisUrineProteinEnum;
    /**
     * 
     * @type {string}
     * @memberof PregnantDiagnosis
     */
    groupBHemolyticStreptococcusRemark?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PregnantDiagnosis
     */
    note?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PregnantDiagnosis
     */
    photoUrls?: string | null;
}


/**
 * @export
 */
export const PregnantDiagnosisBloodTypeEnum = {
    A: 'A',
    B: 'B',
    Ab: 'AB',
    O: 'O',
    Unknown: 'UNKNOWN'
} as const;
export type PregnantDiagnosisBloodTypeEnum = typeof PregnantDiagnosisBloodTypeEnum[keyof typeof PregnantDiagnosisBloodTypeEnum];

/**
 * @export
 */
export const PregnantDiagnosisBloodRhEnum = {
    Plus: '+',
    Minus: '-',
    Unknown: 'UNKNOWN'
} as const;
export type PregnantDiagnosisBloodRhEnum = typeof PregnantDiagnosisBloodRhEnum[keyof typeof PregnantDiagnosisBloodRhEnum];

/**
 * @export
 */
export const PregnantDiagnosisEdemaStatusEnum = {
    MINUS: '-',
    PLUS: '+',
    DOUBLE_PLUS: '++'
} as const;
export type PregnantDiagnosisEdemaStatusEnum = typeof PregnantDiagnosisEdemaStatusEnum[keyof typeof PregnantDiagnosisEdemaStatusEnum];

/**
 * @export
 */
export const PregnantDiagnosisIrregularAntibodyEnum = {
    PLUS: '+',
    MINUS: '-'
} as const;
export type PregnantDiagnosisIrregularAntibodyEnum = typeof PregnantDiagnosisIrregularAntibodyEnum[keyof typeof PregnantDiagnosisIrregularAntibodyEnum];

/**
 * @export
 */
export const PregnantDiagnosisCervicalCancerScreeningEnum = {
    NoDetailedInspectionRequired: 'NO DETAILED_INSPECTION_REQUIRED',
    DetailedInspectionRequired: 'DETAILED_INSPECTION_REQUIRED',
    UndecidableReExaminationRequired: 'UNDECIDABLE_/_RE-EXAMINATION_REQUIRED'
} as const;
export type PregnantDiagnosisCervicalCancerScreeningEnum = typeof PregnantDiagnosisCervicalCancerScreeningEnum[keyof typeof PregnantDiagnosisCervicalCancerScreeningEnum];

/**
 * @export
 */
export const PregnantDiagnosisSyphilisSerumReactionEnum = {
    PLUS: '+',
    MINUS: '-'
} as const;
export type PregnantDiagnosisSyphilisSerumReactionEnum = typeof PregnantDiagnosisSyphilisSerumReactionEnum[keyof typeof PregnantDiagnosisSyphilisSerumReactionEnum];

/**
 * @export
 */
export const PregnantDiagnosisHbsAntibodyEnum = {
    PLUS: '+',
    MINUS: '-'
} as const;
export type PregnantDiagnosisHbsAntibodyEnum = typeof PregnantDiagnosisHbsAntibodyEnum[keyof typeof PregnantDiagnosisHbsAntibodyEnum];

/**
 * @export
 */
export const PregnantDiagnosisHcvAntibodyEnum = {
    PLUS: '+',
    MINUS: '-'
} as const;
export type PregnantDiagnosisHcvAntibodyEnum = typeof PregnantDiagnosisHcvAntibodyEnum[keyof typeof PregnantDiagnosisHcvAntibodyEnum];

/**
 * @export
 */
export const PregnantDiagnosisHivAntibodyEnum = {
    PLUS: '+',
    MINUS: '-'
} as const;
export type PregnantDiagnosisHivAntibodyEnum = typeof PregnantDiagnosisHivAntibodyEnum[keyof typeof PregnantDiagnosisHivAntibodyEnum];

/**
 * @export
 */
export const PregnantDiagnosisRubellaVirusAntibodyEnum = {
    VaccinationConsultationRequired: 'VACCINATION_CONSULTATION_REQUIRED',
    Immune: 'IMMUNE'
} as const;
export type PregnantDiagnosisRubellaVirusAntibodyEnum = typeof PregnantDiagnosisRubellaVirusAntibodyEnum[keyof typeof PregnantDiagnosisRubellaVirusAntibodyEnum];

/**
 * @export
 */
export const PregnantDiagnosisHtlv1AntibodyEnum = {
    PLUS: '+',
    MINUS: '-'
} as const;
export type PregnantDiagnosisHtlv1AntibodyEnum = typeof PregnantDiagnosisHtlv1AntibodyEnum[keyof typeof PregnantDiagnosisHtlv1AntibodyEnum];

/**
 * @export
 */
export const PregnantDiagnosisChlamydiaAntigenEnum = {
    PLUS: '+',
    MINUS: '-'
} as const;
export type PregnantDiagnosisChlamydiaAntigenEnum = typeof PregnantDiagnosisChlamydiaAntigenEnum[keyof typeof PregnantDiagnosisChlamydiaAntigenEnum];

/**
 * @export
 */
export const PregnantDiagnosisGroupBHemolyticStreptococcusEnum = {
    PLUS: '+',
    MINUS: '-'
} as const;
export type PregnantDiagnosisGroupBHemolyticStreptococcusEnum = typeof PregnantDiagnosisGroupBHemolyticStreptococcusEnum[keyof typeof PregnantDiagnosisGroupBHemolyticStreptococcusEnum];

/**
 * @export
 */
export const PregnantDiagnosisUrineProteinEnum = {
    MINUS: '-',
    PLUS: '+',
    PLUS_MINUS: '±',
    DOUBLE_PLUS: '++'
} as const;
export type PregnantDiagnosisUrineProteinEnum = typeof PregnantDiagnosisUrineProteinEnum[keyof typeof PregnantDiagnosisUrineProteinEnum];


/**
 * Check if a given object implements the PregnantDiagnosis interface.
 */
export function instanceOfPregnantDiagnosis(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PregnantDiagnosisFromJSON(json: any): PregnantDiagnosis {
    return PregnantDiagnosisFromJSONTyped(json, false);
}

export function PregnantDiagnosisFromJSONTyped(json: any, ignoreDiscriminator: boolean): PregnantDiagnosis {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'pregnantId': !exists(json, 'pregnantId') ? undefined : json['pregnantId'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'examinationPlace': !exists(json, 'examinationPlace') ? undefined : json['examinationPlace'],
        'prenancyWeeks': !exists(json, 'prenancyWeeks') ? undefined : json['prenancyWeeks'],
        'prenancyDays': !exists(json, 'prenancyDays') ? undefined : json['prenancyDays'],
        'uterineCavityLength': !exists(json, 'uterineCavityLength') ? undefined : json['uterineCavityLength'],
        'waistCircumference': !exists(json, 'waistCircumference') ? undefined : json['waistCircumference'],
        'bodyWeight': !exists(json, 'bodyWeight') ? undefined : json['bodyWeight'],
        'bloodPressureMax': !exists(json, 'bloodPressureMax') ? undefined : json['bloodPressureMax'],
        'bloodPressureMin': !exists(json, 'bloodPressureMin') ? undefined : json['bloodPressureMin'],
        'bloodType': !exists(json, 'bloodType') ? undefined : json['bloodType'],
        'bloodRh': !exists(json, 'bloodRh') ? undefined : json['bloodRh'],
        'edemaStatus': !exists(json, 'edemaStatus') ? undefined : json['edemaStatus'],
        'fetalWeight': !exists(json, 'fetalWeight') ? undefined : json['fetalWeight'],
        'obstetricPathologies': !exists(json, 'obstetricPathologies') ? undefined : json['obstetricPathologies'],
        'obstetricPathologiesNote': !exists(json, 'obstetricPathologiesNote') ? undefined : json['obstetricPathologiesNote'],
        'irregularAntibodyCheck': !exists(json, 'irregularAntibodyCheck') ? undefined : json['irregularAntibodyCheck'],
        'irregularAntibody': !exists(json, 'irregularAntibody') ? undefined : json['irregularAntibody'],
        'irregularAntibodyRemark': !exists(json, 'irregularAntibodyRemark') ? undefined : json['irregularAntibodyRemark'],
        'completeBloodCount': !exists(json, 'completeBloodCount') ? undefined : json['completeBloodCount'],
        'bloodHemoglobin': !exists(json, 'bloodHemoglobin') ? undefined : json['bloodHemoglobin'],
        'bloodHematocrit': !exists(json, 'bloodHematocrit') ? undefined : json['bloodHematocrit'],
        'bloodPlatelet': !exists(json, 'bloodPlatelet') ? undefined : json['bloodPlatelet'],
        'bloodRemark': !exists(json, 'bloodRemark') ? undefined : json['bloodRemark'],
        'cervicalCancerScreeningCheck': !exists(json, 'cervicalCancerScreeningCheck') ? undefined : json['cervicalCancerScreeningCheck'],
        'cervicalCancerScreening': !exists(json, 'cervicalCancerScreening') ? undefined : json['cervicalCancerScreening'],
        'cervicalCancerScreeningRemark': !exists(json, 'cervicalCancerScreeningRemark') ? undefined : json['cervicalCancerScreeningRemark'],
        'syphilisSerumReactionCheck': !exists(json, 'syphilisSerumReactionCheck') ? undefined : json['syphilisSerumReactionCheck'],
        'syphilisSerumReaction': !exists(json, 'syphilisSerumReaction') ? undefined : json['syphilisSerumReaction'],
        'syphilisSerumReactionRemark': !exists(json, 'syphilisSerumReactionRemark') ? undefined : json['syphilisSerumReactionRemark'],
        'hbsAntibodyCheck': !exists(json, 'hbsAntibodyCheck') ? undefined : json['hbsAntibodyCheck'],
        'hbsAntibody': !exists(json, 'hbsAntibody') ? undefined : json['hbsAntibody'],
        'hbsAntibodyRemark': !exists(json, 'hbsAntibodyRemark') ? undefined : json['hbsAntibodyRemark'],
        'hcvAntibodyCheck': !exists(json, 'hcvAntibodyCheck') ? undefined : json['hcvAntibodyCheck'],
        'hcvAntibody': !exists(json, 'hcvAntibody') ? undefined : json['hcvAntibody'],
        'hcvAntibodyRemark': !exists(json, 'hcvAntibodyRemark') ? undefined : json['hcvAntibodyRemark'],
        'hivAntibodyCheck': !exists(json, 'hivAntibodyCheck') ? undefined : json['hivAntibodyCheck'],
        'hivAntibody': !exists(json, 'hivAntibody') ? undefined : json['hivAntibody'],
        'hivAntibodyRemark': !exists(json, 'hivAntibodyRemark') ? undefined : json['hivAntibodyRemark'],
        'rubellaVirusAntibodyCheck': !exists(json, 'rubellaVirusAntibodyCheck') ? undefined : json['rubellaVirusAntibodyCheck'],
        'rubellaVirusAntibody': !exists(json, 'rubellaVirusAntibody') ? undefined : json['rubellaVirusAntibody'],
        'rubellaVirusAntibodyRemark': !exists(json, 'rubellaVirusAntibodyRemark') ? undefined : json['rubellaVirusAntibodyRemark'],
        'htlv1AntibodyCheck': !exists(json, 'htlv1AntibodyCheck') ? undefined : json['htlv1AntibodyCheck'],
        'htlv1Antibody': !exists(json, 'htlv1Antibody') ? undefined : json['htlv1Antibody'],
        'htlv1AntibodyRemark': !exists(json, 'htlv1AntibodyRemark') ? undefined : json['htlv1AntibodyRemark'],
        'chlamydiaAntigenCheck': !exists(json, 'chlamydiaAntigenCheck') ? undefined : json['chlamydiaAntigenCheck'],
        'chlamydiaAntigen': !exists(json, 'chlamydiaAntigen') ? undefined : json['chlamydiaAntigen'],
        'chlamydiaAntigenRemark': !exists(json, 'chlamydiaAntigenRemark') ? undefined : json['chlamydiaAntigenRemark'],
        'groupBHemolyticStreptococcusCheck': !exists(json, 'groupBHemolyticStreptococcusCheck') ? undefined : json['groupBHemolyticStreptococcusCheck'],
        'groupBHemolyticStreptococcus': !exists(json, 'groupBHemolyticStreptococcus') ? undefined : json['groupBHemolyticStreptococcus'],
        'urineProtein': !exists(json, 'urineProtein') ? undefined : json['urineProtein'],
        'groupBHemolyticStreptococcusRemark': !exists(json, 'groupBHemolyticStreptococcusRemark') ? undefined : json['groupBHemolyticStreptococcusRemark'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'photoUrls': !exists(json, 'photoUrls') ? undefined : json['photoUrls'],
    };
}

export function PregnantDiagnosisToJSON(value?: PregnantDiagnosis | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'userId': value.userId,
        'pregnantId': value.pregnantId,
        'date': value.date,
        'examinationPlace': value.examinationPlace,
        'prenancyWeeks': value.prenancyWeeks,
        'prenancyDays': value.prenancyDays,
        'uterineCavityLength': value.uterineCavityLength,
        'waistCircumference': value.waistCircumference,
        'bodyWeight': value.bodyWeight,
        'bloodPressureMax': value.bloodPressureMax,
        'bloodPressureMin': value.bloodPressureMin,
        'bloodType': value.bloodType,
        'bloodRh': value.bloodRh,
        'edemaStatus': value.edemaStatus,
        'fetalWeight': value.fetalWeight,
        'obstetricPathologies': value.obstetricPathologies,
        'obstetricPathologiesNote': value.obstetricPathologiesNote,
        'irregularAntibodyCheck': value.irregularAntibodyCheck,
        'irregularAntibody': value.irregularAntibody,
        'irregularAntibodyRemark': value.irregularAntibodyRemark,
        'completeBloodCount': value.completeBloodCount,
        'bloodHemoglobin': value.bloodHemoglobin,
        'bloodHematocrit': value.bloodHematocrit,
        'bloodPlatelet': value.bloodPlatelet,
        'bloodRemark': value.bloodRemark,
        'cervicalCancerScreeningCheck': value.cervicalCancerScreeningCheck,
        'cervicalCancerScreening': value.cervicalCancerScreening,
        'cervicalCancerScreeningRemark': value.cervicalCancerScreeningRemark,
        'syphilisSerumReactionCheck': value.syphilisSerumReactionCheck,
        'syphilisSerumReaction': value.syphilisSerumReaction,
        'syphilisSerumReactionRemark': value.syphilisSerumReactionRemark,
        'hbsAntibodyCheck': value.hbsAntibodyCheck,
        'hbsAntibody': value.hbsAntibody,
        'hbsAntibodyRemark': value.hbsAntibodyRemark,
        'hcvAntibodyCheck': value.hcvAntibodyCheck,
        'hcvAntibody': value.hcvAntibody,
        'hcvAntibodyRemark': value.hcvAntibodyRemark,
        'hivAntibodyCheck': value.hivAntibodyCheck,
        'hivAntibody': value.hivAntibody,
        'hivAntibodyRemark': value.hivAntibodyRemark,
        'rubellaVirusAntibodyCheck': value.rubellaVirusAntibodyCheck,
        'rubellaVirusAntibody': value.rubellaVirusAntibody,
        'rubellaVirusAntibodyRemark': value.rubellaVirusAntibodyRemark,
        'htlv1AntibodyCheck': value.htlv1AntibodyCheck,
        'htlv1Antibody': value.htlv1Antibody,
        'htlv1AntibodyRemark': value.htlv1AntibodyRemark,
        'chlamydiaAntigenCheck': value.chlamydiaAntigenCheck,
        'chlamydiaAntigen': value.chlamydiaAntigen,
        'chlamydiaAntigenRemark': value.chlamydiaAntigenRemark,
        'groupBHemolyticStreptococcusCheck': value.groupBHemolyticStreptococcusCheck,
        'groupBHemolyticStreptococcus': value.groupBHemolyticStreptococcus,
        'urineProtein': value.urineProtein,
        'groupBHemolyticStreptococcusRemark': value.groupBHemolyticStreptococcusRemark,
        'note': value.note,
        'photoUrls': value.photoUrls,
    };
}

