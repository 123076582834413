/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InfantSixToSevenMonthOld,
    InfantSixToSevenMonthOldFromJSON,
    InfantSixToSevenMonthOldToJSON,
    InfantSixToSevenMonthOldPagination,
    InfantSixToSevenMonthOldPaginationFromJSON,
    InfantSixToSevenMonthOldPaginationToJSON,
} from '../models';

export interface AdminInfantSixToSevenMonthOldsIdGetRequest {
    id: number;
    userId?: number;
}

export interface InfantSixToSevenMonthOldsGetRequest {
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface InfantSixToSevenMonthOldsIdDeleteRequest {
    id: number;
}

export interface InfantSixToSevenMonthOldsIdGetRequest {
    id: number;
}

export interface InfantSixToSevenMonthOldsIdPutRequest {
    id: number;
    infantSixToSevenMonthOld?: InfantSixToSevenMonthOld;
}

export interface InfantSixToSevenMonthOldsPostRequest {
    infantSixToSevenMonthOld?: InfantSixToSevenMonthOld;
}

export interface InfantSixToSevenMonthOldsShareAllGetRequest {
    sourceUserId: number;
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface InfantSixToSevenMonthOldsShareDetailGetRequest {
    recordId: number;
    sourceUserId: number;
}

/**
 * 
 */
export class InfantSixToSevenMonthOldsApi extends runtime.BaseAPI {

    /**
     * 
     */
    adminInfantSixToSevenMonthOldsIdGetRaw = async (requestParameters: AdminInfantSixToSevenMonthOldsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantSixToSevenMonthOld>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling adminInfantSixToSevenMonthOldsIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/infantSixToSevenMonthOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantSixToSevenMonthOldFromJSON(jsonValue));
    }

    /**
     * 
     */
    adminInfantSixToSevenMonthOldsIdGet = async (requestParameters: AdminInfantSixToSevenMonthOldsIdGetRequest, initOverrides?: RequestInit): Promise<InfantSixToSevenMonthOld> => {
        const response = await this.adminInfantSixToSevenMonthOldsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantSixToSevenMonthOldsGetRaw = async (requestParameters: InfantSixToSevenMonthOldsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantSixToSevenMonthOldPagination>> => {
        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling infantSixToSevenMonthOldsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantSixToSevenMonthOlds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantSixToSevenMonthOldPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantSixToSevenMonthOldsGet = async (requestParameters: InfantSixToSevenMonthOldsGetRequest, initOverrides?: RequestInit): Promise<InfantSixToSevenMonthOldPagination> => {
        const response = await this.infantSixToSevenMonthOldsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    infantSixToSevenMonthOldsIdDeleteRaw = async (requestParameters: InfantSixToSevenMonthOldsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling infantSixToSevenMonthOldsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantSixToSevenMonthOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    infantSixToSevenMonthOldsIdDelete = async (requestParameters: InfantSixToSevenMonthOldsIdDeleteRequest, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.infantSixToSevenMonthOldsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantSixToSevenMonthOldsIdGetRaw = async (requestParameters: InfantSixToSevenMonthOldsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantSixToSevenMonthOld>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling infantSixToSevenMonthOldsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantSixToSevenMonthOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantSixToSevenMonthOldFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantSixToSevenMonthOldsIdGet = async (requestParameters: InfantSixToSevenMonthOldsIdGetRequest, initOverrides?: RequestInit): Promise<InfantSixToSevenMonthOld> => {
        const response = await this.infantSixToSevenMonthOldsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    infantSixToSevenMonthOldsIdPutRaw = async (requestParameters: InfantSixToSevenMonthOldsIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantSixToSevenMonthOld>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling infantSixToSevenMonthOldsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/infantSixToSevenMonthOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InfantSixToSevenMonthOldToJSON(requestParameters.infantSixToSevenMonthOld),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantSixToSevenMonthOldFromJSON(jsonValue));
    }

    /**
     */
    infantSixToSevenMonthOldsIdPut = async (requestParameters: InfantSixToSevenMonthOldsIdPutRequest, initOverrides?: RequestInit): Promise<InfantSixToSevenMonthOld> => {
        const response = await this.infantSixToSevenMonthOldsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    infantSixToSevenMonthOldsPostRaw = async (requestParameters: InfantSixToSevenMonthOldsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantSixToSevenMonthOld>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/infantSixToSevenMonthOlds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InfantSixToSevenMonthOldToJSON(requestParameters.infantSixToSevenMonthOld),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantSixToSevenMonthOldFromJSON(jsonValue));
    }

    /**
     */
    infantSixToSevenMonthOldsPost = async (requestParameters: InfantSixToSevenMonthOldsPostRequest = {}, initOverrides?: RequestInit): Promise<InfantSixToSevenMonthOld> => {
        const response = await this.infantSixToSevenMonthOldsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantSixToSevenMonthOldsShareAllGetRaw = async (requestParameters: InfantSixToSevenMonthOldsShareAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantSixToSevenMonthOldPagination>> => {
        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling infantSixToSevenMonthOldsShareAllGet.');
        }

        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling infantSixToSevenMonthOldsShareAllGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantSixToSevenMonthOlds/share/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantSixToSevenMonthOldPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantSixToSevenMonthOldsShareAllGet = async (requestParameters: InfantSixToSevenMonthOldsShareAllGetRequest, initOverrides?: RequestInit): Promise<InfantSixToSevenMonthOldPagination> => {
        const response = await this.infantSixToSevenMonthOldsShareAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantSixToSevenMonthOldsShareDetailGetRaw = async (requestParameters: InfantSixToSevenMonthOldsShareDetailGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantSixToSevenMonthOld>> => {
        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling infantSixToSevenMonthOldsShareDetailGet.');
        }

        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling infantSixToSevenMonthOldsShareDetailGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.recordId !== undefined) {
            queryParameters['recordId'] = requestParameters.recordId;
        }

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantSixToSevenMonthOlds/share/detail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantSixToSevenMonthOldFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantSixToSevenMonthOldsShareDetailGet = async (requestParameters: InfantSixToSevenMonthOldsShareDetailGetRequest, initOverrides?: RequestInit): Promise<InfantSixToSevenMonthOld> => {
        const response = await this.infantSixToSevenMonthOldsShareDetailGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
