/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CheckupData,
    CheckupDataFromJSON,
    CheckupDataToJSON,
    CheckupDataPagination,
    CheckupDataPaginationFromJSON,
    CheckupDataPaginationToJSON,
} from '../models';

export interface CheckupDataGetRequest {
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface CheckupDataIdDeleteRequest {
    id: number;
}

export interface CheckupDataIdGetRequest {
    id: number;
}

export interface CheckupDataIdPutRequest {
    id: number;
    checkupData?: CheckupData;
}

export interface CheckupDataPostRequest {
    checkupData?: CheckupData;
}

/**
 * 
 */
export class CheckupDataApi extends runtime.BaseAPI {

    /**
     * 
     */
    checkupDataGetRaw = async (requestParameters: CheckupDataGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CheckupDataPagination>> => {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/checkupData`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckupDataPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    checkupDataGet = async (requestParameters: CheckupDataGetRequest = {}, initOverrides?: RequestInit): Promise<CheckupDataPagination> => {
        const response = await this.checkupDataGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    checkupDataIdDeleteRaw = async (requestParameters: CheckupDataIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling checkupDataIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/checkupData/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    checkupDataIdDelete = async (requestParameters: CheckupDataIdDeleteRequest, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.checkupDataIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    checkupDataIdGetRaw = async (requestParameters: CheckupDataIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CheckupData>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling checkupDataIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/checkupData/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckupDataFromJSON(jsonValue));
    }

    /**
     * 
     */
    checkupDataIdGet = async (requestParameters: CheckupDataIdGetRequest, initOverrides?: RequestInit): Promise<CheckupData> => {
        const response = await this.checkupDataIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    checkupDataIdPutRaw = async (requestParameters: CheckupDataIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CheckupData>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling checkupDataIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/checkupData/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CheckupDataToJSON(requestParameters.checkupData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckupDataFromJSON(jsonValue));
    }

    /**
     */
    checkupDataIdPut = async (requestParameters: CheckupDataIdPutRequest, initOverrides?: RequestInit): Promise<CheckupData> => {
        const response = await this.checkupDataIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    checkupDataPostRaw = async (requestParameters: CheckupDataPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CheckupData>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/checkupData`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CheckupDataToJSON(requestParameters.checkupData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckupDataFromJSON(jsonValue));
    }

    /**
     */
    checkupDataPost = async (requestParameters: CheckupDataPostRequest = {}, initOverrides?: RequestInit): Promise<CheckupData> => {
        const response = await this.checkupDataPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
