/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InfantEarlyNeonatalPeriod } from './InfantEarlyNeonatalPeriod';
import {
    InfantEarlyNeonatalPeriodFromJSON,
    InfantEarlyNeonatalPeriodFromJSONTyped,
    InfantEarlyNeonatalPeriodToJSON,
} from './InfantEarlyNeonatalPeriod';
import type { PaginationInfo } from './PaginationInfo';
import {
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './PaginationInfo';

/**
 * 
 * @export
 * @interface InfantEarlyNeonatalPeriodPagination
 */
export interface InfantEarlyNeonatalPeriodPagination {
    /**
     * 
     * @type {Array<InfantEarlyNeonatalPeriod>}
     * @memberof InfantEarlyNeonatalPeriodPagination
     */
    data?: Array<InfantEarlyNeonatalPeriod>;
    /**
     * 
     * @type {PaginationInfo}
     * @memberof InfantEarlyNeonatalPeriodPagination
     */
    meta?: PaginationInfo;
}

/**
 * Check if a given object implements the InfantEarlyNeonatalPeriodPagination interface.
 */
export function instanceOfInfantEarlyNeonatalPeriodPagination(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InfantEarlyNeonatalPeriodPaginationFromJSON(json: any): InfantEarlyNeonatalPeriodPagination {
    return InfantEarlyNeonatalPeriodPaginationFromJSONTyped(json, false);
}

export function InfantEarlyNeonatalPeriodPaginationFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfantEarlyNeonatalPeriodPagination {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(InfantEarlyNeonatalPeriodFromJSON)),
        'meta': !exists(json, 'meta') ? undefined : PaginationInfoFromJSON(json['meta']),
    };
}

export function InfantEarlyNeonatalPeriodPaginationToJSON(value?: InfantEarlyNeonatalPeriodPagination | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(InfantEarlyNeonatalPeriodToJSON)),
        'meta': PaginationInfoToJSON(value.meta),
    };
}

