/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BodyData,
    BodyDataFromJSON,
    BodyDataToJSON,
    BodyDataPagination,
    BodyDataPaginationFromJSON,
    BodyDataPaginationToJSON,
} from '../models';

export interface AdminBodyDataGetRequest {
    fetusId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
    userId?: number;
}

export interface BodyDataGetRequest {
    fetusId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface BodyDataIdDeleteRequest {
    id: number;
}

export interface BodyDataIdGetRequest {
    id: number;
}

export interface BodyDataIdPutRequest {
    id: number;
    bodyData?: BodyData;
}

export interface BodyDataPatchRequest {
    bodyData?: BodyData;
}

export interface BodyDataShareAllGetRequest {
    sourceUserId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface BodyDataShareDetailGetRequest {
    sourceUserId: number;
    recordId: number;
}

/**
 * 
 */
export class BodyDataApi extends runtime.BaseAPI {

    /**
     * 
     */
    adminBodyDataGetRaw = async (requestParameters: AdminBodyDataGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BodyDataPagination>> => {
        if (requestParameters.fetusId === null || requestParameters.fetusId === undefined) {
            throw new runtime.RequiredError('fetusId','Required parameter requestParameters.fetusId was null or undefined when calling adminBodyDataGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.fetusId !== undefined) {
            queryParameters['fetusId'] = requestParameters.fetusId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/bodyData`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BodyDataPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    adminBodyDataGet = async (requestParameters: AdminBodyDataGetRequest, initOverrides?: RequestInit): Promise<BodyDataPagination> => {
        const response = await this.adminBodyDataGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    bodyDataGetRaw = async (requestParameters: BodyDataGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BodyDataPagination>> => {
        if (requestParameters.fetusId === null || requestParameters.fetusId === undefined) {
            throw new runtime.RequiredError('fetusId','Required parameter requestParameters.fetusId was null or undefined when calling bodyDataGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.fetusId !== undefined) {
            queryParameters['fetusId'] = requestParameters.fetusId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/bodyData`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BodyDataPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    bodyDataGet = async (requestParameters: BodyDataGetRequest, initOverrides?: RequestInit): Promise<BodyDataPagination> => {
        const response = await this.bodyDataGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    bodyDataIdDeleteRaw = async (requestParameters: BodyDataIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling bodyDataIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/bodyData/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    bodyDataIdDelete = async (requestParameters: BodyDataIdDeleteRequest, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.bodyDataIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    bodyDataIdGetRaw = async (requestParameters: BodyDataIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BodyData>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling bodyDataIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/bodyData/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BodyDataFromJSON(jsonValue));
    }

    /**
     * 
     */
    bodyDataIdGet = async (requestParameters: BodyDataIdGetRequest, initOverrides?: RequestInit): Promise<BodyData> => {
        const response = await this.bodyDataIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    bodyDataIdPutRaw = async (requestParameters: BodyDataIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BodyData>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling bodyDataIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/bodyData/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BodyDataToJSON(requestParameters.bodyData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BodyDataFromJSON(jsonValue));
    }

    /**
     */
    bodyDataIdPut = async (requestParameters: BodyDataIdPutRequest, initOverrides?: RequestInit): Promise<BodyData> => {
        const response = await this.bodyDataIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    bodyDataPatchRaw = async (requestParameters: BodyDataPatchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BodyData>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/bodyData`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: BodyDataToJSON(requestParameters.bodyData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BodyDataFromJSON(jsonValue));
    }

    /**
     */
    bodyDataPatch = async (requestParameters: BodyDataPatchRequest = {}, initOverrides?: RequestInit): Promise<BodyData> => {
        const response = await this.bodyDataPatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    bodyDataShareAllGetRaw = async (requestParameters: BodyDataShareAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BodyDataPagination>> => {
        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling bodyDataShareAllGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/bodyData/share/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BodyDataPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    bodyDataShareAllGet = async (requestParameters: BodyDataShareAllGetRequest, initOverrides?: RequestInit): Promise<BodyDataPagination> => {
        const response = await this.bodyDataShareAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    bodyDataShareDetailGetRaw = async (requestParameters: BodyDataShareDetailGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BodyDataPagination>> => {
        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling bodyDataShareDetailGet.');
        }

        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling bodyDataShareDetailGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        if (requestParameters.recordId !== undefined) {
            queryParameters['recordId'] = requestParameters.recordId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/bodyData/share/detail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BodyDataPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    bodyDataShareDetailGet = async (requestParameters: BodyDataShareDetailGetRequest, initOverrides?: RequestInit): Promise<BodyDataPagination> => {
        const response = await this.bodyDataShareDetailGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
