/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminAllOf } from './AdminAllOf';
import {
    AdminAllOfFromJSON,
    AdminAllOfFromJSONTyped,
    AdminAllOfToJSON,
} from './AdminAllOf';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';

/**
 * 
 * @export
 * @interface Admin
 */
export interface Admin {
    /**
     * 
     * @type {number}
     * @memberof Admin
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    staffName?: string;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    password?: string;
    /**
     * 
     * @type {number}
     * @memberof Admin
     */
    roleId?: number;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    roleName?: string;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    gender?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    birthday?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Admin
     */
    startDate?: string | null;
}

/**
 * Check if a given object implements the Admin interface.
 */
export function instanceOfAdmin(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AdminFromJSON(json: any): Admin {
    return AdminFromJSONTyped(json, false);
}

export function AdminFromJSONTyped(json: any, ignoreDiscriminator: boolean): Admin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'staffName': !exists(json, 'staffName') ? undefined : json['staffName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
        'roleName': !exists(json, 'roleName') ? undefined : json['roleName'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'birthday': !exists(json, 'birthday') ? undefined : json['birthday'],
        'startDate': !exists(json, 'startDate') ? undefined : json['startDate'],
    };
}

export function AdminToJSON(value?: Admin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'staffName': value.staffName,
        'email': value.email,
        'password': value.password,
        'roleId': value.roleId,
        'roleName': value.roleName,
        'gender': value.gender,
        'birthday': value.birthday,
        'startDate': value.startDate,
    };
}

