/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';
import type { InfantAfterOneWeekAllOf } from './InfantAfterOneWeekAllOf';
import {
    InfantAfterOneWeekAllOfFromJSON,
    InfantAfterOneWeekAllOfFromJSONTyped,
    InfantAfterOneWeekAllOfToJSON,
} from './InfantAfterOneWeekAllOf';

/**
 * 
 * @export
 * @interface InfantAfterOneWeek
 */
export interface InfantAfterOneWeek {
    /**
     * 
     * @type {number}
     * @memberof InfantAfterOneWeek
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InfantAfterOneWeek
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof InfantAfterOneWeek
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof InfantAfterOneWeek
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof InfantAfterOneWeek
     */
    babyId?: number;
    /**
     * 
     * @type {number}
     * @memberof InfantAfterOneWeek
     */
    ageId?: number;
    /**
     * 
     * @type {number}
     * @memberof InfantAfterOneWeek
     */
    age1?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantAfterOneWeek
     */
    bodyWeight1?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantAfterOneWeek
     */
    breastFeedingPower1?: InfantAfterOneWeekBreastFeedingPower1Enum;
    /**
     * 
     * @type {string}
     * @memberof InfantAfterOneWeek
     */
    jaundice1?: InfantAfterOneWeekJaundice1Enum;
    /**
     * 
     * @type {string}
     * @memberof InfantAfterOneWeek
     */
    others1?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InfantAfterOneWeek
     */
    age2?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantAfterOneWeek
     */
    bodyWeight2?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantAfterOneWeek
     */
    breastFeedingPower2?: InfantAfterOneWeekBreastFeedingPower2Enum;
    /**
     * 
     * @type {string}
     * @memberof InfantAfterOneWeek
     */
    jaundice2?: InfantAfterOneWeekJaundice2Enum;
    /**
     * 
     * @type {string}
     * @memberof InfantAfterOneWeek
     */
    others2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantAfterOneWeek
     */
    vitaminFirstDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantAfterOneWeek
     */
    vitaminSecondDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantAfterOneWeek
     */
    vitaminThirdDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantAfterOneWeek
     */
    otherAbnormalities?: InfantAfterOneWeekOtherAbnormalitiesEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantAfterOneWeek
     */
    abnormalContent?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantAfterOneWeek
     */
    treatment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantAfterOneWeek
     */
    dischargeDate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InfantAfterOneWeek
     */
    bodyWeight3?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantAfterOneWeek
     */
    nutritionMethod?: InfantAfterOneWeekNutritionMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantAfterOneWeek
     */
    requireObservation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantAfterOneWeek
     */
    facilityOrPersonName?: string | null;
}


/**
 * @export
 */
export const InfantAfterOneWeekBreastFeedingPower1Enum = {
    Usually: 'usually',
    Weak: 'weak'
} as const;
export type InfantAfterOneWeekBreastFeedingPower1Enum = typeof InfantAfterOneWeekBreastFeedingPower1Enum[keyof typeof InfantAfterOneWeekBreastFeedingPower1Enum];

/**
 * @export
 */
export const InfantAfterOneWeekJaundice1Enum = {
    None: 'none',
    Normal: 'normal',
    Strong: 'strong'
} as const;
export type InfantAfterOneWeekJaundice1Enum = typeof InfantAfterOneWeekJaundice1Enum[keyof typeof InfantAfterOneWeekJaundice1Enum];

/**
 * @export
 */
export const InfantAfterOneWeekBreastFeedingPower2Enum = {
    Usually: 'usually',
    Weak: 'weak'
} as const;
export type InfantAfterOneWeekBreastFeedingPower2Enum = typeof InfantAfterOneWeekBreastFeedingPower2Enum[keyof typeof InfantAfterOneWeekBreastFeedingPower2Enum];

/**
 * @export
 */
export const InfantAfterOneWeekJaundice2Enum = {
    None: 'none',
    Normal: 'normal',
    Strong: 'strong'
} as const;
export type InfantAfterOneWeekJaundice2Enum = typeof InfantAfterOneWeekJaundice2Enum[keyof typeof InfantAfterOneWeekJaundice2Enum];

/**
 * @export
 */
export const InfantAfterOneWeekOtherAbnormalitiesEnum = {
    None: 'none',
    CanBe: 'canBe'
} as const;
export type InfantAfterOneWeekOtherAbnormalitiesEnum = typeof InfantAfterOneWeekOtherAbnormalitiesEnum[keyof typeof InfantAfterOneWeekOtherAbnormalitiesEnum];

/**
 * @export
 */
export const InfantAfterOneWeekNutritionMethodEnum = {
    BreastMilk: 'breastMilk',
    Mixture: 'mixture',
    ArtificialMilk: 'artificialMilk'
} as const;
export type InfantAfterOneWeekNutritionMethodEnum = typeof InfantAfterOneWeekNutritionMethodEnum[keyof typeof InfantAfterOneWeekNutritionMethodEnum];


/**
 * Check if a given object implements the InfantAfterOneWeek interface.
 */
export function instanceOfInfantAfterOneWeek(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InfantAfterOneWeekFromJSON(json: any): InfantAfterOneWeek {
    return InfantAfterOneWeekFromJSONTyped(json, false);
}

export function InfantAfterOneWeekFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfantAfterOneWeek {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'babyId': !exists(json, 'babyId') ? undefined : json['babyId'],
        'ageId': !exists(json, 'ageId') ? undefined : json['ageId'],
        'age1': !exists(json, 'age1') ? undefined : json['age1'],
        'bodyWeight1': !exists(json, 'bodyWeight1') ? undefined : json['bodyWeight1'],
        'breastFeedingPower1': !exists(json, 'breastFeedingPower1') ? undefined : json['breastFeedingPower1'],
        'jaundice1': !exists(json, 'jaundice1') ? undefined : json['jaundice1'],
        'others1': !exists(json, 'others1') ? undefined : json['others1'],
        'age2': !exists(json, 'age2') ? undefined : json['age2'],
        'bodyWeight2': !exists(json, 'bodyWeight2') ? undefined : json['bodyWeight2'],
        'breastFeedingPower2': !exists(json, 'breastFeedingPower2') ? undefined : json['breastFeedingPower2'],
        'jaundice2': !exists(json, 'jaundice2') ? undefined : json['jaundice2'],
        'others2': !exists(json, 'others2') ? undefined : json['others2'],
        'vitaminFirstDate': !exists(json, 'vitaminFirstDate') ? undefined : json['vitaminFirstDate'],
        'vitaminSecondDate': !exists(json, 'vitaminSecondDate') ? undefined : json['vitaminSecondDate'],
        'vitaminThirdDate': !exists(json, 'vitaminThirdDate') ? undefined : json['vitaminThirdDate'],
        'otherAbnormalities': !exists(json, 'otherAbnormalities') ? undefined : json['otherAbnormalities'],
        'abnormalContent': !exists(json, 'abnormalContent') ? undefined : json['abnormalContent'],
        'treatment': !exists(json, 'treatment') ? undefined : json['treatment'],
        'dischargeDate': !exists(json, 'dischargeDate') ? undefined : json['dischargeDate'],
        'bodyWeight3': !exists(json, 'bodyWeight3') ? undefined : json['bodyWeight3'],
        'nutritionMethod': !exists(json, 'nutritionMethod') ? undefined : json['nutritionMethod'],
        'requireObservation': !exists(json, 'requireObservation') ? undefined : json['requireObservation'],
        'facilityOrPersonName': !exists(json, 'facilityOrPersonName') ? undefined : json['facilityOrPersonName'],
    };
}

export function InfantAfterOneWeekToJSON(value?: InfantAfterOneWeek | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'userId': value.userId,
        'babyId': value.babyId,
        'ageId': value.ageId,
        'age1': value.age1,
        'bodyWeight1': value.bodyWeight1,
        'breastFeedingPower1': value.breastFeedingPower1,
        'jaundice1': value.jaundice1,
        'others1': value.others1,
        'age2': value.age2,
        'bodyWeight2': value.bodyWeight2,
        'breastFeedingPower2': value.breastFeedingPower2,
        'jaundice2': value.jaundice2,
        'others2': value.others2,
        'vitaminFirstDate': value.vitaminFirstDate,
        'vitaminSecondDate': value.vitaminSecondDate,
        'vitaminThirdDate': value.vitaminThirdDate,
        'otherAbnormalities': value.otherAbnormalities,
        'abnormalContent': value.abnormalContent,
        'treatment': value.treatment,
        'dischargeDate': value.dischargeDate,
        'bodyWeight3': value.bodyWeight3,
        'nutritionMethod': value.nutritionMethod,
        'requireObservation': value.requireObservation,
        'facilityOrPersonName': value.facilityOrPersonName,
    };
}

