/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AdminRole,
    AdminRoleFromJSON,
    AdminRoleToJSON,
    AdminRolePagination,
    AdminRolePaginationFromJSON,
    AdminRolePaginationToJSON,
} from '../models';

export interface AdminAdminRolesGetRequest {
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface AdminAdminRolesIdGetRequest {
    id: number;
}

/**
 * 
 */
export class AdminRolesApi extends runtime.BaseAPI {

    /**
     * 
     */
    adminAdminRolesGetRaw = async (requestParameters: AdminAdminRolesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AdminRolePagination>> => {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/adminRoles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminRolePaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    adminAdminRolesGet = async (requestParameters: AdminAdminRolesGetRequest = {}, initOverrides?: RequestInit): Promise<AdminRolePagination> => {
        const response = await this.adminAdminRolesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    adminAdminRolesIdGetRaw = async (requestParameters: AdminAdminRolesIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AdminRole>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling adminAdminRolesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/adminRoles/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminRoleFromJSON(jsonValue));
    }

    /**
     * 
     */
    adminAdminRolesIdGet = async (requestParameters: AdminAdminRolesIdGetRequest, initOverrides?: RequestInit): Promise<AdminRole> => {
        const response = await this.adminAdminRolesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
