import React from "react";
import styled from "styled-components";
import { CardCustom, TextBlockCustom } from "@/components/display";
import { DrugTable } from "./components";

const StyledAdminListPage = styled.div``;

interface IDrugListPageProps {}

export const DrugListPage = (
  props: React.PropsWithChildren<IDrugListPageProps>
) => {
  return (
    <StyledAdminListPage>
      <TextBlockCustom
        classText="text-lg font-bold mb-8"
        textHeader="薬剤リスト"
        textSize={24}
      />
      <CardCustom>
        <DrugTable />
      </CardCustom>
    </StyledAdminListPage>
  );
};
