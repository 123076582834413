/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';
import type { InfantNineToTenMonthOldAllOf } from './InfantNineToTenMonthOldAllOf';
import {
    InfantNineToTenMonthOldAllOfFromJSON,
    InfantNineToTenMonthOldAllOfFromJSONTyped,
    InfantNineToTenMonthOldAllOfToJSON,
} from './InfantNineToTenMonthOldAllOf';

/**
 * 
 * @export
 * @interface InfantNineToTenMonthOld
 */
export interface InfantNineToTenMonthOld {
    /**
     * 
     * @type {number}
     * @memberof InfantNineToTenMonthOld
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InfantNineToTenMonthOld
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof InfantNineToTenMonthOld
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof InfantNineToTenMonthOld
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof InfantNineToTenMonthOld
     */
    babyId?: number;
    /**
     * 
     * @type {number}
     * @memberof InfantNineToTenMonthOld
     */
    ageId?: number;
    /**
     * 
     * @type {string}
     * @memberof InfantNineToTenMonthOld
     */
    implementationDate?: string;
    /**
     * 
     * @type {number}
     * @memberof InfantNineToTenMonthOld
     */
    month?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantNineToTenMonthOld
     */
    day?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantNineToTenMonthOld
     */
    photo1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantNineToTenMonthOld
     */
    photo2?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InfantNineToTenMonthOld
     */
    bodyWeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantNineToTenMonthOld
     */
    height?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantNineToTenMonthOld
     */
    kaupIndex?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantNineToTenMonthOld
     */
    chestCircumference?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantNineToTenMonthOld
     */
    headCircumference?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantNineToTenMonthOld
     */
    nutritionalStatus?: InfantNineToTenMonthOldNutritionalStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof InfantNineToTenMonthOld
     */
    babyFood?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantNineToTenMonthOld
     */
    teeth?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantNineToTenMonthOld
     */
    diseasesAndAbnormalitiesMouth?: InfantNineToTenMonthOldDiseasesAndAbnormalitiesMouthEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantNineToTenMonthOld
     */
    diseasesAndAbnormalitiesMouthDesc?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantNineToTenMonthOld
     */
    healthObservation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantNineToTenMonthOld
     */
    remarks?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantNineToTenMonthOld
     */
    facilityOrPersonName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantNineToTenMonthOld
     */
    dayCaregiver?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantNineToTenMonthOld
     */
    recordingDate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InfantNineToTenMonthOld
     */
    whenCrawlMonth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantNineToTenMonthOld
     */
    whenCrawlDay?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantNineToTenMonthOld
     */
    whenCaughtMonth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantNineToTenMonthOld
     */
    whenCaughtDay?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantNineToTenMonthOld
     */
    yourFingers?: InfantNineToTenMonthOldYourFingersEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantNineToTenMonthOld
     */
    playAlone?: InfantNineToTenMonthOldPlayAloneEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantNineToTenMonthOld
     */
    weaning?: InfantNineToTenMonthOldWeaningEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantNineToTenMonthOld
     */
    turnAround?: InfantNineToTenMonthOldTurnAroundEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantNineToTenMonthOld
     */
    followUp?: InfantNineToTenMonthOldFollowUpEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantNineToTenMonthOld
     */
    concerns?: InfantNineToTenMonthOldConcernsEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantNineToTenMonthOld
     */
    childRearing?: InfantNineToTenMonthOldChildRearingEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantNineToTenMonthOld
     */
    raisingChildren?: InfantNineToTenMonthOldRaisingChildrenEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantNineToTenMonthOld
     */
    yourGrowth?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InfantNineToTenMonthOld
     */
    beginOfTeeth?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantNineToTenMonthOld
     */
    rightMolarsMaxilla?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantNineToTenMonthOld
     */
    leftFrontToothMaxilla?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantNineToTenMonthOld
     */
    rightMolarsLowerJaw?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantNineToTenMonthOld
     */
    leftFrontToothLowerJaw?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantNineToTenMonthOld
     */
    photo3?: string | null;
}


/**
 * @export
 */
export const InfantNineToTenMonthOldNutritionalStatusEnum = {
    Good: 'good',
    InstructionRequired: 'instructionRequired'
} as const;
export type InfantNineToTenMonthOldNutritionalStatusEnum = typeof InfantNineToTenMonthOldNutritionalStatusEnum[keyof typeof InfantNineToTenMonthOldNutritionalStatusEnum];

/**
 * @export
 */
export const InfantNineToTenMonthOldDiseasesAndAbnormalitiesMouthEnum = {
    None: 'none',
    CanBe: 'canBe'
} as const;
export type InfantNineToTenMonthOldDiseasesAndAbnormalitiesMouthEnum = typeof InfantNineToTenMonthOldDiseasesAndAbnormalitiesMouthEnum[keyof typeof InfantNineToTenMonthOldDiseasesAndAbnormalitiesMouthEnum];

/**
 * @export
 */
export const InfantNineToTenMonthOldYourFingersEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantNineToTenMonthOldYourFingersEnum = typeof InfantNineToTenMonthOldYourFingersEnum[keyof typeof InfantNineToTenMonthOldYourFingersEnum];

/**
 * @export
 */
export const InfantNineToTenMonthOldPlayAloneEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantNineToTenMonthOldPlayAloneEnum = typeof InfantNineToTenMonthOldPlayAloneEnum[keyof typeof InfantNineToTenMonthOldPlayAloneEnum];

/**
 * @export
 */
export const InfantNineToTenMonthOldWeaningEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantNineToTenMonthOldWeaningEnum = typeof InfantNineToTenMonthOldWeaningEnum[keyof typeof InfantNineToTenMonthOldWeaningEnum];

/**
 * @export
 */
export const InfantNineToTenMonthOldTurnAroundEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantNineToTenMonthOldTurnAroundEnum = typeof InfantNineToTenMonthOldTurnAroundEnum[keyof typeof InfantNineToTenMonthOldTurnAroundEnum];

/**
 * @export
 */
export const InfantNineToTenMonthOldFollowUpEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantNineToTenMonthOldFollowUpEnum = typeof InfantNineToTenMonthOldFollowUpEnum[keyof typeof InfantNineToTenMonthOldFollowUpEnum];

/**
 * @export
 */
export const InfantNineToTenMonthOldConcernsEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantNineToTenMonthOldConcernsEnum = typeof InfantNineToTenMonthOldConcernsEnum[keyof typeof InfantNineToTenMonthOldConcernsEnum];

/**
 * @export
 */
export const InfantNineToTenMonthOldChildRearingEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantNineToTenMonthOldChildRearingEnum = typeof InfantNineToTenMonthOldChildRearingEnum[keyof typeof InfantNineToTenMonthOldChildRearingEnum];

/**
 * @export
 */
export const InfantNineToTenMonthOldRaisingChildrenEnum = {
    No: 'no',
    Yes: 'yes',
    CanNotSayAnything: 'canNotSayAnything'
} as const;
export type InfantNineToTenMonthOldRaisingChildrenEnum = typeof InfantNineToTenMonthOldRaisingChildrenEnum[keyof typeof InfantNineToTenMonthOldRaisingChildrenEnum];


/**
 * Check if a given object implements the InfantNineToTenMonthOld interface.
 */
export function instanceOfInfantNineToTenMonthOld(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InfantNineToTenMonthOldFromJSON(json: any): InfantNineToTenMonthOld {
    return InfantNineToTenMonthOldFromJSONTyped(json, false);
}

export function InfantNineToTenMonthOldFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfantNineToTenMonthOld {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'babyId': !exists(json, 'babyId') ? undefined : json['babyId'],
        'ageId': !exists(json, 'ageId') ? undefined : json['ageId'],
        'implementationDate': !exists(json, 'implementationDate') ? undefined : json['implementationDate'],
        'month': !exists(json, 'month') ? undefined : json['month'],
        'day': !exists(json, 'day') ? undefined : json['day'],
        'photo1': !exists(json, 'photo1') ? undefined : json['photo1'],
        'photo2': !exists(json, 'photo2') ? undefined : json['photo2'],
        'bodyWeight': !exists(json, 'bodyWeight') ? undefined : json['bodyWeight'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'kaupIndex': !exists(json, 'kaupIndex') ? undefined : json['kaupIndex'],
        'chestCircumference': !exists(json, 'chestCircumference') ? undefined : json['chestCircumference'],
        'headCircumference': !exists(json, 'headCircumference') ? undefined : json['headCircumference'],
        'nutritionalStatus': !exists(json, 'nutritionalStatus') ? undefined : json['nutritionalStatus'],
        'babyFood': !exists(json, 'babyFood') ? undefined : json['babyFood'],
        'teeth': !exists(json, 'teeth') ? undefined : json['teeth'],
        'diseasesAndAbnormalitiesMouth': !exists(json, 'diseasesAndAbnormalitiesMouth') ? undefined : json['diseasesAndAbnormalitiesMouth'],
        'diseasesAndAbnormalitiesMouthDesc': !exists(json, 'diseasesAndAbnormalitiesMouthDesc') ? undefined : json['diseasesAndAbnormalitiesMouthDesc'],
        'healthObservation': !exists(json, 'healthObservation') ? undefined : json['healthObservation'],
        'remarks': !exists(json, 'remarks') ? undefined : json['remarks'],
        'facilityOrPersonName': !exists(json, 'facilityOrPersonName') ? undefined : json['facilityOrPersonName'],
        'dayCaregiver': !exists(json, 'dayCaregiver') ? undefined : json['dayCaregiver'],
        'recordingDate': !exists(json, 'recordingDate') ? undefined : json['recordingDate'],
        'whenCrawlMonth': !exists(json, 'whenCrawlMonth') ? undefined : json['whenCrawlMonth'],
        'whenCrawlDay': !exists(json, 'whenCrawlDay') ? undefined : json['whenCrawlDay'],
        'whenCaughtMonth': !exists(json, 'whenCaughtMonth') ? undefined : json['whenCaughtMonth'],
        'whenCaughtDay': !exists(json, 'whenCaughtDay') ? undefined : json['whenCaughtDay'],
        'yourFingers': !exists(json, 'yourFingers') ? undefined : json['yourFingers'],
        'playAlone': !exists(json, 'playAlone') ? undefined : json['playAlone'],
        'weaning': !exists(json, 'weaning') ? undefined : json['weaning'],
        'turnAround': !exists(json, 'turnAround') ? undefined : json['turnAround'],
        'followUp': !exists(json, 'followUp') ? undefined : json['followUp'],
        'concerns': !exists(json, 'concerns') ? undefined : json['concerns'],
        'childRearing': !exists(json, 'childRearing') ? undefined : json['childRearing'],
        'raisingChildren': !exists(json, 'raisingChildren') ? undefined : json['raisingChildren'],
        'yourGrowth': !exists(json, 'yourGrowth') ? undefined : json['yourGrowth'],
        'beginOfTeeth': !exists(json, 'beginOfTeeth') ? undefined : json['beginOfTeeth'],
        'rightMolarsMaxilla': !exists(json, 'rightMolarsMaxilla') ? undefined : json['rightMolarsMaxilla'],
        'leftFrontToothMaxilla': !exists(json, 'leftFrontToothMaxilla') ? undefined : json['leftFrontToothMaxilla'],
        'rightMolarsLowerJaw': !exists(json, 'rightMolarsLowerJaw') ? undefined : json['rightMolarsLowerJaw'],
        'leftFrontToothLowerJaw': !exists(json, 'leftFrontToothLowerJaw') ? undefined : json['leftFrontToothLowerJaw'],
        'photo3': !exists(json, 'photo3') ? undefined : json['photo3'],
    };
}

export function InfantNineToTenMonthOldToJSON(value?: InfantNineToTenMonthOld | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'userId': value.userId,
        'babyId': value.babyId,
        'ageId': value.ageId,
        'implementationDate': value.implementationDate,
        'month': value.month,
        'day': value.day,
        'photo1': value.photo1,
        'photo2': value.photo2,
        'bodyWeight': value.bodyWeight,
        'height': value.height,
        'kaupIndex': value.kaupIndex,
        'chestCircumference': value.chestCircumference,
        'headCircumference': value.headCircumference,
        'nutritionalStatus': value.nutritionalStatus,
        'babyFood': value.babyFood,
        'teeth': value.teeth,
        'diseasesAndAbnormalitiesMouth': value.diseasesAndAbnormalitiesMouth,
        'diseasesAndAbnormalitiesMouthDesc': value.diseasesAndAbnormalitiesMouthDesc,
        'healthObservation': value.healthObservation,
        'remarks': value.remarks,
        'facilityOrPersonName': value.facilityOrPersonName,
        'dayCaregiver': value.dayCaregiver,
        'recordingDate': value.recordingDate,
        'whenCrawlMonth': value.whenCrawlMonth,
        'whenCrawlDay': value.whenCrawlDay,
        'whenCaughtMonth': value.whenCaughtMonth,
        'whenCaughtDay': value.whenCaughtDay,
        'yourFingers': value.yourFingers,
        'playAlone': value.playAlone,
        'weaning': value.weaning,
        'turnAround': value.turnAround,
        'followUp': value.followUp,
        'concerns': value.concerns,
        'childRearing': value.childRearing,
        'raisingChildren': value.raisingChildren,
        'yourGrowth': value.yourGrowth,
        'beginOfTeeth': value.beginOfTeeth,
        'rightMolarsMaxilla': value.rightMolarsMaxilla,
        'leftFrontToothMaxilla': value.leftFrontToothMaxilla,
        'rightMolarsLowerJaw': value.rightMolarsLowerJaw,
        'leftFrontToothLowerJaw': value.leftFrontToothLowerJaw,
        'photo3': value.photo3,
    };
}

