import React from "react";
import styled from "styled-components";
import { TextBlockCustom } from "./TextBlockCustom";

const StyledTeethSelectCustom = styled.div``;

export enum BloodSelectionType {
  Checkbox = "Checkbox",
  MultipleFields = "MultipleFields",
}

interface ITeethSelectCustomProps {
  fieldDescriptions?: string[];
  fieldType?: BloodSelectionType;
  initialValues?: (boolean | string)[];
}

const getItemDesc = (text: string) => {
  switch (text) {
    case "/":
      return (text = ": 健全歯");
    case "C":
      return (text = ": むし歯（未処置歯）");
    case "O":
      return (text = ": 処置歯");
    case "A":
      return (text = ": 喪失歯");
  }
};

export const TeethSelectCustom = (
  props: React.PropsWithChildren<ITeethSelectCustomProps>
) => {
  return (
    <StyledTeethSelectCustom>
      {props.initialValues &&
        props.initialValues?.map((initialItem, initialIndex) => {
          if (props.fieldType === BloodSelectionType.Checkbox) {
            if (initialItem === true) {
              return (
                <TextBlockCustom
                  key={initialIndex}
                  classText="mb-2"
                  textHeader={props.fieldDescriptions[initialIndex]}
                ></TextBlockCustom>
              );
            }
          } else if (props.fieldType === BloodSelectionType.MultipleFields) {
            if (initialItem !== null) {
              return (
                <TextBlockCustom
                  classText="mb-2"
                  key={initialIndex}
                  textHeader={
                    props.fieldDescriptions[initialIndex] +
                    " - " +
                    initialItem +
                    getItemDesc(initialItem.toString())
                  }
                ></TextBlockCustom>
              );
            }
          }
        })}
    </StyledTeethSelectCustom>
  );
};
