import { Mother, motherApi } from "@/api";
import { CardCustom, TextBlockCustom } from "@/components/display";
import { COLORS } from "@/constants";
import { getMatureGenderText } from "@/utils";
import { Avatar, Grid } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { useQuery } from "react-query";

interface IMomInfoProps {
  motherId?: number;
}

export const MomInfo = (props: React.PropsWithChildren<IMomInfoProps>) => {
  //--- State
  const [mother, setMother] = useState<Mother>();

  useQuery({
    queryKey: "GET_MOTHER_BY_ID",
    queryFn: () => motherApi.mothersAdminIdGet({ id: props.motherId }),
    onSuccess: (response) => {
      setMother(response);
    },
  });

  return (
    <CardCustom>
      <Grid container alignItems={"center"}>
        <Grid item xs={2}>
          <Avatar
            src={mother?.image ? mother?.image : ""}
            sx={{ width: 100, height: 100 }}
          />
        </Grid>

        <Grid item xs={10}>
          <Grid container spacing={2} className="divide-x">
            <Grid item xs>
              <TextBlockCustom
                classText="mb-2"
                textHeader="名前: "
                textContent={mother?.name}
                contentColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                textHeader="生年月日: "
                textContent={
                  mother?.birthday
                    ? moment(mother?.birthday).format("yyyy/MM/DD")
                    : ""
                }
                contentColor={COLORS.approxGrey}
              />
            </Grid>

            <Grid item xs>
              <TextBlockCustom
                classText="mb-2"
                textHeader="性別: "
                textContent={
                  mother?.gender ? getMatureGenderText(mother?.gender) : ""
                }
                contentColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                classText="whitespace-nowrap"
                textHeader="メール: "
                textContent={mother?.email}
                contentColor={COLORS.approxGrey}
              />
            </Grid>

            <Grid item xs>
              <TextBlockCustom
                classText="mb-2"
                textHeader="領域: "
                textContent={mother?.address}
                contentColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                textHeader="会員登録日: "
                textContent={
                  mother?.createdAt
                    ? moment(mother?.createdAt).format("yyyy/MM/DD")
                    : ""
                }
                contentColor={COLORS.approxGrey}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CardCustom>
  );
};
