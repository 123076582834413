/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AppleAuthRequestBody
 */
export interface AppleAuthRequestBody {
    /**
     * 
     * @type {string}
     * @memberof AppleAuthRequestBody
     */
    identityToken?: string;
}

/**
 * Check if a given object implements the AppleAuthRequestBody interface.
 */
export function instanceOfAppleAuthRequestBody(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AppleAuthRequestBodyFromJSON(json: any): AppleAuthRequestBody {
    return AppleAuthRequestBodyFromJSONTyped(json, false);
}

export function AppleAuthRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): AppleAuthRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'identityToken': !exists(json, 'identityToken') ? undefined : json['identityToken'],
    };
}

export function AppleAuthRequestBodyToJSON(value?: AppleAuthRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'identityToken': value.identityToken,
    };
}

