/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';
import type { InfantFiveYearOldAllOf } from './InfantFiveYearOldAllOf';
import {
    InfantFiveYearOldAllOfFromJSON,
    InfantFiveYearOldAllOfFromJSONTyped,
    InfantFiveYearOldAllOfToJSON,
} from './InfantFiveYearOldAllOf';

/**
 * 
 * @export
 * @interface InfantFiveYearOld
 */
export interface InfantFiveYearOld {
    /**
     * 
     * @type {number}
     * @memberof InfantFiveYearOld
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof InfantFiveYearOld
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof InfantFiveYearOld
     */
    babyId?: number;
    /**
     * 
     * @type {number}
     * @memberof InfantFiveYearOld
     */
    ageId?: number;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    implementationDate?: string;
    /**
     * 
     * @type {number}
     * @memberof InfantFiveYearOld
     */
    year?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantFiveYearOld
     */
    month?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    photo1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    photo2?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InfantFiveYearOld
     */
    bodyWeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantFiveYearOld
     */
    height?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantFiveYearOld
     */
    kaupIndex?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantFiveYearOld
     */
    headCircumference?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    nutritionalStatus?: InfantFiveYearOldNutritionalStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    eyeAbnormalities?: InfantFiveYearOldEyeAbnormalitiesEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    eyeAbnormalitiesNote?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InfantFiveYearOld
     */
    sightLeft?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantFiveYearOld
     */
    sightRight?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    earAbnormalities?: InfantFiveYearOldEarAbnormalitiesEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    earAbnormalitiesNote?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    healthObservation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    remarks?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    facilityOrPersonName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    dayCaregiver?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    cariesRequiringTreatment?: InfantFiveYearOldCariesRequiringTreatmentEnum;
    /**
     * 
     * @type {number}
     * @memberof InfantFiveYearOld
     */
    milkTeeth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantFiveYearOld
     */
    permanentTeeth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantFiveYearOld
     */
    total?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    dirtyTeeth?: InfantFiveYearOldDirtyTeethEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    gingivaMucousMembrane?: InfantFiveYearOldGingivaMucousMembraneEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    gingivaMucousMembraneNote?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    engagement?: InfantFiveYearOldEngagementEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    abnormalToothOralDisease?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    examinationDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    rightMolarsMaxillaA?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    rightMolarsMaxillaB?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    leftFrontToothMaxillaA?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    leftFrontToothMaxillaB?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    rightMolarsLowerJawA?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    rightMolarsLowerJawB?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    leftFrontToothLowerJawA?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    leftFrontToothLowerJawB?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    urineProtein?: InfantFiveYearOldUrineProteinEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    urineSugar?: InfantFiveYearOldUrineSugarEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    urineOccultBlood?: InfantFiveYearOldUrineOccultBloodEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    whiteBloodCells?: InfantFiveYearOldWhiteBloodCellsEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    parentMessage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    recordingDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    turnItBack?: InfantFiveYearOldTurnItBackEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    rememberAndDrawPicture?: InfantFiveYearOldRememberAndDrawPictureEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    knowTheColors?: InfantFiveYearOldKnowTheColorsEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    speakClear?: InfantFiveYearOldSpeakClearEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    poopAlone?: InfantFiveYearOldPoopAloneEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    liftOfKindergartens?: InfantFiveYearOldLiftOfKindergartensEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    loveAnimalsAndFlowers?: InfantFiveYearOldLoveAnimalsAndFlowersEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    eatWithFamily?: InfantFiveYearOldEatWithFamilyEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    brushYourTeeth?: InfantFiveYearOldBrushYourTeethEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    suckYourFingers?: InfantFiveYearOldSuckYourFingersEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    understandTheContent?: InfantFiveYearOldUnderstandTheContentEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    childRearing?: InfantFiveYearOldChildRearingEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    raisingChildren?: InfantFiveYearOldRaisingChildrenEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    yourGrowth?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantFiveYearOld
     */
    photo3?: string | null;
}


/**
 * @export
 */
export const InfantFiveYearOldNutritionalStatusEnum = {
    ALittleFluffy: 'aLittleFluffy',
    Usually: 'usually',
    Skinny: 'skinny'
} as const;
export type InfantFiveYearOldNutritionalStatusEnum = typeof InfantFiveYearOldNutritionalStatusEnum[keyof typeof InfantFiveYearOldNutritionalStatusEnum];

/**
 * @export
 */
export const InfantFiveYearOldEyeAbnormalitiesEnum = {
    None: 'none',
    CanBe: 'canBe',
    Suspicion: 'suspicion'
} as const;
export type InfantFiveYearOldEyeAbnormalitiesEnum = typeof InfantFiveYearOldEyeAbnormalitiesEnum[keyof typeof InfantFiveYearOldEyeAbnormalitiesEnum];

/**
 * @export
 */
export const InfantFiveYearOldEarAbnormalitiesEnum = {
    None: 'none',
    CanBe: 'canBe',
    Suspicion: 'suspicion'
} as const;
export type InfantFiveYearOldEarAbnormalitiesEnum = typeof InfantFiveYearOldEarAbnormalitiesEnum[keyof typeof InfantFiveYearOldEarAbnormalitiesEnum];

/**
 * @export
 */
export const InfantFiveYearOldCariesRequiringTreatmentEnum = {
    None: 'none',
    CanBe: 'canBe',
    NotClear: 'notClear'
} as const;
export type InfantFiveYearOldCariesRequiringTreatmentEnum = typeof InfantFiveYearOldCariesRequiringTreatmentEnum[keyof typeof InfantFiveYearOldCariesRequiringTreatmentEnum];

/**
 * @export
 */
export const InfantFiveYearOldDirtyTeethEnum = {
    Beautiful: 'beautiful',
    Few: 'few',
    Many: 'many'
} as const;
export type InfantFiveYearOldDirtyTeethEnum = typeof InfantFiveYearOldDirtyTeethEnum[keyof typeof InfantFiveYearOldDirtyTeethEnum];

/**
 * @export
 */
export const InfantFiveYearOldGingivaMucousMembraneEnum = {
    NoAbnormality: 'noAbnormality',
    CanBe: 'canBe',
    NotClear: 'notClear'
} as const;
export type InfantFiveYearOldGingivaMucousMembraneEnum = typeof InfantFiveYearOldGingivaMucousMembraneEnum[keyof typeof InfantFiveYearOldGingivaMucousMembraneEnum];

/**
 * @export
 */
export const InfantFiveYearOldEngagementEnum = {
    Good: 'good',
    FollowUp: 'followUp',
    NotClear: 'notClear'
} as const;
export type InfantFiveYearOldEngagementEnum = typeof InfantFiveYearOldEngagementEnum[keyof typeof InfantFiveYearOldEngagementEnum];

/**
 * @export
 */
export const InfantFiveYearOldUrineProteinEnum = {
    Minus: 'minus',
    PlusAndMinus: 'plusAndMinus',
    Plus: 'plus',
    DoublePlus: 'doublePlus'
} as const;
export type InfantFiveYearOldUrineProteinEnum = typeof InfantFiveYearOldUrineProteinEnum[keyof typeof InfantFiveYearOldUrineProteinEnum];

/**
 * @export
 */
export const InfantFiveYearOldUrineSugarEnum = {
    Minus: 'minus',
    PlusAndMinus: 'plusAndMinus',
    Plus: 'plus',
    DoublePlus: 'doublePlus'
} as const;
export type InfantFiveYearOldUrineSugarEnum = typeof InfantFiveYearOldUrineSugarEnum[keyof typeof InfantFiveYearOldUrineSugarEnum];

/**
 * @export
 */
export const InfantFiveYearOldUrineOccultBloodEnum = {
    Minus: 'minus',
    PlusAndMinus: 'plusAndMinus',
    Plus: 'plus',
    DoublePlus: 'doublePlus'
} as const;
export type InfantFiveYearOldUrineOccultBloodEnum = typeof InfantFiveYearOldUrineOccultBloodEnum[keyof typeof InfantFiveYearOldUrineOccultBloodEnum];

/**
 * @export
 */
export const InfantFiveYearOldWhiteBloodCellsEnum = {
    Minus: 'minus',
    PlusAndMinus: 'plusAndMinus',
    Plus: 'plus',
    DoublePlus: 'doublePlus'
} as const;
export type InfantFiveYearOldWhiteBloodCellsEnum = typeof InfantFiveYearOldWhiteBloodCellsEnum[keyof typeof InfantFiveYearOldWhiteBloodCellsEnum];

/**
 * @export
 */
export const InfantFiveYearOldTurnItBackEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantFiveYearOldTurnItBackEnum = typeof InfantFiveYearOldTurnItBackEnum[keyof typeof InfantFiveYearOldTurnItBackEnum];

/**
 * @export
 */
export const InfantFiveYearOldRememberAndDrawPictureEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantFiveYearOldRememberAndDrawPictureEnum = typeof InfantFiveYearOldRememberAndDrawPictureEnum[keyof typeof InfantFiveYearOldRememberAndDrawPictureEnum];

/**
 * @export
 */
export const InfantFiveYearOldKnowTheColorsEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantFiveYearOldKnowTheColorsEnum = typeof InfantFiveYearOldKnowTheColorsEnum[keyof typeof InfantFiveYearOldKnowTheColorsEnum];

/**
 * @export
 */
export const InfantFiveYearOldSpeakClearEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantFiveYearOldSpeakClearEnum = typeof InfantFiveYearOldSpeakClearEnum[keyof typeof InfantFiveYearOldSpeakClearEnum];

/**
 * @export
 */
export const InfantFiveYearOldPoopAloneEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantFiveYearOldPoopAloneEnum = typeof InfantFiveYearOldPoopAloneEnum[keyof typeof InfantFiveYearOldPoopAloneEnum];

/**
 * @export
 */
export const InfantFiveYearOldLiftOfKindergartensEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantFiveYearOldLiftOfKindergartensEnum = typeof InfantFiveYearOldLiftOfKindergartensEnum[keyof typeof InfantFiveYearOldLiftOfKindergartensEnum];

/**
 * @export
 */
export const InfantFiveYearOldLoveAnimalsAndFlowersEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantFiveYearOldLoveAnimalsAndFlowersEnum = typeof InfantFiveYearOldLoveAnimalsAndFlowersEnum[keyof typeof InfantFiveYearOldLoveAnimalsAndFlowersEnum];

/**
 * @export
 */
export const InfantFiveYearOldEatWithFamilyEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantFiveYearOldEatWithFamilyEnum = typeof InfantFiveYearOldEatWithFamilyEnum[keyof typeof InfantFiveYearOldEatWithFamilyEnum];

/**
 * @export
 */
export const InfantFiveYearOldBrushYourTeethEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantFiveYearOldBrushYourTeethEnum = typeof InfantFiveYearOldBrushYourTeethEnum[keyof typeof InfantFiveYearOldBrushYourTeethEnum];

/**
 * @export
 */
export const InfantFiveYearOldSuckYourFingersEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantFiveYearOldSuckYourFingersEnum = typeof InfantFiveYearOldSuckYourFingersEnum[keyof typeof InfantFiveYearOldSuckYourFingersEnum];

/**
 * @export
 */
export const InfantFiveYearOldUnderstandTheContentEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantFiveYearOldUnderstandTheContentEnum = typeof InfantFiveYearOldUnderstandTheContentEnum[keyof typeof InfantFiveYearOldUnderstandTheContentEnum];

/**
 * @export
 */
export const InfantFiveYearOldChildRearingEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantFiveYearOldChildRearingEnum = typeof InfantFiveYearOldChildRearingEnum[keyof typeof InfantFiveYearOldChildRearingEnum];

/**
 * @export
 */
export const InfantFiveYearOldRaisingChildrenEnum = {
    No: 'no',
    Yes: 'yes',
    CanNotSayAnything: 'canNotSayAnything'
} as const;
export type InfantFiveYearOldRaisingChildrenEnum = typeof InfantFiveYearOldRaisingChildrenEnum[keyof typeof InfantFiveYearOldRaisingChildrenEnum];


/**
 * Check if a given object implements the InfantFiveYearOld interface.
 */
export function instanceOfInfantFiveYearOld(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InfantFiveYearOldFromJSON(json: any): InfantFiveYearOld {
    return InfantFiveYearOldFromJSONTyped(json, false);
}

export function InfantFiveYearOldFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfantFiveYearOld {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'babyId': !exists(json, 'babyId') ? undefined : json['babyId'],
        'ageId': !exists(json, 'ageId') ? undefined : json['ageId'],
        'implementationDate': !exists(json, 'implementationDate') ? undefined : json['implementationDate'],
        'year': !exists(json, 'year') ? undefined : json['year'],
        'month': !exists(json, 'month') ? undefined : json['month'],
        'photo1': !exists(json, 'photo1') ? undefined : json['photo1'],
        'photo2': !exists(json, 'photo2') ? undefined : json['photo2'],
        'bodyWeight': !exists(json, 'bodyWeight') ? undefined : json['bodyWeight'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'kaupIndex': !exists(json, 'kaupIndex') ? undefined : json['kaupIndex'],
        'headCircumference': !exists(json, 'headCircumference') ? undefined : json['headCircumference'],
        'nutritionalStatus': !exists(json, 'nutritionalStatus') ? undefined : json['nutritionalStatus'],
        'eyeAbnormalities': !exists(json, 'eyeAbnormalities') ? undefined : json['eyeAbnormalities'],
        'eyeAbnormalitiesNote': !exists(json, 'eyeAbnormalitiesNote') ? undefined : json['eyeAbnormalitiesNote'],
        'sightLeft': !exists(json, 'sightLeft') ? undefined : json['sightLeft'],
        'sightRight': !exists(json, 'sightRight') ? undefined : json['sightRight'],
        'earAbnormalities': !exists(json, 'earAbnormalities') ? undefined : json['earAbnormalities'],
        'earAbnormalitiesNote': !exists(json, 'earAbnormalitiesNote') ? undefined : json['earAbnormalitiesNote'],
        'healthObservation': !exists(json, 'healthObservation') ? undefined : json['healthObservation'],
        'remarks': !exists(json, 'remarks') ? undefined : json['remarks'],
        'facilityOrPersonName': !exists(json, 'facilityOrPersonName') ? undefined : json['facilityOrPersonName'],
        'dayCaregiver': !exists(json, 'dayCaregiver') ? undefined : json['dayCaregiver'],
        'cariesRequiringTreatment': !exists(json, 'cariesRequiringTreatment') ? undefined : json['cariesRequiringTreatment'],
        'milkTeeth': !exists(json, 'milkTeeth') ? undefined : json['milkTeeth'],
        'permanentTeeth': !exists(json, 'permanentTeeth') ? undefined : json['permanentTeeth'],
        'total': !exists(json, 'total') ? undefined : json['total'],
        'dirtyTeeth': !exists(json, 'dirtyTeeth') ? undefined : json['dirtyTeeth'],
        'gingivaMucousMembrane': !exists(json, 'gingivaMucousMembrane') ? undefined : json['gingivaMucousMembrane'],
        'gingivaMucousMembraneNote': !exists(json, 'gingivaMucousMembraneNote') ? undefined : json['gingivaMucousMembraneNote'],
        'engagement': !exists(json, 'engagement') ? undefined : json['engagement'],
        'abnormalToothOralDisease': !exists(json, 'abnormalToothOralDisease') ? undefined : json['abnormalToothOralDisease'],
        'examinationDate': !exists(json, 'examinationDate') ? undefined : json['examinationDate'],
        'rightMolarsMaxillaA': !exists(json, 'rightMolarsMaxillaA') ? undefined : json['rightMolarsMaxillaA'],
        'rightMolarsMaxillaB': !exists(json, 'rightMolarsMaxillaB') ? undefined : json['rightMolarsMaxillaB'],
        'leftFrontToothMaxillaA': !exists(json, 'leftFrontToothMaxillaA') ? undefined : json['leftFrontToothMaxillaA'],
        'leftFrontToothMaxillaB': !exists(json, 'leftFrontToothMaxillaB') ? undefined : json['leftFrontToothMaxillaB'],
        'rightMolarsLowerJawA': !exists(json, 'rightMolarsLowerJawA') ? undefined : json['rightMolarsLowerJawA'],
        'rightMolarsLowerJawB': !exists(json, 'rightMolarsLowerJawB') ? undefined : json['rightMolarsLowerJawB'],
        'leftFrontToothLowerJawA': !exists(json, 'leftFrontToothLowerJawA') ? undefined : json['leftFrontToothLowerJawA'],
        'leftFrontToothLowerJawB': !exists(json, 'leftFrontToothLowerJawB') ? undefined : json['leftFrontToothLowerJawB'],
        'urineProtein': !exists(json, 'urineProtein') ? undefined : json['urineProtein'],
        'urineSugar': !exists(json, 'urineSugar') ? undefined : json['urineSugar'],
        'urineOccultBlood': !exists(json, 'urineOccultBlood') ? undefined : json['urineOccultBlood'],
        'whiteBloodCells': !exists(json, 'whiteBloodCells') ? undefined : json['whiteBloodCells'],
        'parentMessage': !exists(json, 'parentMessage') ? undefined : json['parentMessage'],
        'recordingDate': !exists(json, 'recordingDate') ? undefined : json['recordingDate'],
        'turnItBack': !exists(json, 'turnItBack') ? undefined : json['turnItBack'],
        'rememberAndDrawPicture': !exists(json, 'rememberAndDrawPicture') ? undefined : json['rememberAndDrawPicture'],
        'knowTheColors': !exists(json, 'knowTheColors') ? undefined : json['knowTheColors'],
        'speakClear': !exists(json, 'speakClear') ? undefined : json['speakClear'],
        'poopAlone': !exists(json, 'poopAlone') ? undefined : json['poopAlone'],
        'liftOfKindergartens': !exists(json, 'liftOfKindergartens') ? undefined : json['liftOfKindergartens'],
        'loveAnimalsAndFlowers': !exists(json, 'loveAnimalsAndFlowers') ? undefined : json['loveAnimalsAndFlowers'],
        'eatWithFamily': !exists(json, 'eatWithFamily') ? undefined : json['eatWithFamily'],
        'brushYourTeeth': !exists(json, 'brushYourTeeth') ? undefined : json['brushYourTeeth'],
        'suckYourFingers': !exists(json, 'suckYourFingers') ? undefined : json['suckYourFingers'],
        'understandTheContent': !exists(json, 'understandTheContent') ? undefined : json['understandTheContent'],
        'childRearing': !exists(json, 'childRearing') ? undefined : json['childRearing'],
        'raisingChildren': !exists(json, 'raisingChildren') ? undefined : json['raisingChildren'],
        'yourGrowth': !exists(json, 'yourGrowth') ? undefined : json['yourGrowth'],
        'photo3': !exists(json, 'photo3') ? undefined : json['photo3'],
    };
}

export function InfantFiveYearOldToJSON(value?: InfantFiveYearOld | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'userId': value.userId,
        'babyId': value.babyId,
        'ageId': value.ageId,
        'implementationDate': value.implementationDate,
        'year': value.year,
        'month': value.month,
        'photo1': value.photo1,
        'photo2': value.photo2,
        'bodyWeight': value.bodyWeight,
        'height': value.height,
        'kaupIndex': value.kaupIndex,
        'headCircumference': value.headCircumference,
        'nutritionalStatus': value.nutritionalStatus,
        'eyeAbnormalities': value.eyeAbnormalities,
        'eyeAbnormalitiesNote': value.eyeAbnormalitiesNote,
        'sightLeft': value.sightLeft,
        'sightRight': value.sightRight,
        'earAbnormalities': value.earAbnormalities,
        'earAbnormalitiesNote': value.earAbnormalitiesNote,
        'healthObservation': value.healthObservation,
        'remarks': value.remarks,
        'facilityOrPersonName': value.facilityOrPersonName,
        'dayCaregiver': value.dayCaregiver,
        'cariesRequiringTreatment': value.cariesRequiringTreatment,
        'milkTeeth': value.milkTeeth,
        'permanentTeeth': value.permanentTeeth,
        'total': value.total,
        'dirtyTeeth': value.dirtyTeeth,
        'gingivaMucousMembrane': value.gingivaMucousMembrane,
        'gingivaMucousMembraneNote': value.gingivaMucousMembraneNote,
        'engagement': value.engagement,
        'abnormalToothOralDisease': value.abnormalToothOralDisease,
        'examinationDate': value.examinationDate,
        'rightMolarsMaxillaA': value.rightMolarsMaxillaA,
        'rightMolarsMaxillaB': value.rightMolarsMaxillaB,
        'leftFrontToothMaxillaA': value.leftFrontToothMaxillaA,
        'leftFrontToothMaxillaB': value.leftFrontToothMaxillaB,
        'rightMolarsLowerJawA': value.rightMolarsLowerJawA,
        'rightMolarsLowerJawB': value.rightMolarsLowerJawB,
        'leftFrontToothLowerJawA': value.leftFrontToothLowerJawA,
        'leftFrontToothLowerJawB': value.leftFrontToothLowerJawB,
        'urineProtein': value.urineProtein,
        'urineSugar': value.urineSugar,
        'urineOccultBlood': value.urineOccultBlood,
        'whiteBloodCells': value.whiteBloodCells,
        'parentMessage': value.parentMessage,
        'recordingDate': value.recordingDate,
        'turnItBack': value.turnItBack,
        'rememberAndDrawPicture': value.rememberAndDrawPicture,
        'knowTheColors': value.knowTheColors,
        'speakClear': value.speakClear,
        'poopAlone': value.poopAlone,
        'liftOfKindergartens': value.liftOfKindergartens,
        'loveAnimalsAndFlowers': value.loveAnimalsAndFlowers,
        'eatWithFamily': value.eatWithFamily,
        'brushYourTeeth': value.brushYourTeeth,
        'suckYourFingers': value.suckYourFingers,
        'understandTheContent': value.understandTheContent,
        'childRearing': value.childRearing,
        'raisingChildren': value.raisingChildren,
        'yourGrowth': value.yourGrowth,
        'photo3': value.photo3,
    };
}

