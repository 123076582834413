/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';
import type { InfantOneYearOldAllOf } from './InfantOneYearOldAllOf';
import {
    InfantOneYearOldAllOfFromJSON,
    InfantOneYearOldAllOfFromJSONTyped,
    InfantOneYearOldAllOfToJSON,
} from './InfantOneYearOldAllOf';

/**
 * 
 * @export
 * @interface InfantOneYearOld
 */
export interface InfantOneYearOld {
    /**
     * 
     * @type {number}
     * @memberof InfantOneYearOld
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InfantOneYearOld
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof InfantOneYearOld
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof InfantOneYearOld
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof InfantOneYearOld
     */
    babyId?: number;
    /**
     * 
     * @type {number}
     * @memberof InfantOneYearOld
     */
    ageId?: number;
    /**
     * 
     * @type {string}
     * @memberof InfantOneYearOld
     */
    implementationDate?: string;
    /**
     * 
     * @type {number}
     * @memberof InfantOneYearOld
     */
    year?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantOneYearOld
     */
    month?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneYearOld
     */
    photo1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneYearOld
     */
    photo2?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InfantOneYearOld
     */
    bodyWeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantOneYearOld
     */
    height?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantOneYearOld
     */
    kaupIndex?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantOneYearOld
     */
    chestCircumference?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantOneYearOld
     */
    headCircumference?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneYearOld
     */
    nutritionalStatus?: InfantOneYearOldNutritionalStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneYearOld
     */
    breastMilk?: InfantOneYearOldBreastMilkEnum;
    /**
     * 
     * @type {number}
     * @memberof InfantOneYearOld
     */
    mealDay?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantOneYearOld
     */
    snacks?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneYearOld
     */
    eyeAbnormalities?: InfantOneYearOldEyeAbnormalitiesEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneYearOld
     */
    eyeAbnormalitiesNote?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneYearOld
     */
    healthObservation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneYearOld
     */
    remarks?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneYearOld
     */
    facilityOrPersonName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneYearOld
     */
    dayCaregiver?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneYearOld
     */
    cariesRequiringTreatment?: InfantOneYearOldCariesRequiringTreatmentEnum;
    /**
     * 
     * @type {number}
     * @memberof InfantOneYearOld
     */
    milkTeeth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantOneYearOld
     */
    permanentTeeth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantOneYearOld
     */
    total?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneYearOld
     */
    dirtyTeeth?: InfantOneYearOldDirtyTeethEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneYearOld
     */
    gingivaMucousMembrane?: InfantOneYearOldGingivaMucousMembraneEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneYearOld
     */
    gingivaMucousMembraneNote?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneYearOld
     */
    engagement?: InfantOneYearOldEngagementEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneYearOld
     */
    examinationDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneYearOld
     */
    rightMolarsMaxilla?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneYearOld
     */
    leftFrontToothMaxilla?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneYearOld
     */
    rightMolarsLowerJaw?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneYearOld
     */
    leftFrontToothLowerJaw?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneYearOld
     */
    parentMessage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneYearOld
     */
    recordingDate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InfantOneYearOld
     */
    workHardMonth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantOneYearOld
     */
    workHardDay?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneYearOld
     */
    makeGestures?: InfantOneYearOldMakeGesturesEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneYearOld
     */
    bodyMusic?: InfantOneYearOldBodyMusicEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneYearOld
     */
    simpleWords?: InfantOneYearOldSimpleWordsEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneYearOld
     */
    pointToToy?: InfantOneYearOldPointToToyEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneYearOld
     */
    happyToPlay?: InfantOneYearOldHappyToPlayEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneYearOld
     */
    kindOfPlay?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneYearOld
     */
    rhythmOfEat?: InfantOneYearOldRhythmOfEatEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneYearOld
     */
    brushYourTeeth?: InfantOneYearOldBrushYourTeethEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneYearOld
     */
    childRearing?: InfantOneYearOldChildRearingEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneYearOld
     */
    raisingChildren?: InfantOneYearOldRaisingChildrenEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneYearOld
     */
    yourGrowth?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneYearOld
     */
    photo3?: string | null;
}


/**
 * @export
 */
export const InfantOneYearOldNutritionalStatusEnum = {
    Good: 'good',
    InstructionRequired: 'instructionRequired'
} as const;
export type InfantOneYearOldNutritionalStatusEnum = typeof InfantOneYearOldNutritionalStatusEnum[keyof typeof InfantOneYearOldNutritionalStatusEnum];

/**
 * @export
 */
export const InfantOneYearOldBreastMilkEnum = {
    NotDrink: 'notDrink',
    Drink: 'drink'
} as const;
export type InfantOneYearOldBreastMilkEnum = typeof InfantOneYearOldBreastMilkEnum[keyof typeof InfantOneYearOldBreastMilkEnum];

/**
 * @export
 */
export const InfantOneYearOldEyeAbnormalitiesEnum = {
    None: 'none',
    CanBe: 'canBe',
    Suspicion: 'suspicion'
} as const;
export type InfantOneYearOldEyeAbnormalitiesEnum = typeof InfantOneYearOldEyeAbnormalitiesEnum[keyof typeof InfantOneYearOldEyeAbnormalitiesEnum];

/**
 * @export
 */
export const InfantOneYearOldCariesRequiringTreatmentEnum = {
    None: 'none',
    CanBe: 'canBe',
    NotClear: 'notClear'
} as const;
export type InfantOneYearOldCariesRequiringTreatmentEnum = typeof InfantOneYearOldCariesRequiringTreatmentEnum[keyof typeof InfantOneYearOldCariesRequiringTreatmentEnum];

/**
 * @export
 */
export const InfantOneYearOldDirtyTeethEnum = {
    Beautiful: 'beautiful',
    Few: 'few',
    Many: 'many'
} as const;
export type InfantOneYearOldDirtyTeethEnum = typeof InfantOneYearOldDirtyTeethEnum[keyof typeof InfantOneYearOldDirtyTeethEnum];

/**
 * @export
 */
export const InfantOneYearOldGingivaMucousMembraneEnum = {
    NoAbnormality: 'noAbnormality',
    CanBe: 'canBe',
    NotClear: 'notClear'
} as const;
export type InfantOneYearOldGingivaMucousMembraneEnum = typeof InfantOneYearOldGingivaMucousMembraneEnum[keyof typeof InfantOneYearOldGingivaMucousMembraneEnum];

/**
 * @export
 */
export const InfantOneYearOldEngagementEnum = {
    Good: 'good',
    FollowUp: 'followUp',
    NotClear: 'notClear'
} as const;
export type InfantOneYearOldEngagementEnum = typeof InfantOneYearOldEngagementEnum[keyof typeof InfantOneYearOldEngagementEnum];

/**
 * @export
 */
export const InfantOneYearOldMakeGesturesEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantOneYearOldMakeGesturesEnum = typeof InfantOneYearOldMakeGesturesEnum[keyof typeof InfantOneYearOldMakeGesturesEnum];

/**
 * @export
 */
export const InfantOneYearOldBodyMusicEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantOneYearOldBodyMusicEnum = typeof InfantOneYearOldBodyMusicEnum[keyof typeof InfantOneYearOldBodyMusicEnum];

/**
 * @export
 */
export const InfantOneYearOldSimpleWordsEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantOneYearOldSimpleWordsEnum = typeof InfantOneYearOldSimpleWordsEnum[keyof typeof InfantOneYearOldSimpleWordsEnum];

/**
 * @export
 */
export const InfantOneYearOldPointToToyEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantOneYearOldPointToToyEnum = typeof InfantOneYearOldPointToToyEnum[keyof typeof InfantOneYearOldPointToToyEnum];

/**
 * @export
 */
export const InfantOneYearOldHappyToPlayEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantOneYearOldHappyToPlayEnum = typeof InfantOneYearOldHappyToPlayEnum[keyof typeof InfantOneYearOldHappyToPlayEnum];

/**
 * @export
 */
export const InfantOneYearOldRhythmOfEatEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantOneYearOldRhythmOfEatEnum = typeof InfantOneYearOldRhythmOfEatEnum[keyof typeof InfantOneYearOldRhythmOfEatEnum];

/**
 * @export
 */
export const InfantOneYearOldBrushYourTeethEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantOneYearOldBrushYourTeethEnum = typeof InfantOneYearOldBrushYourTeethEnum[keyof typeof InfantOneYearOldBrushYourTeethEnum];

/**
 * @export
 */
export const InfantOneYearOldChildRearingEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantOneYearOldChildRearingEnum = typeof InfantOneYearOldChildRearingEnum[keyof typeof InfantOneYearOldChildRearingEnum];

/**
 * @export
 */
export const InfantOneYearOldRaisingChildrenEnum = {
    No: 'no',
    Yes: 'yes',
    CanNotSayAnything: 'canNotSayAnything'
} as const;
export type InfantOneYearOldRaisingChildrenEnum = typeof InfantOneYearOldRaisingChildrenEnum[keyof typeof InfantOneYearOldRaisingChildrenEnum];


/**
 * Check if a given object implements the InfantOneYearOld interface.
 */
export function instanceOfInfantOneYearOld(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InfantOneYearOldFromJSON(json: any): InfantOneYearOld {
    return InfantOneYearOldFromJSONTyped(json, false);
}

export function InfantOneYearOldFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfantOneYearOld {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'babyId': !exists(json, 'babyId') ? undefined : json['babyId'],
        'ageId': !exists(json, 'ageId') ? undefined : json['ageId'],
        'implementationDate': !exists(json, 'implementationDate') ? undefined : json['implementationDate'],
        'year': !exists(json, 'year') ? undefined : json['year'],
        'month': !exists(json, 'month') ? undefined : json['month'],
        'photo1': !exists(json, 'photo1') ? undefined : json['photo1'],
        'photo2': !exists(json, 'photo2') ? undefined : json['photo2'],
        'bodyWeight': !exists(json, 'bodyWeight') ? undefined : json['bodyWeight'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'kaupIndex': !exists(json, 'kaupIndex') ? undefined : json['kaupIndex'],
        'chestCircumference': !exists(json, 'chestCircumference') ? undefined : json['chestCircumference'],
        'headCircumference': !exists(json, 'headCircumference') ? undefined : json['headCircumference'],
        'nutritionalStatus': !exists(json, 'nutritionalStatus') ? undefined : json['nutritionalStatus'],
        'breastMilk': !exists(json, 'breastMilk') ? undefined : json['breastMilk'],
        'mealDay': !exists(json, 'mealDay') ? undefined : json['mealDay'],
        'snacks': !exists(json, 'snacks') ? undefined : json['snacks'],
        'eyeAbnormalities': !exists(json, 'eyeAbnormalities') ? undefined : json['eyeAbnormalities'],
        'eyeAbnormalitiesNote': !exists(json, 'eyeAbnormalitiesNote') ? undefined : json['eyeAbnormalitiesNote'],
        'healthObservation': !exists(json, 'healthObservation') ? undefined : json['healthObservation'],
        'remarks': !exists(json, 'remarks') ? undefined : json['remarks'],
        'facilityOrPersonName': !exists(json, 'facilityOrPersonName') ? undefined : json['facilityOrPersonName'],
        'dayCaregiver': !exists(json, 'dayCaregiver') ? undefined : json['dayCaregiver'],
        'cariesRequiringTreatment': !exists(json, 'cariesRequiringTreatment') ? undefined : json['cariesRequiringTreatment'],
        'milkTeeth': !exists(json, 'milkTeeth') ? undefined : json['milkTeeth'],
        'permanentTeeth': !exists(json, 'permanentTeeth') ? undefined : json['permanentTeeth'],
        'total': !exists(json, 'total') ? undefined : json['total'],
        'dirtyTeeth': !exists(json, 'dirtyTeeth') ? undefined : json['dirtyTeeth'],
        'gingivaMucousMembrane': !exists(json, 'gingivaMucousMembrane') ? undefined : json['gingivaMucousMembrane'],
        'gingivaMucousMembraneNote': !exists(json, 'gingivaMucousMembraneNote') ? undefined : json['gingivaMucousMembraneNote'],
        'engagement': !exists(json, 'engagement') ? undefined : json['engagement'],
        'examinationDate': !exists(json, 'examinationDate') ? undefined : json['examinationDate'],
        'rightMolarsMaxilla': !exists(json, 'rightMolarsMaxilla') ? undefined : json['rightMolarsMaxilla'],
        'leftFrontToothMaxilla': !exists(json, 'leftFrontToothMaxilla') ? undefined : json['leftFrontToothMaxilla'],
        'rightMolarsLowerJaw': !exists(json, 'rightMolarsLowerJaw') ? undefined : json['rightMolarsLowerJaw'],
        'leftFrontToothLowerJaw': !exists(json, 'leftFrontToothLowerJaw') ? undefined : json['leftFrontToothLowerJaw'],
        'parentMessage': !exists(json, 'parentMessage') ? undefined : json['parentMessage'],
        'recordingDate': !exists(json, 'recordingDate') ? undefined : json['recordingDate'],
        'workHardMonth': !exists(json, 'workHardMonth') ? undefined : json['workHardMonth'],
        'workHardDay': !exists(json, 'workHardDay') ? undefined : json['workHardDay'],
        'makeGestures': !exists(json, 'makeGestures') ? undefined : json['makeGestures'],
        'bodyMusic': !exists(json, 'bodyMusic') ? undefined : json['bodyMusic'],
        'simpleWords': !exists(json, 'simpleWords') ? undefined : json['simpleWords'],
        'pointToToy': !exists(json, 'pointToToy') ? undefined : json['pointToToy'],
        'happyToPlay': !exists(json, 'happyToPlay') ? undefined : json['happyToPlay'],
        'kindOfPlay': !exists(json, 'kindOfPlay') ? undefined : json['kindOfPlay'],
        'rhythmOfEat': !exists(json, 'rhythmOfEat') ? undefined : json['rhythmOfEat'],
        'brushYourTeeth': !exists(json, 'brushYourTeeth') ? undefined : json['brushYourTeeth'],
        'childRearing': !exists(json, 'childRearing') ? undefined : json['childRearing'],
        'raisingChildren': !exists(json, 'raisingChildren') ? undefined : json['raisingChildren'],
        'yourGrowth': !exists(json, 'yourGrowth') ? undefined : json['yourGrowth'],
        'photo3': !exists(json, 'photo3') ? undefined : json['photo3'],
    };
}

export function InfantOneYearOldToJSON(value?: InfantOneYearOld | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'userId': value.userId,
        'babyId': value.babyId,
        'ageId': value.ageId,
        'implementationDate': value.implementationDate,
        'year': value.year,
        'month': value.month,
        'photo1': value.photo1,
        'photo2': value.photo2,
        'bodyWeight': value.bodyWeight,
        'height': value.height,
        'kaupIndex': value.kaupIndex,
        'chestCircumference': value.chestCircumference,
        'headCircumference': value.headCircumference,
        'nutritionalStatus': value.nutritionalStatus,
        'breastMilk': value.breastMilk,
        'mealDay': value.mealDay,
        'snacks': value.snacks,
        'eyeAbnormalities': value.eyeAbnormalities,
        'eyeAbnormalitiesNote': value.eyeAbnormalitiesNote,
        'healthObservation': value.healthObservation,
        'remarks': value.remarks,
        'facilityOrPersonName': value.facilityOrPersonName,
        'dayCaregiver': value.dayCaregiver,
        'cariesRequiringTreatment': value.cariesRequiringTreatment,
        'milkTeeth': value.milkTeeth,
        'permanentTeeth': value.permanentTeeth,
        'total': value.total,
        'dirtyTeeth': value.dirtyTeeth,
        'gingivaMucousMembrane': value.gingivaMucousMembrane,
        'gingivaMucousMembraneNote': value.gingivaMucousMembraneNote,
        'engagement': value.engagement,
        'examinationDate': value.examinationDate,
        'rightMolarsMaxilla': value.rightMolarsMaxilla,
        'leftFrontToothMaxilla': value.leftFrontToothMaxilla,
        'rightMolarsLowerJaw': value.rightMolarsLowerJaw,
        'leftFrontToothLowerJaw': value.leftFrontToothLowerJaw,
        'parentMessage': value.parentMessage,
        'recordingDate': value.recordingDate,
        'workHardMonth': value.workHardMonth,
        'workHardDay': value.workHardDay,
        'makeGestures': value.makeGestures,
        'bodyMusic': value.bodyMusic,
        'simpleWords': value.simpleWords,
        'pointToToy': value.pointToToy,
        'happyToPlay': value.happyToPlay,
        'kindOfPlay': value.kindOfPlay,
        'rhythmOfEat': value.rhythmOfEat,
        'brushYourTeeth': value.brushYourTeeth,
        'childRearing': value.childRearing,
        'raisingChildren': value.raisingChildren,
        'yourGrowth': value.yourGrowth,
        'photo3': value.photo3,
    };
}

