import {
  adminAuthApi,
  AdminLoginRequest,
  AdminLoginResponse,
  FetchError,
} from "@/api";
import { COLORS } from "@/constants";
import { translate } from "@/helpers";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Button, Checkbox, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";

import * as yup from "yup";

const StyledLoginForm = styled.div`
  margin: 0 auto;
  width: 446px;
  display: flex;
  flex-direction: column;
  .logo {
    width: 234px;
  }
`;

const validationSchema = yup.object({
  email: yup
    .string()
    .email("メールアドレスは正しいく入力してください")
    .required("メールアドレスを入力してください"),
  password: yup
    .string()
    .min(8, "8文字以上で入力してください")
    .max(16, "16文字以内で入力してください")
    .required("パスワードを入力してください"),
});

interface IFormValues extends Partial<AdminLoginRequest> { }

interface ILoginFormProps {
  onSuccess: (result: AdminLoginResponse, saveToken: boolean) => void;
}

export const LoginForm = (props: React.PropsWithChildren<ILoginFormProps>) => {
  const isSubmitted = React.useRef(false);
  const [rememberPassword, setRememberPassword] = useState(false);

  const formik = useFormik<IFormValues>({
    initialValues: {},
    validationSchema: validationSchema,
    onSubmit: (values) => {
      mutate(values);
    }
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: (loginRequest: AdminLoginRequest) =>
      adminAuthApi.adminAuthLoginPost({
        adminLoginRequest: loginRequest,
      }),
    onSuccess: (data) => {
      props?.onSuccess(data, rememberPassword);
    },
    onError(error: FetchError<AdminLoginRequest>) {
      if (error.body.email === "INVALID_EMAIL_OR_PASSWORD") {
        formik.setErrors({
          password: translate(error.body.email),
        });
      } else {
        formik.setErrors({
          email: translate(error.body.email),
        });
      }
    },
  });

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const handleChangeWhiteSpace = (rawEmail: string) => {
    if (!rawEmail) {
      return "";
    }
    // remove white space/tab at the end of email
    // eslint-disable-next-line no-control-regex
    return rawEmail.trim().replace(/	/g, "");
  };

  return (
    <StyledLoginForm>
      <div className="text-center mb-14">
        <img className="logo inline-block" src="/logo.png" alt="logo" />
      </div>
      <form onSubmit={(e) => {
        isSubmitted.current = true;
        formik.handleSubmit(e);
      }}>
        <div className="custom-input backgrounded-input">
          <div className="mb-4">
            <Typography variant="body1" fontWeight={500} component="label">
              メールアドレス
            </Typography>
          </div>
          <div className="mb-6">
            <TextField
              variant="outlined"
              size="small"
              fullWidth={true}
              name="email"
              value={formik.values.email}
              onChange={(e) => {
                const email = handleChangeWhiteSpace(e.target.value);
                formik.setFieldValue("email", email);
              }}
              onBlur={formik.handleBlur("email")}
              onKeyDown={handleKeyDown}
              error={(isSubmitted.current || formik.touched.email) && Boolean(formik.errors.email)}
              helperText={(isSubmitted.current || formik.touched.email) && formik.errors.email}
            />
          </div>
        </div>
        <div className="custom-input backgrounded-input">
          <div className="mb-4">
            <Typography variant="body1" fontWeight={500} component="label">
              パスワード
            </Typography>
          </div>
          <div className="mb-6">
            <TextField
              variant="outlined"
              size="small"
              fullWidth={true}
              type={showPassword ? "text" : "password"}
              name="password"
              value={formik.values.password}
              onChange={(e) => {
                const password = handleChangeWhiteSpace(e.target.value);
                formik.setFieldValue("password", password);
              }}
              onBlur={formik.handleBlur("password")}
              onKeyDown={handleKeyDown}
              error={(isSubmitted.current || formik.touched.password) && Boolean(formik.errors.password)}
              helperText={(isSubmitted.current || formik.touched.password) && formik.errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <Visibility fontSize="small" />
                      ) : (
                        <VisibilityOff fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <div className="mb-6">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: COLORS.primary,
                    "&.Mui-checked": {
                      color: "primary",
                    },
                  }}
                  value={rememberPassword}
                  onChange={() => setRememberPassword(!rememberPassword)}
                />
              }
              label="ログインしたままにする"
            />
          </FormGroup>
        </div>
        <div>
          <Button
            variant="contained"
            size="large"
            className="w-full custom-button"
            type="submit"
            disabled={isLoading}
          >
            ログイン
          </Button>
        </div>
      </form>
    </StyledLoginForm>
  );
};
