/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';
import type { InfantThreeYearOldAllOf } from './InfantThreeYearOldAllOf';
import {
    InfantThreeYearOldAllOfFromJSON,
    InfantThreeYearOldAllOfFromJSONTyped,
    InfantThreeYearOldAllOfToJSON,
} from './InfantThreeYearOldAllOf';

/**
 * 
 * @export
 * @interface InfantThreeYearOld
 */
export interface InfantThreeYearOld {
    /**
     * 
     * @type {number}
     * @memberof InfantThreeYearOld
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof InfantThreeYearOld
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof InfantThreeYearOld
     */
    babyId?: number;
    /**
     * 
     * @type {number}
     * @memberof InfantThreeYearOld
     */
    ageId?: number;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    implementationDate?: string;
    /**
     * 
     * @type {number}
     * @memberof InfantThreeYearOld
     */
    year?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantThreeYearOld
     */
    month?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    photo1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    photo2?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InfantThreeYearOld
     */
    bodyWeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantThreeYearOld
     */
    height?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantThreeYearOld
     */
    kaupIndex?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantThreeYearOld
     */
    headCircumference?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    nutritionalStatus?: InfantThreeYearOldNutritionalStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    eyeAbnormalities?: InfantThreeYearOldEyeAbnormalitiesEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    eyeAbnormalitiesNote?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    earAbnormalities?: InfantThreeYearOldEarAbnormalitiesEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    earAbnormalitiesNote?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    healthObservation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    remarks?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    hospitalOrPersonName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    dayCaregiver?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    whatReceiving?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    affectedDental?: InfantThreeYearOldAffectedDentalEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    cariesRequiringTreatment?: InfantThreeYearOldCariesRequiringTreatmentEnum;
    /**
     * 
     * @type {number}
     * @memberof InfantThreeYearOld
     */
    milkTeeth1?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantThreeYearOld
     */
    permanentTeeth1?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantThreeYearOld
     */
    total?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    treatedCaries?: InfantThreeYearOldTreatedCariesEnum;
    /**
     * 
     * @type {number}
     * @memberof InfantThreeYearOld
     */
    milkTeeth2?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantThreeYearOld
     */
    permanentTeeth2?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    dirtyTeeth?: InfantThreeYearOldDirtyTeethEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    gingivaMucousMembrane?: InfantThreeYearOldGingivaMucousMembraneEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    gingivaMucousMembraneNote?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    engagement?: InfantThreeYearOldEngagementEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    examinationDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    rightMolarsMaxilla?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    leftFrontToothMaxilla?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    rightMolarsLowerJaw?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    leftFrontToothLowerJaw?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    urineProtein?: InfantThreeYearOldUrineProteinEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    urineSugar?: InfantThreeYearOldUrineSugarEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    urineOccultBlood?: InfantThreeYearOldUrineOccultBloodEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    whiteBloodCells?: InfantThreeYearOldWhiteBloodCellsEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    parentMessage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    recordingDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    climbTheStairs?: InfantThreeYearOldClimbTheStairsEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    drawCircle?: InfantThreeYearOldDrawCircleEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    putOnClothes?: InfantThreeYearOldPutOnClothesEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    canSayYourName?: InfantThreeYearOldCanSayYourNameEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    washYourHands?: InfantThreeYearOldWashYourHandsEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    brushYourTeeth?: InfantThreeYearOldBrushYourTeethEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    suckYourFingers?: InfantThreeYearOldSuckYourFingersEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    habitEatWell?: InfantThreeYearOldHabitEatWellEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    squint?: InfantThreeYearOldSquintEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    extremelyClose?: InfantThreeYearOldExtremelyCloseEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    worriedYourEars?: InfantThreeYearOldWorriedYourEarsEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    concernsAboutMeshingOrDentition?: InfantThreeYearOldConcernsAboutMeshingOrDentitionEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    applyFluoride?: InfantThreeYearOldApplyFluorideEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    playHouseAndHeroes?: InfantThreeYearOldPlayHouseAndHeroesEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    playmates?: InfantThreeYearOldPlaymatesEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    childRearing?: InfantThreeYearOldChildRearingEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    raisingChildren?: InfantThreeYearOldRaisingChildrenEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    yourGrowth?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    resultOfWithFindings?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    judgement?: InfantThreeYearOldJudgementEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeYearOld
     */
    photo3?: string | null;
}


/**
 * @export
 */
export const InfantThreeYearOldNutritionalStatusEnum = {
    ALittleFluffy: 'aLittleFluffy',
    Usually: 'usually',
    Skinny: 'skinny'
} as const;
export type InfantThreeYearOldNutritionalStatusEnum = typeof InfantThreeYearOldNutritionalStatusEnum[keyof typeof InfantThreeYearOldNutritionalStatusEnum];

/**
 * @export
 */
export const InfantThreeYearOldEyeAbnormalitiesEnum = {
    None: 'none',
    CanBe: 'canBe',
    Suspicion: 'suspicion'
} as const;
export type InfantThreeYearOldEyeAbnormalitiesEnum = typeof InfantThreeYearOldEyeAbnormalitiesEnum[keyof typeof InfantThreeYearOldEyeAbnormalitiesEnum];

/**
 * @export
 */
export const InfantThreeYearOldEarAbnormalitiesEnum = {
    None: 'none',
    CanBe: 'canBe',
    Suspicion: 'suspicion'
} as const;
export type InfantThreeYearOldEarAbnormalitiesEnum = typeof InfantThreeYearOldEarAbnormalitiesEnum[keyof typeof InfantThreeYearOldEarAbnormalitiesEnum];

/**
 * @export
 */
export const InfantThreeYearOldAffectedDentalEnum = {
    O: 'o',
    A: 'a',
    B: 'b',
    C1: 'c1',
    C2: 'c2'
} as const;
export type InfantThreeYearOldAffectedDentalEnum = typeof InfantThreeYearOldAffectedDentalEnum[keyof typeof InfantThreeYearOldAffectedDentalEnum];

/**
 * @export
 */
export const InfantThreeYearOldCariesRequiringTreatmentEnum = {
    None: 'none',
    CanBe: 'canBe',
    NotClear: 'notClear'
} as const;
export type InfantThreeYearOldCariesRequiringTreatmentEnum = typeof InfantThreeYearOldCariesRequiringTreatmentEnum[keyof typeof InfantThreeYearOldCariesRequiringTreatmentEnum];

/**
 * @export
 */
export const InfantThreeYearOldTreatedCariesEnum = {
    None: 'none',
    CanBe: 'canBe'
} as const;
export type InfantThreeYearOldTreatedCariesEnum = typeof InfantThreeYearOldTreatedCariesEnum[keyof typeof InfantThreeYearOldTreatedCariesEnum];

/**
 * @export
 */
export const InfantThreeYearOldDirtyTeethEnum = {
    Beautiful: 'beautiful',
    Few: 'few',
    Many: 'many'
} as const;
export type InfantThreeYearOldDirtyTeethEnum = typeof InfantThreeYearOldDirtyTeethEnum[keyof typeof InfantThreeYearOldDirtyTeethEnum];

/**
 * @export
 */
export const InfantThreeYearOldGingivaMucousMembraneEnum = {
    NoAbnormality: 'noAbnormality',
    CanBe: 'canBe',
    NotClear: 'notClear'
} as const;
export type InfantThreeYearOldGingivaMucousMembraneEnum = typeof InfantThreeYearOldGingivaMucousMembraneEnum[keyof typeof InfantThreeYearOldGingivaMucousMembraneEnum];

/**
 * @export
 */
export const InfantThreeYearOldEngagementEnum = {
    Good: 'good',
    FollowUp: 'followUp',
    NotClear: 'notClear'
} as const;
export type InfantThreeYearOldEngagementEnum = typeof InfantThreeYearOldEngagementEnum[keyof typeof InfantThreeYearOldEngagementEnum];

/**
 * @export
 */
export const InfantThreeYearOldUrineProteinEnum = {
    Minus: 'minus',
    PlusAndMinus: 'plusAndMinus',
    Plus: 'plus',
    DoublePlus: 'doublePlus'
} as const;
export type InfantThreeYearOldUrineProteinEnum = typeof InfantThreeYearOldUrineProteinEnum[keyof typeof InfantThreeYearOldUrineProteinEnum];

/**
 * @export
 */
export const InfantThreeYearOldUrineSugarEnum = {
    Minus: 'minus',
    PlusAndMinus: 'plusAndMinus',
    Plus: 'plus',
    DoublePlus: 'doublePlus'
} as const;
export type InfantThreeYearOldUrineSugarEnum = typeof InfantThreeYearOldUrineSugarEnum[keyof typeof InfantThreeYearOldUrineSugarEnum];

/**
 * @export
 */
export const InfantThreeYearOldUrineOccultBloodEnum = {
    Minus: 'minus',
    PlusAndMinus: 'plusAndMinus',
    Plus: 'plus',
    DoublePlus: 'doublePlus'
} as const;
export type InfantThreeYearOldUrineOccultBloodEnum = typeof InfantThreeYearOldUrineOccultBloodEnum[keyof typeof InfantThreeYearOldUrineOccultBloodEnum];

/**
 * @export
 */
export const InfantThreeYearOldWhiteBloodCellsEnum = {
    Minus: 'minus',
    PlusAndMinus: 'plusAndMinus',
    Plus: 'plus',
    DoublePlus: 'doublePlus'
} as const;
export type InfantThreeYearOldWhiteBloodCellsEnum = typeof InfantThreeYearOldWhiteBloodCellsEnum[keyof typeof InfantThreeYearOldWhiteBloodCellsEnum];

/**
 * @export
 */
export const InfantThreeYearOldClimbTheStairsEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantThreeYearOldClimbTheStairsEnum = typeof InfantThreeYearOldClimbTheStairsEnum[keyof typeof InfantThreeYearOldClimbTheStairsEnum];

/**
 * @export
 */
export const InfantThreeYearOldDrawCircleEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantThreeYearOldDrawCircleEnum = typeof InfantThreeYearOldDrawCircleEnum[keyof typeof InfantThreeYearOldDrawCircleEnum];

/**
 * @export
 */
export const InfantThreeYearOldPutOnClothesEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantThreeYearOldPutOnClothesEnum = typeof InfantThreeYearOldPutOnClothesEnum[keyof typeof InfantThreeYearOldPutOnClothesEnum];

/**
 * @export
 */
export const InfantThreeYearOldCanSayYourNameEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantThreeYearOldCanSayYourNameEnum = typeof InfantThreeYearOldCanSayYourNameEnum[keyof typeof InfantThreeYearOldCanSayYourNameEnum];

/**
 * @export
 */
export const InfantThreeYearOldWashYourHandsEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantThreeYearOldWashYourHandsEnum = typeof InfantThreeYearOldWashYourHandsEnum[keyof typeof InfantThreeYearOldWashYourHandsEnum];

/**
 * @export
 */
export const InfantThreeYearOldBrushYourTeethEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantThreeYearOldBrushYourTeethEnum = typeof InfantThreeYearOldBrushYourTeethEnum[keyof typeof InfantThreeYearOldBrushYourTeethEnum];

/**
 * @export
 */
export const InfantThreeYearOldSuckYourFingersEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantThreeYearOldSuckYourFingersEnum = typeof InfantThreeYearOldSuckYourFingersEnum[keyof typeof InfantThreeYearOldSuckYourFingersEnum];

/**
 * @export
 */
export const InfantThreeYearOldHabitEatWellEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantThreeYearOldHabitEatWellEnum = typeof InfantThreeYearOldHabitEatWellEnum[keyof typeof InfantThreeYearOldHabitEatWellEnum];

/**
 * @export
 */
export const InfantThreeYearOldSquintEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantThreeYearOldSquintEnum = typeof InfantThreeYearOldSquintEnum[keyof typeof InfantThreeYearOldSquintEnum];

/**
 * @export
 */
export const InfantThreeYearOldExtremelyCloseEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantThreeYearOldExtremelyCloseEnum = typeof InfantThreeYearOldExtremelyCloseEnum[keyof typeof InfantThreeYearOldExtremelyCloseEnum];

/**
 * @export
 */
export const InfantThreeYearOldWorriedYourEarsEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantThreeYearOldWorriedYourEarsEnum = typeof InfantThreeYearOldWorriedYourEarsEnum[keyof typeof InfantThreeYearOldWorriedYourEarsEnum];

/**
 * @export
 */
export const InfantThreeYearOldConcernsAboutMeshingOrDentitionEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantThreeYearOldConcernsAboutMeshingOrDentitionEnum = typeof InfantThreeYearOldConcernsAboutMeshingOrDentitionEnum[keyof typeof InfantThreeYearOldConcernsAboutMeshingOrDentitionEnum];

/**
 * @export
 */
export const InfantThreeYearOldApplyFluorideEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantThreeYearOldApplyFluorideEnum = typeof InfantThreeYearOldApplyFluorideEnum[keyof typeof InfantThreeYearOldApplyFluorideEnum];

/**
 * @export
 */
export const InfantThreeYearOldPlayHouseAndHeroesEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantThreeYearOldPlayHouseAndHeroesEnum = typeof InfantThreeYearOldPlayHouseAndHeroesEnum[keyof typeof InfantThreeYearOldPlayHouseAndHeroesEnum];

/**
 * @export
 */
export const InfantThreeYearOldPlaymatesEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantThreeYearOldPlaymatesEnum = typeof InfantThreeYearOldPlaymatesEnum[keyof typeof InfantThreeYearOldPlaymatesEnum];

/**
 * @export
 */
export const InfantThreeYearOldChildRearingEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantThreeYearOldChildRearingEnum = typeof InfantThreeYearOldChildRearingEnum[keyof typeof InfantThreeYearOldChildRearingEnum];

/**
 * @export
 */
export const InfantThreeYearOldRaisingChildrenEnum = {
    No: 'no',
    Yes: 'yes',
    CanNotSayAnything: 'canNotSayAnything'
} as const;
export type InfantThreeYearOldRaisingChildrenEnum = typeof InfantThreeYearOldRaisingChildrenEnum[keyof typeof InfantThreeYearOldRaisingChildrenEnum];

/**
 * @export
 */
export const InfantThreeYearOldJudgementEnum = {
    NoAbnormality: 'noAbnormality',
    AlreadyMedical: 'alreadyMedical',
    FollowUpRequired: 'followUpRequired',
    PrecisionRequired: 'precisionRequired',
    TreatmentRequired: 'treatmentRequired'
} as const;
export type InfantThreeYearOldJudgementEnum = typeof InfantThreeYearOldJudgementEnum[keyof typeof InfantThreeYearOldJudgementEnum];


/**
 * Check if a given object implements the InfantThreeYearOld interface.
 */
export function instanceOfInfantThreeYearOld(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InfantThreeYearOldFromJSON(json: any): InfantThreeYearOld {
    return InfantThreeYearOldFromJSONTyped(json, false);
}

export function InfantThreeYearOldFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfantThreeYearOld {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'babyId': !exists(json, 'babyId') ? undefined : json['babyId'],
        'ageId': !exists(json, 'ageId') ? undefined : json['ageId'],
        'implementationDate': !exists(json, 'implementationDate') ? undefined : json['implementationDate'],
        'year': !exists(json, 'year') ? undefined : json['year'],
        'month': !exists(json, 'month') ? undefined : json['month'],
        'photo1': !exists(json, 'photo1') ? undefined : json['photo1'],
        'photo2': !exists(json, 'photo2') ? undefined : json['photo2'],
        'bodyWeight': !exists(json, 'bodyWeight') ? undefined : json['bodyWeight'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'kaupIndex': !exists(json, 'kaupIndex') ? undefined : json['kaupIndex'],
        'headCircumference': !exists(json, 'headCircumference') ? undefined : json['headCircumference'],
        'nutritionalStatus': !exists(json, 'nutritionalStatus') ? undefined : json['nutritionalStatus'],
        'eyeAbnormalities': !exists(json, 'eyeAbnormalities') ? undefined : json['eyeAbnormalities'],
        'eyeAbnormalitiesNote': !exists(json, 'eyeAbnormalitiesNote') ? undefined : json['eyeAbnormalitiesNote'],
        'earAbnormalities': !exists(json, 'earAbnormalities') ? undefined : json['earAbnormalities'],
        'earAbnormalitiesNote': !exists(json, 'earAbnormalitiesNote') ? undefined : json['earAbnormalitiesNote'],
        'healthObservation': !exists(json, 'healthObservation') ? undefined : json['healthObservation'],
        'remarks': !exists(json, 'remarks') ? undefined : json['remarks'],
        'hospitalOrPersonName': !exists(json, 'hospitalOrPersonName') ? undefined : json['hospitalOrPersonName'],
        'dayCaregiver': !exists(json, 'dayCaregiver') ? undefined : json['dayCaregiver'],
        'whatReceiving': !exists(json, 'whatReceiving') ? undefined : json['whatReceiving'],
        'affectedDental': !exists(json, 'affectedDental') ? undefined : json['affectedDental'],
        'cariesRequiringTreatment': !exists(json, 'cariesRequiringTreatment') ? undefined : json['cariesRequiringTreatment'],
        'milkTeeth1': !exists(json, 'milkTeeth1') ? undefined : json['milkTeeth1'],
        'permanentTeeth1': !exists(json, 'permanentTeeth1') ? undefined : json['permanentTeeth1'],
        'total': !exists(json, 'total') ? undefined : json['total'],
        'treatedCaries': !exists(json, 'treatedCaries') ? undefined : json['treatedCaries'],
        'milkTeeth2': !exists(json, 'milkTeeth2') ? undefined : json['milkTeeth2'],
        'permanentTeeth2': !exists(json, 'permanentTeeth2') ? undefined : json['permanentTeeth2'],
        'dirtyTeeth': !exists(json, 'dirtyTeeth') ? undefined : json['dirtyTeeth'],
        'gingivaMucousMembrane': !exists(json, 'gingivaMucousMembrane') ? undefined : json['gingivaMucousMembrane'],
        'gingivaMucousMembraneNote': !exists(json, 'gingivaMucousMembraneNote') ? undefined : json['gingivaMucousMembraneNote'],
        'engagement': !exists(json, 'engagement') ? undefined : json['engagement'],
        'examinationDate': !exists(json, 'examinationDate') ? undefined : json['examinationDate'],
        'rightMolarsMaxilla': !exists(json, 'rightMolarsMaxilla') ? undefined : json['rightMolarsMaxilla'],
        'leftFrontToothMaxilla': !exists(json, 'leftFrontToothMaxilla') ? undefined : json['leftFrontToothMaxilla'],
        'rightMolarsLowerJaw': !exists(json, 'rightMolarsLowerJaw') ? undefined : json['rightMolarsLowerJaw'],
        'leftFrontToothLowerJaw': !exists(json, 'leftFrontToothLowerJaw') ? undefined : json['leftFrontToothLowerJaw'],
        'urineProtein': !exists(json, 'urineProtein') ? undefined : json['urineProtein'],
        'urineSugar': !exists(json, 'urineSugar') ? undefined : json['urineSugar'],
        'urineOccultBlood': !exists(json, 'urineOccultBlood') ? undefined : json['urineOccultBlood'],
        'whiteBloodCells': !exists(json, 'whiteBloodCells') ? undefined : json['whiteBloodCells'],
        'parentMessage': !exists(json, 'parentMessage') ? undefined : json['parentMessage'],
        'recordingDate': !exists(json, 'recordingDate') ? undefined : json['recordingDate'],
        'climbTheStairs': !exists(json, 'climbTheStairs') ? undefined : json['climbTheStairs'],
        'drawCircle': !exists(json, 'drawCircle') ? undefined : json['drawCircle'],
        'putOnClothes': !exists(json, 'putOnClothes') ? undefined : json['putOnClothes'],
        'canSayYourName': !exists(json, 'canSayYourName') ? undefined : json['canSayYourName'],
        'washYourHands': !exists(json, 'washYourHands') ? undefined : json['washYourHands'],
        'brushYourTeeth': !exists(json, 'brushYourTeeth') ? undefined : json['brushYourTeeth'],
        'suckYourFingers': !exists(json, 'suckYourFingers') ? undefined : json['suckYourFingers'],
        'habitEatWell': !exists(json, 'habitEatWell') ? undefined : json['habitEatWell'],
        'squint': !exists(json, 'squint') ? undefined : json['squint'],
        'extremelyClose': !exists(json, 'extremelyClose') ? undefined : json['extremelyClose'],
        'worriedYourEars': !exists(json, 'worriedYourEars') ? undefined : json['worriedYourEars'],
        'concernsAboutMeshingOrDentition': !exists(json, 'concernsAboutMeshingOrDentition') ? undefined : json['concernsAboutMeshingOrDentition'],
        'applyFluoride': !exists(json, 'applyFluoride') ? undefined : json['applyFluoride'],
        'playHouseAndHeroes': !exists(json, 'playHouseAndHeroes') ? undefined : json['playHouseAndHeroes'],
        'playmates': !exists(json, 'playmates') ? undefined : json['playmates'],
        'childRearing': !exists(json, 'childRearing') ? undefined : json['childRearing'],
        'raisingChildren': !exists(json, 'raisingChildren') ? undefined : json['raisingChildren'],
        'yourGrowth': !exists(json, 'yourGrowth') ? undefined : json['yourGrowth'],
        'resultOfWithFindings': !exists(json, 'resultOfWithFindings') ? undefined : json['resultOfWithFindings'],
        'judgement': !exists(json, 'judgement') ? undefined : json['judgement'],
        'photo3': !exists(json, 'photo3') ? undefined : json['photo3'],
    };
}

export function InfantThreeYearOldToJSON(value?: InfantThreeYearOld | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'userId': value.userId,
        'babyId': value.babyId,
        'ageId': value.ageId,
        'implementationDate': value.implementationDate,
        'year': value.year,
        'month': value.month,
        'photo1': value.photo1,
        'photo2': value.photo2,
        'bodyWeight': value.bodyWeight,
        'height': value.height,
        'kaupIndex': value.kaupIndex,
        'headCircumference': value.headCircumference,
        'nutritionalStatus': value.nutritionalStatus,
        'eyeAbnormalities': value.eyeAbnormalities,
        'eyeAbnormalitiesNote': value.eyeAbnormalitiesNote,
        'earAbnormalities': value.earAbnormalities,
        'earAbnormalitiesNote': value.earAbnormalitiesNote,
        'healthObservation': value.healthObservation,
        'remarks': value.remarks,
        'hospitalOrPersonName': value.hospitalOrPersonName,
        'dayCaregiver': value.dayCaregiver,
        'whatReceiving': value.whatReceiving,
        'affectedDental': value.affectedDental,
        'cariesRequiringTreatment': value.cariesRequiringTreatment,
        'milkTeeth1': value.milkTeeth1,
        'permanentTeeth1': value.permanentTeeth1,
        'total': value.total,
        'treatedCaries': value.treatedCaries,
        'milkTeeth2': value.milkTeeth2,
        'permanentTeeth2': value.permanentTeeth2,
        'dirtyTeeth': value.dirtyTeeth,
        'gingivaMucousMembrane': value.gingivaMucousMembrane,
        'gingivaMucousMembraneNote': value.gingivaMucousMembraneNote,
        'engagement': value.engagement,
        'examinationDate': value.examinationDate,
        'rightMolarsMaxilla': value.rightMolarsMaxilla,
        'leftFrontToothMaxilla': value.leftFrontToothMaxilla,
        'rightMolarsLowerJaw': value.rightMolarsLowerJaw,
        'leftFrontToothLowerJaw': value.leftFrontToothLowerJaw,
        'urineProtein': value.urineProtein,
        'urineSugar': value.urineSugar,
        'urineOccultBlood': value.urineOccultBlood,
        'whiteBloodCells': value.whiteBloodCells,
        'parentMessage': value.parentMessage,
        'recordingDate': value.recordingDate,
        'climbTheStairs': value.climbTheStairs,
        'drawCircle': value.drawCircle,
        'putOnClothes': value.putOnClothes,
        'canSayYourName': value.canSayYourName,
        'washYourHands': value.washYourHands,
        'brushYourTeeth': value.brushYourTeeth,
        'suckYourFingers': value.suckYourFingers,
        'habitEatWell': value.habitEatWell,
        'squint': value.squint,
        'extremelyClose': value.extremelyClose,
        'worriedYourEars': value.worriedYourEars,
        'concernsAboutMeshingOrDentition': value.concernsAboutMeshingOrDentition,
        'applyFluoride': value.applyFluoride,
        'playHouseAndHeroes': value.playHouseAndHeroes,
        'playmates': value.playmates,
        'childRearing': value.childRearing,
        'raisingChildren': value.raisingChildren,
        'yourGrowth': value.yourGrowth,
        'resultOfWithFindings': value.resultOfWithFindings,
        'judgement': value.judgement,
        'photo3': value.photo3,
    };
}

