import { Route } from "react-router-dom";
import { NOTI_CAMPAIGN_PATH } from "@/constants/paths";
import { NOTI_CAMPAIGN_LIST_PATH } from "./noti-campaign-list/NotiCampaignListPage.shared";
import { NotiCampaignListPage } from "./noti-campaign-list";

export const renderCampaignRoutes = () => (
  <Route path={NOTI_CAMPAIGN_PATH}>
    <Route path={NOTI_CAMPAIGN_LIST_PATH} element={<NotiCampaignListPage />} />
  </Route>
);
