/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InfantInspectionRecord } from './InfantInspectionRecord';
import {
    InfantInspectionRecordFromJSON,
    InfantInspectionRecordFromJSONTyped,
    InfantInspectionRecordToJSON,
} from './InfantInspectionRecord';
import type { PaginationInfo } from './PaginationInfo';
import {
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './PaginationInfo';

/**
 * 
 * @export
 * @interface InfantInspectionRecordPanigation
 */
export interface InfantInspectionRecordPanigation {
    /**
     * 
     * @type {Array<InfantInspectionRecord>}
     * @memberof InfantInspectionRecordPanigation
     */
    data?: Array<InfantInspectionRecord>;
    /**
     * 
     * @type {PaginationInfo}
     * @memberof InfantInspectionRecordPanigation
     */
    meta?: PaginationInfo;
}

/**
 * Check if a given object implements the InfantInspectionRecordPanigation interface.
 */
export function instanceOfInfantInspectionRecordPanigation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InfantInspectionRecordPanigationFromJSON(json: any): InfantInspectionRecordPanigation {
    return InfantInspectionRecordPanigationFromJSONTyped(json, false);
}

export function InfantInspectionRecordPanigationFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfantInspectionRecordPanigation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(InfantInspectionRecordFromJSON)),
        'meta': !exists(json, 'meta') ? undefined : PaginationInfoFromJSON(json['meta']),
    };
}

export function InfantInspectionRecordPanigationToJSON(value?: InfantInspectionRecordPanigation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(InfantInspectionRecordToJSON)),
        'meta': PaginationInfoToJSON(value.meta),
    };
}

