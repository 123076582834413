/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InfantAfterOneWeek,
    InfantAfterOneWeekFromJSON,
    InfantAfterOneWeekToJSON,
    InfantAfterOneWeekPagination,
    InfantAfterOneWeekPaginationFromJSON,
    InfantAfterOneWeekPaginationToJSON,
} from '../models';

export interface AdminInfantAfterOneWeeksIdGetRequest {
    id: number;
    userId?: number;
}

export interface InfantAfterOneWeeksGetRequest {
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface InfantAfterOneWeeksIdDeleteRequest {
    id: number;
}

export interface InfantAfterOneWeeksIdGetRequest {
    id: number;
}

export interface InfantAfterOneWeeksIdPutRequest {
    id: number;
    infantAfterOneWeek?: InfantAfterOneWeek;
}

export interface InfantAfterOneWeeksPostRequest {
    infantAfterOneWeek?: InfantAfterOneWeek;
}

export interface InfantAfterOneWeeksShareAllGetRequest {
    sourceUserId: number;
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface InfantAfterOneWeeksShareDetailGetRequest {
    recordId: number;
    sourceUserId: number;
}

/**
 * 
 */
export class InfantAfterOneWeeksApi extends runtime.BaseAPI {

    /**
     * 
     */
    adminInfantAfterOneWeeksIdGetRaw = async (requestParameters: AdminInfantAfterOneWeeksIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantAfterOneWeek>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling adminInfantAfterOneWeeksIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/infantAfterOneWeeks/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantAfterOneWeekFromJSON(jsonValue));
    }

    /**
     * 
     */
    adminInfantAfterOneWeeksIdGet = async (requestParameters: AdminInfantAfterOneWeeksIdGetRequest, initOverrides?: RequestInit): Promise<InfantAfterOneWeek> => {
        const response = await this.adminInfantAfterOneWeeksIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantAfterOneWeeksGetRaw = async (requestParameters: InfantAfterOneWeeksGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantAfterOneWeekPagination>> => {
        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling infantAfterOneWeeksGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantAfterOneWeeks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantAfterOneWeekPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantAfterOneWeeksGet = async (requestParameters: InfantAfterOneWeeksGetRequest, initOverrides?: RequestInit): Promise<InfantAfterOneWeekPagination> => {
        const response = await this.infantAfterOneWeeksGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    infantAfterOneWeeksIdDeleteRaw = async (requestParameters: InfantAfterOneWeeksIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling infantAfterOneWeeksIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantAfterOneWeeks/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    infantAfterOneWeeksIdDelete = async (requestParameters: InfantAfterOneWeeksIdDeleteRequest, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.infantAfterOneWeeksIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantAfterOneWeeksIdGetRaw = async (requestParameters: InfantAfterOneWeeksIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantAfterOneWeek>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling infantAfterOneWeeksIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantAfterOneWeeks/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantAfterOneWeekFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantAfterOneWeeksIdGet = async (requestParameters: InfantAfterOneWeeksIdGetRequest, initOverrides?: RequestInit): Promise<InfantAfterOneWeek> => {
        const response = await this.infantAfterOneWeeksIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    infantAfterOneWeeksIdPutRaw = async (requestParameters: InfantAfterOneWeeksIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantAfterOneWeek>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling infantAfterOneWeeksIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/infantAfterOneWeeks/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InfantAfterOneWeekToJSON(requestParameters.infantAfterOneWeek),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantAfterOneWeekFromJSON(jsonValue));
    }

    /**
     */
    infantAfterOneWeeksIdPut = async (requestParameters: InfantAfterOneWeeksIdPutRequest, initOverrides?: RequestInit): Promise<InfantAfterOneWeek> => {
        const response = await this.infantAfterOneWeeksIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    infantAfterOneWeeksPostRaw = async (requestParameters: InfantAfterOneWeeksPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantAfterOneWeek>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/infantAfterOneWeeks`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InfantAfterOneWeekToJSON(requestParameters.infantAfterOneWeek),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantAfterOneWeekFromJSON(jsonValue));
    }

    /**
     */
    infantAfterOneWeeksPost = async (requestParameters: InfantAfterOneWeeksPostRequest = {}, initOverrides?: RequestInit): Promise<InfantAfterOneWeek> => {
        const response = await this.infantAfterOneWeeksPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantAfterOneWeeksShareAllGetRaw = async (requestParameters: InfantAfterOneWeeksShareAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantAfterOneWeekPagination>> => {
        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling infantAfterOneWeeksShareAllGet.');
        }

        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling infantAfterOneWeeksShareAllGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantAfterOneWeeks/share/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantAfterOneWeekPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantAfterOneWeeksShareAllGet = async (requestParameters: InfantAfterOneWeeksShareAllGetRequest, initOverrides?: RequestInit): Promise<InfantAfterOneWeekPagination> => {
        const response = await this.infantAfterOneWeeksShareAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantAfterOneWeeksShareDetailGetRaw = async (requestParameters: InfantAfterOneWeeksShareDetailGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantAfterOneWeek>> => {
        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling infantAfterOneWeeksShareDetailGet.');
        }

        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling infantAfterOneWeeksShareDetailGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.recordId !== undefined) {
            queryParameters['recordId'] = requestParameters.recordId;
        }

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantAfterOneWeeks/share/detail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantAfterOneWeekFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantAfterOneWeeksShareDetailGet = async (requestParameters: InfantAfterOneWeeksShareDetailGetRequest, initOverrides?: RequestInit): Promise<InfantAfterOneWeek> => {
        const response = await this.infantAfterOneWeeksShareDetailGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
