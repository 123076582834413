import React, { useEffect, useState } from "react";
import {
  ButtonCustom,
  CardCustom,
  TextBlockCustom,
} from "@/components/display";
import { COLORS, noData } from "@/constants";
import { Grid } from "@mui/material";
import { useQuery } from "react-query";
import { medicalConsultantsApi } from "@/api";
import { toJapanDate } from "@/utils";
import { DialogMedicalConsultant } from "./DialogMedicalConsultant";

interface IMedicalConsultantProps {
  userId?: number;
  babyId?: number;
}

export const MedicalConsultant = (
  props: React.PropsWithChildren<IMedicalConsultantProps>
) => {
  //--- Get medical consultant
  const { data: medicalConsultants, refetch: refetchMedicalConsultants } =
    useQuery({
      queryKey: "GET_MEDICAL_CONSULTANTS",
      queryFn: () => {
        return medicalConsultantsApi.adminMedicalConsultantsGet({
          userId: props.userId,
          babyId: props.babyId,
          orderBy: "consultationDate",
          orderType: "desc",
        });
      },
    });

  //--- Dialog
  const [openDialog, setOpenDialog] = useState(false);
  const [medicalConsultantItemId, setMedicalConsultantItemId] = useState(0);

  const handleClickOpenDialog = (id: number) => {
    setOpenDialog(true);
    setMedicalConsultantItemId(id);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setMedicalConsultantItemId(0);
  };

  useEffect(() => {
    if (props.userId > 0 && props.babyId > 0) {
      refetchMedicalConsultants();
    }
  }, [props.babyId, props.userId, refetchMedicalConsultants]);

  return (
    <>
      <CardCustom classCard={"mt-4"}>
        <TextBlockCustom
          classText="mb-4 font-bold"
          textHeader="医療機関受診の記録"
          headerColor={COLORS.primary}
          textSize={16}
        />

        <Grid container spacing={3}>
          {medicalConsultants?.data?.length > 0 ? (
            (medicalConsultants?.data || []).map((item, index) => (
              <Grid item xs={4} key={"medicalConsultants_" + index}>
                <Grid container alignItems={"center"}>
                  <Grid item xs={8}>
                    <Grid container>
                      <Grid item xs={3}>
                        <TextBlockCustom
                          textHeader="受診日:"
                          headerColor={COLORS.approxGrey}
                        />
                      </Grid>
                      <Grid item xs={7}>
                        <TextBlockCustom
                          classText="mb-2 font-medium"
                          textHeader={toJapanDate(item?.consultationDate)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="flex justify-end mb-2">
                      <ButtonCustom
                        title="詳細"
                        onClick={() => handleClickOpenDialog(item.id)}
                      />
                    </div>
                  </Grid>
                </Grid>
                <div className="divide-dot"></div>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <div className="text-center font-bold">{noData}</div>
            </Grid>
          )}
        </Grid>
      </CardCustom>

      <DialogMedicalConsultant
        openDialog={openDialog}
        onCloseDialog={() => handleCloseDialog()}
        medicalConsultantItem={medicalConsultants?.data?.find(
          (m) => m.id === medicalConsultantItemId
        )}
      />
    </>
  );
};
