import React, { useState } from "react";
import {
  ButtonCustom,
  CardCustom,
  TextBlockCustom,
} from "@/components/display";
import { COLORS, noData } from "@/constants";
import { Grid } from "@mui/material";
import { useQuery } from "react-query";
import { Fetus, pregnantDiagnosesApi, PregnantDiagnosis } from "@/api";
import { getWeekPregnant, toJapanDate } from "@/utils";
import { DialogPregnancyDiagnosisRecord } from "./DialogPregnancyDiagnosisRecord";

interface IPregnancyDiagnosisRecordProps {
  userId?: number;
  pregnantId?: number;
  fetusItem?: Fetus;
}

export const PregnancyDiagnosisRecord = (
  props: React.PropsWithChildren<IPregnancyDiagnosisRecordProps>
) => {
  const { userId, pregnantId, fetusItem } = props;

  //--- Get pregnant diagnoses
  const [pregnantDiagnosesList, setPregnantDiagnosesList] =
    useState<PregnantDiagnosis[]>();

  useQuery({
    queryKey: "GET_LIST_PREGNANT_DIAGNOSES",
    queryFn: () =>
      pregnantDiagnosesApi.adminPregnantDiagnosesGet({
        userId: userId,
        pregnantId: pregnantId,
        orderBy: "date",
        orderType: "desc",
      }),
    onSuccess: (response) => {
      setPregnantDiagnosesList(response.data);
    },
  });

  //--- Open dialog
  const [openDialog, setOpenDialog] = useState(false);
  const [pregnancyDiagnosisItemId, setPregnancyDiagnosisItemId] = useState(0);

  const handleClickOpenDialog = (id: number) => {
    setOpenDialog(true);
    setPregnancyDiagnosisItemId(id);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setPregnancyDiagnosisItemId(0);
  };

  return (
    <>
      <CardCustom classCard={"mt-4"}>
        <TextBlockCustom
          classText="mb-4 text-lg font-bold"
          textHeader="妊婦健診の記録"
          headerColor={COLORS.primary}
          textSize={16}
        />

        <Grid container spacing={3}>
          {pregnantDiagnosesList?.length > 0 ? (
            (pregnantDiagnosesList || []).map((item) => (
              <Grid item xs={4} key={"pregnantDiagnosesList_" + item.id}>
                <Grid container alignItems={"center"}>
                  <Grid item xs={8}>
                    <Grid container>
                      <Grid item xs={4}>
                        <TextBlockCustom
                          classText="mb-2"
                          textHeader="診察日:"
                          headerColor={COLORS.approxGrey}
                        />
                        <TextBlockCustom
                          classText="mb-2"
                          textHeader="妊娠週数:"
                          headerColor={COLORS.approxGrey}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <TextBlockCustom
                          classText="font-medium mb-2"
                          textHeader={item.date ? toJapanDate(item.date) : ""}
                        />
                        <TextBlockCustom
                          classText="font-medium mb-2"
                          textHeader={
                            item.date && fetusItem?.expectedBirthday
                              ? getWeekPregnant(
                                  fetusItem?.expectedBirthday,
                                  item.date,
                                  "weeks"
                                ).toString() +
                                "週" +
                                getWeekPregnant(
                                  fetusItem?.expectedBirthday,
                                  item.date,
                                  "days"
                                ).toString() +
                                "日"
                              : ""
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4} justifySelf="flex-end">
                    <div className="flex justify-end">
                      <ButtonCustom
                        title="詳細"
                        onClick={() => handleClickOpenDialog(item.id)}
                      />
                    </div>
                  </Grid>
                </Grid>
                <div className="divide-dot"></div>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <div className="text-center font-bold">{noData}</div>
            </Grid>
          )}
        </Grid>
      </CardCustom>

      <DialogPregnancyDiagnosisRecord
        openDialog={openDialog}
        onCloseDialog={() => handleCloseDialog()}
        pregnancyDiagnosisItem={pregnantDiagnosesList?.find(
          (p) => p.id === pregnancyDiagnosisItemId
        )}
      />
    </>
  );
};
