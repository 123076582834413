/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';
import type { MedicalRecordAllOf } from './MedicalRecordAllOf';
import {
    MedicalRecordAllOfFromJSON,
    MedicalRecordAllOfFromJSONTyped,
    MedicalRecordAllOfToJSON,
} from './MedicalRecordAllOf';

/**
 * 
 * @export
 * @interface MedicalRecord
 */
export interface MedicalRecord {
    /**
     * 
     * @type {number}
     * @memberof MedicalRecord
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MedicalRecord
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalRecord
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof MedicalRecord
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof MedicalRecord
     */
    babyId?: number;
    /**
     * 
     * @type {number}
     * @memberof MedicalRecord
     */
    diseasesId?: number;
    /**
     * 
     * @type {string}
     * @memberof MedicalRecord
     */
    diseaseName?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalRecord
     */
    sickDay?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalRecord
     */
    symptom?: string;
}

/**
 * Check if a given object implements the MedicalRecord interface.
 */
export function instanceOfMedicalRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MedicalRecordFromJSON(json: any): MedicalRecord {
    return MedicalRecordFromJSONTyped(json, false);
}

export function MedicalRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'babyId': !exists(json, 'babyId') ? undefined : json['babyId'],
        'diseasesId': !exists(json, 'diseasesId') ? undefined : json['diseasesId'],
        'diseaseName': !exists(json, 'diseaseName') ? undefined : json['diseaseName'],
        'sickDay': !exists(json, 'sickDay') ? undefined : json['sickDay'],
        'symptom': !exists(json, 'symptom') ? undefined : json['symptom'],
    };
}

export function MedicalRecordToJSON(value?: MedicalRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'userId': value.userId,
        'babyId': value.babyId,
        'diseasesId': value.diseasesId,
        'diseaseName': value.diseaseName,
        'sickDay': value.sickDay,
        'symptom': value.symptom,
    };
}

