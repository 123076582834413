/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Mother,
    MotherFromJSON,
    MotherToJSON,
    MotherPagination,
    MotherPaginationFromJSON,
    MotherPaginationToJSON,
} from '../models';

export interface MothersAdminIdGetRequest {
    id: number;
}

export interface MothersCurrentUserPostRequest {
    mother?: Mother;
}

export interface MothersCurrentUserPutRequest {
    mother?: Mother;
}

export interface MothersCurrentUserShareDetailGetRequest {
    sourceUserId: number;
}

export interface MothersGetManyGetRequest {
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
    keyword?: string;
}

/**
 * 
 */
export class MothersApi extends runtime.BaseAPI {

    /**
     * 
     */
    mothersAdminIdGetRaw = async (requestParameters: MothersAdminIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Mother>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mothersAdminIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/mothers/admin/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MotherFromJSON(jsonValue));
    }

    /**
     * 
     */
    mothersAdminIdGet = async (requestParameters: MothersAdminIdGetRequest, initOverrides?: RequestInit): Promise<Mother> => {
        const response = await this.mothersAdminIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    mothersCurrentUserGetRaw = async (initOverrides?: RequestInit): Promise<runtime.ApiResponse<Mother>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/mothers/currentUser`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MotherFromJSON(jsonValue));
    }

    /**
     * 
     */
    mothersCurrentUserGet = async (initOverrides?: RequestInit): Promise<Mother> => {
        const response = await this.mothersCurrentUserGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    mothersCurrentUserPostRaw = async (requestParameters: MothersCurrentUserPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Mother>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/mothers/currentUser`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MotherToJSON(requestParameters.mother),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MotherFromJSON(jsonValue));
    }

    /**
     */
    mothersCurrentUserPost = async (requestParameters: MothersCurrentUserPostRequest = {}, initOverrides?: RequestInit): Promise<Mother> => {
        const response = await this.mothersCurrentUserPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    mothersCurrentUserPutRaw = async (requestParameters: MothersCurrentUserPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Mother>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/mothers/currentUser`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MotherToJSON(requestParameters.mother),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MotherFromJSON(jsonValue));
    }

    /**
     */
    mothersCurrentUserPut = async (requestParameters: MothersCurrentUserPutRequest = {}, initOverrides?: RequestInit): Promise<Mother> => {
        const response = await this.mothersCurrentUserPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    mothersCurrentUserShareDetailGetRaw = async (requestParameters: MothersCurrentUserShareDetailGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Mother>> => {
        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling mothersCurrentUserShareDetailGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/mothers/currentUser/share/detail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MotherFromJSON(jsonValue));
    }

    /**
     * 
     */
    mothersCurrentUserShareDetailGet = async (requestParameters: MothersCurrentUserShareDetailGetRequest, initOverrides?: RequestInit): Promise<Mother> => {
        const response = await this.mothersCurrentUserShareDetailGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    mothersGetManyGetRaw = async (requestParameters: MothersGetManyGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MotherPagination>> => {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.keyword !== undefined) {
            queryParameters['keyword'] = requestParameters.keyword;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/mothers/getMany`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MotherPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    mothersGetManyGet = async (requestParameters: MothersGetManyGetRequest = {}, initOverrides?: RequestInit): Promise<MotherPagination> => {
        const response = await this.mothersGetManyGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
