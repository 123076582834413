/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';
import type { CheckupDataAllOf } from './CheckupDataAllOf';
import {
    CheckupDataAllOfFromJSON,
    CheckupDataAllOfFromJSONTyped,
    CheckupDataAllOfToJSON,
} from './CheckupDataAllOf';

/**
 * 
 * @export
 * @interface CheckupData
 */
export interface CheckupData {
    /**
     * 
     * @type {number}
     * @memberof CheckupData
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CheckupData
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckupData
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof CheckupData
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof CheckupData
     */
    caseId?: number;
    /**
     * 
     * @type {string}
     * @memberof CheckupData
     */
    checkupDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CheckupData
     */
    doctorName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CheckupData
     */
    fetusWeeks?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CheckupData
     */
    fetusDays?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CheckupData
     */
    fetusWeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CheckupData
     */
    uterineSize?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CheckupData
     */
    waistSize?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CheckupData
     */
    pregnantWeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CheckupData
     */
    bloodPressure1?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CheckupData
     */
    bloodPressure2?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CheckupData
     */
    edema?: string | null;
}

/**
 * Check if a given object implements the CheckupData interface.
 */
export function instanceOfCheckupData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CheckupDataFromJSON(json: any): CheckupData {
    return CheckupDataFromJSONTyped(json, false);
}

export function CheckupDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckupData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'caseId': !exists(json, 'caseId') ? undefined : json['caseId'],
        'checkupDate': !exists(json, 'checkupDate') ? undefined : json['checkupDate'],
        'doctorName': !exists(json, 'doctorName') ? undefined : json['doctorName'],
        'fetusWeeks': !exists(json, 'fetusWeeks') ? undefined : json['fetusWeeks'],
        'fetusDays': !exists(json, 'fetusDays') ? undefined : json['fetusDays'],
        'fetusWeight': !exists(json, 'fetusWeight') ? undefined : json['fetusWeight'],
        'uterineSize': !exists(json, 'uterineSize') ? undefined : json['uterineSize'],
        'waistSize': !exists(json, 'waistSize') ? undefined : json['waistSize'],
        'pregnantWeight': !exists(json, 'pregnantWeight') ? undefined : json['pregnantWeight'],
        'bloodPressure1': !exists(json, 'bloodPressure1') ? undefined : json['bloodPressure1'],
        'bloodPressure2': !exists(json, 'bloodPressure2') ? undefined : json['bloodPressure2'],
        'edema': !exists(json, 'edema') ? undefined : json['edema'],
    };
}

export function CheckupDataToJSON(value?: CheckupData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'userId': value.userId,
        'caseId': value.caseId,
        'checkupDate': value.checkupDate,
        'doctorName': value.doctorName,
        'fetusWeeks': value.fetusWeeks,
        'fetusDays': value.fetusDays,
        'fetusWeight': value.fetusWeight,
        'uterineSize': value.uterineSize,
        'waistSize': value.waistSize,
        'pregnantWeight': value.pregnantWeight,
        'bloodPressure1': value.bloodPressure1,
        'bloodPressure2': value.bloodPressure2,
        'edema': value.edema,
    };
}

