/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';
import type { InfantOneAndSixYearOldAllOf } from './InfantOneAndSixYearOldAllOf';
import {
    InfantOneAndSixYearOldAllOfFromJSON,
    InfantOneAndSixYearOldAllOfFromJSONTyped,
    InfantOneAndSixYearOldAllOfToJSON,
} from './InfantOneAndSixYearOldAllOf';

/**
 * 
 * @export
 * @interface InfantOneAndSixYearOld
 */
export interface InfantOneAndSixYearOld {
    /**
     * 
     * @type {number}
     * @memberof InfantOneAndSixYearOld
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof InfantOneAndSixYearOld
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof InfantOneAndSixYearOld
     */
    babyId?: number;
    /**
     * 
     * @type {number}
     * @memberof InfantOneAndSixYearOld
     */
    ageId?: number;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    implementationDate?: string;
    /**
     * 
     * @type {number}
     * @memberof InfantOneAndSixYearOld
     */
    year?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantOneAndSixYearOld
     */
    month?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    photo1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    photo2?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InfantOneAndSixYearOld
     */
    bodyWeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantOneAndSixYearOld
     */
    height?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantOneAndSixYearOld
     */
    kaupIndex?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantOneAndSixYearOld
     */
    chestCircumference?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantOneAndSixYearOld
     */
    headCircumference?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    nutritionalStatus?: InfantOneAndSixYearOldNutritionalStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    breastMilk?: InfantOneAndSixYearOldBreastMilkEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    weaning?: InfantOneAndSixYearOldWeaningEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    eyeAbnormalities?: InfantOneAndSixYearOldEyeAbnormalitiesEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    eyeAbnormalitiesNote?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    earAbnormalities?: InfantOneAndSixYearOldEarAbnormalitiesEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    earAbnormalitiesNote?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    healthObservation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    remarks?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    facilityOrPersonName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    dayCaregiver?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    whatReceiving?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    affectedDental?: InfantOneAndSixYearOldAffectedDentalEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    cariesRequiringTreatment?: InfantOneAndSixYearOldCariesRequiringTreatmentEnum;
    /**
     * 
     * @type {number}
     * @memberof InfantOneAndSixYearOld
     */
    milkTeeth1?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantOneAndSixYearOld
     */
    permanentTeeth1?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantOneAndSixYearOld
     */
    total?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    treatedCaries?: InfantOneAndSixYearOldTreatedCariesEnum;
    /**
     * 
     * @type {number}
     * @memberof InfantOneAndSixYearOld
     */
    milkTeeth2?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantOneAndSixYearOld
     */
    permanentTeeth2?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    dirtyTeeth?: InfantOneAndSixYearOldDirtyTeethEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    gingivaMucousMembrane?: InfantOneAndSixYearOldGingivaMucousMembraneEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    gingivaMucousMembraneNote?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    engagement?: InfantOneAndSixYearOldEngagementEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    examinationDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    rightMolarsMaxilla1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    leftFrontToothMaxilla1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    rightMolarsLowerJaw1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    leftFrontToothLowerJaw1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    recordingDate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InfantOneAndSixYearOld
     */
    walkAloneMonth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantOneAndSixYearOld
     */
    walkAloneDay?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    meaningfulWords?: InfantOneAndSixYearOldMeaningfulWordsEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    drinkWaterOwnCup?: InfantOneAndSixYearOldDrinkWaterOwnCupEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    babyBottle?: InfantOneAndSixYearOldBabyBottleEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    fixedTimeForMealsAndSnacks?: InfantOneAndSixYearOldFixedTimeForMealsAndSnacksEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    brushYourTeeth?: InfantOneAndSixYearOldBrushYourTeethEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    worriedYourEyes?: InfantOneAndSixYearOldWorriedYourEyesEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    turnAround?: InfantOneAndSixYearOldTurnAroundEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    kindOfPlay?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    applyFluoride?: InfantOneAndSixYearOldApplyFluorideEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    childRearing?: InfantOneAndSixYearOldChildRearingEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    raisingChildren?: InfantOneAndSixYearOldRaisingChildrenEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    yourGrowth?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    rightMolarsMaxilla2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    leftFrontToothMaxilla2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    rightMolarsLowerJaw2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    leftFrontToothLowerJaw2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    resultOfWithFindings?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    judgement?: InfantOneAndSixYearOldJudgementEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneAndSixYearOld
     */
    photo3?: string | null;
}


/**
 * @export
 */
export const InfantOneAndSixYearOldNutritionalStatusEnum = {
    Good: 'good',
    InstructionRequired: 'instructionRequired'
} as const;
export type InfantOneAndSixYearOldNutritionalStatusEnum = typeof InfantOneAndSixYearOldNutritionalStatusEnum[keyof typeof InfantOneAndSixYearOldNutritionalStatusEnum];

/**
 * @export
 */
export const InfantOneAndSixYearOldBreastMilkEnum = {
    NotDrink: 'notDrink',
    Drink: 'drink'
} as const;
export type InfantOneAndSixYearOldBreastMilkEnum = typeof InfantOneAndSixYearOldBreastMilkEnum[keyof typeof InfantOneAndSixYearOldBreastMilkEnum];

/**
 * @export
 */
export const InfantOneAndSixYearOldWeaningEnum = {
    Complete: 'complete',
    Incomplete: 'incomplete'
} as const;
export type InfantOneAndSixYearOldWeaningEnum = typeof InfantOneAndSixYearOldWeaningEnum[keyof typeof InfantOneAndSixYearOldWeaningEnum];

/**
 * @export
 */
export const InfantOneAndSixYearOldEyeAbnormalitiesEnum = {
    None: 'none',
    CanBe: 'canBe',
    Suspicion: 'suspicion'
} as const;
export type InfantOneAndSixYearOldEyeAbnormalitiesEnum = typeof InfantOneAndSixYearOldEyeAbnormalitiesEnum[keyof typeof InfantOneAndSixYearOldEyeAbnormalitiesEnum];

/**
 * @export
 */
export const InfantOneAndSixYearOldEarAbnormalitiesEnum = {
    None: 'none',
    CanBe: 'canBe',
    Suspicion: 'suspicion'
} as const;
export type InfantOneAndSixYearOldEarAbnormalitiesEnum = typeof InfantOneAndSixYearOldEarAbnormalitiesEnum[keyof typeof InfantOneAndSixYearOldEarAbnormalitiesEnum];

/**
 * @export
 */
export const InfantOneAndSixYearOldAffectedDentalEnum = {
    O1: 'o1',
    O2: 'o2',
    A: 'a',
    B: 'b',
    C: 'c'
} as const;
export type InfantOneAndSixYearOldAffectedDentalEnum = typeof InfantOneAndSixYearOldAffectedDentalEnum[keyof typeof InfantOneAndSixYearOldAffectedDentalEnum];

/**
 * @export
 */
export const InfantOneAndSixYearOldCariesRequiringTreatmentEnum = {
    None: 'none',
    CanBe: 'canBe',
    NotClear: 'notClear'
} as const;
export type InfantOneAndSixYearOldCariesRequiringTreatmentEnum = typeof InfantOneAndSixYearOldCariesRequiringTreatmentEnum[keyof typeof InfantOneAndSixYearOldCariesRequiringTreatmentEnum];

/**
 * @export
 */
export const InfantOneAndSixYearOldTreatedCariesEnum = {
    None: 'none',
    CanBe: 'canBe'
} as const;
export type InfantOneAndSixYearOldTreatedCariesEnum = typeof InfantOneAndSixYearOldTreatedCariesEnum[keyof typeof InfantOneAndSixYearOldTreatedCariesEnum];

/**
 * @export
 */
export const InfantOneAndSixYearOldDirtyTeethEnum = {
    Beautiful: 'beautiful',
    Few: 'few',
    Many: 'many'
} as const;
export type InfantOneAndSixYearOldDirtyTeethEnum = typeof InfantOneAndSixYearOldDirtyTeethEnum[keyof typeof InfantOneAndSixYearOldDirtyTeethEnum];

/**
 * @export
 */
export const InfantOneAndSixYearOldGingivaMucousMembraneEnum = {
    NoAbnormality: 'noAbnormality',
    CanBe: 'canBe',
    NotClear: 'notClear'
} as const;
export type InfantOneAndSixYearOldGingivaMucousMembraneEnum = typeof InfantOneAndSixYearOldGingivaMucousMembraneEnum[keyof typeof InfantOneAndSixYearOldGingivaMucousMembraneEnum];

/**
 * @export
 */
export const InfantOneAndSixYearOldEngagementEnum = {
    Good: 'good',
    FollowUp: 'followUp',
    NotClear: 'notClear'
} as const;
export type InfantOneAndSixYearOldEngagementEnum = typeof InfantOneAndSixYearOldEngagementEnum[keyof typeof InfantOneAndSixYearOldEngagementEnum];

/**
 * @export
 */
export const InfantOneAndSixYearOldMeaningfulWordsEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantOneAndSixYearOldMeaningfulWordsEnum = typeof InfantOneAndSixYearOldMeaningfulWordsEnum[keyof typeof InfantOneAndSixYearOldMeaningfulWordsEnum];

/**
 * @export
 */
export const InfantOneAndSixYearOldDrinkWaterOwnCupEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantOneAndSixYearOldDrinkWaterOwnCupEnum = typeof InfantOneAndSixYearOldDrinkWaterOwnCupEnum[keyof typeof InfantOneAndSixYearOldDrinkWaterOwnCupEnum];

/**
 * @export
 */
export const InfantOneAndSixYearOldBabyBottleEnum = {
    No: 'no',
    Yes: 'yes'
} as const;
export type InfantOneAndSixYearOldBabyBottleEnum = typeof InfantOneAndSixYearOldBabyBottleEnum[keyof typeof InfantOneAndSixYearOldBabyBottleEnum];

/**
 * @export
 */
export const InfantOneAndSixYearOldFixedTimeForMealsAndSnacksEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantOneAndSixYearOldFixedTimeForMealsAndSnacksEnum = typeof InfantOneAndSixYearOldFixedTimeForMealsAndSnacksEnum[keyof typeof InfantOneAndSixYearOldFixedTimeForMealsAndSnacksEnum];

/**
 * @export
 */
export const InfantOneAndSixYearOldBrushYourTeethEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantOneAndSixYearOldBrushYourTeethEnum = typeof InfantOneAndSixYearOldBrushYourTeethEnum[keyof typeof InfantOneAndSixYearOldBrushYourTeethEnum];

/**
 * @export
 */
export const InfantOneAndSixYearOldWorriedYourEyesEnum = {
    No: 'no',
    Yes: 'yes'
} as const;
export type InfantOneAndSixYearOldWorriedYourEyesEnum = typeof InfantOneAndSixYearOldWorriedYourEyesEnum[keyof typeof InfantOneAndSixYearOldWorriedYourEyesEnum];

/**
 * @export
 */
export const InfantOneAndSixYearOldTurnAroundEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantOneAndSixYearOldTurnAroundEnum = typeof InfantOneAndSixYearOldTurnAroundEnum[keyof typeof InfantOneAndSixYearOldTurnAroundEnum];

/**
 * @export
 */
export const InfantOneAndSixYearOldApplyFluorideEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantOneAndSixYearOldApplyFluorideEnum = typeof InfantOneAndSixYearOldApplyFluorideEnum[keyof typeof InfantOneAndSixYearOldApplyFluorideEnum];

/**
 * @export
 */
export const InfantOneAndSixYearOldChildRearingEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantOneAndSixYearOldChildRearingEnum = typeof InfantOneAndSixYearOldChildRearingEnum[keyof typeof InfantOneAndSixYearOldChildRearingEnum];

/**
 * @export
 */
export const InfantOneAndSixYearOldRaisingChildrenEnum = {
    No: 'no',
    Yes: 'yes',
    CanNotSayAnything: 'canNotSayAnything'
} as const;
export type InfantOneAndSixYearOldRaisingChildrenEnum = typeof InfantOneAndSixYearOldRaisingChildrenEnum[keyof typeof InfantOneAndSixYearOldRaisingChildrenEnum];

/**
 * @export
 */
export const InfantOneAndSixYearOldJudgementEnum = {
    NoAbnormality: 'noAbnormality',
    AlreadyMedical: 'alreadyMedical',
    FollowUpRequired: 'followUpRequired',
    PrecisionRequired: 'precisionRequired',
    TreatmentRequired: 'treatmentRequired'
} as const;
export type InfantOneAndSixYearOldJudgementEnum = typeof InfantOneAndSixYearOldJudgementEnum[keyof typeof InfantOneAndSixYearOldJudgementEnum];


/**
 * Check if a given object implements the InfantOneAndSixYearOld interface.
 */
export function instanceOfInfantOneAndSixYearOld(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InfantOneAndSixYearOldFromJSON(json: any): InfantOneAndSixYearOld {
    return InfantOneAndSixYearOldFromJSONTyped(json, false);
}

export function InfantOneAndSixYearOldFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfantOneAndSixYearOld {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'babyId': !exists(json, 'babyId') ? undefined : json['babyId'],
        'ageId': !exists(json, 'ageId') ? undefined : json['ageId'],
        'implementationDate': !exists(json, 'implementationDate') ? undefined : json['implementationDate'],
        'year': !exists(json, 'year') ? undefined : json['year'],
        'month': !exists(json, 'month') ? undefined : json['month'],
        'photo1': !exists(json, 'photo1') ? undefined : json['photo1'],
        'photo2': !exists(json, 'photo2') ? undefined : json['photo2'],
        'bodyWeight': !exists(json, 'bodyWeight') ? undefined : json['bodyWeight'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'kaupIndex': !exists(json, 'kaupIndex') ? undefined : json['kaupIndex'],
        'chestCircumference': !exists(json, 'chestCircumference') ? undefined : json['chestCircumference'],
        'headCircumference': !exists(json, 'headCircumference') ? undefined : json['headCircumference'],
        'nutritionalStatus': !exists(json, 'nutritionalStatus') ? undefined : json['nutritionalStatus'],
        'breastMilk': !exists(json, 'breastMilk') ? undefined : json['breastMilk'],
        'weaning': !exists(json, 'weaning') ? undefined : json['weaning'],
        'eyeAbnormalities': !exists(json, 'eyeAbnormalities') ? undefined : json['eyeAbnormalities'],
        'eyeAbnormalitiesNote': !exists(json, 'eyeAbnormalitiesNote') ? undefined : json['eyeAbnormalitiesNote'],
        'earAbnormalities': !exists(json, 'earAbnormalities') ? undefined : json['earAbnormalities'],
        'earAbnormalitiesNote': !exists(json, 'earAbnormalitiesNote') ? undefined : json['earAbnormalitiesNote'],
        'healthObservation': !exists(json, 'healthObservation') ? undefined : json['healthObservation'],
        'remarks': !exists(json, 'remarks') ? undefined : json['remarks'],
        'facilityOrPersonName': !exists(json, 'facilityOrPersonName') ? undefined : json['facilityOrPersonName'],
        'dayCaregiver': !exists(json, 'dayCaregiver') ? undefined : json['dayCaregiver'],
        'whatReceiving': !exists(json, 'whatReceiving') ? undefined : json['whatReceiving'],
        'affectedDental': !exists(json, 'affectedDental') ? undefined : json['affectedDental'],
        'cariesRequiringTreatment': !exists(json, 'cariesRequiringTreatment') ? undefined : json['cariesRequiringTreatment'],
        'milkTeeth1': !exists(json, 'milkTeeth1') ? undefined : json['milkTeeth1'],
        'permanentTeeth1': !exists(json, 'permanentTeeth1') ? undefined : json['permanentTeeth1'],
        'total': !exists(json, 'total') ? undefined : json['total'],
        'treatedCaries': !exists(json, 'treatedCaries') ? undefined : json['treatedCaries'],
        'milkTeeth2': !exists(json, 'milkTeeth2') ? undefined : json['milkTeeth2'],
        'permanentTeeth2': !exists(json, 'permanentTeeth2') ? undefined : json['permanentTeeth2'],
        'dirtyTeeth': !exists(json, 'dirtyTeeth') ? undefined : json['dirtyTeeth'],
        'gingivaMucousMembrane': !exists(json, 'gingivaMucousMembrane') ? undefined : json['gingivaMucousMembrane'],
        'gingivaMucousMembraneNote': !exists(json, 'gingivaMucousMembraneNote') ? undefined : json['gingivaMucousMembraneNote'],
        'engagement': !exists(json, 'engagement') ? undefined : json['engagement'],
        'examinationDate': !exists(json, 'examinationDate') ? undefined : json['examinationDate'],
        'rightMolarsMaxilla1': !exists(json, 'rightMolarsMaxilla1') ? undefined : json['rightMolarsMaxilla1'],
        'leftFrontToothMaxilla1': !exists(json, 'leftFrontToothMaxilla1') ? undefined : json['leftFrontToothMaxilla1'],
        'rightMolarsLowerJaw1': !exists(json, 'rightMolarsLowerJaw1') ? undefined : json['rightMolarsLowerJaw1'],
        'leftFrontToothLowerJaw1': !exists(json, 'leftFrontToothLowerJaw1') ? undefined : json['leftFrontToothLowerJaw1'],
        'recordingDate': !exists(json, 'recordingDate') ? undefined : json['recordingDate'],
        'walkAloneMonth': !exists(json, 'walkAloneMonth') ? undefined : json['walkAloneMonth'],
        'walkAloneDay': !exists(json, 'walkAloneDay') ? undefined : json['walkAloneDay'],
        'meaningfulWords': !exists(json, 'meaningfulWords') ? undefined : json['meaningfulWords'],
        'drinkWaterOwnCup': !exists(json, 'drinkWaterOwnCup') ? undefined : json['drinkWaterOwnCup'],
        'babyBottle': !exists(json, 'babyBottle') ? undefined : json['babyBottle'],
        'fixedTimeForMealsAndSnacks': !exists(json, 'fixedTimeForMealsAndSnacks') ? undefined : json['fixedTimeForMealsAndSnacks'],
        'brushYourTeeth': !exists(json, 'brushYourTeeth') ? undefined : json['brushYourTeeth'],
        'worriedYourEyes': !exists(json, 'worriedYourEyes') ? undefined : json['worriedYourEyes'],
        'turnAround': !exists(json, 'turnAround') ? undefined : json['turnAround'],
        'kindOfPlay': !exists(json, 'kindOfPlay') ? undefined : json['kindOfPlay'],
        'applyFluoride': !exists(json, 'applyFluoride') ? undefined : json['applyFluoride'],
        'childRearing': !exists(json, 'childRearing') ? undefined : json['childRearing'],
        'raisingChildren': !exists(json, 'raisingChildren') ? undefined : json['raisingChildren'],
        'yourGrowth': !exists(json, 'yourGrowth') ? undefined : json['yourGrowth'],
        'rightMolarsMaxilla2': !exists(json, 'rightMolarsMaxilla2') ? undefined : json['rightMolarsMaxilla2'],
        'leftFrontToothMaxilla2': !exists(json, 'leftFrontToothMaxilla2') ? undefined : json['leftFrontToothMaxilla2'],
        'rightMolarsLowerJaw2': !exists(json, 'rightMolarsLowerJaw2') ? undefined : json['rightMolarsLowerJaw2'],
        'leftFrontToothLowerJaw2': !exists(json, 'leftFrontToothLowerJaw2') ? undefined : json['leftFrontToothLowerJaw2'],
        'resultOfWithFindings': !exists(json, 'resultOfWithFindings') ? undefined : json['resultOfWithFindings'],
        'judgement': !exists(json, 'judgement') ? undefined : json['judgement'],
        'photo3': !exists(json, 'photo3') ? undefined : json['photo3'],
    };
}

export function InfantOneAndSixYearOldToJSON(value?: InfantOneAndSixYearOld | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'userId': value.userId,
        'babyId': value.babyId,
        'ageId': value.ageId,
        'implementationDate': value.implementationDate,
        'year': value.year,
        'month': value.month,
        'photo1': value.photo1,
        'photo2': value.photo2,
        'bodyWeight': value.bodyWeight,
        'height': value.height,
        'kaupIndex': value.kaupIndex,
        'chestCircumference': value.chestCircumference,
        'headCircumference': value.headCircumference,
        'nutritionalStatus': value.nutritionalStatus,
        'breastMilk': value.breastMilk,
        'weaning': value.weaning,
        'eyeAbnormalities': value.eyeAbnormalities,
        'eyeAbnormalitiesNote': value.eyeAbnormalitiesNote,
        'earAbnormalities': value.earAbnormalities,
        'earAbnormalitiesNote': value.earAbnormalitiesNote,
        'healthObservation': value.healthObservation,
        'remarks': value.remarks,
        'facilityOrPersonName': value.facilityOrPersonName,
        'dayCaregiver': value.dayCaregiver,
        'whatReceiving': value.whatReceiving,
        'affectedDental': value.affectedDental,
        'cariesRequiringTreatment': value.cariesRequiringTreatment,
        'milkTeeth1': value.milkTeeth1,
        'permanentTeeth1': value.permanentTeeth1,
        'total': value.total,
        'treatedCaries': value.treatedCaries,
        'milkTeeth2': value.milkTeeth2,
        'permanentTeeth2': value.permanentTeeth2,
        'dirtyTeeth': value.dirtyTeeth,
        'gingivaMucousMembrane': value.gingivaMucousMembrane,
        'gingivaMucousMembraneNote': value.gingivaMucousMembraneNote,
        'engagement': value.engagement,
        'examinationDate': value.examinationDate,
        'rightMolarsMaxilla1': value.rightMolarsMaxilla1,
        'leftFrontToothMaxilla1': value.leftFrontToothMaxilla1,
        'rightMolarsLowerJaw1': value.rightMolarsLowerJaw1,
        'leftFrontToothLowerJaw1': value.leftFrontToothLowerJaw1,
        'recordingDate': value.recordingDate,
        'walkAloneMonth': value.walkAloneMonth,
        'walkAloneDay': value.walkAloneDay,
        'meaningfulWords': value.meaningfulWords,
        'drinkWaterOwnCup': value.drinkWaterOwnCup,
        'babyBottle': value.babyBottle,
        'fixedTimeForMealsAndSnacks': value.fixedTimeForMealsAndSnacks,
        'brushYourTeeth': value.brushYourTeeth,
        'worriedYourEyes': value.worriedYourEyes,
        'turnAround': value.turnAround,
        'kindOfPlay': value.kindOfPlay,
        'applyFluoride': value.applyFluoride,
        'childRearing': value.childRearing,
        'raisingChildren': value.raisingChildren,
        'yourGrowth': value.yourGrowth,
        'rightMolarsMaxilla2': value.rightMolarsMaxilla2,
        'leftFrontToothMaxilla2': value.leftFrontToothMaxilla2,
        'rightMolarsLowerJaw2': value.rightMolarsLowerJaw2,
        'leftFrontToothLowerJaw2': value.leftFrontToothLowerJaw2,
        'resultOfWithFindings': value.resultOfWithFindings,
        'judgement': value.judgement,
        'photo3': value.photo3,
    };
}

