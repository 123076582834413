/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InfantInspectionRecord,
    InfantInspectionRecordFromJSON,
    InfantInspectionRecordToJSON,
    InfantInspectionRecordPanigation,
    InfantInspectionRecordPanigationFromJSON,
    InfantInspectionRecordPanigationToJSON,
} from '../models';

export interface AdminInfantInspectionRecordsIdGetRequest {
    id: number;
    userId?: number;
}

export interface InfantInspectionRecordsGetRequest {
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface InfantInspectionRecordsIdDeleteRequest {
    id: number;
}

export interface InfantInspectionRecordsIdGetRequest {
    id: number;
}

export interface InfantInspectionRecordsIdPutRequest {
    id: number;
    infantInspectionRecord?: InfantInspectionRecord;
}

export interface InfantInspectionRecordsPostRequest {
    infantInspectionRecord?: InfantInspectionRecord;
}

export interface InfantInspectionRecordsShareAllGetRequest {
    sourceUserId: number;
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface InfantInspectionRecordsShareDetailGetRequest {
    recordId: number;
    sourceUserId: number;
}

/**
 * 
 */
export class InfantInspectionRecordsApi extends runtime.BaseAPI {

    /**
     * 
     */
    adminInfantInspectionRecordsIdGetRaw = async (requestParameters: AdminInfantInspectionRecordsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantInspectionRecord>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling adminInfantInspectionRecordsIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/infantInspectionRecords/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantInspectionRecordFromJSON(jsonValue));
    }

    /**
     * 
     */
    adminInfantInspectionRecordsIdGet = async (requestParameters: AdminInfantInspectionRecordsIdGetRequest, initOverrides?: RequestInit): Promise<InfantInspectionRecord> => {
        const response = await this.adminInfantInspectionRecordsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantInspectionRecordsGetRaw = async (requestParameters: InfantInspectionRecordsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<InfantInspectionRecordPanigation>>> => {
        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling infantInspectionRecordsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantInspectionRecords`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InfantInspectionRecordPanigationFromJSON));
    }

    /**
     * 
     */
    infantInspectionRecordsGet = async (requestParameters: InfantInspectionRecordsGetRequest, initOverrides?: RequestInit): Promise<Array<InfantInspectionRecordPanigation>> => {
        const response = await this.infantInspectionRecordsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    infantInspectionRecordsIdDeleteRaw = async (requestParameters: InfantInspectionRecordsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling infantInspectionRecordsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantInspectionRecords/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    infantInspectionRecordsIdDelete = async (requestParameters: InfantInspectionRecordsIdDeleteRequest, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.infantInspectionRecordsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantInspectionRecordsIdGetRaw = async (requestParameters: InfantInspectionRecordsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantInspectionRecord>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling infantInspectionRecordsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantInspectionRecords/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantInspectionRecordFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantInspectionRecordsIdGet = async (requestParameters: InfantInspectionRecordsIdGetRequest, initOverrides?: RequestInit): Promise<InfantInspectionRecord> => {
        const response = await this.infantInspectionRecordsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    infantInspectionRecordsIdPutRaw = async (requestParameters: InfantInspectionRecordsIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantInspectionRecord>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling infantInspectionRecordsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/infantInspectionRecords/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InfantInspectionRecordToJSON(requestParameters.infantInspectionRecord),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantInspectionRecordFromJSON(jsonValue));
    }

    /**
     */
    infantInspectionRecordsIdPut = async (requestParameters: InfantInspectionRecordsIdPutRequest, initOverrides?: RequestInit): Promise<InfantInspectionRecord> => {
        const response = await this.infantInspectionRecordsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    infantInspectionRecordsPostRaw = async (requestParameters: InfantInspectionRecordsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantInspectionRecord>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/infantInspectionRecords`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InfantInspectionRecordToJSON(requestParameters.infantInspectionRecord),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantInspectionRecordFromJSON(jsonValue));
    }

    /**
     */
    infantInspectionRecordsPost = async (requestParameters: InfantInspectionRecordsPostRequest = {}, initOverrides?: RequestInit): Promise<InfantInspectionRecord> => {
        const response = await this.infantInspectionRecordsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantInspectionRecordsShareAllGetRaw = async (requestParameters: InfantInspectionRecordsShareAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<InfantInspectionRecordPanigation>>> => {
        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling infantInspectionRecordsShareAllGet.');
        }

        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling infantInspectionRecordsShareAllGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantInspectionRecords/share/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(InfantInspectionRecordPanigationFromJSON));
    }

    /**
     * 
     */
    infantInspectionRecordsShareAllGet = async (requestParameters: InfantInspectionRecordsShareAllGetRequest, initOverrides?: RequestInit): Promise<Array<InfantInspectionRecordPanigation>> => {
        const response = await this.infantInspectionRecordsShareAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantInspectionRecordsShareDetailGetRaw = async (requestParameters: InfantInspectionRecordsShareDetailGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantInspectionRecord>> => {
        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling infantInspectionRecordsShareDetailGet.');
        }

        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling infantInspectionRecordsShareDetailGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.recordId !== undefined) {
            queryParameters['recordId'] = requestParameters.recordId;
        }

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantInspectionRecords/share/detail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantInspectionRecordFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantInspectionRecordsShareDetailGet = async (requestParameters: InfantInspectionRecordsShareDetailGetRequest, initOverrides?: RequestInit): Promise<InfantInspectionRecord> => {
        const response = await this.infantInspectionRecordsShareDetailGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
