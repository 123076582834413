/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';
import type { InfantSixToSevenMonthOldAllOf } from './InfantSixToSevenMonthOldAllOf';
import {
    InfantSixToSevenMonthOldAllOfFromJSON,
    InfantSixToSevenMonthOldAllOfFromJSONTyped,
    InfantSixToSevenMonthOldAllOfToJSON,
} from './InfantSixToSevenMonthOldAllOf';

/**
 * 
 * @export
 * @interface InfantSixToSevenMonthOld
 */
export interface InfantSixToSevenMonthOld {
    /**
     * 
     * @type {number}
     * @memberof InfantSixToSevenMonthOld
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InfantSixToSevenMonthOld
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof InfantSixToSevenMonthOld
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof InfantSixToSevenMonthOld
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof InfantSixToSevenMonthOld
     */
    babyId?: number;
    /**
     * 
     * @type {number}
     * @memberof InfantSixToSevenMonthOld
     */
    ageId?: number;
    /**
     * 
     * @type {string}
     * @memberof InfantSixToSevenMonthOld
     */
    implementationDate?: string;
    /**
     * 
     * @type {number}
     * @memberof InfantSixToSevenMonthOld
     */
    month?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantSixToSevenMonthOld
     */
    day?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantSixToSevenMonthOld
     */
    photo1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantSixToSevenMonthOld
     */
    photo2?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InfantSixToSevenMonthOld
     */
    bodyWeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantSixToSevenMonthOld
     */
    height?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantSixToSevenMonthOld
     */
    kaupIndex?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantSixToSevenMonthOld
     */
    chestCircumference?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantSixToSevenMonthOld
     */
    headCircumference?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantSixToSevenMonthOld
     */
    nutritionalStatus?: InfantSixToSevenMonthOldNutritionalStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantSixToSevenMonthOld
     */
    nutritionMethod?: InfantSixToSevenMonthOldNutritionMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantSixToSevenMonthOld
     */
    weaning?: InfantSixToSevenMonthOldWeaningEnum;
    /**
     * 
     * @type {number}
     * @memberof InfantSixToSevenMonthOld
     */
    teeth?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantSixToSevenMonthOld
     */
    diseasesAndAbnormalitiesMouth?: InfantSixToSevenMonthOldDiseasesAndAbnormalitiesMouthEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantSixToSevenMonthOld
     */
    diseasesAndAbnormalitiesMouthDesc?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantSixToSevenMonthOld
     */
    healthObservation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantSixToSevenMonthOld
     */
    remarks?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantSixToSevenMonthOld
     */
    facilityOrPersonName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantSixToSevenMonthOld
     */
    dayCaregiver?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantSixToSevenMonthOld
     */
    recordingDate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InfantSixToSevenMonthOld
     */
    turnOverMonth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantSixToSevenMonthOld
     */
    turnOverDay?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantSixToSevenMonthOld
     */
    sitAloneMonth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantSixToSevenMonthOld
     */
    sitAloneDay?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantSixToSevenMonthOld
     */
    toyNearBody?: InfantSixToSevenMonthOldToyNearBodyEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantSixToSevenMonthOld
     */
    talkingVoice?: InfantSixToSevenMonthOldTalkingVoiceEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantSixToSevenMonthOld
     */
    radioSound?: InfantSixToSevenMonthOldRadioSoundEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantSixToSevenMonthOld
     */
    babyFood?: InfantSixToSevenMonthOldBabyFoodEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantSixToSevenMonthOld
     */
    pupilAppear?: InfantSixToSevenMonthOldPupilAppearEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantSixToSevenMonthOld
     */
    childRearing?: InfantSixToSevenMonthOldChildRearingEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantSixToSevenMonthOld
     */
    raisingChildren?: InfantSixToSevenMonthOldRaisingChildrenEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantSixToSevenMonthOld
     */
    yourGrowth?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantSixToSevenMonthOld
     */
    photo3?: string | null;
}


/**
 * @export
 */
export const InfantSixToSevenMonthOldNutritionalStatusEnum = {
    Good: 'good',
    InstructionRequired: 'instructionRequired'
} as const;
export type InfantSixToSevenMonthOldNutritionalStatusEnum = typeof InfantSixToSevenMonthOldNutritionalStatusEnum[keyof typeof InfantSixToSevenMonthOldNutritionalStatusEnum];

/**
 * @export
 */
export const InfantSixToSevenMonthOldNutritionMethodEnum = {
    BreastMilk: 'breastMilk',
    Mixture: 'mixture',
    ArtificialMilk: 'artificialMilk'
} as const;
export type InfantSixToSevenMonthOldNutritionMethodEnum = typeof InfantSixToSevenMonthOldNutritionMethodEnum[keyof typeof InfantSixToSevenMonthOldNutritionMethodEnum];

/**
 * @export
 */
export const InfantSixToSevenMonthOldWeaningEnum = {
    Start: 'start',
    NotStart: 'notStart'
} as const;
export type InfantSixToSevenMonthOldWeaningEnum = typeof InfantSixToSevenMonthOldWeaningEnum[keyof typeof InfantSixToSevenMonthOldWeaningEnum];

/**
 * @export
 */
export const InfantSixToSevenMonthOldDiseasesAndAbnormalitiesMouthEnum = {
    None: 'none',
    CanBe: 'canBe'
} as const;
export type InfantSixToSevenMonthOldDiseasesAndAbnormalitiesMouthEnum = typeof InfantSixToSevenMonthOldDiseasesAndAbnormalitiesMouthEnum[keyof typeof InfantSixToSevenMonthOldDiseasesAndAbnormalitiesMouthEnum];

/**
 * @export
 */
export const InfantSixToSevenMonthOldToyNearBodyEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantSixToSevenMonthOldToyNearBodyEnum = typeof InfantSixToSevenMonthOldToyNearBodyEnum[keyof typeof InfantSixToSevenMonthOldToyNearBodyEnum];

/**
 * @export
 */
export const InfantSixToSevenMonthOldTalkingVoiceEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantSixToSevenMonthOldTalkingVoiceEnum = typeof InfantSixToSevenMonthOldTalkingVoiceEnum[keyof typeof InfantSixToSevenMonthOldTalkingVoiceEnum];

/**
 * @export
 */
export const InfantSixToSevenMonthOldRadioSoundEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantSixToSevenMonthOldRadioSoundEnum = typeof InfantSixToSevenMonthOldRadioSoundEnum[keyof typeof InfantSixToSevenMonthOldRadioSoundEnum];

/**
 * @export
 */
export const InfantSixToSevenMonthOldBabyFoodEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantSixToSevenMonthOldBabyFoodEnum = typeof InfantSixToSevenMonthOldBabyFoodEnum[keyof typeof InfantSixToSevenMonthOldBabyFoodEnum];

/**
 * @export
 */
export const InfantSixToSevenMonthOldPupilAppearEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantSixToSevenMonthOldPupilAppearEnum = typeof InfantSixToSevenMonthOldPupilAppearEnum[keyof typeof InfantSixToSevenMonthOldPupilAppearEnum];

/**
 * @export
 */
export const InfantSixToSevenMonthOldChildRearingEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantSixToSevenMonthOldChildRearingEnum = typeof InfantSixToSevenMonthOldChildRearingEnum[keyof typeof InfantSixToSevenMonthOldChildRearingEnum];

/**
 * @export
 */
export const InfantSixToSevenMonthOldRaisingChildrenEnum = {
    No: 'no',
    Yes: 'yes',
    CanNotSayAnything: 'canNotSayAnything'
} as const;
export type InfantSixToSevenMonthOldRaisingChildrenEnum = typeof InfantSixToSevenMonthOldRaisingChildrenEnum[keyof typeof InfantSixToSevenMonthOldRaisingChildrenEnum];


/**
 * Check if a given object implements the InfantSixToSevenMonthOld interface.
 */
export function instanceOfInfantSixToSevenMonthOld(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InfantSixToSevenMonthOldFromJSON(json: any): InfantSixToSevenMonthOld {
    return InfantSixToSevenMonthOldFromJSONTyped(json, false);
}

export function InfantSixToSevenMonthOldFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfantSixToSevenMonthOld {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'babyId': !exists(json, 'babyId') ? undefined : json['babyId'],
        'ageId': !exists(json, 'ageId') ? undefined : json['ageId'],
        'implementationDate': !exists(json, 'implementationDate') ? undefined : json['implementationDate'],
        'month': !exists(json, 'month') ? undefined : json['month'],
        'day': !exists(json, 'day') ? undefined : json['day'],
        'photo1': !exists(json, 'photo1') ? undefined : json['photo1'],
        'photo2': !exists(json, 'photo2') ? undefined : json['photo2'],
        'bodyWeight': !exists(json, 'bodyWeight') ? undefined : json['bodyWeight'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'kaupIndex': !exists(json, 'kaupIndex') ? undefined : json['kaupIndex'],
        'chestCircumference': !exists(json, 'chestCircumference') ? undefined : json['chestCircumference'],
        'headCircumference': !exists(json, 'headCircumference') ? undefined : json['headCircumference'],
        'nutritionalStatus': !exists(json, 'nutritionalStatus') ? undefined : json['nutritionalStatus'],
        'nutritionMethod': !exists(json, 'nutritionMethod') ? undefined : json['nutritionMethod'],
        'weaning': !exists(json, 'weaning') ? undefined : json['weaning'],
        'teeth': !exists(json, 'teeth') ? undefined : json['teeth'],
        'diseasesAndAbnormalitiesMouth': !exists(json, 'diseasesAndAbnormalitiesMouth') ? undefined : json['diseasesAndAbnormalitiesMouth'],
        'diseasesAndAbnormalitiesMouthDesc': !exists(json, 'diseasesAndAbnormalitiesMouthDesc') ? undefined : json['diseasesAndAbnormalitiesMouthDesc'],
        'healthObservation': !exists(json, 'healthObservation') ? undefined : json['healthObservation'],
        'remarks': !exists(json, 'remarks') ? undefined : json['remarks'],
        'facilityOrPersonName': !exists(json, 'facilityOrPersonName') ? undefined : json['facilityOrPersonName'],
        'dayCaregiver': !exists(json, 'dayCaregiver') ? undefined : json['dayCaregiver'],
        'recordingDate': !exists(json, 'recordingDate') ? undefined : json['recordingDate'],
        'turnOverMonth': !exists(json, 'turnOverMonth') ? undefined : json['turnOverMonth'],
        'turnOverDay': !exists(json, 'turnOverDay') ? undefined : json['turnOverDay'],
        'sitAloneMonth': !exists(json, 'sitAloneMonth') ? undefined : json['sitAloneMonth'],
        'sitAloneDay': !exists(json, 'sitAloneDay') ? undefined : json['sitAloneDay'],
        'toyNearBody': !exists(json, 'toyNearBody') ? undefined : json['toyNearBody'],
        'talkingVoice': !exists(json, 'talkingVoice') ? undefined : json['talkingVoice'],
        'radioSound': !exists(json, 'radioSound') ? undefined : json['radioSound'],
        'babyFood': !exists(json, 'babyFood') ? undefined : json['babyFood'],
        'pupilAppear': !exists(json, 'pupilAppear') ? undefined : json['pupilAppear'],
        'childRearing': !exists(json, 'childRearing') ? undefined : json['childRearing'],
        'raisingChildren': !exists(json, 'raisingChildren') ? undefined : json['raisingChildren'],
        'yourGrowth': !exists(json, 'yourGrowth') ? undefined : json['yourGrowth'],
        'photo3': !exists(json, 'photo3') ? undefined : json['photo3'],
    };
}

export function InfantSixToSevenMonthOldToJSON(value?: InfantSixToSevenMonthOld | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'userId': value.userId,
        'babyId': value.babyId,
        'ageId': value.ageId,
        'implementationDate': value.implementationDate,
        'month': value.month,
        'day': value.day,
        'photo1': value.photo1,
        'photo2': value.photo2,
        'bodyWeight': value.bodyWeight,
        'height': value.height,
        'kaupIndex': value.kaupIndex,
        'chestCircumference': value.chestCircumference,
        'headCircumference': value.headCircumference,
        'nutritionalStatus': value.nutritionalStatus,
        'nutritionMethod': value.nutritionMethod,
        'weaning': value.weaning,
        'teeth': value.teeth,
        'diseasesAndAbnormalitiesMouth': value.diseasesAndAbnormalitiesMouth,
        'diseasesAndAbnormalitiesMouthDesc': value.diseasesAndAbnormalitiesMouthDesc,
        'healthObservation': value.healthObservation,
        'remarks': value.remarks,
        'facilityOrPersonName': value.facilityOrPersonName,
        'dayCaregiver': value.dayCaregiver,
        'recordingDate': value.recordingDate,
        'turnOverMonth': value.turnOverMonth,
        'turnOverDay': value.turnOverDay,
        'sitAloneMonth': value.sitAloneMonth,
        'sitAloneDay': value.sitAloneDay,
        'toyNearBody': value.toyNearBody,
        'talkingVoice': value.talkingVoice,
        'radioSound': value.radioSound,
        'babyFood': value.babyFood,
        'pupilAppear': value.pupilAppear,
        'childRearing': value.childRearing,
        'raisingChildren': value.raisingChildren,
        'yourGrowth': value.yourGrowth,
        'photo3': value.photo3,
    };
}

