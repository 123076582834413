import React from "react";
import {
  AvatarCustom,
  BloodSelectionType,
  TeethSelectCustom,
  TextBlockCustom,
} from "@/components/display";
import { Grid } from "@mui/material";
import { COLORS } from "@/constants";
import {
  InfantMedicalExam,
  InfantOneAndSixYearOldGingivaMucousMembraneEnum,
  infantOneAndSixYearOldsApi,
} from "@/api";
import { useQuery } from "react-query";
import { toJapanDate } from "@/utils";
import { translate } from "@/helpers";

interface IInfantOneAndSixYearOldDetailProps {
  infantMedicalExamItem?: InfantMedicalExam;
}

export const InfantOneAndSixYearOldDetail = (
  props: React.PropsWithChildren<IInfantOneAndSixYearOldDetailProps>
) => {
  const { infantMedicalExamItem } = props;

  //--- Get medical consultant
  const { data: infantOneAndSixYearOld } = useQuery({
    queryKey: "GET_INFANT_ONE_AND_SIX_YEAR_OLD_BY_ID",
    queryFn: () => {
      return infantOneAndSixYearOldsApi.adminInfantOneAndSixYearOldsIdGet({
        id: infantMedicalExamItem?.infantId,
        userId: infantMedicalExamItem?.userId,
      });
    },
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextBlockCustom
          classText="font-bold text-center mb-4"
          textHeader={`${translate("infantMedicalExam.text.age9")} ${translate(
            "infantMedicalExam.text.detail"
          )}`}
          textSize={18}
          headerColor={COLORS.primary}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.implementationDate"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantOneAndSixYearOld?.implementationDate
                  ? toJapanDate(infantOneAndSixYearOld?.implementationDate)
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.ageYear")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                (infantOneAndSixYearOld?.year
                  ? `${infantOneAndSixYearOld?.year}${translate(
                      "infoHealthMon.age"
                    )}`
                  : "") +
                (infantOneAndSixYearOld?.month
                  ? `${infantOneAndSixYearOld?.month}${translate("month.txt")}`
                  : "")
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.photographOfMedical"
              )}
              headerColor={COLORS.approxGrey}
            />

            <div className="flex items-center">
              {infantOneAndSixYearOld?.photo1 && (
                <div className="mr-2">
                  <AvatarCustom imgSrc={infantOneAndSixYearOld?.photo1} />
                </div>
              )}

              {infantOneAndSixYearOld?.photo2 && (
                <AvatarCustom imgSrc={infantOneAndSixYearOld?.photo2} />
              )}
            </div>
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.bodyWeight"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneAndSixYearOld?.bodyWeight
                  ? infantOneAndSixYearOld?.bodyWeight.toString() + "g"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.height")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneAndSixYearOld?.height
                  ? infantOneAndSixYearOld?.height.toString() + "cm"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.kaupIndex"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneAndSixYearOld?.kaupIndex
                  ? infantOneAndSixYearOld?.kaupIndex.toString()
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.chestCircumference"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneAndSixYearOld?.chestCircumference
                  ? infantOneAndSixYearOld?.chestCircumference.toString() + "cm"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.headCircumference"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneAndSixYearOld?.headCircumference
                  ? infantOneAndSixYearOld?.headCircumference.toString() + "cm"
                  : ""
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          {/* 栄養状態/健康・要観察/特記事項など */}
          <Grid item xs={12}>
            <TextBlockCustom
              classText="font-bold"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.nutritionalHealth"
              )}
              headerColor={COLORS.primary}
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.nutritionalStatus"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantOneAndSixYearOld?.nutritionalStatus
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantOneAndSixYearOld?.nutritionalStatus
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.breastMilk"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantOneAndSixYearOld?.breastMilk
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantOneAndSixYearOld?.breastMilk
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.weaning")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantOneAndSixYearOld?.weaning
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantOneAndSixYearOld?.weaning
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.eyeAbnormalities"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantOneAndSixYearOld?.eyeAbnormalities
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantOneAndSixYearOld?.eyeAbnormalities
                    )
                  : ""
              }
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantOneAndSixYearOld?.eyeAbnormalitiesNote
                  ? infantOneAndSixYearOld?.eyeAbnormalitiesNote
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.earAbnormalities"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantOneAndSixYearOld?.earAbnormalities
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantOneAndSixYearOld?.earAbnormalities
                    )
                  : ""
              }
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantOneAndSixYearOld?.earAbnormalitiesNote
                  ? infantOneAndSixYearOld?.earAbnormalitiesNote
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.healthObservation"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantOneAndSixYearOld?.healthObservation
                  ? infantOneAndSixYearOld?.healthObservation
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.remarks")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantOneAndSixYearOld?.remarks
                  ? infantOneAndSixYearOld?.remarks
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.facilityOrPersonName"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantOneAndSixYearOld?.facilityOrPersonName
                  ? infantOneAndSixYearOld?.facilityOrPersonName
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.dayCaregiver"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantOneAndSixYearOld?.dayCaregiver
                  ? infantOneAndSixYearOld?.dayCaregiver
                  : ""
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {/* 予防接種 */}
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextBlockCustom
              classText="font-bold"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.vaccination"
              )}
              headerColor={COLORS.primary}
            />
          </Grid>

          <Grid item xs={12}>
            {!!infantOneAndSixYearOld?.whatReceiving &&
              infantOneAndSixYearOld?.whatReceiving
                ?.split(",")
                .map(
                  (item, index) =>
                    !!item && (
                      <TextBlockCustom
                        key={index}
                        classText="font-medium mb-2"
                        textHeader={translate(
                          `infantMedicalExamFormScreen.checkbox.${item}`
                        )}
                      />
                    )
                )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          {/* 歯の状態 */}
          <Grid item xs={12}>
            <TextBlockCustom
              classText="font-bold"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.teethCondition"
              )}
              headerColor={COLORS.primary}
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.affectedDental"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneAndSixYearOld?.affectedDental
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantOneAndSixYearOld?.affectedDental
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.cariesRequiringTreatment"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneAndSixYearOld?.cariesRequiringTreatment
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantOneAndSixYearOld?.cariesRequiringTreatment
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.milkTeeth"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneAndSixYearOld?.milkTeeth1
                  ? infantOneAndSixYearOld?.milkTeeth1.toString() +
                    translate("title.book")
                  : undefined
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.permanentTeeth"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneAndSixYearOld?.permanentTeeth1
                  ? infantOneAndSixYearOld?.permanentTeeth1.toString() +
                    translate("title.book")
                  : undefined
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.total")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneAndSixYearOld?.total
                  ? infantOneAndSixYearOld?.total.toString() +
                    translate("title.book")
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.treatedCaries"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneAndSixYearOld?.treatedCaries
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantOneAndSixYearOld?.treatedCaries
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.milkTeeth"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneAndSixYearOld?.milkTeeth2
                  ? infantOneAndSixYearOld?.milkTeeth2.toString() +
                    translate("title.book")
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.permanentTeeth"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneAndSixYearOld?.permanentTeeth2
                  ? infantOneAndSixYearOld?.permanentTeeth2.toString() +
                    translate("title.book")
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.dirtyTeeth"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneAndSixYearOld?.dirtyTeeth
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantOneAndSixYearOld?.dirtyTeeth
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.gingivaMucousMembrane"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneAndSixYearOld?.gingivaMucousMembrane
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantOneAndSixYearOld?.gingivaMucousMembrane
                    )
                  : ""
              }
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneAndSixYearOld?.gingivaMucousMembraneNote &&
                infantOneAndSixYearOld?.gingivaMucousMembrane ===
                  InfantOneAndSixYearOldGingivaMucousMembraneEnum.CanBe
                  ? infantOneAndSixYearOld?.gingivaMucousMembraneNote
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.engagement"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneAndSixYearOld?.engagement
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantOneAndSixYearOld?.engagement
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.examinationDate"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneAndSixYearOld?.examinationDate
                  ? toJapanDate(infantOneAndSixYearOld?.examinationDate)
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.teethCondition"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.maxilla")}
              headerColor={COLORS.approxGrey}
            />

            <TeethSelectCustom
              fieldType={BloodSelectionType.MultipleFields}
              fieldDescriptions={["E", "D", "C", "B", "A"]}
              initialValues={
                !!infantOneAndSixYearOld?.rightMolarsMaxilla1 &&
                JSON.parse(infantOneAndSixYearOld.rightMolarsMaxilla1)
              }
            />

            <TeethSelectCustom
              fieldType={BloodSelectionType.MultipleFields}
              fieldDescriptions={["A", "B", "C", "D", "E"]}
              initialValues={
                !!infantOneAndSixYearOld?.leftFrontToothMaxilla1 &&
                JSON.parse(infantOneAndSixYearOld?.leftFrontToothMaxilla1)
              }
            />

            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.lowerJaw"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TeethSelectCustom
              fieldType={BloodSelectionType.MultipleFields}
              fieldDescriptions={["E", "D", "C", "B", "A"]}
              initialValues={
                !!infantOneAndSixYearOld?.rightMolarsLowerJaw1 &&
                JSON.parse(infantOneAndSixYearOld?.rightMolarsLowerJaw1)
              }
            />

            <TeethSelectCustom
              fieldType={BloodSelectionType.MultipleFields}
              fieldDescriptions={["A", "B", "C", "D", "E"]}
              initialValues={
                !!infantOneAndSixYearOld?.leftFrontToothLowerJaw1 &&
                JSON.parse(infantOneAndSixYearOld?.leftFrontToothLowerJaw1)
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          {/* 保護者の記録（1歳6かの頃）*/}
          <Grid item xs={12}>
            <TextBlockCustom
              classText="font-bold"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.parentRecordOneAndSixYear"
              )}
              headerColor={COLORS.primary}
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.recordingDate"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneAndSixYearOld?.recordingDate
                  ? toJapanDate(infantOneAndSixYearOld?.recordingDate)
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.walkAlone"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                (infantOneAndSixYearOld?.walkAloneMonth !== undefined
                  ? `${infantOneAndSixYearOld?.walkAloneMonth}${translate(
                      "infoHealthMon.age"
                    )}`
                  : "") +
                (infantOneAndSixYearOld?.walkAloneDay !== undefined
                  ? `${
                      infantOneAndSixYearOld?.walkAloneDay +
                      translate("title.aroundMoon")
                    }`
                  : "")
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.meaningfulWords"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneAndSixYearOld?.meaningfulWords
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantOneAndSixYearOld?.meaningfulWords
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.drinkWaterOwnCup"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneAndSixYearOld?.drinkWaterOwnCup
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantOneAndSixYearOld?.drinkWaterOwnCup
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.babyBottle"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneAndSixYearOld?.babyBottle
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantOneAndSixYearOld?.babyBottle
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.fixedTimeForMealsAndSnacks"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneAndSixYearOld?.fixedTimeForMealsAndSnacks
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantOneAndSixYearOld?.fixedTimeForMealsAndSnacks
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.brushYourTeeth1"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneAndSixYearOld?.brushYourTeeth
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantOneAndSixYearOld?.brushYourTeeth
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.worriedYourEyes"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneAndSixYearOld?.worriedYourEyes
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantOneAndSixYearOld?.worriedYourEyes
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.turnAround1"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneAndSixYearOld?.turnAround
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantOneAndSixYearOld?.turnAround
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.kindOfPlay"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneAndSixYearOld?.kindOfPlay
                  ? infantOneAndSixYearOld?.kindOfPlay
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.applyFluoride"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneAndSixYearOld?.applyFluoride
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantOneAndSixYearOld?.applyFluoride
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.childRearing"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneAndSixYearOld?.childRearing
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantOneAndSixYearOld?.childRearing
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.raisingChildren"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneAndSixYearOld?.raisingChildren
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantOneAndSixYearOld?.raisingChildren
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.yourGrowth"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneAndSixYearOld?.yourGrowth
                  ? infantOneAndSixYearOld?.yourGrowth
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.maxilla")}
              headerColor={COLORS.approxGrey}
            />

            <TeethSelectCustom
              fieldType={BloodSelectionType.Checkbox}
              fieldDescriptions={["E", "D", "C", "B", "A"]}
              initialValues={
                !!infantOneAndSixYearOld?.rightMolarsMaxilla2 &&
                JSON.parse(infantOneAndSixYearOld.rightMolarsMaxilla2)
              }
            />

            <TeethSelectCustom
              fieldType={BloodSelectionType.Checkbox}
              fieldDescriptions={["A", "B", "C", "D", "E"]}
              initialValues={
                !!infantOneAndSixYearOld?.leftFrontToothMaxilla2 &&
                JSON.parse(infantOneAndSixYearOld?.leftFrontToothMaxilla2)
              }
            />

            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.lowerJaw"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TeethSelectCustom
              fieldType={BloodSelectionType.Checkbox}
              fieldDescriptions={["E", "D", "C", "B", "A"]}
              initialValues={
                !!infantOneAndSixYearOld?.rightMolarsLowerJaw2 &&
                JSON.parse(infantOneAndSixYearOld?.rightMolarsLowerJaw2)
              }
            />

            <TeethSelectCustom
              fieldType={BloodSelectionType.Checkbox}
              fieldDescriptions={["A", "B", "C", "D", "E"]}
              initialValues={
                !!infantOneAndSixYearOld?.leftFrontToothLowerJaw2 &&
                JSON.parse(infantOneAndSixYearOld?.leftFrontToothLowerJaw2)
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          {/* 診察所見 */}
          <Grid item xs={12}>
            <Grid item xs={12}>
              <TextBlockCustom
                classText="font-bold"
                textHeader={translate(
                  "infantMedicalExamFormScreen.text.examFinding"
                )}
                headerColor={COLORS.primary}
              />
            </Grid>

            <Grid item xs={12}>
              {!!infantOneAndSixYearOld?.resultOfWithFindings &&
                infantOneAndSixYearOld?.resultOfWithFindings
                  ?.split(",")
                  .map(
                    (item, index) =>
                      !!item && (
                        <TextBlockCustom
                          key={index}
                          classText="font-medium mb-2"
                          textHeader={translate(
                            `infantMedicalExamFormScreen.checkbox.${item}`
                          )}
                        />
                      )
                  )}
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader={translate(
                  "infantMedicalExamFormScreen.text.judgement"
                )}
                headerColor={COLORS.approxGrey}
              />

              <TextBlockCustom
                classText="font-medium mb-2"
                textHeader={
                  infantOneAndSixYearOld?.judgement
                    ? translate(
                        "infantMedicalExamFormScreen.dropdown." +
                          infantOneAndSixYearOld?.judgement
                      )
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader={translate("infantMedicalExamFormScreen.text.photo")}
                headerColor={COLORS.approxGrey}
              />

              {infantOneAndSixYearOld?.photo3 && (
                <AvatarCustom imgSrc={infantOneAndSixYearOld?.photo3} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
