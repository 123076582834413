/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    VaccineForBaby,
    VaccineForBabyFromJSON,
    VaccineForBabyToJSON,
    VaccinePagination,
    VaccinePaginationFromJSON,
    VaccinePaginationToJSON,
} from '../models';

export interface VaccinesForBabyGetRequest {
    babyId: number;
    dateMeeting: string;
}

export interface VaccinesGetRequest {
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

/**
 * 
 */
export class VaccineApi extends runtime.BaseAPI {

    /**
     * 
     */
    vaccinesForBabyGetRaw = async (requestParameters: VaccinesForBabyGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<VaccineForBaby>>> => {
        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling vaccinesForBabyGet.');
        }

        if (requestParameters.dateMeeting === null || requestParameters.dateMeeting === undefined) {
            throw new runtime.RequiredError('dateMeeting','Required parameter requestParameters.dateMeeting was null or undefined when calling vaccinesForBabyGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        if (requestParameters.dateMeeting !== undefined) {
            queryParameters['dateMeeting'] = requestParameters.dateMeeting;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/vaccines/forBaby`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VaccineForBabyFromJSON));
    }

    /**
     * 
     */
    vaccinesForBabyGet = async (requestParameters: VaccinesForBabyGetRequest, initOverrides?: RequestInit): Promise<Array<VaccineForBaby>> => {
        const response = await this.vaccinesForBabyGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    vaccinesGetRaw = async (requestParameters: VaccinesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VaccinePagination>> => {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/vaccines`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VaccinePaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    vaccinesGet = async (requestParameters: VaccinesGetRequest = {}, initOverrides?: RequestInit): Promise<VaccinePagination> => {
        const response = await this.vaccinesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
