import React, { useCallback, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";
import { NotificationCampaign, notificationCampaignsApi } from "@/api";
import { noData, textRowsPerPage } from "@/constants";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  LinearProgress,
  Modal,
  Backdrop,
  Fade,
  Box,
  Typography,
  Chip,
  Slide,
  Dialog,
  AppBar,
  Toolbar,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import {
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useApiPagination } from "@/hooks";
import debounce from "lodash/debounce";
import DeleteIcon from "@mui/icons-material/Delete";
import { RoleCheck } from "@/components/system";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { NotiCampaignAddEditForm } from "./NotiCampaignAddEditForm";
import { TransitionProps } from "@mui/material/transitions";
import { DialogSelectType } from "./DialogSelectType";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";

const StyledNotiCampaignTable = styled.div`
  position: relative;
`;

export const deleteModalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: "36px",
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const NotiCampaignTable = () => {
  //--- State
  const [searchKeyword, setSearchKeyword] = useState("");
  const [editingNotiCampaign, setEditingNotiCampaign] =
    useState<NotificationCampaign>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchKeywordChange = useCallback(
    debounce((searchKeyword: string) => {
      setSearchKeyword(searchKeyword);
    }, 500),
    []
  );

  //--- Get campaign list
  const {
    data: notiCampaignList,
    isFetching,
    refetch: refetchNotiCampaignList,
  } = useQuery({
    queryKey: "GET_NOTI_CAMPAIGN_LIST",
    queryFn: () => {
      return notificationCampaignsApi.adminNotificationCampaignsGet({
        page: apiPagi.page,
        itemsPerPage: apiPagi.itemsPerPage,
        orderBy: "id",
        orderType: "desc",
        keyword: searchKeyword,
      });
    },
    onSuccess: (data) => {
      apiPagi.setTotalItems(data.meta.totalItems);
    },
    initialData: { data: [] },
  });

  const apiPagi = useApiPagination({
    onPageChange: () => refetchNotiCampaignList(),
  });

  useEffect(() => {
    apiPagi.setPage(1);
    refetchNotiCampaignList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiPagi.setPage, refetchNotiCampaignList, searchKeyword]);

  //--- Add & edit dialog
  const [openAddEditDialog, setOpenAddEditDialog] = useState(false);

  const handleClickOpenAddEditDialog = () => {
    setOpenAddEditDialog(true);
  };

  const handleCloseAddEditDialog = () => {
    refetchNotiCampaignList();
    setEditingNotiCampaign(null);
    setOpenAddEditDialog(false);
  };

  //--- Type Dialog
  const [openTypeDialog, setOpenTypeDialog] = useState(false);

  const handleClickOpenTypeDialog = () => {
    setOpenTypeDialog(true);
  };

  const handleCloseTypeDialog = () => {
    refetchNotiCampaignList();
    setOpenTypeDialog(false);
  };

  //--- Delete dialog
  const [itemDeleteId, setItemDeleteId] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleOpenDeleteDialog = (itemDeleteId: number) => {
    setOpenDeleteDialog(true);
    setItemDeleteId(itemDeleteId);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setItemDeleteId(0);
  };

  const { mutate: deleteNotiCampaign, isLoading: isDeleting } = useMutation(
    () => {
      return notificationCampaignsApi.notificationCampaignsIdDelete({
        id: itemDeleteId,
      });
    },
    {
      onSuccess: () => {
        refetchNotiCampaignList();
        handleCloseDeleteDialog();
      },
    }
  );

  return (
    <StyledNotiCampaignTable>
      <div className="mb-6 flex">
        <div className="grow">
          <OutlinedInput
            className="bordered-input"
            placeholder="タイトルで検索"
            size="small"
            onChange={(e) => handleSearchKeywordChange(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility">
                  {<Search />}
                </IconButton>
              </InputAdornment>
            }
          />
        </div>
        <div>
          <RoleCheck role="admin">
            {({ allowed }) => {
              if (!allowed) {
                return null;
              }

              return (
                <Button
                  variant="outlined"
                  color="secondary"
                  size="medium"
                  startIcon={<AddIcon />}
                  onClick={() => handleClickOpenTypeDialog()}
                >
                  追加
                </Button>
              );
            }}
          </RoleCheck>
        </div>
      </div>

      {isFetching && (
        <div className="absolute w-full">
          <LinearProgress />
        </div>
      )}

      <TableContainer component={Paper} className="mb-8">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="color-primary">ID</TableCell>
              <TableCell align="center" className="color-primary">
                タイトル
              </TableCell>
              <TableCell align="center" className="color-primary">
                イベントタブ表示期間
              </TableCell>
              <TableCell align="center" className="color-primary">
                ホーム表示期間
              </TableCell>
              <TableCell align="center" className="color-primary">
                ステータス
              </TableCell>
              <RoleCheck role="admin">
                {({ allowed }) => {
                  if (!allowed) {
                    return null;
                  }

                  return (
                    <TableCell align="center" className="color-primary">
                      削除
                    </TableCell>
                  );
                }}
              </RoleCheck>
            </TableRow>
          </TableHead>
          <TableBody>
            {(notiCampaignList?.data || []).map((item) => (
              <TableRow
                key={"notiCampaignList_" + item.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{item.id < 10 ? "0" + item.id : item.id}</TableCell>
                <TableCell align="center">{item.title}</TableCell>
                <TableCell align="center">
                  {item?.listStartDate &&
                    item?.listEndDate &&
                    moment(item?.listStartDate).format("DD/MM/YYYY") +
                      " - " +
                      moment(item?.listEndDate).format("DD/MM/YYYY")}
                </TableCell>
                <TableCell align="center">
                  {item?.homeStartDate &&
                    item?.homeEndDate &&
                    moment(item?.homeStartDate).format("DD/MM/YYYY") +
                      " - " +
                      moment(item?.homeEndDate).format("DD/MM/YYYY")}
                </TableCell>
                <TableCell align="center">
                  {item.isPublish ? (
                    <Chip label="公開済み" color="success" sx={{ width: 80 }} />
                  ) : (
                    <Chip label="下書き" sx={{ width: 80 }} />
                  )}
                </TableCell>
                <RoleCheck role="admin">
                  {({ allowed }) => {
                    if (!allowed) {
                      return null;
                    }

                    return (
                      <TableCell align="center">
                        <IconButton
                          color="primary"
                          onClick={() => {
                            setEditingNotiCampaign(item);
                            handleClickOpenAddEditDialog();
                          }}
                        >
                          <DriveFileRenameOutlineIcon />
                        </IconButton>

                        <IconButton
                          color="primary"
                          onClick={() => handleOpenDeleteDialog(item.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    );
                  }}
                </RoleCheck>
              </TableRow>
            ))}

            {notiCampaignList?.data?.length === 0 && (
              <TableRow>
                <TableCell colSpan={6}>
                  <div className="text-center font-bold">{noData}</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        className="custom-pagination"
        component="div"
        count={apiPagi.totalItems}
        rowsPerPage={apiPagi.itemsPerPage}
        page={apiPagi.page - 1}
        labelRowsPerPage={textRowsPerPage}
        onPageChange={(e, page) => {
          apiPagi.setPage(page + 1);
        }}
        onRowsPerPageChange={(e) => {
          apiPagi.setItemsPerPage(+e.target.value);
        }}
        labelDisplayedRows={(p) => `${p.from}-${p.to} / ${p.count}件`}
      />

      {openTypeDialog && (
        <DialogSelectType
          openDialog={openTypeDialog}
          onCloseTypeDialog={() => handleCloseTypeDialog()}
          onOpenAddEditDialog={() => {
            handleCloseTypeDialog();
            handleClickOpenAddEditDialog();
          }}
        />
      )}

      {openAddEditDialog && (
        <Dialog
          fullScreen
          open={openAddEditDialog}
          onClose={handleCloseAddEditDialog}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar sx={{ justifyContent: "flex-end" }}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseAddEditDialog}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>

          <div className="pt-4 pb-4">
            <NotiCampaignAddEditForm
              initialValues={editingNotiCampaign}
              onCloseAddEditDialog={() => handleCloseAddEditDialog()}
            />
          </div>
        </Dialog>
      )}

      {openDeleteDialog && (
        <Modal
          aria-labelledby="delete-modal-title"
          aria-describedby="delete-modal-description"
          open={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openDeleteDialog}>
            <Box sx={deleteModalStyle}>
              <div className="mb-8">
                <Typography
                  variant="h5"
                  fontWeight={700}
                  className="text-center"
                >
                  通知の削除
                </Typography>
              </div>
              <div className="mb-16">
                <Typography variant="body1" className="text-center">
                  通知を本当に削除しますか？
                </Typography>
              </div>
              <div className="flex">
                <div className="w-6/12 mr-2 ml-4 c">
                  <Button
                    variant="outlined"
                    size="large"
                    className="w-full custom-button"
                    type="submit"
                    onClick={handleCloseDeleteDialog}
                  >
                    戻る
                  </Button>
                </div>
                <div className="w-6/12 mr-4 ml-2">
                  <Button
                    variant="contained"
                    size="large"
                    className="w-full custom-button"
                    type="submit"
                    disabled={isDeleting}
                    onClick={() => {
                      deleteNotiCampaign();
                    }}
                  >
                    削除する
                  </Button>
                </div>
              </div>
            </Box>
          </Fade>
        </Modal>
      )}
    </StyledNotiCampaignTable>
  );
};
