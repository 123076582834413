import { Route } from 'react-router-dom';

import { USER_PATH } from '@/constants/paths';

import { UserListPage } from './user-list';
import { USER_LIST_PATH } from './user-list/UserListPage.shared';
import { USER_DETAIL_PATH } from './user-details/UserDetail.shared';
import { UserDetailPage } from './user-details';

export const renderUserRoutes = () => (
    <Route path={USER_PATH}>
        <Route path={USER_LIST_PATH} element={<UserListPage />} />
        <Route path={USER_DETAIL_PATH} element={<UserDetailPage />} />
    </Route>
)