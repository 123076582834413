import { Admin, adminsApi } from "@/api";
import { allRolesState, textRowsPerPage } from "@/constants";
import { useApiPagination } from "@/hooks";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  LinearProgress,
  Button,
  Backdrop,
  Box,
  Fade,
  Modal,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { AdminForm } from "@/form/admin";
import { adminModalStyle } from "./AdminCreateButton";
import { RoleCheck } from '@/components/system';
import { translate } from '@/helpers';
import { getUrlQueryValue } from '@/utils';

const StyledAdminTable = styled.div`
  position: relative;
  button {
    min-width: unset;
  }
`;

export const reloadAdminTableEvent = new Event("reloadAdminTable");

interface IAdminTableProps {
}

export const AdminTable = (
  props: React.PropsWithChildren<IAdminTableProps>
) => {
  const queryClient = useQueryClient();
  const [allRoles] = useRecoilState(allRolesState);

  const [editingAdmin, setEditingAdmin] = React.useState<Admin>(null);
  const [openEdit, setOpenEdit] = React.useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => {
    setOpenEdit(false);
    setEditingAdmin(null);
  };

  const [deletingAdmin, setDeletingAdmin] = React.useState<Admin>(null);
  const [openDelete, setOpenDelete] = React.useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => {
    setOpenDelete(false);
    setDeletingAdmin(null);
  };

  const { mutate: deleteAdmin, isLoading: isDeleting } = useMutation(
    adminsApi.adminAdminsIdDelete,
    {
      onSuccess: () => {
        queryClient.refetchQueries("GET_ADMINS");
        handleCloseDelete();
      },
    }
  );

  const {
    data: admins,
    isFetching,
    refetch,
  } = useQuery(
    "GET_ADMINS",
    () =>
      adminsApi.adminAdminsGet({
        page: apiPagi.page,
        itemsPerPage: apiPagi.itemsPerPage,
        orderBy: "id",
        orderType: "desc",
        keyword: getUrlQueryValue('search') ?? undefined,
      }),
    {
      onSuccess: (data) => {
        apiPagi.setTotalItems(data.meta.totalItems);
      },
      initialData: { data: [] },
    }
  );

  const apiPagi = useApiPagination({
    onPageChange: () => refetch(),
  });

  useEffect(() => {
    if (editingAdmin) {
      handleOpenEdit();
    } else if (openEdit) {
      handleCloseEdit();
    }
  }, [editingAdmin, openEdit]);

  useEffect(() => {
    if (deletingAdmin) {
      handleOpenDelete();
    } else if (openDelete) {
      handleCloseDelete();
    }
  }, [deletingAdmin, openDelete]);

  useEffect(
    () => {
      const handler = () => refetch();
      window.addEventListener("reloadAdminTable", handler);
      return () => window.removeEventListener("reloadAdminTable", handler);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiPagi.setPage, refetch]
  );

  return (
    <StyledAdminTable>
      {isFetching && (
        <div className="absolute w-full">
          <LinearProgress className="" />
        </div>
      )}

      <TableContainer component={Paper} className="mb-8">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="color-primary">No</TableCell>
              <TableCell align="center" className="color-primary">
                社員名
              </TableCell>
              <TableCell align="center" className="color-primary">
                メールアドレス
              </TableCell>
              <TableCell align="center" className="color-primary">
                パスワード
              </TableCell>
              <TableCell align="center" className="color-primary">
                役割
              </TableCell>
              <TableCell align="center" className="color-primary">
                登録日
              </TableCell>
              <RoleCheck role="admin">
                {({ allowed }) => {
                  if (!allowed) {
                    return null;
                  };

                  return (
                    <TableCell align="center" className="color-primary">
                      編集
                    </TableCell>
                  );
                }}
              </RoleCheck>
            </TableRow>
          </TableHead>
          <TableBody>
            {admins?.data?.map((admin, index) => (
              <TableRow key={admin.id}>
                <TableCell>{admin.id}</TableCell>
                <TableCell align="center">{admin.staffName}</TableCell>
                <TableCell align="center">{admin.email}</TableCell>
                <TableCell align="center">***</TableCell>
                <TableCell align="center">
                  {translate(allRoles?.find((o) => o.id === admin.roleId).roleName)}
                </TableCell>
                <TableCell align="center">
                  {new Date(admin.createdAt).toLocaleString('ja-JP').split(' ')[0]}
                </TableCell>
                <RoleCheck role="admin">
                  {({ allowed }) => {
                    if (!allowed) {
                      return null;
                    }

                    const isRootAdmin = admin.id === 1;
                    return (
                      <TableCell align="center">
                        <Button
                          variant="text"
                          size="small"
                          onClick={() => setEditingAdmin(admin)}
                        >
                          <ModeEditIcon />
                        </Button>
                        <Button
                          variant="text"
                          size="small"
                          onClick={() => setDeletingAdmin(admin)}
                          disabled={isRootAdmin}
                        >
                          <DeleteIcon />
                        </Button>
                      </TableCell>
                    );
                  }}
                </RoleCheck>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        className="custom-pagination"
        component={Paper}
        labelRowsPerPage={textRowsPerPage}
        count={apiPagi.totalItems}
        rowsPerPage={apiPagi.itemsPerPage}
        onRowsPerPageChange={(e) => {
          apiPagi.setItemsPerPage(+e.target.value);
        }}
        page={apiPagi.page - 1}
        onPageChange={(e, page) => {
          apiPagi.setPage(page + 1);
        }}
        labelDisplayedRows={(p) => `${p.from}-${p.to} / ${p.count}件`}
      />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openEdit}
        onClose={handleCloseEdit}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openEdit}>
          <Box sx={adminModalStyle}>
            <AdminForm
              initialValues={editingAdmin}
              onSuccess={() => {
                queryClient.refetchQueries("GET_ADMINS");
                handleCloseEdit();
              }}
              onClose={handleCloseEdit}
            />
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openDelete}
        onClose={handleCloseDelete}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openDelete}>
          <Box sx={adminModalStyle}>
            <div className="mb-8">
              <Typography variant="h5" fontWeight={700} className="text-center">
                管理者の削除
              </Typography>
            </div>
            <div className="mb-16">
              <Typography variant="body1" className="text-center">
                アカウント本当に削除しますか？
              </Typography>
            </div>
            <div className="flex">
              <div className="w-6/12 mr-2 ml-4 c">
                <Button
                  variant="outlined"
                  size="large"
                  className="w-full custom-button"
                  type="submit"
                  onClick={handleCloseDelete}
                >
                  戻る
                </Button>
              </div>
              <div className="w-6/12 mr-4 ml-2">
                <Button
                  variant="contained"
                  size="large"
                  className="w-full custom-button"
                  type="submit"
                  disabled={isDeleting}
                  onClick={() => {
                    deleteAdmin({ id: deletingAdmin.id });
                  }}
                >
                  削除する
                </Button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </StyledAdminTable >
  );
};
