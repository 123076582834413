/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';
import type { VaccinationSchedulesAllOf } from './VaccinationSchedulesAllOf';
import {
    VaccinationSchedulesAllOfFromJSON,
    VaccinationSchedulesAllOfFromJSONTyped,
    VaccinationSchedulesAllOfToJSON,
} from './VaccinationSchedulesAllOf';

/**
 * 
 * @export
 * @interface VaccinationSchedules
 */
export interface VaccinationSchedules {
    /**
     * 
     * @type {number}
     * @memberof VaccinationSchedules
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof VaccinationSchedules
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof VaccinationSchedules
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof VaccinationSchedules
     */
    babyId?: number;
    /**
     * 
     * @type {number}
     * @memberof VaccinationSchedules
     */
    userId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof VaccinationSchedules
     */
    show?: boolean;
    /**
     * 
     * @type {number}
     * @memberof VaccinationSchedules
     */
    vaccineId?: number;
    /**
     * 
     * @type {string}
     * @memberof VaccinationSchedules
     */
    dateMeeting?: string;
    /**
     * 
     * @type {string}
     * @memberof VaccinationSchedules
     */
    vaccineName?: string;
    /**
     * 
     * @type {string}
     * @memberof VaccinationSchedules
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof VaccinationSchedules
     */
    numberOfInjections?: number;
    /**
     * 
     * @type {number}
     * @memberof VaccinationSchedules
     */
    vaccinatedRecordId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof VaccinationSchedules
     */
    dateInjectSuggest?: string | null;
}

/**
 * Check if a given object implements the VaccinationSchedules interface.
 */
export function instanceOfVaccinationSchedules(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VaccinationSchedulesFromJSON(json: any): VaccinationSchedules {
    return VaccinationSchedulesFromJSONTyped(json, false);
}

export function VaccinationSchedulesFromJSONTyped(json: any, ignoreDiscriminator: boolean): VaccinationSchedules {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'babyId': !exists(json, 'babyId') ? undefined : json['babyId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'show': !exists(json, 'show') ? undefined : json['show'],
        'vaccineId': !exists(json, 'vaccineId') ? undefined : json['vaccineId'],
        'dateMeeting': !exists(json, 'dateMeeting') ? undefined : json['dateMeeting'],
        'vaccineName': !exists(json, 'vaccineName') ? undefined : json['vaccineName'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'numberOfInjections': !exists(json, 'numberOfInjections') ? undefined : json['numberOfInjections'],
        'vaccinatedRecordId': !exists(json, 'vaccinatedRecordId') ? undefined : json['vaccinatedRecordId'],
        'dateInjectSuggest': !exists(json, 'dateInjectSuggest') ? undefined : json['dateInjectSuggest'],
    };
}

export function VaccinationSchedulesToJSON(value?: VaccinationSchedules | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'babyId': value.babyId,
        'userId': value.userId,
        'show': value.show,
        'vaccineId': value.vaccineId,
        'dateMeeting': value.dateMeeting,
        'vaccineName': value.vaccineName,
        'status': value.status,
        'numberOfInjections': value.numberOfInjections,
        'vaccinatedRecordId': value.vaccinatedRecordId,
        'dateInjectSuggest': value.dateInjectSuggest,
    };
}

