/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InfantTwoYearOld,
    InfantTwoYearOldFromJSON,
    InfantTwoYearOldToJSON,
    InfantTwoYearOldPagination,
    InfantTwoYearOldPaginationFromJSON,
    InfantTwoYearOldPaginationToJSON,
} from '../models';

export interface AdminInfantTwoYearOldsIdGetRequest {
    id: number;
    userId?: number;
}

export interface InfantTwoYearOldsGetRequest {
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface InfantTwoYearOldsIdDeleteRequest {
    id: number;
}

export interface InfantTwoYearOldsIdGetRequest {
    id: number;
}

export interface InfantTwoYearOldsIdPutRequest {
    id: number;
    infantTwoYearOld?: InfantTwoYearOld;
}

export interface InfantTwoYearOldsPostRequest {
    infantTwoYearOld?: InfantTwoYearOld;
}

export interface InfantTwoYearOldsShareAllGetRequest {
    sourceUserId: number;
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface InfantTwoYearOldsShareDetailGetRequest {
    recordId: number;
    sourceUserId: number;
}

/**
 * 
 */
export class InfantTwoYearOldsApi extends runtime.BaseAPI {

    /**
     * 
     */
    adminInfantTwoYearOldsIdGetRaw = async (requestParameters: AdminInfantTwoYearOldsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantTwoYearOld>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling adminInfantTwoYearOldsIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/infantTwoYearOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantTwoYearOldFromJSON(jsonValue));
    }

    /**
     * 
     */
    adminInfantTwoYearOldsIdGet = async (requestParameters: AdminInfantTwoYearOldsIdGetRequest, initOverrides?: RequestInit): Promise<InfantTwoYearOld> => {
        const response = await this.adminInfantTwoYearOldsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantTwoYearOldsGetRaw = async (requestParameters: InfantTwoYearOldsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantTwoYearOldPagination>> => {
        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling infantTwoYearOldsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantTwoYearOlds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantTwoYearOldPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantTwoYearOldsGet = async (requestParameters: InfantTwoYearOldsGetRequest, initOverrides?: RequestInit): Promise<InfantTwoYearOldPagination> => {
        const response = await this.infantTwoYearOldsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    infantTwoYearOldsIdDeleteRaw = async (requestParameters: InfantTwoYearOldsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling infantTwoYearOldsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantTwoYearOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    infantTwoYearOldsIdDelete = async (requestParameters: InfantTwoYearOldsIdDeleteRequest, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.infantTwoYearOldsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantTwoYearOldsIdGetRaw = async (requestParameters: InfantTwoYearOldsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantTwoYearOld>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling infantTwoYearOldsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantTwoYearOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantTwoYearOldFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantTwoYearOldsIdGet = async (requestParameters: InfantTwoYearOldsIdGetRequest, initOverrides?: RequestInit): Promise<InfantTwoYearOld> => {
        const response = await this.infantTwoYearOldsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    infantTwoYearOldsIdPutRaw = async (requestParameters: InfantTwoYearOldsIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantTwoYearOld>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling infantTwoYearOldsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/infantTwoYearOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InfantTwoYearOldToJSON(requestParameters.infantTwoYearOld),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantTwoYearOldFromJSON(jsonValue));
    }

    /**
     */
    infantTwoYearOldsIdPut = async (requestParameters: InfantTwoYearOldsIdPutRequest, initOverrides?: RequestInit): Promise<InfantTwoYearOld> => {
        const response = await this.infantTwoYearOldsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    infantTwoYearOldsPostRaw = async (requestParameters: InfantTwoYearOldsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantTwoYearOld>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/infantTwoYearOlds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InfantTwoYearOldToJSON(requestParameters.infantTwoYearOld),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantTwoYearOldFromJSON(jsonValue));
    }

    /**
     */
    infantTwoYearOldsPost = async (requestParameters: InfantTwoYearOldsPostRequest = {}, initOverrides?: RequestInit): Promise<InfantTwoYearOld> => {
        const response = await this.infantTwoYearOldsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantTwoYearOldsShareAllGetRaw = async (requestParameters: InfantTwoYearOldsShareAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantTwoYearOldPagination>> => {
        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling infantTwoYearOldsShareAllGet.');
        }

        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling infantTwoYearOldsShareAllGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantTwoYearOlds/share/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantTwoYearOldPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantTwoYearOldsShareAllGet = async (requestParameters: InfantTwoYearOldsShareAllGetRequest, initOverrides?: RequestInit): Promise<InfantTwoYearOldPagination> => {
        const response = await this.infantTwoYearOldsShareAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantTwoYearOldsShareDetailGetRaw = async (requestParameters: InfantTwoYearOldsShareDetailGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantTwoYearOld>> => {
        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling infantTwoYearOldsShareDetailGet.');
        }

        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling infantTwoYearOldsShareDetailGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.recordId !== undefined) {
            queryParameters['recordId'] = requestParameters.recordId;
        }

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantTwoYearOlds/share/detail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantTwoYearOldFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantTwoYearOldsShareDetailGet = async (requestParameters: InfantTwoYearOldsShareDetailGetRequest, initOverrides?: RequestInit): Promise<InfantTwoYearOld> => {
        const response = await this.infantTwoYearOldsShareDetailGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
