import React, { useEffect } from "react";
import { BootstrapDialogCustom } from "@/components/dialog";
import { TextBlockCustom } from "@/components/display";
import { Button, Grid, TextField } from "@mui/material";
import { Formik, Form, FieldArray, getIn } from "formik";
import * as yup from "yup";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { diagnosesApi, Diagnosis, PrescriptionDrug } from "@/api";
import { useMutation } from "react-query";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";

interface IDialogAddEditDrugProps {
  openDialog?: boolean;
  onCloseDialog?: () => void;
  drugItemId?: number;
  diagnosisListArray?: Array<Diagnosis>;
  prescriptionDrugListArray?: Array<PrescriptionDrug>;
  onCheckResult?: (value: boolean) => void;
}

const validationSchema = yup.object().shape({
  diagnosisName: yup.string().required("この項目は必須です"),
});

export const DialogAddEditDrug = (
  props: React.PropsWithChildren<IDialogAddEditDrugProps>
) => {
  const {
    openDialog,
    onCloseDialog,
    drugItemId,
    diagnosisListArray,
    prescriptionDrugListArray,
    onCheckResult,
  } = props;

  //--- Submit form
  const { mutate: createAndUpdateDrug, isLoading } = useMutation({
    mutationKey: "CREATE_AND_UPDATE_DRUG",
    mutationFn: (values: any) => {
      if (drugItemId > 0) {
        return diagnosesApi.adminDiagnosesIdPut({
          id: drugItemId,
          diagnosis: { id: drugItemId, ...values },
        });
      }

      return diagnosesApi.adminDiagnosesPost({
        diagnosis: { ...values },
      });
    },
    onSuccess: () => {
      onCheckResult(true);
    },
    onError: () => {
      onCheckResult(false);
    },
  });

  //--- Set diagnosis list data
  let diagnosisListData: any = {
    diagnosisName: "",
    diagnosisLink: "",
    prescriptionDrugs: [
      {
        prescriptionDrugName: "",
      },
    ],
  };

  useEffect(() => {
    if (
      drugItemId > 0 &&
      diagnosisListArray?.length > 0 &&
      prescriptionDrugListArray?.length > 0
    ) {
      (diagnosisListArray || []).forEach((elementDiagnosis: any) => {
        if (elementDiagnosis?.id === drugItemId) {
          diagnosisListData[`diagnosisName`] = elementDiagnosis.diagnosisName;
          diagnosisListData[`diagnosisLink`] = elementDiagnosis.diagnosisLink;

          diagnosisListData[`prescriptionDrugs`] = [];

          (prescriptionDrugListArray || []).forEach(
            (elementPrescriptionDrug: any) => {
              if (elementPrescriptionDrug?.diagnosisId === drugItemId) {
                diagnosisListData[`prescriptionDrugs`].push({
                  prescriptionDrugName:
                    elementPrescriptionDrug.prescriptionDrugName,
                });
              }
            }
          );
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drugItemId]);

  return (
    <BootstrapDialogCustom
      openDialog={openDialog}
      onCloseDialog={onCloseDialog}
    >
      <div className="pl-16 pr-16 form-control mt-4">
        <TextBlockCustom
          classText="text-center font-bold mb-10"
          textHeader={drugItemId > 0 ? " 詳細の編集" : "診断名の追加"}
          textSize={20}
        />

        <Formik
          initialValues={diagnosisListData}
          validationSchema={validationSchema}
          onSubmit={(values) => createAndUpdateDrug(values)}
        >
          {({ values, touched, errors, handleChange, handleBlur, isValid }) => (
            <Form noValidate autoComplete="off">
              <TextBlockCustom
                classText="font-medium mb-2"
                textHeader="診断名"
                isRequired={true}
              />

              <TextField
                name="diagnosisName"
                variant="outlined"
                required
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(
                  getIn(touched, "diagnosisName") &&
                    getIn(errors, "diagnosisName")
                )}
                fullWidth
                size="small"
                value={values?.diagnosisName}
              />

              <TextBlockCustom
                classText="font-medium mt-3 mb-2"
                textHeader="診断名の情報サイト "
              />

              <TextField
                name="diagnosisLink"
                variant="outlined"
                required
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(
                  getIn(touched, "diagnosisLink") &&
                    getIn(errors, "diagnosisLink")
                )}
                fullWidth
                size="small"
                value={values?.diagnosisLink}
              />

              <FieldArray name="prescriptionDrugs">
                {({ push, remove }) => (
                  <>
                    <div className="mt-4 mb-4 flex items-center justify-between">
                      <TextBlockCustom
                        classText="font-medium"
                        textHeader="処方薬名"
                      />

                      <Button
                        type="button"
                        variant="outlined"
                        size="small"
                        startIcon={<AddIcon />}
                        onClick={() => push({ prescriptionDrugName: "" })}
                        disabled={isLoading}
                      >
                        処方薬 追加
                      </Button>
                    </div>

                    {values?.prescriptionDrugs?.map((item, index) => {
                      const prescriptionDrugName = `prescriptionDrugs[${index}].prescriptionDrugName`;

                      return (
                        <div
                          key={"prescriptionDrugList_" + index}
                          className="mb-3"
                        >
                          <Grid container>
                            <Grid item xs={index + 1 === 1 ? 12 : 10}>
                              <TextField
                                variant="outlined"
                                name={prescriptionDrugName}
                                value={item.prescriptionDrugName}
                                fullWidth
                                size="small"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </Grid>

                            {index + 1 !== 1 && (
                              <Grid item xs={2}>
                                <div className="text-right">
                                  <Button
                                    type="button"
                                    color="secondary"
                                    variant="outlined"
                                    onClick={() => remove(index)}
                                    sx={{
                                      border: 0,
                                      backgroundColor: "#FBF8F9",
                                      minWidth: 55,
                                      height: 38,
                                    }}
                                    disabled={isLoading}
                                  >
                                    <RemoveIcon />
                                  </Button>
                                </div>
                              </Grid>
                            )}
                          </Grid>
                        </div>
                      );
                    })}

                    {values?.prescriptionDrugs?.length > 9 && (
                      <div className="mt-4 mb-4 flex items-center justify-end">
                        <Button
                          type="button"
                          variant="outlined"
                          size="small"
                          startIcon={<AddIcon />}
                          onClick={() => push({ prescriptionDrugName: "" })}
                          disabled={isLoading}
                        >
                          処方薬 追加
                        </Button>
                      </div>
                    )}
                  </>
                )}
              </FieldArray>
              <div className="grid grid-cols-2 gap-4 mt-6 mb-4">
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={onCloseDialog}
                  disabled={isLoading}
                >
                  キャンセル
                </Button>

                {isLoading ? (
                  <LoadingButton loading variant="outlined">
                    Submit
                  </LoadingButton>
                ) : (
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={isLoading}
                    startIcon={<SaveIcon />}
                  >
                    保存
                  </Button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </BootstrapDialogCustom>
  );
};
