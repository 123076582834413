import React from "react";
import {
  AvatarCustom,
  BloodSelectionType,
  TeethSelectCustom,
  TextBlockCustom,
} from "@/components/display";
import { Grid } from "@mui/material";
import { COLORS } from "@/constants";
import {
  Baby,
  InfantFiveYearOldGingivaMucousMembraneEnum,
  infantFiveYearOldsApi,
  InfantMedicalExam,
} from "@/api";
import { useQuery } from "react-query";
import { getNextYearFromDate, toJapanDate } from "@/utils";
import { translate } from "@/helpers";

interface IInfantFiveYearOldDetailProps {
  infantMedicalExamItem?: InfantMedicalExam;
  baby?: Baby;
}

export const InfantFiveYearOldDetail = (
  props: React.PropsWithChildren<IInfantFiveYearOldDetailProps>
) => {
  const { infantMedicalExamItem, baby } = props;

  //--- Get medical consultant
  const { data: infantFiveYearOld } = useQuery({
    queryKey: "GET_INFANT_FIVE_YEAR_OLD_BY_ID",
    queryFn: () => {
      return infantFiveYearOldsApi.adminInfantFiveYearOldsIdGet({
        id: infantMedicalExamItem?.infantId,
        userId: infantMedicalExamItem?.userId,
      });
    },
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextBlockCustom
          classText="font-bold text-center mb-4"
          textHeader={`${translate("infantMedicalExam.text.age13")} ${translate(
            "infantMedicalExam.text.detail"
          )}`}
          textSize={18}
          headerColor={COLORS.primary}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.implementationDate"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantFiveYearOld?.implementationDate
                  ? toJapanDate(infantFiveYearOld?.implementationDate)
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.ageYear")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                (infantFiveYearOld?.year
                  ? `${infantFiveYearOld?.year}${translate(
                      "infoHealthMon.age"
                    )}`
                  : "") +
                (infantFiveYearOld?.month
                  ? `${infantFiveYearOld?.month}${translate("month.txt")}`
                  : "")
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.photographOfMedical"
              )}
              headerColor={COLORS.approxGrey}
            />

            <div className="flex items-center">
              {infantFiveYearOld?.photo1 && (
                <div className="mr-2">
                  <AvatarCustom imgSrc={infantFiveYearOld?.photo1} />
                </div>
              )}

              {infantFiveYearOld?.photo2 && (
                <AvatarCustom imgSrc={infantFiveYearOld?.photo2} />
              )}
            </div>
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.bodyWeight"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFiveYearOld?.bodyWeight
                  ? infantFiveYearOld?.bodyWeight.toString() + "kg"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.height")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFiveYearOld?.height
                  ? infantFiveYearOld?.height.toString() + "cm"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.kaupIndex"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFiveYearOld?.kaupIndex
                  ? infantFiveYearOld?.kaupIndex.toString()
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.headCircumference"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFiveYearOld?.headCircumference
                  ? infantFiveYearOld?.headCircumference.toString() + "cm"
                  : ""
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          {/* 栄養状態/健康・要観察/特記事項など */}
          <Grid item xs={12}>
            <TextBlockCustom
              classText="font-bold"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.nutritionalHealth"
              )}
              headerColor={COLORS.primary}
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.nutritionalStatus"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantFiveYearOld?.nutritionalStatus
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFiveYearOld?.nutritionalStatus
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.eyeAbnormalities"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantFiveYearOld?.eyeAbnormalities
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFiveYearOld?.eyeAbnormalities
                    )
                  : ""
              }
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantFiveYearOld?.eyeAbnormalitiesNote
                  ? infantFiveYearOld?.eyeAbnormalitiesNote
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.sight")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                (infantFiveYearOld?.sightLeft
                  ? `${translate("title.left")} ${infantFiveYearOld?.sightLeft}`
                  : "") +
                (infantFiveYearOld?.sightRight
                  ? ` ${translate("title.right")} ${
                      infantFiveYearOld?.sightRight
                    }`
                  : "")
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.earAbnormalities"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantFiveYearOld?.earAbnormalities
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFiveYearOld?.earAbnormalities
                    )
                  : ""
              }
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantFiveYearOld?.earAbnormalitiesNote
                  ? infantFiveYearOld?.earAbnormalitiesNote
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.healthObservation"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantFiveYearOld?.healthObservation
                  ? infantFiveYearOld?.healthObservation
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.remarks")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantFiveYearOld?.remarks ? infantFiveYearOld?.remarks : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.facilityOrPersonName"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantFiveYearOld?.facilityOrPersonName
                  ? infantFiveYearOld?.facilityOrPersonName
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.dayCaregiver"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantFiveYearOld?.dayCaregiver
                  ? infantFiveYearOld?.dayCaregiver
                  : ""
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          {/* 歯の状態 */}
          <Grid item xs={12}>
            <TextBlockCustom
              classText="font-bold"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.teethCondition"
              )}
              headerColor={COLORS.primary}
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.cariesRequiringTreatment"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFiveYearOld?.cariesRequiringTreatment
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFiveYearOld?.cariesRequiringTreatment
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.milkTeeth"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFiveYearOld?.milkTeeth
                  ? infantFiveYearOld?.milkTeeth.toString() +
                    translate("title.book")
                  : undefined
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.permanentTeeth"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFiveYearOld?.permanentTeeth
                  ? infantFiveYearOld?.permanentTeeth.toString() +
                    translate("title.book")
                  : undefined
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.total")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFiveYearOld?.total
                  ? infantFiveYearOld?.total.toString() +
                    translate("title.book")
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.dirtyTeeth"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFiveYearOld?.dirtyTeeth
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFiveYearOld?.dirtyTeeth
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.gingivaMucousMembrane"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFiveYearOld?.gingivaMucousMembrane
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFiveYearOld?.gingivaMucousMembrane
                    )
                  : ""
              }
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFiveYearOld?.gingivaMucousMembraneNote &&
                infantFiveYearOld?.gingivaMucousMembrane ===
                  InfantFiveYearOldGingivaMucousMembraneEnum.CanBe
                  ? infantFiveYearOld?.gingivaMucousMembraneNote
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.engagement"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFiveYearOld?.engagement
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFiveYearOld?.engagement
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.examinationDate"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFiveYearOld?.examinationDate
                  ? toJapanDate(infantFiveYearOld?.examinationDate)
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.teethCondition"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.maxilla")}
              headerColor={COLORS.approxGrey}
            />

            <TeethSelectCustom
              fieldType={BloodSelectionType.MultipleFields}
              fieldDescriptions={["6", "5", "4", "3", "2", "1"]}
              initialValues={
                !!infantFiveYearOld?.rightMolarsMaxillaA &&
                JSON.parse(infantFiveYearOld.rightMolarsMaxillaA)
              }
            />

            <TeethSelectCustom
              fieldType={BloodSelectionType.MultipleFields}
              fieldDescriptions={["E", "D", "C", "B", "A"]}
              initialValues={
                !!infantFiveYearOld?.rightMolarsMaxillaB &&
                JSON.parse(infantFiveYearOld?.rightMolarsMaxillaB)
              }
            />

            <TeethSelectCustom
              fieldType={BloodSelectionType.MultipleFields}
              fieldDescriptions={["1", "2", "3", "4", "5", "6"]}
              initialValues={
                !!infantFiveYearOld?.leftFrontToothMaxillaA &&
                JSON.parse(infantFiveYearOld?.leftFrontToothMaxillaA)
              }
            />

            <TeethSelectCustom
              fieldType={BloodSelectionType.MultipleFields}
              fieldDescriptions={["A", "B", "C", "D", "E"]}
              initialValues={
                !!infantFiveYearOld?.leftFrontToothMaxillaB &&
                JSON.parse(infantFiveYearOld?.leftFrontToothMaxillaB)
              }
            />

            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.lowerJaw"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TeethSelectCustom
              fieldType={BloodSelectionType.MultipleFields}
              fieldDescriptions={["E", "D", "C", "B", "A"]}
              initialValues={
                !!infantFiveYearOld?.rightMolarsLowerJawA &&
                JSON.parse(infantFiveYearOld.rightMolarsLowerJawA)
              }
            />

            <TeethSelectCustom
              fieldType={BloodSelectionType.MultipleFields}
              fieldDescriptions={["6", "5", "4", "3", "2", "1"]}
              initialValues={
                !!infantFiveYearOld?.rightMolarsLowerJawB &&
                JSON.parse(infantFiveYearOld?.rightMolarsLowerJawB)
              }
            />

            <TeethSelectCustom
              fieldType={BloodSelectionType.MultipleFields}
              fieldDescriptions={["A", "B", "C", "D", "E"]}
              initialValues={
                !!infantFiveYearOld?.leftFrontToothLowerJawA &&
                JSON.parse(infantFiveYearOld?.leftFrontToothLowerJawA)
              }
            />

            <TeethSelectCustom
              fieldType={BloodSelectionType.MultipleFields}
              fieldDescriptions={["1", "2", "3", "4", "5", "6"]}
              initialValues={
                !!infantFiveYearOld?.leftFrontToothLowerJawB &&
                JSON.parse(infantFiveYearOld?.leftFrontToothLowerJawB)
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          {/* 検尿 */}
          <Grid item xs={12}>
            <TextBlockCustom
              classText="font-bold"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.urinalysis"
              )}
              headerColor={COLORS.primary}
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.urineProtein"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFiveYearOld?.urineProtein
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFiveYearOld?.urineProtein
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.urineSugar"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFiveYearOld?.urineSugar
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFiveYearOld?.urineSugar
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.urineOccultBlood"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFiveYearOld?.urineOccultBlood
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFiveYearOld?.urineOccultBlood
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.whiteBloodCells"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFiveYearOld?.whiteBloodCells
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFiveYearOld?.whiteBloodCells
                    )
                  : ""
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          {/* 保護者の記録（5歳の頃）*/}
          <Grid item xs={12}>
            <TextBlockCustom
              classText="font-bold"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.parentRecordFiveYear"
              )}
              headerColor={COLORS.primary}
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={
                getNextYearFromDate(baby?.birthday, 5) +
                translate("infantMedicalExamFormScreen.text.fiveYearFromDate")
              }
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFiveYearOld?.parentMessage
                  ? infantFiveYearOld?.parentMessage
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.recordingDate"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFiveYearOld?.recordingDate
                  ? toJapanDate(infantFiveYearOld?.recordingDate)
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.turnItBack"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFiveYearOld?.turnItBack
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFiveYearOld?.turnItBack
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.rememberAndDrawPicture"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFiveYearOld?.rememberAndDrawPicture
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFiveYearOld?.rememberAndDrawPicture
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.knowTheColors"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFiveYearOld?.knowTheColors
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFiveYearOld?.knowTheColors
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.speakClear"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFiveYearOld?.speakClear
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFiveYearOld?.speakClear
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.poopAlone"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFiveYearOld?.poopAlone
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFiveYearOld?.poopAlone
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.liftOfKindergartens"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFiveYearOld?.liftOfKindergartens
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFiveYearOld?.liftOfKindergartens
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.loveAnimalsAndFlowers"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFiveYearOld?.loveAnimalsAndFlowers
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFiveYearOld?.loveAnimalsAndFlowers
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.eatWithFamily"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFiveYearOld?.eatWithFamily
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFiveYearOld?.eatWithFamily
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.brushYourTeeth1"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFiveYearOld?.brushYourTeeth
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFiveYearOld?.brushYourTeeth
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.suckYourFingers"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFiveYearOld?.suckYourFingers
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFiveYearOld?.suckYourFingers
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.understandTheContent"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFiveYearOld?.understandTheContent
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFiveYearOld?.understandTheContent
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.childRearing"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFiveYearOld?.childRearing
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFiveYearOld?.childRearing
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.raisingChildren"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFiveYearOld?.raisingChildren
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFiveYearOld?.raisingChildren
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.yourGrowth"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFiveYearOld?.yourGrowth
                  ? infantFiveYearOld?.yourGrowth
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.photo")}
              headerColor={COLORS.approxGrey}
            />

            {infantFiveYearOld?.photo3 && (
              <AvatarCustom imgSrc={infantFiveYearOld?.photo3} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
