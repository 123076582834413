import React, { useState } from "react";
import styled from "styled-components";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { COLORS, noData } from "@/constants";
import { Link, useParams } from "react-router-dom";
import { getUserListUrl } from "../user-list/UserListPage.shared";
import { TextBlockCustom } from "@/components/display";
import {
  PregnancyInfo,
  MomInfo,
  PregnancyDiagnosisRecord,
  MomHealth,
  MomProfessionAndEnv,
  ChildInfo,
  ChildStatus,
  VaccinationManagement,
  MedicalConsultant,
  MomBodyData,
  ChildBodyData,
  InfantMedicalExam,
  MorbidityHistory,
  SideEffects,
} from "./components";
import { Button, ButtonGroup } from "@mui/material";
import { ReactComponent as IcFetus } from "src/assets/images/ic-fetus.svg";
import { ReactComponent as IcBoy } from "src/assets/images/ic-boy.svg";
import { ReactComponent as IcGirl } from "src/assets/images/ic-girl.svg";
import { babiesApi, Baby, BabyGenderEnum, Fetus, fetusesApi } from "@/api";
import { useQuery } from "react-query";

const StyledUserDetailPage = styled.div``;

interface IUserDetailPageProps {}

export const UserDetailPage = (
  props: React.PropsWithChildren<IUserDetailPageProps>
) => {
  const params = useParams();

  //--- Get fetus list
  const [fetusList, setFetusList] = useState<Fetus[]>();
  const [valueFetus, setValueFetus] = useState(0);
  const [selectBaby, setSelectBaby] = useState(false);

  useQuery({
    queryKey: "GET_FETUS_BY_USER_AND_MOTHER_ID",
    queryFn: () =>
      fetusesApi.adminFetusesGet({
        userId: parseInt(params.userId),
        motherId: parseInt(params.motherId),
      }),
    onSuccess: (response) => {
      if (response?.data?.length > 0) {
        setFetusList(response?.data);
        setValueFetus(response?.data[0]?.id);
      } else {
        setSelectBaby(true);
      }
    },
  });

  //--- Get baby list
  const [babyList, setBabyList] = useState<Baby[]>();
  const [valueBaby, setValueBaby] = useState(0);

  useQuery({
    queryKey: "GET_BABY_BY_USER_AND_MOTHER_ID",
    queryFn: () =>
      babiesApi.adminBabiesGet({
        userId: parseInt(params.userId),
        motherId: parseInt(params.motherId),
      }),
    onSuccess: (response) => {
      if (response?.data?.length > 0) {
        setBabyList(response?.data);

        if (valueFetus === 0) {
          setValueBaby(response?.data[0]?.id);
        }
      }
    },
  });

  //--- Change fetus tab
  const handleChangeFetusTab = (newValue: number) => {
    setValueFetus(newValue);
    setSelectBaby(false);
  };

  //--- Change baby tab
  const handleChangeBabyTab = (newValue: number) => {
    setValueBaby(newValue);
    setSelectBaby(true);
  };

  return (
    <StyledUserDetailPage>
      <div className="mb-4">
        <div className="mb-2">
          <Link
            to={getUserListUrl()}
            className="font-medium"
            style={{ color: COLORS.approxGrey }}
          >
            <ArrowBackIosIcon
              fontSize="inherit"
              sx={{ color: COLORS.approxGrey, marginTop: "-5px" }}
            />
            ユーザー管理
          </Link>
        </div>
        <div className="mb-4">
          <TextBlockCustom textHeader="ユーザー詳細" textSize={24} />
        </div>
      </div>

      <TextBlockCustom
        classText="mb-4"
        textHeader="ママの情報"
        headerColor={COLORS.primary}
      ></TextBlockCustom>
      <MomInfo motherId={parseInt(params.motherId)} />

      <TextBlockCustom
        classText="mt-4 mb-4"
        textHeader="子どもの情報"
        headerColor={COLORS.primary}
      ></TextBlockCustom>

      {fetusList?.length > 0 || babyList?.length > 0 ? (
        <>
          <div className="button-group-custom mb-4">
            <ButtonGroup
              variant="contained"
              aria-label="outlined primary button group"
            >
              {(fetusList || []).map((item) => (
                <Button
                  key={"fetusList_" + item.id}
                  className={
                    valueFetus === item.id && !selectBaby ? "selected" : ""
                  }
                  onClick={() => handleChangeFetusTab(item.id)}
                >
                  <div className="flex items-center">
                    <IcFetus />
                    <span>{item.name}</span>
                  </div>
                </Button>
              ))}

              {(babyList || []).map((item) => (
                <Button
                  key={"babyList_" + item.id}
                  className={
                    valueBaby === item.id && selectBaby ? "selected" : ""
                  }
                  onClick={() => handleChangeBabyTab(item.id)}
                >
                  <div className="flex items-center">
                    {item.gender === BabyGenderEnum.Male ? (
                      <IcBoy />
                    ) : (
                      <IcGirl />
                    )}
                    <span>{item.name}</span>
                  </div>
                </Button>
              ))}
            </ButtonGroup>
          </div>

          {valueFetus ===
            (fetusList || [])?.find((f) => f.id === valueFetus)?.id &&
            !selectBaby && (
              <>
                <PregnancyInfo
                  fetusItem={(fetusList || [])?.find(
                    (f) => f.id === valueFetus
                  )}
                />

                <MomBodyData
                  userId={parseInt(params.userId)}
                  fetusId={valueFetus}
                />

                <PregnancyDiagnosisRecord
                  userId={parseInt(params.userId)}
                  pregnantId={
                    (fetusList || [])?.find(
                      (f) =>
                        f.motherId === parseInt(params.motherId) &&
                        f.userId === parseInt(params.userId)
                    )?.pregnantId
                  }
                  fetusItem={(fetusList || [])?.find(
                    (f) => f.id === valueFetus
                  )}
                />

                <MomHealth
                  userId={parseInt(params.userId)}
                  motherId={parseInt(params.motherId)}
                />

                <MomProfessionAndEnv
                  userId={parseInt(params.userId)}
                  motherId={parseInt(params.motherId)}
                />
              </>
            )}

          {valueBaby ===
            (babyList || [])?.find((f) => f.id === valueBaby)?.id &&
            selectBaby && (
              <>
                <ChildInfo
                  babyItem={(babyList || [])?.find((f) => f.id === valueBaby)}
                />

                <ChildStatus
                  userId={parseInt(params.userId)}
                  babyId={valueBaby}
                />

                <ChildBodyData
                  userId={parseInt(params.userId)}
                  babyId={valueBaby}
                />

                <VaccinationManagement
                  userId={parseInt(params.userId)}
                  babyId={valueBaby}
                />

                <MorbidityHistory
                  userId={parseInt(params.userId)}
                  babyId={valueBaby}
                />

                <SideEffects
                  userId={parseInt(params.userId)}
                  babyId={valueBaby}
                />

                <MedicalConsultant
                  userId={parseInt(params.userId)}
                  babyId={valueBaby}
                />

                <InfantMedicalExam
                  userId={parseInt(params.userId)}
                  babyId={valueBaby}
                  babyItem={(babyList || [])?.find((f) => f.id === valueBaby)}
                />
              </>
            )}
        </>
      ) : (
        <div className="text-center font-bold">{noData}</div>
      )}
    </StyledUserDetailPage>
  );
};
