/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Diagnosis,
    DiagnosisFromJSON,
    DiagnosisToJSON,
    DiagnosisPagination,
    DiagnosisPaginationFromJSON,
    DiagnosisPaginationToJSON,
} from '../models';

export interface AdminDiagnosesIdDeleteRequest {
    id: number;
}

export interface AdminDiagnosesIdPutRequest {
    id: number;
    diagnosis?: Diagnosis;
}

export interface AdminDiagnosesPostRequest {
    diagnosis?: Diagnosis;
}

export interface DiagnosesGetRequest {
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
    keyword?: string;
}

export interface DiagnosesIdGetRequest {
    id: number;
}

/**
 * 
 */
export class DiagnosesApi extends runtime.BaseAPI {

    /**
     */
    adminDiagnosesIdDeleteRaw = async (requestParameters: AdminDiagnosesIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling adminDiagnosesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/diagnoses/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    adminDiagnosesIdDelete = async (requestParameters: AdminDiagnosesIdDeleteRequest, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.adminDiagnosesIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    adminDiagnosesIdPutRaw = async (requestParameters: AdminDiagnosesIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Diagnosis>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling adminDiagnosesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/diagnoses/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DiagnosisToJSON(requestParameters.diagnosis),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DiagnosisFromJSON(jsonValue));
    }

    /**
     */
    adminDiagnosesIdPut = async (requestParameters: AdminDiagnosesIdPutRequest, initOverrides?: RequestInit): Promise<Diagnosis> => {
        const response = await this.adminDiagnosesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    adminDiagnosesPostRaw = async (requestParameters: AdminDiagnosesPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Diagnosis>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/diagnoses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DiagnosisToJSON(requestParameters.diagnosis),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DiagnosisFromJSON(jsonValue));
    }

    /**
     */
    adminDiagnosesPost = async (requestParameters: AdminDiagnosesPostRequest = {}, initOverrides?: RequestInit): Promise<Diagnosis> => {
        const response = await this.adminDiagnosesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    diagnosesGetRaw = async (requestParameters: DiagnosesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DiagnosisPagination>> => {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.keyword !== undefined) {
            queryParameters['keyword'] = requestParameters.keyword;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/diagnoses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DiagnosisPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    diagnosesGet = async (requestParameters: DiagnosesGetRequest = {}, initOverrides?: RequestInit): Promise<DiagnosisPagination> => {
        const response = await this.diagnosesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    diagnosesIdGetRaw = async (requestParameters: DiagnosesIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Diagnosis>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling diagnosesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/diagnoses/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DiagnosisFromJSON(jsonValue));
    }

    /**
     * 
     */
    diagnosesIdGet = async (requestParameters: DiagnosesIdGetRequest, initOverrides?: RequestInit): Promise<Diagnosis> => {
        const response = await this.diagnosesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
