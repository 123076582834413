/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';
import type { NoteScheduleAllOf } from './NoteScheduleAllOf';
import {
    NoteScheduleAllOfFromJSON,
    NoteScheduleAllOfFromJSONTyped,
    NoteScheduleAllOfToJSON,
} from './NoteScheduleAllOf';

/**
 * 
 * @export
 * @interface NoteSchedule
 */
export interface NoteSchedule {
    /**
     * 
     * @type {number}
     * @memberof NoteSchedule
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof NoteSchedule
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteSchedule
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteSchedule
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteSchedule
     */
    noteDate?: string;
}

/**
 * Check if a given object implements the NoteSchedule interface.
 */
export function instanceOfNoteSchedule(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NoteScheduleFromJSON(json: any): NoteSchedule {
    return NoteScheduleFromJSONTyped(json, false);
}

export function NoteScheduleFromJSONTyped(json: any, ignoreDiscriminator: boolean): NoteSchedule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'noteDate': !exists(json, 'noteDate') ? undefined : json['noteDate'],
    };
}

export function NoteScheduleToJSON(value?: NoteSchedule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'note': value.note,
        'noteDate': value.noteDate,
    };
}

