/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ChildBirth,
    ChildBirthFromJSON,
    ChildBirthToJSON,
    ChildBirthPagination,
    ChildBirthPaginationFromJSON,
    ChildBirthPaginationToJSON,
} from '../models';

export interface AdminChildBirthsGetRequest {
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
    userId?: number;
}

export interface ChildBirthsGetRequest {
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface ChildBirthsIdDeleteRequest {
    id: number;
}

export interface ChildBirthsIdGetRequest {
    id: number;
}

export interface ChildBirthsIdPutRequest {
    id: number;
    childBirth?: ChildBirth;
}

export interface ChildBirthsPostRequest {
    childBirth?: ChildBirth;
}

export interface ChildBirthsShareAllGetRequest {
    sourceUserId: number;
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface ChildBirthsShareDetailGetRequest {
    sourceUserId: number;
    recordId: number;
}

/**
 * 
 */
export class ChildBirthsApi extends runtime.BaseAPI {

    /**
     * 
     */
    adminChildBirthsGetRaw = async (requestParameters: AdminChildBirthsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ChildBirthPagination>> => {
        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling adminChildBirthsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/childBirths`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChildBirthPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    adminChildBirthsGet = async (requestParameters: AdminChildBirthsGetRequest, initOverrides?: RequestInit): Promise<ChildBirthPagination> => {
        const response = await this.adminChildBirthsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    childBirthsGetRaw = async (requestParameters: ChildBirthsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ChildBirthPagination>> => {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/childBirths`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChildBirthPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    childBirthsGet = async (requestParameters: ChildBirthsGetRequest = {}, initOverrides?: RequestInit): Promise<ChildBirthPagination> => {
        const response = await this.childBirthsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    childBirthsIdDeleteRaw = async (requestParameters: ChildBirthsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling childBirthsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/childBirths/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    childBirthsIdDelete = async (requestParameters: ChildBirthsIdDeleteRequest, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.childBirthsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    childBirthsIdGetRaw = async (requestParameters: ChildBirthsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ChildBirth>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling childBirthsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/childBirths/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChildBirthFromJSON(jsonValue));
    }

    /**
     * 
     */
    childBirthsIdGet = async (requestParameters: ChildBirthsIdGetRequest, initOverrides?: RequestInit): Promise<ChildBirth> => {
        const response = await this.childBirthsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    childBirthsIdPutRaw = async (requestParameters: ChildBirthsIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ChildBirth>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling childBirthsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/childBirths/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChildBirthToJSON(requestParameters.childBirth),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChildBirthFromJSON(jsonValue));
    }

    /**
     */
    childBirthsIdPut = async (requestParameters: ChildBirthsIdPutRequest, initOverrides?: RequestInit): Promise<ChildBirth> => {
        const response = await this.childBirthsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    childBirthsPostRaw = async (requestParameters: ChildBirthsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ChildBirth>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/childBirths`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChildBirthToJSON(requestParameters.childBirth),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChildBirthFromJSON(jsonValue));
    }

    /**
     */
    childBirthsPost = async (requestParameters: ChildBirthsPostRequest = {}, initOverrides?: RequestInit): Promise<ChildBirth> => {
        const response = await this.childBirthsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    childBirthsShareAllGetRaw = async (requestParameters: ChildBirthsShareAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ChildBirthPagination>> => {
        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling childBirthsShareAllGet.');
        }

        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling childBirthsShareAllGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/childBirths/share/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChildBirthPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    childBirthsShareAllGet = async (requestParameters: ChildBirthsShareAllGetRequest, initOverrides?: RequestInit): Promise<ChildBirthPagination> => {
        const response = await this.childBirthsShareAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    childBirthsShareDetailGetRaw = async (requestParameters: ChildBirthsShareDetailGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ChildBirth>> => {
        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling childBirthsShareDetailGet.');
        }

        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling childBirthsShareDetailGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        if (requestParameters.recordId !== undefined) {
            queryParameters['recordId'] = requestParameters.recordId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/childBirths/share/detail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChildBirthFromJSON(jsonValue));
    }

    /**
     * 
     */
    childBirthsShareDetailGet = async (requestParameters: ChildBirthsShareDetailGetRequest, initOverrides?: RequestInit): Promise<ChildBirth> => {
        const response = await this.childBirthsShareDetailGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
