/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateRequest
 */
export interface UpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof UpdateRequest
     */
    adminId?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateRequest
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRequest
     */
    newPassword?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateRequest
     */
    roleId?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateRequest
     */
    staffName?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRequest
     */
    gender?: UpdateRequestGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateRequest
     */
    birthday?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateRequest
     */
    startDate?: string | null;
}


/**
 * @export
 */
export const UpdateRequestGenderEnum = {
    Male: 'MALE',
    Female: 'FEMALE',
    Unknown: 'UNKNOWN'
} as const;
export type UpdateRequestGenderEnum = typeof UpdateRequestGenderEnum[keyof typeof UpdateRequestGenderEnum];


/**
 * Check if a given object implements the UpdateRequest interface.
 */
export function instanceOfUpdateRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateRequestFromJSON(json: any): UpdateRequest {
    return UpdateRequestFromJSONTyped(json, false);
}

export function UpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'adminId': !exists(json, 'adminId') ? undefined : json['adminId'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'newPassword': !exists(json, 'newPassword') ? undefined : json['newPassword'],
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
        'staffName': !exists(json, 'staffName') ? undefined : json['staffName'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'birthday': !exists(json, 'birthday') ? undefined : json['birthday'],
        'startDate': !exists(json, 'startDate') ? undefined : json['startDate'],
    };
}

export function UpdateRequestToJSON(value?: UpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'adminId': value.adminId,
        'password': value.password,
        'newPassword': value.newPassword,
        'roleId': value.roleId,
        'staffName': value.staffName,
        'gender': value.gender,
        'birthday': value.birthday,
        'startDate': value.startDate,
    };
}

