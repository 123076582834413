/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaginationInfo } from './PaginationInfo';
import {
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './PaginationInfo';
import type { PrescriptionDrug } from './PrescriptionDrug';
import {
    PrescriptionDrugFromJSON,
    PrescriptionDrugFromJSONTyped,
    PrescriptionDrugToJSON,
} from './PrescriptionDrug';

/**
 * 
 * @export
 * @interface PrescriptionDrugPagination
 */
export interface PrescriptionDrugPagination {
    /**
     * 
     * @type {Array<PrescriptionDrug>}
     * @memberof PrescriptionDrugPagination
     */
    data?: Array<PrescriptionDrug>;
    /**
     * 
     * @type {PaginationInfo}
     * @memberof PrescriptionDrugPagination
     */
    meta?: PaginationInfo;
}

/**
 * Check if a given object implements the PrescriptionDrugPagination interface.
 */
export function instanceOfPrescriptionDrugPagination(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PrescriptionDrugPaginationFromJSON(json: any): PrescriptionDrugPagination {
    return PrescriptionDrugPaginationFromJSONTyped(json, false);
}

export function PrescriptionDrugPaginationFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrescriptionDrugPagination {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(PrescriptionDrugFromJSON)),
        'meta': !exists(json, 'meta') ? undefined : PaginationInfoFromJSON(json['meta']),
    };
}

export function PrescriptionDrugPaginationToJSON(value?: PrescriptionDrugPagination | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(PrescriptionDrugToJSON)),
        'meta': PaginationInfoToJSON(value.meta),
    };
}

