/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';
import type { VaccinatedRecordAllOf } from './VaccinatedRecordAllOf';
import {
    VaccinatedRecordAllOfFromJSON,
    VaccinatedRecordAllOfFromJSONTyped,
    VaccinatedRecordAllOfToJSON,
} from './VaccinatedRecordAllOf';

/**
 * 
 * @export
 * @interface VaccinatedRecord
 */
export interface VaccinatedRecord {
    /**
     * 
     * @type {number}
     * @memberof VaccinatedRecord
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof VaccinatedRecord
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof VaccinatedRecord
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof VaccinatedRecord
     */
    babyId?: number;
    /**
     * 
     * @type {number}
     * @memberof VaccinatedRecord
     */
    vaccineId?: number;
    /**
     * 
     * @type {number}
     * @memberof VaccinatedRecord
     */
    vaccinationScheduleId?: number;
    /**
     * 
     * @type {string}
     * @memberof VaccinatedRecord
     */
    rotaVaccine?: VaccinatedRecordRotaVaccineEnum;
    /**
     * 
     * @type {string}
     * @memberof VaccinatedRecord
     */
    lotNumberVaccine?: string;
    /**
     * 
     * @type {string}
     * @memberof VaccinatedRecord
     */
    addressInject?: string;
    /**
     * 
     * @type {string}
     * @memberof VaccinatedRecord
     */
    doctorName?: string;
    /**
     * 
     * @type {string}
     * @memberof VaccinatedRecord
     */
    sideEffects?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VaccinatedRecord
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof VaccinatedRecord
     */
    dateInjected?: string;
    /**
     * 
     * @type {string}
     * @memberof VaccinatedRecord
     */
    vaccineName?: string;
    /**
     * 
     * @type {number}
     * @memberof VaccinatedRecord
     */
    numberOfInjections?: number;
    /**
     * 
     * @type {string}
     * @memberof VaccinatedRecord
     */
    dateInjectSuggest?: string;
    /**
     * 
     * @type {string}
     * @memberof VaccinatedRecord
     */
    bodyPart?: VaccinatedRecordBodyPartEnum;
}


/**
 * @export
 */
export const VaccinatedRecordRotaVaccineEnum = {
    Rotarix: 'ROTARIX',
    Rotatec: 'ROTATEC'
} as const;
export type VaccinatedRecordRotaVaccineEnum = typeof VaccinatedRecordRotaVaccineEnum[keyof typeof VaccinatedRecordRotaVaccineEnum];

/**
 * @export
 */
export const VaccinatedRecordBodyPartEnum = {
    RightArmUpperArmUpperSide: 'RIGHT_ARM_UPPER_ARM_UPPER_SIDE',
    RightArmUpperArmLowerSide: 'RIGHT_ARM_UPPER_ARM_LOWER_SIDE',
    RightFootCentralPartOfThigh: 'RIGHT_FOOT_CENTRAL_PART_OF_THIGH',
    LeftArmUpperArmUpperSide: 'LEFT_ARM_UPPER_ARM_UPPER_SIDE',
    LeftArmUpperArmLowerSide: 'LEFT_ARM_UPPER_ARM_LOWER_SIDE',
    LeftFootCentralPartOfThigh: 'LEFT_FOOT_CENTRAL_PART_OF_THIGH',
    Others: 'OTHERS'
} as const;
export type VaccinatedRecordBodyPartEnum = typeof VaccinatedRecordBodyPartEnum[keyof typeof VaccinatedRecordBodyPartEnum];


/**
 * Check if a given object implements the VaccinatedRecord interface.
 */
export function instanceOfVaccinatedRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VaccinatedRecordFromJSON(json: any): VaccinatedRecord {
    return VaccinatedRecordFromJSONTyped(json, false);
}

export function VaccinatedRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): VaccinatedRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'babyId': !exists(json, 'babyId') ? undefined : json['babyId'],
        'vaccineId': !exists(json, 'vaccineId') ? undefined : json['vaccineId'],
        'vaccinationScheduleId': !exists(json, 'vaccinationScheduleId') ? undefined : json['vaccinationScheduleId'],
        'rotaVaccine': !exists(json, 'rotaVaccine') ? undefined : json['rotaVaccine'],
        'lotNumberVaccine': !exists(json, 'lotNumberVaccine') ? undefined : json['lotNumberVaccine'],
        'addressInject': !exists(json, 'addressInject') ? undefined : json['addressInject'],
        'doctorName': !exists(json, 'doctorName') ? undefined : json['doctorName'],
        'sideEffects': !exists(json, 'sideEffects') ? undefined : json['sideEffects'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'dateInjected': !exists(json, 'dateInjected') ? undefined : json['dateInjected'],
        'vaccineName': !exists(json, 'vaccineName') ? undefined : json['vaccineName'],
        'numberOfInjections': !exists(json, 'numberOfInjections') ? undefined : json['numberOfInjections'],
        'dateInjectSuggest': !exists(json, 'dateInjectSuggest') ? undefined : json['dateInjectSuggest'],
        'bodyPart': !exists(json, 'bodyPart') ? undefined : json['bodyPart'],
    };
}

export function VaccinatedRecordToJSON(value?: VaccinatedRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'babyId': value.babyId,
        'vaccineId': value.vaccineId,
        'vaccinationScheduleId': value.vaccinationScheduleId,
        'rotaVaccine': value.rotaVaccine,
        'lotNumberVaccine': value.lotNumberVaccine,
        'addressInject': value.addressInject,
        'doctorName': value.doctorName,
        'sideEffects': value.sideEffects,
        'comment': value.comment,
        'dateInjected': value.dateInjected,
        'vaccineName': value.vaccineName,
        'numberOfInjections': value.numberOfInjections,
        'dateInjectSuggest': value.dateInjectSuggest,
        'bodyPart': value.bodyPart,
    };
}

