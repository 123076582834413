import { NotificationCampaign, notificationCampaignsApi } from "@/api";
import { FileUploadField } from "@/components/fields/FileUploadField";
import { RichTextEditorField } from "@/components/fields/RichTextEditorField";
import { translate } from "@/helpers";
import {
  Button,
  Checkbox,
  InputAdornment,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import * as yup from "yup";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRange } from "react-date-range";
import moment from "moment";
import { useMutation } from "react-query";
import * as dateRangeLocales from "react-date-range/dist/locale";
import { NotiCampaignUserTable } from "./NotiCampaignUserTable";

const StyledNotiCampaignForm = styled.div`
  padding: 0 32px;

  .campaign-breadcrumb {
    width: fit-content;
    border-bottom: 1px solid #e8e5e5;
    li {
      padding: 8px 24px;
      color: #979797;

      &.active {
        font-weight: 500;
        color: #ee8595;
        border-bottom: 2px solid #ee8595;
      }
    }
  }
`;

interface INotiCampaignFormValues extends Partial<NotificationCampaign> {}

interface INotiCampaignFormProps {
  initialValues?: INotiCampaignFormValues;
  onCloseAddEditDialog?: () => void;
}

export const NotiCampaignAddEditForm = (
  props: React.PropsWithChildren<INotiCampaignFormProps>
) => {
  const isSubmitted = useRef(false);

  const notiCampaignTypeStorage = JSON.parse(
    localStorage.getItem("notiCampaignType")
  );

  //--- State
  const [notiCampaignType, setNotiCampaignType] = useState(
    notiCampaignTypeStorage
  );
  const [isLoading, setIsLoading] = useState(false);
  const [notiStep, setNotiStep] = useState(1);
  const [notiCampaignVal, setNotiCampaignVal] =
    useState<NotificationCampaign>();
  const [userListId, setUserListId] = useState<readonly any[]>([]);

  //--- Check show in list
  const [isCheckedShowInList, setIsCheckedShowInList] = useState(false);

  const handleChangeShowInList = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsCheckedShowInList(event.target.checked);
  };

  //--- Check show in home
  const [isCheckedShowInHome, setIsCheckedShowInHome] = useState(false);

  const handleChangeShowInHome = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsCheckedShowInHome(event.target.checked);
  };

  //--- Popover list date
  const [anchorElListDate, setAnchorElListDate] =
    useState<HTMLButtonElement | null>(null);

  const handleClickListDate = (event: any) => {
    setAnchorElListDate(event.currentTarget);
  };

  const handleCloseListDate = () => {
    setAnchorElListDate(null);
  };

  const openListDate = Boolean(anchorElListDate);
  const idListDate = openListDate ? "list-date-popover" : undefined;

  //--- Change list range date
  const [listRangeDate, setListRangeDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [listStartDate, setListStartDate] = useState("");
  const [listEndDate, setListEndDate] = useState("");

  const handleChangeListRangeDate = (item: any) => {
    let itemStartDate = item.startDate;
    let itemEndDate = item.endDate;

    setListStartDate(itemStartDate);
    setListEndDate(itemEndDate);

    setListRangeDate([item]);
  };

  const handleCancelListDate = () => {
    setListStartDate("");
    setListEndDate("");
    setListRangeDate([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);

    handleCloseListDate();
  };

  //--- Popover home date
  const [anchorElHomeDate, setAnchorElHomeDate] =
    useState<HTMLButtonElement | null>(null);

  const handleClickHomeDate = (event: any) => {
    setAnchorElHomeDate(event.currentTarget);
  };

  const handleCloseHomeDate = () => {
    setAnchorElHomeDate(null);
  };

  const openHomeDate = Boolean(anchorElHomeDate);
  const idHomeDate = openHomeDate ? "home-date-popover" : undefined;

  //--- Change home range date
  const [homeRangeDate, setHomeRangeDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [homeStartDate, setHomeStartDate] = useState("");
  const [homeEndDate, setHomeEndDate] = useState("");

  const handleChangeHomeRangeDate = (item: any) => {
    let itemStartDate = item.startDate;
    let itemEndDate = item.endDate;

    setHomeStartDate(itemStartDate);
    setHomeEndDate(itemEndDate);

    setHomeRangeDate([item]);
  };

  const handleCancelHomeDate = () => {
    setHomeStartDate("");
    setHomeEndDate("");
    setHomeRangeDate([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);

    handleCloseHomeDate();
  };

  //--- Init form
  const validationSchema = yup.object().shape({
    title: yup
      .string()
      .max(50, "50文字以内で入力してください")
      .required("タイトルを必ず入力してください。"),
    ...(notiCampaignType === "content"
      ? {
          content: yup.string().required("コンテンツを必ず入力してください。"),
        }
      : {
          image: yup
            .string()
            .required("バナーのアップロードを必ず入力してください。"),
        }),
  });

  const { mutateAsync: createNotiCampaign } = useMutation(
    notificationCampaignsApi.notificationCampaignsPost
  );
  const { mutateAsync: updateNotiCampain } = useMutation(
    notificationCampaignsApi.notificationCampaignsIdPut
  );

  const formik = useFormik<INotiCampaignFormValues>({
    initialValues: {
      ...props.initialValues,
    },
    validationSchema: validationSchema,
    onSubmit: async (values: INotiCampaignFormValues) => {
      try {
        const newValues: NotificationCampaign = {
          id: values?.id,
          type: notiCampaignType,
          title: values?.title,
          content: values?.content,
          image: values?.image,
          siteUrl: values?.siteUrl,
          isShowInList: isCheckedShowInList,
          isShowInHome: isCheckedShowInHome,
          listStartDate: isCheckedShowInList ? listStartDate : null,
          listEndDate: isCheckedShowInList ? listEndDate : null,
          homeStartDate: isCheckedShowInHome ? homeStartDate : null,
          homeEndDate: isCheckedShowInHome ? homeEndDate : null,
        };

        setNotiCampaignVal(newValues);
      } catch (error) {
        if (error.body) {
          formik.setErrors(error.body);
        }
        throw error;
      } finally {
        setNotiStep(2);
      }
    },
  });

  const handleAddEditNotiCampaign = async (isPublish: boolean) => {
    try {
      setIsLoading(true);
      if (notiCampaignVal?.id > 0) {
        await updateNotiCampain({
          id: notiCampaignVal.id,
          notificationCampaign: {
            id: notiCampaignVal.id,
            ...notiCampaignVal,
            listStartDate: isCheckedShowInList ? listStartDate : null,
            listEndDate: isCheckedShowInList ? listEndDate : null,
            homeStartDate: isCheckedShowInHome ? homeStartDate : null,
            homeEndDate: isCheckedShowInHome ? homeEndDate : null,
            isPublish: isPublish,
            userList: userListId?.length > 0 ? userListId?.join(",") : null,
          },
        });
      } else {
        await createNotiCampaign({
          notificationCampaign: {
            ...notiCampaignVal,
            isPublish: isPublish,
            userList: userListId?.length > 0 ? userListId?.join(",") : null,
          },
        });
      }
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
      props.onCloseAddEditDialog();
    }
  };

  useEffect(() => {
    if (props?.initialValues !== null && props?.initialValues?.id > 0) {
      setIsCheckedShowInList(props?.initialValues?.isShowInList);
      setListStartDate(props?.initialValues?.listStartDate);
      setListEndDate(props?.initialValues?.listEndDate);

      setIsCheckedShowInHome(props?.initialValues?.isShowInHome);
      setHomeStartDate(props?.initialValues?.homeStartDate);
      setHomeEndDate(props?.initialValues?.homeEndDate);

      setNotiCampaignType(props?.initialValues?.type);

      setUserListId(props?.initialValues?.userList?.split(","));
    }
  }, [props.initialValues]);

  return (
    <StyledNotiCampaignForm>
      <ul className="campaign-breadcrumb flex mb-8">
        <li className={notiStep === 1 ? "active" : ""}>コンテンツ</li>
        <li className={notiStep === 2 ? "active" : ""}>送信対象</li>
      </ul>

      {notiStep === 1 ? (
        <form
          onSubmit={(e) => {
            isSubmitted.current = true;
            formik.handleSubmit(e);
          }}
        >
          <div className="mb-8">
            <div className="mb-4">
              <Typography variant="body1" fontWeight={500} component="label">
                タイトル <span className="text-red-500">※</span>
              </Typography>
            </div>
            <div className="custom-input backgrounded-input">
              <TextField
                name="title"
                variant="outlined"
                size="small"
                fullWidth
                onChange={formik.handleChange("title")}
                onBlur={formik.handleBlur("title")}
                error={
                  (isSubmitted.current || formik.touched.title) &&
                  Boolean(formik.errors.title)
                }
                value={formik.values.title}
                helperText={
                  (isSubmitted.current || formik.touched.title) &&
                  translate(formik.errors.title)
                }
              />
            </div>
          </div>

          {notiCampaignType === "content" ? (
            <div className="mb-8">
              <div className="mb-4">
                <Typography variant="body1" fontWeight={500} component="label">
                  コンテンツ <span className="text-red-500">※</span>
                </Typography>
              </div>

              <RichTextEditorField
                name="content"
                required
                errors={formik.errors}
                touched={formik.touched}
                className="h-auto"
                setFieldValue={formik.setFieldValue}
                values={formik.values}
              />
            </div>
          ) : (
            <div className="mb-8">
              <div className="mb-4">
                <Typography variant="body1" fontWeight={500} component="label">
                  バナーのアップロード <span className="text-red-500">※</span>
                </Typography>
              </div>

              <FileUploadField
                name="image"
                required
                showDescription
                errors={formik.errors}
                touched={formik.touched}
                setFieldValue={formik.setFieldValue}
                values={formik.values}
              />
            </div>
          )}

          <div className="mb-8">
            <div className="mb-4">
              <Typography variant="body1" fontWeight={500} component="label">
                URL
              </Typography>
            </div>
            <div className="custom-input backgrounded-input">
              <TextField
                name="siteUrl"
                variant="outlined"
                size="small"
                fullWidth
                onChange={formik.handleChange("siteUrl")}
                onBlur={formik.handleBlur("siteUrl")}
                value={formik.values.siteUrl}
              />
            </div>
          </div>

          <div className="flex items-center">
            <Checkbox
              checked={isCheckedShowInList}
              onChange={handleChangeShowInList}
              inputProps={{ "aria-label": "showInList" }}
            />
            <span>イベントタブへ表示</span>
          </div>

          {isCheckedShowInList && (
            <>
              <div className="custom-input backgrounded-input">
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="listRangeDate"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CalendarMonthIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={
                    listStartDate &&
                    listEndDate &&
                    moment(listStartDate).format("DD/MM/YYYY") +
                      (listEndDate !== ""
                        ? " - " + moment(listEndDate).format("DD/MM/YYYY")
                        : "")
                  }
                  onClick={handleClickListDate}
                />
              </div>

              <Popover
                id={idListDate}
                open={openListDate}
                anchorEl={anchorElListDate}
                onClose={handleCloseListDate}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <DateRange
                  id="listRangeDate"
                  editableDateInputs={true}
                  moveRangeOnFirstSelection={false}
                  ranges={listRangeDate}
                  rangeColors={["#EE8595"]}
                  locale={dateRangeLocales.ja}
                  onChange={(item: any) =>
                    handleChangeListRangeDate(item.selection)
                  }
                />

                <div className="flex justify-between px-4">
                  <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    onClick={handleCancelListDate}
                  >
                    キャンセル
                  </Button>

                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={handleCloseListDate}
                  >
                    確認
                  </Button>
                </div>
              </Popover>
            </>
          )}

          <div className="flex items-center">
            <Checkbox
              checked={isCheckedShowInHome}
              onChange={handleChangeShowInHome}
              inputProps={{ "aria-label": "showInHome" }}
            />
            <span>ホームへ表示</span>
          </div>

          {isCheckedShowInHome && (
            <>
              <div className="custom-input backgrounded-input">
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="homeRangeDate"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CalendarMonthIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={
                    homeStartDate &&
                    homeEndDate &&
                    moment(homeStartDate).format("DD/MM/YYYY") +
                      (homeEndDate !== ""
                        ? " - " + moment(homeEndDate).format("DD/MM/YYYY")
                        : "")
                  }
                  onClick={handleClickHomeDate}
                />
              </div>

              <Popover
                id={idHomeDate}
                open={openHomeDate}
                anchorEl={anchorElHomeDate}
                onClose={handleCloseHomeDate}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <DateRange
                  id="homeRangeDate"
                  editableDateInputs={true}
                  moveRangeOnFirstSelection={false}
                  ranges={homeRangeDate}
                  rangeColors={["#EE8595"]}
                  locale={dateRangeLocales.ja}
                  onChange={(item: any) =>
                    handleChangeHomeRangeDate(item.selection)
                  }
                />

                <div className="flex justify-between px-4">
                  <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    onClick={handleCancelHomeDate}
                  >
                    キャンセル
                  </Button>

                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={handleCloseHomeDate}
                  >
                    確認
                  </Button>
                </div>
              </Popover>
            </>
          )}

          <div className="flex justify-end mt-6">
            <Button type="submit" color="primary" variant="contained">
              次へ
            </Button>
          </div>
        </form>
      ) : (
        <>
          <NotiCampaignUserTable
            userListId={userListId}
            setUserListId={setUserListId}
          />

          <div className="flex justify-between border-t pt-4 mt-6">
            <Button
              type="submit"
              color="primary"
              variant="contained"
              onClick={() => setNotiStep(1)}
            >
              戻る
            </Button>

            <div className="grid grid-cols-2 gap-6">
              <Button
                type="submit"
                color="primary"
                variant="outlined"
                disabled={
                  isLoading ||
                  (props?.initialValues?.isPublish && isCheckedShowInHome)
                }
                onClick={() => handleAddEditNotiCampaign(false)}
              >
                下書き
              </Button>

              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={isLoading}
                onClick={() => handleAddEditNotiCampaign(true)}
              >
                公開
              </Button>
            </div>
          </div>
        </>
      )}
    </StyledNotiCampaignForm>
  );
};
