/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaginationInfo } from './PaginationInfo';
import {
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './PaginationInfo';
import type { RecordSideEffect } from './RecordSideEffect';
import {
    RecordSideEffectFromJSON,
    RecordSideEffectFromJSONTyped,
    RecordSideEffectToJSON,
} from './RecordSideEffect';

/**
 * 
 * @export
 * @interface RecordSideEffectPagination
 */
export interface RecordSideEffectPagination {
    /**
     * 
     * @type {Array<RecordSideEffect>}
     * @memberof RecordSideEffectPagination
     */
    data?: Array<RecordSideEffect>;
    /**
     * 
     * @type {PaginationInfo}
     * @memberof RecordSideEffectPagination
     */
    meta?: PaginationInfo;
}

/**
 * Check if a given object implements the RecordSideEffectPagination interface.
 */
export function instanceOfRecordSideEffectPagination(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RecordSideEffectPaginationFromJSON(json: any): RecordSideEffectPagination {
    return RecordSideEffectPaginationFromJSONTyped(json, false);
}

export function RecordSideEffectPaginationFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordSideEffectPagination {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(RecordSideEffectFromJSON)),
        'meta': !exists(json, 'meta') ? undefined : PaginationInfoFromJSON(json['meta']),
    };
}

export function RecordSideEffectPaginationToJSON(value?: RecordSideEffectPagination | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(RecordSideEffectToJSON)),
        'meta': PaginationInfoToJSON(value.meta),
    };
}

