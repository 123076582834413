/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';
import type { InfantTwoYearOldAllOf } from './InfantTwoYearOldAllOf';
import {
    InfantTwoYearOldAllOfFromJSON,
    InfantTwoYearOldAllOfFromJSONTyped,
    InfantTwoYearOldAllOfToJSON,
} from './InfantTwoYearOldAllOf';

/**
 * 
 * @export
 * @interface InfantTwoYearOld
 */
export interface InfantTwoYearOld {
    /**
     * 
     * @type {number}
     * @memberof InfantTwoYearOld
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InfantTwoYearOld
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof InfantTwoYearOld
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof InfantTwoYearOld
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof InfantTwoYearOld
     */
    babyId?: number;
    /**
     * 
     * @type {number}
     * @memberof InfantTwoYearOld
     */
    ageId?: number;
    /**
     * 
     * @type {string}
     * @memberof InfantTwoYearOld
     */
    implementationDate?: string;
    /**
     * 
     * @type {number}
     * @memberof InfantTwoYearOld
     */
    year?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantTwoYearOld
     */
    month?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantTwoYearOld
     */
    photo1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantTwoYearOld
     */
    photo2?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InfantTwoYearOld
     */
    bodyWeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantTwoYearOld
     */
    height?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantTwoYearOld
     */
    kaupIndex?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantTwoYearOld
     */
    headCircumference?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantTwoYearOld
     */
    nutritionalStatus?: InfantTwoYearOldNutritionalStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantTwoYearOld
     */
    eyeAbnormalities?: InfantTwoYearOldEyeAbnormalitiesEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantTwoYearOld
     */
    eyeAbnormalitiesNote?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantTwoYearOld
     */
    earAbnormalities?: InfantTwoYearOldEarAbnormalitiesEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantTwoYearOld
     */
    earAbnormalitiesNote?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantTwoYearOld
     */
    healthObservation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantTwoYearOld
     */
    remarks?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantTwoYearOld
     */
    facilityOrPersonName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantTwoYearOld
     */
    dayCaregiver?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantTwoYearOld
     */
    affectedDental?: InfantTwoYearOldAffectedDentalEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantTwoYearOld
     */
    cariesRequiringTreatment?: InfantTwoYearOldCariesRequiringTreatmentEnum;
    /**
     * 
     * @type {number}
     * @memberof InfantTwoYearOld
     */
    milkTeeth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantTwoYearOld
     */
    permanentTeeth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantTwoYearOld
     */
    total?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantTwoYearOld
     */
    dirtyTeeth?: InfantTwoYearOldDirtyTeethEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantTwoYearOld
     */
    gingivaMucousMembrane?: InfantTwoYearOldGingivaMucousMembraneEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantTwoYearOld
     */
    gingivaMucousMembraneNote?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantTwoYearOld
     */
    engagement?: InfantTwoYearOldEngagementEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantTwoYearOld
     */
    examinationDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantTwoYearOld
     */
    rightMolarsMaxilla?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantTwoYearOld
     */
    leftFrontToothMaxilla?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantTwoYearOld
     */
    rightMolarsLowerJaw?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantTwoYearOld
     */
    leftFrontToothLowerJaw?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantTwoYearOld
     */
    parentMessage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantTwoYearOld
     */
    recordingDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantTwoYearOld
     */
    canRun?: InfantTwoYearOldCanRunEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantTwoYearOld
     */
    eatWithSpoon?: InfantTwoYearOldEatWithSpoonEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantTwoYearOld
     */
    playMakeSomething?: InfantTwoYearOldPlayMakeSomethingEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantTwoYearOld
     */
    imitateTV?: InfantTwoYearOldImitateTVEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantTwoYearOld
     */
    sayTwoWord?: InfantTwoYearOldSayTwoWordEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantTwoYearOld
     */
    eatMeatAndVegetables?: InfantTwoYearOldEatMeatAndVegetablesEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantTwoYearOld
     */
    brushYourTeeth?: InfantTwoYearOldBrushYourTeethEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantTwoYearOld
     */
    kindOfPlay?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantTwoYearOld
     */
    childRearing?: InfantTwoYearOldChildRearingEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantTwoYearOld
     */
    raisingChildren?: InfantTwoYearOldRaisingChildrenEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantTwoYearOld
     */
    yourGrowth?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantTwoYearOld
     */
    photo3?: string | null;
}


/**
 * @export
 */
export const InfantTwoYearOldNutritionalStatusEnum = {
    Good: 'good',
    InstructionRequired: 'instructionRequired'
} as const;
export type InfantTwoYearOldNutritionalStatusEnum = typeof InfantTwoYearOldNutritionalStatusEnum[keyof typeof InfantTwoYearOldNutritionalStatusEnum];

/**
 * @export
 */
export const InfantTwoYearOldEyeAbnormalitiesEnum = {
    None: 'none',
    CanBe: 'canBe',
    Suspicion: 'suspicion'
} as const;
export type InfantTwoYearOldEyeAbnormalitiesEnum = typeof InfantTwoYearOldEyeAbnormalitiesEnum[keyof typeof InfantTwoYearOldEyeAbnormalitiesEnum];

/**
 * @export
 */
export const InfantTwoYearOldEarAbnormalitiesEnum = {
    None: 'none',
    CanBe: 'canBe',
    Suspicion: 'suspicion'
} as const;
export type InfantTwoYearOldEarAbnormalitiesEnum = typeof InfantTwoYearOldEarAbnormalitiesEnum[keyof typeof InfantTwoYearOldEarAbnormalitiesEnum];

/**
 * @export
 */
export const InfantTwoYearOldAffectedDentalEnum = {
    O1: 'o1',
    O2: 'o2',
    A: 'a',
    B: 'b',
    C: 'c'
} as const;
export type InfantTwoYearOldAffectedDentalEnum = typeof InfantTwoYearOldAffectedDentalEnum[keyof typeof InfantTwoYearOldAffectedDentalEnum];

/**
 * @export
 */
export const InfantTwoYearOldCariesRequiringTreatmentEnum = {
    None: 'none',
    CanBe: 'canBe',
    NotClear: 'notClear'
} as const;
export type InfantTwoYearOldCariesRequiringTreatmentEnum = typeof InfantTwoYearOldCariesRequiringTreatmentEnum[keyof typeof InfantTwoYearOldCariesRequiringTreatmentEnum];

/**
 * @export
 */
export const InfantTwoYearOldDirtyTeethEnum = {
    Beautiful: 'beautiful',
    Few: 'few',
    Many: 'many'
} as const;
export type InfantTwoYearOldDirtyTeethEnum = typeof InfantTwoYearOldDirtyTeethEnum[keyof typeof InfantTwoYearOldDirtyTeethEnum];

/**
 * @export
 */
export const InfantTwoYearOldGingivaMucousMembraneEnum = {
    NoAbnormality: 'noAbnormality',
    CanBe: 'canBe',
    NotClear: 'notClear'
} as const;
export type InfantTwoYearOldGingivaMucousMembraneEnum = typeof InfantTwoYearOldGingivaMucousMembraneEnum[keyof typeof InfantTwoYearOldGingivaMucousMembraneEnum];

/**
 * @export
 */
export const InfantTwoYearOldEngagementEnum = {
    Good: 'good',
    FollowUp: 'followUp',
    NotClear: 'notClear'
} as const;
export type InfantTwoYearOldEngagementEnum = typeof InfantTwoYearOldEngagementEnum[keyof typeof InfantTwoYearOldEngagementEnum];

/**
 * @export
 */
export const InfantTwoYearOldCanRunEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantTwoYearOldCanRunEnum = typeof InfantTwoYearOldCanRunEnum[keyof typeof InfantTwoYearOldCanRunEnum];

/**
 * @export
 */
export const InfantTwoYearOldEatWithSpoonEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantTwoYearOldEatWithSpoonEnum = typeof InfantTwoYearOldEatWithSpoonEnum[keyof typeof InfantTwoYearOldEatWithSpoonEnum];

/**
 * @export
 */
export const InfantTwoYearOldPlayMakeSomethingEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantTwoYearOldPlayMakeSomethingEnum = typeof InfantTwoYearOldPlayMakeSomethingEnum[keyof typeof InfantTwoYearOldPlayMakeSomethingEnum];

/**
 * @export
 */
export const InfantTwoYearOldImitateTVEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantTwoYearOldImitateTVEnum = typeof InfantTwoYearOldImitateTVEnum[keyof typeof InfantTwoYearOldImitateTVEnum];

/**
 * @export
 */
export const InfantTwoYearOldSayTwoWordEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantTwoYearOldSayTwoWordEnum = typeof InfantTwoYearOldSayTwoWordEnum[keyof typeof InfantTwoYearOldSayTwoWordEnum];

/**
 * @export
 */
export const InfantTwoYearOldEatMeatAndVegetablesEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantTwoYearOldEatMeatAndVegetablesEnum = typeof InfantTwoYearOldEatMeatAndVegetablesEnum[keyof typeof InfantTwoYearOldEatMeatAndVegetablesEnum];

/**
 * @export
 */
export const InfantTwoYearOldBrushYourTeethEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantTwoYearOldBrushYourTeethEnum = typeof InfantTwoYearOldBrushYourTeethEnum[keyof typeof InfantTwoYearOldBrushYourTeethEnum];

/**
 * @export
 */
export const InfantTwoYearOldChildRearingEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantTwoYearOldChildRearingEnum = typeof InfantTwoYearOldChildRearingEnum[keyof typeof InfantTwoYearOldChildRearingEnum];

/**
 * @export
 */
export const InfantTwoYearOldRaisingChildrenEnum = {
    No: 'no',
    Yes: 'yes',
    CanNotSayAnything: 'canNotSayAnything'
} as const;
export type InfantTwoYearOldRaisingChildrenEnum = typeof InfantTwoYearOldRaisingChildrenEnum[keyof typeof InfantTwoYearOldRaisingChildrenEnum];


/**
 * Check if a given object implements the InfantTwoYearOld interface.
 */
export function instanceOfInfantTwoYearOld(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InfantTwoYearOldFromJSON(json: any): InfantTwoYearOld {
    return InfantTwoYearOldFromJSONTyped(json, false);
}

export function InfantTwoYearOldFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfantTwoYearOld {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'babyId': !exists(json, 'babyId') ? undefined : json['babyId'],
        'ageId': !exists(json, 'ageId') ? undefined : json['ageId'],
        'implementationDate': !exists(json, 'implementationDate') ? undefined : json['implementationDate'],
        'year': !exists(json, 'year') ? undefined : json['year'],
        'month': !exists(json, 'month') ? undefined : json['month'],
        'photo1': !exists(json, 'photo1') ? undefined : json['photo1'],
        'photo2': !exists(json, 'photo2') ? undefined : json['photo2'],
        'bodyWeight': !exists(json, 'bodyWeight') ? undefined : json['bodyWeight'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'kaupIndex': !exists(json, 'kaupIndex') ? undefined : json['kaupIndex'],
        'headCircumference': !exists(json, 'headCircumference') ? undefined : json['headCircumference'],
        'nutritionalStatus': !exists(json, 'nutritionalStatus') ? undefined : json['nutritionalStatus'],
        'eyeAbnormalities': !exists(json, 'eyeAbnormalities') ? undefined : json['eyeAbnormalities'],
        'eyeAbnormalitiesNote': !exists(json, 'eyeAbnormalitiesNote') ? undefined : json['eyeAbnormalitiesNote'],
        'earAbnormalities': !exists(json, 'earAbnormalities') ? undefined : json['earAbnormalities'],
        'earAbnormalitiesNote': !exists(json, 'earAbnormalitiesNote') ? undefined : json['earAbnormalitiesNote'],
        'healthObservation': !exists(json, 'healthObservation') ? undefined : json['healthObservation'],
        'remarks': !exists(json, 'remarks') ? undefined : json['remarks'],
        'facilityOrPersonName': !exists(json, 'facilityOrPersonName') ? undefined : json['facilityOrPersonName'],
        'dayCaregiver': !exists(json, 'dayCaregiver') ? undefined : json['dayCaregiver'],
        'affectedDental': !exists(json, 'affectedDental') ? undefined : json['affectedDental'],
        'cariesRequiringTreatment': !exists(json, 'cariesRequiringTreatment') ? undefined : json['cariesRequiringTreatment'],
        'milkTeeth': !exists(json, 'milkTeeth') ? undefined : json['milkTeeth'],
        'permanentTeeth': !exists(json, 'permanentTeeth') ? undefined : json['permanentTeeth'],
        'total': !exists(json, 'total') ? undefined : json['total'],
        'dirtyTeeth': !exists(json, 'dirtyTeeth') ? undefined : json['dirtyTeeth'],
        'gingivaMucousMembrane': !exists(json, 'gingivaMucousMembrane') ? undefined : json['gingivaMucousMembrane'],
        'gingivaMucousMembraneNote': !exists(json, 'gingivaMucousMembraneNote') ? undefined : json['gingivaMucousMembraneNote'],
        'engagement': !exists(json, 'engagement') ? undefined : json['engagement'],
        'examinationDate': !exists(json, 'examinationDate') ? undefined : json['examinationDate'],
        'rightMolarsMaxilla': !exists(json, 'rightMolarsMaxilla') ? undefined : json['rightMolarsMaxilla'],
        'leftFrontToothMaxilla': !exists(json, 'leftFrontToothMaxilla') ? undefined : json['leftFrontToothMaxilla'],
        'rightMolarsLowerJaw': !exists(json, 'rightMolarsLowerJaw') ? undefined : json['rightMolarsLowerJaw'],
        'leftFrontToothLowerJaw': !exists(json, 'leftFrontToothLowerJaw') ? undefined : json['leftFrontToothLowerJaw'],
        'parentMessage': !exists(json, 'parentMessage') ? undefined : json['parentMessage'],
        'recordingDate': !exists(json, 'recordingDate') ? undefined : json['recordingDate'],
        'canRun': !exists(json, 'canRun') ? undefined : json['canRun'],
        'eatWithSpoon': !exists(json, 'eatWithSpoon') ? undefined : json['eatWithSpoon'],
        'playMakeSomething': !exists(json, 'playMakeSomething') ? undefined : json['playMakeSomething'],
        'imitateTV': !exists(json, 'imitateTV') ? undefined : json['imitateTV'],
        'sayTwoWord': !exists(json, 'sayTwoWord') ? undefined : json['sayTwoWord'],
        'eatMeatAndVegetables': !exists(json, 'eatMeatAndVegetables') ? undefined : json['eatMeatAndVegetables'],
        'brushYourTeeth': !exists(json, 'brushYourTeeth') ? undefined : json['brushYourTeeth'],
        'kindOfPlay': !exists(json, 'kindOfPlay') ? undefined : json['kindOfPlay'],
        'childRearing': !exists(json, 'childRearing') ? undefined : json['childRearing'],
        'raisingChildren': !exists(json, 'raisingChildren') ? undefined : json['raisingChildren'],
        'yourGrowth': !exists(json, 'yourGrowth') ? undefined : json['yourGrowth'],
        'photo3': !exists(json, 'photo3') ? undefined : json['photo3'],
    };
}

export function InfantTwoYearOldToJSON(value?: InfantTwoYearOld | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'userId': value.userId,
        'babyId': value.babyId,
        'ageId': value.ageId,
        'implementationDate': value.implementationDate,
        'year': value.year,
        'month': value.month,
        'photo1': value.photo1,
        'photo2': value.photo2,
        'bodyWeight': value.bodyWeight,
        'height': value.height,
        'kaupIndex': value.kaupIndex,
        'headCircumference': value.headCircumference,
        'nutritionalStatus': value.nutritionalStatus,
        'eyeAbnormalities': value.eyeAbnormalities,
        'eyeAbnormalitiesNote': value.eyeAbnormalitiesNote,
        'earAbnormalities': value.earAbnormalities,
        'earAbnormalitiesNote': value.earAbnormalitiesNote,
        'healthObservation': value.healthObservation,
        'remarks': value.remarks,
        'facilityOrPersonName': value.facilityOrPersonName,
        'dayCaregiver': value.dayCaregiver,
        'affectedDental': value.affectedDental,
        'cariesRequiringTreatment': value.cariesRequiringTreatment,
        'milkTeeth': value.milkTeeth,
        'permanentTeeth': value.permanentTeeth,
        'total': value.total,
        'dirtyTeeth': value.dirtyTeeth,
        'gingivaMucousMembrane': value.gingivaMucousMembrane,
        'gingivaMucousMembraneNote': value.gingivaMucousMembraneNote,
        'engagement': value.engagement,
        'examinationDate': value.examinationDate,
        'rightMolarsMaxilla': value.rightMolarsMaxilla,
        'leftFrontToothMaxilla': value.leftFrontToothMaxilla,
        'rightMolarsLowerJaw': value.rightMolarsLowerJaw,
        'leftFrontToothLowerJaw': value.leftFrontToothLowerJaw,
        'parentMessage': value.parentMessage,
        'recordingDate': value.recordingDate,
        'canRun': value.canRun,
        'eatWithSpoon': value.eatWithSpoon,
        'playMakeSomething': value.playMakeSomething,
        'imitateTV': value.imitateTV,
        'sayTwoWord': value.sayTwoWord,
        'eatMeatAndVegetables': value.eatMeatAndVegetables,
        'brushYourTeeth': value.brushYourTeeth,
        'kindOfPlay': value.kindOfPlay,
        'childRearing': value.childRearing,
        'raisingChildren': value.raisingChildren,
        'yourGrowth': value.yourGrowth,
        'photo3': value.photo3,
    };
}

