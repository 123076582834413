/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InfantThreeToFourMonthOld,
    InfantThreeToFourMonthOldFromJSON,
    InfantThreeToFourMonthOldToJSON,
    InfantThreeToFourMonthOldPagination,
    InfantThreeToFourMonthOldPaginationFromJSON,
    InfantThreeToFourMonthOldPaginationToJSON,
} from '../models';

export interface AdminInfantThreeToFourMonthOldsIdGetRequest {
    id: number;
    userId?: number;
}

export interface InfantThreeToFourMonthOldsGetRequest {
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface InfantThreeToFourMonthOldsIdDeleteRequest {
    id: number;
}

export interface InfantThreeToFourMonthOldsIdGetRequest {
    id: number;
}

export interface InfantThreeToFourMonthOldsIdPutRequest {
    id: number;
    infantThreeToFourMonthOld?: InfantThreeToFourMonthOld;
}

export interface InfantThreeToFourMonthOldsPostRequest {
    infantThreeToFourMonthOld?: InfantThreeToFourMonthOld;
}

export interface InfantThreeToFourMonthOldsShareAllGetRequest {
    sourceUserId: number;
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface InfantThreeToFourMonthOldsShareDetailGetRequest {
    recordId: number;
    sourceUserId: number;
}

/**
 * 
 */
export class InfantThreeToFourMonthOldsApi extends runtime.BaseAPI {

    /**
     * 
     */
    adminInfantThreeToFourMonthOldsIdGetRaw = async (requestParameters: AdminInfantThreeToFourMonthOldsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantThreeToFourMonthOld>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling adminInfantThreeToFourMonthOldsIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/infantThreeToFourMonthOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantThreeToFourMonthOldFromJSON(jsonValue));
    }

    /**
     * 
     */
    adminInfantThreeToFourMonthOldsIdGet = async (requestParameters: AdminInfantThreeToFourMonthOldsIdGetRequest, initOverrides?: RequestInit): Promise<InfantThreeToFourMonthOld> => {
        const response = await this.adminInfantThreeToFourMonthOldsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantThreeToFourMonthOldsGetRaw = async (requestParameters: InfantThreeToFourMonthOldsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantThreeToFourMonthOldPagination>> => {
        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling infantThreeToFourMonthOldsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantThreeToFourMonthOlds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantThreeToFourMonthOldPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantThreeToFourMonthOldsGet = async (requestParameters: InfantThreeToFourMonthOldsGetRequest, initOverrides?: RequestInit): Promise<InfantThreeToFourMonthOldPagination> => {
        const response = await this.infantThreeToFourMonthOldsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    infantThreeToFourMonthOldsIdDeleteRaw = async (requestParameters: InfantThreeToFourMonthOldsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling infantThreeToFourMonthOldsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantThreeToFourMonthOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    infantThreeToFourMonthOldsIdDelete = async (requestParameters: InfantThreeToFourMonthOldsIdDeleteRequest, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.infantThreeToFourMonthOldsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantThreeToFourMonthOldsIdGetRaw = async (requestParameters: InfantThreeToFourMonthOldsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantThreeToFourMonthOld>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling infantThreeToFourMonthOldsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantThreeToFourMonthOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantThreeToFourMonthOldFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantThreeToFourMonthOldsIdGet = async (requestParameters: InfantThreeToFourMonthOldsIdGetRequest, initOverrides?: RequestInit): Promise<InfantThreeToFourMonthOld> => {
        const response = await this.infantThreeToFourMonthOldsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    infantThreeToFourMonthOldsIdPutRaw = async (requestParameters: InfantThreeToFourMonthOldsIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantThreeToFourMonthOld>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling infantThreeToFourMonthOldsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/infantThreeToFourMonthOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InfantThreeToFourMonthOldToJSON(requestParameters.infantThreeToFourMonthOld),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantThreeToFourMonthOldFromJSON(jsonValue));
    }

    /**
     */
    infantThreeToFourMonthOldsIdPut = async (requestParameters: InfantThreeToFourMonthOldsIdPutRequest, initOverrides?: RequestInit): Promise<InfantThreeToFourMonthOld> => {
        const response = await this.infantThreeToFourMonthOldsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    infantThreeToFourMonthOldsPostRaw = async (requestParameters: InfantThreeToFourMonthOldsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantThreeToFourMonthOld>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/infantThreeToFourMonthOlds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InfantThreeToFourMonthOldToJSON(requestParameters.infantThreeToFourMonthOld),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantThreeToFourMonthOldFromJSON(jsonValue));
    }

    /**
     */
    infantThreeToFourMonthOldsPost = async (requestParameters: InfantThreeToFourMonthOldsPostRequest = {}, initOverrides?: RequestInit): Promise<InfantThreeToFourMonthOld> => {
        const response = await this.infantThreeToFourMonthOldsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantThreeToFourMonthOldsShareAllGetRaw = async (requestParameters: InfantThreeToFourMonthOldsShareAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantThreeToFourMonthOldPagination>> => {
        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling infantThreeToFourMonthOldsShareAllGet.');
        }

        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling infantThreeToFourMonthOldsShareAllGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantThreeToFourMonthOlds/share/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantThreeToFourMonthOldPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantThreeToFourMonthOldsShareAllGet = async (requestParameters: InfantThreeToFourMonthOldsShareAllGetRequest, initOverrides?: RequestInit): Promise<InfantThreeToFourMonthOldPagination> => {
        const response = await this.infantThreeToFourMonthOldsShareAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantThreeToFourMonthOldsShareDetailGetRaw = async (requestParameters: InfantThreeToFourMonthOldsShareDetailGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantThreeToFourMonthOld>> => {
        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling infantThreeToFourMonthOldsShareDetailGet.');
        }

        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling infantThreeToFourMonthOldsShareDetailGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.recordId !== undefined) {
            queryParameters['recordId'] = requestParameters.recordId;
        }

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantThreeToFourMonthOlds/share/detail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantThreeToFourMonthOldFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantThreeToFourMonthOldsShareDetailGet = async (requestParameters: InfantThreeToFourMonthOldsShareDetailGetRequest, initOverrides?: RequestInit): Promise<InfantThreeToFourMonthOld> => {
        const response = await this.infantThreeToFourMonthOldsShareDetailGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
