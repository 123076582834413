import React from "react";
import {
  AvatarCustom,
  BloodSelectionType,
  TeethSelectCustom,
  TextBlockCustom,
} from "@/components/display";
import { Grid } from "@mui/material";
import { COLORS } from "@/constants";
import { InfantMedicalExam, infantNineToTenMonthOldsApi } from "@/api";
import { useQuery } from "react-query";
import { toJapanDate } from "@/utils";
import { translate } from "@/helpers";

interface IInfantNineToTenMonthOldDetailProps {
  infantMedicalExamItem?: InfantMedicalExam;
}

export const InfantNineToTenMonthOldDetail = (
  props: React.PropsWithChildren<IInfantNineToTenMonthOldDetailProps>
) => {
  const { infantMedicalExamItem } = props;

  //--- Get medical consultant
  const { data: infantNineToTenMonthOld } = useQuery({
    queryKey: "GET_INFANT_NINE_TO_TEN_MONTH_OLD_BY_ID",
    queryFn: () => {
      return infantNineToTenMonthOldsApi.adminInfantNineToTenMonthOldsIdGet({
        id: infantMedicalExamItem?.infantId,
        userId: infantMedicalExamItem?.userId,
      });
    },
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextBlockCustom
          classText="font-bold text-center mb-4"
          textHeader={`${translate("infantMedicalExam.text.age7")} ${translate(
            "infantMedicalExam.text.detail"
          )}`}
          textSize={18}
          headerColor={COLORS.primary}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.implementationDate"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantNineToTenMonthOld?.implementationDate
                  ? toJapanDate(infantNineToTenMonthOld?.implementationDate)
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.ageMonth"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                (infantNineToTenMonthOld?.month
                  ? `${infantNineToTenMonthOld?.month}${translate("month.txt")}`
                  : "") +
                (infantNineToTenMonthOld?.day
                  ? `${infantNineToTenMonthOld?.day}${translate(
                      "infoHealthMon.day"
                    )}`
                  : "")
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.photographOfMedical"
              )}
              headerColor={COLORS.approxGrey}
            />

            <div className="flex items-center">
              {infantNineToTenMonthOld?.photo1 && (
                <div className="mr-2">
                  <AvatarCustom imgSrc={infantNineToTenMonthOld?.photo1} />
                </div>
              )}

              {infantNineToTenMonthOld?.photo2 && (
                <AvatarCustom imgSrc={infantNineToTenMonthOld?.photo2} />
              )}
            </div>
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.bodyWeight"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantNineToTenMonthOld?.bodyWeight
                  ? infantNineToTenMonthOld?.bodyWeight.toString() + "g"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.height")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantNineToTenMonthOld?.height
                  ? infantNineToTenMonthOld?.height.toString() + "cm"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.kaupIndex"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantNineToTenMonthOld?.kaupIndex
                  ? infantNineToTenMonthOld?.kaupIndex.toString()
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.chestCircumference"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantNineToTenMonthOld?.chestCircumference
                  ? infantNineToTenMonthOld?.chestCircumference.toString() +
                    "cm"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.headCircumference"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantNineToTenMonthOld?.headCircumference
                  ? infantNineToTenMonthOld?.headCircumference.toString() + "cm"
                  : ""
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          {/* 栄養状態/健康・要観察/特記事項など */}
          <Grid item xs={12}>
            <TextBlockCustom
              classText="font-bold"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.nutritionalHealth"
              )}
              headerColor={COLORS.primary}
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.nutritionalStatus"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantNineToTenMonthOld?.nutritionalStatus
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantNineToTenMonthOld?.nutritionalStatus
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.babyFoodOneDay"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantNineToTenMonthOld?.babyFood
                  ? `${infantNineToTenMonthOld?.babyFood.toString()}${translate(
                      "title.times"
                    )}`
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.teeth")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantNineToTenMonthOld?.teeth
                  ? `${infantNineToTenMonthOld?.teeth.toString()}${translate(
                      "title.book"
                    )}`
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.diseasesAndAbnormalitiesMouth"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantNineToTenMonthOld?.diseasesAndAbnormalitiesMouth
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantNineToTenMonthOld?.diseasesAndAbnormalitiesMouth
                    )
                  : ""
              }
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantNineToTenMonthOld?.diseasesAndAbnormalitiesMouthDesc
                  ? infantNineToTenMonthOld?.diseasesAndAbnormalitiesMouthDesc
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.healthObservation"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantNineToTenMonthOld?.healthObservation
                  ? infantNineToTenMonthOld?.healthObservation
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.remarks")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantNineToTenMonthOld?.remarks
                  ? infantNineToTenMonthOld?.remarks
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.facilityOrPersonName"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantNineToTenMonthOld?.facilityOrPersonName
                  ? infantNineToTenMonthOld?.facilityOrPersonName
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.dayCaregiver"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantNineToTenMonthOld?.dayCaregiver
                  ? infantNineToTenMonthOld?.dayCaregiver
                  : ""
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          {/* 保護者の記録（9~10か月頃）*/}
          <Grid item xs={12}>
            <TextBlockCustom
              classText="font-bold"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.parentRecordNineToTen"
              )}
              headerColor={COLORS.primary}
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.recordingDate"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantNineToTenMonthOld?.recordingDate
                  ? toJapanDate(infantNineToTenMonthOld?.recordingDate)
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.whenCrawl"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                (infantNineToTenMonthOld?.whenCrawlMonth
                  ? `${infantNineToTenMonthOld?.whenCrawlMonth}${translate(
                      "infoHealthMon.moon"
                    )}`
                  : "0" + translate("infoHealthMon.moon")) +
                (infantNineToTenMonthOld?.whenCrawlDay !== undefined
                  ? `${infantNineToTenMonthOld?.whenCrawlDay}${translate(
                      "title.everyday"
                    )}`
                  : "0" + translate("title.everyday"))
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.whenCaught"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                (infantNineToTenMonthOld?.whenCaughtMonth
                  ? `${infantNineToTenMonthOld?.whenCaughtMonth}${translate(
                      "infoHealthMon.moon"
                    )}`
                  : "") +
                (infantNineToTenMonthOld?.whenCaughtDay
                  ? `${infantNineToTenMonthOld?.whenCaughtDay}${translate(
                      "title.everyday"
                    )}`
                  : "0" + translate("title.everyday"))
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.yourFingers"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantNineToTenMonthOld?.yourFingers
                  ? translate(
                      `infantMedicalExamFormScreen.checkbox.${infantNineToTenMonthOld?.yourFingers}`
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.playAlone"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantNineToTenMonthOld?.playAlone
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantNineToTenMonthOld?.playAlone
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.weaningSmoothly"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantNineToTenMonthOld?.weaning
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantNineToTenMonthOld?.weaning
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.turnAround"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantNineToTenMonthOld?.turnAround
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantNineToTenMonthOld?.turnAround
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.followUp"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantNineToTenMonthOld?.followUp
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantNineToTenMonthOld?.followUp
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.concerns"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantNineToTenMonthOld?.concerns
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantNineToTenMonthOld?.concerns
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.childRearing"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantNineToTenMonthOld?.childRearing
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantNineToTenMonthOld?.childRearing
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.raisingChildren"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantNineToTenMonthOld?.raisingChildren
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantNineToTenMonthOld?.raisingChildren
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.yourGrowth"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantNineToTenMonthOld?.yourGrowth
                  ? infantNineToTenMonthOld?.yourGrowth
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.beginOfTeeth"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantNineToTenMonthOld?.beginOfTeeth
                  ? infantNineToTenMonthOld?.beginOfTeeth.toString() +
                    translate("month.txt")
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.abnormalTeeth"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.maxilla")}
              headerColor={COLORS.approxGrey}
            />

            <TeethSelectCustom
              fieldType={BloodSelectionType.Checkbox}
              fieldDescriptions={["E", "D", "C", "B", "A"]}
              initialValues={
                !!infantNineToTenMonthOld?.rightMolarsMaxilla &&
                JSON.parse(infantNineToTenMonthOld.rightMolarsMaxilla)
              }
            />

            <TeethSelectCustom
              fieldType={BloodSelectionType.Checkbox}
              fieldDescriptions={["A", "B", "C", "D", "E"]}
              initialValues={
                !!infantNineToTenMonthOld?.leftFrontToothMaxilla &&
                JSON.parse(infantNineToTenMonthOld?.leftFrontToothMaxilla)
              }
            />

            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.lowerJaw"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TeethSelectCustom
              fieldType={BloodSelectionType.Checkbox}
              fieldDescriptions={["E", "D", "C", "B", "A"]}
              initialValues={
                !!infantNineToTenMonthOld?.rightMolarsLowerJaw &&
                JSON.parse(infantNineToTenMonthOld?.rightMolarsLowerJaw)
              }
            />

            <TeethSelectCustom
              fieldType={BloodSelectionType.Checkbox}
              fieldDescriptions={["A", "B", "C", "D", "E"]}
              initialValues={
                !!infantNineToTenMonthOld?.leftFrontToothLowerJaw &&
                JSON.parse(infantNineToTenMonthOld?.leftFrontToothLowerJaw)
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.photo")}
              headerColor={COLORS.approxGrey}
            />

            {infantNineToTenMonthOld?.photo3 && (
              <AvatarCustom imgSrc={infantNineToTenMonthOld?.photo3} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
