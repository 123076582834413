/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";

export interface ExportParamsRequest {
  names: string[];
}

/**
 *
 */
export class ExportApi extends runtime.BaseAPI {
  /**
   */
  adminExportFileGetRaw = async (
    requestParameters: ExportParamsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Blob>> => {
    const queryParameters: any = {};

    if (requestParameters.names !== undefined) {
      queryParameters["names"] = requestParameters.names;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/admin/exportFile`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.BlobApiResponse(response);
  };

  /**
   */
  adminExportFileGet = async (
    requestParameters: ExportParamsRequest,
    initOverrides?: RequestInit
  ): Promise<Blob> => {
    const response = await this.adminExportFileGetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  };
}
