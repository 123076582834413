/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AdminRoleAllOf } from './AdminRoleAllOf';
import {
    AdminRoleAllOfFromJSON,
    AdminRoleAllOfFromJSONTyped,
    AdminRoleAllOfToJSON,
} from './AdminRoleAllOf';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';

/**
 * 
 * @export
 * @interface AdminRole
 */
export interface AdminRole {
    /**
     * 
     * @type {number}
     * @memberof AdminRole
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminRole
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRole
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminRole
     */
    roleName?: string;
}

/**
 * Check if a given object implements the AdminRole interface.
 */
export function instanceOfAdminRole(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AdminRoleFromJSON(json: any): AdminRole {
    return AdminRoleFromJSONTyped(json, false);
}

export function AdminRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminRole {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'roleName': !exists(json, 'roleName') ? undefined : json['roleName'],
    };
}

export function AdminRoleToJSON(value?: AdminRole | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'roleName': value.roleName,
    };
}

