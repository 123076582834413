/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';
import type { BodyDataAllOf } from './BodyDataAllOf';
import {
    BodyDataAllOfFromJSON,
    BodyDataAllOfFromJSONTyped,
    BodyDataAllOfToJSON,
} from './BodyDataAllOf';

/**
 * 
 * @export
 * @interface BodyData
 */
export interface BodyData {
    /**
     * 
     * @type {number}
     * @memberof BodyData
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof BodyData
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof BodyData
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof BodyData
     */
    userId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BodyData
     */
    motherId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BodyData
     */
    weight?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BodyData
     */
    date?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BodyData
     */
    pregnantId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BodyData
     */
    fetusId?: number | null;
}

/**
 * Check if a given object implements the BodyData interface.
 */
export function instanceOfBodyData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BodyDataFromJSON(json: any): BodyData {
    return BodyDataFromJSONTyped(json, false);
}

export function BodyDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): BodyData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'motherId': !exists(json, 'motherId') ? undefined : json['motherId'],
        'weight': !exists(json, 'weight') ? undefined : json['weight'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'pregnantId': !exists(json, 'pregnantId') ? undefined : json['pregnantId'],
        'fetusId': !exists(json, 'fetusId') ? undefined : json['fetusId'],
    };
}

export function BodyDataToJSON(value?: BodyData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'userId': value.userId,
        'motherId': value.motherId,
        'weight': value.weight,
        'date': value.date,
        'pregnantId': value.pregnantId,
        'fetusId': value.fetusId,
    };
}

