/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MapLocationGetAll } from './MapLocationGetAll';
import {
    MapLocationGetAllFromJSON,
    MapLocationGetAllFromJSONTyped,
    MapLocationGetAllToJSON,
} from './MapLocationGetAll';

/**
 * 
 * @export
 * @interface MapLocationPagination
 */
export interface MapLocationPagination {
    /**
     * 
     * @type {string}
     * @memberof MapLocationPagination
     */
    nextPageCode?: string;
    /**
     * 
     * @type {Array<MapLocationGetAll>}
     * @memberof MapLocationPagination
     */
    listLocation?: Array<MapLocationGetAll>;
}

/**
 * Check if a given object implements the MapLocationPagination interface.
 */
export function instanceOfMapLocationPagination(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MapLocationPaginationFromJSON(json: any): MapLocationPagination {
    return MapLocationPaginationFromJSONTyped(json, false);
}

export function MapLocationPaginationFromJSONTyped(json: any, ignoreDiscriminator: boolean): MapLocationPagination {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nextPageCode': !exists(json, 'nextPageCode') ? undefined : json['nextPageCode'],
        'listLocation': !exists(json, 'listLocation') ? undefined : ((json['listLocation'] as Array<any>).map(MapLocationGetAllFromJSON)),
    };
}

export function MapLocationPaginationToJSON(value?: MapLocationPagination | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nextPageCode': value.nextPageCode,
        'listLocation': value.listLocation === undefined ? undefined : ((value.listLocation as Array<any>).map(MapLocationGetAllToJSON)),
    };
}

