/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Vaccine } from './Vaccine';
import {
    VaccineFromJSON,
    VaccineFromJSONTyped,
    VaccineToJSON,
} from './Vaccine';

/**
 * 
 * @export
 * @interface VaccineForBaby
 */
export interface VaccineForBaby {
    /**
     * 
     * @type {Vaccine}
     * @memberof VaccineForBaby
     */
    vaccine?: Vaccine;
    /**
     * 
     * @type {number}
     * @memberof VaccineForBaby
     */
    nextInject?: number;
}

/**
 * Check if a given object implements the VaccineForBaby interface.
 */
export function instanceOfVaccineForBaby(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VaccineForBabyFromJSON(json: any): VaccineForBaby {
    return VaccineForBabyFromJSONTyped(json, false);
}

export function VaccineForBabyFromJSONTyped(json: any, ignoreDiscriminator: boolean): VaccineForBaby {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vaccine': !exists(json, 'vaccine') ? undefined : VaccineFromJSON(json['vaccine']),
        'nextInject': !exists(json, 'nextInject') ? undefined : json['nextInject'],
    };
}

export function VaccineForBabyToJSON(value?: VaccineForBaby | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vaccine': VaccineToJSON(value.vaccine),
        'nextInject': value.nextInject,
    };
}

