import React, { useEffect, useState } from "react";
import {
  ButtonCustom,
  CardCustom,
  TextBlockCustom,
} from "@/components/display";
import { COLORS, noData } from "@/constants";
import { Grid } from "@mui/material";
import { recordSideEffectsApi } from "@/api";
import { useQuery } from "react-query";
import { toJapanDate } from "@/utils";
import { DialogSideEffects } from "./DialogSideEffects";

interface ISideEffectsProps {
  userId?: number;
  babyId?: number;
}

export const SideEffects = (
  props: React.PropsWithChildren<ISideEffectsProps>
) => {
  //--- Get vaccination records
  const [recordSideEffectsId, setRecordSideEffectId] = useState(0);

  const { data: recordSideEffects, refetch: refetchRecordSideEffect } =
    useQuery({
      queryKey: "GET_RECORD_SIDE_EFFECTS",
      queryFn: () => {
        return recordSideEffectsApi.adminRecordSideEffectsGet({
          userId: props.userId,
          babyId: props.babyId,
          orderBy: "dayOfSideEffects",
          orderType: "desc",
        });
      },
    });

  //--- Dialog
  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpenDialog = (id: number) => {
    setRecordSideEffectId(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setRecordSideEffectId(0);
  };

  useEffect(() => {
    if (props.userId > 0 && props.babyId > 0) {
      refetchRecordSideEffect();
    }
  }, [props.babyId, props.userId, refetchRecordSideEffect]);

  return (
    <>
      <CardCustom classCard={"mt-4"}>
        <TextBlockCustom
          classText="mb-4 font-bold"
          textHeader="副反応の記録"
          headerColor={COLORS.primary}
          textSize={16}
        />

        <Grid container spacing={3}>
          {recordSideEffects?.data.length > 0 ? (
            (recordSideEffects?.data || []).map((item, index) => (
              <Grid item xs={4} key={"medicalRecords_" + index}>
                <Grid container alignItems={"center"}>
                  <Grid item xs={10}>
                    <Grid container>
                      <Grid item xs={6}>
                        <TextBlockCustom
                          classText="mb-2"
                          textHeader="副反応があった日:"
                          headerColor={COLORS.approxGrey}
                        />
                        <TextBlockCustom
                          classText="mb-2"
                          textHeader="副反応があったワクチン:"
                          headerColor={COLORS.approxGrey}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextBlockCustom
                          classText="font-medium mb-2"
                          textHeader={toJapanDate(item?.dayOfSideEffects)}
                        />
                        <TextBlockCustom
                          classText="font-medium mb-2"
                          textHeader={item?.vaccineName}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2} justifySelf="flex-end">
                    <div className="flex justify-end">
                      <ButtonCustom
                        title="詳細"
                        onClick={() => handleClickOpenDialog(item?.id)}
                      />
                    </div>
                  </Grid>
                </Grid>
                <div className="divide-dot"></div>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <div className="text-center font-bold">{noData}</div>
            </Grid>
          )}
        </Grid>
      </CardCustom>

      <DialogSideEffects
        openDialog={openDialog}
        onCloseDialog={() => handleCloseDialog()}
        recordSideEffectsItem={recordSideEffects?.data?.find(
          (v) => v.id === recordSideEffectsId
        )}
      />
    </>
  );
};
