/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';
import type { FetusAllOf } from './FetusAllOf';
import {
    FetusAllOfFromJSON,
    FetusAllOfFromJSONTyped,
    FetusAllOfToJSON,
} from './FetusAllOf';

/**
 * 
 * @export
 * @interface Fetus
 */
export interface Fetus {
    /**
     * 
     * @type {number}
     * @memberof Fetus
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Fetus
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Fetus
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof Fetus
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof Fetus
     */
    childId?: number;
    /**
     * 
     * @type {number}
     * @memberof Fetus
     */
    motherId?: number;
    /**
     * 
     * @type {number}
     * @memberof Fetus
     */
    pregnantId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Fetus
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Fetus
     */
    gender?: FetusGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof Fetus
     */
    expectedBirthday?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Fetus
     */
    monProfession?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Fetus
     */
    monBirthday?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Fetus
     */
    dadProfession?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Fetus
     */
    dadBirthday?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Fetus
     */
    ortherGuardianRelation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Fetus
     */
    ortherGuardianProfession?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Fetus
     */
    ortherGuardianBirthday?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Fetus
     */
    prePregnancyMonHeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Fetus
     */
    prePregnancyMonWeight?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Fetus
     */
    image?: string | null;
}


/**
 * @export
 */
export const FetusGenderEnum = {
    Male: 'MALE',
    Female: 'FEMALE',
    Unknown: 'UNKNOWN'
} as const;
export type FetusGenderEnum = typeof FetusGenderEnum[keyof typeof FetusGenderEnum];


/**
 * Check if a given object implements the Fetus interface.
 */
export function instanceOfFetus(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FetusFromJSON(json: any): Fetus {
    return FetusFromJSONTyped(json, false);
}

export function FetusFromJSONTyped(json: any, ignoreDiscriminator: boolean): Fetus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'childId': !exists(json, 'childId') ? undefined : json['childId'],
        'motherId': !exists(json, 'motherId') ? undefined : json['motherId'],
        'pregnantId': !exists(json, 'pregnantId') ? undefined : json['pregnantId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'expectedBirthday': !exists(json, 'expectedBirthday') ? undefined : json['expectedBirthday'],
        'monProfession': !exists(json, 'monProfession') ? undefined : json['monProfession'],
        'monBirthday': !exists(json, 'monBirthday') ? undefined : json['monBirthday'],
        'dadProfession': !exists(json, 'dadProfession') ? undefined : json['dadProfession'],
        'dadBirthday': !exists(json, 'dadBirthday') ? undefined : json['dadBirthday'],
        'ortherGuardianRelation': !exists(json, 'ortherGuardianRelation') ? undefined : json['ortherGuardianRelation'],
        'ortherGuardianProfession': !exists(json, 'ortherGuardianProfession') ? undefined : json['ortherGuardianProfession'],
        'ortherGuardianBirthday': !exists(json, 'ortherGuardianBirthday') ? undefined : json['ortherGuardianBirthday'],
        'prePregnancyMonHeight': !exists(json, 'prePregnancyMonHeight') ? undefined : json['prePregnancyMonHeight'],
        'prePregnancyMonWeight': !exists(json, 'prePregnancyMonWeight') ? undefined : json['prePregnancyMonWeight'],
        'image': !exists(json, 'image') ? undefined : json['image'],
    };
}

export function FetusToJSON(value?: Fetus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'userId': value.userId,
        'childId': value.childId,
        'motherId': value.motherId,
        'pregnantId': value.pregnantId,
        'name': value.name,
        'gender': value.gender,
        'expectedBirthday': value.expectedBirthday,
        'monProfession': value.monProfession,
        'monBirthday': value.monBirthday,
        'dadProfession': value.dadProfession,
        'dadBirthday': value.dadBirthday,
        'ortherGuardianRelation': value.ortherGuardianRelation,
        'ortherGuardianProfession': value.ortherGuardianProfession,
        'ortherGuardianBirthday': value.ortherGuardianBirthday,
        'prePregnancyMonHeight': value.prePregnancyMonHeight,
        'prePregnancyMonWeight': value.prePregnancyMonWeight,
        'image': value.image,
    };
}

