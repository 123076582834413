/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Admin } from './Admin';
import {
    AdminFromJSON,
    AdminFromJSONTyped,
    AdminToJSON,
} from './Admin';

/**
 * 
 * @export
 * @interface AdminLoginResponse
 */
export interface AdminLoginResponse {
    /**
     * 
     * @type {string}
     * @memberof AdminLoginResponse
     */
    accessToken?: string;
    /**
     * 
     * @type {Admin}
     * @memberof AdminLoginResponse
     */
    admin?: Admin;
}

/**
 * Check if a given object implements the AdminLoginResponse interface.
 */
export function instanceOfAdminLoginResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AdminLoginResponseFromJSON(json: any): AdminLoginResponse {
    return AdminLoginResponseFromJSONTyped(json, false);
}

export function AdminLoginResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminLoginResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessToken': !exists(json, 'accessToken') ? undefined : json['accessToken'],
        'admin': !exists(json, 'admin') ? undefined : AdminFromJSON(json['admin']),
    };
}

export function AdminLoginResponseToJSON(value?: AdminLoginResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessToken': value.accessToken,
        'admin': AdminToJSON(value.admin),
    };
}

