/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InfantSixYearOld,
    InfantSixYearOldFromJSON,
    InfantSixYearOldToJSON,
    InfantSixYearOldPagination,
    InfantSixYearOldPaginationFromJSON,
    InfantSixYearOldPaginationToJSON,
} from '../models';

export interface AdminInfantSixYearOldsIdGetRequest {
    id: number;
    userId?: number;
}

export interface InfantSixYearOldsGetRequest {
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface InfantSixYearOldsIdDeleteRequest {
    id: number;
}

export interface InfantSixYearOldsIdGetRequest {
    id: number;
}

export interface InfantSixYearOldsIdPutRequest {
    id: number;
    infantSixYearOld?: InfantSixYearOld;
}

export interface InfantSixYearOldsPostRequest {
    infantSixYearOld?: InfantSixYearOld;
}

export interface InfantSixYearOldsShareAllGetRequest {
    sourceUserId: number;
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface InfantSixYearOldsShareDetailGetRequest {
    recordId: number;
    sourceUserId: number;
}

/**
 * 
 */
export class InfantSixYearOldsApi extends runtime.BaseAPI {

    /**
     * 
     */
    adminInfantSixYearOldsIdGetRaw = async (requestParameters: AdminInfantSixYearOldsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantSixYearOld>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling adminInfantSixYearOldsIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/infantSixYearOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantSixYearOldFromJSON(jsonValue));
    }

    /**
     * 
     */
    adminInfantSixYearOldsIdGet = async (requestParameters: AdminInfantSixYearOldsIdGetRequest, initOverrides?: RequestInit): Promise<InfantSixYearOld> => {
        const response = await this.adminInfantSixYearOldsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantSixYearOldsGetRaw = async (requestParameters: InfantSixYearOldsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantSixYearOldPagination>> => {
        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling infantSixYearOldsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantSixYearOlds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantSixYearOldPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantSixYearOldsGet = async (requestParameters: InfantSixYearOldsGetRequest, initOverrides?: RequestInit): Promise<InfantSixYearOldPagination> => {
        const response = await this.infantSixYearOldsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    infantSixYearOldsIdDeleteRaw = async (requestParameters: InfantSixYearOldsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling infantSixYearOldsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantSixYearOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    infantSixYearOldsIdDelete = async (requestParameters: InfantSixYearOldsIdDeleteRequest, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.infantSixYearOldsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantSixYearOldsIdGetRaw = async (requestParameters: InfantSixYearOldsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantSixYearOld>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling infantSixYearOldsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantSixYearOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantSixYearOldFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantSixYearOldsIdGet = async (requestParameters: InfantSixYearOldsIdGetRequest, initOverrides?: RequestInit): Promise<InfantSixYearOld> => {
        const response = await this.infantSixYearOldsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    infantSixYearOldsIdPutRaw = async (requestParameters: InfantSixYearOldsIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantSixYearOld>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling infantSixYearOldsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/infantSixYearOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InfantSixYearOldToJSON(requestParameters.infantSixYearOld),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantSixYearOldFromJSON(jsonValue));
    }

    /**
     */
    infantSixYearOldsIdPut = async (requestParameters: InfantSixYearOldsIdPutRequest, initOverrides?: RequestInit): Promise<InfantSixYearOld> => {
        const response = await this.infantSixYearOldsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    infantSixYearOldsPostRaw = async (requestParameters: InfantSixYearOldsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantSixYearOld>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/infantSixYearOlds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InfantSixYearOldToJSON(requestParameters.infantSixYearOld),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantSixYearOldFromJSON(jsonValue));
    }

    /**
     */
    infantSixYearOldsPost = async (requestParameters: InfantSixYearOldsPostRequest = {}, initOverrides?: RequestInit): Promise<InfantSixYearOld> => {
        const response = await this.infantSixYearOldsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantSixYearOldsShareAllGetRaw = async (requestParameters: InfantSixYearOldsShareAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantSixYearOldPagination>> => {
        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling infantSixYearOldsShareAllGet.');
        }

        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling infantSixYearOldsShareAllGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantSixYearOlds/share/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantSixYearOldPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantSixYearOldsShareAllGet = async (requestParameters: InfantSixYearOldsShareAllGetRequest, initOverrides?: RequestInit): Promise<InfantSixYearOldPagination> => {
        const response = await this.infantSixYearOldsShareAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantSixYearOldsShareDetailGetRaw = async (requestParameters: InfantSixYearOldsShareDetailGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantSixYearOld>> => {
        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling infantSixYearOldsShareDetailGet.');
        }

        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling infantSixYearOldsShareDetailGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.recordId !== undefined) {
            queryParameters['recordId'] = requestParameters.recordId;
        }

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantSixYearOlds/share/detail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantSixYearOldFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantSixYearOldsShareDetailGet = async (requestParameters: InfantSixYearOldsShareDetailGetRequest, initOverrides?: RequestInit): Promise<InfantSixYearOld> => {
        const response = await this.infantSixYearOldsShareDetailGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
