/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    NotificationCampaign,
    NotificationCampaignFromJSON,
    NotificationCampaignToJSON,
    NotificationCampaignPagination,
    NotificationCampaignPaginationFromJSON,
    NotificationCampaignPaginationToJSON,
} from '../models';

export interface AdminNotificationCampaignsGetRequest {
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
    keyword?: string;
}

export interface NotificationCampaignsGetRequest {
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
    keyword?: string;
}

export interface NotificationCampaignsIdDeleteRequest {
    id: number;
}

export interface NotificationCampaignsIdGetRequest {
    id: number;
}

export interface NotificationCampaignsIdPutRequest {
    id: number;
    notificationCampaign?: NotificationCampaign;
}

export interface NotificationCampaignsPostRequest {
    notificationCampaign?: NotificationCampaign;
}

/**
 * 
 */
export class NotificationCampaignsApi extends runtime.BaseAPI {

    /**
     * 
     */
    adminNotificationCampaignsGetRaw = async (requestParameters: AdminNotificationCampaignsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<NotificationCampaignPagination>> => {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.keyword !== undefined) {
            queryParameters['keyword'] = requestParameters.keyword;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/notificationCampaigns`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationCampaignPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    adminNotificationCampaignsGet = async (requestParameters: AdminNotificationCampaignsGetRequest = {}, initOverrides?: RequestInit): Promise<NotificationCampaignPagination> => {
        const response = await this.adminNotificationCampaignsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    notificationCampaignsGetRaw = async (requestParameters: NotificationCampaignsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<NotificationCampaignPagination>> => {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.keyword !== undefined) {
            queryParameters['keyword'] = requestParameters.keyword;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/notificationCampaigns`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationCampaignPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    notificationCampaignsGet = async (requestParameters: NotificationCampaignsGetRequest = {}, initOverrides?: RequestInit): Promise<NotificationCampaignPagination> => {
        const response = await this.notificationCampaignsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    notificationCampaignsIdDeleteRaw = async (requestParameters: NotificationCampaignsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling notificationCampaignsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/notificationCampaigns/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    notificationCampaignsIdDelete = async (requestParameters: NotificationCampaignsIdDeleteRequest, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.notificationCampaignsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    notificationCampaignsIdGetRaw = async (requestParameters: NotificationCampaignsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<NotificationCampaign>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling notificationCampaignsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/notificationCampaigns/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationCampaignFromJSON(jsonValue));
    }

    /**
     * 
     */
    notificationCampaignsIdGet = async (requestParameters: NotificationCampaignsIdGetRequest, initOverrides?: RequestInit): Promise<NotificationCampaign> => {
        const response = await this.notificationCampaignsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    notificationCampaignsIdPutRaw = async (requestParameters: NotificationCampaignsIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<NotificationCampaign>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling notificationCampaignsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/notificationCampaigns/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationCampaignToJSON(requestParameters.notificationCampaign),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationCampaignFromJSON(jsonValue));
    }

    /**
     */
    notificationCampaignsIdPut = async (requestParameters: NotificationCampaignsIdPutRequest, initOverrides?: RequestInit): Promise<NotificationCampaign> => {
        const response = await this.notificationCampaignsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    notificationCampaignsPostRaw = async (requestParameters: NotificationCampaignsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<NotificationCampaign>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/notificationCampaigns`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationCampaignToJSON(requestParameters.notificationCampaign),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NotificationCampaignFromJSON(jsonValue));
    }

    /**
     */
    notificationCampaignsPost = async (requestParameters: NotificationCampaignsPostRequest = {}, initOverrides?: RequestInit): Promise<NotificationCampaign> => {
        const response = await this.notificationCampaignsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
