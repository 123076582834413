/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';
import type { InfantOneMonthOldAllOf } from './InfantOneMonthOldAllOf';
import {
    InfantOneMonthOldAllOfFromJSON,
    InfantOneMonthOldAllOfFromJSONTyped,
    InfantOneMonthOldAllOfToJSON,
} from './InfantOneMonthOldAllOf';

/**
 * 
 * @export
 * @interface InfantOneMonthOld
 */
export interface InfantOneMonthOld {
    /**
     * 
     * @type {number}
     * @memberof InfantOneMonthOld
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InfantOneMonthOld
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof InfantOneMonthOld
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof InfantOneMonthOld
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof InfantOneMonthOld
     */
    babyId?: number;
    /**
     * 
     * @type {number}
     * @memberof InfantOneMonthOld
     */
    ageId?: number;
    /**
     * 
     * @type {string}
     * @memberof InfantOneMonthOld
     */
    implementationDate?: string;
    /**
     * 
     * @type {number}
     * @memberof InfantOneMonthOld
     */
    month?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantOneMonthOld
     */
    day?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneMonthOld
     */
    photo1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneMonthOld
     */
    photo2?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InfantOneMonthOld
     */
    bodyWeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantOneMonthOld
     */
    height?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantOneMonthOld
     */
    kaupIndex?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantOneMonthOld
     */
    chestCircumference?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantOneMonthOld
     */
    headCircumference?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneMonthOld
     */
    nutritionalStatus?: InfantOneMonthOldNutritionalStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneMonthOld
     */
    nutritionMethod?: InfantOneMonthOldNutritionMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneMonthOld
     */
    healthObservation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneMonthOld
     */
    remarks?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneMonthOld
     */
    facilityOrPersonName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneMonthOld
     */
    dayCaregiver?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneMonthOld
     */
    recordingDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneMonthOld
     */
    yourLimbs?: InfantOneMonthOldYourLimbsEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneMonthOld
     */
    drinkMilk?: InfantOneMonthOldDrinkMilkEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneMonthOld
     */
    loudNoise?: InfantOneMonthOldLoudNoiseEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneMonthOld
     */
    navel?: InfantOneMonthOldNavelEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneMonthOld
     */
    childRearing?: InfantOneMonthOldChildRearingEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneMonthOld
     */
    raisingChildren?: InfantOneMonthOldRaisingChildrenEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantOneMonthOld
     */
    yourGrowth?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantOneMonthOld
     */
    photo3?: string | null;
}


/**
 * @export
 */
export const InfantOneMonthOldNutritionalStatusEnum = {
    Good: 'good',
    InstructionRequired: 'instructionRequired'
} as const;
export type InfantOneMonthOldNutritionalStatusEnum = typeof InfantOneMonthOldNutritionalStatusEnum[keyof typeof InfantOneMonthOldNutritionalStatusEnum];

/**
 * @export
 */
export const InfantOneMonthOldNutritionMethodEnum = {
    BreastMilk: 'breastMilk',
    Mixture: 'mixture',
    ArtificialMilk: 'artificialMilk'
} as const;
export type InfantOneMonthOldNutritionMethodEnum = typeof InfantOneMonthOldNutritionMethodEnum[keyof typeof InfantOneMonthOldNutritionMethodEnum];

/**
 * @export
 */
export const InfantOneMonthOldYourLimbsEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantOneMonthOldYourLimbsEnum = typeof InfantOneMonthOldYourLimbsEnum[keyof typeof InfantOneMonthOldYourLimbsEnum];

/**
 * @export
 */
export const InfantOneMonthOldDrinkMilkEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantOneMonthOldDrinkMilkEnum = typeof InfantOneMonthOldDrinkMilkEnum[keyof typeof InfantOneMonthOldDrinkMilkEnum];

/**
 * @export
 */
export const InfantOneMonthOldLoudNoiseEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantOneMonthOldLoudNoiseEnum = typeof InfantOneMonthOldLoudNoiseEnum[keyof typeof InfantOneMonthOldLoudNoiseEnum];

/**
 * @export
 */
export const InfantOneMonthOldNavelEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantOneMonthOldNavelEnum = typeof InfantOneMonthOldNavelEnum[keyof typeof InfantOneMonthOldNavelEnum];

/**
 * @export
 */
export const InfantOneMonthOldChildRearingEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantOneMonthOldChildRearingEnum = typeof InfantOneMonthOldChildRearingEnum[keyof typeof InfantOneMonthOldChildRearingEnum];

/**
 * @export
 */
export const InfantOneMonthOldRaisingChildrenEnum = {
    No: 'no',
    Yes: 'yes',
    CanNotSayAnything: 'canNotSayAnything'
} as const;
export type InfantOneMonthOldRaisingChildrenEnum = typeof InfantOneMonthOldRaisingChildrenEnum[keyof typeof InfantOneMonthOldRaisingChildrenEnum];


/**
 * Check if a given object implements the InfantOneMonthOld interface.
 */
export function instanceOfInfantOneMonthOld(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InfantOneMonthOldFromJSON(json: any): InfantOneMonthOld {
    return InfantOneMonthOldFromJSONTyped(json, false);
}

export function InfantOneMonthOldFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfantOneMonthOld {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'babyId': !exists(json, 'babyId') ? undefined : json['babyId'],
        'ageId': !exists(json, 'ageId') ? undefined : json['ageId'],
        'implementationDate': !exists(json, 'implementationDate') ? undefined : json['implementationDate'],
        'month': !exists(json, 'month') ? undefined : json['month'],
        'day': !exists(json, 'day') ? undefined : json['day'],
        'photo1': !exists(json, 'photo1') ? undefined : json['photo1'],
        'photo2': !exists(json, 'photo2') ? undefined : json['photo2'],
        'bodyWeight': !exists(json, 'bodyWeight') ? undefined : json['bodyWeight'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'kaupIndex': !exists(json, 'kaupIndex') ? undefined : json['kaupIndex'],
        'chestCircumference': !exists(json, 'chestCircumference') ? undefined : json['chestCircumference'],
        'headCircumference': !exists(json, 'headCircumference') ? undefined : json['headCircumference'],
        'nutritionalStatus': !exists(json, 'nutritionalStatus') ? undefined : json['nutritionalStatus'],
        'nutritionMethod': !exists(json, 'nutritionMethod') ? undefined : json['nutritionMethod'],
        'healthObservation': !exists(json, 'healthObservation') ? undefined : json['healthObservation'],
        'remarks': !exists(json, 'remarks') ? undefined : json['remarks'],
        'facilityOrPersonName': !exists(json, 'facilityOrPersonName') ? undefined : json['facilityOrPersonName'],
        'dayCaregiver': !exists(json, 'dayCaregiver') ? undefined : json['dayCaregiver'],
        'recordingDate': !exists(json, 'recordingDate') ? undefined : json['recordingDate'],
        'yourLimbs': !exists(json, 'yourLimbs') ? undefined : json['yourLimbs'],
        'drinkMilk': !exists(json, 'drinkMilk') ? undefined : json['drinkMilk'],
        'loudNoise': !exists(json, 'loudNoise') ? undefined : json['loudNoise'],
        'navel': !exists(json, 'navel') ? undefined : json['navel'],
        'childRearing': !exists(json, 'childRearing') ? undefined : json['childRearing'],
        'raisingChildren': !exists(json, 'raisingChildren') ? undefined : json['raisingChildren'],
        'yourGrowth': !exists(json, 'yourGrowth') ? undefined : json['yourGrowth'],
        'photo3': !exists(json, 'photo3') ? undefined : json['photo3'],
    };
}

export function InfantOneMonthOldToJSON(value?: InfantOneMonthOld | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'userId': value.userId,
        'babyId': value.babyId,
        'ageId': value.ageId,
        'implementationDate': value.implementationDate,
        'month': value.month,
        'day': value.day,
        'photo1': value.photo1,
        'photo2': value.photo2,
        'bodyWeight': value.bodyWeight,
        'height': value.height,
        'kaupIndex': value.kaupIndex,
        'chestCircumference': value.chestCircumference,
        'headCircumference': value.headCircumference,
        'nutritionalStatus': value.nutritionalStatus,
        'nutritionMethod': value.nutritionMethod,
        'healthObservation': value.healthObservation,
        'remarks': value.remarks,
        'facilityOrPersonName': value.facilityOrPersonName,
        'dayCaregiver': value.dayCaregiver,
        'recordingDate': value.recordingDate,
        'yourLimbs': value.yourLimbs,
        'drinkMilk': value.drinkMilk,
        'loudNoise': value.loudNoise,
        'navel': value.navel,
        'childRearing': value.childRearing,
        'raisingChildren': value.raisingChildren,
        'yourGrowth': value.yourGrowth,
        'photo3': value.photo3,
    };
}

