/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetMapLocationForBaby,
    GetMapLocationForBabyFromJSON,
    GetMapLocationForBabyToJSON,
    GetPhoneRequest,
    GetPhoneRequestFromJSON,
    GetPhoneRequestToJSON,
    GetPhoneResponse,
    GetPhoneResponseFromJSON,
    GetPhoneResponseToJSON,
    MapLocationPagination,
    MapLocationPaginationFromJSON,
    MapLocationPaginationToJSON,
} from '../models';

export interface MapLocationsForBabyBabyIdGetRequest {
    babyId: number;
}

export interface MapLocationsGetRequest {
    keyword?: string;
    nextPageToken?: string;
}

export interface MapLocationsIdDeleteRequest {
    id: number;
}

export interface MapLocationsPhoneNumberPlaceIdGetRequest {
    placeId: string;
}

export interface MapLocationsPhoneNumberPostRequest {
    getPhoneRequest?: GetPhoneRequest;
}

export interface MapLocationsPostRequest {
    getMapLocationForBaby?: GetMapLocationForBaby;
}

/**
 * 
 */
export class MapLocationsApi extends runtime.BaseAPI {

    /**
     * 
     */
    mapLocationsForBabyBabyIdGetRaw = async (requestParameters: MapLocationsForBabyBabyIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetMapLocationForBaby>> => {
        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling mapLocationsForBabyBabyIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/mapLocations/forBaby/:babyId`.replace(`${":babyId"}`, encodeURIComponent(String(requestParameters.babyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMapLocationForBabyFromJSON(jsonValue));
    }

    /**
     * 
     */
    mapLocationsForBabyBabyIdGet = async (requestParameters: MapLocationsForBabyBabyIdGetRequest, initOverrides?: RequestInit): Promise<GetMapLocationForBaby> => {
        const response = await this.mapLocationsForBabyBabyIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    mapLocationsGetRaw = async (requestParameters: MapLocationsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MapLocationPagination>> => {
        const queryParameters: any = {};

        if (requestParameters.keyword !== undefined) {
            queryParameters['keyword'] = requestParameters.keyword;
        }

        if (requestParameters.nextPageToken !== undefined) {
            queryParameters['nextPageToken'] = requestParameters.nextPageToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/mapLocations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MapLocationPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    mapLocationsGet = async (requestParameters: MapLocationsGetRequest = {}, initOverrides?: RequestInit): Promise<MapLocationPagination> => {
        const response = await this.mapLocationsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    mapLocationsIdDeleteRaw = async (requestParameters: MapLocationsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling mapLocationsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/mapLocations/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    mapLocationsIdDelete = async (requestParameters: MapLocationsIdDeleteRequest, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.mapLocationsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    mapLocationsPhoneNumberPlaceIdGetRaw = async (requestParameters: MapLocationsPhoneNumberPlaceIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetPhoneResponse>> => {
        if (requestParameters.placeId === null || requestParameters.placeId === undefined) {
            throw new runtime.RequiredError('placeId','Required parameter requestParameters.placeId was null or undefined when calling mapLocationsPhoneNumberPlaceIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/mapLocations/phoneNumber/:placeId`.replace(`${":placeId"}`, encodeURIComponent(String(requestParameters.placeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPhoneResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    mapLocationsPhoneNumberPlaceIdGet = async (requestParameters: MapLocationsPhoneNumberPlaceIdGetRequest, initOverrides?: RequestInit): Promise<GetPhoneResponse> => {
        const response = await this.mapLocationsPhoneNumberPlaceIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    mapLocationsPhoneNumberPostRaw = async (requestParameters: MapLocationsPhoneNumberPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetPhoneResponse>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/mapLocations/phoneNumber`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetPhoneRequestToJSON(requestParameters.getPhoneRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPhoneResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    mapLocationsPhoneNumberPost = async (requestParameters: MapLocationsPhoneNumberPostRequest = {}, initOverrides?: RequestInit): Promise<GetPhoneResponse> => {
        const response = await this.mapLocationsPhoneNumberPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    mapLocationsPostRaw = async (requestParameters: MapLocationsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetMapLocationForBaby>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/mapLocations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetMapLocationForBabyToJSON(requestParameters.getMapLocationForBaby),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetMapLocationForBabyFromJSON(jsonValue));
    }

    /**
     * 
     */
    mapLocationsPost = async (requestParameters: MapLocationsPostRequest = {}, initOverrides?: RequestInit): Promise<GetMapLocationForBaby> => {
        const response = await this.mapLocationsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
