import { COLORS } from "@/constants";
import { Avatar } from "@mui/material";
import React from "react";
import styled from "styled-components";

const StyledAvatarCustom = styled.div``;

interface IAvatarCustomProps {
  imgSrc?: string;
}

export const AvatarCustom = (
  props: React.PropsWithChildren<IAvatarCustomProps>
) => {
  return (
    <StyledAvatarCustom>
      {props?.imgSrc ? (
        <Avatar
          sx={{
            bgcolor: COLORS.primary,
            width: 100,
            height: 100,
            borderRadius: "5px",
          }}
          variant="square"
          src={props?.imgSrc}
        ></Avatar>
      ) : (
        <Avatar
          sx={{
            bgcolor: "#FBF8F9",
            width: 100,
            height: 100,
            border: "1px solid #FEE4E8",
            borderRadius: "5px",
          }}
          variant="square"
          imgProps={{
            style: {
              objectFit: "contain",
              width: 46,
              height: 35,
            },
          }}
          src={"/img-default.svg"}
        ></Avatar>
      )}
    </StyledAvatarCustom>
  );
};
