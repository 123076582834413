import React from "react";
import { AvatarCustom, TextBlockCustom } from "@/components/display";
import { Grid } from "@mui/material";
import { COLORS } from "@/constants";
import {
  InfantMedicalExam,
  InfantSixToSevenMonthOldDiseasesAndAbnormalitiesMouthEnum,
  infantSixToSevenMonthOldsApi,
} from "@/api";
import { useQuery } from "react-query";
import { toJapanDate } from "@/utils";
import { translate } from "@/helpers";

interface IInfantSixToSevenMonthOldDetailProps {
  infantMedicalExamItem?: InfantMedicalExam;
}

export const InfantSixToSevenMonthOldDetail = (
  props: React.PropsWithChildren<IInfantSixToSevenMonthOldDetailProps>
) => {
  const { infantMedicalExamItem } = props;

  //--- Get medical consultant
  const { data: infantSixToSevenMonthOld } = useQuery({
    queryKey: "GET_INFANT_SIX_TO_SEVEN_MONTH_OLD_BY_ID",
    queryFn: () => {
      return infantSixToSevenMonthOldsApi.adminInfantSixToSevenMonthOldsIdGet({
        id: infantMedicalExamItem?.infantId,
        userId: infantMedicalExamItem?.userId,
      });
    },
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextBlockCustom
          classText="font-bold text-center mb-4"
          textHeader={`${translate("infantMedicalExam.text.age6")} ${translate(
            "infantMedicalExam.text.detail"
          )}`}
          textSize={18}
          headerColor={COLORS.primary}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.implementationDate"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantSixToSevenMonthOld?.implementationDate
                  ? toJapanDate(infantSixToSevenMonthOld?.implementationDate)
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.ageMonth"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                (infantSixToSevenMonthOld?.month
                  ? `${infantSixToSevenMonthOld?.month}か月`
                  : "") +
                (infantSixToSevenMonthOld?.day
                  ? `${infantSixToSevenMonthOld?.day}日`
                  : "")
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.photographOfMedical"
              )}
              headerColor={COLORS.approxGrey}
            />

            <div className="flex items-center">
              {infantSixToSevenMonthOld?.photo1 && (
                <div className="mr-2">
                  <AvatarCustom imgSrc={infantSixToSevenMonthOld?.photo1} />
                </div>
              )}

              {infantSixToSevenMonthOld?.photo2 && (
                <AvatarCustom imgSrc={infantSixToSevenMonthOld?.photo2} />
              )}
            </div>
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.bodyWeight"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixToSevenMonthOld?.bodyWeight
                  ? infantSixToSevenMonthOld?.bodyWeight.toString() + "g"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.height")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixToSevenMonthOld?.height
                  ? infantSixToSevenMonthOld?.height.toString() + "cm"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.kaupIndex"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixToSevenMonthOld?.kaupIndex
                  ? infantSixToSevenMonthOld?.kaupIndex.toString()
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.chestCircumference"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixToSevenMonthOld?.chestCircumference
                  ? infantSixToSevenMonthOld?.chestCircumference.toString() +
                    "cm"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.headCircumference"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixToSevenMonthOld?.headCircumference
                  ? infantSixToSevenMonthOld?.headCircumference.toString() +
                    "cm"
                  : ""
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          {/* 栄養状態/健康・要観察/特記事項など */}
          <Grid item xs={12}>
            <TextBlockCustom
              classText="font-bold"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.nutritionalHealth"
              )}
              headerColor={COLORS.primary}
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.nutritionalStatus"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantSixToSevenMonthOld?.nutritionalStatus
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantSixToSevenMonthOld?.nutritionalStatus
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.nutritionMethod"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantSixToSevenMonthOld?.nutritionMethod
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantSixToSevenMonthOld?.nutritionMethod
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.weaning")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantSixToSevenMonthOld?.weaning
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantSixToSevenMonthOld?.weaning
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.teeth")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantSixToSevenMonthOld?.teeth
                  ? `${infantSixToSevenMonthOld?.teeth.toString()}${translate(
                      "title.book"
                    )}`
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.diseasesAndAbnormalitiesMouth"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantSixToSevenMonthOld?.diseasesAndAbnormalitiesMouth
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantSixToSevenMonthOld?.diseasesAndAbnormalitiesMouth
                    )
                  : ""
              }
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantSixToSevenMonthOld?.diseasesAndAbnormalitiesMouthDesc &&
                infantSixToSevenMonthOld?.diseasesAndAbnormalitiesMouth ===
                  InfantSixToSevenMonthOldDiseasesAndAbnormalitiesMouthEnum.CanBe
                  ? infantSixToSevenMonthOld?.diseasesAndAbnormalitiesMouthDesc
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.healthObservation"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantSixToSevenMonthOld?.healthObservation
                  ? infantSixToSevenMonthOld?.healthObservation
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.remarks")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantSixToSevenMonthOld?.remarks
                  ? infantSixToSevenMonthOld?.remarks
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.facilityOrPersonName"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantSixToSevenMonthOld?.facilityOrPersonName
                  ? infantSixToSevenMonthOld?.facilityOrPersonName
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.dayCaregiver"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantSixToSevenMonthOld?.dayCaregiver
                  ? infantSixToSevenMonthOld?.dayCaregiver
                  : ""
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          {/* 保護者の記録（6~7か月頃）*/}
          <Grid item xs={12}>
            <TextBlockCustom
              classText="font-bold"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.parentRecordSixToSeven"
              )}
              headerColor={COLORS.primary}
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.recordingDate"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixToSevenMonthOld?.recordingDate
                  ? toJapanDate(infantSixToSevenMonthOld?.recordingDate)
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.turnOver"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                (infantSixToSevenMonthOld?.turnOverMonth
                  ? `${infantSixToSevenMonthOld?.turnOverMonth}${translate(
                      "infoHealthMon.moon"
                    )}`
                  : "") +
                (infantSixToSevenMonthOld?.turnOverDay
                  ? `${infantSixToSevenMonthOld?.turnOverDay}${translate(
                      "title.everyday"
                    )}`
                  : "")
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.sitAlone"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                (infantSixToSevenMonthOld?.sitAloneMonth
                  ? `${infantSixToSevenMonthOld?.sitAloneMonth}${translate(
                      "infoHealthMon.moon"
                    )}`
                  : "") +
                (infantSixToSevenMonthOld?.sitAloneDay
                  ? `${infantSixToSevenMonthOld?.sitAloneDay}${translate(
                      "title.everyday"
                    )}`
                  : "")
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.toyNearBody"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixToSevenMonthOld?.toyNearBody
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantSixToSevenMonthOld?.toyNearBody
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.talkingVoice"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixToSevenMonthOld?.talkingVoice
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantSixToSevenMonthOld?.talkingVoice
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.radioSound"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixToSevenMonthOld?.radioSound
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantSixToSevenMonthOld?.radioSound
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.babyFood"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixToSevenMonthOld?.babyFood
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantSixToSevenMonthOld?.babyFood
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.pupilAppear"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixToSevenMonthOld?.babyFood
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantSixToSevenMonthOld?.pupilAppear
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.childRearing"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixToSevenMonthOld?.childRearing
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantSixToSevenMonthOld?.childRearing
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.raisingChildren"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixToSevenMonthOld?.raisingChildren
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantSixToSevenMonthOld?.raisingChildren
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.yourGrowth"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixToSevenMonthOld?.yourGrowth
                  ? infantSixToSevenMonthOld?.yourGrowth
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.photo")}
              headerColor={COLORS.approxGrey}
            />

            <AvatarCustom imgSrc={infantSixToSevenMonthOld?.photo3} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
