/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';
import type { ChildrenBodyAllOf } from './ChildrenBodyAllOf';
import {
    ChildrenBodyAllOfFromJSON,
    ChildrenBodyAllOfFromJSONTyped,
    ChildrenBodyAllOfToJSON,
} from './ChildrenBodyAllOf';

/**
 * 
 * @export
 * @interface ChildrenBody
 */
export interface ChildrenBody {
    /**
     * 
     * @type {number}
     * @memberof ChildrenBody
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ChildrenBody
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildrenBody
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ChildrenBody
     */
    userId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ChildrenBody
     */
    babyId?: number;
    /**
     * 
     * @type {number}
     * @memberof ChildrenBody
     */
    motherId?: number;
    /**
     * 
     * @type {string}
     * @memberof ChildrenBody
     */
    dateFollow?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ChildrenBody
     */
    height?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ChildrenBody
     */
    weight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ChildrenBody
     */
    headCircumference?: number | null;
}

/**
 * Check if a given object implements the ChildrenBody interface.
 */
export function instanceOfChildrenBody(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChildrenBodyFromJSON(json: any): ChildrenBody {
    return ChildrenBodyFromJSONTyped(json, false);
}

export function ChildrenBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChildrenBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'babyId': !exists(json, 'babyId') ? undefined : json['babyId'],
        'motherId': !exists(json, 'motherId') ? undefined : json['motherId'],
        'dateFollow': !exists(json, 'dateFollow') ? undefined : json['dateFollow'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'weight': !exists(json, 'weight') ? undefined : json['weight'],
        'headCircumference': !exists(json, 'headCircumference') ? undefined : json['headCircumference'],
    };
}

export function ChildrenBodyToJSON(value?: ChildrenBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'userId': value.userId,
        'babyId': value.babyId,
        'motherId': value.motherId,
        'dateFollow': value.dateFollow,
        'height': value.height,
        'weight': value.weight,
        'headCircumference': value.headCircumference,
    };
}

