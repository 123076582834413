/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AppleAuthRequestBody,
    AppleAuthRequestBodyFromJSON,
    AppleAuthRequestBodyToJSON,
    AuthAppleRegisterPost201Response,
    AuthAppleRegisterPost201ResponseFromJSON,
    AuthAppleRegisterPost201ResponseToJSON,
    AuthChangePasswordRequestBody,
    AuthChangePasswordRequestBodyFromJSON,
    AuthChangePasswordRequestBodyToJSON,
    AuthLoginRequestBody,
    AuthLoginRequestBodyFromJSON,
    AuthLoginRequestBodyToJSON,
    AuthResetPasswordRequestBody,
    AuthResetPasswordRequestBodyFromJSON,
    AuthResetPasswordRequestBodyToJSON,
    AuthSendResetPassCodeRequestBody,
    AuthSendResetPassCodeRequestBodyFromJSON,
    AuthSendResetPassCodeRequestBodyToJSON,
    AuthVerifyEmailRequestBody,
    AuthVerifyEmailRequestBodyFromJSON,
    AuthVerifyEmailRequestBodyToJSON,
    DeviceTokenLogOut,
    DeviceTokenLogOutFromJSON,
    DeviceTokenLogOutToJSON,
    GoogleAuthRequestBody,
    GoogleAuthRequestBodyFromJSON,
    GoogleAuthRequestBodyToJSON,
    RegisterRequestBody,
    RegisterRequestBodyFromJSON,
    RegisterRequestBodyToJSON,
} from '../models';

export interface AuthAppleLinkPostRequest {
    appleAuthRequestBody?: AppleAuthRequestBody;
}

export interface AuthAppleLoginPostRequest {
    appleAuthRequestBody?: AppleAuthRequestBody;
}

export interface AuthAppleRegisterPostRequest {
    appleAuthRequestBody?: AppleAuthRequestBody;
}

export interface AuthChangePasswordPostRequest {
    authChangePasswordRequestBody?: AuthChangePasswordRequestBody;
}

export interface AuthGoogleLinkPostRequest {
    googleAuthRequestBody?: GoogleAuthRequestBody;
}

export interface AuthGoogleLoginPostRequest {
    googleAuthRequestBody?: GoogleAuthRequestBody;
}

export interface AuthGoogleRegisterPostRequest {
    googleAuthRequestBody?: GoogleAuthRequestBody;
}

export interface AuthLoginPostRequest {
    authLoginRequestBody?: AuthLoginRequestBody;
}

export interface AuthLogoutPostRequest {
    deviceTokenLogOut?: DeviceTokenLogOut;
}

export interface AuthRegisterPostRequest {
    registerRequestBody?: RegisterRequestBody;
}

export interface AuthResendEmailVerificationCodePostRequest {
    authSendResetPassCodeRequestBody?: AuthSendResetPassCodeRequestBody;
}

export interface AuthResetPasswordPostRequest {
    authResetPasswordRequestBody?: AuthResetPasswordRequestBody;
}

export interface AuthSendEmailVerificationCodePostRequest {
    authSendResetPassCodeRequestBody?: AuthSendResetPassCodeRequestBody;
}

export interface AuthSendResetPasswordCodePostRequest {
    authSendResetPassCodeRequestBody?: AuthSendResetPassCodeRequestBody;
}

export interface AuthVerifyEmailPostRequest {
    authVerifyEmailRequestBody?: AuthVerifyEmailRequestBody;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     */
    authAppleLinkPostRaw = async (requestParameters: AuthAppleLinkPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthAppleRegisterPost201Response>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/apple/link`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AppleAuthRequestBodyToJSON(requestParameters.appleAuthRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthAppleRegisterPost201ResponseFromJSON(jsonValue));
    }

    /**
     */
    authAppleLinkPost = async (requestParameters: AuthAppleLinkPostRequest = {}, initOverrides?: RequestInit): Promise<AuthAppleRegisterPost201Response> => {
        const response = await this.authAppleLinkPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    authAppleLoginPostRaw = async (requestParameters: AuthAppleLoginPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthAppleRegisterPost201Response>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/apple/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AppleAuthRequestBodyToJSON(requestParameters.appleAuthRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthAppleRegisterPost201ResponseFromJSON(jsonValue));
    }

    /**
     */
    authAppleLoginPost = async (requestParameters: AuthAppleLoginPostRequest = {}, initOverrides?: RequestInit): Promise<AuthAppleRegisterPost201Response> => {
        const response = await this.authAppleLoginPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    authAppleRegisterPostRaw = async (requestParameters: AuthAppleRegisterPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthAppleRegisterPost201Response>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/apple/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AppleAuthRequestBodyToJSON(requestParameters.appleAuthRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthAppleRegisterPost201ResponseFromJSON(jsonValue));
    }

    /**
     */
    authAppleRegisterPost = async (requestParameters: AuthAppleRegisterPostRequest = {}, initOverrides?: RequestInit): Promise<AuthAppleRegisterPost201Response> => {
        const response = await this.authAppleRegisterPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    authChangePasswordPostRaw = async (requestParameters: AuthChangePasswordPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<object>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/change-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthChangePasswordRequestBodyToJSON(requestParameters.authChangePasswordRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    authChangePasswordPost = async (requestParameters: AuthChangePasswordPostRequest = {}, initOverrides?: RequestInit): Promise<object> => {
        const response = await this.authChangePasswordPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    authGoogleLinkPostRaw = async (requestParameters: AuthGoogleLinkPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthAppleRegisterPost201Response>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/google/link`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GoogleAuthRequestBodyToJSON(requestParameters.googleAuthRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthAppleRegisterPost201ResponseFromJSON(jsonValue));
    }

    /**
     */
    authGoogleLinkPost = async (requestParameters: AuthGoogleLinkPostRequest = {}, initOverrides?: RequestInit): Promise<AuthAppleRegisterPost201Response> => {
        const response = await this.authGoogleLinkPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    authGoogleLoginPostRaw = async (requestParameters: AuthGoogleLoginPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthAppleRegisterPost201Response>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/google/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GoogleAuthRequestBodyToJSON(requestParameters.googleAuthRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthAppleRegisterPost201ResponseFromJSON(jsonValue));
    }

    /**
     */
    authGoogleLoginPost = async (requestParameters: AuthGoogleLoginPostRequest = {}, initOverrides?: RequestInit): Promise<AuthAppleRegisterPost201Response> => {
        const response = await this.authGoogleLoginPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    authGoogleRegisterPostRaw = async (requestParameters: AuthGoogleRegisterPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthAppleRegisterPost201Response>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/google/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GoogleAuthRequestBodyToJSON(requestParameters.googleAuthRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthAppleRegisterPost201ResponseFromJSON(jsonValue));
    }

    /**
     */
    authGoogleRegisterPost = async (requestParameters: AuthGoogleRegisterPostRequest = {}, initOverrides?: RequestInit): Promise<AuthAppleRegisterPost201Response> => {
        const response = await this.authGoogleRegisterPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    authLoginPostRaw = async (requestParameters: AuthLoginPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthAppleRegisterPost201Response>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthLoginRequestBodyToJSON(requestParameters.authLoginRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthAppleRegisterPost201ResponseFromJSON(jsonValue));
    }

    /**
     */
    authLoginPost = async (requestParameters: AuthLoginPostRequest = {}, initOverrides?: RequestInit): Promise<AuthAppleRegisterPost201Response> => {
        const response = await this.authLoginPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    authLogoutPostRaw = async (requestParameters: AuthLogoutPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/logout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeviceTokenLogOutToJSON(requestParameters.deviceTokenLogOut),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    authLogoutPost = async (requestParameters: AuthLogoutPostRequest = {}, initOverrides?: RequestInit): Promise<void> => {
        await this.authLogoutPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    authRegisterPostRaw = async (requestParameters: AuthRegisterPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthAppleRegisterPost201Response>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterRequestBodyToJSON(requestParameters.registerRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthAppleRegisterPost201ResponseFromJSON(jsonValue));
    }

    /**
     */
    authRegisterPost = async (requestParameters: AuthRegisterPostRequest = {}, initOverrides?: RequestInit): Promise<AuthAppleRegisterPost201Response> => {
        const response = await this.authRegisterPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    authResendEmailVerificationCodePostRaw = async (requestParameters: AuthResendEmailVerificationCodePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/resend-email-verification-code`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthSendResetPassCodeRequestBodyToJSON(requestParameters.authSendResetPassCodeRequestBody),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    authResendEmailVerificationCodePost = async (requestParameters: AuthResendEmailVerificationCodePostRequest = {}, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.authResendEmailVerificationCodePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    authResetPasswordPostRaw = async (requestParameters: AuthResetPasswordPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<object>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/reset-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthResetPasswordRequestBodyToJSON(requestParameters.authResetPasswordRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    authResetPasswordPost = async (requestParameters: AuthResetPasswordPostRequest = {}, initOverrides?: RequestInit): Promise<object> => {
        const response = await this.authResetPasswordPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    authSendEmailVerificationCodePostRaw = async (requestParameters: AuthSendEmailVerificationCodePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/send-email-verification-code`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthSendResetPassCodeRequestBodyToJSON(requestParameters.authSendResetPassCodeRequestBody),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    authSendEmailVerificationCodePost = async (requestParameters: AuthSendEmailVerificationCodePostRequest = {}, initOverrides?: RequestInit): Promise<void> => {
        await this.authSendEmailVerificationCodePostRaw(requestParameters, initOverrides);
    }

    /**
     */
    authSendResetPasswordCodePostRaw = async (requestParameters: AuthSendResetPasswordCodePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<object>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/send-reset-password-code`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthSendResetPassCodeRequestBodyToJSON(requestParameters.authSendResetPassCodeRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    authSendResetPasswordCodePost = async (requestParameters: AuthSendResetPasswordCodePostRequest = {}, initOverrides?: RequestInit): Promise<object> => {
        const response = await this.authSendResetPasswordCodePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    authVerifyEmailPostRaw = async (requestParameters: AuthVerifyEmailPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthAppleRegisterPost201Response>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/verify-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthVerifyEmailRequestBodyToJSON(requestParameters.authVerifyEmailRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthAppleRegisterPost201ResponseFromJSON(jsonValue));
    }

    /**
     */
    authVerifyEmailPost = async (requestParameters: AuthVerifyEmailPostRequest = {}, initOverrides?: RequestInit): Promise<AuthAppleRegisterPost201Response> => {
        const response = await this.authVerifyEmailPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
