export const getTextEdemaStatus = (text: string) => {
    switch (text) {
        case "+":
            return "+";
        case "-":
            return "-";
        case "±":
            return "±";
        case "++":
            return "++以上";
    }
};

export const getTextCervicalCancerScreening = (text: string) => {
    switch (text) {
        case "DETAILED_INSPECTION_REQUIRED":
            return "要精密検査";
        case "UNDECIDABLE":
            return "精密検査不要";
        default:
            return "判定不能/要再検査";
    }
};

export const getTextObstetricPathologies = (text: string) => {
    switch (text) {
        case "beQuiet":
            return "要安静";
        case "withBleeding":
            return "出血あり";
        case "preeclampsia":
            return "妊娠高血圧症候群";
        case "rest":
            return "休養";
        case "hungry":
            return "お腹の張りあり";
        case "anemia":
            return "貧血";
        case "gestationalDiabetes":
            return "妊娠糖尿病"
        case "placentaPrevia":
            return "前置胎盤";
        case "placentalAbruptionOfThePlacenta":
            return "常位胎盤早期剥離"
        case "internalInfections":
            return "体内感染症";
        case "withTherapeuticDrugAdministration":
            return "治療用の薬物投与あり";
        case "imminentPrematureBirth":
            return "切迫早産";
    }
};

export const getTextListIrregularAntibody = (text: string) => {
    switch (text) {
        case "+":
            return "陽性+";
        case "-":
            return "陰性-";
    }
};

export const getTextOtherIllnesses = (text: string) => {
    switch (text) {
        case "highBloodPressure":
            return "高血圧";
        case "chronicNephritis":
            return "慢性腎炎";
        case "diabetesMellitus":
            return "糖尿病";
        case "hepatitis":
            return "肝炎";
        case "heartDisease":
            return "心臓病";
        case "thyroidDisease":
            return "甲状腺の病気";
        case "mentalIllness":
            return "精神疾患(心の病気)";
        case "otherIllnesses":
            return "その他の病気";
    }
};

export const renderHealthOfMomCheckVaccinated = (text: string) => {
    switch (text) {
        case "BLANK":
            return "未記入";
        case "YES":
            return "はい";
        case "NO":
            return "いいえ";
        case "VACCINATED":
            return "予防接種を受けた";
    }
};

export const renderHealthOfMomAbility = (text: string) => {
    switch (text) {
        case "BLANK":
            return "未記入";
        case "NONE":
            return "なし";
        case "CAN_BE":
            return "あり";
        case "YES":
            return "はい";
    }
};

export const renderDrinkingHabitLevel = (text: string) => {
    switch (text) {
        case "EVERY_DAY":
            return "毎日";
        case "2~3_TIMES_A_WEEK":
            return "週２〜３回";
        case "SOMETIMES":
            return "時々";
    }
};

export const renderChildbirthStatus = (text: string) => {
    switch (text) {
        case "CAESAREAN_SECTION":
            return "帝王切開";
        case "SUCTION_DELIVERY":
            return "吸引分娩";
        case "NORMAL_DELIVERY":
            return "正常分娩";
    }
};

export const getTypeOfResidence = (text: string) => {
    switch (text) {
        case "BLANK":
            return "未記入";
        case "DETACHED_HOUSE":
            return "一戸建て";
        case "HOUSING_COMPLEX":
            return "集合住宅";
        case "OTHERS":
            return "その他";
    }
};

export const getTextNoise = (text: string) => {
    switch (text) {
        case "BLANK":
            return "未記入";
        case "QUIET":
            return "静";
        case "USUALLY":
            return "普通";
        case "NOISY":
            return "騒";
    }
};


export const getTextSunlight = (text: string) => {
    switch (text) {
        case "BLANK":
            return "未記入";
        case "GOOD":
            return "良";
        case "USUALLY":
            return "普通";
        case "EVIL":
            return "悪";
    }
};

export const getTextChildGender = (text: string) => {
    switch (text) {
        case "MALE":
            return "男の子";
        case "FEMALE":
            return "女の子";
        default:
            return "不明";
    }
};

export const getMatureGenderText = (text: string) => {
    switch (text) {
        case "MALE":
            return "男性";
        case "FEMALE":
            return "女性";
        default:
            return "不明";
    }
};

export const getTextDeliveryProcess = (text: string) => {
    switch (text) {
        case "headPosture":
            return "頭位";
        case "breech":
            return "骨盤位";
        case "others":
            return "その他";
    }
};

export const getTextAsphyxiaDescription = (text: string) => {
    switch (text) {
        case "severe":
            return "重症";
        case "mild":
            return "軽症";
    }
};

export const getTextNeonatalAsphyxia = (text: string) => {
    switch (text) {
        case "die":
            return "死亡";
        case "resuscitation":
            return "蘇生";
        case "stillbirth":
            return "死産";
    }
};


export const getTextEvidence = (text: string) => {
    switch (text) {
        case "birthCertificate":
            return "出生証明書";
        case "stillbirthCertificate":
            return "死産証書（死胎検案書)";
        case "birthDeathCertificate":
            return "出生証明書及び死亡診断書";
    }
};

export const getTextRotaVaccine = (text: string) => {
    switch (text) {
        case "ROTATEC":
            return "ロタリックス(1価)";
        case "ROTARIX":
            return "ロタテック(5価)";
    }
};

export const getTextIrregularRubellaAntibody = (text: string) => {
    switch (text) {
        case "VACCINATION_CONSULTATION_REQUIRED":
            return "予防接種要相談";
        default:
            return "免疫あり";
    }
};

export const getTextLiveTogether = (text: string) => {
    switch (text) {
        case "from":
            return "から";
        case "child":
            return "子ども";
        case "husband1":
            return "夫";
        case "husbandDad":
            return "夫の父";
        case "husbandMom":
            return "夫の母";
        case "dad1":
            return "実の父";
        case "mom1":
            return "実の母";
        case "to":
            return "まで";
        case "day":
            return "日間";
        case "man":
            return "人";
    }
};

export const getTextDeliveryStatus = (text: string) => {
    switch (text) {
        case "naturalChildbirth":
            return "自然分娩";
        case "other":
            return "その他";
        case "laborAnalgesia":
            return "無痛分娩";
        case "forcepsDelivery":
            return "鉗子分娩";
        case "suctionDelivery":
            return "吸引分娩";
        case "breechBirth":
            return "骨盤位（逆子)";
        case "caesareanSection":
            return "帝王切開";
        case "administration":
            return "陣痛促進剤投与";
        case "waterRupture":
            return "前早期破水";
        case "weakLabor":
            return "微弱陣痛";
        case "deliveryProcess":
            return "分娩経過";
        case "headPosture":
            return "頭位";
        case "breech":
            return "骨盤位";
        case "smallAmount":
            return "少量";
        case "mediumAmount":
            return "中量";
        case "largeAmount":
            return "多量";
        case "bleeding":
            return "出血";
        case "bloodTransfusion":
            return "輸血";
        case "maternityHospital":
            return "輸血";
        case "産院":
            return "輸血";
    }
};

export const getTextBleeding = (text: string) => {
    switch (text) {
        case "smallAmount":
            return "少量";
        case "mediumAmount":
            return "中量";
        case "largeAmount":
            return "多量";
    }
};

export const getTextYesNo = (text: string) => {
    switch (text) {
        case "none":
            return "無し";
        case "yes":
            return "有り";
    }
};

export const getTextLactation = (text: string) => {
    switch (text) {
        case "weak":
            return "弱い";
        case "usually":
            return "普通";
    }
};

export const getTextBodyPart = (text: string) => {
    switch (text) {
        case "RIGHT_ARM_UPPER_ARM_UPPER_SIDE":
            return "右腕：二の腕（上側）";
        case "RIGHT_ARM_UPPER_ARM_LOWER_SIDE":
            return "右腕：二の腕（下側、肘付近）";
        case "RIGHT_FOOT_CENTRAL_PART_OF_THIGH":
            return "右足：足太もも中央部";
        case "LEFT_ARM_UPPER_ARM_UPPER_SIDE":
            return "左腕：二の腕（上側）";
        case "LEFT_ARM_UPPER_ARM_LOWER_SIDE":
            return "左腕：二の腕（下側、肘付近)";
        case "LEFT_FOOT_CENTRAL_PART_OF_THIGH":
            return "左足：足太もも中央部";
        case "OTHERS":
            return "その他";
    }
};

export const getTextAgeData = (ageNumber: number) => {
    switch (ageNumber) {
        case 1:
            return "早期新生児期（生後1週間以内）の経過";
        case 2:
            return "後期新生児期の経過";
        case 3:
            return "検査の記録";
        case 4:
            return "1か月児健康診査";
        case 5:
            return "3〜4か月児健康診査";
        case 6:
            return "6〜7か月児健康診査";
        case 7:
            return "9〜10か月児健康診査";
        case 8:
            return "1歳児健康診査";
        case 9:
            return "1歳6か月児健康診査";
        case 10:
            return "2歳児健康診査";
        case 11:
            return "3歳児健康診査";
        case 12:
            return "4歳児健康診査";
        case 13:
            return "5歳児健康診査";
        case 14:
            return "6歳児健康診査";
    }
};