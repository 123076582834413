/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';
import type { InfantMedicalExamAllOf } from './InfantMedicalExamAllOf';
import {
    InfantMedicalExamAllOfFromJSON,
    InfantMedicalExamAllOfFromJSONTyped,
    InfantMedicalExamAllOfToJSON,
} from './InfantMedicalExamAllOf';

/**
 * 
 * @export
 * @interface InfantMedicalExam
 */
export interface InfantMedicalExam {
    /**
     * 
     * @type {number}
     * @memberof InfantMedicalExam
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InfantMedicalExam
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof InfantMedicalExam
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof InfantMedicalExam
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof InfantMedicalExam
     */
    babyId?: number;
    /**
     * 
     * @type {number}
     * @memberof InfantMedicalExam
     */
    infantId?: number;
    /**
     * 
     * @type {number}
     * @memberof InfantMedicalExam
     */
    ageId?: number;
    /**
     * 
     * @type {string}
     * @memberof InfantMedicalExam
     */
    examImplementDate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InfantMedicalExam
     */
    examAgeMonth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantMedicalExam
     */
    examAgeDay?: number | null;
}

/**
 * Check if a given object implements the InfantMedicalExam interface.
 */
export function instanceOfInfantMedicalExam(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InfantMedicalExamFromJSON(json: any): InfantMedicalExam {
    return InfantMedicalExamFromJSONTyped(json, false);
}

export function InfantMedicalExamFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfantMedicalExam {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'babyId': !exists(json, 'babyId') ? undefined : json['babyId'],
        'infantId': !exists(json, 'infantId') ? undefined : json['infantId'],
        'ageId': !exists(json, 'ageId') ? undefined : json['ageId'],
        'examImplementDate': !exists(json, 'examImplementDate') ? undefined : json['examImplementDate'],
        'examAgeMonth': !exists(json, 'examAgeMonth') ? undefined : json['examAgeMonth'],
        'examAgeDay': !exists(json, 'examAgeDay') ? undefined : json['examAgeDay'],
    };
}

export function InfantMedicalExamToJSON(value?: InfantMedicalExam | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'userId': value.userId,
        'babyId': value.babyId,
        'infantId': value.infantId,
        'ageId': value.ageId,
        'examImplementDate': value.examImplementDate,
        'examAgeMonth': value.examAgeMonth,
        'examAgeDay': value.examAgeDay,
    };
}

