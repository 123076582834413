import React from "react";
import { TextBlockCustom } from "@/components/display";
import { Grid } from "@mui/material";
import { COLORS } from "@/constants";
import {
  InfantAfterOneWeekOtherAbnormalitiesEnum,
  infantAfterOneWeeksApi,
  InfantMedicalExam,
} from "@/api";
import { useQuery } from "react-query";
import { toJapanDate } from "@/utils";
import { translate } from "@/helpers";

interface IInfantAfterOneWeekDetailProps {
  infantMedicalExamItem?: InfantMedicalExam;
}

export const InfantAfterOneWeekDetail = (
  props: React.PropsWithChildren<IInfantAfterOneWeekDetailProps>
) => {
  const { infantMedicalExamItem } = props;

  //--- Get medical consultant
  const { data: infantAfterOneWeek } = useQuery({
    queryKey: "GET_INFANT_AFTER_ONE_WEEK_BY_ID",
    queryFn: () => {
      return infantAfterOneWeeksApi.adminInfantAfterOneWeeksIdGet({
        id: infantMedicalExamItem?.infantId,
        userId: infantMedicalExamItem?.userId,
      });
    },
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextBlockCustom
          classText="font-bold text-center mb-4"
          textHeader={`${translate("infantMedicalExam.text.age1")} ${translate(
            "infantMedicalExam.text.detail"
          )}`}
          textSize={18}
          headerColor={COLORS.primary}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          {/* 1回目 */}
          <Grid item xs={12}>
            <TextBlockCustom
              classText="font-bold"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.firstTime"
              )}
              headerColor={COLORS.primary}
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.age")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantAfterOneWeek?.age1
                  ? infantAfterOneWeek?.age1?.toString() + "日"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.bodyWeight"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantAfterOneWeek?.bodyWeight1
                  ? infantAfterOneWeek?.bodyWeight1?.toString() + "g"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.breastFeedingPower"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantAfterOneWeek?.breastFeedingPower1
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantAfterOneWeek?.breastFeedingPower1
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.jaundice"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantAfterOneWeek?.jaundice1
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantAfterOneWeek?.jaundice1
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.other")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantAfterOneWeek?.others1 ? infantAfterOneWeek?.others1 : ""
              }
            />
          </Grid>

          {/* 退院時の記録 */}
          <Grid item xs={12}>
            <TextBlockCustom
              classText="font-bold"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.recordDischarge"
              )}
              headerColor={COLORS.primary}
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.dischargeDate"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantAfterOneWeek?.dischargeDate
                  ? toJapanDate(infantAfterOneWeek?.dischargeDate)
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.bodyWeight"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantAfterOneWeek?.bodyWeight3
                  ? infantAfterOneWeek?.bodyWeight3?.toString() + "g"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.nutritionMethod"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantAfterOneWeek?.nutritionMethod
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantAfterOneWeek?.nutritionMethod
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.requireObservation"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantAfterOneWeek?.requireObservation
                  ? infantAfterOneWeek?.requireObservation
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.facilityOrPersonName"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantAfterOneWeek?.facilityOrPersonName
                  ? infantAfterOneWeek?.facilityOrPersonName
                  : ""
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          {/* 2回目 */}
          <Grid item xs={12}>
            <TextBlockCustom
              classText="font-bold"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.secondTime"
              )}
              headerColor={COLORS.primary}
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.age")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantAfterOneWeek?.age2
                  ? infantAfterOneWeek?.age2?.toString() + "日"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.bodyWeight"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantAfterOneWeek?.bodyWeight2
                  ? infantAfterOneWeek?.bodyWeight2?.toString() + "g"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.breastFeedingPower"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantAfterOneWeek?.breastFeedingPower2
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantAfterOneWeek?.breastFeedingPower2
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.jaundice"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantAfterOneWeek?.jaundice2
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantAfterOneWeek?.jaundice2
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.other")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantAfterOneWeek?.others2 ? infantAfterOneWeek?.others2 : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.note2")}
              headerColor={COLORS.approxGrey}
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.date1")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantAfterOneWeek?.vitaminFirstDate
                  ? toJapanDate(infantAfterOneWeek?.vitaminFirstDate)
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.date2")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantAfterOneWeek?.vitaminSecondDate
                  ? toJapanDate(infantAfterOneWeek?.vitaminSecondDate)
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.date3")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantAfterOneWeek?.vitaminThirdDate
                  ? toJapanDate(infantAfterOneWeek?.vitaminThirdDate)
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.otherAbnormalities"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantAfterOneWeek?.otherAbnormalities
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantAfterOneWeek?.otherAbnormalities
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.abnormalContent"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantAfterOneWeek?.abnormalContent &&
                infantAfterOneWeek?.otherAbnormalities ===
                  InfantAfterOneWeekOtherAbnormalitiesEnum.CanBe
                  ? infantAfterOneWeek?.abnormalContent
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.treatment"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantAfterOneWeek?.treatment &&
                infantAfterOneWeek?.otherAbnormalities ===
                  InfantAfterOneWeekOtherAbnormalitiesEnum.CanBe
                  ? infantAfterOneWeek?.treatment
                  : ""
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
