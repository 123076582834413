/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';
import type { InfantEarlyNeonatalPeriodAllOf } from './InfantEarlyNeonatalPeriodAllOf';
import {
    InfantEarlyNeonatalPeriodAllOfFromJSON,
    InfantEarlyNeonatalPeriodAllOfFromJSONTyped,
    InfantEarlyNeonatalPeriodAllOfToJSON,
} from './InfantEarlyNeonatalPeriodAllOf';

/**
 * 
 * @export
 * @interface InfantEarlyNeonatalPeriod
 */
export interface InfantEarlyNeonatalPeriod {
    /**
     * 
     * @type {number}
     * @memberof InfantEarlyNeonatalPeriod
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InfantEarlyNeonatalPeriod
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof InfantEarlyNeonatalPeriod
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof InfantEarlyNeonatalPeriod
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof InfantEarlyNeonatalPeriod
     */
    babyId?: number;
    /**
     * 
     * @type {number}
     * @memberof InfantEarlyNeonatalPeriod
     */
    ageId?: number;
    /**
     * 
     * @type {number}
     * @memberof InfantEarlyNeonatalPeriod
     */
    age1?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantEarlyNeonatalPeriod
     */
    bodyWeight1?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantEarlyNeonatalPeriod
     */
    breastFeedingPower1?: InfantEarlyNeonatalPeriodBreastFeedingPower1Enum;
    /**
     * 
     * @type {string}
     * @memberof InfantEarlyNeonatalPeriod
     */
    nutritionMethod1?: InfantEarlyNeonatalPeriodNutritionMethod1Enum;
    /**
     * 
     * @type {string}
     * @memberof InfantEarlyNeonatalPeriod
     */
    others1?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InfantEarlyNeonatalPeriod
     */
    age2?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantEarlyNeonatalPeriod
     */
    bodyWeight2?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantEarlyNeonatalPeriod
     */
    breastFeedingPower2?: InfantEarlyNeonatalPeriodBreastFeedingPower2Enum;
    /**
     * 
     * @type {string}
     * @memberof InfantEarlyNeonatalPeriod
     */
    nutritionMethod2?: InfantEarlyNeonatalPeriodNutritionMethod2Enum;
    /**
     * 
     * @type {string}
     * @memberof InfantEarlyNeonatalPeriod
     */
    others2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantEarlyNeonatalPeriod
     */
    implementationDate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InfantEarlyNeonatalPeriod
     */
    age3?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantEarlyNeonatalPeriod
     */
    bodyWeight3?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantEarlyNeonatalPeriod
     */
    height?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantEarlyNeonatalPeriod
     */
    chestCircumference?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantEarlyNeonatalPeriod
     */
    headCircumference?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantEarlyNeonatalPeriod
     */
    nutritionMethod3?: InfantEarlyNeonatalPeriodNutritionMethod3Enum;
    /**
     * 
     * @type {string}
     * @memberof InfantEarlyNeonatalPeriod
     */
    facilityOrPersonName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantEarlyNeonatalPeriod
     */
    remarks?: string | null;
}


/**
 * @export
 */
export const InfantEarlyNeonatalPeriodBreastFeedingPower1Enum = {
    Usually: 'usually',
    Weak: 'weak'
} as const;
export type InfantEarlyNeonatalPeriodBreastFeedingPower1Enum = typeof InfantEarlyNeonatalPeriodBreastFeedingPower1Enum[keyof typeof InfantEarlyNeonatalPeriodBreastFeedingPower1Enum];

/**
 * @export
 */
export const InfantEarlyNeonatalPeriodNutritionMethod1Enum = {
    BreastMilk: 'breastMilk',
    Mixture: 'mixture',
    ArtificialMilk: 'artificialMilk'
} as const;
export type InfantEarlyNeonatalPeriodNutritionMethod1Enum = typeof InfantEarlyNeonatalPeriodNutritionMethod1Enum[keyof typeof InfantEarlyNeonatalPeriodNutritionMethod1Enum];

/**
 * @export
 */
export const InfantEarlyNeonatalPeriodBreastFeedingPower2Enum = {
    Usually: 'usually',
    Weak: 'weak'
} as const;
export type InfantEarlyNeonatalPeriodBreastFeedingPower2Enum = typeof InfantEarlyNeonatalPeriodBreastFeedingPower2Enum[keyof typeof InfantEarlyNeonatalPeriodBreastFeedingPower2Enum];

/**
 * @export
 */
export const InfantEarlyNeonatalPeriodNutritionMethod2Enum = {
    BreastMilk: 'breastMilk',
    Mixture: 'mixture',
    ArtificialMilk: 'artificialMilk'
} as const;
export type InfantEarlyNeonatalPeriodNutritionMethod2Enum = typeof InfantEarlyNeonatalPeriodNutritionMethod2Enum[keyof typeof InfantEarlyNeonatalPeriodNutritionMethod2Enum];

/**
 * @export
 */
export const InfantEarlyNeonatalPeriodNutritionMethod3Enum = {
    BreastMilk: 'breastMilk',
    Mixture: 'mixture',
    ArtificialMilk: 'artificialMilk'
} as const;
export type InfantEarlyNeonatalPeriodNutritionMethod3Enum = typeof InfantEarlyNeonatalPeriodNutritionMethod3Enum[keyof typeof InfantEarlyNeonatalPeriodNutritionMethod3Enum];


/**
 * Check if a given object implements the InfantEarlyNeonatalPeriod interface.
 */
export function instanceOfInfantEarlyNeonatalPeriod(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InfantEarlyNeonatalPeriodFromJSON(json: any): InfantEarlyNeonatalPeriod {
    return InfantEarlyNeonatalPeriodFromJSONTyped(json, false);
}

export function InfantEarlyNeonatalPeriodFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfantEarlyNeonatalPeriod {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'babyId': !exists(json, 'babyId') ? undefined : json['babyId'],
        'ageId': !exists(json, 'ageId') ? undefined : json['ageId'],
        'age1': !exists(json, 'age1') ? undefined : json['age1'],
        'bodyWeight1': !exists(json, 'bodyWeight1') ? undefined : json['bodyWeight1'],
        'breastFeedingPower1': !exists(json, 'breastFeedingPower1') ? undefined : json['breastFeedingPower1'],
        'nutritionMethod1': !exists(json, 'nutritionMethod1') ? undefined : json['nutritionMethod1'],
        'others1': !exists(json, 'others1') ? undefined : json['others1'],
        'age2': !exists(json, 'age2') ? undefined : json['age2'],
        'bodyWeight2': !exists(json, 'bodyWeight2') ? undefined : json['bodyWeight2'],
        'breastFeedingPower2': !exists(json, 'breastFeedingPower2') ? undefined : json['breastFeedingPower2'],
        'nutritionMethod2': !exists(json, 'nutritionMethod2') ? undefined : json['nutritionMethod2'],
        'others2': !exists(json, 'others2') ? undefined : json['others2'],
        'implementationDate': !exists(json, 'implementationDate') ? undefined : json['implementationDate'],
        'age3': !exists(json, 'age3') ? undefined : json['age3'],
        'bodyWeight3': !exists(json, 'bodyWeight3') ? undefined : json['bodyWeight3'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'chestCircumference': !exists(json, 'chestCircumference') ? undefined : json['chestCircumference'],
        'headCircumference': !exists(json, 'headCircumference') ? undefined : json['headCircumference'],
        'nutritionMethod3': !exists(json, 'nutritionMethod3') ? undefined : json['nutritionMethod3'],
        'facilityOrPersonName': !exists(json, 'facilityOrPersonName') ? undefined : json['facilityOrPersonName'],
        'remarks': !exists(json, 'remarks') ? undefined : json['remarks'],
    };
}

export function InfantEarlyNeonatalPeriodToJSON(value?: InfantEarlyNeonatalPeriod | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'userId': value.userId,
        'babyId': value.babyId,
        'ageId': value.ageId,
        'age1': value.age1,
        'bodyWeight1': value.bodyWeight1,
        'breastFeedingPower1': value.breastFeedingPower1,
        'nutritionMethod1': value.nutritionMethod1,
        'others1': value.others1,
        'age2': value.age2,
        'bodyWeight2': value.bodyWeight2,
        'breastFeedingPower2': value.breastFeedingPower2,
        'nutritionMethod2': value.nutritionMethod2,
        'others2': value.others2,
        'implementationDate': value.implementationDate,
        'age3': value.age3,
        'bodyWeight3': value.bodyWeight3,
        'height': value.height,
        'chestCircumference': value.chestCircumference,
        'headCircumference': value.headCircumference,
        'nutritionMethod3': value.nutritionMethod3,
        'facilityOrPersonName': value.facilityOrPersonName,
        'remarks': value.remarks,
    };
}

