import { Route } from 'react-router-dom';

import { ADMIN_PATH } from '@/constants/paths';

import { AdminListPage } from './admin-list';
import { ADMIN_LIST_PATH } from './admin-list/AdminListPage.shared';

export const renderAdminRoutes = () => (
    <Route path={ADMIN_PATH} >
        <Route path={ADMIN_LIST_PATH} element={<AdminListPage />} />
    </Route>
)