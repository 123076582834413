import React from "react";
import { childrenBodiesApi } from "@/api";
import { CardCustom, TextBlockCustom } from "@/components/display";
import { COLORS, noData } from "@/constants";
import { useQuery } from "react-query";
import { Grid } from "@mui/material";
import { toJapanDate } from "@/utils";

interface IChildBodyDataProps {
  userId?: number;
  babyId?: number;
}

export const ChildBodyData = (
  props: React.PropsWithChildren<IChildBodyDataProps>
) => {
  //--- Get body data
  const { data: childBodyData } = useQuery({
    queryKey: "GET_BODY_DATA",
    queryFn: () => {
      return childrenBodiesApi.adminChildrenBodiesGet({
        userId: props.userId,
        babyId: props.babyId,
        orderBy: "dateFollow",
        orderType: "desc",
      });
    },
  });

  return (
    <CardCustom classCard={"mt-4"}>
      <TextBlockCustom
        classText="mb-4 font-bold"
        textHeader="身体発育の記録"
        headerColor={COLORS.primary}
        textSize={16}
      />

      <Grid container spacing={3}>
        {childBodyData?.data?.length > 0 ? (
          (childBodyData?.data || []).map((item, index) => (
            <Grid item xs={4} key={"childBodyData_" + index}>
              <Grid container alignItems={"center"}>
                <Grid item xs={12}>
                  <TextBlockCustom
                    classText="font-medium mb-2"
                    textHeader={toJapanDate(item?.dateFollow)}
                  />

                  <TextBlockCustom
                    classText="mb-2"
                    textHeader={
                      item?.height && item?.weight && item?.headCircumference
                        ? `身長${item?.height?.toString() + "cm"} /体重${
                            item?.weight?.toString() + "kg"
                          } /頭囲${item?.headCircumference?.toString() + "cm"}`
                        : ""
                    }
                    headerColor={COLORS.approxGrey}
                  />
                </Grid>
              </Grid>
              <div className="divide-dot"></div>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <div className="text-center font-bold">{noData}</div>
          </Grid>
        )}
      </Grid>
    </CardCustom>
  );
};
