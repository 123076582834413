/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GoogleAuthRequestBody
 */
export interface GoogleAuthRequestBody {
    /**
     * 
     * @type {string}
     * @memberof GoogleAuthRequestBody
     */
    accessToken?: string;
}

/**
 * Check if a given object implements the GoogleAuthRequestBody interface.
 */
export function instanceOfGoogleAuthRequestBody(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GoogleAuthRequestBodyFromJSON(json: any): GoogleAuthRequestBody {
    return GoogleAuthRequestBodyFromJSONTyped(json, false);
}

export function GoogleAuthRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): GoogleAuthRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessToken': !exists(json, 'access_token') ? undefined : json['access_token'],
    };
}

export function GoogleAuthRequestBodyToJSON(value?: GoogleAuthRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'access_token': value.accessToken,
    };
}

