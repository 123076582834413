import { exportApi } from "@/api";
import { CardCustom, TextBlockCustom } from "@/components/display";
import { EXPORTS } from "@/constants";
import { changeUrlQuery, getUrlQueryValue } from "@/utils";
import Downloading from "@mui/icons-material/Downloading";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Search from "@mui/icons-material/Search";
import {
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputAdornment,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import debounce from "lodash/debounce";
import React, { useCallback, useState } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import { UserTable, reloadUserTableEvent } from "./components";

const StyledUserListPage = styled.div``;

interface IUserListPageProps {}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const UserListPage = (
  props: React.PropsWithChildren<IUserListPageProps>
) => {
  const [download, setDownload] = useState<boolean>(false);
  const [selectedExport, setSelectedExport] = useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof selectedExport>) => {
    const {
      target: { value },
    } = event;
    setSelectedExport(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchKeywordChange = useCallback(
    debounce((searchKeyword: string) => {
      changeUrlQuery("search", searchKeyword);
      window.dispatchEvent(reloadUserTableEvent);
    }, 500),
    []
  );

  //--- Export file
  const { mutate: exportMotherList } = useMutation({
    mutationFn: () => exportApi.adminExportFileGet({ names: selectedExport }),
    onSuccess: (blob) => {
      const downloadUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = "ユーザーリスト.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setDownload(false);
    },
    onError: (error) => {
      console.error(error);
      setDownload(false);
    },
  });

  return (
    <StyledUserListPage>
      <TextBlockCustom
        classText="text-lg font-bold mb-8"
        textHeader="ユーザーリスト"
        textSize={24}
      />
      <CardCustom>
        <div className="mb-6 flex">
          <div className="grow">
            <OutlinedInput
              defaultValue={getUrlQueryValue("search") ?? ""}
              className="bordered-input"
              placeholder="ユーザー名またはメールアドレスで検索"
              size="small"
              onChange={(e) => handleSearchKeywordChange(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton>{<Search />}</IconButton>
                </InputAdornment>
              }
              sx={{
                minWidth: 400,
              }}
            />
          </div>
          <div className="w-[500px] mx-9">
            <FormControl
              fullWidth={true}
              className="backgrounded-input custom-select"
            >
              <Select
                id="demo-multiple-checkbox"
                multiple
                value={selectedExport}
                onChange={handleChange}
                input={<OutlinedInput />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {EXPORTS.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    disabled={
                      selectedExport.length >= 5 &&
                      !selectedExport.includes(name)
                    }
                  >
                    <Checkbox checked={selectedExport.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <Button
              disabled={download}
              variant="outlined"
              color="secondary"
              size="medium"
              startIcon={download ? <Downloading /> : <FileDownloadIcon />}
              onClick={() => {
                setDownload(true);
                exportMotherList();
              }}
            >
              {download ? "エクスポート中" : "エクスポート"}
            </Button>
          </div>
        </div>

        <UserTable />
      </CardCustom>
    </StyledUserListPage>
  );
};
