import React, { useState } from "react";
import { BootstrapDialogCustom } from "@/components/dialog";
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { TextBlockCustom } from "@/components/display";

interface IDialogAddEditDrugProps {
  openDialog?: boolean;
  onCloseTypeDialog?: () => void;
  onOpenAddEditDialog?: () => void;
}

export const DialogSelectType = (
  props: React.PropsWithChildren<IDialogAddEditDrugProps>
) => {
  const { openDialog, onCloseTypeDialog, onOpenAddEditDialog } = props;

  //--- State
  const [notiType, setNotiType] = useState("content");

  //--- Change type
  const handleChangeNotiType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotiType((event.target as HTMLInputElement).value);
  };

  //--- Submit type
  const handleSubmitType = () => {
    localStorage.setItem("notiCampaignType", JSON.stringify(notiType));
    onOpenAddEditDialog();
  };

  return (
    <BootstrapDialogCustom
      openDialog={openDialog}
      // onCloseDialog={onCloseTypeDialog}
    >
      <div className="pl-16 pr-16 form-control mt-4">
        <TextBlockCustom
          classText="font-bold mb-6"
          textHeader="ポップアップ形の選択"
          textSize={24}
        />

        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={notiType}
            onChange={handleChangeNotiType}
          >
            <FormControlLabel
              value="content"
              control={<Radio />}
              label="コンテンツ"
            />
            <FormControlLabel
              value="banner"
              control={<Radio />}
              label="バナー"
            />
          </RadioGroup>
        </FormControl>

        <div className="grid grid-cols-2 gap-4 mt-6 mb-4">
          <Button
            color="primary"
            variant="outlined"
            onClick={onCloseTypeDialog}
          >
            戻る
          </Button>

          <Button
            color="primary"
            variant="contained"
            onClick={() => handleSubmitType()}
          >
            次へ
          </Button>
        </div>
      </div>
    </BootstrapDialogCustom>
  );
};
