/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InfantOneAndSixYearOld } from './InfantOneAndSixYearOld';
import {
    InfantOneAndSixYearOldFromJSON,
    InfantOneAndSixYearOldFromJSONTyped,
    InfantOneAndSixYearOldToJSON,
} from './InfantOneAndSixYearOld';
import type { PaginationInfo } from './PaginationInfo';
import {
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './PaginationInfo';

/**
 * 
 * @export
 * @interface InfantOneAndSixYearOldPagination
 */
export interface InfantOneAndSixYearOldPagination {
    /**
     * 
     * @type {Array<InfantOneAndSixYearOld>}
     * @memberof InfantOneAndSixYearOldPagination
     */
    data?: Array<InfantOneAndSixYearOld>;
    /**
     * 
     * @type {PaginationInfo}
     * @memberof InfantOneAndSixYearOldPagination
     */
    meta?: PaginationInfo;
}

/**
 * Check if a given object implements the InfantOneAndSixYearOldPagination interface.
 */
export function instanceOfInfantOneAndSixYearOldPagination(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InfantOneAndSixYearOldPaginationFromJSON(json: any): InfantOneAndSixYearOldPagination {
    return InfantOneAndSixYearOldPaginationFromJSONTyped(json, false);
}

export function InfantOneAndSixYearOldPaginationFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfantOneAndSixYearOldPagination {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(InfantOneAndSixYearOldFromJSON)),
        'meta': !exists(json, 'meta') ? undefined : PaginationInfoFromJSON(json['meta']),
    };
}

export function InfantOneAndSixYearOldPaginationToJSON(value?: InfantOneAndSixYearOldPagination | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(InfantOneAndSixYearOldToJSON)),
        'meta': PaginationInfoToJSON(value.meta),
    };
}

