/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';
import type { InfantThreeToFourMonthOldAllOf } from './InfantThreeToFourMonthOldAllOf';
import {
    InfantThreeToFourMonthOldAllOfFromJSON,
    InfantThreeToFourMonthOldAllOfFromJSONTyped,
    InfantThreeToFourMonthOldAllOfToJSON,
} from './InfantThreeToFourMonthOldAllOf';

/**
 * 
 * @export
 * @interface InfantThreeToFourMonthOld
 */
export interface InfantThreeToFourMonthOld {
    /**
     * 
     * @type {number}
     * @memberof InfantThreeToFourMonthOld
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeToFourMonthOld
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeToFourMonthOld
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof InfantThreeToFourMonthOld
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof InfantThreeToFourMonthOld
     */
    babyId?: number;
    /**
     * 
     * @type {number}
     * @memberof InfantThreeToFourMonthOld
     */
    ageId?: number;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeToFourMonthOld
     */
    implementationDate?: string;
    /**
     * 
     * @type {number}
     * @memberof InfantThreeToFourMonthOld
     */
    month?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantThreeToFourMonthOld
     */
    day?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeToFourMonthOld
     */
    photo1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeToFourMonthOld
     */
    photo2?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InfantThreeToFourMonthOld
     */
    bodyWeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantThreeToFourMonthOld
     */
    height?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantThreeToFourMonthOld
     */
    kaupIndex?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantThreeToFourMonthOld
     */
    chestCircumference?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantThreeToFourMonthOld
     */
    headCircumference?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeToFourMonthOld
     */
    nutritionalStatus?: InfantThreeToFourMonthOldNutritionalStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeToFourMonthOld
     */
    nutritionMethod?: InfantThreeToFourMonthOldNutritionMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeToFourMonthOld
     */
    restrictionOfHip?: InfantThreeToFourMonthOldRestrictionOfHipEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeToFourMonthOld
     */
    healthObservation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeToFourMonthOld
     */
    remarks?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeToFourMonthOld
     */
    facilityOrPersonName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeToFourMonthOld
     */
    dayCaregiver?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeToFourMonthOld
     */
    recordingDate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InfantThreeToFourMonthOld
     */
    yourNeckMonth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantThreeToFourMonthOld
     */
    yourNeckDay?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeToFourMonthOld
     */
    laughALot?: InfantThreeToFourMonthOldLaughALotEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeToFourMonthOld
     */
    yourEyes?: InfantThreeToFourMonthOldYourEyesEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeToFourMonthOld
     */
    invisibleDirection?: InfantThreeToFourMonthOldInvisibleDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeToFourMonthOld
     */
    bathInAir?: InfantThreeToFourMonthOldBathInAirEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeToFourMonthOld
     */
    childRearing?: InfantThreeToFourMonthOldChildRearingEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeToFourMonthOld
     */
    raisingChildren?: InfantThreeToFourMonthOldRaisingChildrenEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeToFourMonthOld
     */
    yourGrowth?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeToFourMonthOld
     */
    resultOfWithFindings?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeToFourMonthOld
     */
    judgement?: InfantThreeToFourMonthOldJudgementEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantThreeToFourMonthOld
     */
    photo3?: string | null;
}


/**
 * @export
 */
export const InfantThreeToFourMonthOldNutritionalStatusEnum = {
    Good: 'good',
    InstructionRequired: 'instructionRequired'
} as const;
export type InfantThreeToFourMonthOldNutritionalStatusEnum = typeof InfantThreeToFourMonthOldNutritionalStatusEnum[keyof typeof InfantThreeToFourMonthOldNutritionalStatusEnum];

/**
 * @export
 */
export const InfantThreeToFourMonthOldNutritionMethodEnum = {
    BreastMilk: 'breastMilk',
    Mixture: 'mixture',
    ArtificialMilk: 'artificialMilk'
} as const;
export type InfantThreeToFourMonthOldNutritionMethodEnum = typeof InfantThreeToFourMonthOldNutritionMethodEnum[keyof typeof InfantThreeToFourMonthOldNutritionMethodEnum];

/**
 * @export
 */
export const InfantThreeToFourMonthOldRestrictionOfHipEnum = {
    None: 'none',
    CanBe: 'canBe'
} as const;
export type InfantThreeToFourMonthOldRestrictionOfHipEnum = typeof InfantThreeToFourMonthOldRestrictionOfHipEnum[keyof typeof InfantThreeToFourMonthOldRestrictionOfHipEnum];

/**
 * @export
 */
export const InfantThreeToFourMonthOldLaughALotEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantThreeToFourMonthOldLaughALotEnum = typeof InfantThreeToFourMonthOldLaughALotEnum[keyof typeof InfantThreeToFourMonthOldLaughALotEnum];

/**
 * @export
 */
export const InfantThreeToFourMonthOldYourEyesEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantThreeToFourMonthOldYourEyesEnum = typeof InfantThreeToFourMonthOldYourEyesEnum[keyof typeof InfantThreeToFourMonthOldYourEyesEnum];

/**
 * @export
 */
export const InfantThreeToFourMonthOldInvisibleDirectionEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantThreeToFourMonthOldInvisibleDirectionEnum = typeof InfantThreeToFourMonthOldInvisibleDirectionEnum[keyof typeof InfantThreeToFourMonthOldInvisibleDirectionEnum];

/**
 * @export
 */
export const InfantThreeToFourMonthOldBathInAirEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantThreeToFourMonthOldBathInAirEnum = typeof InfantThreeToFourMonthOldBathInAirEnum[keyof typeof InfantThreeToFourMonthOldBathInAirEnum];

/**
 * @export
 */
export const InfantThreeToFourMonthOldChildRearingEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantThreeToFourMonthOldChildRearingEnum = typeof InfantThreeToFourMonthOldChildRearingEnum[keyof typeof InfantThreeToFourMonthOldChildRearingEnum];

/**
 * @export
 */
export const InfantThreeToFourMonthOldRaisingChildrenEnum = {
    No: 'no',
    Yes: 'yes',
    CanNotSayAnything: 'canNotSayAnything'
} as const;
export type InfantThreeToFourMonthOldRaisingChildrenEnum = typeof InfantThreeToFourMonthOldRaisingChildrenEnum[keyof typeof InfantThreeToFourMonthOldRaisingChildrenEnum];

/**
 * @export
 */
export const InfantThreeToFourMonthOldJudgementEnum = {
    NoAbnormality: 'noAbnormality',
    AlreadyMedical: 'alreadyMedical',
    FollowUpRequired: 'followUpRequired',
    PrecisionRequired: 'precisionRequired',
    TreatmentRequired: 'treatmentRequired'
} as const;
export type InfantThreeToFourMonthOldJudgementEnum = typeof InfantThreeToFourMonthOldJudgementEnum[keyof typeof InfantThreeToFourMonthOldJudgementEnum];


/**
 * Check if a given object implements the InfantThreeToFourMonthOld interface.
 */
export function instanceOfInfantThreeToFourMonthOld(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InfantThreeToFourMonthOldFromJSON(json: any): InfantThreeToFourMonthOld {
    return InfantThreeToFourMonthOldFromJSONTyped(json, false);
}

export function InfantThreeToFourMonthOldFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfantThreeToFourMonthOld {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'babyId': !exists(json, 'babyId') ? undefined : json['babyId'],
        'ageId': !exists(json, 'ageId') ? undefined : json['ageId'],
        'implementationDate': !exists(json, 'implementationDate') ? undefined : json['implementationDate'],
        'month': !exists(json, 'month') ? undefined : json['month'],
        'day': !exists(json, 'day') ? undefined : json['day'],
        'photo1': !exists(json, 'photo1') ? undefined : json['photo1'],
        'photo2': !exists(json, 'photo2') ? undefined : json['photo2'],
        'bodyWeight': !exists(json, 'bodyWeight') ? undefined : json['bodyWeight'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'kaupIndex': !exists(json, 'kaupIndex') ? undefined : json['kaupIndex'],
        'chestCircumference': !exists(json, 'chestCircumference') ? undefined : json['chestCircumference'],
        'headCircumference': !exists(json, 'headCircumference') ? undefined : json['headCircumference'],
        'nutritionalStatus': !exists(json, 'nutritionalStatus') ? undefined : json['nutritionalStatus'],
        'nutritionMethod': !exists(json, 'nutritionMethod') ? undefined : json['nutritionMethod'],
        'restrictionOfHip': !exists(json, 'restrictionOfHip') ? undefined : json['restrictionOfHip'],
        'healthObservation': !exists(json, 'healthObservation') ? undefined : json['healthObservation'],
        'remarks': !exists(json, 'remarks') ? undefined : json['remarks'],
        'facilityOrPersonName': !exists(json, 'facilityOrPersonName') ? undefined : json['facilityOrPersonName'],
        'dayCaregiver': !exists(json, 'dayCaregiver') ? undefined : json['dayCaregiver'],
        'recordingDate': !exists(json, 'recordingDate') ? undefined : json['recordingDate'],
        'yourNeckMonth': !exists(json, 'yourNeckMonth') ? undefined : json['yourNeckMonth'],
        'yourNeckDay': !exists(json, 'yourNeckDay') ? undefined : json['yourNeckDay'],
        'laughALot': !exists(json, 'laughALot') ? undefined : json['laughALot'],
        'yourEyes': !exists(json, 'yourEyes') ? undefined : json['yourEyes'],
        'invisibleDirection': !exists(json, 'invisibleDirection') ? undefined : json['invisibleDirection'],
        'bathInAir': !exists(json, 'bathInAir') ? undefined : json['bathInAir'],
        'childRearing': !exists(json, 'childRearing') ? undefined : json['childRearing'],
        'raisingChildren': !exists(json, 'raisingChildren') ? undefined : json['raisingChildren'],
        'yourGrowth': !exists(json, 'yourGrowth') ? undefined : json['yourGrowth'],
        'resultOfWithFindings': !exists(json, 'resultOfWithFindings') ? undefined : json['resultOfWithFindings'],
        'judgement': !exists(json, 'judgement') ? undefined : json['judgement'],
        'photo3': !exists(json, 'photo3') ? undefined : json['photo3'],
    };
}

export function InfantThreeToFourMonthOldToJSON(value?: InfantThreeToFourMonthOld | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'userId': value.userId,
        'babyId': value.babyId,
        'ageId': value.ageId,
        'implementationDate': value.implementationDate,
        'month': value.month,
        'day': value.day,
        'photo1': value.photo1,
        'photo2': value.photo2,
        'bodyWeight': value.bodyWeight,
        'height': value.height,
        'kaupIndex': value.kaupIndex,
        'chestCircumference': value.chestCircumference,
        'headCircumference': value.headCircumference,
        'nutritionalStatus': value.nutritionalStatus,
        'nutritionMethod': value.nutritionMethod,
        'restrictionOfHip': value.restrictionOfHip,
        'healthObservation': value.healthObservation,
        'remarks': value.remarks,
        'facilityOrPersonName': value.facilityOrPersonName,
        'dayCaregiver': value.dayCaregiver,
        'recordingDate': value.recordingDate,
        'yourNeckMonth': value.yourNeckMonth,
        'yourNeckDay': value.yourNeckDay,
        'laughALot': value.laughALot,
        'yourEyes': value.yourEyes,
        'invisibleDirection': value.invisibleDirection,
        'bathInAir': value.bathInAir,
        'childRearing': value.childRearing,
        'raisingChildren': value.raisingChildren,
        'yourGrowth': value.yourGrowth,
        'resultOfWithFindings': value.resultOfWithFindings,
        'judgement': value.judgement,
        'photo3': value.photo3,
    };
}

