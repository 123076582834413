/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';
import type { HealthOfMomsAllOf } from './HealthOfMomsAllOf';
import {
    HealthOfMomsAllOfFromJSON,
    HealthOfMomsAllOfFromJSONTyped,
    HealthOfMomsAllOfToJSON,
} from './HealthOfMomsAllOf';
import type { PregnantInfo } from './PregnantInfo';
import {
    PregnantInfoFromJSON,
    PregnantInfoFromJSONTyped,
    PregnantInfoToJSON,
} from './PregnantInfo';

/**
 * 
 * @export
 * @interface HealthOfMoms
 */
export interface HealthOfMoms {
    /**
     * 
     * @type {number}
     * @memberof HealthOfMoms
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof HealthOfMoms
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof HealthOfMoms
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof HealthOfMoms
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof HealthOfMoms
     */
    motherId?: number;
    /**
     * 
     * @type {string}
     * @memberof HealthOfMoms
     */
    illnesses?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HealthOfMoms
     */
    illnessOther?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HealthOfMoms
     */
    diseaseRubella?: HealthOfMomsDiseaseRubellaEnum;
    /**
     * 
     * @type {number}
     * @memberof HealthOfMoms
     */
    infectedAgeRubella?: number | null;
    /**
     * 
     * @type {string}
     * @memberof HealthOfMoms
     */
    diseaseMeasles?: HealthOfMomsDiseaseMeaslesEnum;
    /**
     * 
     * @type {number}
     * @memberof HealthOfMoms
     */
    infectedAgeMeasles?: number | null;
    /**
     * 
     * @type {string}
     * @memberof HealthOfMoms
     */
    diseaseChickenPox?: HealthOfMomsDiseaseChickenPoxEnum;
    /**
     * 
     * @type {number}
     * @memberof HealthOfMoms
     */
    infectedAgeChickenPox?: number | null;
    /**
     * 
     * @type {string}
     * @memberof HealthOfMoms
     */
    surgery?: HealthOfMomsSurgeryEnum;
    /**
     * 
     * @type {string}
     * @memberof HealthOfMoms
     */
    nameSurgeryIllnesses?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HealthOfMoms
     */
    medicinesBeingTaken?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HealthOfMoms
     */
    stress?: HealthOfMomsStressEnum;
    /**
     * 
     * @type {string}
     * @memberof HealthOfMoms
     */
    concernsRegardingYourPregnancy?: HealthOfMomsConcernsRegardingYourPregnancyEnum;
    /**
     * 
     * @type {string}
     * @memberof HealthOfMoms
     */
    concernsOther?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HealthOfMoms
     */
    smoker?: HealthOfMomsSmokerEnum;
    /**
     * 
     * @type {number}
     * @memberof HealthOfMoms
     */
    amountCigarettesOneDay?: number | null;
    /**
     * 
     * @type {string}
     * @memberof HealthOfMoms
     */
    residentsSmoker?: HealthOfMomsResidentsSmokerEnum;
    /**
     * 
     * @type {number}
     * @memberof HealthOfMoms
     */
    amountCigarettesOneDayResidentsSmoker?: number | null;
    /**
     * 
     * @type {string}
     * @memberof HealthOfMoms
     */
    drinkingHabit?: HealthOfMomsDrinkingHabitEnum;
    /**
     * 
     * @type {string}
     * @memberof HealthOfMoms
     */
    drinkingHabitLevel?: HealthOfMomsDrinkingHabitLevelEnum;
    /**
     * 
     * @type {Array<PregnantInfo>}
     * @memberof HealthOfMoms
     */
    pregnantInfos?: Array<PregnantInfo>;
}


/**
 * @export
 */
export const HealthOfMomsDiseaseRubellaEnum = {
    Blank: 'BLANK',
    Vaccinated: 'VACCINATED',
    Yes: 'YES',
    No: 'NO'
} as const;
export type HealthOfMomsDiseaseRubellaEnum = typeof HealthOfMomsDiseaseRubellaEnum[keyof typeof HealthOfMomsDiseaseRubellaEnum];

/**
 * @export
 */
export const HealthOfMomsDiseaseMeaslesEnum = {
    Blank: 'BLANK',
    Vaccinated: 'VACCINATED',
    Yes: 'YES',
    No: 'NO'
} as const;
export type HealthOfMomsDiseaseMeaslesEnum = typeof HealthOfMomsDiseaseMeaslesEnum[keyof typeof HealthOfMomsDiseaseMeaslesEnum];

/**
 * @export
 */
export const HealthOfMomsDiseaseChickenPoxEnum = {
    Blank: 'BLANK',
    Vaccinated: 'VACCINATED',
    Yes: 'YES',
    No: 'NO'
} as const;
export type HealthOfMomsDiseaseChickenPoxEnum = typeof HealthOfMomsDiseaseChickenPoxEnum[keyof typeof HealthOfMomsDiseaseChickenPoxEnum];

/**
 * @export
 */
export const HealthOfMomsSurgeryEnum = {
    Blank: 'BLANK',
    Yes: 'YES',
    None: 'NONE',
    CanBe: 'CAN_BE'
} as const;
export type HealthOfMomsSurgeryEnum = typeof HealthOfMomsSurgeryEnum[keyof typeof HealthOfMomsSurgeryEnum];

/**
 * @export
 */
export const HealthOfMomsStressEnum = {
    Blank: 'BLANK',
    None: 'NONE',
    CanBe: 'CAN_BE'
} as const;
export type HealthOfMomsStressEnum = typeof HealthOfMomsStressEnum[keyof typeof HealthOfMomsStressEnum];

/**
 * @export
 */
export const HealthOfMomsConcernsRegardingYourPregnancyEnum = {
    Blank: 'BLANK',
    None: 'NONE',
    CanBe: 'CAN_BE'
} as const;
export type HealthOfMomsConcernsRegardingYourPregnancyEnum = typeof HealthOfMomsConcernsRegardingYourPregnancyEnum[keyof typeof HealthOfMomsConcernsRegardingYourPregnancyEnum];

/**
 * @export
 */
export const HealthOfMomsSmokerEnum = {
    Blank: 'BLANK',
    None: 'NONE',
    CanBe: 'CAN_BE'
} as const;
export type HealthOfMomsSmokerEnum = typeof HealthOfMomsSmokerEnum[keyof typeof HealthOfMomsSmokerEnum];

/**
 * @export
 */
export const HealthOfMomsResidentsSmokerEnum = {
    Blank: 'BLANK',
    None: 'NONE',
    CanBe: 'CAN_BE'
} as const;
export type HealthOfMomsResidentsSmokerEnum = typeof HealthOfMomsResidentsSmokerEnum[keyof typeof HealthOfMomsResidentsSmokerEnum];

/**
 * @export
 */
export const HealthOfMomsDrinkingHabitEnum = {
    Blank: 'BLANK',
    None: 'NONE',
    CanBe: 'CAN_BE'
} as const;
export type HealthOfMomsDrinkingHabitEnum = typeof HealthOfMomsDrinkingHabitEnum[keyof typeof HealthOfMomsDrinkingHabitEnum];

/**
 * @export
 */
export const HealthOfMomsDrinkingHabitLevelEnum = {
    EveryDay: 'EVERY_DAY',
    _23TimesAWeek: '2~3_TIMES_A_WEEK',
    Sometimes: 'SOMETIMES'
} as const;
export type HealthOfMomsDrinkingHabitLevelEnum = typeof HealthOfMomsDrinkingHabitLevelEnum[keyof typeof HealthOfMomsDrinkingHabitLevelEnum];


/**
 * Check if a given object implements the HealthOfMoms interface.
 */
export function instanceOfHealthOfMoms(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HealthOfMomsFromJSON(json: any): HealthOfMoms {
    return HealthOfMomsFromJSONTyped(json, false);
}

export function HealthOfMomsFromJSONTyped(json: any, ignoreDiscriminator: boolean): HealthOfMoms {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'motherId': !exists(json, 'motherId') ? undefined : json['motherId'],
        'illnesses': !exists(json, 'illnesses') ? undefined : json['illnesses'],
        'illnessOther': !exists(json, 'illnessOther') ? undefined : json['illnessOther'],
        'diseaseRubella': !exists(json, 'diseaseRubella') ? undefined : json['diseaseRubella'],
        'infectedAgeRubella': !exists(json, 'infectedAgeRubella') ? undefined : json['infectedAgeRubella'],
        'diseaseMeasles': !exists(json, 'diseaseMeasles') ? undefined : json['diseaseMeasles'],
        'infectedAgeMeasles': !exists(json, 'infectedAgeMeasles') ? undefined : json['infectedAgeMeasles'],
        'diseaseChickenPox': !exists(json, 'diseaseChickenPox') ? undefined : json['diseaseChickenPox'],
        'infectedAgeChickenPox': !exists(json, 'infectedAgeChickenPox') ? undefined : json['infectedAgeChickenPox'],
        'surgery': !exists(json, 'surgery') ? undefined : json['surgery'],
        'nameSurgeryIllnesses': !exists(json, 'nameSurgeryIllnesses') ? undefined : json['nameSurgeryIllnesses'],
        'medicinesBeingTaken': !exists(json, 'medicinesBeingTaken') ? undefined : json['medicinesBeingTaken'],
        'stress': !exists(json, 'stress') ? undefined : json['stress'],
        'concernsRegardingYourPregnancy': !exists(json, 'concernsRegardingYourPregnancy') ? undefined : json['concernsRegardingYourPregnancy'],
        'concernsOther': !exists(json, 'concernsOther') ? undefined : json['concernsOther'],
        'smoker': !exists(json, 'smoker') ? undefined : json['smoker'],
        'amountCigarettesOneDay': !exists(json, 'amountCigarettesOneDay') ? undefined : json['amountCigarettesOneDay'],
        'residentsSmoker': !exists(json, 'residentsSmoker') ? undefined : json['residentsSmoker'],
        'amountCigarettesOneDayResidentsSmoker': !exists(json, 'amountCigarettesOneDayResidentsSmoker') ? undefined : json['amountCigarettesOneDayResidentsSmoker'],
        'drinkingHabit': !exists(json, 'drinkingHabit') ? undefined : json['drinkingHabit'],
        'drinkingHabitLevel': !exists(json, 'drinkingHabitLevel') ? undefined : json['drinkingHabitLevel'],
        'pregnantInfos': !exists(json, 'pregnantInfos') ? undefined : ((json['pregnantInfos'] as Array<any>).map(PregnantInfoFromJSON)),
    };
}

export function HealthOfMomsToJSON(value?: HealthOfMoms | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'userId': value.userId,
        'motherId': value.motherId,
        'illnesses': value.illnesses,
        'illnessOther': value.illnessOther,
        'diseaseRubella': value.diseaseRubella,
        'infectedAgeRubella': value.infectedAgeRubella,
        'diseaseMeasles': value.diseaseMeasles,
        'infectedAgeMeasles': value.infectedAgeMeasles,
        'diseaseChickenPox': value.diseaseChickenPox,
        'infectedAgeChickenPox': value.infectedAgeChickenPox,
        'surgery': value.surgery,
        'nameSurgeryIllnesses': value.nameSurgeryIllnesses,
        'medicinesBeingTaken': value.medicinesBeingTaken,
        'stress': value.stress,
        'concernsRegardingYourPregnancy': value.concernsRegardingYourPregnancy,
        'concernsOther': value.concernsOther,
        'smoker': value.smoker,
        'amountCigarettesOneDay': value.amountCigarettesOneDay,
        'residentsSmoker': value.residentsSmoker,
        'amountCigarettesOneDayResidentsSmoker': value.amountCigarettesOneDayResidentsSmoker,
        'drinkingHabit': value.drinkingHabit,
        'drinkingHabitLevel': value.drinkingHabitLevel,
        'pregnantInfos': value.pregnantInfos === undefined ? undefined : ((value.pregnantInfos as Array<any>).map(PregnantInfoToJSON)),
    };
}

