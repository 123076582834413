/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    NoteSchedule,
    NoteScheduleFromJSON,
    NoteScheduleToJSON,
    NoteSchedulePagination,
    NoteSchedulePaginationFromJSON,
    NoteSchedulePaginationToJSON,
} from '../models';

export interface NoteSchedulesGetRequest {
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface NoteSchedulesIdDeleteRequest {
    id: number;
}

export interface NoteSchedulesIdGetRequest {
    id: number;
}

export interface NoteSchedulesIdPutRequest {
    id: number;
    noteSchedule?: NoteSchedule;
}

export interface NoteSchedulesPostRequest {
    noteSchedule?: NoteSchedule;
}

/**
 * 
 */
export class NoteSchedulesApi extends runtime.BaseAPI {

    /**
     * 
     */
    noteSchedulesGetRaw = async (requestParameters: NoteSchedulesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<NoteSchedulePagination>> => {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/noteSchedules`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NoteSchedulePaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    noteSchedulesGet = async (requestParameters: NoteSchedulesGetRequest = {}, initOverrides?: RequestInit): Promise<NoteSchedulePagination> => {
        const response = await this.noteSchedulesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    noteSchedulesIdDeleteRaw = async (requestParameters: NoteSchedulesIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling noteSchedulesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/noteSchedules/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    noteSchedulesIdDelete = async (requestParameters: NoteSchedulesIdDeleteRequest, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.noteSchedulesIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    noteSchedulesIdGetRaw = async (requestParameters: NoteSchedulesIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<NoteSchedule>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling noteSchedulesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/noteSchedules/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NoteScheduleFromJSON(jsonValue));
    }

    /**
     * 
     */
    noteSchedulesIdGet = async (requestParameters: NoteSchedulesIdGetRequest, initOverrides?: RequestInit): Promise<NoteSchedule> => {
        const response = await this.noteSchedulesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    noteSchedulesIdPutRaw = async (requestParameters: NoteSchedulesIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<NoteSchedule>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling noteSchedulesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/noteSchedules/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NoteScheduleToJSON(requestParameters.noteSchedule),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NoteScheduleFromJSON(jsonValue));
    }

    /**
     */
    noteSchedulesIdPut = async (requestParameters: NoteSchedulesIdPutRequest, initOverrides?: RequestInit): Promise<NoteSchedule> => {
        const response = await this.noteSchedulesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    noteSchedulesPostRaw = async (requestParameters: NoteSchedulesPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<NoteSchedule>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/noteSchedules`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NoteScheduleToJSON(requestParameters.noteSchedule),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NoteScheduleFromJSON(jsonValue));
    }

    /**
     */
    noteSchedulesPost = async (requestParameters: NoteSchedulesPostRequest = {}, initOverrides?: RequestInit): Promise<NoteSchedule> => {
        const response = await this.noteSchedulesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
