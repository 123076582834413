/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InfantFiveYearOld,
    InfantFiveYearOldFromJSON,
    InfantFiveYearOldToJSON,
    InfantFiveYearOldPagination,
    InfantFiveYearOldPaginationFromJSON,
    InfantFiveYearOldPaginationToJSON,
} from '../models';

export interface AdminInfantFiveYearOldsIdGetRequest {
    id: number;
    userId?: number;
}

export interface InfantFiveYearOldsGetRequest {
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface InfantFiveYearOldsIdDeleteRequest {
    id: number;
}

export interface InfantFiveYearOldsIdGetRequest {
    id: number;
}

export interface InfantFiveYearOldsIdPutRequest {
    id: number;
    infantFiveYearOld?: InfantFiveYearOld;
}

export interface InfantFiveYearOldsPostRequest {
    infantFiveYearOld?: InfantFiveYearOld;
}

export interface InfantFiveYearOldsShareAllGetRequest {
    sourceUserId: number;
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface InfantFiveYearOldsShareDetailGetRequest {
    sourceUserId: number;
    recordId: number;
}

/**
 * 
 */
export class InfantFiveYearOldsApi extends runtime.BaseAPI {

    /**
     * 
     */
    adminInfantFiveYearOldsIdGetRaw = async (requestParameters: AdminInfantFiveYearOldsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantFiveYearOld>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling adminInfantFiveYearOldsIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/infantFiveYearOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantFiveYearOldFromJSON(jsonValue));
    }

    /**
     * 
     */
    adminInfantFiveYearOldsIdGet = async (requestParameters: AdminInfantFiveYearOldsIdGetRequest, initOverrides?: RequestInit): Promise<InfantFiveYearOld> => {
        const response = await this.adminInfantFiveYearOldsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantFiveYearOldsGetRaw = async (requestParameters: InfantFiveYearOldsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantFiveYearOldPagination>> => {
        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling infantFiveYearOldsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantFiveYearOlds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantFiveYearOldPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantFiveYearOldsGet = async (requestParameters: InfantFiveYearOldsGetRequest, initOverrides?: RequestInit): Promise<InfantFiveYearOldPagination> => {
        const response = await this.infantFiveYearOldsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    infantFiveYearOldsIdDeleteRaw = async (requestParameters: InfantFiveYearOldsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling infantFiveYearOldsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantFiveYearOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    infantFiveYearOldsIdDelete = async (requestParameters: InfantFiveYearOldsIdDeleteRequest, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.infantFiveYearOldsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantFiveYearOldsIdGetRaw = async (requestParameters: InfantFiveYearOldsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantFiveYearOld>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling infantFiveYearOldsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantFiveYearOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantFiveYearOldFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantFiveYearOldsIdGet = async (requestParameters: InfantFiveYearOldsIdGetRequest, initOverrides?: RequestInit): Promise<InfantFiveYearOld> => {
        const response = await this.infantFiveYearOldsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    infantFiveYearOldsIdPutRaw = async (requestParameters: InfantFiveYearOldsIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantFiveYearOld>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling infantFiveYearOldsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/infantFiveYearOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InfantFiveYearOldToJSON(requestParameters.infantFiveYearOld),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantFiveYearOldFromJSON(jsonValue));
    }

    /**
     */
    infantFiveYearOldsIdPut = async (requestParameters: InfantFiveYearOldsIdPutRequest, initOverrides?: RequestInit): Promise<InfantFiveYearOld> => {
        const response = await this.infantFiveYearOldsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    infantFiveYearOldsPostRaw = async (requestParameters: InfantFiveYearOldsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantFiveYearOld>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/infantFiveYearOlds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InfantFiveYearOldToJSON(requestParameters.infantFiveYearOld),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantFiveYearOldFromJSON(jsonValue));
    }

    /**
     */
    infantFiveYearOldsPost = async (requestParameters: InfantFiveYearOldsPostRequest = {}, initOverrides?: RequestInit): Promise<InfantFiveYearOld> => {
        const response = await this.infantFiveYearOldsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantFiveYearOldsShareAllGetRaw = async (requestParameters: InfantFiveYearOldsShareAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantFiveYearOldPagination>> => {
        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling infantFiveYearOldsShareAllGet.');
        }

        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling infantFiveYearOldsShareAllGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantFiveYearOlds/share/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantFiveYearOldPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantFiveYearOldsShareAllGet = async (requestParameters: InfantFiveYearOldsShareAllGetRequest, initOverrides?: RequestInit): Promise<InfantFiveYearOldPagination> => {
        const response = await this.infantFiveYearOldsShareAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantFiveYearOldsShareDetailGetRaw = async (requestParameters: InfantFiveYearOldsShareDetailGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantFiveYearOld>> => {
        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling infantFiveYearOldsShareDetailGet.');
        }

        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling infantFiveYearOldsShareDetailGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        if (requestParameters.recordId !== undefined) {
            queryParameters['recordId'] = requestParameters.recordId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantFiveYearOlds/share/detail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantFiveYearOldFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantFiveYearOldsShareDetailGet = async (requestParameters: InfantFiveYearOldsShareDetailGetRequest, initOverrides?: RequestInit): Promise<InfantFiveYearOld> => {
        const response = await this.infantFiveYearOldsShareDetailGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
