import { Avatar, Button, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import LogoutIcon from "@mui/icons-material/Logout";
import { getLoginUrl } from "@/pages/auth/login/LoginPage.shared";
import { removeToken } from "@/utils";
import { useRecoilState } from "recoil";
import { currentUserState } from "@/constants";

const StyledMasterUserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 36px;

  .admin-name {
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;
  }

  .admin-quick-actions {
    .MuiButton-root {
      color: #fff !important;
      padding: 0;
      width: 30px;
      min-width: unset;
    }
  }

  .admin-email {
    color: #ffffff;
    font-weight: 400;
    font-size: 14px;
  }
`;

interface IMasterUserInfoProps { }

export const MasterUserInfo = (
  props: React.PropsWithChildren<IMasterUserInfoProps>
) => {
  const doLogout = () => {
    removeToken();
    window.location.replace(getLoginUrl());
  };

  const [currentAdmin] = useRecoilState(currentUserState);

  return (
    <StyledMasterUserInfo>
      <div className="admin-avatar mb-2">
        <Avatar src="/avatar.png" />
      </div>
      <div className="mb-1">
        <Typography className="admin-name">
          {currentAdmin?.staffName}
        </Typography>
      </div>
      <div className="mb-3">
        <Typography className="admin-email">{currentAdmin?.email}</Typography>
      </div>
      <div className="admin-quick-actions">
        <Button variant="text" onClick={doLogout}>
          <LogoutIcon />
        </Button>
      </div>
    </StyledMasterUserInfo>
  );
};
