/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InfantOneMonthOld,
    InfantOneMonthOldFromJSON,
    InfantOneMonthOldToJSON,
    InfantOneMonthOldPanigation,
    InfantOneMonthOldPanigationFromJSON,
    InfantOneMonthOldPanigationToJSON,
} from '../models';

export interface AdminInfantOneMonthOldsIdGetRequest {
    id: number;
    userId?: number;
}

export interface InfantOneMonthOldsGetRequest {
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface InfantOneMonthOldsIdDeleteRequest {
    id: number;
}

export interface InfantOneMonthOldsIdGetRequest {
    id: number;
}

export interface InfantOneMonthOldsIdPutRequest {
    id: number;
    infantOneMonthOld?: InfantOneMonthOld;
}

export interface InfantOneMonthOldsPostRequest {
    infantOneMonthOld?: InfantOneMonthOld;
}

export interface InfantOneMonthOldsShareAllGetRequest {
    babyId: number;
    sourceUserId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface InfantOneMonthOldsShareDetailGetRequest {
    recordId: number;
    sourceUserId: number;
}

/**
 * 
 */
export class InfantOneMonthOldsApi extends runtime.BaseAPI {

    /**
     * 
     */
    adminInfantOneMonthOldsIdGetRaw = async (requestParameters: AdminInfantOneMonthOldsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantOneMonthOld>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling adminInfantOneMonthOldsIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/infantOneMonthOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantOneMonthOldFromJSON(jsonValue));
    }

    /**
     * 
     */
    adminInfantOneMonthOldsIdGet = async (requestParameters: AdminInfantOneMonthOldsIdGetRequest, initOverrides?: RequestInit): Promise<InfantOneMonthOld> => {
        const response = await this.adminInfantOneMonthOldsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantOneMonthOldsGetRaw = async (requestParameters: InfantOneMonthOldsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantOneMonthOldPanigation>> => {
        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling infantOneMonthOldsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantOneMonthOlds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantOneMonthOldPanigationFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantOneMonthOldsGet = async (requestParameters: InfantOneMonthOldsGetRequest, initOverrides?: RequestInit): Promise<InfantOneMonthOldPanigation> => {
        const response = await this.infantOneMonthOldsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    infantOneMonthOldsIdDeleteRaw = async (requestParameters: InfantOneMonthOldsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling infantOneMonthOldsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantOneMonthOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    infantOneMonthOldsIdDelete = async (requestParameters: InfantOneMonthOldsIdDeleteRequest, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.infantOneMonthOldsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantOneMonthOldsIdGetRaw = async (requestParameters: InfantOneMonthOldsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantOneMonthOld>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling infantOneMonthOldsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantOneMonthOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantOneMonthOldFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantOneMonthOldsIdGet = async (requestParameters: InfantOneMonthOldsIdGetRequest, initOverrides?: RequestInit): Promise<InfantOneMonthOld> => {
        const response = await this.infantOneMonthOldsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    infantOneMonthOldsIdPutRaw = async (requestParameters: InfantOneMonthOldsIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantOneMonthOld>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling infantOneMonthOldsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/infantOneMonthOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InfantOneMonthOldToJSON(requestParameters.infantOneMonthOld),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantOneMonthOldFromJSON(jsonValue));
    }

    /**
     */
    infantOneMonthOldsIdPut = async (requestParameters: InfantOneMonthOldsIdPutRequest, initOverrides?: RequestInit): Promise<InfantOneMonthOld> => {
        const response = await this.infantOneMonthOldsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    infantOneMonthOldsPostRaw = async (requestParameters: InfantOneMonthOldsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantOneMonthOld>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/infantOneMonthOlds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InfantOneMonthOldToJSON(requestParameters.infantOneMonthOld),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantOneMonthOldFromJSON(jsonValue));
    }

    /**
     */
    infantOneMonthOldsPost = async (requestParameters: InfantOneMonthOldsPostRequest = {}, initOverrides?: RequestInit): Promise<InfantOneMonthOld> => {
        const response = await this.infantOneMonthOldsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantOneMonthOldsShareAllGetRaw = async (requestParameters: InfantOneMonthOldsShareAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantOneMonthOldPanigation>> => {
        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling infantOneMonthOldsShareAllGet.');
        }

        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling infantOneMonthOldsShareAllGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantOneMonthOlds/share/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantOneMonthOldPanigationFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantOneMonthOldsShareAllGet = async (requestParameters: InfantOneMonthOldsShareAllGetRequest, initOverrides?: RequestInit): Promise<InfantOneMonthOldPanigation> => {
        const response = await this.infantOneMonthOldsShareAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantOneMonthOldsShareDetailGetRaw = async (requestParameters: InfantOneMonthOldsShareDetailGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantOneMonthOld>> => {
        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling infantOneMonthOldsShareDetailGet.');
        }

        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling infantOneMonthOldsShareDetailGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.recordId !== undefined) {
            queryParameters['recordId'] = requestParameters.recordId;
        }

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantOneMonthOlds/share/detail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantOneMonthOldFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantOneMonthOldsShareDetailGet = async (requestParameters: InfantOneMonthOldsShareDetailGetRequest, initOverrides?: RequestInit): Promise<InfantOneMonthOld> => {
        const response = await this.infantOneMonthOldsShareDetailGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
