import React from "react";
import { AvatarCustom, TextBlockCustom } from "@/components/display";
import { Grid } from "@mui/material";
import { COLORS } from "@/constants";
import { InfantMedicalExam, infantThreeToFourMonthOldsApi } from "@/api";
import { useQuery } from "react-query";
import { toJapanDate } from "@/utils";
import { translate } from "@/helpers";

interface IInfantThreeToFourMonthOldDetailProps {
  infantMedicalExamItem?: InfantMedicalExam;
}

export const InfantThreeToFourMonthOldDetail = (
  props: React.PropsWithChildren<IInfantThreeToFourMonthOldDetailProps>
) => {
  const { infantMedicalExamItem } = props;

  //--- Get medical consultant
  const { data: infantThreeToFourMonthOld } = useQuery({
    queryKey: "GET_INFANT_THREE_TO_FOUR_MONTH_OLD_BY_ID",
    queryFn: () => {
      return infantThreeToFourMonthOldsApi.adminInfantThreeToFourMonthOldsIdGet(
        {
          id: infantMedicalExamItem?.infantId,
          userId: infantMedicalExamItem?.userId,
        }
      );
    },
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextBlockCustom
          classText="font-bold text-center mb-4"
          textHeader={`${translate("infantMedicalExam.text.age5")} ${translate(
            "infantMedicalExam.text.detail"
          )}`}
          textSize={18}
          headerColor={COLORS.primary}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.implementationDate"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantThreeToFourMonthOld?.implementationDate
                  ? toJapanDate(infantThreeToFourMonthOld?.implementationDate)
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.ageMonth"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                (infantThreeToFourMonthOld?.month
                  ? `${infantThreeToFourMonthOld?.month}か月`
                  : "") +
                (infantThreeToFourMonthOld?.day
                  ? `${infantThreeToFourMonthOld?.day}日`
                  : "")
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.photographOfMedical"
              )}
              headerColor={COLORS.approxGrey}
            />

            <div className="flex items-center">
              {infantThreeToFourMonthOld?.photo1 && (
                <div className="mr-2">
                  <AvatarCustom imgSrc={infantThreeToFourMonthOld?.photo1} />
                </div>
              )}

              {infantThreeToFourMonthOld?.photo2 && (
                <AvatarCustom imgSrc={infantThreeToFourMonthOld?.photo2} />
              )}
            </div>
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.bodyWeight"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantThreeToFourMonthOld?.bodyWeight
                  ? infantThreeToFourMonthOld?.bodyWeight.toString() + "g"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.height")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantThreeToFourMonthOld?.height
                  ? infantThreeToFourMonthOld?.height.toString() + "cm"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.kaupIndex"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantThreeToFourMonthOld?.kaupIndex
                  ? infantThreeToFourMonthOld?.kaupIndex.toString()
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.chestCircumference"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantThreeToFourMonthOld?.chestCircumference
                  ? infantThreeToFourMonthOld?.chestCircumference.toString() +
                    "cm"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.headCircumference"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantThreeToFourMonthOld?.headCircumference
                  ? infantThreeToFourMonthOld?.headCircumference.toString() +
                    "cm"
                  : ""
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          {/* 栄養状態/健康・要観察/特記事項など */}
          <Grid item xs={12}>
            <TextBlockCustom
              classText="font-bold"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.nutritionalHealth"
              )}
              headerColor={COLORS.primary}
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.nutritionalStatus"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantThreeToFourMonthOld?.nutritionalStatus
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantThreeToFourMonthOld?.nutritionalStatus
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.nutritionMethod"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantThreeToFourMonthOld?.nutritionMethod
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantThreeToFourMonthOld?.nutritionMethod
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.restrictionOfHip"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantThreeToFourMonthOld?.restrictionOfHip
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantThreeToFourMonthOld?.restrictionOfHip
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.healthObservation"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantThreeToFourMonthOld?.healthObservation
                  ? infantThreeToFourMonthOld?.healthObservation
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.remarks")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantThreeToFourMonthOld?.remarks
                  ? infantThreeToFourMonthOld?.remarks
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.facilityOrPersonName"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantThreeToFourMonthOld?.facilityOrPersonName
                  ? infantThreeToFourMonthOld?.facilityOrPersonName
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.dayCaregiver"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantThreeToFourMonthOld?.dayCaregiver
                  ? infantThreeToFourMonthOld?.dayCaregiver
                  : ""
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          {/* 保護者の記録（3~4か月頃） */}
          <Grid item xs={12}>
            <TextBlockCustom
              classText="font-bold"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.parentRecordThreeToFour"
              )}
              headerColor={COLORS.primary}
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.recordingDate"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantThreeToFourMonthOld?.recordingDate
                  ? toJapanDate(infantThreeToFourMonthOld?.recordingDate)
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.yourNeck"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                (infantThreeToFourMonthOld?.yourNeckMonth
                  ? `${infantThreeToFourMonthOld?.yourNeckMonth}${translate(
                      "infoHealthMon.moon"
                    )}`
                  : "") +
                (infantThreeToFourMonthOld?.yourNeckDay
                  ? `${infantThreeToFourMonthOld?.yourNeckDay}${translate(
                      "title.everyday"
                    )}`
                  : "")
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.laughALot"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantThreeToFourMonthOld?.laughALot
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantThreeToFourMonthOld?.laughALot
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.yourEyes"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantThreeToFourMonthOld?.yourEyes
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantThreeToFourMonthOld?.yourEyes
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.invisibleDirection"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantThreeToFourMonthOld?.invisibleDirection
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantThreeToFourMonthOld?.invisibleDirection
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.bathInAir"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantThreeToFourMonthOld?.bathInAir
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantThreeToFourMonthOld?.bathInAir
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.childRearing"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantThreeToFourMonthOld?.childRearing
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantThreeToFourMonthOld?.childRearing
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.raisingChildren"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantThreeToFourMonthOld?.raisingChildren
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantThreeToFourMonthOld?.raisingChildren
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.yourGrowth"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantThreeToFourMonthOld?.yourGrowth
                  ? infantThreeToFourMonthOld?.yourGrowth
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="font-bold"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.examFinding"
              )}
              headerColor={COLORS.primary}
            />
          </Grid>

          <Grid item xs={12}>
            {infantThreeToFourMonthOld &&
              infantThreeToFourMonthOld.resultOfWithFindings &&
              infantThreeToFourMonthOld.resultOfWithFindings
                ?.split(",")
                .map(
                  (item, index) =>
                    !!item && (
                      <TextBlockCustom
                        key={index}
                        classText="font-medium mb-2"
                        textHeader={translate(
                          `infantMedicalExamFormScreen.checkbox.${item}`
                        )}
                      />
                    )
                )}
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.judgement"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantThreeToFourMonthOld?.judgement
                  ? translate(
                      "infantMedicalExamFormScreen.dropdown." +
                        infantThreeToFourMonthOld?.judgement
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.photo")}
              headerColor={COLORS.approxGrey}
            />

            <AvatarCustom imgSrc={infantThreeToFourMonthOld?.photo3} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
