/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NoteSchedule } from './NoteSchedule';
import {
    NoteScheduleFromJSON,
    NoteScheduleFromJSONTyped,
    NoteScheduleToJSON,
} from './NoteSchedule';
import type { PaginationInfo } from './PaginationInfo';
import {
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './PaginationInfo';

/**
 * 
 * @export
 * @interface NoteSchedulePagination
 */
export interface NoteSchedulePagination {
    /**
     * 
     * @type {Array<NoteSchedule>}
     * @memberof NoteSchedulePagination
     */
    data?: Array<NoteSchedule>;
    /**
     * 
     * @type {PaginationInfo}
     * @memberof NoteSchedulePagination
     */
    meta?: PaginationInfo;
}

/**
 * Check if a given object implements the NoteSchedulePagination interface.
 */
export function instanceOfNoteSchedulePagination(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function NoteSchedulePaginationFromJSON(json: any): NoteSchedulePagination {
    return NoteSchedulePaginationFromJSONTyped(json, false);
}

export function NoteSchedulePaginationFromJSONTyped(json: any, ignoreDiscriminator: boolean): NoteSchedulePagination {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(NoteScheduleFromJSON)),
        'meta': !exists(json, 'meta') ? undefined : PaginationInfoFromJSON(json['meta']),
    };
}

export function NoteSchedulePaginationToJSON(value?: NoteSchedulePagination | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(NoteScheduleToJSON)),
        'meta': PaginationInfoToJSON(value.meta),
    };
}

