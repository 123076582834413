/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface AuthAppleRegisterPost201Response
 */
export interface AuthAppleRegisterPost201Response {
    /**
     * 
     * @type {User}
     * @memberof AuthAppleRegisterPost201Response
     */
    user?: User;
}

/**
 * Check if a given object implements the AuthAppleRegisterPost201Response interface.
 */
export function instanceOfAuthAppleRegisterPost201Response(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AuthAppleRegisterPost201ResponseFromJSON(json: any): AuthAppleRegisterPost201Response {
    return AuthAppleRegisterPost201ResponseFromJSONTyped(json, false);
}

export function AuthAppleRegisterPost201ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthAppleRegisterPost201Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
    };
}

export function AuthAppleRegisterPost201ResponseToJSON(value?: AuthAppleRegisterPost201Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': UserToJSON(value.user),
    };
}

