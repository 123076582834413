import React, { useCallback, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";
import { diagnosesApi, prescriptionDrugsApi } from "@/api";
import { noData, otherDrug, textRowsPerPage } from "@/constants";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  LinearProgress,
  Modal,
  Backdrop,
  Fade,
  Box,
  Typography,
} from "@mui/material";
import moment from "moment";
import { DialogAddEditDrug } from "./DialogAddEditDrug";
import { Search } from "@mui/icons-material";
import {
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useApiPagination } from "@/hooks";
import debounce from "lodash/debounce";
import DeleteIcon from "@mui/icons-material/Delete";
import { RoleCheck } from "@/components/system";
import { AlertCustom } from "@/components/display";

const StyledAdminTable = styled.div`
  position: relative;
`;

export const deleteModalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: "36px",
};

interface IDrugTableProps {}

export const DrugTable = (props: React.PropsWithChildren<IDrugTableProps>) => {
  const [searchKeyword, setSearchKeyword] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchKeywordChange = useCallback(
    debounce((searchKeyword: string) => {
      setSearchKeyword(searchKeyword);
    }, 500),
    []
  );

  //--- Get diagnosis list
  const {
    data: diagnosisList,
    isFetching,
    refetch: refetchDiagnosisList,
  } = useQuery({
    queryKey: "GET_DIAGNOSIS_LIST_DRUG",
    queryFn: () => {
      return diagnosesApi.diagnosesGet({
        page: apiPagi.page,
        itemsPerPage: apiPagi.itemsPerPage,
        orderBy: "id",
        orderType: "desc",
        keyword: searchKeyword,
      });
    },
    onSuccess: (data) => {
      apiPagi.setTotalItems(data.meta.totalItems);
    },
    initialData: { data: [] },
  });

  const apiPagi = useApiPagination({
    onPageChange: () => refetchDiagnosisList(),
  });

  useEffect(() => {
    apiPagi.setPage(1);
    refetchDiagnosisList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiPagi.setPage, refetchDiagnosisList, searchKeyword]);

  //--- Get prescription drug
  const { data: prescriptionDrugList, refetch: refetchPrescriptionDrugList } =
    useQuery({
      queryKey: "GET_PRESCRIPTION_DRUG_LIST_DRUG",
      queryFn: () => {
        return prescriptionDrugsApi.prescriptionDrugsGet({
          itemsPerPage: 100000,
        });
      },
    });

  //--- Create and update dialog
  const [openDialog, setOpenDialog] = useState(false);
  const [drugItemId, setDrugItemId] = useState(0);
  const [alertMessage, setAlertMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const handleClickOpenDialog = (id: number) => {
    setDrugItemId(id);
    setOpenDialog(true);
  };

  const handleCheckResult = (value: boolean) => {
    if (value) {
      setAlertMessage("処方薬リストは作成できました。");
    } else {
      setAlertMessage("処方薬リストの作成は失敗しました。");
    }

    handleCloseDialog();
    setOpenSnackBar(true);
  };

  const handleCloseDialog = () => {
    setDrugItemId(0);
    refetchDiagnosisList();
    refetchPrescriptionDrugList();
    setOpenDialog(false);
  };

  //--- Delete dialog
  const [drugDeleteId, setDrugDeleteId] = useState(0);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleOpenDeleteDialog = (drugId: number) => {
    setOpenDeleteDialog(true);
    setDrugDeleteId(drugId);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setDrugDeleteId(0);
  };

  const { mutate: deleteDrug, isLoading: isDeleting } = useMutation(
    () => {
      return diagnosesApi.adminDiagnosesIdDelete({ id: drugDeleteId });
    },
    {
      onSuccess: () => {
        refetchDiagnosisList();
        refetchPrescriptionDrugList();
        handleCloseDeleteDialog();
      },
    }
  );

  //--- Snack bar
  const handleCloseSnack = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };

  return (
    <StyledAdminTable>
      <div className="mb-6 flex">
        <div className="grow">
          <OutlinedInput
            className="bordered-input"
            placeholder="診断名で検索"
            size="small"
            onChange={(e) => handleSearchKeywordChange(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility">
                  {<Search />}
                </IconButton>
              </InputAdornment>
            }
          />
        </div>
        <div>
          <RoleCheck role="admin">
            {({ allowed }) => {
              if (!allowed) {
                return null;
              }

              return (
                <Button
                  variant="outlined"
                  color="secondary"
                  size="medium"
                  startIcon={<AddIcon />}
                  onClick={() => handleClickOpenDialog(0)}
                >
                  診断名追加
                </Button>
              );
            }}
          </RoleCheck>
        </div>
      </div>

      {isFetching && (
        <div className="absolute w-full">
          <LinearProgress />
        </div>
      )}

      <TableContainer component={Paper} className="mb-8">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="color-primary">ID</TableCell>
              <TableCell align="center" className="color-primary">
                診断名
              </TableCell>
              <TableCell align="center" className="color-primary">
                作成日
              </TableCell>
              <RoleCheck role="admin">
                {({ allowed }) => {
                  if (!allowed) {
                    return null;
                  }

                  return (
                    <TableCell align="center" className="color-primary">
                      削除
                    </TableCell>
                  );
                }}
              </RoleCheck>
            </TableRow>
          </TableHead>
          <TableBody>
            {(diagnosisList?.data || []).map((dItem, dIndex) => (
              <TableRow
                key={"diagnosisList_" + dItem.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  {dItem.id < 10 ? "0" + dItem.id : dItem.id}
                </TableCell>
                <TableCell align="center">
                  <RoleCheck role="admin">
                    {({ allowed }) => {
                      if (!allowed) {
                        return (
                          <span>
                            {dItem.diagnosisName === otherDrug
                              ? otherDrug
                              : dItem.diagnosisName}
                          </span>
                        );
                      }

                      return (
                        <span
                          className={
                            dItem.diagnosisName === otherDrug
                              ? ""
                              : "underline cursor-pointer"
                          }
                          onClick={() =>
                            dItem.diagnosisName === otherDrug
                              ? null
                              : handleClickOpenDialog(dItem.id)
                          }
                        >
                          {dItem.diagnosisName === otherDrug
                            ? otherDrug
                            : dItem.diagnosisName}
                        </span>
                      );
                    }}
                  </RoleCheck>
                </TableCell>
                <TableCell align="center">
                  {moment(dItem.createdAt).format("yyyy/MM/DD")}
                </TableCell>
                <RoleCheck role="admin">
                  {({ allowed }) => {
                    if (!allowed) {
                      return null;
                    }

                    return (
                      <TableCell align="center">
                        <Button
                          variant="text"
                          size="small"
                          onClick={() => handleOpenDeleteDialog(dItem.id)}
                        >
                          <DeleteIcon />
                        </Button>
                      </TableCell>
                    );
                  }}
                </RoleCheck>
              </TableRow>
            ))}

            {diagnosisList?.data?.length === 0 && (
              <TableRow>
                <TableCell colSpan={4}>
                  <div className="text-center font-bold">{noData}</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        className="custom-pagination"
        component="div"
        count={apiPagi.totalItems}
        rowsPerPage={apiPagi.itemsPerPage}
        page={apiPagi.page - 1}
        labelRowsPerPage={textRowsPerPage}
        onPageChange={(e, page) => {
          apiPagi.setPage(page + 1);
        }}
        onRowsPerPageChange={(e) => {
          apiPagi.setItemsPerPage(+e.target.value);
        }}
        labelDisplayedRows={(p) => `${p.from}-${p.to} / ${p.count}件`}
      />

      {openDialog && (
        <DialogAddEditDrug
          openDialog={openDialog}
          onCloseDialog={() => handleCloseDialog()}
          drugItemId={drugItemId}
          diagnosisListArray={diagnosisList?.data}
          prescriptionDrugListArray={prescriptionDrugList?.data}
          onCheckResult={(value) => handleCheckResult(value)}
        />
      )}

      {openSnackBar && (
        <AlertCustom
          openSnackBar={openSnackBar}
          onCloseSnack={() => handleCloseSnack()}
          alertMessage={alertMessage}
        />
      )}

      {openDeleteDialog && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openDeleteDialog}>
            <Box sx={deleteModalStyle}>
              <div className="mb-8">
                <Typography
                  variant="h5"
                  fontWeight={700}
                  className="text-center"
                >
                  管理者の追加
                </Typography>
              </div>
              <div className="mb-16">
                <Typography variant="body1" className="text-center">
                  アカウント本当に削除しますか？
                </Typography>
              </div>
              <div className="flex">
                <div className="w-6/12 mr-2 ml-4 c">
                  <Button
                    variant="outlined"
                    size="large"
                    className="w-full custom-button"
                    type="submit"
                    onClick={handleCloseDeleteDialog}
                  >
                    戻る
                  </Button>
                </div>
                <div className="w-6/12 mr-4 ml-2">
                  <Button
                    variant="contained"
                    size="large"
                    className="w-full custom-button"
                    type="submit"
                    disabled={isDeleting}
                    onClick={() => {
                      deleteDrug();
                    }}
                  >
                    削除する
                  </Button>
                </div>
              </div>
            </Box>
          </Fade>
        </Modal>
      )}
    </StyledAdminTable>
  );
};
