/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ProfessionAndEnvironmentOfMom,
    ProfessionAndEnvironmentOfMomFromJSON,
    ProfessionAndEnvironmentOfMomToJSON,
    ProfessionAndEnvironmentOfMomPagination,
    ProfessionAndEnvironmentOfMomPaginationFromJSON,
    ProfessionAndEnvironmentOfMomPaginationToJSON,
} from '../models';

export interface AdminProfessionAndEnvironmentOfMomsGetRequest {
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
    userId?: number;
    motherId?: number;
}

export interface AdminProfessionAndEnvironmentOfMomsPostRequest {
    professionAndEnvironmentOfMom?: ProfessionAndEnvironmentOfMom;
}

export interface ProfessionAndEnvironmentOfMomsIdDeleteRequest {
    id: number;
}

export interface ProfessionAndEnvironmentOfMomsIdGetRequest {
    id: number;
}

export interface ProfessionAndEnvironmentOfMomsIdPutRequest {
    id: number;
    professionAndEnvironmentOfMom?: ProfessionAndEnvironmentOfMom;
}

export interface ProfessionAndEnvironmentOfMomsPostRequest {
    professionAndEnvironmentOfMom?: ProfessionAndEnvironmentOfMom;
}

export interface ProfessionsAndEnvironmentShareDetailGetRequest {
    sourceUserId: number;
}

/**
 * 
 */
export class ProfessionAndEnvironmentOfMomsApi extends runtime.BaseAPI {

    /**
     * 
     */
    adminProfessionAndEnvironmentOfMomsGetRaw = async (requestParameters: AdminProfessionAndEnvironmentOfMomsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProfessionAndEnvironmentOfMomPagination>> => {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.motherId !== undefined) {
            queryParameters['motherId'] = requestParameters.motherId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/professionAndEnvironmentOfMoms`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfessionAndEnvironmentOfMomPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    adminProfessionAndEnvironmentOfMomsGet = async (requestParameters: AdminProfessionAndEnvironmentOfMomsGetRequest = {}, initOverrides?: RequestInit): Promise<ProfessionAndEnvironmentOfMomPagination> => {
        const response = await this.adminProfessionAndEnvironmentOfMomsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    adminProfessionAndEnvironmentOfMomsPostRaw = async (requestParameters: AdminProfessionAndEnvironmentOfMomsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProfessionAndEnvironmentOfMom>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/professionAndEnvironmentOfMoms`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProfessionAndEnvironmentOfMomToJSON(requestParameters.professionAndEnvironmentOfMom),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfessionAndEnvironmentOfMomFromJSON(jsonValue));
    }

    /**
     */
    adminProfessionAndEnvironmentOfMomsPost = async (requestParameters: AdminProfessionAndEnvironmentOfMomsPostRequest = {}, initOverrides?: RequestInit): Promise<ProfessionAndEnvironmentOfMom> => {
        const response = await this.adminProfessionAndEnvironmentOfMomsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    professionAndEnvironmentOfMomsIdDeleteRaw = async (requestParameters: ProfessionAndEnvironmentOfMomsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling professionAndEnvironmentOfMomsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/professionAndEnvironmentOfMoms/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    professionAndEnvironmentOfMomsIdDelete = async (requestParameters: ProfessionAndEnvironmentOfMomsIdDeleteRequest, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.professionAndEnvironmentOfMomsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    professionAndEnvironmentOfMomsIdGetRaw = async (requestParameters: ProfessionAndEnvironmentOfMomsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProfessionAndEnvironmentOfMom>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling professionAndEnvironmentOfMomsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/professionAndEnvironmentOfMoms/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfessionAndEnvironmentOfMomFromJSON(jsonValue));
    }

    /**
     * 
     */
    professionAndEnvironmentOfMomsIdGet = async (requestParameters: ProfessionAndEnvironmentOfMomsIdGetRequest, initOverrides?: RequestInit): Promise<ProfessionAndEnvironmentOfMom> => {
        const response = await this.professionAndEnvironmentOfMomsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    professionAndEnvironmentOfMomsIdPutRaw = async (requestParameters: ProfessionAndEnvironmentOfMomsIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProfessionAndEnvironmentOfMom>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling professionAndEnvironmentOfMomsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/professionAndEnvironmentOfMoms/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProfessionAndEnvironmentOfMomToJSON(requestParameters.professionAndEnvironmentOfMom),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfessionAndEnvironmentOfMomFromJSON(jsonValue));
    }

    /**
     */
    professionAndEnvironmentOfMomsIdPut = async (requestParameters: ProfessionAndEnvironmentOfMomsIdPutRequest, initOverrides?: RequestInit): Promise<ProfessionAndEnvironmentOfMom> => {
        const response = await this.professionAndEnvironmentOfMomsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    professionAndEnvironmentOfMomsPostRaw = async (requestParameters: ProfessionAndEnvironmentOfMomsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProfessionAndEnvironmentOfMom>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/professionAndEnvironmentOfMoms`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProfessionAndEnvironmentOfMomToJSON(requestParameters.professionAndEnvironmentOfMom),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfessionAndEnvironmentOfMomFromJSON(jsonValue));
    }

    /**
     */
    professionAndEnvironmentOfMomsPost = async (requestParameters: ProfessionAndEnvironmentOfMomsPostRequest = {}, initOverrides?: RequestInit): Promise<ProfessionAndEnvironmentOfMom> => {
        const response = await this.professionAndEnvironmentOfMomsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    professionsAndEnvironmentShareDetailGetRaw = async (requestParameters: ProfessionsAndEnvironmentShareDetailGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProfessionAndEnvironmentOfMom>> => {
        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling professionsAndEnvironmentShareDetailGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/professionsAndEnvironment/share/detail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProfessionAndEnvironmentOfMomFromJSON(jsonValue));
    }

    /**
     * 
     */
    professionsAndEnvironmentShareDetailGet = async (requestParameters: ProfessionsAndEnvironmentShareDetailGetRequest, initOverrides?: RequestInit): Promise<ProfessionAndEnvironmentOfMom> => {
        const response = await this.professionsAndEnvironmentShareDetailGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
