/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InfantMedicalExam } from './InfantMedicalExam';
import {
    InfantMedicalExamFromJSON,
    InfantMedicalExamFromJSONTyped,
    InfantMedicalExamToJSON,
} from './InfantMedicalExam';
import type { PaginationInfo } from './PaginationInfo';
import {
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './PaginationInfo';

/**
 * 
 * @export
 * @interface InfantMedicalExamPagination
 */
export interface InfantMedicalExamPagination {
    /**
     * 
     * @type {Array<InfantMedicalExam>}
     * @memberof InfantMedicalExamPagination
     */
    data?: Array<InfantMedicalExam>;
    /**
     * 
     * @type {PaginationInfo}
     * @memberof InfantMedicalExamPagination
     */
    meta?: PaginationInfo;
}

/**
 * Check if a given object implements the InfantMedicalExamPagination interface.
 */
export function instanceOfInfantMedicalExamPagination(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InfantMedicalExamPaginationFromJSON(json: any): InfantMedicalExamPagination {
    return InfantMedicalExamPaginationFromJSONTyped(json, false);
}

export function InfantMedicalExamPaginationFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfantMedicalExamPagination {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(InfantMedicalExamFromJSON)),
        'meta': !exists(json, 'meta') ? undefined : PaginationInfoFromJSON(json['meta']),
    };
}

export function InfantMedicalExamPaginationToJSON(value?: InfantMedicalExamPagination | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(InfantMedicalExamToJSON)),
        'meta': PaginationInfoToJSON(value.meta),
    };
}

