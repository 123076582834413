/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MedicalConsultant,
    MedicalConsultantFromJSON,
    MedicalConsultantToJSON,
    MedicalConsultantPagination,
    MedicalConsultantPaginationFromJSON,
    MedicalConsultantPaginationToJSON,
} from '../models';

export interface AdminMedicalConsultantsGetRequest {
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
    userId?: number;
}

export interface MedicalConsultantsGetRequest {
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface MedicalConsultantsIdDeleteRequest {
    id: number;
}

export interface MedicalConsultantsIdGetRequest {
    id: number;
}

export interface MedicalConsultantsIdPutRequest {
    id: number;
    medicalConsultant?: MedicalConsultant;
}

export interface MedicalConsultantsPostRequest {
    medicalConsultant?: MedicalConsultant;
}

export interface MedicalConsultantsShareAllGetRequest {
    sourceUserId: number;
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface MedicalConsultantsShareDetailGetRequest {
    recordId: number;
    sourceUserId: number;
}

/**
 * 
 */
export class MedicalConsultantsApi extends runtime.BaseAPI {

    /**
     * 
     */
    adminMedicalConsultantsGetRaw = async (requestParameters: AdminMedicalConsultantsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MedicalConsultantPagination>> => {
        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling adminMedicalConsultantsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/medicalConsultants`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MedicalConsultantPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    adminMedicalConsultantsGet = async (requestParameters: AdminMedicalConsultantsGetRequest, initOverrides?: RequestInit): Promise<MedicalConsultantPagination> => {
        const response = await this.adminMedicalConsultantsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    medicalConsultantsGetRaw = async (requestParameters: MedicalConsultantsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MedicalConsultantPagination>> => {
        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling medicalConsultantsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/medicalConsultants`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MedicalConsultantPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    medicalConsultantsGet = async (requestParameters: MedicalConsultantsGetRequest, initOverrides?: RequestInit): Promise<MedicalConsultantPagination> => {
        const response = await this.medicalConsultantsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    medicalConsultantsIdDeleteRaw = async (requestParameters: MedicalConsultantsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling medicalConsultantsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/medicalConsultants/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    medicalConsultantsIdDelete = async (requestParameters: MedicalConsultantsIdDeleteRequest, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.medicalConsultantsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    medicalConsultantsIdGetRaw = async (requestParameters: MedicalConsultantsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MedicalConsultant>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling medicalConsultantsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/medicalConsultants/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MedicalConsultantFromJSON(jsonValue));
    }

    /**
     * 
     */
    medicalConsultantsIdGet = async (requestParameters: MedicalConsultantsIdGetRequest, initOverrides?: RequestInit): Promise<MedicalConsultant> => {
        const response = await this.medicalConsultantsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    medicalConsultantsIdPutRaw = async (requestParameters: MedicalConsultantsIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MedicalConsultant>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling medicalConsultantsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/medicalConsultants/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MedicalConsultantToJSON(requestParameters.medicalConsultant),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MedicalConsultantFromJSON(jsonValue));
    }

    /**
     */
    medicalConsultantsIdPut = async (requestParameters: MedicalConsultantsIdPutRequest, initOverrides?: RequestInit): Promise<MedicalConsultant> => {
        const response = await this.medicalConsultantsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    medicalConsultantsPostRaw = async (requestParameters: MedicalConsultantsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MedicalConsultant>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/medicalConsultants`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MedicalConsultantToJSON(requestParameters.medicalConsultant),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MedicalConsultantFromJSON(jsonValue));
    }

    /**
     */
    medicalConsultantsPost = async (requestParameters: MedicalConsultantsPostRequest = {}, initOverrides?: RequestInit): Promise<MedicalConsultant> => {
        const response = await this.medicalConsultantsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    medicalConsultantsShareAllGetRaw = async (requestParameters: MedicalConsultantsShareAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MedicalConsultantPagination>> => {
        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling medicalConsultantsShareAllGet.');
        }

        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling medicalConsultantsShareAllGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/medicalConsultants/share/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MedicalConsultantPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    medicalConsultantsShareAllGet = async (requestParameters: MedicalConsultantsShareAllGetRequest, initOverrides?: RequestInit): Promise<MedicalConsultantPagination> => {
        const response = await this.medicalConsultantsShareAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    medicalConsultantsShareDetailGetRaw = async (requestParameters: MedicalConsultantsShareDetailGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MedicalConsultant>> => {
        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling medicalConsultantsShareDetailGet.');
        }

        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling medicalConsultantsShareDetailGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.recordId !== undefined) {
            queryParameters['recordId'] = requestParameters.recordId;
        }

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/medicalConsultants/share/detail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MedicalConsultantFromJSON(jsonValue));
    }

    /**
     * 
     */
    medicalConsultantsShareDetailGet = async (requestParameters: MedicalConsultantsShareDetailGetRequest, initOverrides?: RequestInit): Promise<MedicalConsultant> => {
        const response = await this.medicalConsultantsShareDetailGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
