import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
} from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import ArticleIcon from "@mui/icons-material/Article";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getUserListUrl } from "@/pages/user/user-list/UserListPage.shared";
import { useLocation, useNavigate } from "react-router-dom";
import { getAdminListUrl } from "@/pages/admin/admin-list/AdminListPage.shared";
import { getDrugListUrl } from "@/pages/drug/drug-list/DrugListPage.shared";
import {
  ADMIN_PATH,
  NOTI_CAMPAIGN_PATH,
  DRUG_PATH,
  USER_PATH,
} from "@/constants";
import { getNotiCampaignListUrl } from "@/pages/noti-campaign/noti-campaign-list/NotiCampaignListPage.shared";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";

function hasChildren(item) {
  const { items: children } = item;

  if (children === undefined) {
    return false;
  }

  if (children.constructor !== Array) {
    return false;
  }

  if (children.length === 0) {
    return false;
  }

  return true;
}

const MenuItem = ({ item }) => {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
  return <Component item={item} />;
};

const SingleLevel = ({ item }) => {
  const navigate = useNavigate();
  const isActive = global.location.pathname === item.url;

  const onClick = React.useCallback(
    () => item.url && navigate(item.url, { replace: isActive }),
    [isActive, item.url, navigate]
  );

  return (
    <ListItem
      button
      onClick={onClick}
      className={item.isActive?.() ? "child actived" : "child"}
    >
      <ListItemText primary={item.title} />
    </ListItem>
  );
};

const MultiLevel = ({ item }) => {
  const { items: children } = item;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <div className="mb-2">
      <ListItem
        button
        onClick={handleClick}
        className={item.isActive?.() ? "actived" : ""}
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.title} />
        <ListItemIcon>
          {open ? <ExpandMoreIcon /> : <ChevronRightIcon />}
        </ListItemIcon>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children.map((child, key) => (
            <MenuItem key={key} item={child} />
          ))}
        </List>
      </Collapse>
    </div>
  );
};

const StyledMasterMenu = styled.div`
  .MuiListItemIcon-root,
  .MuiTypography-root {
    color: white;
  }
  .MuiListItemIcon-root {
    min-width: 36px;
  }
  .MuiCollapse-root {
    padding-top: 5px;
  }
  .MuiButtonBase-root {
    border-radius: 10px;

    &.child {
      width: calc(100% - 40px);
      margin-left: 40px;
    }
    &.actived {
      background: rgba(255, 255, 255, 0.2);
    }
  }
`;

interface IMasterMenuProps {}

export const MasterMenu = (
  props: React.PropsWithChildren<IMasterMenuProps>
) => {
  // use useLocation to rerender after route change
  useLocation();

  return (
    <StyledMasterMenu>
      {menu.map((item, key) => (
        <MenuItem key={key} item={item} />
      ))}
    </StyledMasterMenu>
  );
};

export const menu = [
  {
    title: "ユーザー管理",
    icon: <PersonOutlineIcon />,
    isActive: () => window.location.pathname.startsWith(`/${USER_PATH}`),
    items: [
      {
        title: "リスト",
        url: getUserListUrl(),
        isActive: () => window.location.pathname.startsWith(getUserListUrl()),
      },
    ],
  },
  {
    title: "管理者リスト",
    icon: <VerifiedUserOutlinedIcon />,
    isActive: () => window.location.pathname.startsWith(`/${ADMIN_PATH}`),
    items: [
      {
        title: "リスト",
        url: getAdminListUrl(),
        isActive: () => window.location.pathname.startsWith(getAdminListUrl()),
      },
    ],
  },
  {
    title: "薬剤リスト",
    icon: <ArticleIcon />,
    isActive: () => window.location.pathname.startsWith(`/${DRUG_PATH}`),
    items: [
      {
        title: "リスト",
        url: getDrugListUrl(),
        isActive: () => window.location.pathname.startsWith(getDrugListUrl()),
      },
    ],
  },
  {
    title: "イベント通知",
    icon: <NotificationsNoneIcon />,
    isActive: () =>
      window.location.pathname.startsWith(`/${NOTI_CAMPAIGN_PATH}`),
    items: [
      {
        title: "通知リスト",
        url: getNotiCampaignListUrl(),
        isActive: () =>
          window.location.pathname.startsWith(getNotiCampaignListUrl()),
      },
    ],
  },
];
