/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';
import type { ChildAllOf } from './ChildAllOf';
import {
    ChildAllOfFromJSON,
    ChildAllOfFromJSONTyped,
    ChildAllOfToJSON,
} from './ChildAllOf';

/**
 * 
 * @export
 * @interface Child
 */
export interface Child {
    /**
     * 
     * @type {number}
     * @memberof Child
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Child
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Child
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof Child
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof Child
     */
    type?: ChildTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Child
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Child
     */
    gender?: ChildGenderEnum;
    /**
     * 
     * @type {number}
     * @memberof Child
     */
    babyId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Child
     */
    fetusId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Child
     */
    image?: string | null;
}


/**
 * @export
 */
export const ChildTypeEnum = {
    Fetus: 'fetus',
    Baby: 'baby'
} as const;
export type ChildTypeEnum = typeof ChildTypeEnum[keyof typeof ChildTypeEnum];

/**
 * @export
 */
export const ChildGenderEnum = {
    Male: 'MALE',
    Female: 'FEMALE',
    Unknown: 'UNKNOWN'
} as const;
export type ChildGenderEnum = typeof ChildGenderEnum[keyof typeof ChildGenderEnum];


/**
 * Check if a given object implements the Child interface.
 */
export function instanceOfChild(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChildFromJSON(json: any): Child {
    return ChildFromJSONTyped(json, false);
}

export function ChildFromJSONTyped(json: any, ignoreDiscriminator: boolean): Child {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'babyId': !exists(json, 'babyId') ? undefined : json['babyId'],
        'fetusId': !exists(json, 'fetusId') ? undefined : json['fetusId'],
        'image': !exists(json, 'image') ? undefined : json['image'],
    };
}

export function ChildToJSON(value?: Child | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'userId': value.userId,
        'type': value.type,
        'name': value.name,
        'gender': value.gender,
        'babyId': value.babyId,
        'fetusId': value.fetusId,
        'image': value.image,
    };
}

