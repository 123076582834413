const userInformationSheetName = "ユーザーリスト";
const fetusSheetName = "おなかの赤ちゃんの基本情報";
const babyName = "子どもの基本情報";
const healthOfMotherSheetName = "ママの健康状態";
const childBirthSheetName = " 出産の状態";
const vaccineRecordSheetName = " 予防接種管理";
const recordSideEffectSheetName = "副反応の記録";
const medicalRecordSheetName = "罹患歴の記録";
const medicalConsultantSheetName = "医療機関受診の記録";
const jobAndEnvironmentSheetName = "ママの職業と環境";
const pregnantDiagnosisSheetName = "妊娠診断の記録";
const childrenBodiesName = "お子さまー身体発育曲線";
const bodiesName = "妊娠中の体重グラフ";
const infantAfterOneWeekName = "早期新生児期（生後1週間以内)の経過";
const infantEarlyNeonatalPeriodName = "後期新生児期の経過";
const infantInspectionRecordName = "スクリーニング検査";
const infantOneMonthOldName = "1か月児健康診査";
const infantThreeToFourMonthOldName = "3〜4か月児健康診査";
const infantSixToSevenMonthOldName = "6〜7か月児健康診査";
const infantNineToTenMonthOldName = "9〜10か月児健康診査";
const infantOneYearOldName = "1歳児健康診査";
const infantOneHalfYearOldName = "1歳6か月児健康診査";
const infantTwoYearOldName = "2歳児健康診査";
const infantThreeYearOldName = "3歳児健康診査";
const infantFourOldName = "4 歳児健康診査";
const infantFiveOldName = "5 歳児健康診査";
const infantSixOldName = "6 歳児健康診査";

export const EXPORTS: string[] = [
  userInformationSheetName,
  fetusSheetName,
  babyName,
  healthOfMotherSheetName,
  childBirthSheetName,
  vaccineRecordSheetName,
  recordSideEffectSheetName,
  medicalRecordSheetName,
  medicalConsultantSheetName,
  jobAndEnvironmentSheetName,
  pregnantDiagnosisSheetName,
  childrenBodiesName,
  bodiesName,
  infantAfterOneWeekName,
  infantEarlyNeonatalPeriodName,
  infantInspectionRecordName,
  infantOneMonthOldName,
  infantThreeToFourMonthOldName,
  infantSixToSevenMonthOldName,
  infantNineToTenMonthOldName,
  infantOneYearOldName,
  infantOneHalfYearOldName,
  infantTwoYearOldName,
  infantThreeYearOldName,
  infantFourOldName,
  infantFiveOldName,
  infantSixOldName,
];
