import React from "react";
import {
  AvatarCustom,
  BloodSelectionType,
  TeethSelectCustom,
  TextBlockCustom,
} from "@/components/display";
import { Grid } from "@mui/material";
import { COLORS } from "@/constants";
import {
  Baby,
  InfantMedicalExam,
  InfantSixYearOldGingivaMucousMembraneEnum,
  infantSixYearOldsApi,
} from "@/api";
import { useQuery } from "react-query";
import { getNextYearFromDate, toJapanDate } from "@/utils";
import { translate } from "@/helpers";

interface IInfantSixYearOldDetailProps {
  infantMedicalExamItem?: InfantMedicalExam;
  baby?: Baby;
}

export const InfantSixYearOldDetail = (
  props: React.PropsWithChildren<IInfantSixYearOldDetailProps>
) => {
  const { infantMedicalExamItem, baby } = props;

  //--- Get medical consultant
  const { data: infantSixYearOld } = useQuery({
    queryKey: "GET_INFANT_SIX_YEAR_OLD_BY_ID",
    queryFn: () => {
      return infantSixYearOldsApi.adminInfantSixYearOldsIdGet({
        id: infantMedicalExamItem?.infantId,
        userId: infantMedicalExamItem?.userId,
      });
    },
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextBlockCustom
          classText="font-bold text-center mb-4"
          textHeader={`${translate("infantMedicalExam.text.age14")} ${translate(
            "infantMedicalExam.text.detail"
          )}`}
          textSize={18}
          headerColor={COLORS.primary}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.implementationDate"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantSixYearOld?.implementationDate
                  ? toJapanDate(infantSixYearOld?.implementationDate)
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.ageYear")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                (infantSixYearOld?.year
                  ? `${infantSixYearOld?.year}${translate("infoHealthMon.age")}`
                  : "") +
                (infantSixYearOld?.month
                  ? `${infantSixYearOld?.month}${translate("month.txt")}`
                  : "")
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.photographOfMedical"
              )}
              headerColor={COLORS.approxGrey}
            />

            <div className="flex items-center">
              {infantSixYearOld?.photo1 && (
                <div className="mr-2">
                  <AvatarCustom imgSrc={infantSixYearOld?.photo1} />
                </div>
              )}

              {infantSixYearOld?.photo2 && (
                <AvatarCustom imgSrc={infantSixYearOld?.photo2} />
              )}
            </div>
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.bodyWeight"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixYearOld?.bodyWeight
                  ? infantSixYearOld?.bodyWeight.toString() + "kg"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.height")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixYearOld?.height
                  ? infantSixYearOld?.height.toString() + "cm"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.kaupIndex"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixYearOld?.kaupIndex
                  ? infantSixYearOld?.kaupIndex.toString()
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.headCircumference"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixYearOld?.headCircumference
                  ? infantSixYearOld?.headCircumference.toString() + "cm"
                  : ""
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          {/* 栄養状態/健康・要観察/特記事項など */}
          <Grid item xs={12}>
            <TextBlockCustom
              classText="font-bold"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.nutritionalHealth"
              )}
              headerColor={COLORS.primary}
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.nutritionalStatus"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantSixYearOld?.nutritionalStatus
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantSixYearOld?.nutritionalStatus
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.eyeAbnormalities"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantSixYearOld?.eyeAbnormalities
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantSixYearOld?.eyeAbnormalities
                    )
                  : ""
              }
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantSixYearOld?.eyeAbnormalitiesNote
                  ? infantSixYearOld?.eyeAbnormalitiesNote
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.sight")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                (infantSixYearOld?.sightLeft
                  ? `${translate("title.left")} ${infantSixYearOld?.sightLeft}`
                  : "") +
                (infantSixYearOld?.sightRight
                  ? ` ${translate("title.right")} ${
                      infantSixYearOld?.sightRight
                    }`
                  : "")
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.earAbnormalities"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantSixYearOld?.earAbnormalities
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantSixYearOld?.earAbnormalities
                    )
                  : ""
              }
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantSixYearOld?.earAbnormalitiesNote
                  ? infantSixYearOld?.earAbnormalitiesNote
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.healthObservation"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantSixYearOld?.healthObservation
                  ? infantSixYearOld?.healthObservation
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.remarks")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantSixYearOld?.remarks ? infantSixYearOld?.remarks : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.facilityOrPersonName"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantSixYearOld?.facilityOrPersonName
                  ? infantSixYearOld?.facilityOrPersonName
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.dayCaregiver"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantSixYearOld?.dayCaregiver
                  ? infantSixYearOld?.dayCaregiver
                  : ""
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          {/* 予防接種 */}
          <Grid item xs={12}>
            <TextBlockCustom
              classText="font-bold"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.vaccination"
              )}
              headerColor={COLORS.primary}
            />
          </Grid>

          <Grid item xs={12}>
            {!!infantSixYearOld?.whatReceiving &&
              infantSixYearOld?.whatReceiving
                ?.split(",")
                .map(
                  (item, index) =>
                    !!item && (
                      <TextBlockCustom
                        key={index}
                        classText="font-medium mb-2"
                        textHeader={translate(
                          `infantMedicalExamFormScreen.checkbox.${item}`
                        )}
                      />
                    )
                )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          {/* 歯の状態 */}
          <Grid item xs={12}>
            <TextBlockCustom
              classText="font-bold"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.teethCondition"
              )}
              headerColor={COLORS.primary}
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.cariesRequiringTreatment"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixYearOld?.cariesRequiringTreatment
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantSixYearOld?.cariesRequiringTreatment
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.milkTeeth"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixYearOld?.milkTeeth
                  ? infantSixYearOld?.milkTeeth.toString() +
                    translate("title.book")
                  : undefined
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.permanentTeeth"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixYearOld?.permanentTeeth
                  ? infantSixYearOld?.permanentTeeth.toString() +
                    translate("title.book")
                  : undefined
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.total")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixYearOld?.total
                  ? infantSixYearOld?.total.toString() + translate("title.book")
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.dirtyTeeth"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixYearOld?.dirtyTeeth
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantSixYearOld?.dirtyTeeth
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.gingivaMucousMembrane"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixYearOld?.gingivaMucousMembrane
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantSixYearOld?.gingivaMucousMembrane
                    )
                  : ""
              }
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixYearOld?.gingivaMucousMembraneNote &&
                infantSixYearOld?.gingivaMucousMembrane ===
                  InfantSixYearOldGingivaMucousMembraneEnum.CanBe
                  ? infantSixYearOld?.gingivaMucousMembraneNote
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.engagement"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixYearOld?.engagement
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantSixYearOld?.engagement
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.abnormalToothOralDisease"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixYearOld?.abnormalToothOralDisease
                  ? infantSixYearOld?.abnormalToothOralDisease
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.examinationDate"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixYearOld?.examinationDate
                  ? toJapanDate(infantSixYearOld?.examinationDate)
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.teethCondition"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.maxilla")}
              headerColor={COLORS.approxGrey}
            />

            <TeethSelectCustom
              fieldType={BloodSelectionType.MultipleFields}
              fieldDescriptions={["6", "5", "4", "3", "2", "1"]}
              initialValues={
                !!infantSixYearOld?.rightMolarsMaxillaA &&
                JSON.parse(infantSixYearOld.rightMolarsMaxillaA)
              }
            />

            <TeethSelectCustom
              fieldType={BloodSelectionType.MultipleFields}
              fieldDescriptions={["E", "D", "C", "B", "A"]}
              initialValues={
                !!infantSixYearOld?.rightMolarsMaxillaB &&
                JSON.parse(infantSixYearOld?.rightMolarsMaxillaB)
              }
            />

            <TeethSelectCustom
              fieldType={BloodSelectionType.MultipleFields}
              fieldDescriptions={["1", "2", "3", "4", "5", "6"]}
              initialValues={
                !!infantSixYearOld?.leftFrontToothMaxillaA &&
                JSON.parse(infantSixYearOld?.leftFrontToothMaxillaA)
              }
            />

            <TeethSelectCustom
              fieldType={BloodSelectionType.MultipleFields}
              fieldDescriptions={["A", "B", "C", "D", "E"]}
              initialValues={
                !!infantSixYearOld?.leftFrontToothMaxillaB &&
                JSON.parse(infantSixYearOld?.leftFrontToothMaxillaB)
              }
            />

            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.lowerJaw"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TeethSelectCustom
              fieldType={BloodSelectionType.MultipleFields}
              fieldDescriptions={["E", "D", "C", "B", "A"]}
              initialValues={
                !!infantSixYearOld?.rightMolarsLowerJawA &&
                JSON.parse(infantSixYearOld.rightMolarsLowerJawA)
              }
            />

            <TeethSelectCustom
              fieldType={BloodSelectionType.MultipleFields}
              fieldDescriptions={["6", "5", "4", "3", "2", "1"]}
              initialValues={
                !!infantSixYearOld?.rightMolarsLowerJawB &&
                JSON.parse(infantSixYearOld?.rightMolarsLowerJawB)
              }
            />

            <TeethSelectCustom
              fieldType={BloodSelectionType.MultipleFields}
              fieldDescriptions={["A", "B", "C", "D", "E"]}
              initialValues={
                !!infantSixYearOld?.leftFrontToothLowerJawA &&
                JSON.parse(infantSixYearOld?.leftFrontToothLowerJawA)
              }
            />

            <TeethSelectCustom
              fieldType={BloodSelectionType.MultipleFields}
              fieldDescriptions={["1", "2", "3", "4", "5", "6"]}
              initialValues={
                !!infantSixYearOld?.leftFrontToothLowerJawB &&
                JSON.parse(infantSixYearOld?.leftFrontToothLowerJawB)
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          {/* 検尿 */}
          <Grid item xs={12}>
            <TextBlockCustom
              classText="font-bold"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.urinalysis"
              )}
              headerColor={COLORS.primary}
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.urineProtein"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixYearOld?.urineProtein
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantSixYearOld?.urineProtein
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.urineSugar"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixYearOld?.urineSugar
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantSixYearOld?.urineSugar
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.urineOccultBlood"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixYearOld?.urineOccultBlood
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantSixYearOld?.urineOccultBlood
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.whiteBloodCells"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixYearOld?.whiteBloodCells
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantSixYearOld?.whiteBloodCells
                    )
                  : ""
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          {/* 保護者の記録（6歳の頃）*/}
          <Grid item xs={12}>
            <TextBlockCustom
              classText="font-bold"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.parentRecordSixYear"
              )}
              headerColor={COLORS.primary}
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={
                getNextYearFromDate(baby?.birthday, 6) +
                translate("infantMedicalExamFormScreen.text.sixYearFromDate")
              }
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixYearOld?.parentMessage
                  ? infantSixYearOld?.parentMessage
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.recordingDate"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixYearOld?.recordingDate
                  ? toJapanDate(infantSixYearOld?.recordingDate)
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.standOnOneLeg"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixYearOld?.standOnOneLeg
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantSixYearOld?.standOnOneLeg
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.writeByImitating"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixYearOld?.writeByImitating
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantSixYearOld?.writeByImitating
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.knowFrontBackLeftRight"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixYearOld?.knowFrontBackLeftRight
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantSixYearOld?.knowFrontBackLeftRight
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.writeNameInHiragana"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixYearOld?.writeNameInHiragana
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantSixYearOld?.writeNameInHiragana
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.putUpWithToysAndSweets"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixYearOld?.putUpWithToysAndSweets
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantSixYearOld?.putUpWithToysAndSweets
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.keepPromisesAndRules"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixYearOld?.keepPromisesAndRules
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantSixYearOld?.keepPromisesAndRules
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.firstMolarsGrow"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixYearOld?.firstMolarsGrow
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantSixYearOld?.firstMolarsGrow
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.brushYourTeeth1"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixYearOld?.brushYourTeeth
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantSixYearOld?.brushYourTeeth
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.eatBreakfast"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixYearOld?.eatBreakfast
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantSixYearOld?.eatBreakfast
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.childRearing"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixYearOld?.childRearing
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantSixYearOld?.childRearing
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.raisingChildren"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixYearOld?.raisingChildren
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantSixYearOld?.raisingChildren
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.yourGrowth"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantSixYearOld?.yourGrowth ? infantSixYearOld?.yourGrowth : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.photo")}
              headerColor={COLORS.approxGrey}
            />

            {infantSixYearOld?.photo3 && (
              <AvatarCustom imgSrc={infantSixYearOld?.photo3} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
