/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PregnantDiagnosis,
    PregnantDiagnosisFromJSON,
    PregnantDiagnosisToJSON,
    PregnantDiagnosisPagination,
    PregnantDiagnosisPaginationFromJSON,
    PregnantDiagnosisPaginationToJSON,
} from '../models';

export interface AdminPregnantDiagnosesGetRequest {
    pregnantId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
    userId?: number;
}

export interface PregnantDiagnosesGetRequest {
    pregnantId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface PregnantDiagnosesIdDeleteRequest {
    id: number;
}

export interface PregnantDiagnosesIdGetRequest {
    id: number;
}

export interface PregnantDiagnosesIdPutRequest {
    id: number;
    pregnantDiagnosis?: PregnantDiagnosis;
}

export interface PregnantDiagnosesPatchRequest {
    pregnantDiagnosis?: PregnantDiagnosis;
}

export interface PregnantDiagnosesShareAllGetRequest {
    sourceUserId: number;
    pregnantId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

/**
 * 
 */
export class PregnantDiagnosesApi extends runtime.BaseAPI {

    /**
     * 
     */
    adminPregnantDiagnosesGetRaw = async (requestParameters: AdminPregnantDiagnosesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PregnantDiagnosisPagination>> => {
        if (requestParameters.pregnantId === null || requestParameters.pregnantId === undefined) {
            throw new runtime.RequiredError('pregnantId','Required parameter requestParameters.pregnantId was null or undefined when calling adminPregnantDiagnosesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.pregnantId !== undefined) {
            queryParameters['pregnantId'] = requestParameters.pregnantId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/pregnantDiagnoses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PregnantDiagnosisPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    adminPregnantDiagnosesGet = async (requestParameters: AdminPregnantDiagnosesGetRequest, initOverrides?: RequestInit): Promise<PregnantDiagnosisPagination> => {
        const response = await this.adminPregnantDiagnosesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    pregnantDiagnosesGetRaw = async (requestParameters: PregnantDiagnosesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PregnantDiagnosisPagination>> => {
        if (requestParameters.pregnantId === null || requestParameters.pregnantId === undefined) {
            throw new runtime.RequiredError('pregnantId','Required parameter requestParameters.pregnantId was null or undefined when calling pregnantDiagnosesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.pregnantId !== undefined) {
            queryParameters['pregnantId'] = requestParameters.pregnantId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/pregnantDiagnoses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PregnantDiagnosisPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    pregnantDiagnosesGet = async (requestParameters: PregnantDiagnosesGetRequest, initOverrides?: RequestInit): Promise<PregnantDiagnosisPagination> => {
        const response = await this.pregnantDiagnosesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    pregnantDiagnosesIdDeleteRaw = async (requestParameters: PregnantDiagnosesIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pregnantDiagnosesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/pregnantDiagnoses/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    pregnantDiagnosesIdDelete = async (requestParameters: PregnantDiagnosesIdDeleteRequest, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.pregnantDiagnosesIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    pregnantDiagnosesIdGetRaw = async (requestParameters: PregnantDiagnosesIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PregnantDiagnosis>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pregnantDiagnosesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/pregnantDiagnoses/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PregnantDiagnosisFromJSON(jsonValue));
    }

    /**
     * 
     */
    pregnantDiagnosesIdGet = async (requestParameters: PregnantDiagnosesIdGetRequest, initOverrides?: RequestInit): Promise<PregnantDiagnosis> => {
        const response = await this.pregnantDiagnosesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    pregnantDiagnosesIdPutRaw = async (requestParameters: PregnantDiagnosesIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PregnantDiagnosis>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling pregnantDiagnosesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/pregnantDiagnoses/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PregnantDiagnosisToJSON(requestParameters.pregnantDiagnosis),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PregnantDiagnosisFromJSON(jsonValue));
    }

    /**
     */
    pregnantDiagnosesIdPut = async (requestParameters: PregnantDiagnosesIdPutRequest, initOverrides?: RequestInit): Promise<PregnantDiagnosis> => {
        const response = await this.pregnantDiagnosesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    pregnantDiagnosesPatchRaw = async (requestParameters: PregnantDiagnosesPatchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PregnantDiagnosis>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/pregnantDiagnoses`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PregnantDiagnosisToJSON(requestParameters.pregnantDiagnosis),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PregnantDiagnosisFromJSON(jsonValue));
    }

    /**
     */
    pregnantDiagnosesPatch = async (requestParameters: PregnantDiagnosesPatchRequest = {}, initOverrides?: RequestInit): Promise<PregnantDiagnosis> => {
        const response = await this.pregnantDiagnosesPatchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    pregnantDiagnosesShareAllGetRaw = async (requestParameters: PregnantDiagnosesShareAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PregnantDiagnosisPagination>> => {
        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling pregnantDiagnosesShareAllGet.');
        }

        if (requestParameters.pregnantId === null || requestParameters.pregnantId === undefined) {
            throw new runtime.RequiredError('pregnantId','Required parameter requestParameters.pregnantId was null or undefined when calling pregnantDiagnosesShareAllGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        if (requestParameters.pregnantId !== undefined) {
            queryParameters['pregnantId'] = requestParameters.pregnantId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/pregnantDiagnoses/share/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PregnantDiagnosisPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    pregnantDiagnosesShareAllGet = async (requestParameters: PregnantDiagnosesShareAllGetRequest, initOverrides?: RequestInit): Promise<PregnantDiagnosisPagination> => {
        const response = await this.pregnantDiagnosesShareAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
