/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Child,
    ChildFromJSON,
    ChildToJSON,
    ChildPagination,
    ChildPaginationFromJSON,
    ChildPaginationToJSON,
} from '../models';

export interface ChildrenGetRequest {
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface ChildrenIdGetRequest {
    id: number;
}

export interface ChildrenIdPutRequest {
    id: number;
    child?: Child;
}

export interface ChildrenShareAllGetRequest {
    sourceUserId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface ChildrenShareDetailGetRequest {
    childrenId: number;
    sourceUserId: number;
}

/**
 * 
 */
export class ChildrenApi extends runtime.BaseAPI {

    /**
     * 
     */
    childrenGetRaw = async (requestParameters: ChildrenGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ChildPagination>> => {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/children`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChildPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    childrenGet = async (requestParameters: ChildrenGetRequest = {}, initOverrides?: RequestInit): Promise<ChildPagination> => {
        const response = await this.childrenGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    childrenIdGetRaw = async (requestParameters: ChildrenIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Child>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling childrenIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/children/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChildFromJSON(jsonValue));
    }

    /**
     * 
     */
    childrenIdGet = async (requestParameters: ChildrenIdGetRequest, initOverrides?: RequestInit): Promise<Child> => {
        const response = await this.childrenIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    childrenIdPutRaw = async (requestParameters: ChildrenIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Child>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling childrenIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/children/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChildToJSON(requestParameters.child),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChildFromJSON(jsonValue));
    }

    /**
     * 
     */
    childrenIdPut = async (requestParameters: ChildrenIdPutRequest, initOverrides?: RequestInit): Promise<Child> => {
        const response = await this.childrenIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    childrenShareAllGetRaw = async (requestParameters: ChildrenShareAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ChildPagination>> => {
        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling childrenShareAllGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/children/share/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChildPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    childrenShareAllGet = async (requestParameters: ChildrenShareAllGetRequest, initOverrides?: RequestInit): Promise<ChildPagination> => {
        const response = await this.childrenShareAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    childrenShareDetailGetRaw = async (requestParameters: ChildrenShareDetailGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Child>> => {
        if (requestParameters.childrenId === null || requestParameters.childrenId === undefined) {
            throw new runtime.RequiredError('childrenId','Required parameter requestParameters.childrenId was null or undefined when calling childrenShareDetailGet.');
        }

        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling childrenShareDetailGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.childrenId !== undefined) {
            queryParameters['childrenId'] = requestParameters.childrenId;
        }

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/children/share/detail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChildFromJSON(jsonValue));
    }

    /**
     * 
     */
    childrenShareDetailGet = async (requestParameters: ChildrenShareDetailGetRequest, initOverrides?: RequestInit): Promise<Child> => {
        const response = await this.childrenShareDetailGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
