/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BabiesFilter
 */
export interface BabiesFilter {
    /**
     * 
     * @type {number}
     * @memberof BabiesFilter
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BabiesFilter
     */
    createdAt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BabiesFilter
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BabiesFilter
     */
    isDeleted?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof BabiesFilter
     */
    userId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BabiesFilter
     */
    motherId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BabiesFilter
     */
    childId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BabiesFilter
     */
    childBirthId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BabiesFilter
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BabiesFilter
     */
    gender?: BabiesFilterGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof BabiesFilter
     */
    birthday?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BabiesFilter
     */
    bloodType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BabiesFilter
     */
    bloodRh?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BabiesFilter
     */
    allergicContent?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BabiesFilter
     */
    image?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof BabiesFilter
     */
    illNameList?: Array<string> | null;
}


/**
 * @export
 */
export const BabiesFilterGenderEnum = {
    Male: 'MALE',
    Female: 'FEMALE',
    Unknown: 'UNKNOWN'
} as const;
export type BabiesFilterGenderEnum = typeof BabiesFilterGenderEnum[keyof typeof BabiesFilterGenderEnum];


/**
 * Check if a given object implements the BabiesFilter interface.
 */
export function instanceOfBabiesFilter(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BabiesFilterFromJSON(json: any): BabiesFilter {
    return BabiesFilterFromJSONTyped(json, false);
}

export function BabiesFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): BabiesFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'motherId': !exists(json, 'motherId') ? undefined : json['motherId'],
        'childId': !exists(json, 'childId') ? undefined : json['childId'],
        'childBirthId': !exists(json, 'childBirthId') ? undefined : json['childBirthId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'birthday': !exists(json, 'birthday') ? undefined : json['birthday'],
        'bloodType': !exists(json, 'bloodType') ? undefined : json['bloodType'],
        'bloodRh': !exists(json, 'bloodRh') ? undefined : json['bloodRh'],
        'allergicContent': !exists(json, 'allergicContent') ? undefined : json['allergicContent'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'illNameList': !exists(json, 'illNameList') ? undefined : json['illNameList'],
    };
}

export function BabiesFilterToJSON(value?: BabiesFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'isDeleted': value.isDeleted,
        'userId': value.userId,
        'motherId': value.motherId,
        'childId': value.childId,
        'childBirthId': value.childBirthId,
        'name': value.name,
        'gender': value.gender,
        'birthday': value.birthday,
        'bloodType': value.bloodType,
        'bloodRh': value.bloodRh,
        'allergicContent': value.allergicContent,
        'image': value.image,
        'illNameList': value.illNameList,
    };
}

