import React from "react";
import {
  AvatarCustom,
  BloodSelectionType,
  TeethSelectCustom,
  TextBlockCustom,
} from "@/components/display";
import { Grid } from "@mui/material";
import { COLORS } from "@/constants";
import {
  Baby,
  InfantFourYearOldGingivaMucousMembraneEnum,
  infantFourYearOldsApi,
  InfantMedicalExam,
} from "@/api";
import { useQuery } from "react-query";
import { getNextYearFromDate, toJapanDate } from "@/utils";
import { translate } from "@/helpers";

interface IInfantFourYearOldDetailProps {
  infantMedicalExamItem?: InfantMedicalExam;
  baby?: Baby;
}

export const InfantFourYearOldDetail = (
  props: React.PropsWithChildren<IInfantFourYearOldDetailProps>
) => {
  const { infantMedicalExamItem, baby } = props;

  //--- Get medical consultant
  const { data: infantFourYearOld } = useQuery({
    queryKey: "GET_INFANT_FOUR_YEAR_OLD_BY_ID",
    queryFn: () => {
      return infantFourYearOldsApi.adminInfantFourYearOldsIdGet({
        id: infantMedicalExamItem?.infantId,
        userId: infantMedicalExamItem?.userId,
      });
    },
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextBlockCustom
          classText="font-bold text-center mb-4"
          textHeader={`${translate("infantMedicalExam.text.age12")} ${translate(
            "infantMedicalExam.text.detail"
          )}`}
          textSize={18}
          headerColor={COLORS.primary}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.implementationDate"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantFourYearOld?.implementationDate
                  ? toJapanDate(infantFourYearOld?.implementationDate)
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.ageYear")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                (infantFourYearOld?.year
                  ? `${infantFourYearOld?.year}${translate(
                      "infoHealthMon.age"
                    )}`
                  : "") +
                (infantFourYearOld?.month
                  ? `${infantFourYearOld?.month}${translate("month.txt")}`
                  : "")
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.photographOfMedical"
              )}
              headerColor={COLORS.approxGrey}
            />

            <div className="flex items-center">
              {infantFourYearOld?.photo1 && (
                <div className="mr-2">
                  <AvatarCustom imgSrc={infantFourYearOld?.photo1} />
                </div>
              )}

              {infantFourYearOld?.photo2 && (
                <AvatarCustom imgSrc={infantFourYearOld?.photo2} />
              )}
            </div>
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.bodyWeight"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFourYearOld?.bodyWeight
                  ? infantFourYearOld?.bodyWeight.toString() + "kg"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.height")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFourYearOld?.height
                  ? infantFourYearOld?.height.toString() + "cm"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.kaupIndex"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFourYearOld?.kaupIndex
                  ? infantFourYearOld?.kaupIndex.toString()
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.headCircumference"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFourYearOld?.headCircumference
                  ? infantFourYearOld?.headCircumference.toString() + "cm"
                  : ""
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          {/* 栄養状態/健康・要観察/特記事項など */}
          <Grid item xs={12}>
            <TextBlockCustom
              classText="font-bold"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.nutritionalHealth"
              )}
              headerColor={COLORS.primary}
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.nutritionalStatus"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantFourYearOld?.nutritionalStatus
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFourYearOld?.nutritionalStatus
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.eyeAbnormalities"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantFourYearOld?.eyeAbnormalities
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFourYearOld?.eyeAbnormalities
                    )
                  : ""
              }
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantFourYearOld?.eyeAbnormalitiesNote
                  ? infantFourYearOld?.eyeAbnormalitiesNote
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.sight")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                (infantFourYearOld?.sightLeft
                  ? `${translate("title.left")} ${infantFourYearOld?.sightLeft}`
                  : "") +
                (infantFourYearOld?.sightRight
                  ? ` ${translate("title.right")} ${
                      infantFourYearOld?.sightRight
                    }`
                  : "")
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.earAbnormalities"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantFourYearOld?.earAbnormalities
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFourYearOld?.earAbnormalities
                    )
                  : ""
              }
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantFourYearOld?.earAbnormalitiesNote
                  ? infantFourYearOld?.earAbnormalitiesNote
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.healthObservation"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantFourYearOld?.healthObservation
                  ? infantFourYearOld?.healthObservation
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.remarks")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantFourYearOld?.remarks ? infantFourYearOld?.remarks : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.facilityOrPersonName"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantFourYearOld?.facilityOrPersonName
                  ? infantFourYearOld?.facilityOrPersonName
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.dayCaregiver"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantFourYearOld?.dayCaregiver
                  ? infantFourYearOld?.dayCaregiver
                  : ""
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          {/* 歯の状態 */}
          <Grid item xs={12}>
            <TextBlockCustom
              classText="font-bold"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.teethCondition"
              )}
              headerColor={COLORS.primary}
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.cariesRequiringTreatment"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFourYearOld?.cariesRequiringTreatment
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFourYearOld?.cariesRequiringTreatment
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.milkTeeth"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFourYearOld?.milkTeeth
                  ? infantFourYearOld?.milkTeeth.toString() +
                    translate("title.book")
                  : undefined
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.permanentTeeth"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFourYearOld?.permanentTeeth
                  ? infantFourYearOld?.permanentTeeth.toString() +
                    translate("title.book")
                  : undefined
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.total")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFourYearOld?.total
                  ? infantFourYearOld?.total.toString() +
                    translate("title.book")
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.dirtyTeeth"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFourYearOld?.dirtyTeeth
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFourYearOld?.dirtyTeeth
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.gingivaMucousMembrane"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFourYearOld?.gingivaMucousMembrane
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFourYearOld?.gingivaMucousMembrane
                    )
                  : ""
              }
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFourYearOld?.gingivaMucousMembraneNote &&
                infantFourYearOld?.gingivaMucousMembrane ===
                  InfantFourYearOldGingivaMucousMembraneEnum.CanBe
                  ? infantFourYearOld?.gingivaMucousMembraneNote
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.engagement"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFourYearOld?.engagement
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFourYearOld?.engagement
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.examinationDate"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFourYearOld?.examinationDate
                  ? toJapanDate(infantFourYearOld?.examinationDate)
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.teethCondition"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.maxilla")}
              headerColor={COLORS.approxGrey}
            />

            <TeethSelectCustom
              fieldType={BloodSelectionType.MultipleFields}
              fieldDescriptions={["E", "D", "C", "B", "A"]}
              initialValues={
                !!infantFourYearOld?.rightMolarsMaxilla &&
                JSON.parse(infantFourYearOld.rightMolarsMaxilla)
              }
            />

            <TeethSelectCustom
              fieldType={BloodSelectionType.MultipleFields}
              fieldDescriptions={["A", "B", "C", "D", "E"]}
              initialValues={
                !!infantFourYearOld?.leftFrontToothMaxilla &&
                JSON.parse(infantFourYearOld?.leftFrontToothMaxilla)
              }
            />

            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.lowerJaw"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TeethSelectCustom
              fieldType={BloodSelectionType.MultipleFields}
              fieldDescriptions={["E", "D", "C", "B", "A"]}
              initialValues={
                !!infantFourYearOld?.rightMolarsLowerJaw &&
                JSON.parse(infantFourYearOld?.rightMolarsLowerJaw)
              }
            />

            <TeethSelectCustom
              fieldType={BloodSelectionType.MultipleFields}
              fieldDescriptions={["A", "B", "C", "D", "E"]}
              initialValues={
                !!infantFourYearOld?.leftFrontToothLowerJaw &&
                JSON.parse(infantFourYearOld?.leftFrontToothLowerJaw)
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          {/* 検尿 */}
          <Grid item xs={12}>
            <TextBlockCustom
              classText="font-bold"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.urinalysis"
              )}
              headerColor={COLORS.primary}
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.urineProtein"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFourYearOld?.urineProtein
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFourYearOld?.urineProtein
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.urineSugar"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFourYearOld?.urineSugar
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFourYearOld?.urineSugar
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.urineOccultBlood"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFourYearOld?.urineOccultBlood
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFourYearOld?.urineOccultBlood
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.whiteBloodCells"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFourYearOld?.whiteBloodCells
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFourYearOld?.whiteBloodCells
                    )
                  : ""
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          {/* 保護者の記録（4歳の頃）*/}
          <Grid item xs={12}>
            <TextBlockCustom
              classText="font-bold"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.parentRecordFourYear"
              )}
              headerColor={COLORS.primary}
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={
                getNextYearFromDate(baby?.birthday, 4) +
                translate("infantMedicalExamFormScreen.text.fourYearFromDate")
              }
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFourYearOld?.parentMessage
                  ? infantFourYearOld?.parentMessage
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.recordingDate"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFourYearOld?.recordingDate
                  ? toJapanDate(infantFourYearOld?.recordingDate)
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.jumpStaircase"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFourYearOld?.jumpStaircase
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFourYearOld?.jumpStaircase
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.jumpWithOneFoot"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFourYearOld?.jumpWithOneFoot
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFourYearOld?.jumpWithOneFoot
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.tellParentYourExp"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFourYearOld?.tellParentYourExp
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFourYearOld?.tellParentYourExp
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.drawCross"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFourYearOld?.drawCross
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFourYearOld?.drawCross
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.useScissors"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFourYearOld?.useScissors
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFourYearOld?.useScissors
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.takeOffClothes"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFourYearOld?.takeOffClothes
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFourYearOld?.takeOffClothes
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.playWithFriends"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFourYearOld?.playWithFriends
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFourYearOld?.playWithFriends
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.washYourMouth"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFourYearOld?.washYourMouth
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFourYearOld?.washYourMouth
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.brushYourTeeth1"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFourYearOld?.brushYourTeeth
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFourYearOld?.brushYourTeeth
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.suckYourFingers"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFourYearOld?.suckYourFingers
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFourYearOld?.suckYourFingers
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.likeOrDislikeFood"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFourYearOld?.likeOrDislikeFood
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFourYearOld?.likeOrDislikeFood
                    )
                  : ""
              }
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFourYearOld?.likeOrDislikeFoodNote
                  ? infantFourYearOld?.likeOrDislikeFoodNote
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.peeAlone"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFourYearOld?.peeAlone
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFourYearOld?.peeAlone
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.childRearing"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFourYearOld?.childRearing
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFourYearOld?.childRearing
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.raisingChildren"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFourYearOld?.raisingChildren
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantFourYearOld?.raisingChildren
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.yourGrowth"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantFourYearOld?.yourGrowth
                  ? infantFourYearOld?.yourGrowth
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.photo")}
              headerColor={COLORS.approxGrey}
            />

            {infantFourYearOld?.photo3 && (
              <AvatarCustom imgSrc={infantFourYearOld?.photo3} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
