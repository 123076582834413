/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InfantSixToSevenMonthOld } from './InfantSixToSevenMonthOld';
import {
    InfantSixToSevenMonthOldFromJSON,
    InfantSixToSevenMonthOldFromJSONTyped,
    InfantSixToSevenMonthOldToJSON,
} from './InfantSixToSevenMonthOld';
import type { PaginationInfo } from './PaginationInfo';
import {
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './PaginationInfo';

/**
 * 
 * @export
 * @interface InfantSixToSevenMonthOldPagination
 */
export interface InfantSixToSevenMonthOldPagination {
    /**
     * 
     * @type {Array<InfantSixToSevenMonthOld>}
     * @memberof InfantSixToSevenMonthOldPagination
     */
    data?: Array<InfantSixToSevenMonthOld>;
    /**
     * 
     * @type {PaginationInfo}
     * @memberof InfantSixToSevenMonthOldPagination
     */
    meta?: PaginationInfo;
}

/**
 * Check if a given object implements the InfantSixToSevenMonthOldPagination interface.
 */
export function instanceOfInfantSixToSevenMonthOldPagination(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InfantSixToSevenMonthOldPaginationFromJSON(json: any): InfantSixToSevenMonthOldPagination {
    return InfantSixToSevenMonthOldPaginationFromJSONTyped(json, false);
}

export function InfantSixToSevenMonthOldPaginationFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfantSixToSevenMonthOldPagination {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(InfantSixToSevenMonthOldFromJSON)),
        'meta': !exists(json, 'meta') ? undefined : PaginationInfoFromJSON(json['meta']),
    };
}

export function InfantSixToSevenMonthOldPaginationToJSON(value?: InfantSixToSevenMonthOldPagination | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(InfantSixToSevenMonthOldToJSON)),
        'meta': PaginationInfoToJSON(value.meta),
    };
}

