/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';
import type { VaccineAllOf } from './VaccineAllOf';
import {
    VaccineAllOfFromJSON,
    VaccineAllOfFromJSONTyped,
    VaccineAllOfToJSON,
} from './VaccineAllOf';

/**
 * 
 * @export
 * @interface Vaccine
 */
export interface Vaccine {
    /**
     * 
     * @type {number}
     * @memberof Vaccine
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Vaccine
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Vaccine
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Vaccine
     */
    category?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Vaccine
     */
    vaccineName?: string;
    /**
     * 
     * @type {number}
     * @memberof Vaccine
     */
    firstVaccinationMonth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Vaccine
     */
    firstVaccinationMonthBonus?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Vaccine
     */
    secondVaccinationMonth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Vaccine
     */
    secondVaccinationMonthBonus?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Vaccine
     */
    thirdVaccinationMonth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Vaccine
     */
    thirdVaccinationMonthBonus?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Vaccine
     */
    fourthVaccinationMonth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Vaccine
     */
    fourthVaccinationMonthBonus?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Vaccine
     */
    maxInject?: number;
    /**
     * 
     * @type {string}
     * @memberof Vaccine
     */
    status?: VaccineStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Vaccine
     */
    type?: VaccineTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Vaccine
     */
    recommendedFromMonth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Vaccine
     */
    recommendedToMonth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Vaccine
     */
    recommendedFromMonth2?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Vaccine
     */
    recommendedToMonth2?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Vaccine
     */
    optionalFromMonth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Vaccine
     */
    optionalToMonth?: number | null;
}


/**
 * @export
 */
export const VaccineStatusEnum = {
    Any: 'any',
    Regular: 'regular'
} as const;
export type VaccineStatusEnum = typeof VaccineStatusEnum[keyof typeof VaccineStatusEnum];

/**
 * @export
 */
export const VaccineTypeEnum = {
    Drink: 'drink',
    Inject: 'inject'
} as const;
export type VaccineTypeEnum = typeof VaccineTypeEnum[keyof typeof VaccineTypeEnum];


/**
 * Check if a given object implements the Vaccine interface.
 */
export function instanceOfVaccine(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VaccineFromJSON(json: any): Vaccine {
    return VaccineFromJSONTyped(json, false);
}

export function VaccineFromJSONTyped(json: any, ignoreDiscriminator: boolean): Vaccine {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'vaccineName': !exists(json, 'vaccineName') ? undefined : json['vaccineName'],
        'firstVaccinationMonth': !exists(json, 'firstVaccinationMonth') ? undefined : json['firstVaccinationMonth'],
        'firstVaccinationMonthBonus': !exists(json, 'firstVaccinationMonthBonus') ? undefined : json['firstVaccinationMonthBonus'],
        'secondVaccinationMonth': !exists(json, 'secondVaccinationMonth') ? undefined : json['secondVaccinationMonth'],
        'secondVaccinationMonthBonus': !exists(json, 'secondVaccinationMonthBonus') ? undefined : json['secondVaccinationMonthBonus'],
        'thirdVaccinationMonth': !exists(json, 'thirdVaccinationMonth') ? undefined : json['thirdVaccinationMonth'],
        'thirdVaccinationMonthBonus': !exists(json, 'thirdVaccinationMonthBonus') ? undefined : json['thirdVaccinationMonthBonus'],
        'fourthVaccinationMonth': !exists(json, 'fourthVaccinationMonth') ? undefined : json['fourthVaccinationMonth'],
        'fourthVaccinationMonthBonus': !exists(json, 'fourthVaccinationMonthBonus') ? undefined : json['fourthVaccinationMonthBonus'],
        'maxInject': !exists(json, 'maxInject') ? undefined : json['maxInject'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'recommendedFromMonth': !exists(json, 'recommendedFromMonth') ? undefined : json['recommendedFromMonth'],
        'recommendedToMonth': !exists(json, 'recommendedToMonth') ? undefined : json['recommendedToMonth'],
        'recommendedFromMonth2': !exists(json, 'recommendedFromMonth2') ? undefined : json['recommendedFromMonth2'],
        'recommendedToMonth2': !exists(json, 'recommendedToMonth2') ? undefined : json['recommendedToMonth2'],
        'optionalFromMonth': !exists(json, 'optionalFromMonth') ? undefined : json['optionalFromMonth'],
        'optionalToMonth': !exists(json, 'optionalToMonth') ? undefined : json['optionalToMonth'],
    };
}

export function VaccineToJSON(value?: Vaccine | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'category': value.category,
        'vaccineName': value.vaccineName,
        'firstVaccinationMonth': value.firstVaccinationMonth,
        'firstVaccinationMonthBonus': value.firstVaccinationMonthBonus,
        'secondVaccinationMonth': value.secondVaccinationMonth,
        'secondVaccinationMonthBonus': value.secondVaccinationMonthBonus,
        'thirdVaccinationMonth': value.thirdVaccinationMonth,
        'thirdVaccinationMonthBonus': value.thirdVaccinationMonthBonus,
        'fourthVaccinationMonth': value.fourthVaccinationMonth,
        'fourthVaccinationMonthBonus': value.fourthVaccinationMonthBonus,
        'maxInject': value.maxInject,
        'status': value.status,
        'type': value.type,
        'recommendedFromMonth': value.recommendedFromMonth,
        'recommendedToMonth': value.recommendedToMonth,
        'recommendedFromMonth2': value.recommendedFromMonth2,
        'recommendedToMonth2': value.recommendedToMonth2,
        'optionalFromMonth': value.optionalFromMonth,
        'optionalToMonth': value.optionalToMonth,
    };
}

