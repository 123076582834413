import { Button } from "@mui/material";
import React from "react";
import styled from "styled-components";

const StyledButtonCustom = styled.div`
  .button-custom {
    background: #ee8595;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    width: 52px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: #ee8595;
    }
  }
`;

interface IButtonCustomProps {
  title?: string;
  onClick?: any;
}

export const ButtonCustom = (
  props: React.PropsWithChildren<IButtonCustomProps>
) => {
  return (
    <StyledButtonCustom>
      <Button className="cursor-pointer button-custom" onClick={props.onClick}>
        {props.title}
      </Button>
    </StyledButtonCustom>
  );
};
