import moment from 'moment';
import kanjidate from "kanjidate";
import { translate } from '@/helpers';

interface IToJapanDateOptions {
    showEraName: boolean;
}

export const toJapanDate = (date?: string | Date | moment.Moment, options?: IToJapanDateOptions): string => {
    if (!date) {
        return "";
    }

    const momentDate = moment(date);

    const year = momentDate.year();
    const month = momentDate.month() + 1;
    const day = momentDate.date();

    if (options?.showEraName) {
        const eraName = (kanjidate.format(momentDate.toDate()).split("年")[0]);
        return `${year}(${eraName})年${month}月${day}日`;
    }

    return `${year}年${month}月${day}日`;
};

export const padZero = (number, n: number) => {
    if (!number) {
        return;
    }

    if (number >= 10) {
        return number;
    }

    return new Array(n).join("0").slice((n || 2) * -1) + number.toString().replace("0", "");
};

export const toTwoDigits = (number: number): string => {
    if (!number) {
        return "00";
    }

    return padZero(number, 2);
};

export const toJapanTime = (hours: number, minutes: number,
    textHour: string = "週",
    textMinus: string = "日") => {
    return `${!!hours ? toTwoDigits(hours) + textHour : ""}${!!minutes ? toTwoDigits(minutes) + textMinus : ""}`;
};

export const getWeekPregnant = (firstDate: string | Date | moment.Moment,
    secondDate: string | Date | moment.Moment, type: "weeks" | "days") => {
    if (firstDate && secondDate) {
        const date = moment(secondDate).format("MM/DD/yyyy");
        const expectedDate = moment(firstDate).format("MM/DD/yyyy");

        const weeks = 40;
        const days = 7;
        const weekCount = weeks - (Math.ceil(moment(expectedDate).diff(date, "days") / days));
        const dayCount = (weeks * days - moment(expectedDate).diff(date, "days")) % days;

        if (type === "weeks") {
            return weekCount;
        }

        return Math.abs(dayCount);
    }

    return "";
};

export const calcAge = (birthday: any) => {
    const today = new Date();

    //--- Get year, month, date of today
    const yearNow = today.getFullYear();
    const monthNow = today.getMonth();
    const dateNow = today.getDate();

    //--- Get year, month, date of birthday
    birthday = moment(birthday).format("MM/DD/yyyy");

    const dob = new Date(birthday.substring(6, 10),
        birthday.substring(0, 2) - 1,
        birthday.substring(3, 5)
    );

    const yearDob = dob.getFullYear();
    const monthDob = dob.getMonth();
    const dateDob = dob.getDate();

    //--- Age model
    const age = { years: 0, months: 0, days: 0 };

    //--- String
    let ageString = "";
    const yearString = "歳";
    const monthString = "か月";
    const dayString = "日";

    //--- Calc year
    let yearAge = yearNow - yearDob;

    //--- Calc month
    let monthAge = 0;
    if (monthNow >= monthDob) {
        monthAge = monthNow - monthDob;
    }
    else {
        yearAge--;
        monthAge = 12 + monthNow - monthDob;
    }

    //--- Calc date
    let dateAge = 0;
    if (dateNow >= dateDob) {
        dateAge = dateNow - dateDob;
    }
    else {
        monthAge--;
        dateAge = 31 + dateNow - dateDob;

        if (monthAge < 0) {
            monthAge = 11;
            yearAge--;
        }
    }

    //--- Set age
    age.years = yearAge;
    age.months = monthAge;
    age.days = dateAge;

    if ((age.years > 0) && (age.months > 0) && (age.days > 0)) {
        ageString = age.years + yearString + age.months + monthString + age.days + dayString;
    }
    else if ((age.years === 0) && (age.months === 0) && (age.days > 0)) {
        ageString = age.days + dayString;
    }
    else if ((age.years > 0) && (age.months === 0) && (age.days === 0)) {
        ageString = age.years + yearString;
    }
    else if ((age.years > 0) && (age.months > 0) && (age.days === 0)) {
        ageString = age.years + yearString + age.months + monthString;
    }
    else if ((age.years === 0) && (age.months > 0) && (age.days > 0)) {
        ageString = age.months + monthString + age.days + dayString;
    }
    else if ((age.years > 0) && (age.months === 0) && (age.days > 0)) {
        ageString = age.years + yearString + age.days + dayString;
    }
    else if ((age.years === 0) && (age.months > 0) && (age.days === 0)) {
        ageString = age.months + monthString;
    }

    return ageString;
}

export const getNextMonthFromDate = (date: string | Date | moment.Moment) => {
    const getDate = `${moment(date).add(1, "M").format("YYYY") + translate("infoHealthMon.year")
        + moment(date).add(1, "M").format("MM") + translate("infoHealthMon.moon")
        + moment(date).add(1, "M").format("DD") + translate("infoHealthMon.day")}`;

    return getDate;
};

export const getNextYearFromDate = (date: string | Date | moment.Moment, nextYearNumber: number) => {
    const getDate = `${moment(date).add(nextYearNumber, "y").format("YYYY") + translate("infoHealthMon.year")
        + moment(date).add(nextYearNumber, "y").format("MM") + translate("infoHealthMon.moon")
        + moment(date).add(nextYearNumber, "y").format("DD") + translate("infoHealthMon.day")}`;

    return getDate;
};