/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PaginationInfo } from './PaginationInfo';
import {
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './PaginationInfo';
import type { PregnantDiagnosis } from './PregnantDiagnosis';
import {
    PregnantDiagnosisFromJSON,
    PregnantDiagnosisFromJSONTyped,
    PregnantDiagnosisToJSON,
} from './PregnantDiagnosis';

/**
 * 
 * @export
 * @interface PregnantDiagnosisPagination
 */
export interface PregnantDiagnosisPagination {
    /**
     * 
     * @type {Array<PregnantDiagnosis>}
     * @memberof PregnantDiagnosisPagination
     */
    data?: Array<PregnantDiagnosis>;
    /**
     * 
     * @type {PaginationInfo}
     * @memberof PregnantDiagnosisPagination
     */
    meta?: PaginationInfo;
}

/**
 * Check if a given object implements the PregnantDiagnosisPagination interface.
 */
export function instanceOfPregnantDiagnosisPagination(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PregnantDiagnosisPaginationFromJSON(json: any): PregnantDiagnosisPagination {
    return PregnantDiagnosisPaginationFromJSONTyped(json, false);
}

export function PregnantDiagnosisPaginationFromJSONTyped(json: any, ignoreDiscriminator: boolean): PregnantDiagnosisPagination {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(PregnantDiagnosisFromJSON)),
        'meta': !exists(json, 'meta') ? undefined : PaginationInfoFromJSON(json['meta']),
    };
}

export function PregnantDiagnosisPaginationToJSON(value?: PregnantDiagnosisPagination | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(PregnantDiagnosisToJSON)),
        'meta': PaginationInfoToJSON(value.meta),
    };
}

