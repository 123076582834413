/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AdminLoginRequest,
    AdminLoginRequestFromJSON,
    AdminLoginRequestToJSON,
    AdminLoginResponse,
    AdminLoginResponseFromJSON,
    AdminLoginResponseToJSON,
} from '../models';

export interface AdminAuthLoginPostRequest {
    adminLoginRequest?: AdminLoginRequest;
}

/**
 * 
 */
export class AdminAuthApi extends runtime.BaseAPI {

    /**
     */
    adminAuthLoginPostRaw = async (requestParameters: AdminAuthLoginPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AdminLoginResponse>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/auth/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdminLoginRequestToJSON(requestParameters.adminLoginRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminLoginResponseFromJSON(jsonValue));
    }

    /**
     */
    adminAuthLoginPost = async (requestParameters: AdminAuthLoginPostRequest = {}, initOverrides?: RequestInit): Promise<AdminLoginResponse> => {
        const response = await this.adminAuthLoginPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
