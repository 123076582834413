import { env } from "@/constants";
import { getToken } from "@/utils";

import {
  AdminAuthApi,
  AdminRolesApi,
  AdminsApi,
  AgeDataApi,
  AuthApi,
  BabiesApi,
  BodyDataApi,
  CheckupDataApi,
  ChildBirthsApi,
  ChildrenApi,
  ChildrenBodiesApi,
  Configuration,
  DiagnosesApi,
  DiseasesApi,
  ExportApi,
  FetusesApi,
  HealthOfMomsApi,
  InfantAfterOneWeeksApi,
  InfantEarlyNeonatalPeriodsApi,
  InfantFiveYearOldsApi,
  InfantFourYearOldsApi,
  InfantInspectionRecordsApi,
  InfantMedicalExamsApi,
  InfantNineToTenMonthOldsApi,
  InfantOneAndSixYearOldsApi,
  InfantOneMonthOldsApi,
  InfantOneYearOldsApi,
  InfantSixToSevenMonthOldsApi,
  InfantSixYearOldsApi,
  InfantThreeToFourMonthOldsApi,
  InfantThreeYearOldsApi,
  InfantTwoYearOldsApi,
  MapLocationsApi,
  MedicalConsultantsApi,
  MedicalRecordsApi,
  MedicsApi,
  MothersApi,
  NotificationCampaignsApi,
  PregnantDiagnosesApi,
  PregnantsApi,
  PrescriptionDrugsApi,
  ProfessionAndEnvironmentOfMomsApi,
  RecordSideEffectsApi,
  UsersApi,
  VaccinatedRecordsApi,
  VaccinationSchedulesApi,
  VaccineApi,
  NoteSchedulesApi,
} from "./gennerated";
import { FileApi } from './gennerated/apis/FileApi';

export type ResponseErrorMessages<T = Record<string, unknown>> = Partial<
  Record<keyof T, string>
> & {
  _message?: string;
};

const apiConfig = new Configuration({
  basePath: env.API_URL,
  headers: {
    credentials: "includes",
  },
  middleware: [
    {
      pre: async (context) => {
        context.init.headers = {
          ...context.init.headers,
          Authorization: `Bearer ${getToken()}`,
        };
      },
    },
  ],
});

export const authAPI = new AuthApi(apiConfig);
export const usersApi = new UsersApi(apiConfig);
export const babiesApi = new BabiesApi(apiConfig);
export const pregnantsApi = new PregnantsApi(apiConfig);
export const pregnantDiagnosesApi = new PregnantDiagnosesApi(apiConfig);
export const fetusesApi = new FetusesApi(apiConfig);
export const checkupDataApi = new CheckupDataApi(apiConfig);
export const bodyDataApi = new BodyDataApi(apiConfig);
export const motherApi = new MothersApi(apiConfig);
export const medicalConsultantsApi = new MedicalConsultantsApi(apiConfig);
export const childrenApi = new ChildrenApi(apiConfig);
export const mothersApi = new MothersApi(apiConfig);
export const childBirthsApi = new ChildBirthsApi(apiConfig);
export const ageDataApi = new AgeDataApi(apiConfig);
export const infantMedicalExamsApi = new InfantMedicalExamsApi(apiConfig);
export const infantAfterOneWeeksApi = new InfantAfterOneWeeksApi(apiConfig);
export const infantEarlyNeonatalPeriodsApi = new InfantEarlyNeonatalPeriodsApi(
  apiConfig
);
export const vaccinationSchedulesApi = new VaccinationSchedulesApi(apiConfig);
export const vaccineApi = new VaccineApi(apiConfig);
export const vaccinatedRecordsApi = new VaccinatedRecordsApi(apiConfig);
export const medicsApi = new MedicsApi(apiConfig);
export const professionAndEnvironmentApi =
  new ProfessionAndEnvironmentOfMomsApi(apiConfig);
export const recordSideEffectsApi = new RecordSideEffectsApi(apiConfig);
export const medicalRecordsApi = new MedicalRecordsApi(apiConfig);
export const diseasesApi = new DiseasesApi(apiConfig);
export const mapLocationsApi = new MapLocationsApi(apiConfig);
export const infantInspectionRecordsApi = new InfantInspectionRecordsApi(
  apiConfig
);
export const childrenBodiesApi = new ChildrenBodiesApi(apiConfig);
export const infantOneMonthOldApi = new InfantOneMonthOldsApi(apiConfig);
export const infantThreeToFourMonthOldsApi = new InfantThreeToFourMonthOldsApi(
  apiConfig
);
export const infantNineToTenMonthOldsApi = new InfantNineToTenMonthOldsApi(
  apiConfig
);
export const healthOfMomsApi = new HealthOfMomsApi(apiConfig);
export const infantSixToSevenMonthOldsApi = new InfantSixToSevenMonthOldsApi(
  apiConfig
);
export const infantOneYearOldsApi = new InfantOneYearOldsApi(apiConfig);
export const infantOneAndSixYearOldsApi = new InfantOneAndSixYearOldsApi(
  apiConfig
);
export const infantTwoYearOldsApi = new InfantTwoYearOldsApi(apiConfig);
export const infantThreeYearOldsApi = new InfantThreeYearOldsApi(apiConfig);
export const infantFourYearOldsApi = new InfantFourYearOldsApi(apiConfig);
export const infantFiveYearOldsApi = new InfantFiveYearOldsApi(apiConfig);
export const infantSixYearOldsApi = new InfantSixYearOldsApi(apiConfig);
export const noteSchedulesApi = new NoteSchedulesApi(apiConfig);
export const diagnosesApi = new DiagnosesApi(apiConfig);
export const prescriptionDrugsApi = new PrescriptionDrugsApi(apiConfig);
export const adminsApi = new AdminsApi(apiConfig);
export const adminAuthApi = new AdminAuthApi(apiConfig);
export const adminRolesApi = new AdminRolesApi(apiConfig);
export const exportApi = new ExportApi(apiConfig);
export const notificationCampaignsApi = new NotificationCampaignsApi(apiConfig);
export const fileApi = new FileApi(apiConfig);
