/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InfantNineToTenMonthOld,
    InfantNineToTenMonthOldFromJSON,
    InfantNineToTenMonthOldToJSON,
    InfantNineToTenMonthOldPagination,
    InfantNineToTenMonthOldPaginationFromJSON,
    InfantNineToTenMonthOldPaginationToJSON,
} from '../models';

export interface AdminInfantNineToTenMonthOldsIdGetRequest {
    id: number;
    userId?: number;
}

export interface InfantNineToTenMonthOldsGetRequest {
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface InfantNineToTenMonthOldsIdDeleteRequest {
    id: number;
}

export interface InfantNineToTenMonthOldsIdGetRequest {
    id: number;
}

export interface InfantNineToTenMonthOldsIdPutRequest {
    id: number;
    infantNineToTenMonthOld?: InfantNineToTenMonthOld;
}

export interface InfantNineToTenMonthOldsPostRequest {
    infantNineToTenMonthOld?: InfantNineToTenMonthOld;
}

export interface InfantNineToTenMonthOldsShareAllGetRequest {
    sourceUserId: number;
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface InfantNineToTenMonthOldsShareDetailGetRequest {
    sourceUserId: number;
    recordId: number;
}

/**
 * 
 */
export class InfantNineToTenMonthOldsApi extends runtime.BaseAPI {

    /**
     * 
     */
    adminInfantNineToTenMonthOldsIdGetRaw = async (requestParameters: AdminInfantNineToTenMonthOldsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantNineToTenMonthOld>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling adminInfantNineToTenMonthOldsIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/infantNineToTenMonthOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantNineToTenMonthOldFromJSON(jsonValue));
    }

    /**
     * 
     */
    adminInfantNineToTenMonthOldsIdGet = async (requestParameters: AdminInfantNineToTenMonthOldsIdGetRequest, initOverrides?: RequestInit): Promise<InfantNineToTenMonthOld> => {
        const response = await this.adminInfantNineToTenMonthOldsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantNineToTenMonthOldsGetRaw = async (requestParameters: InfantNineToTenMonthOldsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantNineToTenMonthOldPagination>> => {
        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling infantNineToTenMonthOldsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantNineToTenMonthOlds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantNineToTenMonthOldPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantNineToTenMonthOldsGet = async (requestParameters: InfantNineToTenMonthOldsGetRequest, initOverrides?: RequestInit): Promise<InfantNineToTenMonthOldPagination> => {
        const response = await this.infantNineToTenMonthOldsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    infantNineToTenMonthOldsIdDeleteRaw = async (requestParameters: InfantNineToTenMonthOldsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling infantNineToTenMonthOldsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantNineToTenMonthOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    infantNineToTenMonthOldsIdDelete = async (requestParameters: InfantNineToTenMonthOldsIdDeleteRequest, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.infantNineToTenMonthOldsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantNineToTenMonthOldsIdGetRaw = async (requestParameters: InfantNineToTenMonthOldsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantNineToTenMonthOld>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling infantNineToTenMonthOldsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantNineToTenMonthOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantNineToTenMonthOldFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantNineToTenMonthOldsIdGet = async (requestParameters: InfantNineToTenMonthOldsIdGetRequest, initOverrides?: RequestInit): Promise<InfantNineToTenMonthOld> => {
        const response = await this.infantNineToTenMonthOldsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    infantNineToTenMonthOldsIdPutRaw = async (requestParameters: InfantNineToTenMonthOldsIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantNineToTenMonthOld>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling infantNineToTenMonthOldsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/infantNineToTenMonthOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InfantNineToTenMonthOldToJSON(requestParameters.infantNineToTenMonthOld),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantNineToTenMonthOldFromJSON(jsonValue));
    }

    /**
     */
    infantNineToTenMonthOldsIdPut = async (requestParameters: InfantNineToTenMonthOldsIdPutRequest, initOverrides?: RequestInit): Promise<InfantNineToTenMonthOld> => {
        const response = await this.infantNineToTenMonthOldsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    infantNineToTenMonthOldsPostRaw = async (requestParameters: InfantNineToTenMonthOldsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantNineToTenMonthOld>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/infantNineToTenMonthOlds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InfantNineToTenMonthOldToJSON(requestParameters.infantNineToTenMonthOld),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantNineToTenMonthOldFromJSON(jsonValue));
    }

    /**
     */
    infantNineToTenMonthOldsPost = async (requestParameters: InfantNineToTenMonthOldsPostRequest = {}, initOverrides?: RequestInit): Promise<InfantNineToTenMonthOld> => {
        const response = await this.infantNineToTenMonthOldsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantNineToTenMonthOldsShareAllGetRaw = async (requestParameters: InfantNineToTenMonthOldsShareAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantNineToTenMonthOldPagination>> => {
        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling infantNineToTenMonthOldsShareAllGet.');
        }

        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling infantNineToTenMonthOldsShareAllGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantNineToTenMonthOlds/share/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantNineToTenMonthOldPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantNineToTenMonthOldsShareAllGet = async (requestParameters: InfantNineToTenMonthOldsShareAllGetRequest, initOverrides?: RequestInit): Promise<InfantNineToTenMonthOldPagination> => {
        const response = await this.infantNineToTenMonthOldsShareAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantNineToTenMonthOldsShareDetailGetRaw = async (requestParameters: InfantNineToTenMonthOldsShareDetailGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantNineToTenMonthOld>> => {
        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling infantNineToTenMonthOldsShareDetailGet.');
        }

        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling infantNineToTenMonthOldsShareDetailGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        if (requestParameters.recordId !== undefined) {
            queryParameters['recordId'] = requestParameters.recordId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantNineToTenMonthOlds/share/detail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantNineToTenMonthOldFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantNineToTenMonthOldsShareDetailGet = async (requestParameters: InfantNineToTenMonthOldsShareDetailGetRequest, initOverrides?: RequestInit): Promise<InfantNineToTenMonthOld> => {
        const response = await this.infantNineToTenMonthOldsShareDetailGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
