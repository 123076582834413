import React, { useEffect, useState } from "react";
import { BabyBloodTypeEnum, ChildBirth, childBirthsApi } from "@/api";
import {
  AvatarCustom,
  CardCustom,
  TextBlockCustom,
} from "@/components/display";
import { COLORS } from "@/constants";
import { Grid } from "@mui/material";
import { useQuery } from "react-query";
import {
  getTextBleeding,
  getTextDeliveryStatus,
  getTextLactation,
  getTextYesNo,
  toJapanDate,
  toJapanTime,
} from "@/utils";
import {
  getTextAsphyxiaDescription,
  getTextChildGender,
  getTextDeliveryProcess,
  getTextEvidence,
  getTextNeonatalAsphyxia,
} from "@/utils";

interface IChildStatusProps {
  userId?: number;
  babyId?: number;
}

export const ChildStatus = (
  props: React.PropsWithChildren<IChildStatusProps>
) => {
  //--- Get child birth
  const [statusInfoBaby, setStatusInfoBaby] = useState<ChildBirth>();

  const { data: dataChildBirth } = useQuery({
    queryKey: "GET_CHILD_BIRD",
    queryFn: () => {
      return childBirthsApi.adminChildBirthsGet({
        userId: props.userId,
        babyId: props.babyId,
      });
    },
  });

  useEffect(() => {
    if (props.userId > 0 && props.babyId > 0) {
      const dataChildStatus = dataChildBirth?.data?.find(
        (c) => c.userId === props.userId && c.babyId === props.babyId
      );

      setStatusInfoBaby(dataChildStatus);
    }
  }, [dataChildBirth?.data, props.babyId, props.userId]);

  return (
    <CardCustom classCard={"mt-5"}>
      <TextBlockCustom
        classText="mb-4 text-lg font-bold"
        textHeader=" 出産の状態 "
        headerColor={COLORS.primary}
        textSize={16}
      />

      <Grid container spacing={8}>
        <Grid item xs={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="名前（ニックネーム)"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={statusInfoBaby?.name}
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="娩出日時"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />

              {statusInfoBaby?.hours || statusInfoBaby?.minutes ? (
                <TextBlockCustom
                  classText="font-medium"
                  textHeader={`${toJapanDate(
                    statusInfoBaby?.birthday
                  )} ${getTextChildGender(
                    statusInfoBaby?.gender
                  )} ${toJapanTime(
                    statusInfoBaby?.hours,
                    statusInfoBaby?.minutes,
                    "時",
                    "分"
                  )}`}
                />
              ) : (
                <TextBlockCustom
                  classText="font-medium"
                  textHeader={`${toJapanDate(
                    statusInfoBaby?.birthday
                  )} ${getTextChildGender(statusInfoBaby?.gender)}`}
                />
              )}
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="妊娠期間"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  statusInfoBaby?.week || statusInfoBaby?.day
                    ? `${toJapanTime(
                        statusInfoBaby?.week,
                        statusInfoBaby?.day,
                        "週",
                        "日"
                      )}`
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="パパの年齢"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  statusInfoBaby?.ageOfDad?.toString()
                    ? statusInfoBaby?.ageOfDad + "歳"
                    : statusInfoBaby?.ageOfDad?.toString()
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="ママの年齢"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  statusInfoBaby?.ageOfMom?.toString()
                    ? statusInfoBaby?.ageOfMom + "歳"
                    : statusInfoBaby?.ageOfMom?.toString()
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="産院"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={statusInfoBaby?.maternityHospital}
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="分娩状況"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              {statusInfoBaby?.deliveryStatus?.length > 0 &&
                statusInfoBaby?.deliveryStatus
                  .split(",")
                  .map(
                    (item, index) =>
                      item !== "" && (
                        <TextBlockCustom
                          key={"deliveryStatus_" + index}
                          classText="font-medium mb-2"
                          textHeader={getTextDeliveryStatus(item)}
                        />
                      )
                  )}
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="分娩経過"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />

              {statusInfoBaby?.deliveryProcessDescription ? (
                <>
                  <TextBlockCustom
                    classText="font-medium mb-2"
                    textHeader={
                      statusInfoBaby?.deliveryProcess
                        ? getTextDeliveryProcess(
                            statusInfoBaby?.deliveryProcess
                          )
                        : ""
                    }
                  />

                  <TextBlockCustom
                    classText="font-medium"
                    textHeader={
                      statusInfoBaby?.deliveryProcessDescription
                        ? statusInfoBaby?.deliveryProcessDescription
                        : ""
                    }
                  />
                </>
              ) : (
                <TextBlockCustom
                  classText="font-medium"
                  textHeader={
                    statusInfoBaby?.deliveryProcess
                      ? getTextDeliveryProcess(statusInfoBaby?.deliveryProcess)
                      : ""
                  }
                />
              )}
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="出血"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />

              {statusInfoBaby?.bleedingDescription ? (
                <>
                  <TextBlockCustom
                    classText="font-medium"
                    textHeader={
                      statusInfoBaby?.bleeding
                        ? getTextBleeding(statusInfoBaby?.bleeding)
                        : ""
                    }
                  />

                  <TextBlockCustom
                    classText="font-medium"
                    textHeader={
                      statusInfoBaby?.bleedingDescription
                        ? statusInfoBaby?.bleedingDescription + "ml"
                        : ""
                    }
                  />
                </>
              ) : (
                <TextBlockCustom
                  classText="font-medium"
                  textHeader={
                    statusInfoBaby?.bleeding
                      ? getTextBleeding(statusInfoBaby?.bleeding)
                      : ""
                  }
                />
              )}
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="輸血"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />

              {statusInfoBaby?.bloodTransfusionDescription ? (
                <>
                  <TextBlockCustom
                    classText="font-medium mb-2"
                    textHeader={
                      statusInfoBaby?.bloodTransfusion
                        ? getTextYesNo(statusInfoBaby?.bloodTransfusion)
                        : ""
                    }
                  />

                  <TextBlockCustom
                    classText="font-medium mb-2"
                    textHeader={
                      statusInfoBaby?.bloodTransfusionDescription
                        ? statusInfoBaby?.bloodTransfusionDescription + "ml"
                        : ""
                    }
                  />
                </>
              ) : (
                <TextBlockCustom
                  classText="font-medium mb-2"
                  textHeader={
                    statusInfoBaby?.bloodTransfusion
                      ? getTextYesNo(statusInfoBaby?.bloodTransfusion)
                      : ""
                  }
                />
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextBlockCustom
                textHeader="出産時の赤ちゃんの状態"
                headerColor={COLORS.primary}
              />
            </Grid>

            {/* <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="単・多(胎数) "
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={statusInfoBaby?.numberChild?.toString()}
              />
            </Grid> */}

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="体重"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  statusInfoBaby?.weightChild?.toString()
                    ? statusInfoBaby?.weightChild + "g"
                    : statusInfoBaby?.weightChild?.toString()
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="身長"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  statusInfoBaby?.heightChild?.toString()
                    ? statusInfoBaby?.heightChild + "cm"
                    : statusInfoBaby?.heightChild?.toString()
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="胸囲"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  statusInfoBaby?.chestCircumference?.toString()
                    ? statusInfoBaby?.chestCircumference + "cm"
                    : statusInfoBaby?.chestCircumference?.toString()
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="頭囲"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  !!statusInfoBaby?.headCircumference?.toString()
                    ? statusInfoBaby?.headCircumference + "cm"
                    : statusInfoBaby?.headCircumference?.toString()
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="血液型"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  statusInfoBaby?.bloodType &&
                  statusInfoBaby?.bloodType !== BabyBloodTypeEnum.Unknown
                    ? statusInfoBaby.bloodType + "型"
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="Rh"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              {statusInfoBaby?.bloodType &&
                statusInfoBaby?.bloodType !== BabyBloodTypeEnum.Unknown && (
                  <TextBlockCustom
                    classText="font-medium"
                    textHeader={
                      statusInfoBaby?.bloodRh &&
                      statusInfoBaby?.bloodRh !== "UNKNOWN"
                        ? statusInfoBaby.bloodRh
                        : ""
                    }
                  />
                )}
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="仮死"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />

              {statusInfoBaby?.asphyxiaDescription ? (
                <>
                  <TextBlockCustom
                    classText="font-medium"
                    textHeader={
                      statusInfoBaby?.asphyxia
                        ? getTextYesNo(statusInfoBaby?.asphyxia)
                        : ""
                    }
                  />

                  <TextBlockCustom
                    classText="font-medium"
                    textHeader={
                      statusInfoBaby?.asphyxiaDescription
                        ? getTextAsphyxiaDescription(
                            statusInfoBaby?.asphyxiaDescription
                          )
                        : ""
                    }
                  />
                </>
              ) : (
                <TextBlockCustom
                  classText="font-medium"
                  textHeader={
                    statusInfoBaby?.asphyxia
                      ? getTextYesNo(statusInfoBaby?.asphyxia)
                      : ""
                  }
                />
              )}
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="新生児仮死"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  statusInfoBaby?.neonatalAsphyxia
                    ? getTextNeonatalAsphyxia(statusInfoBaby?.neonatalAsphyxia)
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="証明"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  statusInfoBaby?.evidence
                    ? getTextEvidence(statusInfoBaby?.evidence)
                    : ""
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextBlockCustom
                textHeader="分娩取扱者氏名"
                headerColor={COLORS.primary}
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="医師"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={statusInfoBaby?.doctorName}
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="助産師"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={statusInfoBaby?.midwifeName}
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="その他"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={statusInfoBaby?.otherPersonName}
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="黄疸"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={statusInfoBaby?.jaundice}
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="保育器の使用"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />

              {statusInfoBaby?.useOfIncubatorDescription ? (
                <>
                  <TextBlockCustom
                    classText="font-medium"
                    textHeader={
                      statusInfoBaby?.useOfIncubator
                        ? getTextYesNo(statusInfoBaby?.useOfIncubator)
                        : ""
                    }
                  />

                  <TextBlockCustom
                    classText="font-medium"
                    textHeader={
                      statusInfoBaby?.useOfIncubatorDescription
                        ? `${statusInfoBaby?.useOfIncubatorDescription}日間`
                        : ""
                    }
                  />
                </>
              ) : (
                <TextBlockCustom
                  classText="font-medium"
                  textHeader={
                    statusInfoBaby?.useOfIncubator
                      ? getTextYesNo(statusInfoBaby?.useOfIncubator)
                      : ""
                  }
                />
              )}
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="哺乳力"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />

              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  statusInfoBaby?.lactation
                    ? getTextLactation(statusInfoBaby?.lactation)
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="その他特記事項"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={statusInfoBaby?.otherNoteworthyThings}
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="出生から退院までの本人の経過"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={statusInfoBaby?.courseFromBirthToDischarge}
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="画像添付"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />

              <AvatarCustom imgSrc={statusInfoBaby?.image} />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="その他"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={statusInfoBaby?.other}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CardCustom>
  );
};
