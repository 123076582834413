/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BabyAllOf } from './BabyAllOf';
import {
    BabyAllOfFromJSON,
    BabyAllOfFromJSONTyped,
    BabyAllOfToJSON,
} from './BabyAllOf';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';

/**
 * 
 * @export
 * @interface Baby
 */
export interface Baby {
    /**
     * 
     * @type {number}
     * @memberof Baby
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Baby
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Baby
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof Baby
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof Baby
     */
    childId?: number;
    /**
     * 
     * @type {number}
     * @memberof Baby
     */
    motherId?: number;
    /**
     * 
     * @type {number}
     * @memberof Baby
     */
    childBirthId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Baby
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Baby
     */
    gender?: BabyGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof Baby
     */
    birthday?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Baby
     */
    bloodType?: BabyBloodTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Baby
     */
    bloodRh?: BabyBloodRhEnum;
    /**
     * 
     * @type {string}
     * @memberof Baby
     */
    allergicContent?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Baby
     */
    image?: string | null;
}


/**
 * @export
 */
export const BabyGenderEnum = {
    Male: 'MALE',
    Female: 'FEMALE',
    Unknown: 'UNKNOWN'
} as const;
export type BabyGenderEnum = typeof BabyGenderEnum[keyof typeof BabyGenderEnum];

/**
 * @export
 */
export const BabyBloodTypeEnum = {
    A: 'A',
    B: 'B',
    Ab: 'AB',
    O: 'O',
    Unknown: 'UNKNOWN'
} as const;
export type BabyBloodTypeEnum = typeof BabyBloodTypeEnum[keyof typeof BabyBloodTypeEnum];

/**
 * @export
 */
export const BabyBloodRhEnum = {
    Plus: '+',
    Minus: '-',
    Unknown: 'UNKNOWN'
} as const;
export type BabyBloodRhEnum = typeof BabyBloodRhEnum[keyof typeof BabyBloodRhEnum];


/**
 * Check if a given object implements the Baby interface.
 */
export function instanceOfBaby(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BabyFromJSON(json: any): Baby {
    return BabyFromJSONTyped(json, false);
}

export function BabyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Baby {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'childId': !exists(json, 'childId') ? undefined : json['childId'],
        'motherId': !exists(json, 'motherId') ? undefined : json['motherId'],
        'childBirthId': !exists(json, 'childBirthId') ? undefined : json['childBirthId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'birthday': !exists(json, 'birthday') ? undefined : json['birthday'],
        'bloodType': !exists(json, 'bloodType') ? undefined : json['bloodType'],
        'bloodRh': !exists(json, 'bloodRh') ? undefined : json['bloodRh'],
        'allergicContent': !exists(json, 'allergicContent') ? undefined : json['allergicContent'],
        'image': !exists(json, 'image') ? undefined : json['image'],
    };
}

export function BabyToJSON(value?: Baby | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'userId': value.userId,
        'childId': value.childId,
        'motherId': value.motherId,
        'childBirthId': value.childBirthId,
        'name': value.name,
        'gender': value.gender,
        'birthday': value.birthday,
        'bloodType': value.bloodType,
        'bloodRh': value.bloodRh,
        'allergicContent': value.allergicContent,
        'image': value.image,
    };
}

