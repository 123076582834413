/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Baby,
    BabyFromJSON,
    BabyToJSON,
    BabyPagination,
    BabyPaginationFromJSON,
    BabyPaginationToJSON,
} from '../models';

export interface AdminBabiesGetRequest {
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
    userId?: number;
    motherId?: number;
}

export interface AdminBabiesPostRequest {
    baby?: Baby;
}

export interface BabiesGetRequest {
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface BabiesIdDeleteRequest {
    id: number;
}

export interface BabiesIdGetRequest {
    id: number;
}

export interface BabiesIdPutRequest {
    id: number;
    baby?: Baby;
}

export interface BabiesPostRequest {
    baby?: Baby;
}

export interface BabiesShareAllGetRequest {
    sourceUserId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface BabiesShareDetailGetRequest {
    sourceUserId: number;
    babyId: number;
}

/**
 * 
 */
export class BabiesApi extends runtime.BaseAPI {

    /**
     * 
     */
    adminBabiesGetRaw = async (requestParameters: AdminBabiesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BabyPagination>> => {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.motherId !== undefined) {
            queryParameters['motherId'] = requestParameters.motherId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/babies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BabyPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    adminBabiesGet = async (requestParameters: AdminBabiesGetRequest = {}, initOverrides?: RequestInit): Promise<BabyPagination> => {
        const response = await this.adminBabiesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    adminBabiesPostRaw = async (requestParameters: AdminBabiesPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Baby>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/babies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BabyToJSON(requestParameters.baby),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BabyFromJSON(jsonValue));
    }

    /**
     */
    adminBabiesPost = async (requestParameters: AdminBabiesPostRequest = {}, initOverrides?: RequestInit): Promise<Baby> => {
        const response = await this.adminBabiesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    babiesGetRaw = async (requestParameters: BabiesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BabyPagination>> => {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/babies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BabyPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    babiesGet = async (requestParameters: BabiesGetRequest = {}, initOverrides?: RequestInit): Promise<BabyPagination> => {
        const response = await this.babiesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    babiesIdDeleteRaw = async (requestParameters: BabiesIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling babiesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/babies/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    babiesIdDelete = async (requestParameters: BabiesIdDeleteRequest, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.babiesIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    babiesIdGetRaw = async (requestParameters: BabiesIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Baby>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling babiesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/babies/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BabyFromJSON(jsonValue));
    }

    /**
     * 
     */
    babiesIdGet = async (requestParameters: BabiesIdGetRequest, initOverrides?: RequestInit): Promise<Baby> => {
        const response = await this.babiesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    babiesIdPutRaw = async (requestParameters: BabiesIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Baby>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling babiesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/babies/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BabyToJSON(requestParameters.baby),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BabyFromJSON(jsonValue));
    }

    /**
     */
    babiesIdPut = async (requestParameters: BabiesIdPutRequest, initOverrides?: RequestInit): Promise<Baby> => {
        const response = await this.babiesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    babiesPostRaw = async (requestParameters: BabiesPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Baby>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/babies`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BabyToJSON(requestParameters.baby),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BabyFromJSON(jsonValue));
    }

    /**
     */
    babiesPost = async (requestParameters: BabiesPostRequest = {}, initOverrides?: RequestInit): Promise<Baby> => {
        const response = await this.babiesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    babiesShareAllGetRaw = async (requestParameters: BabiesShareAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BabyPagination>> => {
        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling babiesShareAllGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/babies/share/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BabyPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    babiesShareAllGet = async (requestParameters: BabiesShareAllGetRequest, initOverrides?: RequestInit): Promise<BabyPagination> => {
        const response = await this.babiesShareAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    babiesShareDetailGetRaw = async (requestParameters: BabiesShareDetailGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Baby>> => {
        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling babiesShareDetailGet.');
        }

        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling babiesShareDetailGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/babies/share/detail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BabyFromJSON(jsonValue));
    }

    /**
     * 
     */
    babiesShareDetailGet = async (requestParameters: BabiesShareDetailGetRequest, initOverrides?: RequestInit): Promise<Baby> => {
        const response = await this.babiesShareDetailGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
