import React from "react";
import { TextBlockCustom } from "@/components/display";
import { COLORS } from "@/constants";
import { Grid } from "@mui/material";
import { BootstrapDialogCustom } from "@/components/dialog";
import { getTextBodyPart, toJapanDate } from "@/utils";
import { VaccinatedRecord } from "@/api";

interface IDialogVacationRecordsProps {
  openDialog?: boolean;
  onCloseDialog?: () => void;
  vaccinationRecordItem?: VaccinatedRecord;
}

export const DialogVacationRecords = (
  props: React.PropsWithChildren<IDialogVacationRecordsProps>
) => {
  const { openDialog, onCloseDialog, vaccinationRecordItem } = props;

  return (
    <>
      <BootstrapDialogCustom
        openDialog={openDialog}
        onCloseDialog={onCloseDialog}
      >
        <Grid container spacing={2} key={vaccinationRecordItem?.id}>
          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader="接種日"
              headerColor={COLORS.approxGrey}
            />
            <TextBlockCustom
              classText="font-medium"
              textHeader={
                vaccinationRecordItem?.dateInjected
                  ? toJapanDate(vaccinationRecordItem?.dateInjected)
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader="接種したワクチンの種類"
              headerColor={COLORS.approxGrey}
            />
            <TextBlockCustom
              classText="font-medium"
              textHeader={
                vaccinationRecordItem?.vaccineName
                  ? vaccinationRecordItem?.vaccineName
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader="ロット"
              headerColor={COLORS.approxGrey}
            />
            <TextBlockCustom
              classText="font-medium"
              textHeader={vaccinationRecordItem?.lotNumberVaccine}
            />
          </Grid>
          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader="接種場所名"
              headerColor={COLORS.approxGrey}
            />
            <TextBlockCustom
              classText="font-medium"
              textHeader={vaccinationRecordItem?.addressInject}
            />
          </Grid>
          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader="接種者署名"
              headerColor={COLORS.approxGrey}
            />
            <TextBlockCustom
              classText="font-medium"
              textHeader={vaccinationRecordItem?.doctorName}
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader="接種箇所"
              headerColor={COLORS.approxGrey}
            />
            <TextBlockCustom
              classText="font-medium"
              textHeader={
                vaccinationRecordItem?.bodyPart
                  ? getTextBodyPart(vaccinationRecordItem?.bodyPart)
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader="副反応"
              headerColor={COLORS.approxGrey}
            />

            {vaccinationRecordItem?.sideEffects ? (
              <>
                <TextBlockCustom
                  classText="font-medium mb-2"
                  textHeader="はい"
                />

                <TextBlockCustom
                  classText="font-medium"
                  textHeader={vaccinationRecordItem?.sideEffects}
                />
              </>
            ) : (
              <TextBlockCustom classText="font-medium" textHeader="ない" />
            )}
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader="備考"
              headerColor={COLORS.approxGrey}
            />
            <TextBlockCustom
              classText="font-medium"
              textHeader={vaccinationRecordItem?.comment}
            />
          </Grid>
        </Grid>
      </BootstrapDialogCustom>
    </>
  );
};
