/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface PresignS3Request {
    key: string
}

/**
 * 
 */
export class FileApi extends runtime.BaseAPI {

  postPresignS3Url = async (requestParameters: PresignS3Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        const queryParameters: any = {};

        if (requestParameters.key !== undefined) {
          queryParameters['key'] = requestParameters.key;
        }
        const headerParameters: runtime.HTTPHeaders = {};
        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/file/s3`,
            method: 'POST',
            headers: headerParameters,
            body: {
              key: requestParameters.key
            },
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue);
    }

}
