import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Backdrop, Box, Button, Fade, Modal } from '@mui/material';
import { AdminForm } from '@/form/admin';
import { useQueryClient } from 'react-query';

export const adminModalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 850,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: '36px',
};

interface IAdminCreateButtonProps {

}

export const AdminCreateButton = (props: React.PropsWithChildren<IAdminCreateButtonProps>) => {
    const queryClient = useQueryClient();

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <Button
                variant="outlined"
                color="secondary"
                size="medium"
                startIcon={<AddIcon />}
                onClick={handleOpen}
            >
                管理者を追加
            </Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={adminModalStyle}>
                        <AdminForm
                            onSuccess={() => {
                                queryClient.refetchQueries('GET_ADMINS');
                                handleClose();
                            }}
                            onClose={handleClose}
                        />
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};