export const renderBMI = (weight: number, height: number) => {
    if (!height || !weight) {
        return;
    }

    return (Math.round((weight / ((height / 100) * 2)) * 10) / 10).toString();
};

export const renderKaupIndex = (weight: number, height: number) => {
    if (!height || !weight) {
        return "";
    }

    const final = (Math.round(((weight / (height * height)) * 10) * 10) / 10);

    if (final === 0) {
        return undefined;
    }

    return final.toString();
};

export const renderKaupIndexBabyLarge = (weight: number, height: number) => {
    if (!height || !weight) {
        return "";
    }

    const final = (Math.round(((weight / (height * height)) * 10000) * 10) / 10);

    if (final === 0) {
        return undefined;
    }

    return final.toString();
};