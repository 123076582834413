import { COLORS, LAYOUT_SIDE_WIDTH } from "@/constants";
import { getUserListUrl } from "@/pages/user/user-list/UserListPage.shared";
import { Drawer } from "@mui/material";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import styled from "styled-components";
import { MasterMenu } from "./master-layout";
import { MasterUserInfo } from "./master-layout/MasterUserInfo";

const StyledMasterLayout = styled.div`
  .logo {
    width: 137px;
  }
  .main {
    margin-left: ${LAYOUT_SIDE_WIDTH}px;
    padding: 30px 36px;
    max-height: 100vh;
    height: 100vh;
    position: relative;
  }
  .copyright {
    display: block;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: #c0c0c0;
    margin-top: 80px;
    padding-bottom: 20px;
  }
`;

interface IMasterLayoutProps {}

export const MasterLayout = (
  props: React.PropsWithChildren<IMasterLayoutProps>
) => {
  if (window.location.pathname === "/") {
    return <Navigate to={getUserListUrl()} replace />;
  }

  return (
    <StyledMasterLayout>
      <Drawer
        sx={{
          width: LAYOUT_SIDE_WIDTH,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: LAYOUT_SIDE_WIDTH,
            boxSizing: "border-box",
            background: COLORS.primary,
            borderRight: "0",
            padding: `0 20px`,
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <div className="text-center mt-6 mb-8">
          <img className="logo inline-block" src="/logo-white.png" alt="logo" />
        </div>
        <MasterUserInfo />
        <MasterMenu />
      </Drawer>
      <main className="main">
        <Outlet />

        <div className="copyright">Copyright @ SEASTAR</div>
      </main>
    </StyledMasterLayout>
  );
};
