/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InfantThreeYearOld,
    InfantThreeYearOldFromJSON,
    InfantThreeYearOldToJSON,
    InfantThreeYearOldPagination,
    InfantThreeYearOldPaginationFromJSON,
    InfantThreeYearOldPaginationToJSON,
} from '../models';

export interface AdminInfantThreeYearOldsIdGetRequest {
    id: number;
    userId?: number;
}

export interface InfantThreeYearOldsGetRequest {
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface InfantThreeYearOldsIdDeleteRequest {
    id: number;
}

export interface InfantThreeYearOldsIdGetRequest {
    id: number;
}

export interface InfantThreeYearOldsIdPutRequest {
    id: number;
    infantThreeYearOld?: InfantThreeYearOld;
}

export interface InfantThreeYearOldsPostRequest {
    infantThreeYearOld?: InfantThreeYearOld;
}

export interface InfantThreeYearOldsShareAllGetRequest {
    sourceUserId: number;
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface InfantThreeYearOldsShareDetailGetRequest {
    recordId: number;
    sourceUserId: number;
}

/**
 * 
 */
export class InfantThreeYearOldsApi extends runtime.BaseAPI {

    /**
     * 
     */
    adminInfantThreeYearOldsIdGetRaw = async (requestParameters: AdminInfantThreeYearOldsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantThreeYearOld>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling adminInfantThreeYearOldsIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/infantThreeYearOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantThreeYearOldFromJSON(jsonValue));
    }

    /**
     * 
     */
    adminInfantThreeYearOldsIdGet = async (requestParameters: AdminInfantThreeYearOldsIdGetRequest, initOverrides?: RequestInit): Promise<InfantThreeYearOld> => {
        const response = await this.adminInfantThreeYearOldsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantThreeYearOldsGetRaw = async (requestParameters: InfantThreeYearOldsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantThreeYearOldPagination>> => {
        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling infantThreeYearOldsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantThreeYearOlds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantThreeYearOldPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantThreeYearOldsGet = async (requestParameters: InfantThreeYearOldsGetRequest, initOverrides?: RequestInit): Promise<InfantThreeYearOldPagination> => {
        const response = await this.infantThreeYearOldsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    infantThreeYearOldsIdDeleteRaw = async (requestParameters: InfantThreeYearOldsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling infantThreeYearOldsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantThreeYearOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    infantThreeYearOldsIdDelete = async (requestParameters: InfantThreeYearOldsIdDeleteRequest, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.infantThreeYearOldsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantThreeYearOldsIdGetRaw = async (requestParameters: InfantThreeYearOldsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantThreeYearOld>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling infantThreeYearOldsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantThreeYearOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantThreeYearOldFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantThreeYearOldsIdGet = async (requestParameters: InfantThreeYearOldsIdGetRequest, initOverrides?: RequestInit): Promise<InfantThreeYearOld> => {
        const response = await this.infantThreeYearOldsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    infantThreeYearOldsIdPutRaw = async (requestParameters: InfantThreeYearOldsIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantThreeYearOld>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling infantThreeYearOldsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/infantThreeYearOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InfantThreeYearOldToJSON(requestParameters.infantThreeYearOld),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantThreeYearOldFromJSON(jsonValue));
    }

    /**
     */
    infantThreeYearOldsIdPut = async (requestParameters: InfantThreeYearOldsIdPutRequest, initOverrides?: RequestInit): Promise<InfantThreeYearOld> => {
        const response = await this.infantThreeYearOldsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    infantThreeYearOldsPostRaw = async (requestParameters: InfantThreeYearOldsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantThreeYearOld>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/infantThreeYearOlds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InfantThreeYearOldToJSON(requestParameters.infantThreeYearOld),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantThreeYearOldFromJSON(jsonValue));
    }

    /**
     */
    infantThreeYearOldsPost = async (requestParameters: InfantThreeYearOldsPostRequest = {}, initOverrides?: RequestInit): Promise<InfantThreeYearOld> => {
        const response = await this.infantThreeYearOldsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantThreeYearOldsShareAllGetRaw = async (requestParameters: InfantThreeYearOldsShareAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantThreeYearOldPagination>> => {
        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling infantThreeYearOldsShareAllGet.');
        }

        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling infantThreeYearOldsShareAllGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantThreeYearOlds/share/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantThreeYearOldPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantThreeYearOldsShareAllGet = async (requestParameters: InfantThreeYearOldsShareAllGetRequest, initOverrides?: RequestInit): Promise<InfantThreeYearOldPagination> => {
        const response = await this.infantThreeYearOldsShareAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantThreeYearOldsShareDetailGetRaw = async (requestParameters: InfantThreeYearOldsShareDetailGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantThreeYearOld>> => {
        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling infantThreeYearOldsShareDetailGet.');
        }

        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling infantThreeYearOldsShareDetailGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.recordId !== undefined) {
            queryParameters['recordId'] = requestParameters.recordId;
        }

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantThreeYearOlds/share/detail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantThreeYearOldFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantThreeYearOldsShareDetailGet = async (requestParameters: InfantThreeYearOldsShareDetailGetRequest, initOverrides?: RequestInit): Promise<InfantThreeYearOld> => {
        const response = await this.infantThreeYearOldsShareDetailGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
