import { useRecoilState } from "recoil";

import { allRolesState, currentUserState } from "@/constants";

interface IAccessControlProps {
  role: "admin" | "employee";
  children: ({ allowed }) => JSX.Element;
}

export const RoleCheck = (props: IAccessControlProps) => {
  const [currentUser] = useRecoilState(currentUserState);
  const [allRoles] = useRecoilState(allRolesState);

  const currentUserRole = allRoles?.find(
    (role) => role.id === currentUser.roleId
  );

  return props.children({ allowed: currentUserRole?.roleName === props.role });
};
