import React, { useEffect, useState } from "react";
import { Baby, BabyBloodRhEnum, BabyBloodTypeEnum } from "@/api";
import { CardCustom, TextBlockCustom } from "@/components/display";
import { COLORS } from "@/constants";
import { Avatar, Grid } from "@mui/material";
import moment from "moment";
import { calcAge, getTextChildGender } from "@/utils";

interface IChildInfoProps {
  babyItem?: Baby;
}

export const ChildInfo = (props: React.PropsWithChildren<IChildInfoProps>) => {
  //--- State
  const [babyInfo, setBabyInfo] = useState<Baby>();

  useEffect(() => {
    setBabyInfo(props.babyItem);
  }, [props.babyItem]);

  return (
    <CardCustom classCard={"mt-4"}>
      <Grid container alignItems={"center"}>
        <Grid item xs={2}>
          <Avatar
            src={babyInfo?.image ? babyInfo?.image : ""}
            sx={{ width: 100, height: 100 }}
          />
        </Grid>

        <Grid item xs={10}>
          <Grid container spacing={2} className="divide-x">
            <Grid item xs={4}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="名前（ニックネーム）: "
                textContent={babyInfo?.name}
                contentColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                classText="mb-2"
                textHeader="生年月日: "
                textContent={
                  babyInfo?.birthday
                    ? moment(babyInfo?.birthday).format("yyyy/MM/DD")
                    : ""
                }
                contentColor={COLORS.approxGrey}
              />
            </Grid>
            <Grid item xs>
              <TextBlockCustom
                classText="mb-2"
                textHeader="性別: "
                textContent={
                  babyInfo?.gender ? getTextChildGender(babyInfo?.gender) : ""
                }
                contentColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                classText="mb-2"
                textHeader="年齢: "
                textContent={
                  babyInfo?.birthday
                    ? calcAge(babyInfo?.birthday)
                    : ""
                }
                contentColor={COLORS.approxGrey}
              />
            </Grid>
            <Grid item xs>
              <TextBlockCustom
                classText="mb-2"
                textHeader="血液型: "
                textContent={
                  babyInfo?.bloodType !== BabyBloodTypeEnum.Unknown
                    ? babyInfo?.bloodType
                    : ""
                }
                contentColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                classText="mb-2"
                textHeader="Rh: "
                textContent={
                  babyInfo?.bloodType &&
                    babyInfo?.bloodType !== BabyBloodTypeEnum.Unknown &&
                    babyInfo.bloodRh !== BabyBloodRhEnum.Unknown
                    ? babyInfo?.bloodRh
                    : ""
                }
                contentColor={COLORS.approxGrey}
              />
            </Grid>
            <Grid item xs={4}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="薬剤や食品などのアレルギー : "
                textContent={babyInfo?.allergicContent}
                contentColor={COLORS.approxGrey}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CardCustom>
  );
};
