/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FetusesFilter
 */
export interface FetusesFilter {
    /**
     * 
     * @type {number}
     * @memberof FetusesFilter
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FetusesFilter
     */
    createdAt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FetusesFilter
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FetusesFilter
     */
    isDeleted?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof FetusesFilter
     */
    userId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FetusesFilter
     */
    childId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FetusesFilter
     */
    motherId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof FetusesFilter
     */
    pregnantId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FetusesFilter
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FetusesFilter
     */
    gender?: FetusesFilterGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof FetusesFilter
     */
    expectedBirthday?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FetusesFilter
     */
    monProfession?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FetusesFilter
     */
    monBirthday?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FetusesFilter
     */
    dadProfession?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FetusesFilter
     */
    dadBirthday?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FetusesFilter
     */
    ortherGuardianRelation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FetusesFilter
     */
    ortherGuardianProfession?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FetusesFilter
     */
    ortherGuardianBirthday?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FetusesFilter
     */
    prePregnancyMonHeight?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FetusesFilter
     */
    prePregnancyMonWeight?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FetusesFilter
     */
    image?: string | null;
}


/**
 * @export
 */
export const FetusesFilterGenderEnum = {
    Male: 'MALE',
    Female: 'FEMALE',
    Unknown: 'UNKNOWN'
} as const;
export type FetusesFilterGenderEnum = typeof FetusesFilterGenderEnum[keyof typeof FetusesFilterGenderEnum];


/**
 * Check if a given object implements the FetusesFilter interface.
 */
export function instanceOfFetusesFilter(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FetusesFilterFromJSON(json: any): FetusesFilter {
    return FetusesFilterFromJSONTyped(json, false);
}

export function FetusesFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): FetusesFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'childId': !exists(json, 'childId') ? undefined : json['childId'],
        'motherId': !exists(json, 'motherId') ? undefined : json['motherId'],
        'pregnantId': !exists(json, 'pregnantId') ? undefined : json['pregnantId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'expectedBirthday': !exists(json, 'expectedBirthday') ? undefined : json['expectedBirthday'],
        'monProfession': !exists(json, 'monProfession') ? undefined : json['monProfession'],
        'monBirthday': !exists(json, 'monBirthday') ? undefined : json['monBirthday'],
        'dadProfession': !exists(json, 'dadProfession') ? undefined : json['dadProfession'],
        'dadBirthday': !exists(json, 'dadBirthday') ? undefined : json['dadBirthday'],
        'ortherGuardianRelation': !exists(json, 'ortherGuardianRelation') ? undefined : json['ortherGuardianRelation'],
        'ortherGuardianProfession': !exists(json, 'ortherGuardianProfession') ? undefined : json['ortherGuardianProfession'],
        'ortherGuardianBirthday': !exists(json, 'ortherGuardianBirthday') ? undefined : json['ortherGuardianBirthday'],
        'prePregnancyMonHeight': !exists(json, 'prePregnancyMonHeight') ? undefined : json['prePregnancyMonHeight'],
        'prePregnancyMonWeight': !exists(json, 'prePregnancyMonWeight') ? undefined : json['prePregnancyMonWeight'],
        'image': !exists(json, 'image') ? undefined : json['image'],
    };
}

export function FetusesFilterToJSON(value?: FetusesFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'isDeleted': value.isDeleted,
        'userId': value.userId,
        'childId': value.childId,
        'motherId': value.motherId,
        'pregnantId': value.pregnantId,
        'name': value.name,
        'gender': value.gender,
        'expectedBirthday': value.expectedBirthday,
        'monProfession': value.monProfession,
        'monBirthday': value.monBirthday,
        'dadProfession': value.dadProfession,
        'dadBirthday': value.dadBirthday,
        'ortherGuardianRelation': value.ortherGuardianRelation,
        'ortherGuardianProfession': value.ortherGuardianProfession,
        'ortherGuardianBirthday': value.ortherGuardianBirthday,
        'prePregnancyMonHeight': value.prePregnancyMonHeight,
        'prePregnancyMonWeight': value.prePregnancyMonWeight,
        'image': value.image,
    };
}

