import React from "react";
import styled from "styled-components";

const StyledCardCustom = styled.div`
  padding: 24px 32px 24px 32px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(77, 70, 70, 0.1);
`;

interface ICardCustomProps {
  classCard?: any;
}

export const CardCustom = (
  props: React.PropsWithChildren<ICardCustomProps>
) => {
  return (
    <StyledCardCustom className={props.classCard}>
      {props.children}
    </StyledCardCustom>
  );
};
