/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';
import type { DiagnosisAllOf } from './DiagnosisAllOf';
import {
    DiagnosisAllOfFromJSON,
    DiagnosisAllOfFromJSONTyped,
    DiagnosisAllOfToJSON,
} from './DiagnosisAllOf';
import type { PrescriptionDrug } from './PrescriptionDrug';
import {
    PrescriptionDrugFromJSON,
    PrescriptionDrugFromJSONTyped,
    PrescriptionDrugToJSON,
} from './PrescriptionDrug';

/**
 * 
 * @export
 * @interface Diagnosis
 */
export interface Diagnosis {
    /**
     * 
     * @type {number}
     * @memberof Diagnosis
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Diagnosis
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Diagnosis
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Diagnosis
     */
    diagnosisName?: string;
    /**
     * 
     * @type {string}
     * @memberof Diagnosis
     */
    diagnosisLink?: string | null;
    /**
     * 
     * @type {Array<PrescriptionDrug>}
     * @memberof Diagnosis
     */
    prescriptionDrugs?: Array<PrescriptionDrug>;
}

/**
 * Check if a given object implements the Diagnosis interface.
 */
export function instanceOfDiagnosis(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DiagnosisFromJSON(json: any): Diagnosis {
    return DiagnosisFromJSONTyped(json, false);
}

export function DiagnosisFromJSONTyped(json: any, ignoreDiscriminator: boolean): Diagnosis {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'diagnosisName': !exists(json, 'diagnosisName') ? undefined : json['diagnosisName'],
        'diagnosisLink': !exists(json, 'diagnosisLink') ? undefined : json['diagnosisLink'],
        'prescriptionDrugs': !exists(json, 'prescriptionDrugs') ? undefined : ((json['prescriptionDrugs'] as Array<any>).map(PrescriptionDrugFromJSON)),
    };
}

export function DiagnosisToJSON(value?: Diagnosis | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'diagnosisName': value.diagnosisName,
        'diagnosisLink': value.diagnosisLink,
        'prescriptionDrugs': value.prescriptionDrugs === undefined ? undefined : ((value.prescriptionDrugs as Array<any>).map(PrescriptionDrugToJSON)),
    };
}

