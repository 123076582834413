/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaginationInfo
 */
export interface PaginationInfo {
    /**
     * 
     * @type {number}
     * @memberof PaginationInfo
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationInfo
     */
    itemsPerPage?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationInfo
     */
    totalItems?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationInfo
     */
    currentItems?: number;
}

/**
 * Check if a given object implements the PaginationInfo interface.
 */
export function instanceOfPaginationInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PaginationInfoFromJSON(json: any): PaginationInfo {
    return PaginationInfoFromJSONTyped(json, false);
}

export function PaginationInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginationInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': !exists(json, 'page') ? undefined : json['page'],
        'itemsPerPage': !exists(json, 'itemsPerPage') ? undefined : json['itemsPerPage'],
        'totalItems': !exists(json, 'totalItems') ? undefined : json['totalItems'],
        'currentItems': !exists(json, 'currentItems') ? undefined : json['currentItems'],
    };
}

export function PaginationInfoToJSON(value?: PaginationInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'itemsPerPage': value.itemsPerPage,
        'totalItems': value.totalItems,
        'currentItems': value.currentItems,
    };
}

