import { ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import {} from "react-router-dom";
import { RecoilRoot, useRecoilState } from "recoil";
import { allRolesState, AUTH_PATH, currentUserState } from "./constants";
import { MasterLayout } from "./layout";
import { renderAdminRoutes } from "./pages/admin";

import { renderAuthRoutes } from "./pages/auth";
import { renderUserRoutes } from "./pages/user";
import { renderDrugRoutes } from "./pages/drug";
import { renderCampaignRoutes } from "./pages/noti-campaign";

import { getLoginUrl } from "./pages/auth/login/LoginPage.shared";

import { theme } from "./theme";
import { adminRolesApi, adminsApi } from "./api";
import { getToken, decodeToken } from "./utils";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
    },
  },
});

function Router() {
  const token = getToken();
  const hadToken = !!token;

  const [currentAdmin, setCurrentAdmin] = useRecoilState(currentUserState);
  const [, setAllRoles] = useRecoilState(allRolesState);

  const { isFetching: isAdminFetching } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: "GET_CURRENT_ADMIN",
    queryFn: () => {
      const decodedToken = decodeToken(token);
      return adminsApi.adminAdminsIdGet({
        id: decodedToken.id,
      });
    },
    onSuccess: (result) => {
      setCurrentAdmin(result);
    },
    enabled: hadToken,
    staleTime: Infinity,
  });

  useQuery({
    refetchOnWindowFocus: false,
    queryKey: "GET_ROLES",
    queryFn: () => {
      return adminRolesApi.adminAdminRolesGet({});
    },
    onSuccess: (result) => {
      setAllRoles(result.data);
    },
    enabled: hadToken,
    staleTime: Infinity,
  });

  if (isAdminFetching) {
    return null;
  }

  if (!token || !currentAdmin) {
    if (!window.location.pathname.startsWith(`/${AUTH_PATH}`)) {
      window.location.replace(getLoginUrl());
      return null;
    }
  }

  return (
    <BrowserRouter>
      <Routes>
        {renderAuthRoutes()}
        <Route path="/" element={<MasterLayout />}>
          {renderUserRoutes()}
          {renderAdminRoutes()}
          {renderDrugRoutes()}
          {renderCampaignRoutes()}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function App() {
  return (
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <Router />
        </ThemeProvider>
      </QueryClientProvider>
    </RecoilRoot>
  );
}

/**
 * The lines bellow do nothing but break the Back/forward cache in Firefox and Safari
 * https://stackoverflow.com/questions/7248111/how-to-prevent-content-being-displayed-from-back-forward-cache-in-firefox
 */
window.addEventListener("unload", function () {});

export default App;
