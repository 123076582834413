import { IconClose } from "@/assets/icons/IconClose";
import { Loading } from "@/assets/icons/Loading";
import { selectFile, uploadFile } from "@/services/file.service";
import { showErrorNotification } from "@/utils/notify";
import { TextField } from "@mui/material";
import { useMemo, useState } from "react";

type Props = {
  name: string;
  required?: boolean;
  errors: { [key: string]: any };
  touched: { [key: string]: any };
  setFieldValue: any;
  values: { [key: string]: any };
  showDescription?: boolean;
};
export const FileUploadField = ({
  name,
  required,
  values,
  errors,
  setFieldValue,
  showDescription,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const uploadFileHandler = async () => {
    const file = await selectFile();
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (
      !fileExtension.includes("jpg") &&
      !fileExtension.includes("jpeg") &&
      !fileExtension.includes("png")
    ) {
      showErrorNotification(
        " 拡張子が許可されていません。jpeg、jpg、pngファイルをアップロードしてください。"
      );
      return;
    }
    if (file) {
      setLoading(true);
      try {
        const imageUrl = await uploadFile(file);
        setFieldValue(name, imageUrl);
      } catch (e) {
        showErrorNotification(e.message);
      } finally {
        setLoading(false);
      }
    }
  };
  const isEmpty = useMemo(
    () => !values[name] && !loading,
    [values, name, loading]
  );
  return (
    <div
      className={isEmpty ? "cursor-pointer" : ""}
      onClick={() => isEmpty && uploadFileHandler()}
    >
      <div
        className={[
          "h-[118px] rounded-[3px] w-full border flex flex-col justify-center items-center",
          required && errors[name] ? "ant-input-status-error" : "",
        ].join(" ")}
      >
        {isEmpty && (
          <>
            <img
              className="h-[36px] w-[36px]"
              src="/upload.png"
              alt="upload img"
            />
            <div className="pt-2 font-normal text-[12px]">
              ここにファイルをドロップするか、クリックしてアップロードします。
            </div>
            {showDescription && (
              <div className="pt-2 font-normal text-[11px] text-[#C2C3C7]">
                * 幅が 1029 ピクセル以上の画像を選択してください。*
              </div>
            )}
          </>
        )}
        {values[name] && !loading && (
          <div className="relative">
            <img className="h-[100px]" src={values[name]} alt="img" />
            <div
              className="absolute top-1 right-1 rounded-full cursor-pointer bg-gray-50/90"
              onClick={() => setFieldValue(name)}
            >
              <IconClose />
            </div>
          </div>
        )}
        {loading && (
          <div className="!h-full w-full flex justify-center items-center">
            <div role="status">
              <Loading />
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </div>
      <TextField
        name={name}
        type="text"
        onChange={setFieldValue}
        value={values[name]}
        className="!hidden"
      />
      {required && errors[name] && (
        <div className="text-[#F21D1D] text-xs mt-2">{errors[name]}</div>
      )}
    </div>
  );
};
