import React, { Fragment } from "react";
import { AvatarCustom, TextBlockCustom } from "@/components/display";
import { COLORS } from "@/constants";
import { Grid } from "@mui/material";
import { BootstrapDialogCustom } from "@/components/dialog";
import { diagnosesApi, MedicalConsultant, prescriptionDrugsApi } from "@/api";
import { useQuery } from "react-query";
import { toJapanDate } from "@/utils";

interface IDialogMedicalConsultantProps {
  openDialog?: boolean;
  onCloseDialog?: () => void;
  medicalConsultantItem?: MedicalConsultant;
}

export const DialogMedicalConsultant = (
  props: React.PropsWithChildren<IDialogMedicalConsultantProps>
) => {
  const { openDialog, onCloseDialog, medicalConsultantItem } = props;

  //--- Get diagnosis list
  const { data: diagnosisList } = useQuery({
    queryKey: "GET_DIAGNOSIS_LIST",
    queryFn: () => {
      return diagnosesApi.diagnosesGet();
    },
  });

  //--- Get prescriptionDrug list
  const { data: prescriptionDrugList } = useQuery({
    queryKey: "GET_PRESCRIPTION_DRUG_LIST",
    queryFn: () => {
      return prescriptionDrugsApi.prescriptionDrugsGet({
        itemsPerPage: 10000
      });
    }
  });

  return (
    <BootstrapDialogCustom
      openDialog={openDialog}
      onCloseDialog={onCloseDialog}
    >
      <Grid container>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="受診日"
                headerColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  medicalConsultantItem?.consultationDate
                    ? toJapanDate(medicalConsultantItem?.consultationDate)
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="医療機関名"
                headerColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={medicalConsultantItem?.medicalInstitutionName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="担当医師名"
                headerColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={medicalConsultantItem?.doctorName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="症状"
                headerColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={medicalConsultantItem?.symptoms}
              />
            </Grid>
            <Grid item xs={12}>
              {medicalConsultantItem?.diagnosisList &&
                JSON.parse(medicalConsultantItem?.diagnosisList)?.map(
                  (itemDiagnosis, indexDiagnosis) =>
                    !!itemDiagnosis && (
                      <Fragment key={"diagnosisList_" + indexDiagnosis}>
                        <TextBlockCustom
                          classText="font-medium mb-2"
                          textHeader={"診断名 " + (indexDiagnosis + 1)}
                          headerColor={COLORS.primary}
                        />
                        <TextBlockCustom
                          classText="mb-2"
                          textHeader="診断名"
                          headerColor={COLORS.approxGrey}
                        />

                        {diagnosisList &&
                          itemDiagnosis?.diagnosisName !== "" && (
                            <TextBlockCustom
                              classText="font-bold mb-2"
                              textHeader={
                                diagnosisList?.data?.find(
                                  (d) => d.id === itemDiagnosis?.diagnosisName
                                )?.diagnosisName
                              }
                            />
                          )}

                        {itemDiagnosis?.diagnosisOther !== "" && (
                          <TextBlockCustom
                            classText="mb-2"
                            textHeader={itemDiagnosis?.diagnosisOther}
                          />
                        )}

                        <TextBlockCustom
                          classText="mb-2"
                          textHeader="処方薬"
                          headerColor={COLORS.approxGrey}
                        />

                        {itemDiagnosis?.prescriptionDrugs &&
                          itemDiagnosis?.prescriptionDrugs?.map(
                            (itemPrescriptionDrug, indexPrescriptionDrug) =>
                              !!itemPrescriptionDrug &&
                              itemPrescriptionDrug?.prescriptionDrugsName !==
                              "" && (
                                <TextBlockCustom
                                  key={indexPrescriptionDrug}
                                  classText="font-bold mb-2"
                                  textHeader={
                                    prescriptionDrugList?.data?.find(
                                      (p) =>
                                        p.id ===
                                        itemPrescriptionDrug?.prescriptionDrugsName
                                    )?.prescriptionDrugName
                                  }
                                />
                              )
                          )}

                        {itemDiagnosis?.prescriptionDrugOther !== "" && (
                          <TextBlockCustom
                            classText="mb-2"
                            textHeader={itemDiagnosis?.prescriptionDrugOther}
                          />
                        )}

                        <div className="border mb-2"></div>
                      </Fragment>
                    )
                )}
            </Grid>
            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="治療内容"
                headerColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={medicalConsultantItem?.treatmentContent}
              />
            </Grid>
            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="かかりつけ薬局名"
                headerColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={medicalConsultantItem?.familyPharmacyName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="副作用や特記事項"
                headerColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={medicalConsultantItem?.sideEffectsAndSpecialNotes}
              />
            </Grid>
            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="写真"
                headerColor={COLORS.approxGrey}
              />

              <AvatarCustom imgSrc={medicalConsultantItem?.medicalPhoto} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </BootstrapDialogCustom>
  );
};
