/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AppleId,
    AppleIdFromJSON,
    AppleIdToJSON,
    EmailLinked,
    EmailLinkedFromJSON,
    EmailLinkedToJSON,
    GoogleId,
    GoogleIdFromJSON,
    GoogleIdToJSON,
    LinkedAccounts,
    LinkedAccountsFromJSON,
    LinkedAccountsToJSON,
    Password,
    PasswordFromJSON,
    PasswordToJSON,
    User,
    UserFromJSON,
    UserToJSON,
} from '../models';

export interface UsersCancelLinkApplePostRequest {
    appleId?: AppleId;
}

export interface UsersCancelLinkEmailPostRequest {
    emailLinked?: EmailLinked;
}

export interface UsersCancelLinkGooglePostRequest {
    googleId?: GoogleId;
}

export interface UsersCheckPassPostRequest {
    password?: Password;
}

export interface UsersDeleteAppleIdDeleteRequest {
    id: number;
}

export interface UsersDeleteEmailDeleteRequest {
    userId: number;
    password: string;
}

export interface UsersDeleteGmailIdDeleteRequest {
    id: number;
}

export interface UsersLinkAccountPostRequest {
    linkedAccounts?: LinkedAccounts;
}

export interface UsersSelfPutRequest {
    user?: User;
}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     */
    usersCancelLinkApplePostRaw = async (requestParameters: UsersCancelLinkApplePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<User>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/cancelLinkApple`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AppleIdToJSON(requestParameters.appleId),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    usersCancelLinkApplePost = async (requestParameters: UsersCancelLinkApplePostRequest = {}, initOverrides?: RequestInit): Promise<User> => {
        const response = await this.usersCancelLinkApplePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    usersCancelLinkEmailPostRaw = async (requestParameters: UsersCancelLinkEmailPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<User>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/cancelLinkEmail`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmailLinkedToJSON(requestParameters.emailLinked),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    usersCancelLinkEmailPost = async (requestParameters: UsersCancelLinkEmailPostRequest = {}, initOverrides?: RequestInit): Promise<User> => {
        const response = await this.usersCancelLinkEmailPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    usersCancelLinkGooglePostRaw = async (requestParameters: UsersCancelLinkGooglePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<User>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/cancelLinkGoogle`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GoogleIdToJSON(requestParameters.googleId),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    usersCancelLinkGooglePost = async (requestParameters: UsersCancelLinkGooglePostRequest = {}, initOverrides?: RequestInit): Promise<User> => {
        const response = await this.usersCancelLinkGooglePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    usersCheckPassPostRaw = async (requestParameters: UsersCheckPassPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/checkPass`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PasswordToJSON(requestParameters.password),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    usersCheckPassPost = async (requestParameters: UsersCheckPassPostRequest = {}, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.usersCheckPassPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    usersDeleteAppleIdDeleteRaw = async (requestParameters: UsersDeleteAppleIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling usersDeleteAppleIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/deleteApple/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    usersDeleteAppleIdDelete = async (requestParameters: UsersDeleteAppleIdDeleteRequest, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.usersDeleteAppleIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    usersDeleteEmailDeleteRaw = async (requestParameters: UsersDeleteEmailDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling usersDeleteEmailDelete.');
        }

        if (requestParameters.password === null || requestParameters.password === undefined) {
            throw new runtime.RequiredError('password','Required parameter requestParameters.password was null or undefined when calling usersDeleteEmailDelete.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.password !== undefined) {
            queryParameters['password'] = requestParameters.password;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/deleteEmail`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    usersDeleteEmailDelete = async (requestParameters: UsersDeleteEmailDeleteRequest, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.usersDeleteEmailDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    usersDeleteGmailIdDeleteRaw = async (requestParameters: UsersDeleteGmailIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling usersDeleteGmailIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/deleteGmail/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    usersDeleteGmailIdDelete = async (requestParameters: UsersDeleteGmailIdDeleteRequest, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.usersDeleteGmailIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    usersLinkAccountPostRaw = async (requestParameters: UsersLinkAccountPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<User>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/linkAccount`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LinkedAccountsToJSON(requestParameters.linkedAccounts),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    usersLinkAccountPost = async (requestParameters: UsersLinkAccountPostRequest = {}, initOverrides?: RequestInit): Promise<User> => {
        const response = await this.usersLinkAccountPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    usersSelfGetRaw = async (initOverrides?: RequestInit): Promise<runtime.ApiResponse<User>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/users/self`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    usersSelfGet = async (initOverrides?: RequestInit): Promise<User> => {
        const response = await this.usersSelfGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    usersSelfPutRaw = async (requestParameters: UsersSelfPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<User>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/users/self`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserToJSON(requestParameters.user),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    usersSelfPut = async (requestParameters: UsersSelfPutRequest = {}, initOverrides?: RequestInit): Promise<User> => {
        const response = await this.usersSelfPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
