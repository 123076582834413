/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InfantFourYearOld,
    InfantFourYearOldFromJSON,
    InfantFourYearOldToJSON,
    InfantFourYearOldPagination,
    InfantFourYearOldPaginationFromJSON,
    InfantFourYearOldPaginationToJSON,
} from '../models';

export interface AdminInfantFourYearOldsIdGetRequest {
    id: number;
    userId?: number;
}

export interface InfantFourYearOldsGetRequest {
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface InfantFourYearOldsIdDeleteRequest {
    id: number;
}

export interface InfantFourYearOldsIdGetRequest {
    id: number;
}

export interface InfantFourYearOldsIdPutRequest {
    id: number;
    infantFourYearOld?: InfantFourYearOld;
}

export interface InfantFourYearOldsPostRequest {
    infantFourYearOld?: InfantFourYearOld;
}

export interface InfantFourYearOldsShareAllGetRequest {
    sourceUserId: number;
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface InfantFourYearOldsShareDetailGetRequest {
    recordId: number;
    sourceUserId: number;
}

/**
 * 
 */
export class InfantFourYearOldsApi extends runtime.BaseAPI {

    /**
     * 
     */
    adminInfantFourYearOldsIdGetRaw = async (requestParameters: AdminInfantFourYearOldsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantFourYearOld>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling adminInfantFourYearOldsIdGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/infantFourYearOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantFourYearOldFromJSON(jsonValue));
    }

    /**
     * 
     */
    adminInfantFourYearOldsIdGet = async (requestParameters: AdminInfantFourYearOldsIdGetRequest, initOverrides?: RequestInit): Promise<InfantFourYearOld> => {
        const response = await this.adminInfantFourYearOldsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantFourYearOldsGetRaw = async (requestParameters: InfantFourYearOldsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantFourYearOldPagination>> => {
        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling infantFourYearOldsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantFourYearOlds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantFourYearOldPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantFourYearOldsGet = async (requestParameters: InfantFourYearOldsGetRequest, initOverrides?: RequestInit): Promise<InfantFourYearOldPagination> => {
        const response = await this.infantFourYearOldsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    infantFourYearOldsIdDeleteRaw = async (requestParameters: InfantFourYearOldsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling infantFourYearOldsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantFourYearOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    infantFourYearOldsIdDelete = async (requestParameters: InfantFourYearOldsIdDeleteRequest, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.infantFourYearOldsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantFourYearOldsIdGetRaw = async (requestParameters: InfantFourYearOldsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantFourYearOld>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling infantFourYearOldsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantFourYearOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantFourYearOldFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantFourYearOldsIdGet = async (requestParameters: InfantFourYearOldsIdGetRequest, initOverrides?: RequestInit): Promise<InfantFourYearOld> => {
        const response = await this.infantFourYearOldsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    infantFourYearOldsIdPutRaw = async (requestParameters: InfantFourYearOldsIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantFourYearOld>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling infantFourYearOldsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/infantFourYearOlds/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InfantFourYearOldToJSON(requestParameters.infantFourYearOld),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantFourYearOldFromJSON(jsonValue));
    }

    /**
     */
    infantFourYearOldsIdPut = async (requestParameters: InfantFourYearOldsIdPutRequest, initOverrides?: RequestInit): Promise<InfantFourYearOld> => {
        const response = await this.infantFourYearOldsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    infantFourYearOldsPostRaw = async (requestParameters: InfantFourYearOldsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantFourYearOld>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/infantFourYearOlds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InfantFourYearOldToJSON(requestParameters.infantFourYearOld),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantFourYearOldFromJSON(jsonValue));
    }

    /**
     */
    infantFourYearOldsPost = async (requestParameters: InfantFourYearOldsPostRequest = {}, initOverrides?: RequestInit): Promise<InfantFourYearOld> => {
        const response = await this.infantFourYearOldsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantFourYearOldsShareAllGetRaw = async (requestParameters: InfantFourYearOldsShareAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantFourYearOldPagination>> => {
        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling infantFourYearOldsShareAllGet.');
        }

        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling infantFourYearOldsShareAllGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantFourYearOlds/share/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantFourYearOldPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantFourYearOldsShareAllGet = async (requestParameters: InfantFourYearOldsShareAllGetRequest, initOverrides?: RequestInit): Promise<InfantFourYearOldPagination> => {
        const response = await this.infantFourYearOldsShareAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    infantFourYearOldsShareDetailGetRaw = async (requestParameters: InfantFourYearOldsShareDetailGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InfantFourYearOld>> => {
        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling infantFourYearOldsShareDetailGet.');
        }

        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling infantFourYearOldsShareDetailGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.recordId !== undefined) {
            queryParameters['recordId'] = requestParameters.recordId;
        }

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/infantFourYearOlds/share/detail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InfantFourYearOldFromJSON(jsonValue));
    }

    /**
     * 
     */
    infantFourYearOldsShareDetailGet = async (requestParameters: InfantFourYearOldsShareDetailGetRequest, initOverrides?: RequestInit): Promise<InfantFourYearOld> => {
        const response = await this.infantFourYearOldsShareDetailGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
