/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Fetus,
    FetusFromJSON,
    FetusToJSON,
    FetusPagination,
    FetusPaginationFromJSON,
    FetusPaginationToJSON,
} from '../models';

export interface AdminFetusesGetRequest {
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
    userId?: number;
    motherId?: number;
}

export interface AdminFetusesPostRequest {
    fetus?: Fetus;
}

export interface FetusesGetRequest {
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface FetusesIdDeleteRequest {
    id: number;
}

export interface FetusesIdGetRequest {
    id: number;
}

export interface FetusesIdPutRequest {
    id: number;
    fetus?: Fetus;
}

export interface FetusesPostRequest {
    fetus?: Fetus;
}

export interface FetusesShareDetailGetRequest {
    sourceUserId: number;
    fetusId: number;
}

/**
 * 
 */
export class FetusesApi extends runtime.BaseAPI {

    /**
     * 
     */
    adminFetusesGetRaw = async (requestParameters: AdminFetusesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FetusPagination>> => {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.motherId !== undefined) {
            queryParameters['motherId'] = requestParameters.motherId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/fetuses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FetusPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    adminFetusesGet = async (requestParameters: AdminFetusesGetRequest = {}, initOverrides?: RequestInit): Promise<FetusPagination> => {
        const response = await this.adminFetusesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    adminFetusesPostRaw = async (requestParameters: AdminFetusesPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Fetus>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/admin/fetuses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetusToJSON(requestParameters.fetus),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FetusFromJSON(jsonValue));
    }

    /**
     */
    adminFetusesPost = async (requestParameters: AdminFetusesPostRequest = {}, initOverrides?: RequestInit): Promise<Fetus> => {
        const response = await this.adminFetusesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    fetusesGetRaw = async (requestParameters: FetusesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<FetusPagination>> => {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/fetuses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FetusPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    fetusesGet = async (requestParameters: FetusesGetRequest = {}, initOverrides?: RequestInit): Promise<FetusPagination> => {
        const response = await this.fetusesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    fetusesIdDeleteRaw = async (requestParameters: FetusesIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetusesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/fetuses/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    fetusesIdDelete = async (requestParameters: FetusesIdDeleteRequest, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.fetusesIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    fetusesIdGetRaw = async (requestParameters: FetusesIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Fetus>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetusesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/fetuses/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FetusFromJSON(jsonValue));
    }

    /**
     * 
     */
    fetusesIdGet = async (requestParameters: FetusesIdGetRequest, initOverrides?: RequestInit): Promise<Fetus> => {
        const response = await this.fetusesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    fetusesIdPutRaw = async (requestParameters: FetusesIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Fetus>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetusesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/fetuses/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: FetusToJSON(requestParameters.fetus),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FetusFromJSON(jsonValue));
    }

    /**
     */
    fetusesIdPut = async (requestParameters: FetusesIdPutRequest, initOverrides?: RequestInit): Promise<Fetus> => {
        const response = await this.fetusesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    fetusesPostRaw = async (requestParameters: FetusesPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Fetus>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/fetuses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FetusToJSON(requestParameters.fetus),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FetusFromJSON(jsonValue));
    }

    /**
     */
    fetusesPost = async (requestParameters: FetusesPostRequest = {}, initOverrides?: RequestInit): Promise<Fetus> => {
        const response = await this.fetusesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    fetusesShareDetailGetRaw = async (requestParameters: FetusesShareDetailGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Fetus>> => {
        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling fetusesShareDetailGet.');
        }

        if (requestParameters.fetusId === null || requestParameters.fetusId === undefined) {
            throw new runtime.RequiredError('fetusId','Required parameter requestParameters.fetusId was null or undefined when calling fetusesShareDetailGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        if (requestParameters.fetusId !== undefined) {
            queryParameters['fetusId'] = requestParameters.fetusId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/fetuses/share/detail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FetusFromJSON(jsonValue));
    }

    /**
     * 
     */
    fetusesShareDetailGet = async (requestParameters: FetusesShareDetailGetRequest, initOverrides?: RequestInit): Promise<Fetus> => {
        const response = await this.fetusesShareDetailGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
