import React from "react";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { Snackbar } from "@mui/material";

interface IAlertCustomProps {
  openSnackBar?: boolean;
  onCloseSnack?: () => void;
  alertMessage?: string;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const AlertCustom = (
  props: React.PropsWithChildren<IAlertCustomProps>
) => {
  const { openSnackBar, onCloseSnack, alertMessage } = props;

  return (
    <Snackbar
      open={openSnackBar}
      autoHideDuration={6000}
      onClose={onCloseSnack}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert onClose={onCloseSnack} severity="success" sx={{ width: "100%" }}>
        {alertMessage}
      </Alert>
    </Snackbar>
  );
};
