import React from "react";
import { BootstrapDialogCustom } from "@/components/dialog";
import { Baby, InfantMedicalExam } from "@/api";
import { InfantAfterOneWeekDetail } from "./InfantAfterOneWeekDetail";
import { InfantEarlyNeonatalPeriodDetail } from "./InfantEarlyNeonatalPeriodDetail";
import { InfantInspectionRecordDetail } from "./InfantInspectionRecordDetail";
import { InfantOneMonthOldDetail } from "./InfantOneMonthOldDetail";
import { InfantThreeToFourMonthOldDetail } from "./InfantThreeToFourMonthOldDetail";
import { InfantSixToSevenMonthOldDetail } from "./InfantSixToSevenMonthOldDetail";
import { InfantNineToTenMonthOldDetail } from "./InfantNineToTenMonthOldDetail";
import { InfantOneYearOldDetail } from "./InfantOneYearOldDetail";
import { InfantOneAndSixYearOldDetail } from "./InfantOneAndSixYearOldDetail";
import { InfantTwoYearOldDetail } from "./InfantTwoYearOldDetail";
import { InfantThreeYearOldDetail } from "./InfantThreeYearOldDetail";
import { InfantFourYearOldDetail } from "./InfantFourYearOldDetail";
import { InfantFiveYearOldDetail } from "./InfantFiveYearOldDetail";
import { InfantSixYearOldDetail } from "./InfantSixYearOldDetail";

interface IDialogInfantMedicalExamProps {
  openDialog?: boolean;
  onCloseDialog?: () => void;
  infantMedicalExamItem?: InfantMedicalExam;
  baby?: Baby;
}

export const DialogInfantMedicalExam = (
  props: React.PropsWithChildren<IDialogInfantMedicalExamProps>
) => {
  const { openDialog, onCloseDialog, infantMedicalExamItem, baby } = props;

  return (
    <BootstrapDialogCustom
      openDialog={openDialog}
      onCloseDialog={onCloseDialog}
    >
      {infantMedicalExamItem?.ageId === 1 && (
        <InfantAfterOneWeekDetail
          infantMedicalExamItem={infantMedicalExamItem}
        />
      )}

      {infantMedicalExamItem?.ageId === 2 && (
        <InfantEarlyNeonatalPeriodDetail
          infantMedicalExamItem={infantMedicalExamItem}
        />
      )}

      {infantMedicalExamItem?.ageId === 3 && (
        <InfantInspectionRecordDetail
          infantMedicalExamItem={infantMedicalExamItem}
        />
      )}

      {infantMedicalExamItem?.ageId === 4 && (
        <InfantOneMonthOldDetail
          infantMedicalExamItem={infantMedicalExamItem}
          baby={baby}
        />
      )}

      {infantMedicalExamItem?.ageId === 5 && (
        <InfantThreeToFourMonthOldDetail
          infantMedicalExamItem={infantMedicalExamItem}
        />
      )}

      {infantMedicalExamItem?.ageId === 6 && (
        <InfantSixToSevenMonthOldDetail
          infantMedicalExamItem={infantMedicalExamItem}
        />
      )}

      {infantMedicalExamItem?.ageId === 7 && (
        <InfantNineToTenMonthOldDetail
          infantMedicalExamItem={infantMedicalExamItem}
        />
      )}

      {infantMedicalExamItem?.ageId === 8 && (
        <InfantOneYearOldDetail
          infantMedicalExamItem={infantMedicalExamItem}
          baby={baby}
        />
      )}

      {infantMedicalExamItem?.ageId === 9 && (
        <InfantOneAndSixYearOldDetail
          infantMedicalExamItem={infantMedicalExamItem}
        />
      )}

      {infantMedicalExamItem?.ageId === 10 && (
        <InfantTwoYearOldDetail
          infantMedicalExamItem={infantMedicalExamItem}
          baby={baby}
        />
      )}

      {infantMedicalExamItem?.ageId === 11 && (
        <InfantThreeYearOldDetail
          infantMedicalExamItem={infantMedicalExamItem}
          baby={baby}
        />
      )}

      {infantMedicalExamItem?.ageId === 12 && (
        <InfantFourYearOldDetail
          infantMedicalExamItem={infantMedicalExamItem}
          baby={baby}
        />
      )}

      {infantMedicalExamItem?.ageId === 13 && (
        <InfantFiveYearOldDetail
          infantMedicalExamItem={infantMedicalExamItem}
          baby={baby}
        />
      )}

      {infantMedicalExamItem?.ageId === 14 && (
        <InfantSixYearOldDetail
          infantMedicalExamItem={infantMedicalExamItem}
          baby={baby}
        />
      )}
    </BootstrapDialogCustom>
  );
};
