import styled from "styled-components";
import { CardCustom, TextBlockCustom } from "@/components/display";
import { NotiCampaignTable } from "./components";

const StyledNotiCampaignListPage = styled.div``;

export const NotiCampaignListPage = () => {
  return (
    <StyledNotiCampaignListPage>
      <TextBlockCustom
        classText="text-lg font-bold mb-8"
        textHeader="通知リスト"
        textSize={24}
      />
      <CardCustom>
        <NotiCampaignTable />
      </CardCustom>
    </StyledNotiCampaignListPage>
  );
};
