import React, { useEffect, useState } from "react";
import {
  ButtonCustom,
  CardCustom,
  TextBlockCustom,
} from "@/components/display";
import { COLORS, noData } from "@/constants";
import { Grid } from "@mui/material";
import { vaccinatedRecordsApi, vaccinationSchedulesApi } from "@/api";
import { useQuery } from "react-query";
import { toJapanDate } from "@/utils";
import { DialogVacationRecords } from "./DialogVacationRecords";

interface IVaccinationManagementProps {
  userId?: number;
  babyId?: number;
}

export const VaccinationManagement = (
  props: React.PropsWithChildren<IVaccinationManagementProps>
) => {
  //--- Get vaccination schedules
  const { data: vaccinationSchedules, refetch: refetchVaccinationSchedules } =
    useQuery({
      queryKey: "GET_VACCINATION_SCHEDULES",
      queryFn: () => {
        return vaccinationSchedulesApi.adminVaccinationSchedulesGet({
          userId: props.userId,
          babyId: props.babyId,
          orderBy: "dateInjectSuggest",
          orderType: "asc",
        });
      },
    });

  //--- Get vaccination records
  const [vaccinationRecordId, setVaccinationRecordId] = useState(0);

  const { data: vaccinationRecords, refetch: refetchVaccinationRecords } =
    useQuery({
      queryKey: "GET_VACCINATION_RECORDS",
      queryFn: () => {
        return vaccinatedRecordsApi.adminVaccinatedRecordsGet({
          babyId: props.babyId,
          orderBy: "dateInjected",
          orderType: "desc",
        });
      },
    });

  //--- Dialog
  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpenDialog = (id: number) => {
    setVaccinationRecordId(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setVaccinationRecordId(0);
  };

  useEffect(() => {
    if (props.userId > 0 && props.babyId > 0) {
      refetchVaccinationSchedules();

      refetchVaccinationRecords();
    }
  }, [
    props.babyId,
    props.userId,
    refetchVaccinationSchedules,
    refetchVaccinationRecords,
  ]);

  return (
    <>
      <CardCustom classCard={"mt-4"}>
        <TextBlockCustom
          classText="mb-4 font-bold"
          textHeader="予防接種管理"
          headerColor={COLORS.primary}
          textSize={16}
        />

        <TextBlockCustom
          classText="mb-4"
          textHeader="次の予定"
          headerColor={COLORS.primary}
        />

        <Grid container spacing={3}>
          {vaccinationSchedules?.data.length > 0 ? (
            (vaccinationSchedules?.data || []).map((item, index) => (
              <Grid item xs={4} key={"vaccinationSchedules_" + index}>
                <Grid container alignItems={"center"}>
                  <Grid item xs={10}>
                    <Grid container>
                      <Grid item xs={4}>
                        <TextBlockCustom
                          classText="mb-2"
                          textHeader="接種日:"
                          headerColor={COLORS.approxGrey}
                        />
                        <TextBlockCustom
                          classText="mb-2"
                          textHeader="接種するワクチン:"
                          headerColor={COLORS.approxGrey}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <TextBlockCustom
                          classText="font-medium mb-2"
                          textHeader={toJapanDate(item?.dateInjectSuggest)}
                        />
                        <TextBlockCustom
                          classText="font-medium mb-2"
                          textHeader={item?.vaccineName}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <div className="divide-dot"></div>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <div className="text-center font-bold">{noData}</div>
            </Grid>
          )}
        </Grid>

        <TextBlockCustom
          classText="mt-6 mb-4"
          textHeader="接種済のワクチン"
          headerColor={COLORS.primary}
        />

        <Grid container spacing={3}>
          {vaccinationRecords?.data.length > 0 ? (
            (vaccinationRecords?.data || []).map((item, index) => (
              <Grid item xs={4} key={"vaccinationRecords_" + index}>
                <Grid container alignItems={"center"}>
                  <Grid item xs={10}>
                    <Grid container>
                      <Grid item xs={4}>
                        <TextBlockCustom
                          classText="mb-2"
                          textHeader="接種日"
                          headerColor={COLORS.approxGrey}
                        />
                        <TextBlockCustom
                          classText="mb-2"
                          textHeader="接種するワクチン"
                          headerColor={COLORS.approxGrey}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <TextBlockCustom
                          classText="font-medium mb-2"
                          textHeader={toJapanDate(item?.dateInjected)}
                        />
                        <TextBlockCustom
                          classText="font-medium mb-2"
                          textHeader={item?.vaccineName}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2} justifySelf="flex-end">
                    <div className="flex justify-end">
                      <ButtonCustom
                        title="詳細"
                        onClick={() => handleClickOpenDialog(item?.id)}
                      />
                    </div>
                  </Grid>
                </Grid>
                <div className="divide-dot"></div>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <div className="text-center font-bold">{noData}</div>
            </Grid>
          )}
        </Grid>
      </CardCustom>

      <DialogVacationRecords
        openDialog={openDialog}
        onCloseDialog={() => handleCloseDialog()}
        vaccinationRecordItem={vaccinationRecords?.data?.find(
          (v) => v.id === vaccinationRecordId
        )}
      />
    </>
  );
};
