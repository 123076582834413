/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DeleteResponse,
    DeleteResponseFromJSON,
    DeleteResponseToJSON,
    PutRequestBody,
    PutRequestBodyFromJSON,
    PutRequestBodyToJSON,
    VaccinationSchedules,
    VaccinationSchedulesFromJSON,
    VaccinationSchedulesToJSON,
    VaccinationSchedulesPagination,
    VaccinationSchedulesPaginationFromJSON,
    VaccinationSchedulesPaginationToJSON,
    VaccinationSchedulesPutResponse,
    VaccinationSchedulesPutResponseFromJSON,
    VaccinationSchedulesPutResponseToJSON,
    VaccinationSchedulesRequest,
    VaccinationSchedulesRequestFromJSON,
    VaccinationSchedulesRequestToJSON,
} from '../models';

export interface AdminVaccinationSchedulesGetRequest {
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
    userId?: number;
}

export interface AllVaccinationSchedulesGetRequest {
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface VaccinationSchedulesDeleteRequest {
    deleteResponse?: DeleteResponse;
}

export interface VaccinationSchedulesGetRequest {
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface VaccinationSchedulesIdGetRequest {
    id: number;
}

export interface VaccinationSchedulesIdPutRequest {
    id: number;
    putRequestBody?: PutRequestBody;
}

export interface VaccinationSchedulesPostRequest {
    vaccinationSchedulesRequest?: VaccinationSchedulesRequest;
}

export interface VaccinationSchedulesShareAllGetRequest {
    babyId: number;
    sourceUserId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

/**
 * 
 */
export class VaccinationSchedulesApi extends runtime.BaseAPI {

    /**
     * 
     */
    adminVaccinationSchedulesGetRaw = async (requestParameters: AdminVaccinationSchedulesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VaccinationSchedulesPagination>> => {
        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling adminVaccinationSchedulesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/vaccinationSchedules`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VaccinationSchedulesPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    adminVaccinationSchedulesGet = async (requestParameters: AdminVaccinationSchedulesGetRequest, initOverrides?: RequestInit): Promise<VaccinationSchedulesPagination> => {
        const response = await this.adminVaccinationSchedulesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    allVaccinationSchedulesGetRaw = async (requestParameters: AllVaccinationSchedulesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VaccinationSchedulesPagination>> => {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/allVaccinationSchedules`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VaccinationSchedulesPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    allVaccinationSchedulesGet = async (requestParameters: AllVaccinationSchedulesGetRequest = {}, initOverrides?: RequestInit): Promise<VaccinationSchedulesPagination> => {
        const response = await this.allVaccinationSchedulesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    vaccinationSchedulesDeleteRaw = async (requestParameters: VaccinationSchedulesDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/VaccinationSchedules`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteResponseToJSON(requestParameters.deleteResponse),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    vaccinationSchedulesDelete = async (requestParameters: VaccinationSchedulesDeleteRequest = {}, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.vaccinationSchedulesDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    vaccinationSchedulesGetRaw = async (requestParameters: VaccinationSchedulesGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VaccinationSchedulesPagination>> => {
        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling vaccinationSchedulesGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/VaccinationSchedules`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VaccinationSchedulesPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    vaccinationSchedulesGet = async (requestParameters: VaccinationSchedulesGetRequest, initOverrides?: RequestInit): Promise<VaccinationSchedulesPagination> => {
        const response = await this.vaccinationSchedulesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    vaccinationSchedulesIdGetRaw = async (requestParameters: VaccinationSchedulesIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VaccinationSchedules>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling vaccinationSchedulesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/VaccinationSchedules/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VaccinationSchedulesFromJSON(jsonValue));
    }

    /**
     * 
     */
    vaccinationSchedulesIdGet = async (requestParameters: VaccinationSchedulesIdGetRequest, initOverrides?: RequestInit): Promise<VaccinationSchedules> => {
        const response = await this.vaccinationSchedulesIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    vaccinationSchedulesIdPutRaw = async (requestParameters: VaccinationSchedulesIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VaccinationSchedulesPutResponse>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling vaccinationSchedulesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/VaccinationSchedules/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutRequestBodyToJSON(requestParameters.putRequestBody),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VaccinationSchedulesPutResponseFromJSON(jsonValue));
    }

    /**
     */
    vaccinationSchedulesIdPut = async (requestParameters: VaccinationSchedulesIdPutRequest, initOverrides?: RequestInit): Promise<VaccinationSchedulesPutResponse> => {
        const response = await this.vaccinationSchedulesIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    vaccinationSchedulesPostRaw = async (requestParameters: VaccinationSchedulesPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<VaccinationSchedules>>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/VaccinationSchedules`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VaccinationSchedulesRequestToJSON(requestParameters.vaccinationSchedulesRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VaccinationSchedulesFromJSON));
    }

    /**
     */
    vaccinationSchedulesPost = async (requestParameters: VaccinationSchedulesPostRequest = {}, initOverrides?: RequestInit): Promise<Array<VaccinationSchedules>> => {
        const response = await this.vaccinationSchedulesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    vaccinationSchedulesShareAllGetRaw = async (requestParameters: VaccinationSchedulesShareAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VaccinationSchedulesPagination>> => {
        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling vaccinationSchedulesShareAllGet.');
        }

        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling vaccinationSchedulesShareAllGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/vaccinationSchedules/share/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VaccinationSchedulesPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    vaccinationSchedulesShareAllGet = async (requestParameters: VaccinationSchedulesShareAllGetRequest, initOverrides?: RequestInit): Promise<VaccinationSchedulesPagination> => {
        const response = await this.vaccinationSchedulesShareAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
