import {
  professionAndEnvironmentApi,
  ProfessionAndEnvironmentOfMom,
} from "@/api";
import { CardCustom, TextBlockCustom } from "@/components/display";
import { COLORS } from "@/constants";
import { getTextLiveTogether, toJapanDate, toTwoDigits } from "@/utils";
import { getTextNoise, getTextSunlight, getTypeOfResidence } from "@/utils";
import { Grid } from "@mui/material";
import React, { useState } from "react";
import { useQuery } from "react-query";

interface IMomProfessionAndEnvProps {
  userId?: number;
  motherId?: number;
}

export const MomProfessionAndEnv = (
  props: React.PropsWithChildren<IMomProfessionAndEnvProps>
) => {
  //--- Get fetus list
  const [professionAndEnvironmentOfMom, setProfessionAndEnvironmentOfMom] =
    useState<ProfessionAndEnvironmentOfMom>();

  useQuery({
    queryKey: "GET_PROFESSION_AND_ENVIRONMENT_OF_MOM",
    queryFn: () =>
      professionAndEnvironmentApi.adminProfessionAndEnvironmentOfMomsGet({
        userId: props.userId,
        motherId: props.motherId,
      }),
    onSuccess: (response) => {
      setProfessionAndEnvironmentOfMom(response.data[0]);
    },
  });

  return (
    <CardCustom classCard={"mt-5"}>
      <TextBlockCustom
        classText="mb-4 text-lg font-bold"
        textHeader="ママの職業と環境"
        headerColor={COLORS.primary}
        textSize={16}
      />

      <Grid container>
        <Grid item xs={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="職業"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={professionAndEnvironmentOfMom?.profession}
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="仕事の内容と職場環境"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  professionAndEnvironmentOfMom?.workContentAndEnvironment
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="仕事をする時間"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium mb-2"
                textHeader={
                  professionAndEnvironmentOfMom?.timeToWork
                    ? "1日約" +
                      professionAndEnvironmentOfMom?.timeToWork +
                      "時間"
                    : ""
                }
              />
              <TextBlockCustom
                classText="font-medium mb-2"
                textHeader={
                  (professionAndEnvironmentOfMom?.timeToWorkStart
                    ? professionAndEnvironmentOfMom?.timeToWorkStart + "時"
                    : "") +
                  (professionAndEnvironmentOfMom?.timeToWorkEnd !== undefined
                    ? "~" + professionAndEnvironmentOfMom?.timeToWorkEnd + "時"
                    : "")
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="通勤手段"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium mb-2"
                textHeader={professionAndEnvironmentOfMom?.meansOfCommuting}
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="通勤の時間"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium mb-2"
                textHeader={
                  professionAndEnvironmentOfMom?.commutingTime
                    ? professionAndEnvironmentOfMom?.commutingTime?.toString() +
                      "時間"
                    : ""
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="妊娠してからの変更点"
                headerColor={COLORS.primary}
                textSize={12}
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="休職"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium mb-2"
                textHeader={
                  professionAndEnvironmentOfMom?.leaveOfAbsence
                    ? "妊娠" +
                      professionAndEnvironmentOfMom?.leaveOfAbsence +
                      "週"
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="転職"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium mb-2"
                textHeader={
                  professionAndEnvironmentOfMom?.jobChange
                    ? "妊娠" + professionAndEnvironmentOfMom?.jobChange + "週"
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="辞職"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium mb-2"
                textHeader={
                  professionAndEnvironmentOfMom?.resignation
                    ? "妊娠" + professionAndEnvironmentOfMom?.resignation + "週"
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="メモ"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium mb-2"
                textHeader={professionAndEnvironmentOfMom?.memo}
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="産休"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium mb-2"
                textHeader={
                  professionAndEnvironmentOfMom?.maternityLeaveNumberDay
                    ? toJapanDate(
                        professionAndEnvironmentOfMom?.maternityLeave
                      ) +
                      "から" +
                      professionAndEnvironmentOfMom?.maternityLeaveNumberDay +
                      "日間"
                    : toJapanDate(professionAndEnvironmentOfMom?.maternityLeave)
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="育休"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium mb-2"
                textHeader={
                  professionAndEnvironmentOfMom?.childcareLeaveNumberDay
                    ? toJapanDate(
                        professionAndEnvironmentOfMom?.childcareLeave
                      ) +
                      "から" +
                      professionAndEnvironmentOfMom?.childcareLeaveNumberDay +
                      "日間"
                    : toJapanDate(professionAndEnvironmentOfMom?.childcareLeave)
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="住居の種類"
                headerColor={COLORS.primary}
                textSize={12}
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="住居の種類"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium mb-2"
                textHeader={
                  professionAndEnvironmentOfMom?.typeOfResidence
                    ? getTypeOfResidence(
                        professionAndEnvironmentOfMom?.typeOfResidence
                      )
                    : ""
                }
              />
              <TextBlockCustom
                classText="font-medium mb-2"
                textHeader={
                  professionAndEnvironmentOfMom?.typeOfResidence === "OTHERS"
                    ? professionAndEnvironmentOfMom?.typeOfResidenceOther || ""
                    : (professionAndEnvironmentOfMom?.typeOfResidenceFloorBuilding
                        ? toTwoDigits(
                            professionAndEnvironmentOfMom?.typeOfResidenceFloorBuilding
                          ) + "階建て"
                        : "") +
                      (professionAndEnvironmentOfMom?.typeOfResidenceFloor
                        ? toTwoDigits(
                            professionAndEnvironmentOfMom?.typeOfResidenceFloor
                          ) + "階"
                        : "")
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="騒音"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium mb-2"
                textHeader={
                  professionAndEnvironmentOfMom?.noise
                    ? getTextNoise(professionAndEnvironmentOfMom?.noise)
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="日当たり"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium mb-2"
                textHeader={
                  professionAndEnvironmentOfMom?.sunlight
                    ? getTextSunlight(professionAndEnvironmentOfMom?.sunlight)
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="同居"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              {professionAndEnvironmentOfMom?.liveTogetherChild && (
                <>
                  <TextBlockCustom
                    classText="font-medium mb-2"
                    textHeader={
                      professionAndEnvironmentOfMom?.liveTogetherChild
                        ? "子ども"
                        : ""
                    }
                  />
                  {professionAndEnvironmentOfMom?.liveTogetherChildNumber && (
                    <TextBlockCustom
                      classText="font-medium mb-2"
                      textHeader={
                        professionAndEnvironmentOfMom?.liveTogetherChildNumber +
                        "人"
                      }
                    />
                  )}
                </>
              )}

              {professionAndEnvironmentOfMom?.liveTogether?.length > 0 &&
                professionAndEnvironmentOfMom?.liveTogether
                  .split(",")
                  .map(
                    (item, index) =>
                      !!item && (
                        <TextBlockCustom
                          key={index}
                          classText="font-medium mb-2"
                          textHeader={getTextLiveTogether(item)}
                        />
                      )
                  )}

              {professionAndEnvironmentOfMom?.liveTogetherOther && (
                <>
                  <TextBlockCustom
                    classText="font-medium mb-2"
                    textHeader={
                      professionAndEnvironmentOfMom?.liveTogetherOther
                        ? "その他"
                        : ""
                    }
                  />

                  {!!professionAndEnvironmentOfMom?.liveTogetherOtherNumber && (
                    <TextBlockCustom
                      classText="font-medium mb-2"
                      textHeader={
                        professionAndEnvironmentOfMom?.liveTogetherOtherNumber +
                        "人"
                      }
                    />
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CardCustom>
  );
};
