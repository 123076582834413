/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';
import type { ProfessionAndEnvironmentOfMomAllOf } from './ProfessionAndEnvironmentOfMomAllOf';
import {
    ProfessionAndEnvironmentOfMomAllOfFromJSON,
    ProfessionAndEnvironmentOfMomAllOfFromJSONTyped,
    ProfessionAndEnvironmentOfMomAllOfToJSON,
} from './ProfessionAndEnvironmentOfMomAllOf';

/**
 * 
 * @export
 * @interface ProfessionAndEnvironmentOfMom
 */
export interface ProfessionAndEnvironmentOfMom {
    /**
     * 
     * @type {number}
     * @memberof ProfessionAndEnvironmentOfMom
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProfessionAndEnvironmentOfMom
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfessionAndEnvironmentOfMom
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ProfessionAndEnvironmentOfMom
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof ProfessionAndEnvironmentOfMom
     */
    motherId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProfessionAndEnvironmentOfMom
     */
    profession?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfessionAndEnvironmentOfMom
     */
    workContentAndEnvironment?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProfessionAndEnvironmentOfMom
     */
    timeToWork?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProfessionAndEnvironmentOfMom
     */
    timeToWorkStart?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProfessionAndEnvironmentOfMom
     */
    timeToWorkEnd?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProfessionAndEnvironmentOfMom
     */
    meansOfCommuting?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProfessionAndEnvironmentOfMom
     */
    commutingTime?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProfessionAndEnvironmentOfMom
     */
    leaveOfAbsence?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProfessionAndEnvironmentOfMom
     */
    resignation?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProfessionAndEnvironmentOfMom
     */
    jobChange?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProfessionAndEnvironmentOfMom
     */
    memo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfessionAndEnvironmentOfMom
     */
    maternityLeave?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProfessionAndEnvironmentOfMom
     */
    maternityLeaveNumberDay?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProfessionAndEnvironmentOfMom
     */
    childcareLeave?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProfessionAndEnvironmentOfMom
     */
    childcareLeaveNumberDay?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProfessionAndEnvironmentOfMom
     */
    typeOfResidence?: ProfessionAndEnvironmentOfMomTypeOfResidenceEnum;
    /**
     * 
     * @type {number}
     * @memberof ProfessionAndEnvironmentOfMom
     */
    typeOfResidenceFloorBuilding?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProfessionAndEnvironmentOfMom
     */
    typeOfResidenceFloor?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProfessionAndEnvironmentOfMom
     */
    typeOfResidenceOther?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfessionAndEnvironmentOfMom
     */
    noise?: ProfessionAndEnvironmentOfMomNoiseEnum;
    /**
     * 
     * @type {string}
     * @memberof ProfessionAndEnvironmentOfMom
     */
    sunlight?: ProfessionAndEnvironmentOfMomSunlightEnum;
    /**
     * 
     * @type {string}
     * @memberof ProfessionAndEnvironmentOfMom
     */
    liveTogether?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProfessionAndEnvironmentOfMom
     */
    liveTogetherChild?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof ProfessionAndEnvironmentOfMom
     */
    liveTogetherChildNumber?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProfessionAndEnvironmentOfMom
     */
    liveTogetherOther?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof ProfessionAndEnvironmentOfMom
     */
    liveTogetherOtherNumber?: number | null;
}


/**
 * @export
 */
export const ProfessionAndEnvironmentOfMomTypeOfResidenceEnum = {
    Blank: 'BLANK',
    DetachedHouse: 'DETACHED_HOUSE',
    HousingComplex: 'HOUSING_COMPLEX',
    Others: 'OTHERS'
} as const;
export type ProfessionAndEnvironmentOfMomTypeOfResidenceEnum = typeof ProfessionAndEnvironmentOfMomTypeOfResidenceEnum[keyof typeof ProfessionAndEnvironmentOfMomTypeOfResidenceEnum];

/**
 * @export
 */
export const ProfessionAndEnvironmentOfMomNoiseEnum = {
    Blank: 'BLANK',
    Quiet: 'QUIET',
    Usually: 'USUALLY',
    Noisy: 'NOISY'
} as const;
export type ProfessionAndEnvironmentOfMomNoiseEnum = typeof ProfessionAndEnvironmentOfMomNoiseEnum[keyof typeof ProfessionAndEnvironmentOfMomNoiseEnum];

/**
 * @export
 */
export const ProfessionAndEnvironmentOfMomSunlightEnum = {
    Blank: 'BLANK',
    Good: 'GOOD',
    Usually: 'USUALLY',
    Evil: 'EVIL'
} as const;
export type ProfessionAndEnvironmentOfMomSunlightEnum = typeof ProfessionAndEnvironmentOfMomSunlightEnum[keyof typeof ProfessionAndEnvironmentOfMomSunlightEnum];


/**
 * Check if a given object implements the ProfessionAndEnvironmentOfMom interface.
 */
export function instanceOfProfessionAndEnvironmentOfMom(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProfessionAndEnvironmentOfMomFromJSON(json: any): ProfessionAndEnvironmentOfMom {
    return ProfessionAndEnvironmentOfMomFromJSONTyped(json, false);
}

export function ProfessionAndEnvironmentOfMomFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfessionAndEnvironmentOfMom {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'motherId': !exists(json, 'motherId') ? undefined : json['motherId'],
        'profession': !exists(json, 'profession') ? undefined : json['profession'],
        'workContentAndEnvironment': !exists(json, 'workContentAndEnvironment') ? undefined : json['workContentAndEnvironment'],
        'timeToWork': !exists(json, 'timeToWork') ? undefined : json['timeToWork'],
        'timeToWorkStart': !exists(json, 'timeToWorkStart') ? undefined : json['timeToWorkStart'],
        'timeToWorkEnd': !exists(json, 'timeToWorkEnd') ? undefined : json['timeToWorkEnd'],
        'meansOfCommuting': !exists(json, 'meansOfCommuting') ? undefined : json['meansOfCommuting'],
        'commutingTime': !exists(json, 'commutingTime') ? undefined : json['commutingTime'],
        'leaveOfAbsence': !exists(json, 'leaveOfAbsence') ? undefined : json['leaveOfAbsence'],
        'resignation': !exists(json, 'resignation') ? undefined : json['resignation'],
        'jobChange': !exists(json, 'jobChange') ? undefined : json['jobChange'],
        'memo': !exists(json, 'memo') ? undefined : json['memo'],
        'maternityLeave': !exists(json, 'maternityLeave') ? undefined : json['maternityLeave'],
        'maternityLeaveNumberDay': !exists(json, 'maternityLeaveNumberDay') ? undefined : json['maternityLeaveNumberDay'],
        'childcareLeave': !exists(json, 'childcareLeave') ? undefined : json['childcareLeave'],
        'childcareLeaveNumberDay': !exists(json, 'childcareLeaveNumberDay') ? undefined : json['childcareLeaveNumberDay'],
        'typeOfResidence': !exists(json, 'typeOfResidence') ? undefined : json['typeOfResidence'],
        'typeOfResidenceFloorBuilding': !exists(json, 'typeOfResidenceFloorBuilding') ? undefined : json['typeOfResidenceFloorBuilding'],
        'typeOfResidenceFloor': !exists(json, 'typeOfResidenceFloor') ? undefined : json['typeOfResidenceFloor'],
        'typeOfResidenceOther': !exists(json, 'typeOfResidenceOther') ? undefined : json['typeOfResidenceOther'],
        'noise': !exists(json, 'noise') ? undefined : json['noise'],
        'sunlight': !exists(json, 'sunlight') ? undefined : json['sunlight'],
        'liveTogether': !exists(json, 'liveTogether') ? undefined : json['liveTogether'],
        'liveTogetherChild': !exists(json, 'liveTogetherChild') ? undefined : json['liveTogetherChild'],
        'liveTogetherChildNumber': !exists(json, 'liveTogetherChildNumber') ? undefined : json['liveTogetherChildNumber'],
        'liveTogetherOther': !exists(json, 'liveTogetherOther') ? undefined : json['liveTogetherOther'],
        'liveTogetherOtherNumber': !exists(json, 'liveTogetherOtherNumber') ? undefined : json['liveTogetherOtherNumber'],
    };
}

export function ProfessionAndEnvironmentOfMomToJSON(value?: ProfessionAndEnvironmentOfMom | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'userId': value.userId,
        'motherId': value.motherId,
        'profession': value.profession,
        'workContentAndEnvironment': value.workContentAndEnvironment,
        'timeToWork': value.timeToWork,
        'timeToWorkStart': value.timeToWorkStart,
        'timeToWorkEnd': value.timeToWorkEnd,
        'meansOfCommuting': value.meansOfCommuting,
        'commutingTime': value.commutingTime,
        'leaveOfAbsence': value.leaveOfAbsence,
        'resignation': value.resignation,
        'jobChange': value.jobChange,
        'memo': value.memo,
        'maternityLeave': value.maternityLeave,
        'maternityLeaveNumberDay': value.maternityLeaveNumberDay,
        'childcareLeave': value.childcareLeave,
        'childcareLeaveNumberDay': value.childcareLeaveNumberDay,
        'typeOfResidence': value.typeOfResidence,
        'typeOfResidenceFloorBuilding': value.typeOfResidenceFloorBuilding,
        'typeOfResidenceFloor': value.typeOfResidenceFloor,
        'typeOfResidenceOther': value.typeOfResidenceOther,
        'noise': value.noise,
        'sunlight': value.sunlight,
        'liveTogether': value.liveTogether,
        'liveTogetherChild': value.liveTogetherChild,
        'liveTogetherChildNumber': value.liveTogetherChildNumber,
        'liveTogetherOther': value.liveTogetherOther,
        'liveTogetherOtherNumber': value.liveTogetherOtherNumber,
    };
}

