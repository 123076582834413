import React from "react";
import { TextBlockCustom } from "@/components/display";
import { COLORS } from "@/constants";
import { Grid } from "@mui/material";
import { BootstrapDialogCustom } from "@/components/dialog";
import { toJapanDate } from "@/utils";
import { MedicalRecord } from "@/api";

interface IDialogMorbidityHistoryProps {
  openDialog?: boolean;
  onCloseDialog?: () => void;
  medicalRecordItem?: MedicalRecord;
}

export const DialogMorbidityHistory = (
  props: React.PropsWithChildren<IDialogMorbidityHistoryProps>
) => {
  const { openDialog, onCloseDialog, medicalRecordItem } = props;

  return (
    <>
      <BootstrapDialogCustom
        openDialog={openDialog}
        onCloseDialog={onCloseDialog}
      >
        <Grid container spacing={2} key={medicalRecordItem?.id}>
          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader="病名"
              headerColor={COLORS.approxGrey}
            />
            <TextBlockCustom
              classText="font-medium"
              textHeader={
                medicalRecordItem?.diseaseName
                  ? medicalRecordItem?.diseaseName
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader="病気になった日"
              headerColor={COLORS.approxGrey}
            />
            <TextBlockCustom
              classText="font-medium"
              textHeader={
                medicalRecordItem?.sickDay
                  ? toJapanDate(medicalRecordItem.sickDay)
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader="症状・経過等"
              headerColor={COLORS.approxGrey}
            />
            <TextBlockCustom
              classText="font-medium"
              textHeader={
                medicalRecordItem?.symptom ? medicalRecordItem?.symptom : ""
              }
            />
          </Grid>
        </Grid>
      </BootstrapDialogCustom>
    </>
  );
};
