/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AgeData,
    AgeDataFromJSON,
    AgeDataToJSON,
    AgeDataGet200Response,
    AgeDataGet200ResponseFromJSON,
    AgeDataGet200ResponseToJSON,
} from '../models';

export interface AgeDataGetRequest {
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface AgeDataIdDeleteRequest {
    id: number;
}

export interface AgeDataIdGetRequest {
    id: number;
}

export interface AgeDataIdPutRequest {
    id: number;
    ageData?: AgeData;
}

export interface AgeDataPostRequest {
    ageData?: AgeData;
}

/**
 * 
 */
export class AgeDataApi extends runtime.BaseAPI {

    /**
     * 
     */
    ageDataGetRaw = async (requestParameters: AgeDataGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AgeDataGet200Response>> => {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ageData`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AgeDataGet200ResponseFromJSON(jsonValue));
    }

    /**
     * 
     */
    ageDataGet = async (requestParameters: AgeDataGetRequest = {}, initOverrides?: RequestInit): Promise<AgeDataGet200Response> => {
        const response = await this.ageDataGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    ageDataIdDeleteRaw = async (requestParameters: AgeDataIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling ageDataIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ageData/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    ageDataIdDelete = async (requestParameters: AgeDataIdDeleteRequest, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.ageDataIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    ageDataIdGetRaw = async (requestParameters: AgeDataIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AgeData>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling ageDataIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/ageData/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AgeDataFromJSON(jsonValue));
    }

    /**
     * 
     */
    ageDataIdGet = async (requestParameters: AgeDataIdGetRequest, initOverrides?: RequestInit): Promise<AgeData> => {
        const response = await this.ageDataIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    ageDataIdPutRaw = async (requestParameters: AgeDataIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AgeData>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling ageDataIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/ageData/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AgeDataToJSON(requestParameters.ageData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AgeDataFromJSON(jsonValue));
    }

    /**
     */
    ageDataIdPut = async (requestParameters: AgeDataIdPutRequest, initOverrides?: RequestInit): Promise<AgeData> => {
        const response = await this.ageDataIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    ageDataPostRaw = async (requestParameters: AgeDataPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AgeData>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/ageData`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AgeDataToJSON(requestParameters.ageData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AgeDataFromJSON(jsonValue));
    }

    /**
     */
    ageDataPost = async (requestParameters: AgeDataPostRequest = {}, initOverrides?: RequestInit): Promise<AgeData> => {
        const response = await this.ageDataPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
