/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';
import type { PrescriptionDrugAllOf } from './PrescriptionDrugAllOf';
import {
    PrescriptionDrugAllOfFromJSON,
    PrescriptionDrugAllOfFromJSONTyped,
    PrescriptionDrugAllOfToJSON,
} from './PrescriptionDrugAllOf';

/**
 * 
 * @export
 * @interface PrescriptionDrug
 */
export interface PrescriptionDrug {
    /**
     * 
     * @type {number}
     * @memberof PrescriptionDrug
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionDrug
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionDrug
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof PrescriptionDrug
     */
    diagnosisId?: number;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionDrug
     */
    prescriptionDrugName?: string;
}

/**
 * Check if a given object implements the PrescriptionDrug interface.
 */
export function instanceOfPrescriptionDrug(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PrescriptionDrugFromJSON(json: any): PrescriptionDrug {
    return PrescriptionDrugFromJSONTyped(json, false);
}

export function PrescriptionDrugFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrescriptionDrug {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'diagnosisId': !exists(json, 'diagnosisId') ? undefined : json['diagnosisId'],
        'prescriptionDrugName': !exists(json, 'prescriptionDrugName') ? undefined : json['prescriptionDrugName'],
    };
}

export function PrescriptionDrugToJSON(value?: PrescriptionDrug | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'diagnosisId': value.diagnosisId,
        'prescriptionDrugName': value.prescriptionDrugName,
    };
}

