import React from "react";
import { TextBlockCustom } from "@/components/display";
import { COLORS } from "@/constants";
import { Grid } from "@mui/material";
import { BootstrapDialogCustom } from "@/components/dialog";
import { toJapanDate } from "@/utils";
import { RecordSideEffect } from "@/api";

interface IDialogSideEffectsProps {
  openDialog?: boolean;
  onCloseDialog?: () => void;
  recordSideEffectsItem?: RecordSideEffect;
}

export const DialogSideEffects = (
  props: React.PropsWithChildren<IDialogSideEffectsProps>
) => {
  const { openDialog, onCloseDialog, recordSideEffectsItem } = props;

  return (
    <>
      <BootstrapDialogCustom
        openDialog={openDialog}
        onCloseDialog={onCloseDialog}
      >
        <Grid container spacing={2} key={recordSideEffectsItem?.id}>
          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader="副反応があった日"
              headerColor={COLORS.approxGrey}
            />
            <TextBlockCustom
              classText="font-medium"
              textHeader={
                recordSideEffectsItem?.dayOfSideEffects
                  ? toJapanDate(recordSideEffectsItem.dayOfSideEffects)
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader="副反応があったワクチン"
              headerColor={COLORS.approxGrey}
            />
            <TextBlockCustom
              classText="font-medium"
              textHeader={
                recordSideEffectsItem?.vaccineName
                  ? recordSideEffectsItem?.vaccineName
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader="副反応の内容"
              headerColor={COLORS.approxGrey}
            />
            <TextBlockCustom
              classText="font-medium"
              textHeader={
                recordSideEffectsItem?.sideEffectsDetails
                  ? recordSideEffectsItem?.sideEffectsDetails
                  : ""
              }
            />
          </Grid>
        </Grid>
      </BootstrapDialogCustom>
    </>
  );
};
