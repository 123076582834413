/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';
import type { InfantSixYearOldAllOf } from './InfantSixYearOldAllOf';
import {
    InfantSixYearOldAllOfFromJSON,
    InfantSixYearOldAllOfFromJSONTyped,
    InfantSixYearOldAllOfToJSON,
} from './InfantSixYearOldAllOf';

/**
 * 
 * @export
 * @interface InfantSixYearOld
 */
export interface InfantSixYearOld {
    /**
     * 
     * @type {number}
     * @memberof InfantSixYearOld
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof InfantSixYearOld
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof InfantSixYearOld
     */
    babyId?: number;
    /**
     * 
     * @type {number}
     * @memberof InfantSixYearOld
     */
    ageId?: number;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    implementationDate?: string;
    /**
     * 
     * @type {number}
     * @memberof InfantSixYearOld
     */
    year?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantSixYearOld
     */
    month?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    photo1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    photo2?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InfantSixYearOld
     */
    bodyWeight?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantSixYearOld
     */
    height?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantSixYearOld
     */
    kaupIndex?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantSixYearOld
     */
    headCircumference?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    nutritionalStatus?: InfantSixYearOldNutritionalStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    eyeAbnormalities?: InfantSixYearOldEyeAbnormalitiesEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    eyeAbnormalitiesNote?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InfantSixYearOld
     */
    sightLeft?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantSixYearOld
     */
    sightRight?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    earAbnormalities?: InfantSixYearOldEarAbnormalitiesEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    earAbnormalitiesNote?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    healthObservation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    remarks?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    facilityOrPersonName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    dayCaregiver?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    whatReceiving?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    cariesRequiringTreatment?: InfantSixYearOldCariesRequiringTreatmentEnum;
    /**
     * 
     * @type {number}
     * @memberof InfantSixYearOld
     */
    milkTeeth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantSixYearOld
     */
    permanentTeeth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InfantSixYearOld
     */
    total?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    dirtyTeeth?: InfantSixYearOldDirtyTeethEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    gingivaMucousMembrane?: InfantSixYearOldGingivaMucousMembraneEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    gingivaMucousMembraneNote?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    engagement?: InfantSixYearOldEngagementEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    abnormalToothOralDisease?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    examinationDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    rightMolarsMaxillaA?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    rightMolarsMaxillaB?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    leftFrontToothMaxillaA?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    leftFrontToothMaxillaB?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    rightMolarsLowerJawA?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    rightMolarsLowerJawB?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    leftFrontToothLowerJawA?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    leftFrontToothLowerJawB?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    urineProtein?: InfantSixYearOldUrineProteinEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    urineSugar?: InfantSixYearOldUrineSugarEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    urineOccultBlood?: InfantSixYearOldUrineOccultBloodEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    whiteBloodCells?: InfantSixYearOldWhiteBloodCellsEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    parentMessage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    recordingDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    standOnOneLeg?: InfantSixYearOldStandOnOneLegEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    writeByImitating?: InfantSixYearOldWriteByImitatingEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    knowFrontBackLeftRight?: InfantSixYearOldKnowFrontBackLeftRightEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    writeNameInHiragana?: InfantSixYearOldWriteNameInHiraganaEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    putUpWithToysAndSweets?: InfantSixYearOldPutUpWithToysAndSweetsEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    keepPromisesAndRules?: InfantSixYearOldKeepPromisesAndRulesEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    firstMolarsGrow?: InfantSixYearOldFirstMolarsGrowEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    brushYourTeeth?: InfantSixYearOldBrushYourTeethEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    eatBreakfast?: InfantSixYearOldEatBreakfastEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    childRearing?: InfantSixYearOldChildRearingEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    raisingChildren?: InfantSixYearOldRaisingChildrenEnum;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    yourGrowth?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantSixYearOld
     */
    photo3?: string | null;
}


/**
 * @export
 */
export const InfantSixYearOldNutritionalStatusEnum = {
    ALittleFluffy: 'aLittleFluffy',
    Usually: 'usually',
    Skinny: 'skinny'
} as const;
export type InfantSixYearOldNutritionalStatusEnum = typeof InfantSixYearOldNutritionalStatusEnum[keyof typeof InfantSixYearOldNutritionalStatusEnum];

/**
 * @export
 */
export const InfantSixYearOldEyeAbnormalitiesEnum = {
    None: 'none',
    CanBe: 'canBe',
    Suspicion: 'suspicion'
} as const;
export type InfantSixYearOldEyeAbnormalitiesEnum = typeof InfantSixYearOldEyeAbnormalitiesEnum[keyof typeof InfantSixYearOldEyeAbnormalitiesEnum];

/**
 * @export
 */
export const InfantSixYearOldEarAbnormalitiesEnum = {
    None: 'none',
    CanBe: 'canBe',
    Suspicion: 'suspicion'
} as const;
export type InfantSixYearOldEarAbnormalitiesEnum = typeof InfantSixYearOldEarAbnormalitiesEnum[keyof typeof InfantSixYearOldEarAbnormalitiesEnum];

/**
 * @export
 */
export const InfantSixYearOldCariesRequiringTreatmentEnum = {
    None: 'none',
    CanBe: 'canBe',
    NotClear: 'notClear'
} as const;
export type InfantSixYearOldCariesRequiringTreatmentEnum = typeof InfantSixYearOldCariesRequiringTreatmentEnum[keyof typeof InfantSixYearOldCariesRequiringTreatmentEnum];

/**
 * @export
 */
export const InfantSixYearOldDirtyTeethEnum = {
    Beautiful: 'beautiful',
    Few: 'few',
    Many: 'many'
} as const;
export type InfantSixYearOldDirtyTeethEnum = typeof InfantSixYearOldDirtyTeethEnum[keyof typeof InfantSixYearOldDirtyTeethEnum];

/**
 * @export
 */
export const InfantSixYearOldGingivaMucousMembraneEnum = {
    NoAbnormality: 'noAbnormality',
    CanBe: 'canBe',
    NotClear: 'notClear'
} as const;
export type InfantSixYearOldGingivaMucousMembraneEnum = typeof InfantSixYearOldGingivaMucousMembraneEnum[keyof typeof InfantSixYearOldGingivaMucousMembraneEnum];

/**
 * @export
 */
export const InfantSixYearOldEngagementEnum = {
    Good: 'good',
    FollowUp: 'followUp',
    NotClear: 'notClear'
} as const;
export type InfantSixYearOldEngagementEnum = typeof InfantSixYearOldEngagementEnum[keyof typeof InfantSixYearOldEngagementEnum];

/**
 * @export
 */
export const InfantSixYearOldUrineProteinEnum = {
    Minus: 'minus',
    PlusAndMinus: 'plusAndMinus',
    Plus: 'plus',
    DoublePlus: 'doublePlus'
} as const;
export type InfantSixYearOldUrineProteinEnum = typeof InfantSixYearOldUrineProteinEnum[keyof typeof InfantSixYearOldUrineProteinEnum];

/**
 * @export
 */
export const InfantSixYearOldUrineSugarEnum = {
    Minus: 'minus',
    PlusAndMinus: 'plusAndMinus',
    Plus: 'plus',
    DoublePlus: 'doublePlus'
} as const;
export type InfantSixYearOldUrineSugarEnum = typeof InfantSixYearOldUrineSugarEnum[keyof typeof InfantSixYearOldUrineSugarEnum];

/**
 * @export
 */
export const InfantSixYearOldUrineOccultBloodEnum = {
    Minus: 'minus',
    PlusAndMinus: 'plusAndMinus',
    Plus: 'plus',
    DoublePlus: 'doublePlus'
} as const;
export type InfantSixYearOldUrineOccultBloodEnum = typeof InfantSixYearOldUrineOccultBloodEnum[keyof typeof InfantSixYearOldUrineOccultBloodEnum];

/**
 * @export
 */
export const InfantSixYearOldWhiteBloodCellsEnum = {
    Minus: 'minus',
    PlusAndMinus: 'plusAndMinus',
    Plus: 'plus',
    DoublePlus: 'doublePlus'
} as const;
export type InfantSixYearOldWhiteBloodCellsEnum = typeof InfantSixYearOldWhiteBloodCellsEnum[keyof typeof InfantSixYearOldWhiteBloodCellsEnum];

/**
 * @export
 */
export const InfantSixYearOldStandOnOneLegEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantSixYearOldStandOnOneLegEnum = typeof InfantSixYearOldStandOnOneLegEnum[keyof typeof InfantSixYearOldStandOnOneLegEnum];

/**
 * @export
 */
export const InfantSixYearOldWriteByImitatingEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantSixYearOldWriteByImitatingEnum = typeof InfantSixYearOldWriteByImitatingEnum[keyof typeof InfantSixYearOldWriteByImitatingEnum];

/**
 * @export
 */
export const InfantSixYearOldKnowFrontBackLeftRightEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantSixYearOldKnowFrontBackLeftRightEnum = typeof InfantSixYearOldKnowFrontBackLeftRightEnum[keyof typeof InfantSixYearOldKnowFrontBackLeftRightEnum];

/**
 * @export
 */
export const InfantSixYearOldWriteNameInHiraganaEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantSixYearOldWriteNameInHiraganaEnum = typeof InfantSixYearOldWriteNameInHiraganaEnum[keyof typeof InfantSixYearOldWriteNameInHiraganaEnum];

/**
 * @export
 */
export const InfantSixYearOldPutUpWithToysAndSweetsEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantSixYearOldPutUpWithToysAndSweetsEnum = typeof InfantSixYearOldPutUpWithToysAndSweetsEnum[keyof typeof InfantSixYearOldPutUpWithToysAndSweetsEnum];

/**
 * @export
 */
export const InfantSixYearOldKeepPromisesAndRulesEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantSixYearOldKeepPromisesAndRulesEnum = typeof InfantSixYearOldKeepPromisesAndRulesEnum[keyof typeof InfantSixYearOldKeepPromisesAndRulesEnum];

/**
 * @export
 */
export const InfantSixYearOldFirstMolarsGrowEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantSixYearOldFirstMolarsGrowEnum = typeof InfantSixYearOldFirstMolarsGrowEnum[keyof typeof InfantSixYearOldFirstMolarsGrowEnum];

/**
 * @export
 */
export const InfantSixYearOldBrushYourTeethEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantSixYearOldBrushYourTeethEnum = typeof InfantSixYearOldBrushYourTeethEnum[keyof typeof InfantSixYearOldBrushYourTeethEnum];

/**
 * @export
 */
export const InfantSixYearOldEatBreakfastEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantSixYearOldEatBreakfastEnum = typeof InfantSixYearOldEatBreakfastEnum[keyof typeof InfantSixYearOldEatBreakfastEnum];

/**
 * @export
 */
export const InfantSixYearOldChildRearingEnum = {
    Yes: 'yes',
    No: 'no'
} as const;
export type InfantSixYearOldChildRearingEnum = typeof InfantSixYearOldChildRearingEnum[keyof typeof InfantSixYearOldChildRearingEnum];

/**
 * @export
 */
export const InfantSixYearOldRaisingChildrenEnum = {
    No: 'no',
    Yes: 'yes',
    CanNotSayAnything: 'canNotSayAnything'
} as const;
export type InfantSixYearOldRaisingChildrenEnum = typeof InfantSixYearOldRaisingChildrenEnum[keyof typeof InfantSixYearOldRaisingChildrenEnum];


/**
 * Check if a given object implements the InfantSixYearOld interface.
 */
export function instanceOfInfantSixYearOld(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InfantSixYearOldFromJSON(json: any): InfantSixYearOld {
    return InfantSixYearOldFromJSONTyped(json, false);
}

export function InfantSixYearOldFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfantSixYearOld {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'babyId': !exists(json, 'babyId') ? undefined : json['babyId'],
        'ageId': !exists(json, 'ageId') ? undefined : json['ageId'],
        'implementationDate': !exists(json, 'implementationDate') ? undefined : json['implementationDate'],
        'year': !exists(json, 'year') ? undefined : json['year'],
        'month': !exists(json, 'month') ? undefined : json['month'],
        'photo1': !exists(json, 'photo1') ? undefined : json['photo1'],
        'photo2': !exists(json, 'photo2') ? undefined : json['photo2'],
        'bodyWeight': !exists(json, 'bodyWeight') ? undefined : json['bodyWeight'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'kaupIndex': !exists(json, 'kaupIndex') ? undefined : json['kaupIndex'],
        'headCircumference': !exists(json, 'headCircumference') ? undefined : json['headCircumference'],
        'nutritionalStatus': !exists(json, 'nutritionalStatus') ? undefined : json['nutritionalStatus'],
        'eyeAbnormalities': !exists(json, 'eyeAbnormalities') ? undefined : json['eyeAbnormalities'],
        'eyeAbnormalitiesNote': !exists(json, 'eyeAbnormalitiesNote') ? undefined : json['eyeAbnormalitiesNote'],
        'sightLeft': !exists(json, 'sightLeft') ? undefined : json['sightLeft'],
        'sightRight': !exists(json, 'sightRight') ? undefined : json['sightRight'],
        'earAbnormalities': !exists(json, 'earAbnormalities') ? undefined : json['earAbnormalities'],
        'earAbnormalitiesNote': !exists(json, 'earAbnormalitiesNote') ? undefined : json['earAbnormalitiesNote'],
        'healthObservation': !exists(json, 'healthObservation') ? undefined : json['healthObservation'],
        'remarks': !exists(json, 'remarks') ? undefined : json['remarks'],
        'facilityOrPersonName': !exists(json, 'facilityOrPersonName') ? undefined : json['facilityOrPersonName'],
        'dayCaregiver': !exists(json, 'dayCaregiver') ? undefined : json['dayCaregiver'],
        'whatReceiving': !exists(json, 'whatReceiving') ? undefined : json['whatReceiving'],
        'cariesRequiringTreatment': !exists(json, 'cariesRequiringTreatment') ? undefined : json['cariesRequiringTreatment'],
        'milkTeeth': !exists(json, 'milkTeeth') ? undefined : json['milkTeeth'],
        'permanentTeeth': !exists(json, 'permanentTeeth') ? undefined : json['permanentTeeth'],
        'total': !exists(json, 'total') ? undefined : json['total'],
        'dirtyTeeth': !exists(json, 'dirtyTeeth') ? undefined : json['dirtyTeeth'],
        'gingivaMucousMembrane': !exists(json, 'gingivaMucousMembrane') ? undefined : json['gingivaMucousMembrane'],
        'gingivaMucousMembraneNote': !exists(json, 'gingivaMucousMembraneNote') ? undefined : json['gingivaMucousMembraneNote'],
        'engagement': !exists(json, 'engagement') ? undefined : json['engagement'],
        'abnormalToothOralDisease': !exists(json, 'abnormalToothOralDisease') ? undefined : json['abnormalToothOralDisease'],
        'examinationDate': !exists(json, 'examinationDate') ? undefined : json['examinationDate'],
        'rightMolarsMaxillaA': !exists(json, 'rightMolarsMaxillaA') ? undefined : json['rightMolarsMaxillaA'],
        'rightMolarsMaxillaB': !exists(json, 'rightMolarsMaxillaB') ? undefined : json['rightMolarsMaxillaB'],
        'leftFrontToothMaxillaA': !exists(json, 'leftFrontToothMaxillaA') ? undefined : json['leftFrontToothMaxillaA'],
        'leftFrontToothMaxillaB': !exists(json, 'leftFrontToothMaxillaB') ? undefined : json['leftFrontToothMaxillaB'],
        'rightMolarsLowerJawA': !exists(json, 'rightMolarsLowerJawA') ? undefined : json['rightMolarsLowerJawA'],
        'rightMolarsLowerJawB': !exists(json, 'rightMolarsLowerJawB') ? undefined : json['rightMolarsLowerJawB'],
        'leftFrontToothLowerJawA': !exists(json, 'leftFrontToothLowerJawA') ? undefined : json['leftFrontToothLowerJawA'],
        'leftFrontToothLowerJawB': !exists(json, 'leftFrontToothLowerJawB') ? undefined : json['leftFrontToothLowerJawB'],
        'urineProtein': !exists(json, 'urineProtein') ? undefined : json['urineProtein'],
        'urineSugar': !exists(json, 'urineSugar') ? undefined : json['urineSugar'],
        'urineOccultBlood': !exists(json, 'urineOccultBlood') ? undefined : json['urineOccultBlood'],
        'whiteBloodCells': !exists(json, 'whiteBloodCells') ? undefined : json['whiteBloodCells'],
        'parentMessage': !exists(json, 'parentMessage') ? undefined : json['parentMessage'],
        'recordingDate': !exists(json, 'recordingDate') ? undefined : json['recordingDate'],
        'standOnOneLeg': !exists(json, 'standOnOneLeg') ? undefined : json['standOnOneLeg'],
        'writeByImitating': !exists(json, 'writeByImitating') ? undefined : json['writeByImitating'],
        'knowFrontBackLeftRight': !exists(json, 'knowFrontBackLeftRight') ? undefined : json['knowFrontBackLeftRight'],
        'writeNameInHiragana': !exists(json, 'writeNameInHiragana') ? undefined : json['writeNameInHiragana'],
        'putUpWithToysAndSweets': !exists(json, 'putUpWithToysAndSweets') ? undefined : json['putUpWithToysAndSweets'],
        'keepPromisesAndRules': !exists(json, 'keepPromisesAndRules') ? undefined : json['keepPromisesAndRules'],
        'firstMolarsGrow': !exists(json, 'firstMolarsGrow') ? undefined : json['firstMolarsGrow'],
        'brushYourTeeth': !exists(json, 'brushYourTeeth') ? undefined : json['brushYourTeeth'],
        'eatBreakfast': !exists(json, 'eatBreakfast') ? undefined : json['eatBreakfast'],
        'childRearing': !exists(json, 'childRearing') ? undefined : json['childRearing'],
        'raisingChildren': !exists(json, 'raisingChildren') ? undefined : json['raisingChildren'],
        'yourGrowth': !exists(json, 'yourGrowth') ? undefined : json['yourGrowth'],
        'photo3': !exists(json, 'photo3') ? undefined : json['photo3'],
    };
}

export function InfantSixYearOldToJSON(value?: InfantSixYearOld | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'userId': value.userId,
        'babyId': value.babyId,
        'ageId': value.ageId,
        'implementationDate': value.implementationDate,
        'year': value.year,
        'month': value.month,
        'photo1': value.photo1,
        'photo2': value.photo2,
        'bodyWeight': value.bodyWeight,
        'height': value.height,
        'kaupIndex': value.kaupIndex,
        'headCircumference': value.headCircumference,
        'nutritionalStatus': value.nutritionalStatus,
        'eyeAbnormalities': value.eyeAbnormalities,
        'eyeAbnormalitiesNote': value.eyeAbnormalitiesNote,
        'sightLeft': value.sightLeft,
        'sightRight': value.sightRight,
        'earAbnormalities': value.earAbnormalities,
        'earAbnormalitiesNote': value.earAbnormalitiesNote,
        'healthObservation': value.healthObservation,
        'remarks': value.remarks,
        'facilityOrPersonName': value.facilityOrPersonName,
        'dayCaregiver': value.dayCaregiver,
        'whatReceiving': value.whatReceiving,
        'cariesRequiringTreatment': value.cariesRequiringTreatment,
        'milkTeeth': value.milkTeeth,
        'permanentTeeth': value.permanentTeeth,
        'total': value.total,
        'dirtyTeeth': value.dirtyTeeth,
        'gingivaMucousMembrane': value.gingivaMucousMembrane,
        'gingivaMucousMembraneNote': value.gingivaMucousMembraneNote,
        'engagement': value.engagement,
        'abnormalToothOralDisease': value.abnormalToothOralDisease,
        'examinationDate': value.examinationDate,
        'rightMolarsMaxillaA': value.rightMolarsMaxillaA,
        'rightMolarsMaxillaB': value.rightMolarsMaxillaB,
        'leftFrontToothMaxillaA': value.leftFrontToothMaxillaA,
        'leftFrontToothMaxillaB': value.leftFrontToothMaxillaB,
        'rightMolarsLowerJawA': value.rightMolarsLowerJawA,
        'rightMolarsLowerJawB': value.rightMolarsLowerJawB,
        'leftFrontToothLowerJawA': value.leftFrontToothLowerJawA,
        'leftFrontToothLowerJawB': value.leftFrontToothLowerJawB,
        'urineProtein': value.urineProtein,
        'urineSugar': value.urineSugar,
        'urineOccultBlood': value.urineOccultBlood,
        'whiteBloodCells': value.whiteBloodCells,
        'parentMessage': value.parentMessage,
        'recordingDate': value.recordingDate,
        'standOnOneLeg': value.standOnOneLeg,
        'writeByImitating': value.writeByImitating,
        'knowFrontBackLeftRight': value.knowFrontBackLeftRight,
        'writeNameInHiragana': value.writeNameInHiragana,
        'putUpWithToysAndSweets': value.putUpWithToysAndSweets,
        'keepPromisesAndRules': value.keepPromisesAndRules,
        'firstMolarsGrow': value.firstMolarsGrow,
        'brushYourTeeth': value.brushYourTeeth,
        'eatBreakfast': value.eatBreakfast,
        'childRearing': value.childRearing,
        'raisingChildren': value.raisingChildren,
        'yourGrowth': value.yourGrowth,
        'photo3': value.photo3,
    };
}

