import React from "react";

interface ITextBlockCustomProps {
  textHeader?: string;
  classText?: string;
  headerColor?: string;
  textSize?: number;
  textContent?: string;
  contentColor?: string;
  isRequired?: boolean;
}

export const TextBlockCustom = (
  props: React.PropsWithChildren<ITextBlockCustomProps>
) => {
  return (
    <h6
      className={props.classText}
      style={{ color: props.headerColor, fontSize: props.textSize }}
    >
      <span>
        {props.textHeader}
        {props.isRequired && <span style={{ color: "red" }}> ※</span>}
      </span>

      {props.textContent && (
        <>
          <span style={{ color: props.contentColor }}>{props.textContent}</span>
        </>
      )}
    </h6>
  );
};
