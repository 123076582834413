/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InfantThreeToFourMonthOld } from './InfantThreeToFourMonthOld';
import {
    InfantThreeToFourMonthOldFromJSON,
    InfantThreeToFourMonthOldFromJSONTyped,
    InfantThreeToFourMonthOldToJSON,
} from './InfantThreeToFourMonthOld';
import type { PaginationInfo } from './PaginationInfo';
import {
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './PaginationInfo';

/**
 * 
 * @export
 * @interface InfantThreeToFourMonthOldPagination
 */
export interface InfantThreeToFourMonthOldPagination {
    /**
     * 
     * @type {Array<InfantThreeToFourMonthOld>}
     * @memberof InfantThreeToFourMonthOldPagination
     */
    data?: Array<InfantThreeToFourMonthOld>;
    /**
     * 
     * @type {PaginationInfo}
     * @memberof InfantThreeToFourMonthOldPagination
     */
    meta?: PaginationInfo;
}

/**
 * Check if a given object implements the InfantThreeToFourMonthOldPagination interface.
 */
export function instanceOfInfantThreeToFourMonthOldPagination(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InfantThreeToFourMonthOldPaginationFromJSON(json: any): InfantThreeToFourMonthOldPagination {
    return InfantThreeToFourMonthOldPaginationFromJSONTyped(json, false);
}

export function InfantThreeToFourMonthOldPaginationFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfantThreeToFourMonthOldPagination {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(InfantThreeToFourMonthOldFromJSON)),
        'meta': !exists(json, 'meta') ? undefined : PaginationInfoFromJSON(json['meta']),
    };
}

export function InfantThreeToFourMonthOldPaginationToJSON(value?: InfantThreeToFourMonthOldPagination | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(InfantThreeToFourMonthOldToJSON)),
        'meta': PaginationInfoToJSON(value.meta),
    };
}

