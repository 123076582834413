import React from "react";
import { bodyDataApi } from "@/api";
import { CardCustom, TextBlockCustom } from "@/components/display";
import { COLORS, noData } from "@/constants";
import { useQuery } from "react-query";
import { Grid } from "@mui/material";
import { toJapanDate } from "@/utils";

interface IMomBodyDataProps {
  userId?: number;
  fetusId?: number;
}

export const MomBodyData = (
  props: React.PropsWithChildren<IMomBodyDataProps>
) => {
  //--- Get body data
  const { data: motherBodyData } = useQuery({
    queryKey: "GET_BODY_DATA",
    queryFn: () => {
      return bodyDataApi.adminBodyDataGet({
        userId: props.userId,
        fetusId: props.fetusId,
        orderBy: "date",
        orderType: "desc",
      });
    },
  });

  return (
    <CardCustom classCard={"mt-4"}>
      <TextBlockCustom
        classText="mb-4 font-bold"
        textHeader="ママの体重の記録"
        headerColor={COLORS.primary}
        textSize={16}
      />

      <Grid container spacing={3}>
        {motherBodyData?.data?.length > 0 ? (
          (motherBodyData?.data || []).map((item, index) => (
            <Grid item xs={4} key={"motherBodyData_" + index}>
              <Grid container alignItems={"center"}>
                <Grid item xs={12}>
                  <TextBlockCustom
                    classText="font-medium mb-2"
                    textHeader={toJapanDate(item?.date)}
                  />
                  <TextBlockCustom
                    classText="font-medium mb-2"
                    textHeader={item?.weight?.toString() + "kg"}
                    headerColor={COLORS.approxGrey}
                  />
                </Grid>
              </Grid>
              <div className="divide-dot"></div>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <div className="text-center font-bold">{noData}</div>
          </Grid>
        )}
      </Grid>
    </CardCustom>
  );
};
