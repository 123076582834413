/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';
import type { GetMapLocationForBabyAllOf } from './GetMapLocationForBabyAllOf';
import {
    GetMapLocationForBabyAllOfFromJSON,
    GetMapLocationForBabyAllOfFromJSONTyped,
    GetMapLocationForBabyAllOfToJSON,
} from './GetMapLocationForBabyAllOf';

/**
 * 
 * @export
 * @interface GetMapLocationForBaby
 */
export interface GetMapLocationForBaby {
    /**
     * 
     * @type {number}
     * @memberof GetMapLocationForBaby
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof GetMapLocationForBaby
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMapLocationForBaby
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMapLocationForBaby
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMapLocationForBaby
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMapLocationForBaby
     */
    phoneNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof GetMapLocationForBaby
     */
    babyId?: number;
    /**
     * 
     * @type {number}
     * @memberof GetMapLocationForBaby
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof GetMapLocationForBaby
     */
    longitude?: number;
}

/**
 * Check if a given object implements the GetMapLocationForBaby interface.
 */
export function instanceOfGetMapLocationForBaby(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetMapLocationForBabyFromJSON(json: any): GetMapLocationForBaby {
    return GetMapLocationForBabyFromJSONTyped(json, false);
}

export function GetMapLocationForBabyFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMapLocationForBaby {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'babyId': !exists(json, 'babyId') ? undefined : json['babyId'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
    };
}

export function GetMapLocationForBabyToJSON(value?: GetMapLocationForBaby | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'name': value.name,
        'address': value.address,
        'phoneNumber': value.phoneNumber,
        'babyId': value.babyId,
        'latitude': value.latitude,
        'longitude': value.longitude,
    };
}

