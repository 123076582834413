import React from "react";
import {
  AvatarCustom,
  BloodSelectionType,
  TeethSelectCustom,
  TextBlockCustom,
} from "@/components/display";
import { Grid } from "@mui/material";
import { COLORS } from "@/constants";
import {
  Baby,
  InfantMedicalExam,
  InfantOneYearOldGingivaMucousMembraneEnum,
  infantOneYearOldsApi,
} from "@/api";
import { useQuery } from "react-query";
import { getNextYearFromDate, toJapanDate } from "@/utils";
import { translate } from "@/helpers";

interface IInfantOneYearOldDetailProps {
  infantMedicalExamItem?: InfantMedicalExam;
  baby?: Baby;
}

export const InfantOneYearOldDetail = (
  props: React.PropsWithChildren<IInfantOneYearOldDetailProps>
) => {
  const { infantMedicalExamItem, baby } = props;

  //--- Get medical consultant
  const { data: infantOneYearOld } = useQuery({
    queryKey: "GET_INFANT_ONE_YEAR_OLD_BY_ID",
    queryFn: () => {
      return infantOneYearOldsApi.adminInfantOneYearOldsIdGet({
        id: infantMedicalExamItem?.infantId,
        userId: infantMedicalExamItem?.userId,
      });
    },
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextBlockCustom
          classText="font-bold text-center mb-4"
          textHeader={`${translate("infantMedicalExam.text.age8")} ${translate(
            "infantMedicalExam.text.detail"
          )}`}
          textSize={18}
          headerColor={COLORS.primary}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.implementationDate"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantOneYearOld?.implementationDate
                  ? toJapanDate(infantOneYearOld?.implementationDate)
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.ageYear")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                (infantOneYearOld?.year
                  ? `${infantOneYearOld?.year}${translate("infoHealthMon.age")}`
                  : "") +
                (infantOneYearOld?.month
                  ? `${infantOneYearOld?.month}${translate("month.txt")}`
                  : "")
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.photographOfMedical"
              )}
              headerColor={COLORS.approxGrey}
            />

            <div className="flex items-center">
              {infantOneYearOld?.photo1 && (
                <div className="mr-2">
                  <AvatarCustom imgSrc={infantOneYearOld?.photo1} />
                </div>
              )}

              {infantOneYearOld?.photo2 && (
                <AvatarCustom imgSrc={infantOneYearOld?.photo2} />
              )}
            </div>
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.bodyWeight"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneYearOld?.bodyWeight
                  ? infantOneYearOld?.bodyWeight.toString() + "g"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.height")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneYearOld?.height
                  ? infantOneYearOld?.height.toString() + "cm"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.kaupIndex"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneYearOld?.kaupIndex
                  ? infantOneYearOld?.kaupIndex.toString()
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.chestCircumference"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneYearOld?.chestCircumference
                  ? infantOneYearOld?.chestCircumference.toString() + "cm"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.headCircumference"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneYearOld?.headCircumference
                  ? infantOneYearOld?.headCircumference.toString() + "cm"
                  : ""
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          {/* 栄養状態/健康・要観察/特記事項など */}
          <Grid item xs={12}>
            <TextBlockCustom
              classText="font-bold"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.nutritionalHealth"
              )}
              headerColor={COLORS.primary}
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.nutritionalStatus"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantOneYearOld?.nutritionalStatus
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantOneYearOld?.nutritionalStatus
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.breastMilk"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantOneYearOld?.breastMilk
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantOneYearOld?.breastMilk
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.mealDay")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantOneYearOld?.mealDay
                  ? infantOneYearOld?.mealDay.toString() +
                    translate("title.times")
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.snacks")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantOneYearOld?.snacks
                  ? infantOneYearOld?.snacks.toString() +
                    translate("title.times")
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.eyeAbnormalities"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantOneYearOld?.eyeAbnormalities
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantOneYearOld?.eyeAbnormalities
                    )
                  : ""
              }
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantOneYearOld?.eyeAbnormalitiesNote
                  ? infantOneYearOld?.eyeAbnormalitiesNote
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.healthObservation"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantOneYearOld?.healthObservation
                  ? infantOneYearOld?.healthObservation
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.remarks")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantOneYearOld?.remarks ? infantOneYearOld?.remarks : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.facilityOrPersonName"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantOneYearOld?.facilityOrPersonName
                  ? infantOneYearOld?.facilityOrPersonName
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.dayCaregiver"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium"
              textHeader={
                infantOneYearOld?.dayCaregiver
                  ? infantOneYearOld?.dayCaregiver
                  : ""
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          {/* 歯の状態 */}
          <Grid item xs={12}>
            <TextBlockCustom
              classText="font-bold"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.teethCondition"
              )}
              headerColor={COLORS.primary}
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.cariesRequiringTreatment"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneYearOld?.cariesRequiringTreatment
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantOneYearOld?.cariesRequiringTreatment
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.milkTeeth"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneYearOld?.milkTeeth
                  ? infantOneYearOld?.milkTeeth.toString() +
                    translate("title.book")
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.permanentTeeth"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneYearOld?.permanentTeeth
                  ? infantOneYearOld?.permanentTeeth.toString() +
                    translate("title.book")
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.total")}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneYearOld?.total
                  ? infantOneYearOld?.total.toString() + translate("title.book")
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.dirtyTeeth"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneYearOld?.dirtyTeeth
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantOneYearOld?.dirtyTeeth
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.gingivaMucousMembrane"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneYearOld?.gingivaMucousMembrane
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantOneYearOld?.gingivaMucousMembrane
                    )
                  : ""
              }
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneYearOld?.gingivaMucousMembraneNote &&
                infantOneYearOld?.gingivaMucousMembrane ===
                  InfantOneYearOldGingivaMucousMembraneEnum.CanBe
                  ? infantOneYearOld?.gingivaMucousMembraneNote
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.engagement"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneYearOld?.engagement
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantOneYearOld?.engagement
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.examinationDate"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneYearOld?.examinationDate
                  ? toJapanDate(infantOneYearOld?.examinationDate)
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.teethCondition"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.maxilla")}
              headerColor={COLORS.approxGrey}
            />

            <TeethSelectCustom
              fieldType={BloodSelectionType.MultipleFields}
              fieldDescriptions={["E", "D", "C", "B", "A"]}
              initialValues={
                !!infantOneYearOld?.rightMolarsMaxilla &&
                JSON.parse(infantOneYearOld.rightMolarsMaxilla)
              }
            />

            <TeethSelectCustom
              fieldType={BloodSelectionType.MultipleFields}
              fieldDescriptions={["A", "B", "C", "D", "E"]}
              initialValues={
                !!infantOneYearOld?.leftFrontToothMaxilla &&
                JSON.parse(infantOneYearOld?.leftFrontToothMaxilla)
              }
            />

            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.lowerJaw"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TeethSelectCustom
              fieldType={BloodSelectionType.MultipleFields}
              fieldDescriptions={["E", "D", "C", "B", "A"]}
              initialValues={
                !!infantOneYearOld?.rightMolarsLowerJaw &&
                JSON.parse(infantOneYearOld?.rightMolarsLowerJaw)
              }
            />

            <TeethSelectCustom
              fieldType={BloodSelectionType.MultipleFields}
              fieldDescriptions={["A", "B", "C", "D", "E"]}
              initialValues={
                !!infantOneYearOld?.leftFrontToothLowerJaw &&
                JSON.parse(infantOneYearOld?.leftFrontToothLowerJaw)
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          {/* 保護者の記録（1歳の頃）*/}
          <Grid item xs={12}>
            <TextBlockCustom
              classText="font-bold"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.parentRecordOneYear"
              )}
              headerColor={COLORS.primary}
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={
                getNextYearFromDate(baby?.birthday, 1) +
                translate("infantMedicalExamFormScreen.text.oneYearFromDate")
              }
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneYearOld?.parentMessage
                  ? infantOneYearOld?.parentMessage
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.recordingDate"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneYearOld?.recordingDate
                  ? toJapanDate(infantOneYearOld?.recordingDate)
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.workHard"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                (infantOneYearOld?.workHardMonth
                  ? `${infantOneYearOld?.workHardMonth}${translate(
                      "infoHealthMon.moon"
                    )}`
                  : "") +
                (infantOneYearOld?.workHardDay
                  ? `${infantOneYearOld?.workHardDay}${translate(
                      "title.everyday"
                    )}`
                  : "")
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.makeGestures"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneYearOld?.makeGestures
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantOneYearOld?.makeGestures
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.bodyMusic"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneYearOld?.bodyMusic
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantOneYearOld?.bodyMusic
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.simpleWords"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneYearOld?.simpleWords
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantOneYearOld?.simpleWords
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.pointToToy"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneYearOld?.pointToToy
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantOneYearOld?.pointToToy
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.happyToPlay"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneYearOld?.happyToPlay
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantOneYearOld?.happyToPlay
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.kindOfPlay"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneYearOld?.kindOfPlay ? infantOneYearOld?.kindOfPlay : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.rhythmOfEat"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneYearOld?.rhythmOfEat
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantOneYearOld?.rhythmOfEat
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.brushYourTeeth"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneYearOld?.brushYourTeeth
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantOneYearOld?.brushYourTeeth
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.childRearing"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneYearOld?.childRearing
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantOneYearOld?.childRearing
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.raisingChildren"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneYearOld?.raisingChildren
                  ? translate(
                      "infantMedicalExamFormScreen.checkbox." +
                        infantOneYearOld?.raisingChildren
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate(
                "infantMedicalExamFormScreen.text.yourGrowth"
              )}
              headerColor={COLORS.approxGrey}
            />

            <TextBlockCustom
              classText="font-medium mb-2"
              textHeader={
                infantOneYearOld?.yourGrowth ? infantOneYearOld?.yourGrowth : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <TextBlockCustom
              classText="mb-2"
              textHeader={translate("infantMedicalExamFormScreen.text.photo")}
              headerColor={COLORS.approxGrey}
            />

            {infantOneYearOld?.photo3 && (
              <AvatarCustom imgSrc={infantOneYearOld?.photo3} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
