import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { adminsApi } from "@/api";
import { noData, textRowsPerPage } from "@/constants";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  LinearProgress,
  Checkbox,
  Collapse,
} from "@mui/material";
import { IconButton } from "@mui/material";
import { useApiPagination } from "@/hooks";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { NotiCampaignUserFilter } from "./NotiCampaignUserFilter";
import moment from "moment";
import { calcAge, getMatureGenderText, getTextChildGender } from "@/utils";

const StyledNotiCampaignUserTable = styled.div`
  position: relative;
`;

export const deleteModalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: "36px",
};

function UserRow(props: { row: any; handleClick: any; selectedItem: any }) {
  const { row, handleClick, selectedItem } = props;

  //--- State
  const [open, setOpen] = useState(false);

  //--- Select row
  const isSelected = (name: string) => selectedItem?.indexOf(name) !== -1;
  const isItemSelected = isSelected(row.userId);
  const labelId = `enhanced-table-checkbox-${row.userId}`;

  return (
    <>
      <TableRow
        hover
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.userId}
        selected={isItemSelected}
      >
        <TableCell id={labelId}>{row.id}</TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <span className="whitespace-nowrap">{row.name}</span>
        </TableCell>
        <TableCell>{moment(row.birthday).format("DD/MM/yyyy")}</TableCell>
        <TableCell>
          <span className="whitespace-nowrap">
            {getMatureGenderText(row.gender)}
          </span>
        </TableCell>
        <TableCell>{row.email}</TableCell>
        <TableCell>{moment(row.createdAt).format("DD/MM/yyyy")}</TableCell>
        <TableCell>{row.address}</TableCell>
        <TableCell padding="checkbox" align="center">
          <Checkbox
            color="primary"
            checked={isItemSelected}
            inputProps={{
              "aria-labelledby": labelId,
            }}
            onClick={(event) => handleClick(event, row.userId)}
          />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div className="m-6">
              <h6 className="font-medium mb-4">子ども</h6>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <span className="color-primary">ニックネーム</span>
                    </TableCell>
                    <TableCell>
                      <span className="color-primary">子供の性別</span>
                    </TableCell>
                    <TableCell>
                      <span className="color-primary">子供の年齢</span>
                    </TableCell>
                    <TableCell>
                      <span className="color-primary">子供の状態</span>
                    </TableCell>
                    <TableCell>
                      <span className="color-primary">受診の記録の診断名</span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.babies?.length > 0 &&
                    row.babies.map((babyItem: any) => (
                      <TableRow key={"baby_" + babyItem.id}>
                        <TableCell>{babyItem.name}</TableCell>
                        <TableCell>
                          {getTextChildGender(babyItem.gender)}
                        </TableCell>
                        <TableCell>{calcAge(babyItem.birthday)}</TableCell>
                        <TableCell>子ども</TableCell>
                        <TableCell>{babyItem.illNameList.join(", ")}</TableCell>
                      </TableRow>
                    ))}

                  {row.fetuses?.length > 0 &&
                    row.fetuses.map((fetusItem: any) => (
                      <TableRow key={"fetus_" + fetusItem.id}>
                        <TableCell>{fetusItem.name}</TableCell>
                        <TableCell>
                          {getTextChildGender(fetusItem.gender)}
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell>妊娠中</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    ))}

                  {row.babies?.length === 0 && row.fetuses?.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={5}>
                        <div className="text-center font-bold">{noData}</div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

type INotiCampaignUserTableProps = {
  userListId: any;
  setUserListId: any;
};

export const NotiCampaignUserTable = (props: INotiCampaignUserTableProps) => {
  //--- State
  const [motherBirthdayStartDate, setMotherBirthdayStartDate] = useState("");
  const [motherBirthdayEndDate, setMotherBirthdayEndDate] = useState("");
  const [motherGender, setMotherGender] = useState("");
  const [motherEmail, setMotherEmail] = useState("");
  const [motherRegisterStartDate, setMotherRegisterStartDate] = useState("");
  const [motherRegisterEndDate, setMotherRegisterEndDate] = useState("");
  const [motherAddress, setMotherAddress] = useState("");
  const [babyGender, setBabyGender] = useState("");
  const [babyAgeStart, setBabyAgeStart] = useState("");
  const [babyAgeEnd, setBabyAgeEnd] = useState("");
  const [babyStatus, setBabyStatus] = useState("");
  const [diagnosisList, setDiagnosisList] = useState<any[]>([]);

  const [isSelectAllUser, setIsSelectAllUser] = useState<boolean>(false);

  //--- Select item
  const [selectedCheckbox, setSelectedCheckbox] = useState<readonly any[]>([]);

  const handleClickCheckbox = (
    event: React.MouseEvent<unknown>,
    name: string
  ) => {
    const selectedIndex = selectedCheckbox.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedCheckbox, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedCheckbox.slice(1));
    } else if (selectedIndex === selectedCheckbox.length - 1) {
      newSelected = newSelected.concat(selectedCheckbox.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedCheckbox.slice(0, selectedIndex),
        selectedCheckbox.slice(selectedIndex + 1)
      );
    }

    setSelectedCheckbox(newSelected);
    props.setUserListId(newSelected);
  };

  // console.log("selectedCheckbox: ", selectedCheckbox);

  //--- Get user list
  const {
    data: userList,
    isFetching,
    refetch: refetchUserList,
  } = useQuery({
    queryKey: "GET_DIAGNOSIS_LIST_DRUG",
    queryFn: () => {
      return adminsApi.adminFilterGet({
        page: apiPagi.page,
        itemsPerPage: apiPagi.itemsPerPage,
        orderBy: "id",
        orderType: "desc",
        motherBirthStart: motherBirthdayStartDate,
        motherBirthEnd: motherBirthdayEndDate,
        motherGender: motherGender === "ALL" ? "" : motherGender,
        email: motherEmail,
        createStart: motherRegisterStartDate,
        createEnd: motherRegisterEndDate,
        address: motherAddress,
        babyGender: babyGender === "ALL" ? "" : babyGender,
        babyAgeStart: babyAgeStart,
        babyAgeEnd: babyAgeEnd,
        babyStatus: babyStatus,
        diagnosis: diagnosisList?.map((item: any) => item.id)?.join(",") || "",
      });
    },
    onSuccess: (data) => {
      apiPagi.setTotalItems(data.meta.totalItems);
      handleCheckAllUser(data.data);
    },
    initialData: { data: [] },
  });

  const apiPagi = useApiPagination({
    onPageChange: () => refetchUserList(),
  });

  const handleFilterUser = () => {
    refetchUserList();
  };

  //--- Select all user
  const checkUnique = (value: any, index: any, self: string | any[]) => {
    return self.indexOf(value) === index;
  };

  const handleSelectAllUser = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newSelected: readonly any[] = [];

    if (event.target.checked) {
      setIsSelectAllUser(true);

      newSelected = newSelected
        .concat(
          selectedCheckbox,
          userList?.data.map((n) => n.userId)
        )
        .filter(checkUnique);

      setSelectedCheckbox(newSelected);
      props.setUserListId(newSelected);
      return;
    } else {
      setIsSelectAllUser(false);

      newSelected = selectedCheckbox?.filter(
        (e) => !userList?.data.map((n) => n.userId).includes(e)
      );

      setSelectedCheckbox(newSelected);
      props.setUserListId(newSelected);
    }
  };

  const handleCheckAllUser = (userData: any) => {
    let isExisted = userData?.some((user: any) =>
      selectedCheckbox.includes(user?.userId)
    );

    let result = userData?.filter((o1: any) =>
      selectedCheckbox.some((o2) => o1.userId === o2)
    );

    if (result?.length === apiPagi.itemsPerPage) {
      setIsSelectAllUser(isExisted);
    } else {
      setIsSelectAllUser(false);
    }
  };

  useEffect(() => {
    apiPagi.setPage(1);
    refetchUserList();

    if (props.userListId?.length > 0) {
      setSelectedCheckbox(props.userListId?.map((item: any) => Number(item)));
    } else {
      setSelectedCheckbox([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiPagi.setPage, refetchUserList]);

  return (
    <StyledNotiCampaignUserTable>
      <NotiCampaignUserFilter
        setMotherBirthdayStartDate={setMotherBirthdayStartDate}
        setMotherBirthdayEndDate={setMotherBirthdayEndDate}
        setMotherGender={setMotherGender}
        setMotherEmail={setMotherEmail}
        setMotherRegisterStartDate={setMotherRegisterStartDate}
        setMotherRegisterEndDate={setMotherRegisterEndDate}
        setMotherAddress={setMotherAddress}
        setBabyGender={setBabyGender}
        setBabyAgeStart={setBabyAgeStart}
        setBabyAgeEnd={setBabyAgeEnd}
        setBabyStatus={setBabyStatus}
        setDiagnosisList={setDiagnosisList}
        onClickSearch={handleFilterUser}
      />

      {isFetching && (
        <div className="absolute w-full">
          <LinearProgress />
        </div>
      )}

      <TableContainer component={Paper} className="mb-8">
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>
                <span className="color-primary">ID</span>
              </TableCell>
              <TableCell />
              <TableCell>
                <span className="color-primary">ユーザー</span>
              </TableCell>
              <TableCell>
                <span className="color-primary">生年月日</span>
              </TableCell>
              <TableCell>
                <span className="color-primary">性別</span>
              </TableCell>
              <TableCell>
                <span className="color-primary">メールアドレス</span>
              </TableCell>
              <TableCell>
                <span className="color-primary">会員登録日</span>
              </TableCell>
              <TableCell>
                <span className="color-primary">お住いの地域</span>
              </TableCell>
              <TableCell>
                <Checkbox
                  color="primary"
                  checked={isSelectAllUser}
                  onChange={handleSelectAllUser}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userList?.data?.length > 0 &&
              userList?.data?.map((item: any) => (
                <UserRow
                  key={item.id}
                  row={item}
                  handleClick={handleClickCheckbox}
                  selectedItem={selectedCheckbox}
                />
              ))}

            {userList?.data?.length === 0 && (
              <TableRow>
                <TableCell colSpan={9}>
                  <div className="text-center font-bold">{noData}</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        className="custom-pagination"
        component="div"
        count={apiPagi.totalItems}
        rowsPerPage={apiPagi.itemsPerPage}
        page={apiPagi.page - 1}
        labelRowsPerPage={textRowsPerPage}
        onPageChange={(e, page) => {
          apiPagi.setPage(page + 1);
        }}
        onRowsPerPageChange={(e) => {
          apiPagi.setItemsPerPage(+e.target.value);
        }}
        labelDisplayedRows={(p) => `${p.from}-${p.to} / ${p.count}件`}
      />
    </StyledNotiCampaignUserTable>
  );
};
