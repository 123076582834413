/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';
import type { ChildBirthAllOf } from './ChildBirthAllOf';
import {
    ChildBirthAllOfFromJSON,
    ChildBirthAllOfFromJSONTyped,
    ChildBirthAllOfToJSON,
} from './ChildBirthAllOf';

/**
 * 
 * @export
 * @interface ChildBirth
 */
export interface ChildBirth {
    /**
     * 
     * @type {number}
     * @memberof ChildBirth
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ChildBirth
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildBirth
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ChildBirth
     */
    userId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ChildBirth
     */
    fetusId?: number;
    /**
     * 
     * @type {number}
     * @memberof ChildBirth
     */
    babyId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ChildBirth
     */
    childId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ChildBirth
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildBirth
     */
    gender?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildBirth
     */
    birthday?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildBirth
     */
    session?: ChildBirthSessionEnum;
    /**
     * 
     * @type {number}
     * @memberof ChildBirth
     */
    hours?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ChildBirth
     */
    minutes?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ChildBirth
     */
    week?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ChildBirth
     */
    day?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ChildBirth
     */
    ageOfDad?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ChildBirth
     */
    ageOfMom?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ChildBirth
     */
    maternityHospital?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChildBirth
     */
    deliveryStatus?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChildBirth
     */
    deliveryProcess?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChildBirth
     */
    deliveryProcessDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChildBirth
     */
    bleeding?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChildBirth
     */
    bleedingDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChildBirth
     */
    bloodTransfusion?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChildBirth
     */
    bloodTransfusionDescription?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ChildBirth
     */
    weightChild?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ChildBirth
     */
    heightChild?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ChildBirth
     */
    bloodType?: ChildBirthBloodTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ChildBirth
     */
    bloodRh?: ChildBirthBloodRhEnum;
    /**
     * 
     * @type {string}
     * @memberof ChildBirth
     */
    asphyxia?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ChildBirth
     */
    chestCircumference?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ChildBirth
     */
    headCircumference?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ChildBirth
     */
    asphyxiaDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChildBirth
     */
    neonatalAsphyxia?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChildBirth
     */
    evidence?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChildBirth
     */
    doctorName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChildBirth
     */
    midwifeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChildBirth
     */
    otherPersonName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChildBirth
     */
    jaundice?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChildBirth
     */
    useOfIncubator?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ChildBirth
     */
    useOfIncubatorDescription?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ChildBirth
     */
    lactation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChildBirth
     */
    courseFromBirthToDischarge?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChildBirth
     */
    otherNoteworthyThings?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChildBirth
     */
    image?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChildBirth
     */
    other?: string | null;
}


/**
 * @export
 */
export const ChildBirthSessionEnum = {
    Day: 'DAY',
    Night: 'NIGHT'
} as const;
export type ChildBirthSessionEnum = typeof ChildBirthSessionEnum[keyof typeof ChildBirthSessionEnum];

/**
 * @export
 */
export const ChildBirthBloodTypeEnum = {
    A: 'A',
    B: 'B',
    Ab: 'AB',
    O: 'O',
    Unknown: 'UNKNOWN'
} as const;
export type ChildBirthBloodTypeEnum = typeof ChildBirthBloodTypeEnum[keyof typeof ChildBirthBloodTypeEnum];

/**
 * @export
 */
export const ChildBirthBloodRhEnum = {
    Plus: '+',
    Minus: '-',
    Unknown: 'UNKNOWN'
} as const;
export type ChildBirthBloodRhEnum = typeof ChildBirthBloodRhEnum[keyof typeof ChildBirthBloodRhEnum];


/**
 * Check if a given object implements the ChildBirth interface.
 */
export function instanceOfChildBirth(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChildBirthFromJSON(json: any): ChildBirth {
    return ChildBirthFromJSONTyped(json, false);
}

export function ChildBirthFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChildBirth {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'fetusId': !exists(json, 'fetusId') ? undefined : json['fetusId'],
        'babyId': !exists(json, 'babyId') ? undefined : json['babyId'],
        'childId': !exists(json, 'childId') ? undefined : json['childId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'birthday': !exists(json, 'birthday') ? undefined : json['birthday'],
        'session': !exists(json, 'session') ? undefined : json['session'],
        'hours': !exists(json, 'hours') ? undefined : json['hours'],
        'minutes': !exists(json, 'minutes') ? undefined : json['minutes'],
        'week': !exists(json, 'week') ? undefined : json['week'],
        'day': !exists(json, 'day') ? undefined : json['day'],
        'ageOfDad': !exists(json, 'ageOfDad') ? undefined : json['ageOfDad'],
        'ageOfMom': !exists(json, 'ageOfMom') ? undefined : json['ageOfMom'],
        'maternityHospital': !exists(json, 'maternityHospital') ? undefined : json['maternityHospital'],
        'deliveryStatus': !exists(json, 'deliveryStatus') ? undefined : json['deliveryStatus'],
        'deliveryProcess': !exists(json, 'deliveryProcess') ? undefined : json['deliveryProcess'],
        'deliveryProcessDescription': !exists(json, 'deliveryProcessDescription') ? undefined : json['deliveryProcessDescription'],
        'bleeding': !exists(json, 'bleeding') ? undefined : json['bleeding'],
        'bleedingDescription': !exists(json, 'bleedingDescription') ? undefined : json['bleedingDescription'],
        'bloodTransfusion': !exists(json, 'bloodTransfusion') ? undefined : json['bloodTransfusion'],
        'bloodTransfusionDescription': !exists(json, 'bloodTransfusionDescription') ? undefined : json['bloodTransfusionDescription'],
        'weightChild': !exists(json, 'weightChild') ? undefined : json['weightChild'],
        'heightChild': !exists(json, 'heightChild') ? undefined : json['heightChild'],
        'bloodType': !exists(json, 'bloodType') ? undefined : json['bloodType'],
        'bloodRh': !exists(json, 'bloodRh') ? undefined : json['bloodRh'],
        'asphyxia': !exists(json, 'asphyxia') ? undefined : json['asphyxia'],
        'chestCircumference': !exists(json, 'chestCircumference') ? undefined : json['chestCircumference'],
        'headCircumference': !exists(json, 'headCircumference') ? undefined : json['headCircumference'],
        'asphyxiaDescription': !exists(json, 'asphyxiaDescription') ? undefined : json['asphyxiaDescription'],
        'neonatalAsphyxia': !exists(json, 'neonatalAsphyxia') ? undefined : json['neonatalAsphyxia'],
        'evidence': !exists(json, 'evidence') ? undefined : json['evidence'],
        'doctorName': !exists(json, 'doctorName') ? undefined : json['doctorName'],
        'midwifeName': !exists(json, 'midwifeName') ? undefined : json['midwifeName'],
        'otherPersonName': !exists(json, 'otherPersonName') ? undefined : json['otherPersonName'],
        'jaundice': !exists(json, 'jaundice') ? undefined : json['jaundice'],
        'useOfIncubator': !exists(json, 'useOfIncubator') ? undefined : json['useOfIncubator'],
        'useOfIncubatorDescription': !exists(json, 'useOfIncubatorDescription') ? undefined : json['useOfIncubatorDescription'],
        'lactation': !exists(json, 'lactation') ? undefined : json['lactation'],
        'courseFromBirthToDischarge': !exists(json, 'courseFromBirthToDischarge') ? undefined : json['courseFromBirthToDischarge'],
        'otherNoteworthyThings': !exists(json, 'otherNoteworthyThings') ? undefined : json['otherNoteworthyThings'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'other': !exists(json, 'other') ? undefined : json['other'],
    };
}

export function ChildBirthToJSON(value?: ChildBirth | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'userId': value.userId,
        'fetusId': value.fetusId,
        'babyId': value.babyId,
        'childId': value.childId,
        'name': value.name,
        'gender': value.gender,
        'birthday': value.birthday,
        'session': value.session,
        'hours': value.hours,
        'minutes': value.minutes,
        'week': value.week,
        'day': value.day,
        'ageOfDad': value.ageOfDad,
        'ageOfMom': value.ageOfMom,
        'maternityHospital': value.maternityHospital,
        'deliveryStatus': value.deliveryStatus,
        'deliveryProcess': value.deliveryProcess,
        'deliveryProcessDescription': value.deliveryProcessDescription,
        'bleeding': value.bleeding,
        'bleedingDescription': value.bleedingDescription,
        'bloodTransfusion': value.bloodTransfusion,
        'bloodTransfusionDescription': value.bloodTransfusionDescription,
        'weightChild': value.weightChild,
        'heightChild': value.heightChild,
        'bloodType': value.bloodType,
        'bloodRh': value.bloodRh,
        'asphyxia': value.asphyxia,
        'chestCircumference': value.chestCircumference,
        'headCircumference': value.headCircumference,
        'asphyxiaDescription': value.asphyxiaDescription,
        'neonatalAsphyxia': value.neonatalAsphyxia,
        'evidence': value.evidence,
        'doctorName': value.doctorName,
        'midwifeName': value.midwifeName,
        'otherPersonName': value.otherPersonName,
        'jaundice': value.jaundice,
        'useOfIncubator': value.useOfIncubator,
        'useOfIncubatorDescription': value.useOfIncubatorDescription,
        'lactation': value.lactation,
        'courseFromBirthToDischarge': value.courseFromBirthToDischarge,
        'otherNoteworthyThings': value.otherNoteworthyThings,
        'image': value.image,
        'other': value.other,
    };
}

