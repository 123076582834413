/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    RecordSideEffect,
    RecordSideEffectFromJSON,
    RecordSideEffectToJSON,
    RecordSideEffectPagination,
    RecordSideEffectPaginationFromJSON,
    RecordSideEffectPaginationToJSON,
} from '../models';

export interface AdminRecordSideEffectsGetRequest {
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
    userId?: number;
}

export interface RecordSideEffectsGetRequest {
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface RecordSideEffectsIdDeleteRequest {
    id: number;
}

export interface RecordSideEffectsIdGetRequest {
    id: number;
}

export interface RecordSideEffectsIdPutRequest {
    id: number;
    recordSideEffect?: RecordSideEffect;
}

export interface RecordSideEffectsPostRequest {
    recordSideEffect?: RecordSideEffect;
}

export interface RecordSideEffectsShareAllGetRequest {
    sourceUserId: number;
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface RecordSideEffectsShareDetailGetRequest {
    sourceUserId: number;
    recordId: number;
}

/**
 * 
 */
export class RecordSideEffectsApi extends runtime.BaseAPI {

    /**
     * 
     */
    adminRecordSideEffectsGetRaw = async (requestParameters: AdminRecordSideEffectsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RecordSideEffectPagination>> => {
        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling adminRecordSideEffectsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/recordSideEffects`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecordSideEffectPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    adminRecordSideEffectsGet = async (requestParameters: AdminRecordSideEffectsGetRequest, initOverrides?: RequestInit): Promise<RecordSideEffectPagination> => {
        const response = await this.adminRecordSideEffectsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    recordSideEffectsGetRaw = async (requestParameters: RecordSideEffectsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RecordSideEffectPagination>> => {
        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling recordSideEffectsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/recordSideEffects`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecordSideEffectPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    recordSideEffectsGet = async (requestParameters: RecordSideEffectsGetRequest, initOverrides?: RequestInit): Promise<RecordSideEffectPagination> => {
        const response = await this.recordSideEffectsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    recordSideEffectsIdDeleteRaw = async (requestParameters: RecordSideEffectsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling recordSideEffectsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/recordSideEffects/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    recordSideEffectsIdDelete = async (requestParameters: RecordSideEffectsIdDeleteRequest, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.recordSideEffectsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    recordSideEffectsIdGetRaw = async (requestParameters: RecordSideEffectsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RecordSideEffect>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling recordSideEffectsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/recordSideEffects/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecordSideEffectFromJSON(jsonValue));
    }

    /**
     * 
     */
    recordSideEffectsIdGet = async (requestParameters: RecordSideEffectsIdGetRequest, initOverrides?: RequestInit): Promise<RecordSideEffect> => {
        const response = await this.recordSideEffectsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    recordSideEffectsIdPutRaw = async (requestParameters: RecordSideEffectsIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RecordSideEffect>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling recordSideEffectsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/recordSideEffects/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RecordSideEffectToJSON(requestParameters.recordSideEffect),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecordSideEffectFromJSON(jsonValue));
    }

    /**
     */
    recordSideEffectsIdPut = async (requestParameters: RecordSideEffectsIdPutRequest, initOverrides?: RequestInit): Promise<RecordSideEffect> => {
        const response = await this.recordSideEffectsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    recordSideEffectsPostRaw = async (requestParameters: RecordSideEffectsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RecordSideEffect>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/recordSideEffects`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RecordSideEffectToJSON(requestParameters.recordSideEffect),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecordSideEffectFromJSON(jsonValue));
    }

    /**
     */
    recordSideEffectsPost = async (requestParameters: RecordSideEffectsPostRequest = {}, initOverrides?: RequestInit): Promise<RecordSideEffect> => {
        const response = await this.recordSideEffectsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    recordSideEffectsShareAllGetRaw = async (requestParameters: RecordSideEffectsShareAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RecordSideEffectPagination>> => {
        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling recordSideEffectsShareAllGet.');
        }

        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling recordSideEffectsShareAllGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/recordSideEffects/share/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecordSideEffectPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    recordSideEffectsShareAllGet = async (requestParameters: RecordSideEffectsShareAllGetRequest, initOverrides?: RequestInit): Promise<RecordSideEffectPagination> => {
        const response = await this.recordSideEffectsShareAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    recordSideEffectsShareDetailGetRaw = async (requestParameters: RecordSideEffectsShareDetailGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RecordSideEffect>> => {
        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling recordSideEffectsShareDetailGet.');
        }

        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling recordSideEffectsShareDetailGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        if (requestParameters.recordId !== undefined) {
            queryParameters['recordId'] = requestParameters.recordId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/recordSideEffects/share/detail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecordSideEffectFromJSON(jsonValue));
    }

    /**
     * 
     */
    recordSideEffectsShareDetailGet = async (requestParameters: RecordSideEffectsShareDetailGetRequest, initOverrides?: RequestInit): Promise<RecordSideEffect> => {
        const response = await this.recordSideEffectsShareDetailGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
