import {
  TextField,
  Popover,
  Select,
  MenuItem,
  SelectChangeEvent,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import {
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import moment from "moment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { DateRange } from "react-date-range";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import * as dateRangeLocales from "react-date-range/dist/locale";
import { useState } from "react";
import { useQuery } from "react-query";
import { diagnosesApi } from "@/api";
import ClearIcon from "@mui/icons-material/Clear";
import { makeStyles } from "@mui/styles";

type INotiCampaignUserFilterProps = {
  setMotherBirthdayStartDate: any;
  setMotherBirthdayEndDate: any;
  setMotherGender: any;
  setMotherEmail: any;
  setMotherRegisterStartDate: any;
  setMotherRegisterEndDate: any;
  setMotherAddress: any;
  setBabyGender: any;
  setBabyAgeStart: any;
  setBabyAgeEnd: any;
  setBabyStatus: any;
  setDiagnosisList: any;
  onClickSearch: any;
};

const useStyles = makeStyles({
  autoCompletePaper: {
    boxShadow:
      "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgb(0, 0, 0, 0.12) 0px 3px 14px 2px",
  },
});

export const NotiCampaignUserFilter = (props: INotiCampaignUserFilterProps) => {
  const classes = useStyles();

  //--- Popover mother birthday
  const [anchorElMotherBirthday, setAnchorElMotherBirthday] =
    useState<HTMLButtonElement | null>(null);

  const handleClickMotherBirthday = (event: any) => {
    setAnchorElMotherBirthday(event.currentTarget);
  };

  const handleCloseMotherBirthday = () => {
    setAnchorElMotherBirthday(null);
  };

  const openMotherBirthday = Boolean(anchorElMotherBirthday);
  const idMotherBirthday = openMotherBirthday
    ? "mother-birthday-popover"
    : undefined;

  //--- Change mother birthday
  const [motherBirthdayRangeDate, setMotherBirthdayRangeDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [motherBirthdayStartDate, setMotherBirthdayStartDate] = useState("");
  const [motherBirthdayEndDate, setMotherBirthdayEndDate] = useState("");

  const handleChangeMotherBirthdayRangeDate = (item: any) => {
    let itemStartDate = item.startDate;
    let itemEndDate = item.endDate;

    setMotherBirthdayStartDate(moment(itemStartDate).format("DD/MM/YYYY"));
    setMotherBirthdayEndDate(moment(itemEndDate).format("DD/MM/YYYY"));

    props.setMotherBirthdayStartDate(
      moment(itemStartDate).format("YYYY-MM-DD")
    );
    props.setMotherBirthdayEndDate(moment(itemEndDate).format("YYYY-MM-DD"));

    setMotherBirthdayRangeDate([item]);
  };

  const handleCancelMotherBirthday = () => {
    setMotherBirthdayStartDate("");
    setMotherBirthdayEndDate("");

    props.setMotherBirthdayStartDate("");
    props.setMotherBirthdayEndDate("");

    setMotherBirthdayRangeDate([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);

    handleCloseMotherBirthday();
  };

  //--- Change mother gender
  const [motherGender, setMotherGender] = useState("");

  const handleChangeMotherGender = (event: SelectChangeEvent) => {
    setMotherGender(event.target.value as string);
    props.setMotherGender(event.target.value as string);
  };

  const handleClearMotherGender = () => {
    setMotherGender("");
    props.setMotherGender("");
  };

  //--- Search mother email
  const [motherEmail, setMotherEmail] = useState("");

  const handleSearchMotherEmail = (keyword: string) => {
    setMotherEmail(keyword);
    props.setMotherEmail(keyword);
  };

  const handleClearMotherEmail = () => {
    setMotherEmail("");
    props.setMotherEmail("");
  };

  //--- Popover mother register date
  const [anchorElMotherRegister, setAnchorElMotherRegister] =
    useState<HTMLButtonElement | null>(null);

  const handleClickMotherRegister = (event: any) => {
    setAnchorElMotherRegister(event.currentTarget);
  };

  const handleCloseMotherRegister = () => {
    setAnchorElMotherRegister(null);
  };

  const openMotherRegister = Boolean(anchorElMotherRegister);
  const idMotherRegister = openMotherRegister
    ? "mother-register-popover"
    : undefined;

  //--- Change mother register date
  const [motherRegisterRangeDate, setMotherRegisterRangeDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [motherRegisterStartDate, setMotherRegisterStartDate] = useState("");
  const [motherRegisterEndDate, setMotherRegisterEndDate] = useState("");

  const handleChangeMotherRegisterRangeDate = (item: any) => {
    let itemStartDate = item.startDate;
    let itemEndDate = item.endDate;

    setMotherRegisterStartDate(moment(itemStartDate).format("DD/MM/YYYY"));
    setMotherRegisterEndDate(moment(itemEndDate).format("DD/MM/YYYY"));

    props.setMotherRegisterStartDate(
      moment(itemStartDate).format("YYYY-MM-DD")
    );
    props.setMotherRegisterEndDate(moment(itemEndDate).format("YYYY-MM-DD"));

    setMotherRegisterRangeDate([item]);
  };

  const handleCancelMotherRegister = () => {
    setMotherRegisterStartDate("");
    setMotherRegisterEndDate("");

    props.setMotherRegisterStartDate("");
    props.setMotherRegisterEndDate("");

    setMotherRegisterRangeDate([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);

    handleCloseMotherRegister();
  };

  //--- Search mother address
  const [motherAddress, setMotherAddress] = useState("");

  const handleSearchMotherAddress = (keyword: string) => {
    setMotherAddress(keyword);
    props.setMotherAddress(keyword);
  };

  const handleClearMotherAddress = () => {
    setMotherAddress("");
    props.setMotherAddress("");
  };

  //--- Change baby gender
  const [babyGender, setBabyGender] = useState("");

  const handleChangeBabyGender = (event: SelectChangeEvent) => {
    setBabyGender(event.target.value as string);
    props.setBabyGender(event.target.value as string);
  };

  const handleClearBabyGender = () => {
    setBabyGender("");
    props.setBabyGender("");
  };

  //--- Change baby age
  const [babyAgeStart, setBabyAgeStart] = useState("");
  const [babyAgeEnd, setBabyAgeEnd] = useState("");

  const handleChangeBabyAgeStart = (event: SelectChangeEvent) => {
    setBabyAgeStart(event.target.value as string);
    props.setBabyAgeStart(event.target.value as string);
  };

  const handleClearBabyAgeStart = () => {
    setBabyAgeStart("");
    props.setBabyAgeStart("");
  };

  const handleChangeBabyAgeEnd = (event: SelectChangeEvent) => {
    setBabyAgeEnd(event.target.value as string);
    props.setBabyAgeEnd(event.target.value as string);
  };

  const handleClearBabyAgeEnd = () => {
    setBabyAgeEnd("");
    props.setBabyAgeEnd("");
  };

  //--- Change baby status
  const [babyStatus, setBabyStatus] = useState("");

  const handleChangeBabyStatus = (event: SelectChangeEvent) => {
    setBabyStatus(event.target.value as string);
    props.setBabyStatus(event.target.value as string);
  };

  const handleClearBabyStatus = () => {
    setBabyStatus("");
    props.setBabyStatus("");
  };

  //--- Get diagnosis list
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const [diagnosisValues, setDiagnosisValues] = useState<string[]>([]);

  const { data: diagnosisList } = useQuery({
    queryKey: "GET_DIAGNOSIS_LIST",
    queryFn: () => {
      return diagnosesApi.diagnosesGet({
        itemsPerPage: 1000,
        orderBy: "id",
        orderType: "desc",
      });
    },
  });

  const handleChangeDiagnosisList = (event, newValue: any) => {
    setDiagnosisValues(newValue);
    props.setDiagnosisList(newValue);
  };

  const handleClearDiagnosisList = () => {
    setDiagnosisValues([]);
    props.setDiagnosisList([]);
  };

  //--- Reset filter
  const handleResetFilter = () => {
    handleCancelMotherBirthday();
    handleClearMotherGender();
    handleClearMotherEmail();
    handleCancelMotherRegister();
    handleClearMotherAddress();
    handleClearBabyGender();
    handleClearBabyAgeStart();
    handleClearBabyAgeEnd();
    handleClearDiagnosisList();
  };

  return (
    <div className="mb-6 flex flex-col gap-4">
      <div className="grid grid-cols-3 gap-4">
        <div className="flex items-center">
          <label className="whitespace-nowrap">生年月日:</label>

          <div className="ml-4 w-full">
            <OutlinedInput
              className="bordered-input"
              placeholder=""
              size="small"
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="toggle mother birthday visibility">
                    {<CalendarMonthIcon />}
                  </IconButton>
                </InputAdornment>
              }
              value={
                motherBirthdayStartDate +
                (motherBirthdayEndDate !== ""
                  ? " - " + motherBirthdayEndDate
                  : "")
              }
              onClick={handleClickMotherBirthday}
            />

            <Popover
              id={idMotherBirthday}
              open={openMotherBirthday}
              anchorEl={anchorElMotherBirthday}
              onClose={handleCloseMotherBirthday}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              PaperProps={{
                sx: {
                  boxShadow:
                    "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgb(0, 0, 0, 0.12) 0px 3px 14px 2px",
                },
              }}
            >
              <DateRange
                id="motherBirthdayRangeDate"
                editableDateInputs={true}
                moveRangeOnFirstSelection={false}
                ranges={motherBirthdayRangeDate}
                rangeColors={["#EE8595"]}
                locale={dateRangeLocales.ja}
                onChange={(item: any) =>
                  handleChangeMotherBirthdayRangeDate(item.selection)
                }
              />

              <div className="flex justify-between px-4 pb-2">
                <Button
                  color="primary"
                  variant="outlined"
                  size="small"
                  onClick={handleCancelMotherBirthday}
                >
                  キャンセル
                </Button>

                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={handleCloseMotherBirthday}
                >
                  確認
                </Button>
              </div>
            </Popover>
          </div>
        </div>

        <div className="flex items-center">
          <label className="whitespace-nowrap">性別:</label>

          <div className="ml-4 w-full">
            <Select
              labelId="mother-gender-select-label"
              id="mother-gender-select"
              value={motherGender}
              onChange={handleChangeMotherGender}
              fullWidth
              className="bordered-input"
              size="small"
              endAdornment={
                <IconButton
                  size="small"
                  sx={{
                    visibility: motherGender ? "visible" : "hidden",
                    marginRight: "12px",

                    "& .MuiSvgIcon-root": {
                      height: 19,
                    },
                  }}
                  onClick={handleClearMotherGender}
                >
                  <ClearIcon />
                </IconButton>
              }
              MenuProps={{
                PaperProps: {
                  sx: {
                    boxShadow:
                      "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgb(0, 0, 0, 0.12) 0px 3px 14px 2px",
                  },
                },
              }}
            >
              <MenuItem value={"ALL"}>全て</MenuItem>
              <MenuItem value={"MALE"}>男性</MenuItem>
              <MenuItem value={"FEMALE"}>女性</MenuItem>
              <MenuItem value={"UNKNOWN"}>不明</MenuItem>
            </Select>
          </div>
        </div>

        <div className="flex items-center">
          <label className="whitespace-nowrap">メールアドレス:</label>

          <div className="ml-4 w-full">
            <OutlinedInput
              className="bordered-input"
              placeholder=""
              size="small"
              fullWidth
              value={motherEmail}
              onChange={(e) => handleSearchMotherEmail(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4">
        <div className="flex items-center">
          <label className="whitespace-nowrap">会員登録日:</label>

          <div className="ml-4 w-full">
            <OutlinedInput
              className="bordered-input"
              placeholder=""
              size="small"
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="toggle mother register visibility">
                    {<CalendarMonthIcon />}
                  </IconButton>
                </InputAdornment>
              }
              value={
                motherRegisterStartDate +
                (motherRegisterEndDate !== ""
                  ? " - " + motherRegisterEndDate
                  : "")
              }
              onClick={handleClickMotherRegister}
            />

            <Popover
              id={idMotherRegister}
              open={openMotherRegister}
              anchorEl={anchorElMotherRegister}
              onClose={handleCloseMotherRegister}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              PaperProps={{
                sx: {
                  boxShadow:
                    "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgb(0, 0, 0, 0.12) 0px 3px 14px 2px",
                },
              }}
            >
              <DateRange
                id="motherRegisterRangeDate"
                editableDateInputs={true}
                moveRangeOnFirstSelection={false}
                ranges={motherRegisterRangeDate}
                rangeColors={["#EE8595"]}
                locale={dateRangeLocales.ja}
                onChange={(item: any) =>
                  handleChangeMotherRegisterRangeDate(item.selection)
                }
              />

              <div className="flex justify-between px-4 pb-2">
                <Button
                  color="primary"
                  variant="outlined"
                  size="small"
                  onClick={handleCancelMotherRegister}
                >
                  キャンセル
                </Button>

                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  onClick={handleCloseMotherRegister}
                >
                  確認
                </Button>
              </div>
            </Popover>
          </div>
        </div>

        <div className="flex items-center">
          <label className="whitespace-nowrap">お住いの地域:</label>

          <div className="ml-4 w-full">
            <OutlinedInput
              className="bordered-input"
              placeholder=""
              size="small"
              fullWidth
              value={motherAddress}
              onChange={(e) => handleSearchMotherAddress(e.target.value)}
            />
          </div>
        </div>

        <div className="flex items-center">
          <label className="whitespace-nowrap">子供の性別:</label>

          <div className="ml-4 w-full">
            <Select
              labelId="baby-gender-select-label"
              id="baby-gender-select"
              value={babyGender}
              onChange={handleChangeBabyGender}
              fullWidth
              className="bordered-input"
              size="small"
              endAdornment={
                <IconButton
                  size="small"
                  sx={{
                    visibility: babyGender ? "visible" : "hidden",
                    marginRight: "12px",

                    "& .MuiSvgIcon-root": {
                      height: 19,
                    },
                  }}
                  onClick={handleClearBabyGender}
                >
                  <ClearIcon />
                </IconButton>
              }
              MenuProps={{
                PaperProps: {
                  sx: {
                    boxShadow:
                      "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgb(0, 0, 0, 0.12) 0px 3px 14px 2px",
                  },
                },
              }}
            >
              <MenuItem value={"ALL"}>全て</MenuItem>
              <MenuItem value={"MALE"}>男の子</MenuItem>
              <MenuItem value={"FEMALE"}>女の子</MenuItem>
              <MenuItem value={"UNKNOWN"}>不明</MenuItem>
            </Select>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4">
        <div className="flex items-center">
          <label className="whitespace-nowrap">子供の年齢:</label>

          <div className="ml-4 w-full">
            <Select
              labelId="baby-age-start-select-label"
              id="baby-age-start-select"
              value={babyAgeStart}
              onChange={handleChangeBabyAgeStart}
              fullWidth
              className="bordered-input"
              size="small"
              endAdornment={
                <IconButton
                  size="small"
                  sx={{
                    visibility: babyAgeStart ? "visible" : "hidden",
                    marginRight: "12px",

                    "& .MuiSvgIcon-root": {
                      height: 19,
                    },
                  }}
                  onClick={handleClearBabyAgeStart}
                >
                  <ClearIcon />
                </IconButton>
              }
              MenuProps={{
                PaperProps: {
                  sx: {
                    boxShadow:
                      "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgb(0, 0, 0, 0.12) 0px 3px 14px 2px",
                  },
                },
              }}
            >
              <MenuItem value={0}>0か月</MenuItem>
              <MenuItem value={3}>3か月</MenuItem>
              <MenuItem value={6}>6か月</MenuItem>
              <MenuItem value={12}>1歳</MenuItem>
              <MenuItem value={24}>2歳</MenuItem>
              <MenuItem value={36}>3歳</MenuItem>
              <MenuItem value={48}>4歳</MenuItem>
              <MenuItem value={60}>5歳</MenuItem>
              <MenuItem value={72}>6歳</MenuItem>
              <MenuItem value={84}>7歳</MenuItem>
              <MenuItem value={96}>8歳</MenuItem>
              <MenuItem value={108}>9歳</MenuItem>
              <MenuItem value={120}>10歳</MenuItem>
              <MenuItem value={132}>11歳</MenuItem>
              <MenuItem value={144}>12歳</MenuItem>
              <MenuItem value={156}>13歳</MenuItem>
              <MenuItem value={168}>14歳</MenuItem>
              <MenuItem value={180}>15歳</MenuItem>
              <MenuItem value={192}>16歳</MenuItem>
              <MenuItem value={204}>17歳</MenuItem>
              <MenuItem value={216}>18歳</MenuItem>
            </Select>
          </div>

          <span className="ml-4">~</span>

          <div className="ml-4 w-full">
            <Select
              labelId="baby-age-end-select-label"
              id="baby-age-end-select"
              value={babyAgeEnd}
              onChange={handleChangeBabyAgeEnd}
              fullWidth
              className="bordered-input"
              size="small"
              endAdornment={
                <IconButton
                  size="small"
                  sx={{
                    visibility: babyAgeEnd ? "visible" : "hidden",
                    marginRight: "12px",

                    "& .MuiSvgIcon-root": {
                      height: 19,
                    },
                  }}
                  onClick={handleClearBabyAgeEnd}
                >
                  <ClearIcon />
                </IconButton>
              }
              MenuProps={{
                PaperProps: {
                  sx: {
                    boxShadow:
                      "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgb(0, 0, 0, 0.12) 0px 3px 14px 2px",
                  },
                },
              }}
            >
              <MenuItem value={0}>0か月</MenuItem>
              <MenuItem value={3}>3か月</MenuItem>
              <MenuItem value={6}>6か月</MenuItem>
              <MenuItem value={12}>1歳</MenuItem>
              <MenuItem value={24}>2歳</MenuItem>
              <MenuItem value={36}>3歳</MenuItem>
              <MenuItem value={48}>4歳</MenuItem>
              <MenuItem value={60}>5歳</MenuItem>
              <MenuItem value={72}>6歳</MenuItem>
              <MenuItem value={84}>7歳</MenuItem>
              <MenuItem value={96}>8歳</MenuItem>
              <MenuItem value={108}>9歳</MenuItem>
              <MenuItem value={120}>10歳</MenuItem>
              <MenuItem value={132}>11歳</MenuItem>
              <MenuItem value={144}>12歳</MenuItem>
              <MenuItem value={156}>13歳</MenuItem>
              <MenuItem value={168}>14歳</MenuItem>
              <MenuItem value={180}>15歳</MenuItem>
              <MenuItem value={192}>16歳</MenuItem>
              <MenuItem value={204}>17歳</MenuItem>
              <MenuItem value={216}>18歳</MenuItem>
            </Select>
          </div>
        </div>

        <div className="flex items-center">
          <label className="whitespace-nowrap">子供の状態:</label>

          <div className="ml-4 w-full">
            <Select
              labelId="baby-status-select-label"
              id="baby-status-select"
              value={babyStatus}
              onChange={handleChangeBabyStatus}
              fullWidth
              className="bordered-input"
              size="small"
              endAdornment={
                <IconButton
                  size="small"
                  sx={{
                    visibility: babyStatus ? "visible" : "hidden",
                    marginRight: "12px",

                    "& .MuiSvgIcon-root": {
                      height: 19,
                    },
                  }}
                  onClick={handleClearBabyStatus}
                >
                  <ClearIcon />
                </IconButton>
              }
              MenuProps={{
                PaperProps: {
                  sx: {
                    boxShadow:
                      "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgb(0, 0, 0, 0.12) 0px 3px 14px 2px",
                  },
                },
              }}
            >
              <MenuItem value={"1"}>妊娠中</MenuItem>
              <MenuItem value={"2"}>こども</MenuItem>
              <MenuItem value={"3"}>全て</MenuItem>
            </Select>
          </div>
        </div>

        {diagnosisList?.data?.length > 0 && (
          <div className="flex items-center">
            <label className="whitespace-nowrap">受診の記録の診断名:</label>

            <Autocomplete
              multiple
              fullWidth
              id="diagnosis-autocomplete"
              disableCloseOnSelect
              filterSelectedOptions
              className="bordered-input rounded ml-4"
              options={diagnosisList?.data}
              getOptionLabel={(option: any) => option.diagnosisName}
              value={diagnosisValues}
              onChange={handleChangeDiagnosisList}
              classes={{ paper: classes.autoCompletePaper }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.diagnosisName}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="bordered-input"
                  size="small"
                />
              )}
            />
          </div>
        )}
      </div>

      <div className="flex justify-center mt-3 border-b pb-4">
        <Button
          type="submit"
          color="primary"
          variant="outlined"
          className="w-[268px] !mr-4"
          onClick={() => handleResetFilter()}
        >
          リセット
        </Button>

        <Button
          type="submit"
          color="primary"
          variant="contained"
          className="w-[268px]"
          onClick={props.onClickSearch}
        >
          検索
        </Button>
      </div>
    </div>
  );
};
