/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    VaccinatedRecord,
    VaccinatedRecordFromJSON,
    VaccinatedRecordToJSON,
    VaccinatedRecordPagination,
    VaccinatedRecordPaginationFromJSON,
    VaccinatedRecordPaginationToJSON,
} from '../models';

export interface AdminVaccinatedRecordsGetRequest {
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface VaccinatedRecordsGetRequest {
    babyId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
    vaccineId?: number;
}

export interface VaccinatedRecordsIdDeleteRequest {
    id: number;
}

export interface VaccinatedRecordsIdGetRequest {
    id: number;
}

export interface VaccinatedRecordsIdPutRequest {
    id: number;
    vaccinatedRecord?: VaccinatedRecord;
}

export interface VaccinatedRecordsPostRequest {
    vaccinatedRecord?: VaccinatedRecord;
}

export interface VaccinatedRecordsShareAllGetRequest {
    babyId: number;
    sourceUserId: number;
    page?: number;
    itemsPerPage?: number;
    orderType?: string;
    orderBy?: string;
}

export interface VaccinatedRecordsWithoutSchedulePostRequest {
    vaccinatedRecord?: VaccinatedRecord;
}

/**
 * 
 */
export class VaccinatedRecordsApi extends runtime.BaseAPI {

    /**
     * 
     */
    adminVaccinatedRecordsGetRaw = async (requestParameters: AdminVaccinatedRecordsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VaccinatedRecordPagination>> => {
        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling adminVaccinatedRecordsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admin/vaccinatedRecords`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VaccinatedRecordPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    adminVaccinatedRecordsGet = async (requestParameters: AdminVaccinatedRecordsGetRequest, initOverrides?: RequestInit): Promise<VaccinatedRecordPagination> => {
        const response = await this.adminVaccinatedRecordsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    vaccinatedRecordsGetRaw = async (requestParameters: VaccinatedRecordsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VaccinatedRecordPagination>> => {
        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling vaccinatedRecordsGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        if (requestParameters.vaccineId !== undefined) {
            queryParameters['vaccineId'] = requestParameters.vaccineId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/vaccinatedRecords`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VaccinatedRecordPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    vaccinatedRecordsGet = async (requestParameters: VaccinatedRecordsGetRequest, initOverrides?: RequestInit): Promise<VaccinatedRecordPagination> => {
        const response = await this.vaccinatedRecordsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    vaccinatedRecordsIdDeleteRaw = async (requestParameters: VaccinatedRecordsIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling vaccinatedRecordsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/vaccinatedRecords/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    vaccinatedRecordsIdDelete = async (requestParameters: VaccinatedRecordsIdDeleteRequest, initOverrides?: RequestInit): Promise<any> => {
        const response = await this.vaccinatedRecordsIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    vaccinatedRecordsIdGetRaw = async (requestParameters: VaccinatedRecordsIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VaccinatedRecord>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling vaccinatedRecordsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/vaccinatedRecords/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VaccinatedRecordFromJSON(jsonValue));
    }

    /**
     * 
     */
    vaccinatedRecordsIdGet = async (requestParameters: VaccinatedRecordsIdGetRequest, initOverrides?: RequestInit): Promise<VaccinatedRecord> => {
        const response = await this.vaccinatedRecordsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    vaccinatedRecordsIdPutRaw = async (requestParameters: VaccinatedRecordsIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VaccinatedRecord>> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling vaccinatedRecordsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/vaccinatedRecords/:id`.replace(`${":id"}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: VaccinatedRecordToJSON(requestParameters.vaccinatedRecord),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VaccinatedRecordFromJSON(jsonValue));
    }

    /**
     */
    vaccinatedRecordsIdPut = async (requestParameters: VaccinatedRecordsIdPutRequest, initOverrides?: RequestInit): Promise<VaccinatedRecord> => {
        const response = await this.vaccinatedRecordsIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    vaccinatedRecordsPostRaw = async (requestParameters: VaccinatedRecordsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VaccinatedRecord>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/vaccinatedRecords`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VaccinatedRecordToJSON(requestParameters.vaccinatedRecord),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VaccinatedRecordFromJSON(jsonValue));
    }

    /**
     */
    vaccinatedRecordsPost = async (requestParameters: VaccinatedRecordsPostRequest = {}, initOverrides?: RequestInit): Promise<VaccinatedRecord> => {
        const response = await this.vaccinatedRecordsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    vaccinatedRecordsShareAllGetRaw = async (requestParameters: VaccinatedRecordsShareAllGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VaccinatedRecordPagination>> => {
        if (requestParameters.babyId === null || requestParameters.babyId === undefined) {
            throw new runtime.RequiredError('babyId','Required parameter requestParameters.babyId was null or undefined when calling vaccinatedRecordsShareAllGet.');
        }

        if (requestParameters.sourceUserId === null || requestParameters.sourceUserId === undefined) {
            throw new runtime.RequiredError('sourceUserId','Required parameter requestParameters.sourceUserId was null or undefined when calling vaccinatedRecordsShareAllGet.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.itemsPerPage !== undefined) {
            queryParameters['itemsPerPage'] = requestParameters.itemsPerPage;
        }

        if (requestParameters.orderType !== undefined) {
            queryParameters['orderType'] = requestParameters.orderType;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.babyId !== undefined) {
            queryParameters['babyId'] = requestParameters.babyId;
        }

        if (requestParameters.sourceUserId !== undefined) {
            queryParameters['sourceUserId'] = requestParameters.sourceUserId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/vaccinatedRecords/share/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VaccinatedRecordPaginationFromJSON(jsonValue));
    }

    /**
     * 
     */
    vaccinatedRecordsShareAllGet = async (requestParameters: VaccinatedRecordsShareAllGetRequest, initOverrides?: RequestInit): Promise<VaccinatedRecordPagination> => {
        const response = await this.vaccinatedRecordsShareAllGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    vaccinatedRecordsWithoutSchedulePostRaw = async (requestParameters: VaccinatedRecordsWithoutSchedulePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VaccinatedRecord>> => {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/vaccinatedRecordsWithoutSchedule`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VaccinatedRecordToJSON(requestParameters.vaccinatedRecord),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VaccinatedRecordFromJSON(jsonValue));
    }

    /**
     */
    vaccinatedRecordsWithoutSchedulePost = async (requestParameters: VaccinatedRecordsWithoutSchedulePostRequest = {}, initOverrides?: RequestInit): Promise<VaccinatedRecord> => {
        const response = await this.vaccinatedRecordsWithoutSchedulePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
