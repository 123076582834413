/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Mother } from './Mother';
import {
    MotherFromJSON,
    MotherFromJSONTyped,
    MotherToJSON,
} from './Mother';
import type { PaginationInfo } from './PaginationInfo';
import {
    PaginationInfoFromJSON,
    PaginationInfoFromJSONTyped,
    PaginationInfoToJSON,
} from './PaginationInfo';

/**
 * 
 * @export
 * @interface MotherPagination
 */
export interface MotherPagination {
    /**
     * 
     * @type {Array<Mother>}
     * @memberof MotherPagination
     */
    data?: Array<Mother>;
    /**
     * 
     * @type {PaginationInfo}
     * @memberof MotherPagination
     */
    meta?: PaginationInfo;
}

/**
 * Check if a given object implements the MotherPagination interface.
 */
export function instanceOfMotherPagination(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MotherPaginationFromJSON(json: any): MotherPagination {
    return MotherPaginationFromJSONTyped(json, false);
}

export function MotherPaginationFromJSONTyped(json: any, ignoreDiscriminator: boolean): MotherPagination {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(MotherFromJSON)),
        'meta': !exists(json, 'meta') ? undefined : PaginationInfoFromJSON(json['meta']),
    };
}

export function MotherPaginationToJSON(value?: MotherPagination | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(MotherToJSON)),
        'meta': PaginationInfoToJSON(value.meta),
    };
}

