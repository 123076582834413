import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { COLORS } from "@/constants";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: COLORS.primary,
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface IBootstrapDialogCustomProps {
  dialogTitle?: string;
  openDialog?: boolean;
  onCloseDialog?: () => void;
}

export const BootstrapDialogCustom = (
  props: React.PropsWithChildren<IBootstrapDialogCustomProps>
) => {
  return (
    <>
      <BootstrapDialog
        onClose={props.onCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={props.openDialog}
        TransitionComponent={Transition}
        keepMounted
        maxWidth={"sm"}
        fullWidth
      >
        {props.dialogTitle && (
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={props.onCloseDialog}
          >
            {props.dialogTitle}
          </BootstrapDialogTitle>
        )}

        <DialogContent>{props.children}</DialogContent>
      </BootstrapDialog>
    </>
  );
};
