import { Route } from "react-router-dom";
import { DRUG_PATH } from "@/constants/paths";
import { DRUG_LIST_PATH } from "./drug-list/DrugListPage.shared";
import { DrugListPage } from "./drug-list";

export const renderDrugRoutes = () => (
  <Route path={DRUG_PATH}>
    <Route path={DRUG_LIST_PATH} element={<DrugListPage />} />
  </Route>
);
