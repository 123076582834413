import { Fetus } from "@/api";
import { CardCustom, TextBlockCustom } from "@/components/display";
import { COLORS } from "@/constants";
import { renderBMI, toJapanDate } from "@/utils";
import { getTextChildGender } from "@/utils";
import { Avatar, Grid } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";

interface IPregnancyInfoProps {
  fetusItem?: Fetus;
}

export const PregnancyInfo = (
  props: React.PropsWithChildren<IPregnancyInfoProps>
) => {
  //--- State
  const [fetusInfo, setFetusInfo] = useState<Fetus>();

  useEffect(() => {
    setFetusInfo(props.fetusItem);
  }, [props.fetusItem]);

  return (
    <CardCustom>
      <Grid container alignItems={"center"}>
        <Grid item xs={2}>
          <Avatar
            src={fetusInfo?.image ? fetusInfo?.image : ""}
            sx={{ width: 100, height: 100 }}
          />
        </Grid>

        <Grid item xs={10}>
          <Grid
            container
            spacing={2}
            alignItems={"center"}
            className="divide-x"
          >
            <Grid item xs={4}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="名前（ニックネーム): "
                textContent={fetusInfo?.name}
                contentColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                classText="mb-2"
                textHeader="出産予定日: "
                textContent={
                  fetusInfo?.expectedBirthday
                    ? toJapanDate(fetusInfo?.expectedBirthday, {
                        showEraName: true,
                      })
                    : ""
                }
                contentColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                textHeader="性別: "
                textContent={
                  fetusInfo?.gender ? getTextChildGender(fetusInfo?.gender) : ""
                }
                contentColor={COLORS.approxGrey}
              />
            </Grid>
            <Grid item xs>
              <TextBlockCustom
                textHeader="ママの体重: "
                textContent={
                  fetusInfo?.prePregnancyMonWeight
                    ? fetusInfo?.prePregnancyMonWeight + "kg"
                    : ""
                }
                contentColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                textHeader="ママの身長: "
                textContent={
                  fetusInfo?.prePregnancyMonHeight
                    ? fetusInfo?.prePregnancyMonHeight + "cm"
                    : ""
                }
                contentColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                textHeader="ママのBMI: "
                textContent={
                  fetusInfo?.prePregnancyMonWeight &&
                  fetusInfo?.prePregnancyMonHeight
                    ? renderBMI(
                        fetusInfo?.prePregnancyMonWeight,
                        fetusInfo?.prePregnancyMonHeight
                      )
                    : ""
                }
                contentColor={COLORS.approxGrey}
              />
            </Grid>
            <Grid item xs>
              <TextBlockCustom
                textHeader="ママの生年月日: "
                textContent={
                  fetusInfo?.monBirthday
                    ? moment(fetusInfo?.monBirthday).format("yyyy/MM/DD")
                    : ""
                }
                contentColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                textHeader="ママの職業: "
                textContent={fetusInfo?.monProfession}
                contentColor={COLORS.approxGrey}
              />
            </Grid>
            <Grid item xs>
              <TextBlockCustom
                textHeader="パパの生年月日: "
                textContent={
                  fetusInfo?.dadBirthday
                    ? moment(fetusInfo?.dadBirthday).format("yyyy/MM/DD")
                    : ""
                }
                contentColor={COLORS.approxGrey}
              />
              <TextBlockCustom
                textHeader="パパの職業: "
                textContent={fetusInfo?.dadProfession}
                contentColor={COLORS.approxGrey}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CardCustom>
  );
};
