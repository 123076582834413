/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';
import type { MedicalConsultantAllOf } from './MedicalConsultantAllOf';
import {
    MedicalConsultantAllOfFromJSON,
    MedicalConsultantAllOfFromJSONTyped,
    MedicalConsultantAllOfToJSON,
} from './MedicalConsultantAllOf';

/**
 * 
 * @export
 * @interface MedicalConsultant
 */
export interface MedicalConsultant {
    /**
     * 
     * @type {number}
     * @memberof MedicalConsultant
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MedicalConsultant
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalConsultant
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof MedicalConsultant
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof MedicalConsultant
     */
    babyId?: number;
    /**
     * 
     * @type {string}
     * @memberof MedicalConsultant
     */
    consultationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof MedicalConsultant
     */
    medicalInstitutionName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedicalConsultant
     */
    doctorName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedicalConsultant
     */
    symptoms?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedicalConsultant
     */
    diagnosisList?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedicalConsultant
     */
    treatmentContent?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedicalConsultant
     */
    familyPharmacyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedicalConsultant
     */
    sideEffectsAndSpecialNotes?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MedicalConsultant
     */
    medicalPhoto?: string | null;
}

/**
 * Check if a given object implements the MedicalConsultant interface.
 */
export function instanceOfMedicalConsultant(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MedicalConsultantFromJSON(json: any): MedicalConsultant {
    return MedicalConsultantFromJSONTyped(json, false);
}

export function MedicalConsultantFromJSONTyped(json: any, ignoreDiscriminator: boolean): MedicalConsultant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'babyId': !exists(json, 'babyId') ? undefined : json['babyId'],
        'consultationDate': !exists(json, 'consultationDate') ? undefined : json['consultationDate'],
        'medicalInstitutionName': !exists(json, 'medicalInstitutionName') ? undefined : json['medicalInstitutionName'],
        'doctorName': !exists(json, 'doctorName') ? undefined : json['doctorName'],
        'symptoms': !exists(json, 'symptoms') ? undefined : json['symptoms'],
        'diagnosisList': !exists(json, 'diagnosisList') ? undefined : json['diagnosisList'],
        'treatmentContent': !exists(json, 'treatmentContent') ? undefined : json['treatmentContent'],
        'familyPharmacyName': !exists(json, 'familyPharmacyName') ? undefined : json['familyPharmacyName'],
        'sideEffectsAndSpecialNotes': !exists(json, 'sideEffectsAndSpecialNotes') ? undefined : json['sideEffectsAndSpecialNotes'],
        'medicalPhoto': !exists(json, 'medicalPhoto') ? undefined : json['medicalPhoto'],
    };
}

export function MedicalConsultantToJSON(value?: MedicalConsultant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'userId': value.userId,
        'babyId': value.babyId,
        'consultationDate': value.consultationDate,
        'medicalInstitutionName': value.medicalInstitutionName,
        'doctorName': value.doctorName,
        'symptoms': value.symptoms,
        'diagnosisList': value.diagnosisList,
        'treatmentContent': value.treatmentContent,
        'familyPharmacyName': value.familyPharmacyName,
        'sideEffectsAndSpecialNotes': value.sideEffectsAndSpecialNotes,
        'medicalPhoto': value.medicalPhoto,
    };
}

