import { fileApi } from "@/api";
import { env } from "@/constants";
import axios from "axios";
import * as s3Key from "s3-key";

const APIs = {
  S3_PUT_PRESIGN: `${env.API_URL}/admin/file/s3`,
};

/**
 * This promise may never be resolved/rejected. for ex. if user cancels the selection
 */
export const selectFile: (attributes?: {
  [key: string]: string | any;
}) => Promise<File> = (attributes = {}) =>
  new Promise((resolve) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/png, image/jpeg");
    input.setAttribute("style", "display: none");
    for (const [key, val] of Object.entries(attributes)) {
      input.setAttribute(key, val);
    }
    input.onchange = () => {
      input.files && resolve(input.files[0]);
      input.remove();
    };
    document.body.appendChild(input);
    input.click();
  });

export const keyToAWSS3Url = (key: string) => {
  return `https://s3-${env.PUBLIC_AWS_REGION}.amazonaws.com/\
${env.PUBLIC_AWS_PUBLIC_BUCKET}\
/${key}`;
};

export const uploadFile = async (file: File) => {
  try {
    const now = new Date();
    const key = s3Key.encodeS3Key(
      `${now.getFullYear()}/${now.getMonth()}/${now.getDate()}/${Date.now()}_${
        file.name
      }`
    );
    const response = await fileApi.postPresignS3Url({
      key: s3Key.decodeS3Key(key),
    });
    const { preSignedUrl } = await response.value();
    await axios.put(preSignedUrl, file, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return keyToAWSS3Url(key);
  } catch (e) {
    throw e;
  }
};
