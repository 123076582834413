/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';
import type { InfantInspectionRecordAllOf } from './InfantInspectionRecordAllOf';
import {
    InfantInspectionRecordAllOfFromJSON,
    InfantInspectionRecordAllOfFromJSONTyped,
    InfantInspectionRecordAllOfToJSON,
} from './InfantInspectionRecordAllOf';

/**
 * 
 * @export
 * @interface InfantInspectionRecord
 */
export interface InfantInspectionRecord {
    /**
     * 
     * @type {number}
     * @memberof InfantInspectionRecord
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InfantInspectionRecord
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof InfantInspectionRecord
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof InfantInspectionRecord
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof InfantInspectionRecord
     */
    babyId?: number;
    /**
     * 
     * @type {number}
     * @memberof InfantInspectionRecord
     */
    ageId?: number;
    /**
     * 
     * @type {string}
     * @memberof InfantInspectionRecord
     */
    inspectionDate1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantInspectionRecord
     */
    remarks1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantInspectionRecord
     */
    inspectionDate2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfantInspectionRecord
     */
    remarks2?: string | null;
}

/**
 * Check if a given object implements the InfantInspectionRecord interface.
 */
export function instanceOfInfantInspectionRecord(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InfantInspectionRecordFromJSON(json: any): InfantInspectionRecord {
    return InfantInspectionRecordFromJSONTyped(json, false);
}

export function InfantInspectionRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfantInspectionRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'babyId': !exists(json, 'babyId') ? undefined : json['babyId'],
        'ageId': !exists(json, 'ageId') ? undefined : json['ageId'],
        'inspectionDate1': !exists(json, 'inspectionDate1') ? undefined : json['inspectionDate1'],
        'remarks1': !exists(json, 'remarks1') ? undefined : json['remarks1'],
        'inspectionDate2': !exists(json, 'inspectionDate2') ? undefined : json['inspectionDate2'],
        'remarks2': !exists(json, 'remarks2') ? undefined : json['remarks2'],
    };
}

export function InfantInspectionRecordToJSON(value?: InfantInspectionRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'userId': value.userId,
        'babyId': value.babyId,
        'ageId': value.ageId,
        'inspectionDate1': value.inspectionDate1,
        'remarks1': value.remarks1,
        'inspectionDate2': value.inspectionDate2,
        'remarks2': value.remarks2,
    };
}

