import React from "react";
import styled from "styled-components";

import { LoginForm } from "@/form/auth/login";
import { useNavigate } from "react-router-dom";
import { getUserListUrl } from "@/pages/user/user-list/UserListPage.shared";
import { useRecoilState } from "recoil";
import { currentUserState } from "@/constants";
import { AdminLoginResponse } from "@/api";
import { saveTokenToCookies, saveTokenToSession } from "@/utils";

const StyledLoginPage = styled.div`
  position: relative;
  height: 100vh;
  .page-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    width: 866px;
    height: 570px;
  }
  .card {
    display: flex;
    justify-content: center;
    padding: 36px 48px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(77, 70, 70, 0.2);
  }
`;

interface ILoginPageProps { }

export const LoginPage = (props: React.PropsWithChildren<ILoginPageProps>) => {
  const navigate = useNavigate();
  const [, setCurrentUser] = useRecoilState(currentUserState);

  return (
    <StyledLoginPage>
      <div className="page-content">
        <div className="card">
          <LoginForm
            onSuccess={(result: AdminLoginResponse, saveToken: boolean) => {
              if (saveToken) {
                saveTokenToCookies(result.accessToken);
              } else {
                saveTokenToSession(result.accessToken);
              }

              setCurrentUser(result.admin);
              navigate(getUserListUrl(), { replace: true });
            }}
          />
        </div>
      </div>
    </StyledLoginPage>
  );
};
