import React, { useEffect, useState } from "react";
import {
  ButtonCustom,
  CardCustom,
  TextBlockCustom,
} from "@/components/display";
import { COLORS, noData } from "@/constants";
import { Grid } from "@mui/material";
import { medicalRecordsApi } from "@/api";
import { useQuery } from "react-query";
import { toJapanDate } from "@/utils";
import { DialogMorbidityHistory } from "./DialogMorbidityHistory";

interface IMorbidityHistoryProps {
  userId?: number;
  babyId?: number;
}

export const MorbidityHistory = (
  props: React.PropsWithChildren<IMorbidityHistoryProps>
) => {
  //--- Get vaccination records
  const [medicalRecordId, setMedicalRecordId] = useState(0);

  const { data: medicalRecords, refetch: refetchMedicalRecords } = useQuery({
    queryKey: "GET_MEDICAL_RECORD",
    queryFn: () => {
      return medicalRecordsApi.adminMedicalRecordsGet({
        userId: props.userId,
        babyId: props.babyId,
        orderBy: "sickDay",
        orderType: "desc",
      });
    },
  });

  //--- Dialog
  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpenDialog = (id: number) => {
    setMedicalRecordId(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setMedicalRecordId(0);
  };

  useEffect(() => {
    if (props.userId > 0 && props.babyId > 0) {
      refetchMedicalRecords();
    }
  }, [props.babyId, props.userId, refetchMedicalRecords]);

  return (
    <>
      <CardCustom classCard={"mt-4"}>
        <TextBlockCustom
          classText="mb-4 font-bold"
          textHeader="罹患歴の記録"
          headerColor={COLORS.primary}
          textSize={16}
        />

        <Grid container spacing={3}>
          {medicalRecords?.data.length > 0 ? (
            (medicalRecords?.data || []).map((item, index) => (
              <Grid item xs={4} key={"medicalRecords_" + index}>
                <Grid container alignItems={"center"}>
                  <Grid item xs={10}>
                    <Grid container>
                      <Grid item xs={4}>
                        <TextBlockCustom
                          classText="mb-2"
                          textHeader="病名:"
                          headerColor={COLORS.approxGrey}
                        />
                        <TextBlockCustom
                          classText="mb-2"
                          textHeader="病気になった日:"
                          headerColor={COLORS.approxGrey}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <TextBlockCustom
                          classText="font-medium mb-2"
                          textHeader={item?.diseaseName}
                        />
                        <TextBlockCustom
                          classText="font-medium mb-2"
                          textHeader={toJapanDate(item?.sickDay)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2} justifySelf="flex-end">
                    <div className="flex justify-end">
                      <ButtonCustom
                        title="詳細"
                        onClick={() => handleClickOpenDialog(item?.id)}
                      />
                    </div>
                  </Grid>
                </Grid>
                <div className="divide-dot"></div>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <div className="text-center font-bold">{noData}</div>
            </Grid>
          )}
        </Grid>
      </CardCustom>

      <DialogMorbidityHistory
        openDialog={openDialog}
        onCloseDialog={() => handleCloseDialog()}
        medicalRecordItem={medicalRecords?.data?.find(
          (v) => v.id === medicalRecordId
        )}
      />
    </>
  );
};
