import { HealthOfMoms, healthOfMomsApi } from "@/api";
import { CardCustom, TextBlockCustom } from "@/components/display";
import { COLORS } from "@/constants";
import {
  getTextChildGender,
  getTextOtherIllnesses,
  renderChildbirthStatus,
  renderDrinkingHabitLevel,
  renderHealthOfMomAbility,
  renderHealthOfMomCheckVaccinated,
} from "@/utils";
import { Grid } from "@mui/material";
import React, { Fragment, useState } from "react";
import { useQuery } from "react-query";

interface IMomHealthProps {
  userId?: number;
  motherId?: number;
}

export const MomHealth = (props: React.PropsWithChildren<IMomHealthProps>) => {
  //--- Get fetus list
  const [healthOfMoms, setHealthOfMoms] = useState<HealthOfMoms>();

  useQuery({
    queryKey: "GET_HEALTH_OF_MOM",
    queryFn: () =>
      healthOfMomsApi.adminHealthOfMomsMotherIdGet({
        motherId: props.motherId,
      }),
    onSuccess: (response) => {
      setHealthOfMoms(response);
    },
  });

  return (
    <CardCustom classCard={"mt-5"}>
      <TextBlockCustom
        classText="mb-4 text-lg font-bold"
        textHeader="ママの健康状態"
        headerColor={COLORS.primary}
        textSize={16}
      />

      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="次の病気にかかったことがありますか。"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              {healthOfMoms?.illnesses
                ?.replace("otherIllnesses", "")
                ?.split(",")
                ?.map(
                  (item, index) =>
                    item !== "" && (
                      <TextBlockCustom
                        key={index}
                        classText="font-medium mb-2"
                        textHeader={getTextOtherIllnesses(item)}
                      />
                    )
                )}

              {!!healthOfMoms?.illnessOther && (
                <>
                  <TextBlockCustom
                    classText="font-medium mb-2"
                    textHeader="その他の病気"
                  />

                  <TextBlockCustom
                    classText="font-medium mb-2"
                    textHeader="病名"
                    headerColor={COLORS.approxGrey}
                  />

                  <TextBlockCustom
                    classText="font-medium mb-2"
                    textHeader={healthOfMoms?.illnessOther}
                  />
                </>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="次の感染症にかかったことがありますか。"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="mb-2"
                textHeader="風疹"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium mb-2"
                textHeader={
                  healthOfMoms?.diseaseRubella
                    ? renderHealthOfMomCheckVaccinated(
                        healthOfMoms?.diseaseRubella
                      )
                    : ""
                }
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  healthOfMoms?.infectedAgeRubella
                    ? healthOfMoms?.infectedAgeRubella + "歳"
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="麻疹"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium mb-2"
                textHeader={
                  healthOfMoms?.diseaseMeasles
                    ? renderHealthOfMomCheckVaccinated(
                        healthOfMoms?.diseaseMeasles
                      )
                    : ""
                }
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  healthOfMoms?.infectedAgeMeasles
                    ? healthOfMoms?.infectedAgeMeasles + "歳"
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="水痘"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium mb-2"
                textHeader={
                  healthOfMoms?.diseaseChickenPox
                    ? renderHealthOfMomCheckVaccinated(
                        healthOfMoms?.diseaseChickenPox
                      )
                    : ""
                }
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  healthOfMoms?.infectedAgeChickenPox
                    ? healthOfMoms?.infectedAgeChickenPox + "歳"
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="今までに手術を受けたことがありますか。"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  healthOfMoms?.surgery
                    ? renderHealthOfMomAbility(healthOfMoms?.surgery)
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="病名"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  healthOfMoms?.nameSurgeryIllnesses
                    ? healthOfMoms?.nameSurgeryIllnesses
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="服薬中の薬（常備薬）"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={healthOfMoms?.medicinesBeingTaken}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="家庭や仕事など日常生活で強いストレスを感じていますか。"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  healthOfMoms?.stress
                    ? renderHealthOfMomAbility(healthOfMoms?.stress)
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="今回の妊娠に際し、過去の妊娠・分娩に関連して心配なことはありますか。"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  healthOfMoms?.concernsRegardingYourPregnancy
                    ? renderHealthOfMomAbility(
                        healthOfMoms?.concernsRegardingYourPregnancy
                      )
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="その他心配なことはありますか。"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={healthOfMoms?.concernsOther}
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="たばこを吸いますか。"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium mb-2"
                textHeader={
                  healthOfMoms?.smoker
                    ? renderHealthOfMomAbility(healthOfMoms?.smoker)
                    : ""
                }
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  healthOfMoms?.amountCigarettesOneDay
                    ? healthOfMoms?.amountCigarettesOneDay + "本/一日"
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="同居者は同室でたばこを吸いますか。"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium mb-2"
                textHeader={
                  healthOfMoms?.residentsSmoker
                    ? renderHealthOfMomAbility(healthOfMoms?.residentsSmoker)
                    : ""
                }
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  healthOfMoms?.amountCigarettesOneDayResidentsSmoker
                    ? healthOfMoms.amountCigarettesOneDayResidentsSmoker +
                      "本/一日"
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="飲酒の習慣がありますか。"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              <TextBlockCustom
                classText="font-medium mb-2"
                textHeader={
                  healthOfMoms?.drinkingHabit
                    ? renderHealthOfMomAbility(healthOfMoms?.drinkingHabit)
                    : ""
                }
              />
              <TextBlockCustom
                classText="font-medium"
                textHeader={
                  healthOfMoms?.drinkingHabitLevel
                    ? renderDrinkingHabitLevel(healthOfMoms?.drinkingHabitLevel)
                    : ""
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="いままでの妊娠"
                headerColor={COLORS.primary}
                textSize={12}
              />
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="出産年月"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              {(healthOfMoms?.pregnantInfos || []).map((item, index) => (
                <Fragment key={"pregnantInfos_" + index}>
                  <TextBlockCustom
                    classText="font-medium mb-2"
                    textHeader={item.year ? `${item.year}年` : ""}
                  />
                  <TextBlockCustom
                    classText="font-medium"
                    textHeader={item.month ? `${item.month}月` : ""}
                  />
                </Fragment>
              ))}
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="妊娠・出産・産後の状態"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              {(healthOfMoms?.pregnantInfos || []).map((item, index) => (
                <Fragment key={"pregnantInfos1_" + index}>
                  <TextBlockCustom
                    classText="font-medium mb-2"
                    textHeader={renderChildbirthStatus(item.childbirthStatus)}
                  />
                  <TextBlockCustom
                    classText="font-medium"
                    textHeader={item.pregnancy ? `${item.pregnancy}週` : ""}
                  />
                </Fragment>
              ))}
            </Grid>

            <Grid item xs={12}>
              <TextBlockCustom
                classText="mb-2"
                textHeader="出生時の体重・性別"
                headerColor={COLORS.approxGrey}
                textSize={12}
              />
              {(healthOfMoms?.pregnantInfos || []).map((item, index) => (
                <Fragment key={"pregnantInfos2_" + index}>
                  <TextBlockCustom
                    classText="font-medium mb-2"
                    textHeader={item.weight ? `${item.weight}g` : ""}
                  />
                  <TextBlockCustom
                    classText="font-medium mb-2"
                    textHeader={getTextChildGender(item.gender)}
                  />
                </Fragment>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CardCustom>
  );
};
