/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseModel } from './BaseModel';
import {
    BaseModelFromJSON,
    BaseModelFromJSONTyped,
    BaseModelToJSON,
} from './BaseModel';
import type { MotherAllOf } from './MotherAllOf';
import {
    MotherAllOfFromJSON,
    MotherAllOfFromJSONTyped,
    MotherAllOfToJSON,
} from './MotherAllOf';

/**
 * 
 * @export
 * @interface Mother
 */
export interface Mother {
    /**
     * 
     * @type {number}
     * @memberof Mother
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Mother
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Mother
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof Mother
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof Mother
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Mother
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Mother
     */
    birthday?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Mother
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Mother
     */
    gender?: MotherGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof Mother
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof Mother
     */
    image?: string | null;
}


/**
 * @export
 */
export const MotherGenderEnum = {
    Male: 'MALE',
    Female: 'FEMALE',
    Unknown: 'UNKNOWN'
} as const;
export type MotherGenderEnum = typeof MotherGenderEnum[keyof typeof MotherGenderEnum];


/**
 * Check if a given object implements the Mother interface.
 */
export function instanceOfMother(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MotherFromJSON(json: any): Mother {
    return MotherFromJSONTyped(json, false);
}

export function MotherFromJSONTyped(json: any, ignoreDiscriminator: boolean): Mother {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'birthday': !exists(json, 'birthday') ? undefined : json['birthday'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'image': !exists(json, 'image') ? undefined : json['image'],
    };
}

export function MotherToJSON(value?: Mother | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'userId': value.userId,
        'name': value.name,
        'email': value.email,
        'birthday': value.birthday,
        'postalCode': value.postalCode,
        'gender': value.gender,
        'address': value.address,
        'image': value.image,
    };
}

