/* tslint:disable */
/* eslint-disable */
/**
 * API SPECS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BabiesFilter } from './BabiesFilter';
import {
    BabiesFilterFromJSON,
    BabiesFilterFromJSONTyped,
    BabiesFilterToJSON,
} from './BabiesFilter';
import type { FetusesFilter } from './FetusesFilter';
import {
    FetusesFilterFromJSON,
    FetusesFilterFromJSONTyped,
    FetusesFilterToJSON,
} from './FetusesFilter';

/**
 * 
 * @export
 * @interface Filter
 */
export interface Filter {
    /**
     * 
     * @type {number}
     * @memberof Filter
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    createdAt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    updatedAt?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Filter
     */
    isDeleted?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof Filter
     */
    userId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    gender?: FilterGenderEnum;
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    birthday?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    postalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    image?: string | null;
    /**
     * 
     * @type {Array<BabiesFilter>}
     * @memberof Filter
     */
    babies?: Array<BabiesFilter>;
    /**
     * 
     * @type {Array<FetusesFilter>}
     * @memberof Filter
     */
    fetuses?: Array<FetusesFilter>;
}


/**
 * @export
 */
export const FilterGenderEnum = {
    Male: 'MALE',
    Female: 'FEMALE',
    Unknown: 'UNKNOWN'
} as const;
export type FilterGenderEnum = typeof FilterGenderEnum[keyof typeof FilterGenderEnum];


/**
 * Check if a given object implements the Filter interface.
 */
export function instanceOfFilter(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FilterFromJSON(json: any): Filter {
    return FilterFromJSONTyped(json, false);
}

export function FilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): Filter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'birthday': !exists(json, 'birthday') ? undefined : json['birthday'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'babies': !exists(json, 'babies') ? undefined : ((json['babies'] as Array<any>).map(BabiesFilterFromJSON)),
        'fetuses': !exists(json, 'fetuses') ? undefined : ((json['fetuses'] as Array<any>).map(FetusesFilterFromJSON)),
    };
}

export function FilterToJSON(value?: Filter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'isDeleted': value.isDeleted,
        'userId': value.userId,
        'email': value.email,
        'name': value.name,
        'gender': value.gender,
        'birthday': value.birthday,
        'postalCode': value.postalCode,
        'address': value.address,
        'image': value.image,
        'babies': value.babies === undefined ? undefined : ((value.babies as Array<any>).map(BabiesFilterToJSON)),
        'fetuses': value.fetuses === undefined ? undefined : ((value.fetuses as Array<any>).map(FetusesFilterToJSON)),
    };
}

